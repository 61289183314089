import styled from 'styled-components';
import {Stack} from '../Layout';
import {NavProps} from '../Nav';
import {Text} from '../Text';
import {_NavHeaderTitle} from './_NavHeaderTitle';

type Props = Pick<
  NavProps,
  'title' | 'titleRightContent' | 'description' | 'Image'
>;

export const _NavHeader = ({
  title,
  titleRightContent,
  description,
  Image,
}: Props) => {
  const hasHeader = !!Image || !!title || !!titleRightContent || !!description;

  if (!hasHeader) return null;

  return (
    <Stack
      css={`
        margin-bottom: var(--spacing-4);
      `}>
      {!!Image && (
        <_ImageContainer>
          <Image />
        </_ImageContainer>
      )}
      <Stack direction='column' justifyContent='center'>
        {!!title && <_NavHeaderTitle title={title} />}
        {description &&
          (typeof description === 'string' ? (
            <Text
              variant='body1'
              color='muted'
              css={`
                margin-top: var(-spacing-2);
                max-width: 50rem;
              `}>
              {description}
            </Text>
          ) : (
            description
          ))}
      </Stack>
    </Stack>
  );
};

const _ImageContainer = styled.div`
  align-items: flex-start;
  display: flex;
  margin: 0.125rem var(--spacing-6) 0 0;
  width: 4.5rem;
`;
