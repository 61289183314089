import {gql} from '@apollo/client';

export default gql`
  mutation acceptCompanyClaim($input: AcceptCompanyClaimInput!) {
    authed {
      success
    }
    acceptCompanyClaim(input: $input) {
      success
    }
  }
`;
