import React from 'react';
import {Switch} from 'react-router-dom';
import useRoutes from '@core/lib/useRoutes';
import OrganizationCreate from './OrganizationCreate';
import Organizations from './Organizations';
import OrganizationIndex from './organization';

const OrganizationsIndex = (): JSX.Element => {
  const {renderRoutes} = useRoutes({
    baseUrl: `/organizations`,
    basePath: '/organizations',
    routes: [
      {key: 'organizations', path: '', exact: true},
      {key: 'new', exact: true},
      {key: 'organization', path: ':organizationId'},
    ],
  });

  return (
    <Switch>
      {renderRoutes({
        components: {
          organizations: Organizations,
          new: OrganizationCreate,
          organization: OrganizationIndex,
        },
        extraProps: {},
        redirects: [{from: '*', to: ''}],
      })}
    </Switch>
  );
};

export default OrganizationsIndex;
