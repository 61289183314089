import ErrorBase from './ErrorBase';

const NetworkError = () => {
  return (
    <ErrorBase title='Sorry, unexpected error' baseColor='var(--red-orange)'>
      <p>
        We are working on fixing the problem. Please reach out to{' '}
        <a
          style={{color: 'var(--red-orange)'}}
          href='mailto:support-adanalytics@spotify.com'>
          support-adanalytics@spotify.com
        </a>{' '}
        for help.
      </p>
    </ErrorBase>
  );
};

export default NetworkError;
