// NOTE: This code was generated and should not be changed

/**
 * Semantic Metric for Clients for non-authenticated requests
 */
export type SemanticMetricClientNonAuthEventData = {
  /**
   * Metric key
   */
  key?: string | null;
  /**
   * gauge, counter, meter, timer
   */
  metric_type?: string | null;
  /**
   * Component ID that this metric belongs to
   */
  component_id?: string | null;
  /**
   * What to measure, using "kebab-case"
   */
  what?: string | null;
  /**
   * Value of what is measured
   */
  value?: number | null;
  /**
   * Additional metadata for the metric
   */
  tags?: Record<string, string> | null;
};

export type SemanticMetricClientNonAuthEvent = {
  name: 'SemanticMetricClientNonAuth';
  environments: ['browsernonauth', 'devicenonauth'];
  data: SemanticMetricClientNonAuthEventData;
};

/**
 * A builder for SemanticMetricClientNonAuth
 *
 * @param data - The event data
 * @return The formatted event data for SemanticMetricClientNonAuthEvent
 */
export function createSemanticMetricClientNonAuth(
  data: SemanticMetricClientNonAuthEventData
): SemanticMetricClientNonAuthEvent {
  return {
    name: 'SemanticMetricClientNonAuth',
    environments: ['browsernonauth', 'devicenonauth'],
    data,
  };
}
