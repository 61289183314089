export type UserAgentContext = {
  name: 'context_user_agent';
  data: {
    /**
     * UserAgent is a string that comes from a browser's navigator.userAgent property. It identifies the OS and OS version, and browser and browser version.
     */
    value: string;
  };
};

/**
 * A builder for UserAgent
 *
 * @param userAgent - userAgent is a string that identifies the OS and OS version, and browser and browser version. Defaults to extract from `navigator.userAgent`.
 * @return The formatted event data for UserAgentContext
 */
export function createUserAgentContext(
  userAgent?: string
): () => UserAgentContext {
  if (!userAgent && typeof navigator === 'undefined') {
    throw new Error(
      'userAgent is not provided and navigator.UserAgent is not available'
    );
  }
  return () => {
    return {
      name: 'context_user_agent',
      data: {value: userAgent || navigator.userAgent},
    };
  };
}
