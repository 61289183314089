import { screenSmMin, screenXxsMax } from '@spotify-internal/encore-foundation';
import { cssSpacing, overlay, zIndexDialog } from "../../styles/index.js";
import { snackbarTransitionDuration } from "./index.js";
import styled, { css } from "../../styled-components.js";
export var Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-eij2zk-0"
})(["", ""], function (_ref) {
  var colorSet = _ref.colorSet,
    isVisible = _ref.isVisible;
  return css(["", " padding-block-start:", ";padding-block-end:", ";padding-inline-start:", ";padding-inline-end:", ";overflow-wrap:break-word;text-align:center;position:fixed;overflow-x:hidden;text-overflow:ellipsis;z-index:", ";transition:visibility ", "ms linear,opacity ", "ms ease-in-out;bottom:48px;.docs-story{position:absolute;}@media (max-width:", "){left:8px;right:8px;}@media (min-width:", "){transform:translateX(-50%);left:50%;}", ""], overlay({
    maxWidth: '384px',
    colorSet: colorSet
  }), cssSpacing('tighter', '12px'), cssSpacing('tighter', '12px'), cssSpacing('looser-2', '32px'), cssSpacing('looser-2', '32px'), zIndexDialog, snackbarTransitionDuration, snackbarTransitionDuration, screenXxsMax, screenSmMin, isVisible ? css(["animation-fill-mode:forwards;visibility:visible;opacity:1;"]) : css(["visibility:hidden;opacity:0;"]));
});