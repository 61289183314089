import React from 'react';
import QueueQuery from '@admin/graphql-api/_old_queries/QueueQuery';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import {Link} from 'react-router-dom';
import {Element} from '@core/style';
import Loading from '@core/ui/Loading';

class Queue extends React.Component {
  render() {
    const {
      match: {
        params: {topic},
      },
      data: {loading, admin},
    } = this.props;

    if (loading) {
      return <Loading absolute />;
    }

    const messages = admin.queue.messages.slice(0);
    messages.reverse();

    return (
      <div>
        <div>
          <Link to={`/queue/${topic}/publish`}>Publish Message</Link>{' '}
        </div>

        <div>
          {admin.queue.length}
          {messages.map((msg, n) => {
            return (
              <div key={n}>
                {msg.action} : {msg.attributes}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default graphql(QueueQuery, {
  options: ({
    match: {
      params: {topic, start, stop},
    },
  }) => ({
    variables: {
      topic,
      start: start ? start : -100,
      stop: stop ? stop : -1,
    },
  }),
})(Queue);
