import {
  InputAdornment as MuiInputAdornment,
  type InputAdornmentProps as MuiInputAdornmentProps,
  ThemeOptions,
} from '@mui/material';

export const InputAdornment = MuiInputAdornment;
export type InputAdornmentProps = MuiInputAdornmentProps;

export const MuiInputAdornmentStyles: ThemeOptions['components'] = {
  MuiInputAdornment: {
    defaultProps: {
      disablePointerEvents: true,
    },
    styleOverrides: {
      sizeSmall: ({ownerState: {hiddenLabel}}) => ({
        width: '1.25rem',
        height: '1.25rem',
        ...(!!hiddenLabel && {marginTop: 0}),
      }),
    },
  },
};
