import {CounterSettings, Duration, Retriable} from './typedefs';

import {PromiseResolver, createPromiseResolver} from '../promise_resolver';
import {Counter} from './counter';

/**
 * A backoff module that handles retries and backoff with different timings
 * depending on configuration. Expects a "thenable" function to retry.
 *
 * Usage: function getData(url) { return getRequest(url); // pretend function
 * that returns ajax promise }
 *
 *     function successHandler(data) {
 *       console.log('great success!', data);
 *     }
 *
 *     Backoff.init(getData.bind(null, 'https://httpbin.org/get'))
 *       .then(successHandler)
 *       .catch(function() {
 *         console.log('you're out of luck!');
 *       });
 */

function backoffDestroyed(): Promise<never> {
  return Promise.reject(new Error('Backoff already consumed'));
}

type PromiseFn<T> = () => Promise<T>;
type RetryPredicate = (err: Retriable) => boolean;

export interface BackoffSettings extends CounterSettings {
  /**
   * True if the first call should be backed-off.
   */
  backoffInitial?: boolean;
  /**
   * The longest allowed time for the retries to be run, defaults to Infinity.
   */
  maxDuration?: Duration;
  /**
   * The maximum number of retries, defaults to Infinity.
   */
  maxRetries?: number;
  /**
   * The longest time to allow passing between two retries, defaults to Infinity.
   */
  maxTime?: Duration;
  /**
   * A function that indicates if the retry should happen.
   */
  retryPredicate?: RetryPredicate;
}

/**
 * The default options used when initializing a Backoff instance. Values in this
 * object are overridden if supplied to the constructor.
 */
const backoffDefaults: Required<BackoffSettings> = {
  backoffInitial: false,
  baseTime: 200,
  ceiling: 0,
  curve: 'linear',
  jitter: true,
  maxDuration: Infinity,
  maxRetries: Infinity,
  maxTime: Infinity,
  retryPredicate: () => true,
};

export class Backoff<T = unknown> {
  /**
   * Function to retry. t to the Backoff instance. Note that arguments to this
   * function must be bound to it before adding
   */
  private _fn: PromiseFn<T>;

  /**
   * Promise to resolve/reject when successful/failed.
   */
  private _resolver: PromiseResolver<T> = createPromiseResolver<T>();

  /**
   * Max allowed number of milliseconds for the backoff to run.
   */
  private _maxDuration: Duration;

  /**
   * Max number of allowed retries, optional (defaults to Infinity).
   */
  private _maxRetries: number;

  /**
   * Max number of milliseconds for a single retry. If this is exceeded the
   * backoff terminates, optional (defaults to Infinity).
   */
  private _maxTime: Duration;

  /**
   * A predicate that receives an error and returns if it should retry
   */
  private _retryPredicate: RetryPredicate;

  /**
   * Epoch timestamp, will be set when the backoff starts.
   */
  private _ts: number = 0;

  /**
   * Number of executed retries.
   */
  private _callCount: number = 0;

  /**
   * True if the first call should not be backed of, so the first call to tick
   * will be delayed.
   */
  private _backoffInitial: boolean;

  /**
   * Timeout that holds the sleep until next retry.
   */
  private _tickInterval: Duration = 0;

  /**
   * True when the backoff is running.
   */
  private _isRunning: boolean = false;

  /**
   * True when the backoff has been consumed or stopped.
   */
  private _isDestroyed: boolean = false;

  /**
   * Counter instance belonging to this instance of backoff.
   */
  private _counter: Counter;

  /**
   * Backoff, handles backoff retries for async functions.
   *
   * @class
   * @param fn - Thenable function to use the backoff with.
   * @param opts - Initialization options, extends/overrides defaults.
   * @export module:spotify-backoff
   */
  constructor(fn: PromiseFn<T>, opts?: BackoffSettings) {
    // Destruct the options required by this class in order to spread the remainder
    // Counter-specific settings; this allows us to automatically keep in-sync with Counter
    // without having to manually update and pass configuration around
    const {
      backoffInitial,
      maxDuration,
      maxRetries,
      maxTime,
      retryPredicate,
      ...counterOpts
    } = {...backoffDefaults, ...opts};

    this._fn = fn;

    this._backoffInitial = backoffInitial;
    this._maxDuration = maxDuration;
    this._maxRetries = maxRetries;
    this._maxTime = maxTime;
    this._retryPredicate = retryPredicate;
    this._backoffInitial = backoffInitial;

    // If we have forgotten to destruct any properties not requried by Counter
    // they will get passed in this constructor which _probably_ is harmless
    // unless Counter starts to pass the options elsewhere
    this._counter = new Counter(counterOpts);

    if (this._backoffInitial) {
      // To get the correct timings from the counter instance, max retries needs
      // to be incremented by one if backoffInitial is set.
      this._maxRetries += 1;
    }
  }

  /**
   * Initializes and starts a new backoff.
   *
   * @param fn - Thenable function to use the backoff with.
   * @param opts - Initialization options.
   * @returns A new backoff instance.
   */
  static init<T>(fn: PromiseFn<T>, opts: BackoffSettings = {}): Promise<T> {
    return new Backoff(fn, opts).start();
  }

  /**
   * Destroys a backoff instance.
   */
  private _destroy(): void {
    this._isRunning = false;
    this._isDestroyed = true;
  }

  /**
   * Failure handler, will reset the timeout to the next point in the future.
   *
   * @param err - The error from the failure that might cause a retry.
   */
  private _failure(err: Retriable): void {
    // if the backoff is manually stopped we can
    // land here when the original promise resolves
    // and cause unintended side effects
    if (this._isDestroyed) {
      return;
    }

    if (err && 'retryAfter' in err) {
      this._retryAfter(err);
    } else {
      const time = this._counter.getTime(this._callCount);
      const shouldRetry = this._shouldRetry(time, err);

      if (shouldRetry) {
        this._callCount++;
        this._tickInterval = setTimeout(() => this._tick(), time);
      } else {
        this._resolver.reject(err);
        this._destroy();
      }
    }
  }

  /**
   * Failure handler for errors with the `retryAfter` property set, will retry
   * once that amount of milliseconds have passed.
   *
   * @param err - The error from the failure that caused the retry.
   */
  private _retryAfter(err: Retriable): void {
    this._callCount++;
    this._tickInterval = setTimeout(() => this._tick(), err.retryAfter);
  }

  /**
   * Success handler, resolves the resolver promise and makes the backoff self-destruct.
   *
   * @param args - The arguments to the resolver.
   */
  private _success(...args: any): void {
    // if the backoff is manually stopped we can
    // land here when the original promise resolves
    // and cause unintended side effects
    if (this._isDestroyed) {
      return;
    }

    this._resolver.resolve(...args);
    this._destroy();
  }

  /**
   * Test if backoff should retry.
   *
   * @param time - The time for the next tick timeout.
   * @param err - The error from the failure that possibly might cause a retry.
   * @returns True if the backoff should be retried, false otherwise.
   */
  private _shouldRetry(time: Duration, err: Retriable): boolean {
    const duration = Date.now() - this._ts + time;
    return (
      this._callCount < this._maxRetries &&
      time < this._maxTime &&
      duration < this._maxDuration &&
      this._retryPredicate(err)
    );
  }

  /**
   * Tick, timed-out function that initializes the next retry.
   */
  private _tick(): void {
    this._fn()
      .then((...args: any[]) => this._success(...args))
      .catch((e) => this._failure(e));
  }

  /**
   * Returns the resolver promise for a backoff instance.
   *
   * @returns The resolver promise.
   */
  getResolver(): Promise<any> {
    return this._resolver.promise;
  }

  /**
   * Starts the backoff, returns it's resolver promise.
   *
   * @returns The result of the resolver promise.
   */
  start(): Promise<T> {
    if (this._isDestroyed) {
      return backoffDestroyed();
    }

    if (!this._isRunning) {
      this._ts = Date.now();
      this._isRunning = true;
      if (this._backoffInitial) {
        this._callCount = 1;
        this._tickInterval = setTimeout(
          () => this._tick(),
          this._counter.getTime(0)
        );
      } else {
        this._tick();
      }
    }
    return this._resolver.promise;
  }

  /**
   * Stops a backoff instance, and destroys it.
   */
  stop(): void {
    clearTimeout(this._tickInterval);
    this._destroy();
  }

  /**
   * Get the number of retries.
   *
   * @returns The number of retries.
   */
  getRetryCount(): number {
    return this._callCount;
  }
}
