import {useRef, useState} from 'react';
import {useUpdateCampaignDatesMutation} from '@admin/graphql-api';
import {ButtonPrimary, ButtonTertiary} from '@spotify-internal/encore-web';
import {DataTableFilterButton} from '@core/ui/DataTable';
import DatePicker from '@core/ui/DatePicker';
import {Stack} from '@core/ui/Layout';
import {Overlay, useOverlay} from '@core/ui/Overlay';
import {CampaignDetailsProps} from '.';
import {fmtCampaignDate, getCampaignDates} from '../utils';

export function CampaignStartEnd({
  campaign,
  onCampaignError,
  setUpdatingCampaign,
}: CampaignDetailsProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const [opened, toggle] = useOverlay();
  const [dates, setDates] = useState(getCampaignDates(campaign));
  const [updateCampaignDates] = useUpdateCampaignDatesMutation();

  const onSubmit = async () => {
    setUpdatingCampaign(true);
    try {
      await updateCampaignDates({
        variables: {
          input: {
            id: campaign.id,
            startAt: dates[0].toDate().toISOString(),
            endAt: dates[1].toDate().toISOString(),
          },
        },
      });
    } catch (e) {
      console.warn(e);
      onCampaignError(e.message ?? 'Error updating campaign start/end dates');
    } finally {
      toggle(false);
      setUpdatingCampaign(false);
    }
  };

  return (
    <>
      <DataTableFilterButton
        css={{
          fontSize: 'var(--font-size-body-2)',
          height: 'var(--input-height-sm)',
          marginBottom: '0.5rem',
        }}
        domRef={ref}
        onClick={() => toggle()}>
        {fmtCampaignDate(campaign.startAt)} / {fmtCampaignDate(campaign.endAt)}
      </DataTableFilterButton>
      {opened && (
        <Overlay
          horizontalAlign='right'
          opened={opened}
          positionTarget={ref.current}
          toggle={toggle}
          transparentBackdrop
          verticalAlign='bottom'
          verticalOffset={2}
          withShadow
          withBackdrop
          css={`
            max-width: initial;
            padding: 1rem;
          `}>
          <div css={{position: 'relative', height: '100%'}}>
            <DatePicker
              defaultDates={dates}
              onDatesChange={setDates}
              twoMonthsView
            />
            <Stack gap={2} justifyContent='flex-end' css={{marginTop: '2rem'}}>
              <ButtonTertiary onClick={() => toggle(false)} size='small'>
                Cancel
              </ButtonTertiary>
              <ButtonPrimary
                disabled={dates?.length !== 2}
                onClick={onSubmit}
                size='small'>
                Save changes
              </ButtonPrimary>
            </Stack>
          </div>
        </Overlay>
      )}
    </>
  );
}
