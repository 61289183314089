export const RangeSliderHandle = ({
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    css={{
      background: 'var(--bg-overlay)',
      borderColor: 'var(--green)',
      borderRadius: '50%',
      borderStyle: 'solid',
      borderWidth: '0.1875rem',
      bottom: 0,
      boxShadow: '0 0 0 0.1875rem var(--bg-overlay)',
      height: '1.5rem',
      marginLeft: '-0.625rem',
      position: 'absolute',
      top: 0,
      transition: 'transform .1s ease',
      width: '1.5rem',
      willChange: 'left',
      zIndex: 2,
      ':hover': {
        transform: 'scale(1.10)',
      },
    }}
    {...props}
  />
);
