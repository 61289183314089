/**
 * An event object.
 *
 * @typeParam T - The type of the `data` property of this event.
 */
export class EmittedEvent<T = never> {
  /**
   * The data that is associated with this event.
   */
  readonly data: T;

  /**
   * The event "type", indicating the event that was emitted.
   */
  readonly type: string;

  /**
   * A flag that indicates whether the `preventDefault()` method has been called
   * on this event.
   */
  defaultPrevented: boolean = false;

  /**
   * A flag that indicates whether the `stopImmediatePropagation()` method has
   * been called on this event.
   */
  immediateStopped: boolean = false;

  /**
   * A flag that indicates whether the `stopPropagation()` method has been
   * called on this event.
   */
  stopped: boolean = false;

  /**
   * @param type - The name of the event
   * @param data - The data that is associated with the event.
   */
  constructor(type: string, data: T) {
    this.type = type;
    this.data = data;
  }

  /**
   * Prevents the default behaviour of this event.
   *
   * Note that whether this is respected is dependent on the emitter itself.
   */
  preventDefault(): void {
    this.defaultPrevented = true;
  }

  /**
   * Stops the propagation of the event.
   *
   * Note that whether this is respected is dependent on the emitter itself.
   *
   * In cases of proxied events, calling this method prevents the proxies from
   * emitting this event.
   */
  stopPropagation(): void {
    this.stopped = true;
  }

  /**
   * Stops the immediate propagation of the event.
   *
   * Event listeners are called in the order they were added. Calling this
   * method inside a listener prevents the emitter from calling listeners after
   * the current one.
   */
  stopImmediatePropagation(): void {
    this.immediateStopped = true;
  }
}
