/**
 * Encore Foundation - 5.23.1
 *
 * The contents of this file is owned and generated by the Encore team.
 * If you need to make changes to the values stored here please reach out
 * to #encore on Slack.
 *
 * Links:
 *
 * - Source & Documentation: https://ghe.spotify.net/encore/foundation
 *
 * Tack!
 *
 * Encore
 * encore.spotify.net
 */
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 * 100% transparency of an element
 */
export var opacity0 = '0';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 * 90% transparency of an element
 */
export var opacity10 = '0.1';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 * 70% transparency of an element
 */
export var opacity30 = '0.3';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 * 50% transparency of an element
 */
export var opacity50 = '0.5';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 * 30% transparency of an element
 */
export var opacity70 = '0.7';
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 * 10% transparency of an element
 */
export var opacity90 = '0.9';
