import {useEffect, useRef, useState} from 'react';
import CompanyClaimsQuery from '@admin/graphql-api/_old_queries/CompanyClaimsQuery';
import {useQuery} from '@apollo/client';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import dayjs from '@core/lib/dayjs';
import Badge from '@core/ui/Badge';
import {Card, PageTitle} from '@core/ui/Content';
import DataTable from '@core/ui/DataTable';
import Loading from '@core/ui/Loading';

const animatedComponents = makeAnimated();

const CompanyClaims = () => {
  const stateOptions = [
    {value: 'requested', label: 'Requested'},
    {value: 'approved', label: 'Approved'},
    {value: 'denied', label: 'Denied'},
  ];
  const [selectedStates, setSelectedStates] = useState([stateOptions[0]]);
  const isMounted = useRef(false);

  const {loading, error, data, refetch} = useQuery(CompanyClaimsQuery, {
    fetchPolicy: 'network-only',
    variables: {
      states: selectedStates.map((option) => option.value),
    },
  });

  useEffect(() => {
    if (isMounted.current) {
      refetch({
        states: selectedStates.map((option) => option.value),
      });
    } else {
      isMounted.current = true;
    }
  }, [refetch, selectedStates]);

  if (loading) {
    return <Loading absolute />;
  }

  if (error) {
    console.error('Error fetching data:', error);
    return <div>Error fetching data</div>;
  }

  const handleSelectedStateChange = (selected) => {
    setSelectedStates(
      selected.map((item) => ({value: item.value, label: item.label}))
    );
  };

  const columns = [
    {
      title: 'Claim',
      accessor: 'companyName',
      type: 'string',
      rule: () => ({fontWeight: 500}),
    },
    {
      title: 'State',
      accessor: 'state',
      type: 'string',

      Filter: () => (
        <Select
          defaultValue={[stateOptions[0]]}
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          name='states'
          options={stateOptions}
          value={selectedStates}
          onChange={(selectedStates) =>
            handleSelectedStateChange(selectedStates)
          }
          placeholder='Select states'
          className='basic-multi-select'
          classNamePrefix='select'
        />
      ),
      Cell: ({data: {state}}) => {
        const COLORS = {
          approved: {
            variant: 'filled',
            color: 'secondary',
          },
          denied: {
            variant: 'filled',
            color: 'error',
          },
          default: {
            variant: 'outlined',
            color: 'default',
          },
        };

        const {variant, color} = COLORS[state?.toLowerCase()] || COLORS.default;

        return (
          <Badge variant={variant} color={color}>
            {state}
          </Badge>
        );
      },
    },
    {
      title: 'Created',
      accessor: 'createdAt',
      type: 'date',
      fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
    },

    {
      title: 'Created by',
      type: 'string',
      Cell: ({data: {user}}) => <span>{user ? user.email : ''}</span>,
    },
  ];

  const tableData = data.admin.companyClaims
    ? data.admin.companyClaims.map((d) =>
        Object.assign({companyName: d.company ? d.company.name : ''}, d)
      )
    : [];

  return (
    <>
      <PageTitle>Claims</PageTitle>
      <Card>
        <DataTable
          columns={columns}
          data={tableData}
          orderBy='-createdAt'
          searchPlaceholder='Search by company name'
          filtersStorageKey='admin-company-claims'
          searchKeys={['companyName']}
          onClickRowPath={({id}) => `/claims/${id}`}
        />
      </Card>
    </>
  );
};

export default CompanyClaims;
