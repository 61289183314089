import {
  ButtonPrimary,
  ButtonSecondary,
  IconArrowTopRight,
  TextLink,
  Type,
} from '@spotify-internal/encore-web';
import styled from 'styled-components';

export const OnboardHeader = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 64px 0px;
  width: 100%;
  justify-content: center;
`;

export const OnboardPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: 1;
`;

export const OnboardContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const OnboardBodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  gap: 20px;
  padding: 80px;
  justify-content: flex-start;
  padding-top: 120px;
  max-width: 600px;
  @media (max-width: 1024px) {
    padding: 80px;
  }
`;

export const OnboardTitleText = styled(({...props}) => (
  <Type variant='alto' {...props} />
))`
  text-align: left;
`;

export const OnboardPrimaryButton = styled(({...props}) => (
  <ButtonPrimary style={{width: '100%'}} {...props} />
))``;

export const OnboardSecondaryButton = styled(({...props}) => (
  <ButtonSecondary style={{width: '100%'}} {...props} />
))``;

export const FormContainer = styled.div`
  margin-right: 20%;
  margin-left: 20%;
`;

export const HadAccountMessage = () => {
  return (
    <>
      <Type variant='ballad' style={{color: '#727272', paddingTop: 40}}>
        Had a Podsights account?
      </Type>
      <Type variant='viola' style={{color: '#727272'}}>
        Create a new Spotify account with the same credentials and we’ll import
        your existing campaign data.{' '}
        <TextLink
          style={{
            fontSize: 14,
            textDecoration: 'underline',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            color: '#000000',
          }}
          target='_blank'
          href='https://help.adanalytics.spotify.com/spotify-ad-analytics-faq'>
          Learn more
          <IconArrowTopRight size='small' />
        </TextLink>
      </Type>
    </>
  );
};
