import React from 'react';
import RemoveAccessMutation from '@admin/graphql-api/_old_mutations/RemoveAccess';
import {graphql} from '@apollo/client/react/hoc';
import {Element} from '@core/style';
import {Submit} from '@core/ui/FormElements';

class OrganizationAccessRemove extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organizationId: '',
      loading: false,
      complete: false,
    };
  }

  onOrgChange = ({title, value}) => {
    this.setState({
      organizationId: value,
    });
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const {
      removeAccess,
      match: {
        params: {permissionId},
      },
    } = this.props;

    this.setState({
      loading: true,
    });

    removeAccess({
      accessId: parseInt(permissionId),
    }).then(() => {
      this.setState({
        complete: true,
      });
    });
  };

  render() {
    const {
      organization,
      match: {
        params: {permissionId},
      },
    } = this.props;

    const accessId = parseInt(permissionId);

    // Find the permission.
    const permission = organization.permissions
      .filter(({id}) => id == accessId)
      .concat(
        organization.reversePermissions.filter(({id}) => id == accessId)
      )[0];

    const {loading, complete, error} = this.state;

    if (complete) {
      window.location.pathname = `/organizations/${organization.id}`;
      return null;
    }

    return (
      <Element rules={() => ({maxWidth: '600px', margin: '25px auto'})}>
        <h4>Remove Access</h4>

        <form onSubmit={this.onSubmit}>
          <h5>This is Destructive</h5>
          <dl>
            <dt>Name</dt>
            <dd>{permission.name}</dd>
            <dt>Permission</dt>
            <dd>{permission.permission}</dd>
            <dt>Object</dt>
            <dd>{permission.objectName}</dd>
            <dt>Content Object Name</dt>
            <dd>{permission.contentObjectName}</dd>
            <dt>Organization</dt>
            <dd>{permission.organization.name}</dd>
          </dl>

          <Element rules={() => ({padding: '20px 0', textAlign: 'right'})}>
            <Submit color='error' value='Remove Access' />
          </Element>
        </form>
      </Element>
    );
  }
}

export default graphql(RemoveAccessMutation, {
  props: ({mutate}) => ({
    removeAccess: (input) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
  }),
})(OrganizationAccessRemove);
