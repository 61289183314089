import {gql} from '@apollo/client';

export default gql`
  query getEpisodelessCampaignEpisodes($after: String!, $before: String!) {
    admin {
      episodelessCampaignEpisodes(after: $after, before: $before) {
        id
        futureEpisode {
          id
          title
          subtitle
          expectedPublished
        }

        campaignPodcast {
          id
          campaign {
            id
            name
            slug
            organization {
              id
              name
              slug
            }

            advertiser {
              id
              name
            }
          }
          feed {
            id
            title
            description(words: 25)
            href
            image {
              id
              href
              path
            }
          }
        }

        updatedAt
        createdAt
      }
    }
  }
`;
