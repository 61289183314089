import React, {FC, useEffect, useRef, useState} from 'react';
import dayjs from 'dayjs';
import {TRules, css} from '@core/style';
import {Icon} from '../Icon';
import {Overlay, OverlayProps, useOverlay} from '../Overlay';
import DatePicker from './DatePicker';

interface IDateRangeInputProps {
  disabled?: boolean;
  endDate?: dayjs.Dayjs;
  inputDateFormat?: string;
  inputRules?: TRules;
  invalid?: boolean;
  isOutsideRange?: (_date: dayjs.Dayjs) => boolean;
  onDatesChange?: (_dates: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) => void;
  overlayProps?: OverlayProps;
  placeholder?: string;
  rules?: TRules;
  small?: boolean;
  startDate?: dayjs.Dayjs;
}

const DateRangeInput: FC<IDateRangeInputProps> = ({
  disabled = false,
  endDate,
  inputDateFormat = 'MM/DD/YY',
  inputRules,
  invalid = false,
  isOutsideRange,
  onDatesChange,
  overlayProps = {
    horizontalAlign: 'right',
    verticalAlign: 'bottom',
    verticalOffset: 2,
  },
  placeholder = 'Select dates...',
  rules,
  small,
  startDate,
}): JSX.Element => {
  const inputRef = useRef<HTMLButtonElement>();
  const [opened, toggle] = useOverlay(false);
  const [selectedDates, setSelectDates] = useState<dayjs.Dayjs[]>(
    [startDate, endDate].filter((d) => !!d)
  );

  const handleDatesChange = (dates: dayjs.Dayjs[]) => {
    setSelectDates(dates);

    if (onDatesChange && dates.length === 2) {
      onDatesChange({startDate: dates[0], endDate: dates[1]});
    }
  };

  useEffect(() => {
    if (!opened) {
      if (selectedDates.length !== 2) {
        // Reset dates if just one selected
        handleDatesChange(startDate && endDate ? [startDate, endDate] : []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <>
      <div {...css([() => ({display: 'flex', alignItems: 'center'}), rules])}>
        <button
          type='button'
          ref={inputRef}
          onClick={() => {
            if (!disabled) {
              toggle(true);
            }
          }}
          {...css([
            () => ({
              background: disabled ? 'var(--bg-muted)' : 'var(--bg-default)',
              border: `0.0625rem solid ${
                invalid
                  ? 'var(--red-orange-80)'
                  : opened
                  ? 'var(--blue)'
                  : 'var(--border-default)'
              }`,
              borderRadius: '0.375rem',
              boxShadow: opened ? '0 0 0 0.0625rem var(--blue)' : null,
              color: invalid
                ? 'var(--red-orange)'
                : opened || selectedDates.length > 0
                ? 'var(--text-default)'
                : 'var(--text-muted)',
              alignItems: 'center',
              cursor: disabled ? 'not-allowed' : 'pointer',
              display: 'flex',
              fontSize: 'inherit',
              minHeight: small ? '2.125rem ' : '2.5rem',
              minWidth: '7.8125rem',
              outline: 0,
              padding: '0 0.75rem',
              textAlign: 'left',
              userSelect: 'none',
              '[disabled]': {
                color: 'var(--text-disabled)',
              },
              ':focus': {
                borderColor: 'var(--blue)',
                boxShadow: '0 0 0 0.0625rem var(--blue)',
              },
            }),
            inputRules,
          ])}>
          {small ? null : (
            <Icon
              icon='date'
              rules={() => ({
                color: invalid
                  ? 'var(--red-orange)'
                  : disabled
                  ? 'var(--icon-muted)'
                  : 'var(--icon-subtle)',
                height: '1.375rem',
                marginRight: '0.625rem',
                width: '1.375rem',
              })}
            />
          )}
          {selectedDates.length > 0 ? (
            <>
              {selectedDates[0]
                ? selectedDates[0].format(inputDateFormat)
                : null}{' '}
              -{' '}
              {selectedDates[1]
                ? selectedDates[1].format(inputDateFormat)
                : null}
            </>
          ) : (
            placeholder
          )}
        </button>
      </div>
      {opened ? (
        <Overlay
          opened={opened}
          toggle={toggle}
          positionTarget={inputRef.current}
          withShadow
          withBackdrop
          transparentBackdrop
          css={`
            border: 1px solid var(--border-default);
            max-width: initial;
            padding: 1rem;
          `}
          {...overlayProps}>
          <DatePicker
            isOutsideRange={isOutsideRange}
            onDatesChange={handleDatesChange}
            defaultDates={selectedDates}
            twoMonthsView
          />
        </Overlay>
      ) : null}
    </>
  );
};

export default DateRangeInput;
