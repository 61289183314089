import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import DomainsAdd from './DomainsAdd';
import DomainIndex from './domain';

const DomainsIndex = ({organization}) => {
  return (
    <Switch>
      <PropsRoute
        path='/organizations/:organizationId/domains/add'
        organization={organization}
        component={DomainsAdd}
      />
      <PropsRoute
        path='/organizations/:organizationId/domains/:domainId'
        organization={organization}
        component={DomainIndex}
      />
    </Switch>
  );
};

export default DomainsIndex;
