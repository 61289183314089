import {comma} from '@core/lib/filters';
import {formatDate, toTimeString} from '@core/lib/time';
import {isNone} from '@core/lib/utils';
import {stripHTML, truncateWords} from '@core/lib/words';
import {DARK_GRAY, Element, GREEN, ORANGE} from '@core/style';
import {EpisodeImage} from '@core/ui/Image';
import {CleanLink} from '@core/ui/Link';
import Stat from '@core/ui/Stat';

const EpisodeContent = ({feed, episode, placement}) => {
  return (
    <Element rules={() => ({display: 'flex'})}>
      <Element rules={() => ({flex: '0 0 125px', padding: '5px'})}>
        <EpisodeImage episode={episode} feed={feed} width={200} />
      </Element>
      <Element rules={() => ({flex: 1, marginLeft: '5px'})}>
        <Element rules={() => ({display: 'flex', alignItems: 'center'})}>
          {placement && (
            <Element
              rules={() => ({
                fontSize: '12px',
                marginRight: '10px',
                color: '#aaa',
              })}>
              ({toTimeString(placement.startTime)} -{' '}
              {toTimeString(placement.endTime)})
            </Element>
          )}
          <Element tag='h6' rules={() => ({flex: 1})}>
            {episode.title}
          </Element>

          <Stat
            value='✅'
            title='analytics prefix'
            show={!!episode.enclosure.prefixPodsights}
          />
          <Stat
            value='🚫'
            title='analytics prefix'
            show={!episode.enclosure.prefixPodsights}
          />
          <Stat
            value={formatDate(episode.published, 'MM/DD/YY')}
            title='published'
          />

          <Stat
            value={toTimeString(episode.enclosure.length)}
            title='duration'
          />
          <Stat value={episode.placementsCount} title='ads' />

          {episode.enclosure && !isNone(episode.enclosure.downloads) && (
            <Stat
              value={comma(episode.enclosure.downloads)}
              title='downloads'
            />
          )}
        </Element>

        <Element
          tag='p'
          rules={() => ({
            color: '#666',
            fontSize: '13px',
            marginTop: '10px',
            marginBottom: '0',
          })}>
          {truncateWords(
            stripHTML(episode.subtitle || episode.summary || ''),
            50
          )}
        </Element>
      </Element>
    </Element>
  );
};

const Episode = ({feed, episode, placement, linkTo}) => {
  const color =
    episode.placementsCount > 0 ? GREEN : episode.hasNoAds ? DARK_GRAY : ORANGE;

  return linkTo ? (
    <CleanLink
      to={linkTo}
      css={`
        display: block;
        margin: 15px 0;
        boxshadow: 0px 0px 2px ${color};
        padding: 13px 10px 10px;
        border-radius: 5px;
        :hover {
          box-shadow: 0px 0px 4px ${color};
        }
      `}>
      <EpisodeContent feed={feed} episode={episode} placement={placement} />
    </CleanLink>
  ) : (
    <div
      css={`
        display: block;
        margin: 15px 0;
        boxshadow: 0px 0px 2px ${color};
        padding: 13px 10px 10px;
        border-radius: 5px;
        :hover {
          box-shadow: 0px 0px 4px ${color};
        }
      `}>
      <EpisodeContent feed={feed} episode={episode} placement={placement} />
    </div>
  );
};

export default Episode;
