import {useEffect} from 'react';
import LogRocket from 'logrocket';
import {isProd} from '@core/lib/environment';
import {Card} from '@core/ui/Content';
import ErrorBase from './ErrorBase';

interface Props {
  error: Error;
  user: {
    isAdmin?: boolean;
    email: string;
  };
}

const AppError = ({error, user}: Props) => {
  useEffect(() => {
    if (!error) return;

    // Only console log errors for admin users
    if (user?.isAdmin) {
      return LogRocket.error(error);
    }

    // Only capture errors in production (for non-admin users)
    if (!isProd) return;
    // Capture error without blocking the UI thread
    const errorTimeout = setTimeout(() => LogRocket.captureException(error), 0);

    return () => clearTimeout(errorTimeout);
  }, [error, user]);

  return (
    <ErrorBase title='Something went wrong'>
      <p>
        Our team has been notified and is working on the issue. Please reach out
        to{' '}
        <a
          css={{color: 'var(--red-orange)'}}
          href='mailto:support-adanalytics@spotify.com'>
          support-adanalytics@spotify.com
        </a>{' '}
        for further help.
      </p>
      {user?.isAdmin && (
        <>
          <div
            css={`
              color: var(--text-muted);
              font-size: var(--font-size-h3);
              font-weight: var(--font-weight-bold);
              margin-bottom: var(--spacing-2);
              margin-left: var(--spacing-6);
              margin-top: var(--spacing-3);
              text-align: left;
            `}>
            Debugger (Admin only)
          </div>
          <Card rules={() => ({textAlign: 'left'})}>{error.toString()}</Card>
        </>
      )}
    </ErrorBase>
  );
};

export default AppError;
