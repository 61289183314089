import {gql} from '@apollo/client';

export default gql`
  query getOrganizationBackfill(
    $id: ID!
    $after: String!
    $before: String!
    $isMarketing: Boolean
  ) {
    admin {
      management {
        attribution {
          missing(organizationId: $id, after: $after, before: $before) {
            after
            before
          }
        }
      }

      organization(id: $id) {
        id
        name
        campaigns(query: {isMarketing: $isMarketing}) {
          results {
            id
            name
            lastBuilt

            attribution(after: $after, before: $before) {
              events(action: "init") {
                hourly {
                  hour
                  action
                  count
                }
              }
            }

            downloads(after: $after, before: $before) {
              count
              hourly(kind: "good") {
                hour
                downloadClass
                count
              }
            }
          }
        }
      }
    }
  }
`;
