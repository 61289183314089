import React from 'react';
import styled from 'styled-components';
import {Card} from '../Content';

export interface IDetailsCardProps
  extends React.HTMLAttributes<HTMLDivElement> {
  data: Array<{
    title: string;
    value: any;
  }>;
  inverse?: boolean;
  PageTitle?: string;
}

export const DetailsCard = ({
  children,
  data = [],
  PageTitle,
  ...props
}: IDetailsCardProps) => (
  <StyledCard {...props}>
    {PageTitle && <StyledTitle>{PageTitle}</StyledTitle>}
    <StyledTable>
      <tbody>
        {data.map(({title, value}) => (
          <tr key={title}>
            <td>{title}</td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
    {children}
  </StyledCard>
);

const StyledCard = styled(Card)<{inverse?: boolean}>`
  background: ${(p) => (!p.inverse ? 'var(--bg-muted)' : undefined)};
  margin: 0;
`;

const StyledTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
`;

const StyledTable = styled.table`
  font-size: 0.875rem;
  line-height: 1.4;
  & tr td {
    vertical-align: top;
    padding: 0.3125rem 0;
  }
  & tr td:first-child {
    color: var(--text-muted);
    padding-right: 0.625rem;
    white-space: nowrap;
  }
`;
