import {Dispatch, SetStateAction} from 'react';
import {FetchedAdminCampaign} from '@admin/graphql-api';
import {ROWS} from './rows';

export type CampaignDetailsProps = {
  campaign: FetchedAdminCampaign;
  onCampaignError: (_: string) => void;
  setUpdatingCampaign: Dispatch<SetStateAction<boolean>>;
};

export function CampaignDetails(props: CampaignDetailsProps) {
  return (
    <table
      css={{
        fontSize: 'var(--font-size-body-3)',
        '& td': {
          alignItems: 'center',
          display: 'inline-table',
          minHeight: '2.5rem',
        },
        '& td:first-child': {
          color: 'var(--text-subtle)',
          lineHeight: '1.3',
          paddingRight: '1rem',
          width: '13.5rem',
        },
      }}>
      <tbody>
        {ROWS.map(({title, Component}) => (
          <tr key={title}>
            <td>{title}</td>
            <td>
              <Component {...props} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
