import {useState} from 'react';
import {AddAccessInput, useAddAccessMutation} from '@admin/graphql-api';
import {
  Banner,
  ButtonPrimary,
  ButtonTertiary,
  Type,
} from '@spotify-internal/encore-web';
import {t} from '@core/texts';
import {Stack} from '@core/ui/Layout';
import {Overlay} from '@core/ui/Overlay';
import Select from '@core/ui/Select';
import {CampaignDetailsProps} from '.';

type Access = (typeof ACCESS_ITEMS)[number];

const ACCESS_ITEMS = [
  {
    title: `Give publisher access to brand's pixel data.`,
    value: 'can-view-brand',
  },
  {
    title: `Give brand access to publisher's IP data.`,
    value: 'share-ip',
  },
];

export function CampaignGrantAccess({
  campaign,
  setUpdatingCampaign,
}: CampaignDetailsProps) {
  const [addAccess] = useAddAccessMutation();
  const [error, setError] = useState<string>();
  const [reset, setReset] = useState(0);
  const [selectedAccess, setSelectedAccess] = useState<Access>();

  const items = ACCESS_ITEMS.filter(
    ({value}) =>
      (campaign.permissions ?? []).filter(
        (p) => p?.name === 'publisher:campaign' && p?.permission === value
      ).length === 0
  );

  if (!items.length) {
    return <>No actions available</>;
  }

  const onSubmit = async () => {
    if (error) setError(undefined);
    setUpdatingCampaign?.(true);

    try {
      if (!selectedAccess) {
        throw new Error('Please select access type');
      }

      await addAccess({
        variables: {
          input: {
            organizationId: campaign.organization?.id,
            name: 'publisher:campaign',
            permission: selectedAccess.value.toLowerCase(),
            objectPk: campaign.id,
            contentType: 'campaigns.campaign',
          } as AddAccessInput,
        },
      });

      setSelectedAccess(undefined);
    } catch (e) {
      setError(t('errors.network'));
    } finally {
      setUpdatingCampaign?.(false);
    }
  };

  return (
    <>
      <Select
        key={reset}
        defaultValue={selectedAccess?.value}
        items={items}
        onSelect={setSelectedAccess}
        rules={() => ({marginBottom: '0.5rem'})}
        small
      />
      {selectedAccess && (
        <Overlay
          opened
          centered
          withBackdrop
          css={`
            padding: 1.75rem;
          `}>
          {error && <Banner colorSet='negative'>{error}</Banner>}
          Are you sure you would like to{' '}
          <Type weight='bold'>{selectedAccess?.title.replace(/\./g, '')}</Type>?
          <Stack css={{marginTop: '1rem'}} gap={2} justifyContent='flex-end'>
            <ButtonTertiary
              onClick={() => {
                setSelectedAccess(undefined);
                setReset(reset + 1);
              }}
              size='small'>
              Cancel
            </ButtonTertiary>
            <ButtonPrimary onClick={onSubmit} size='small'>
              Confirm
            </ButtonPrimary>
          </Stack>
        </Overlay>
      )}
    </>
  );
}
