import {OrganizationKind} from '@core/graphql-api';

const ORG_KIND_TO_PATH_PART_MAP = {
  SELL_SIDE: 's',
  BUY_SIDE: 'a',
  BRAND: 'b',
  PODCAST: 'p',
};

/**
 * Maps the `kind` field of an `Organization` to a URL path part.
 *
 * @param kind - The `kind` field of an `Organization` object.
 * @returns The URL path part corresponding to the `kind` of the organization.
 */
export const mapOrgKindToPathPart = ({kind}: {kind: OrganizationKind}) => {
  return (
    ORG_KIND_TO_PATH_PART_MAP[kind.toUpperCase()] || ('_unknown_' as never)
  );
};

export const isOrgBrand = ({kind}: {kind: OrganizationKind}) =>
  kind === OrganizationKind.Brand;

export const isOrgPodcast = ({kind}: {kind: OrganizationKind}) =>
  kind === OrganizationKind.Podcast;

export const isOrgSellSide = ({kind}: {kind: OrganizationKind}) =>
  kind === OrganizationKind.SellSide;

export const isOrgBuySide = ({kind}: {kind: OrganizationKind}) =>
  kind === OrganizationKind.BuySide;

export const isOrgAgency = ({kind}: {kind: OrganizationKind}) =>
  isOrgSellSide({kind}) || isOrgBuySide({kind});

export const isSelling = ({kind}: {kind: OrganizationKind}) =>
  isOrgSellSide({kind}) || isOrgPodcast({kind});

export const isBuying = ({kind}: {kind: OrganizationKind}) =>
  isOrgBuySide({kind}) || isOrgBrand({kind});

/**
 * Create a path to an organization based on its kind and slug
 * @param {object} organization - An object that contains the organization's kind and slug
 * @param {string} path - An optional additional path to add to the organization's base path
 * @return {string} A URL string to the organization with an optional additional path
 */
export const createOrgPath = (
  {kind, slug}: {kind: OrganizationKind; slug: string},
  path?: string
): string => {
  // Create the base URL using the mapped path part and the organization's slug
  const base = `/${mapOrgKindToPathPart({kind})}/${slug}`;

  if (path) {
    // Ensure the additional path starts with a '/'
    const startingSlash = path.charAt(0) === '/' ? '' : '/';

    return `${base}${startingSlash}${path}`;
  }
  // If there's no additional path, return the base URL
  return base;
};

/**
 * @deprecated
 */
export const organizationCanViewBrandData = (
  {kind}: {kind: OrganizationKind},
  campaign: any
): boolean => isBuying({kind}) || campaign.pixels.length > 0;
