import {css} from '../../style';
import DataSelector, {DataSelectorProps} from '../../ui/DataSelector';
import FeedSearchQuery from './queries/_FeedSearchQuery';

const SelectOption = ({title, href}: {title: string; href: string}) => (
  <>
    {title} <span css={{color: 'var(--text-muted)'}}>({href})</span>
  </>
);

const FeedSelector = ({
  queryVariables,
  ...props
}: Partial<DataSelectorProps>) => (
  <DataSelector
    dataQuery={FeedSearchQuery}
    queryVariables={queryVariables}
    renderItem={(item) => <SelectOption {...item} />}
    fromResponse={(data) => data.admin.feedSearch}
    propertyForName='title'
    {...props}
  />
);

export default FeedSelector;
