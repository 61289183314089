// Read: See https://github.com/microsoft/TypeScript/issues/50058#issuecomment-1404411380
// Workaround: https://github.com/styled-components/styled-components/issues/3437#issuecomment-1103085056

// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';

// eslint-disable-next-line no-restricted-imports
export * from 'styled-components';

// @ts-ignore
export default typeof styled.div === 'function' ? styled :
// @ts-ignore
styled["default"];