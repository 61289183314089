/**
 * Encore Foundation - 5.23.1
 *
 * The contents of this file is owned and generated by the Encore team.
 * If you need to make changes to the values stored here please reach out
 * to #encore on Slack.
 *
 * Links:
 *
 * - Source & Documentation: https://ghe.spotify.net/encore/foundation
 *
 * Tack!
 *
 * Encore
 * encore.spotify.net
 */
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Body Medium instead
 */
export var mobileBallad = {
    fontSize: '1rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 400
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Body Medium instead
 */
export var mobileBalladBold = {
    fontSize: '1rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Body Small instead
 */
export var mobileViola = {
    fontSize: '0.875rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 400
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Body Small instead
 */
export var mobileViolaBold = {
    fontSize: '0.875rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Body Small instead
 */
export var mobileMesto = {
    fontSize: '0.8125rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 400
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Body Small instead
 */
export var mobileMestoBold = {
    fontSize: '0.8125rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Headline Large instead
 */
export var mobileBass = {
    fontSize: '3.5rem',
    fontFamily: 'CircularSpTitle,CircularSpTitle-Tall,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 900
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Headline Medium instead
 */
export var mobileForte = {
    fontSize: '3rem',
    fontFamily: 'CircularSpTitle,CircularSpTitle-Tall,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Headline Medium instead
 */
export var mobileBrio = {
    fontSize: '2.5rem',
    fontFamily: 'CircularSpTitle,CircularSpTitle-Tall,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Marginal instead
 */
export var mobileFinale = {
    fontSize: '0.6875rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 400
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Marginal instead
 */
export var mobileFinaleBold = {
    fontSize: '0.6875rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated In order to better support non-latin languages, the all-caps type styles have been deprecated. You can learn more about the reasons and replacements on http://go/all-caps-deprecation
 */
export var mobileMinuet = {
    fontSize: '0.625rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 400,
    letterSpacing: '0.1em',
    textTransform: 'uppercase'
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated In order to better support non-latin languages, the all-caps type styles have been deprecated. You can learn more about the reasons and replacements on http://go/all-caps-deprecation
 */
export var mobileMinuetBold = {
    fontSize: '0.625rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700,
    letterSpacing: '0.1em',
    textTransform: 'uppercase'
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated In order to better support non-latin languages, the all-caps type styles have been deprecated. You can learn more about the reasons and replacements on http://go/all-caps-deprecation
 */
export var mobileMetronome = {
    fontSize: '0.875rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700,
    letterSpacing: '0.1em',
    textTransform: 'uppercase'
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Title Large instead
 */
export var mobileAltoBrio = {
    fontSize: '2rem',
    fontFamily: 'CircularSpTitle,CircularSpTitle-Tall,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Title Large instead
 */
export var mobileAlto = {
    fontSize: '2rem',
    fontFamily: 'CircularSpTitle,CircularSpTitle-Tall,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Title Medium instead
 */
export var mobileCanon = {
    fontSize: '1.5rem',
    fontFamily: 'CircularSpTitle,CircularSpTitle-Tall,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Title Small instead
 */
export var mobileCelloCanon = {
    fontSize: '1.125rem',
    fontFamily: 'CircularSpTitle,CircularSpTitle-Tall,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Title Small instead
 */
export var mobileCello = {
    fontSize: '1.125rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
