import React from 'react';
import AddFeed from '@admin/graphql-api/_old_mutations/AddFeed';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import {Input, Label} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';

class FeedAdd extends React.Component {
  state = {
    loading: false,
    redirectTo: null,
    href: '',
    measure: false,
  };

  onChange = ({target: {value}}) => {
    this.setState({
      href: value,
    });
  };

  onMeasureChange = () => {
    this.setState({
      measure: !this.state.measure,
    });
  };

  onSubmit = (evt) => {
    evt.preventDefault();
    const {addFeed} = this.props;
    const {href, measure} = this.state;

    if (!href) {
      return;
    }
    this.setState({
      loading: true,
    });

    addFeed({href, measure}).then((resp) => {
      this.setState({
        loading: false,
        redirectTo: resp.data.addFeed.feed.id,
      });
    });
  };

  render() {
    const {loading, redirectTo, href, measure} = this.state;

    if (redirectTo) {
      return <Redirect to={`/feeds/${redirectTo}`} />;
    }

    return (
      <ResponsiveContent containerSize='medium'>
        <CardHeader>Add Feed</CardHeader>
        <Card>
          <form onSubmit={this.onSubmit}>
            <Label>Feed URL</Label>
            <Input
              type='text'
              value={href}
              disabled={loading}
              onChange={this.onChange}
              placeholder='RSS or Itunes URL'
              withMargin
            />

            <Label>Measure</Label>
            <Label rules={() => ({display: 'flex'})}>
              <Element
                tag='input'
                type='checkbox'
                defaultChecked={measure}
                disabled={loading}
                onChange={this.onMeasureChange}
              />
              <Element
                tag='span'
                rules={({theme}) => ({
                  color: theme.textTertiary,
                  marginLeft: '0.625rem',
                })}>
                Do you want to pull ads from this feed forever?
              </Element>
            </Label>
            <Element rules={() => ({padding: '1rem 0', textAlign: 'right'})}>
              <Submit value='Add Feed' disabled={loading} color='primary' />
            </Element>
          </form>
        </Card>
      </ResponsiveContent>
    );
  }
}

export default graphql(AddFeed, {
  props: ({ownProps: {episode}, mutate}) => ({
    addFeed: (input) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
  }),
})(FeedAdd);
