import React from 'react';
import DataTable from '@core/ui/DataTable';

const columns = [
  {
    title: 'Name',
    accessor: 'name',
    type: 'string',
  },
  {
    title: 'Id',
    accessor: 'id',
    type: 'string',
  },
];

const OrganizationCompaniesTable = ({organization}) => {
  return (
    <DataTable
      searchKeys={['name', 'id']}
      data={organization.companies}
      columns={columns}
      orderBy='-name'
      emptyMessageText='No companies found.'
      onClickRowPath={({id}) => `/companies/${id}`}
    />
  );
};

export default OrganizationCompaniesTable;
