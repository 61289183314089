import React, {FC} from 'react';
import {TRules, css} from '@core/style';

interface DummyProps extends React.HTMLProps<HTMLDivElement> {
  rules?: TRules;
  width?: number;
}

const Dummy: FC<DummyProps> = ({rules, width = 72, ...props}): JSX.Element => (
  <div
    {...css([
      () => ({
        background: 'var(--bg-surface-hover)',
        borderRadius: '.5rem',
        flexShrink: 0,
        height: `${width}px`,
        width: `${width}px`,
      }),
      rules,
    ])}
    {...props}
  />
);

export default Dummy;
