import { ButtonTertiary } from "../ButtonTertiary/index.js";
import { IconX } from "../Icon/icons/IconX/index.js";
import styled from "../../styled-components.js";
export var CloseButton = styled(ButtonTertiary).attrs(function (_ref) {
  var _ref$closeButtonAriaL = _ref.closeButtonAriaLabel,
    closeButtonAriaLabel = _ref$closeButtonAriaL === void 0 ? 'Close' : _ref$closeButtonAriaL,
    onClose = _ref.onClose;
  return {
    'aria-label': closeButtonAriaLabel,
    onClick: onClose,
    iconOnly: IconX,
    type: 'button'
  };
}).withConfig({
  displayName: "CloseButton",
  componentId: "sc-1n0japy-0"
})(["position:absolute;right:0;top:0;[dir='rtl'] &{right:unset;left:0;}"]);