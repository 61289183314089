import {FC} from 'react';
import {Icon} from '../Icon';

interface DataTablePaginationProps {
  count: number;
  onRowsPerPageChange: (_value: number) => void;
  onRowStartChange: (_value: number) => void;
  paginationRowsPerPage: number;
  paginationRowsPerPageOptions: number[];
  rowsPerPage: number;
  rowStart: number;
}

const DataTablePagination: FC<DataTablePaginationProps> = ({
  count,
  onRowsPerPageChange,
  onRowStartChange,
  paginationRowsPerPage,
  paginationRowsPerPageOptions,
  rowsPerPage,
  rowStart,
}): JSX.Element => {
  if (rowsPerPage > count && rowsPerPage === paginationRowsPerPage) {
    return <div />;
  }
  const rightDisabled = rowStart + rowsPerPage >= count;
  const leftDisabled = rowStart === 0;
  const to = rowStart + rowsPerPage > count ? count : rowStart + rowsPerPage;

  const onArrowClick = (direction: 'left' | 'right') => {
    if (direction === 'left') {
      onRowStartChange(Math.max(0, rowStart - rowsPerPage));
    } else if (direction === 'right') {
      const newRowStart = rowStart + rowsPerPage;
      onRowStartChange(newRowStart > count ? rowsPerPage : newRowStart);
    }
  };

  return (
    <div
      css={{
        alignItems: 'center',
        color: 'var(--text-muted)',
        display: 'flex',
        fontSize: '0.8125rem',
        justifyContent: 'flex-end',
        padding: '.5rem 0.625rem',
        userSelect: 'none',
      }}>
      <div css={{marginRight: '4rem'}}>
        Rows per page:{' '}
        <select
          value={rowsPerPage}
          css={{
            '-webkit-appearance': 'menulist',
            appearance: 'menulist',
            borderRadius: '.5rem',
            color: 'inherit',
            fontFamily: 'inherit',
            height: '1.625rem',
            padding: '0 0.375rem',
          }}
          onChange={(evt) => {
            onRowStartChange(0);
            onRowsPerPageChange(parseInt(evt.target.value));
          }}>
          {paginationRowsPerPageOptions.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
      <div css={{marginRight: '1.5rem'}}>
        {to > 0 ? rowStart + 1 : 0} to {to} of {count}
      </div>
      <div css={{display: 'flex', alignItems: 'center'}}>
        <Icon
          icon='arrow-right'
          onClick={() => onArrowClick('left')}
          rules={() => ({
            color: 'var(--text-muted)',
            cursor: 'pointer',
            height: '1.125rem',
            marginRight: '1.5rem',
            opacity: leftDisabled && 0.2,
            pointerEvents: leftDisabled && 'none',
            transform: 'rotate(180deg)',
            width: '1.125rem',
            ':hover': {
              color: 'var(--blue)',
            },
          })}
        />
        <Icon
          icon='arrow-right'
          onClick={() => onArrowClick('right')}
          rules={() => ({
            color: 'var(--text-muted)',
            cursor: 'pointer',
            height: '1.125rem',
            opacity: rightDisabled && 0.2,
            pointerEvents: rightDisabled && 'none',
            width: '1.125rem',
            ':hover': {
              color: 'var(--blue)',
            },
          })}
        />
      </div>
    </div>
  );
};

export default DataTablePagination;
