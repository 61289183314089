import {gql} from '@apollo/client';

export default gql`
  mutation removeAccess($input: RemoveAccessInput!) {
    authed {
      success
    }
    removeAccess(input: $input) {
      success
    }
  }
`;
