import React from 'react';
import {
  Stack as MuiStack,
  type StackProps as MuiStackProps,
  ThemeOptions,
} from '@mui/material';

export type StackProps = Pick<
  MuiStackProps,
  | 'alignItems'
  | 'children'
  | 'direction'
  | 'divider'
  | 'gap'
  | 'justifyContent'
  | 'spacing'
> & {component?: React.ElementType};

export function Stack(props: StackProps) {
  return <MuiStack {...props} />;
}

export const MuiStackStyles: ThemeOptions['components'] = {
  MuiStack: {
    defaultProps: {
      direction: 'row',
    },
  },
};
