import React from 'react';
import {
  IconChevronDown,
  ProgressCircle,
  cssSpacing,
} from '@spotify-internal/encore-web';

export interface DataTableFilterButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  domRef?: React.RefObject<HTMLButtonElement>;
  isLoading?: boolean;
  noArrowIcon?: boolean;
}

export default function DataTableFilterButton({
  active = false,
  children,
  domRef,
  isLoading = false,
  noArrowIcon = false,
  ...props
}: DataTableFilterButtonProps) {
  return (
    <button
      ref={domRef}
      type='button'
      css={`
        align-items: center;
        background: var(--bg-default);
        border-radius: var(--input-border-radius-md);
        border: var(--input-border);
        color: var(--text-muted);
        display: flex;
        font-size: 1rem;
        gap: ${cssSpacing('tighter-2')};
        height: var(--input-height-md);
        opacity: ${props.disabled ? 0.5 : ''};
        padding: 0 0.75rem;
        pointer-events: ${props.disabled ? 'none' : ''};
        :hover {
          color: var(--black);
        }
        :hover:not([disabled]) svg {
          color: var(--black);
        }
        :focus {
          border-color: var(--black);
          box-shadow: 0 0 0 1px var(--black);
        }
        ${active &&
        `
        color: 'var(--text-muted)',
        fontWeight: 500,
      `}
      `}
      {...props}>
      {children}
      {isLoading ? (
        <ProgressCircle aria-label='loading filter options' size='small' />
      ) : !noArrowIcon ? (
        <IconChevronDown size='small' />
      ) : null}
    </button>
  );
}
