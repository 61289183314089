/**
 * An error thrown by the XResolve EndpointsProvider function.
 */
export class XResolveError extends Error {
  /**
   * The code for this error.
   *
   * This value should be compared to the `TransportError` enum.
   */
  code?: string;

  /**
   * If the error happened as part of a request, this field will contain the
   * status code of the response. Otherwise, it'll be set to -1.
   */
  status: number;

  constructor(code: string, message: string, status: number = -1) {
    super(message);
    this.name = 'XResolveError';
    this.message = message;
    this.code = code;
    this.status = status;
  }
}
