import {gql} from '@apollo/client';

export default gql`
  mutation updateCompany($input: UpdateCompanyInput!) {
    authed {
      success
    }
    updateCompany(input: $input) {
      company {
        id
        name
        slug
        url
        description
        createdAt

        image {
          id
          width
          height
          src
          contentType
        }
      }
    }
  }
`;
