export const getLocationSearch = (): string =>
  /^\??(.*)/.exec(window.location.search)[1];

export const searchParamsToObject = (entries): {[key: string]: string} => {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};
export const getQueryParams = () =>
  searchParamsToObject(new URLSearchParams(window.location.search));

export const encodeQueryParams = (params) =>
  Object.keys(params)
    .filter((key) => params[key] !== null)
    .sort()
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join('&');

export const replaceState = (params) => {
  const newParms = encodeQueryParams(params);
  if (getLocationSearch() != newParms) {
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${newParms}`
    );
  }
};
