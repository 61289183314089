import {useAdminCompanyQuery} from '@admin/graphql-api';
import {Redirect, Switch} from 'react-router-dom';
import {Button} from '@core/ui/Button';
import {DropdownMenu} from '@core/ui/DropdownMenu';
import {Link} from '@core/ui/Link';
import Loading from '@core/ui/Loading';
import Company from './Company';
import CompanyArchive from './CompanyArchive';
import CompanyDelete from './CompanyDelete';
import CompanyDetail from './CompanyDetail';
import CompanyImage from './CompanyImage';
import CompanyMerge from './CompanyMerge';
import Tag from './CompanyTag';
import CompanyTags from './CompanyTags';
import CompanyUpdate from './CompanyUpdate';
import {useCompanyRoutes} from './_routes';
import ProductIndex from './product';

interface Props {
  match: {
    params: {
      companyId: string;
    };
  };
}

export default function CompanyIndex({
  match: {
    params: {companyId},
  },
}: Props) {
  const {data, loading, error} = useAdminCompanyQuery({
    variables: {
      id: companyId,
    },
  });

  if (loading) return <Loading absolute />;
  if (error || !data?.admin?.company) return <Redirect to='/companies' />;

  const {company} = data.admin;
  const {renderRoutes} = useCompanyRoutes({company});

  return (
    <div>
      <div css={{margin: '1rem 1.5rem'}}>
        <div
          css={{alignItems: 'center', display: 'flex', marginBottom: '1.5rem'}}>
          {company.image ? (
            <div css={{flex: '0 0 6.25rem'}}>
              <img src={company.image.src} />
            </div>
          ) : (
            <Link
              to={`/companies/${company.id}/image`}
              css={`
                align-items: center;
                background: var(--bg-subtle);
                border-radius: 0.5rem;
                display: flex;
                height: 6.25rem;
                justify-content: center;
                text-align: center;
                width: 6.25rem;
              `}>
              No Image
            </Link>
          )}
          <div css={{margin: '0 1rem', color: 'var(--text-placeholder)'}}>
            <div
              css={{
                color: 'var(--text-default)',
                fontSize: '1.25rem',
                fontWeight: 500,
                marginBottom: '0.375rem',
                textTransform: 'none',
              }}>
              <Link to={`/companies/${company.id}`}>{company.name}</Link>
            </div>
            <div>{company.url}</div>
            {company.description ? (
              <div css={{marginTop: '0.5rem'}}>{company.description}</div>
            ) : null}
            {company.tags.length ? (
              <div>
                {company.tags.map((tag) => (
                  <Tag tag={tag} key={tag.id} />
                ))}
              </div>
            ) : null}
          </div>
          <div>
            <DropdownMenu
              items={[
                {title: 'Update', to: `/companies/${company.id}/update`},
                {title: 'Merge', to: `/companies/${company.id}/merge`},
                {title: 'Add Image', to: `/companies/${company.id}/image`},
                {title: 'Manage Tags', to: `/companies/${company.id}/tags`},
                ...(!company.archived
                  ? [
                      {
                        title: 'Archive',
                        to: `/companies/${company.id}/archive`,
                      },
                    ]
                  : []),
                {title: 'Delete', to: `/companies/${company.id}/delete`},
              ]}>
              <Button iconLeading='manage-outline' variant='outlined'>
                Manage Company
              </Button>
            </DropdownMenu>
          </div>
        </div>
      </div>
      <div>
        <Switch>
          {renderRoutes({
            components: {
              company: Company,
              detail: CompanyDetail,
              update: CompanyUpdate,
              merge: CompanyMerge,
              image: CompanyImage,
              tags: CompanyTags,
              delete: CompanyDelete,
              archive: CompanyArchive,
              product: ProductIndex,
            },
            extraProps: {
              company,
            },
            redirects: [{from: '*', to: ''}],
          })}
        </Switch>
      </div>
    </div>
  );
}
