import {CounterSettings, Curve, Duration} from './typedefs';

const MAX_SAFE_BACKOFF = 4294967296;
/**
 * Takes a number and returns it with +/-10% jitter.
 *
 * @param n - The value to apply jitter to.
 * @returns The jitter amount.
 */
function jitter(n: number): number {
  const diff = n / 5;
  return Math.floor(n - diff / 2 + Math.random() * diff);
}

const counterDefaults = {
  baseTime: 200,
  ceiling: 0,
  curve: 'linear' as Curve,
  jitter: true,
};

export class Counter {
  /**
   * (Short) name of curve to use to calculate the backoff curve. Currently:
   */
  private _curve: Curve;

  /**
   * The minimum/base interval.
   */
  private _baseTime: Duration;

  /**
   * The ceiling property defines a max duration that does not terminate the backoff.
   *
   * Once this value is reached the backing off stops but the retrying continues
   * with this value used as time between calls.
   */
  private _ceiling: Duration;

  /**
   * Flag for determining if the counter should apply some jitter to the return
   * value in `getTime`. Defaults to `true`.
   */
  private _jitter: boolean;

  /**
   * @param options - Settings for the counter instance.
   */
  constructor(options: CounterSettings = {}) {
    this._curve = options.curve || counterDefaults.curve;
    this._baseTime = options.baseTime || counterDefaults.baseTime;
    this._ceiling = options.ceiling || counterDefaults.ceiling;
    this._jitter =
      'jitter' in options ? !!options.jitter : counterDefaults.jitter;
  }

  getTime(retries: number): Duration {
    let time;
    switch (this._curve) {
      case 'static':
        time = 1;
        break;
      case 'logarithmic':
        time = Math.log(retries);
        break;
      case 'exponential':
        time = Math.pow(Math.E, retries);
        break;
      case 'linear':
      default:
        time = retries + 1;
        break;
    }
    let ret = Math.min(MAX_SAFE_BACKOFF, Math.floor(time * this._baseTime));
    if (this._ceiling) {
      ret = Math.min(ret, this._ceiling);
    }
    return this._jitter ? jitter(ret) : ret;
  }
}
