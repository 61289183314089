import {gql} from '@apollo/client';

export default gql`
  query getFeeds(
    $query: String
    $order: String
    $offset: Int
    $limit: Int
    $state: String
  ) {
    admin {
      feeds(
        offset: $offset
        order: $order
        limit: $limit
        query: $query
        state: $state
      ) {
        id
        title
        subtitle
        summary
        image {
          id
          href
          path
        }
        processing
        hasNoAds
        measure
        state
        createdAt

        episodesCount
        placementsCount
        processedEpisodesCount
      }
    }
  }
`;
