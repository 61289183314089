import {useLayoutEffect, useMemo, useRef, useState} from 'react';
import {
  IconChevronDown,
  ProgressCircle,
  cssSpacing,
} from '@spotify-internal/encore-web';
import styled from 'styled-components';
import {FelaElement, Theme, css, useTheme} from '@core/style';
import {Overlay, useOverlay} from '../Overlay';
import {MultipleSelectProps} from './';
import {_MultipleSelectInputContent} from './_MultipleSelectInputContent';
import {_MultipleSelectOverlayContent} from './_MultipleSelectOverlayContent';
import {getDefaultSelectedItems} from './_helpers';

export default function MultipleSelect({
  buttonProps = {},
  defaultValue = [],
  disabled = false,
  emptyText = 'Nothing to select',
  getSelectLabel,
  invalid = false,
  isLoading = false,
  items,
  noChevronIcon = false,
  onSelect,
  OptionContent,
  outline = false,
  overlayProps,
  placeholder,
  propertyForDescription = 'description',
  propertyForName = 'title',
  propertyForValue = 'value',
  removeOption,
  removeOptionLabel = '-',
  rules,
  searchable = false,
  searchKeys = [],
  singleSelection = false,
  small = false,
}: MultipleSelectProps) {
  const ref = useRef<HTMLElement>(null);
  const theme = useTheme();
  const [minWidth, setMinWidth] = useState<number>(0);
  const [opened, toggle] = useOverlay();
  const [selectedItems, _setSelectedItems] = useState<any[]>(
    getDefaultSelectedItems({
      items,
      propertyForValue,
      value: defaultValue,
    })
  );

  const setSelectedItems = (arr: any[]) => {
    if (typeof onSelect === 'function') {
      onSelect(arr);
    }
    _setSelectedItems(arr);
  };

  useLayoutEffect(() => {
    if (ref.current) {
      // Set min-width to the width of the input
      setMinWidth(ref.current.getBoundingClientRect().width);
    }
  }, [opened]);

  const allItems = useMemo(
    () =>
      removeOption
        ? [
            {[propertyForName]: removeOptionLabel, __removeSelected__: true},
            ...items,
          ]
        : items,
    [items, propertyForName, removeOption, removeOptionLabel]
  );

  return (
    <>
      <_Input
        ref={ref}
        type='button'
        as='button'
        tabIndex={0}
        onClick={() => !disabled && toggle()}
        $small={small}
        $outline={outline}
        $theme={theme}
        $invalid={invalid}
        $hasSelectedItems={selectedItems.length > 0}
        $disabled={disabled}
        {...css(rules)}
        {...buttonProps}>
        <_MultipleSelectInputContent
          getSelectLabel={getSelectLabel}
          placeholder={placeholder}
          propertyForName={propertyForName}
          selectedItems={selectedItems}
          singleSelection={singleSelection}
        />
        {isLoading ? (
          <ProgressCircle aria-label='loading select options' size='small' />
        ) : !noChevronIcon ? (
          <IconChevronDown size='small' />
        ) : null}
      </_Input>
      <Overlay
        opened={opened}
        toggle={toggle}
        positionTarget={ref.current}
        withShadow
        withBackdrop
        transparentBackdrop
        animation='opacity'
        {...{
          horizontalAlign: 'right',
          verticalOffset: 2,
          ...overlayProps,
        }}
        css={`
          border: 1px solid var(--border-default);
          min-width: ${minWidth}px;
          overflow: hidden;
        `}>
        <_MultipleSelectOverlayContent
          emptyText={emptyText}
          OptionContent={OptionContent}
          items={allItems}
          propertyForDescription={propertyForDescription}
          propertyForName={propertyForName}
          propertyForValue={propertyForValue}
          searchable={searchable}
          searchKeys={searchKeys}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          singleSelection={singleSelection}
          toggle={toggle}
        />
      </Overlay>
    </>
  );
}

const _Input = styled(FelaElement)<{
  $hasSelectedItems: boolean;
  $invalid: boolean;
  $outline: boolean;
  $small: boolean;
  $disabled: boolean;
  $theme: Theme;
}>`
  align-items: center;
  background: var(--bg-default);
  border-radius: ${(p) =>
    p.$small
      ? 'var(--input-border-radius-sm)'
      : 'var(--input-border-radius-md)'};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  gap: ${cssSpacing('tighter-2')};
  line-height: 1;
  height: ${(p) =>
    p.$small ? 'var(--input-height-sm)' : 'var(--input-height-md)'};
  justify-content: space-between;
  padding: ${(p) => (p.$small ? '0 0.5rem 0 0.75rem' : '0 0.75rem 0 1rem')};
  font-size: ${(p) => (p.$small ? '0.875rem' : '15px')};
  text-align: left;
  width: auto;
  color: ${(p) =>
    p.$invalid
      ? 'var(--red-orange)'
      : p.$hasSelectedItems
      ? 'inherit'
      : 'var(--text-muted)'};
  border: ${(p) =>
    p.$invalid ? 'var(--input-border-error)' : 'var(--input-border)'};
  opacity: ${(p) => (p.$disabled ? 0.5 : '')};
  pointer-events: ${(p) => (p.$disabled ? 'none' : '')};
  :hover {
    color: var(--black);
  }
  :focus {
    border-color: var(--black);
    box-shadow: 0 0 0 1px var(--black);
  }
`;
