import {useState} from 'react';
import {
  ProcessFeedInput,
  useArchiveFeedMutation,
  useFixRedisForFeedIdMutation,
  useMeasureFeedMutation,
  useNoAdsFeedMutation,
  useProcessFeedMutation,
} from '@admin/graphql-api';
import {Snackbar} from '@spotify-internal/encore-web';
import {DropdownMenu} from '@core/ui/DropdownMenu';
import {Icon} from '@core/ui/Icon';
import {LoadingSpinner} from '@core/ui/Loading';
import {Overlay} from '@core/ui/Overlay';

export function FeedOptionsDropdown({feed}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const [processFeed] = useProcessFeedMutation();
  const [measureFeed] = useMeasureFeedMutation();
  const [archiveFeed] = useArchiveFeedMutation();
  const [noAdsFeed] = useNoAdsFeedMutation();
  const [fixRedisForFeedId] = useFixRedisForFeedIdMutation();

  const onError = (error: string) => {
    setError(error);
    setTimeout(() => setError(undefined), 3_000);
  };

  return (
    <>
      {loading && (
        <Overlay opened withBackdrop css={{background: 'none'}}>
          <LoadingSpinner fixed centered />
        </Overlay>
      )}
      <Snackbar colorSet='negative' showSnackbar={!!error}>
        {error}
      </Snackbar>
      <DropdownMenu
        items={[
          {
            title: 'Process Feed',
            disabled: feed.processing,
            callback: async () => {
              setLoading(true);
              try {
                await processFeed({
                  variables: {
                    input: {
                      feedId: feed.id,
                    } as ProcessFeedInput,
                  },
                });
              } catch (e) {
                onError(e.message ?? 'Error processing feed');
                console.warn(e);
              } finally {
                setLoading(false);
              }
            },
          },
          {
            title: 'Measure Feed',
            disabled: feed.measure,
            callback: async () => {
              setLoading(true);
              try {
                await measureFeed({
                  variables: {
                    input: {
                      feedId: feed.id,
                    },
                  },
                });
              } catch (e) {
                onError(e.message ?? 'Error measuring feed');
                console.warn(e);
              } finally {
                setLoading(false);
              }
            },
          },
          {
            title: 'Feed Has No Ads',
            disabled: feed.hasNoAds,
            callback: async () => {
              setLoading(true);
              try {
                await noAdsFeed({
                  variables: {
                    input: {
                      feedId: feed.id,
                    },
                  },
                });
              } catch (e) {
                onError(e.message ?? 'Error processing no ads feed');
                console.warn(e);
              } finally {
                setLoading(false);
              }
            },
          },
          {
            title: 'Archive Feed',
            disabled: feed.state === 'ARCHIVED',
            callback: async () => {
              setLoading(true);
              try {
                await archiveFeed({
                  variables: {
                    input: {
                      feedId: feed.id,
                    },
                  },
                });
              } catch (e) {
                onError(e.message ?? 'Error archiving feed');
                console.warn(e);
              } finally {
                setLoading(false);
              }
            },
          },
          {
            title: 'Fix Redis Cache',
            callback: async () => {
              setLoading(true);
              try {
                await fixRedisForFeedId({
                  variables: {
                    input: {
                      feedId: feed.id,
                    },
                  },
                });
              } catch (e) {
                onError(e.message ?? 'Error fixing Redis cache for feed');
                console.warn(e);
              } finally {
                setLoading(false);
              }
            },
          },
        ]}
        css={`
          background: var(--bg-muted);
          border-radius: 0.5rem;
          color: var(icon-1);
          margin: 0 0.5rem;
          padding: 0.375rem;
          transform: rotate(90deg);
          transition: background 0.2s linear;
          :hover {
            background: var(--bg-default-hover);
            color: var(--blue);
          }
          :active {
            boxshadow: var(--shadow-focus);
          }
        `}>
        <Icon icon='more' />
      </DropdownMenu>
    </>
  );
}
