import {PropsWithChildren, ReactNode, useRef} from 'react';
import styled from 'styled-components';
import {TRules} from '@core/style';
import {FelaElement} from '@core/style';
import {Overlay, OverlayProps, useOverlay} from '../Overlay';

export type TooltipProps = PropsWithChildren<{
  active?: boolean;
  description?: ReactNode | string;
  overlayProps?: OverlayProps;
  /** @deprecated */
  rules?: TRules;
  title?: string;
  className?: string;
}>;

export function Tooltip({
  active = true,
  children,
  description,
  overlayProps = {horizontalAlign: 'right', verticalOffset: 2},
  title,
  ...props
}: TooltipProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [opened, toggle] = useOverlay();

  return (
    <>
      <_ChildContainer
        ref={ref}
        tabIndex={-1}
        aria-hidden={opened}
        onBlur={() => toggle(false)}
        onMouseDown={() => toggle(false)}
        onMouseEnter={() => {
          if (active && (title || description)) {
            ref.current?.focus();
            toggle(true);
          }
        }}
        onMouseLeave={() => toggle(false)}
        {...props}>
        {children}
      </_ChildContainer>
      {opened && (
        <Overlay
          role='tooltip'
          aria-live='polite'
          opened={opened}
          toggle={toggle}
          closeOnOutsideClick={false}
          closeOnEscKey={false}
          positionTarget={ref.current}
          css={`
            background: var(--bg-tooltip);
            border-radius: 0.5rem;
            border: 0;
            box-shadow: var(--shadow-lg);
            color: var(--text-tooltip);
            display: block;
            font-size: 0.8125rem;
            font-weight: 400;
            line-height: 1.4;
            max-width: 13.75rem;
            min-width: auto;
            padding: 0.5rem 0.75rem;
            pointer-events: none;
            text-align: left;
            text-transform: initial;
            width: auto;
          `}
          {...overlayProps}>
          {!!title && (
            <_TooltipTitle $hasDescription={!!description}>
              {title}
            </_TooltipTitle>
          )}
          {!!description && <div>{description}</div>}
        </Overlay>
      )}
    </>
  );
}

const _ChildContainer = styled(FelaElement)`
  outline: 0;
`;

const _TooltipTitle = styled.div<{$hasDescription: boolean}>`
  ${(p) =>
    p.$hasDescription &&
    `
      border-bottom: .125rem solid var(--text-tooltip);
      margin-bottom: .375rem;
      padding-bottom: .375rem;
    `}
`;
