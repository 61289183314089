import React from 'react';
import CreatePodcastMutation from '@admin/graphql-api/_old_mutations/CreatePodcast';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import FeedSelector from '@core/components/FeedSelector';
import {Element} from '@core/style';
import Button, {ButtonContainer} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {Label} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import Select from '@core/ui/Select';

class OrganizationCreatePodcast extends React.Component {
  constructor(props) {
    super(props);
    const {company} = props;

    this.state = {
      feedId: '',
      feedTitle: '',
      relation: null,
      loading: false,
      complete: false,
    };
  }

  onFeedChange = ({title, id}) => {
    this.setState({
      feedId: id,
      feedTitle: title,
    });
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const {createPodcast, organization} = this.props;

    const {feedId, relation} = this.state;

    if (!feedId) {
      return;
    }

    if (relation == null) {
      // eslint-disable-next-line no-alert
      alert('Relation to Organization Required');
      return;
    }

    this.setState({
      loading: true,
    });

    createPodcast({
      organizationId: organization.id,
      feedId,
      relation,
    }).then(() => {
      this.setState({
        complete: true,
      });
    });
  };

  onCancel = () => {
    this.setState({
      feedId: '',
      feedTitle: '',
    });
  };

  render() {
    const {organization} = this.props;

    const {loading, complete, feedId, feedTitle, error} = this.state;

    if (complete) {
      return <Redirect to={`/organizations/${organization.id}`} />;
    }

    return (
      <ResponsiveContent containerSize='medium'>
        <CardHeader>Create Podcast</CardHeader>
        <Card>
          <p>Link organization {organization.name} to a podcast</p>
          <form onSubmit={this.onSubmit}>
            {feedId ? (
              <div>
                <Element
                  rules={() => ({
                    margin: '1rem 0',
                    fontWeight: 500,
                  })}>
                  {feedTitle}
                </Element>

                <Select
                  items={[
                    {
                      title: 'Owner of the Podcast',
                      value: 0,
                    },
                    {
                      title: 'Secondary Owner (i.e. Sell Side Agency)',
                      value: 1,
                    },
                    {
                      title:
                        'Dynamic Insertion (Publisher does not own the podcast, but can insert ads into it.)',
                      value: 2,
                    },
                    {
                      title: 'Archived (Publisher lost the show)',
                      value: 3,
                    },
                  ]}
                  onSelect={({value}) => {
                    this.setState({
                      relation: value,
                    });
                  }}
                />

                <ButtonContainer style-alignRight>
                  <Button onClick={this.onCancel}>Cancel</Button>
                  <Submit value='Create' disabled={loading} />
                </ButtonContainer>
              </div>
            ) : (
              <div>
                <Label>Feed</Label>
                <FeedSelector
                  onSelect={this.onFeedChange}
                  queryVariables={{state: 'active'}}
                />
              </div>
            )}
          </form>
        </Card>
      </ResponsiveContent>
    );
  }
}

export default graphql(CreatePodcastMutation, {
  props: ({mutate}) => ({
    createPodcast: (input) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
  }),
})(OrganizationCreatePodcast);
