import {gql} from '@apollo/client';

export default gql`
  mutation updateProduct($input: UpdateProductInput!) {
    authed {
      success
    }
    updateProduct(input: $input) {
      product {
        id
        name
        slug
        url
        description
        createdAt

        image {
          id
          width
          height
          src
          contentType
        }
      }
    }
  }
`;
