import { Svg } from "../Icon/Svg.js";
import styled, { css, keyframes } from "../../styled-components.js";
var indeterminateAnimation = function indeterminateAnimation(circumference) {
  return keyframes(["0%{stroke-dashoffset:", ";transform:rotate(0deg);}25%{stroke-dashoffset:", ";transform:rotate(270deg);}50%,100%{stroke-dashoffset:", ";}100%{transform:rotate(360deg);}"], circumference * -0.75, circumference * -0.25, circumference * -0.75);
};
var StyledProgressCircleSvg = styled(Svg).withConfig({
  displayName: "StyledProgressCircleSvg",
  componentId: "sc-121h923-0"
})(["display:inline-block;", ""], function (_ref) {
  var $variant = _ref.$variant,
    $circumference = _ref.$circumference;
  return $variant === 'determinate' ? css(["transform:rotate(-90deg);"]) : css(["animation:", " 1s linear infinite;"], indeterminateAnimation($circumference));
});
export default StyledProgressCircleSvg;