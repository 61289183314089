import styled from 'styled-components';
import {Stack} from '../Layout';
import {Link} from '../Link';
import {Text} from '../Text';

type Props = {title?: Readonly<string | Array<{title: string; to?: string}>>};

export const _NavHeaderTitle = ({title}: Props) => {
  if (typeof title === 'string') {
    return <Text variant='h2'>{title}</Text>;
  }

  if (Array.isArray(title)) {
    return (
      <Stack alignItems='center'>
        {title.map(({title: _titlePart, to}, idx) => {
          if (to) {
            return (
              <>
                <Link key={idx} to={to} variant='h2' color='muted'>
                  {_titlePart}
                </Link>
                {idx !== title.length - 1 && <_Arrow>{'>'}</_Arrow>}
              </>
            );
          }
          return (
            <Text key={idx} variant='h2'>
              {_titlePart}
            </Text>
          );
        })}
      </Stack>
    );
  }

  return null;
};

const _Arrow = styled.div`
  color: var(--text-muted);
  font-size: var(--font-size-h2);
  padding: 0 0.625rem;
`;
