import {useMemo} from 'react';
import {toDashboardUrl} from '@admin/lib/paths';
import {IconExternalLink} from '@spotify-internal/encore-web';
import {DropdownMenu} from '@core/ui/DropdownMenu';
import {Stack} from '@core/ui/Layout';
import {ActionButton} from './styled';

const getCampaignUrl = (org, campaign, path?: string) =>
  `${toDashboardUrl(org)}/campaigns/${campaign.slug}${path || ''}`;

const EXTERNAL_ACTIONS = [
  {title: 'Edit campaign', to: '/update'},
  {title: 'Edit ads', to: '/bulk'},
  {title: 'Debugger', to: '/debug'},
];

export function CampaignToolbar({campaign}) {
  const agencies = campaign?.stateOverview?.managers ?? [];
  const publisher = campaign.organization;
  const brand = campaign.advertiser?.organization;

  return (
    <Stack css={{flexWrap: 'wrap', marginBottom: 'var(--spacing-5)'}}>
      <DropdownMenu
        items={useMemo(
          () => [
            {
              title: 'Open as Publisher',
              value: getCampaignUrl(publisher, campaign),
            },
            ...(brand
              ? [
                  {
                    title: 'Open as Brand',
                    value: getCampaignUrl(brand, campaign),
                  },
                ]
              : []),
            ...agencies.map(({name, slug}) => ({
              title: `Open as Agency (${name})`,
              value: getCampaignUrl({slug, kind: 'buy_side'}, campaign),
            })),
          ],
          [campaign]
        )}
        onSubmit={({value}) => window.open(value, '_blank')}>
        <ActionButton>Open...</ActionButton>
      </DropdownMenu>
      {EXTERNAL_ACTIONS.map(({title, to}) => (
        <ActionButton
          key={to}
          href={getCampaignUrl(publisher, campaign, to)}
          iconTrailing={IconExternalLink}>
          {title}
        </ActionButton>
      ))}
    </Stack>
  );
}
