import React, {FC, forwardRef} from 'react';
import TierBadge from '@admin/components/TierBadge';
import {useAdminOrganizationsQuery} from '@admin/graphql-api';
import dayjs from '@core/lib/dayjs';
import {Button} from '@core/ui/Button';
import {Card, PageTitle} from '@core/ui/Content';
import DataTable, {
  DataTableColumn,
  DateRangeFilter,
  MultipleSelectFilter,
} from '@core/ui/DataTable';
import EmptyMessage from '@core/ui/EmptyMessage';
import Loading from '@core/ui/Loading';

const Organizations: FC = (): JSX.Element => {
  const {data, loading, error} = useAdminOrganizationsQuery();

  if (loading) return <Loading absolute />;

  if (error)
    return (
      <>
        <PageTitle>Organizations</PageTitle>
        <Card>
          <EmptyMessage>Network error. Please try again.</EmptyMessage>
        </Card>
      </>
    );

  const columns: DataTableColumn[] = [
    {
      accessor: 'id',
      hidden: true,
    },
    {
      title: 'Name',
      accessor: 'name',
      type: 'string',
      rule: () => ({fontWeight: 500}),
    },
    {
      title: 'Slug',
      accessor: 'slug',
      type: 'string',
    },
    {
      title: 'Kind',
      accessor: 'kind',
      type: 'string',
      Filter: forwardRef(({...props}, ref) => (
        <MultipleSelectFilter ref={ref} searchable={false} {...props} />
      )),
    },
    {
      title: 'Tier',
      accessor: 'tier',
      type: 'string',
      Cell: ({data: {tier}}) => {
        return <TierBadge tier={tier} />;
      },
      Filter: forwardRef(({...props}, ref) => (
        <MultipleSelectFilter ref={ref} searchable={false} {...props} />
      )),
    },
    {
      title: 'Created',
      accessor: 'createdAt',
      type: 'date',
      Filter: forwardRef(({...props}, ref) => (
        <DateRangeFilter
          ref={ref}
          label='Created'
          startAccessor='createdAt'
          endAccessor='createdAt'
          {...props}
        />
      )),
      fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
    },
  ];

  return (
    <>
      <PageTitle
        rightContent={
          <Button color='primary' to='/organizations/new'>
            New organization
          </Button>
        }>
        Organizations
      </PageTitle>
      <Card>
        <DataTable
          columns={columns}
          data={data?.admin?.organizations ?? []}
          orderBy='-createdAt'
          searchKeys={['name', 'id']}
          searchPlaceholder='Search'
          filtersStorageKey='admin-organizations'
          onClickRowPath={({id}) => `/organizations/${id}`}
        />
      </Card>
    </>
  );
};

export default Organizations;
