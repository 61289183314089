import {gql} from '@apollo/client';

export default gql`
  mutation resetContact($input: ResetContactInput!) {
    authed {
      success
    }
    resetContact(input: $input) {
      success
    }
  }
`;
