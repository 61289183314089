import {ResponseHeaders} from '../../typedefs';

export class FetchHeaders implements ResponseHeaders {
  constructor(fetchHeaders: ResponseHeaders) {
    this.get = fetchHeaders.get.bind(fetchHeaders);
    this.has = fetchHeaders.has.bind(fetchHeaders);
  }

  get(_: string): string | null {
    return null;
  }

  has(_: string): boolean {
    return false;
  }
}
