import React, {FC, useEffect, useRef} from 'react';
import {TRules, css} from '@core/style';

/**
 * @deprecated
 */
export interface IInputProps extends React.HTMLProps<HTMLInputElement> {
  autofocus?: boolean;
  domRef?: React.RefObject<HTMLInputElement>;
  invalid?: boolean;
  rules?: TRules;
  small?: boolean;
}

/**
 * @deprecated
 */
const Input: FC<IInputProps> = ({
  autoComplete = 'off',
  autofocus = false,
  domRef,
  invalid = false,
  rules,
  small = false,
  spellCheck = 'false',
  ...props
}): JSX.Element => {
  const _ref = useRef<HTMLInputElement>(null);
  const ref = domRef ?? _ref;

  useEffect(() => {
    if (autofocus) {
      requestAnimationFrame(() => {
        ref.current?.select();
      });
    }
  }, [autofocus, ref]);

  return (
    <input
      ref={ref}
      spellCheck={spellCheck}
      autoComplete={autoComplete}
      {...css([
        () => ({
          background: 'var(--bg-default)',
          border: `.0625rem solid ${
            invalid ? 'var(--red-orange)' : 'var(--border-default)'
          }`,
          borderRadius: '.375rem',
          color: invalid ? 'var(--red-orange)' : 'var(--text-default)',
          display: 'block',
          fontSize: '1rem',
          outline: 0,
          padding: small ? '.5rem .625rem' : '.8125rem 1rem',
          width: '100%',
          '[disabled]': {
            color: 'var(--text-disabled)',
          },
          ':focus': {
            borderColor: 'var(--black)',
            boxShadow: '0 0 0 1px var(--black)',
          },
          '::placeholder': {
            color: invalid ? 'var(--red-orange)' : 'var(--text-muted)',
          },
        }),
        rules,
      ])}
      {...props}
    />
  );
};

export default Input;
