import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import PodcastIndex from './podcast';

const PodcastsIndex = ({organization}) => {
  return (
    <Switch>
      <PropsRoute
        path='/organizations/:organizationId/podcasts/:podcastId'
        organization={organization}
        component={PodcastIndex}
      />
    </Switch>
  );
};

export default PodcastsIndex;
