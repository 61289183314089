export default {
  stats: {
    adType: {
      title: 'Ad Type',
      longDescription:
        'Ad type is reflective of how the publisher defines the way the ad is being served on their end.',
    },
    aov: {
      title: 'AOV',
      longTitle: 'Average Order Value',
    },
    attribution: {
      title: 'Attribution',
      longDescription: `The number of users that visited the site or app after listening to an ad. This number can be a fraction if the user listened to multiple episodes or podcasts`,
    },
    cac: {
      title: 'CAC',
      longTitle: 'Customer Acquisition Cost',
      description: 'Cost to acquire a new customer',
    },
    cpl: {
      title: 'CPL',
      longTitle: 'Cost per Lead',
      description: 'Cost to acquire one lead event',
    },
    cpm: {
      title: ({spendByImpressionsDelivered}) =>
        spendByImpressionsDelivered ? 'CPM' : 'CPM*',
      longTitle: ({spendByImpressionsDelivered}) =>
        spendByImpressionsDelivered
          ? 'Cost per Thousand Impressions'
          : 'Cost per Thousand Impressions to date',
    },
    cpv: {
      title: 'CPV',
      longTitle: 'Cost Per Visitor',
      description: 'Cost Per Visitor',
    },
    cr: {
      title: 'CR',
      longTitle: 'Conversion Rate',
      description: 'Percent of households that visited the site or app.',
    },
    frequency: {
      title: 'Household Frequency',
      description:
        'Average number of times a household was exposed to an ad across all players',
    },
    verifiedFrequency: {
      title: 'Frequency',
      description:
        'Average number of times a unique audience member was exposed to an ad on Spotify',
    },
    impressions: {
      title: 'Impressions',
    },
    placement: {
      title: 'Roll placement',
    },
    leads: {
      title: 'Leads',
    },
    installs: {
      title: 'Installs',
    },
    pageViews: {
      title: 'Page Views',
    },
    purchases: {
      title: ({unique}) => (unique ? 'Unique Purchases' : 'Purchases'),
      longDescription: `Ad Analytics gives partial attribution for purchases meaning if a user downloaded two episodes, each gets credit for half that purchase`,
    },
    reach: {
      title: 'Household Reach',
      longTitle: 'Household Reached',
      description:
        'Number of households that downloaded an ad across all players',
    },
    verifiedReach: {
      title: 'Reach',
      description:
        'Number of unique audience members that streamed an ad on Spotify',
    },
    revenue: {
      title: 'Revenue',
    },
    roas: {
      title: 'ROAS',
      longTitle: 'Return on Advertising Spend',
      description: 'Revenue / Cost. This number will rise as the campaign runs',
    },
    spend: {
      title: ({spendByImpressionsDelivered}) =>
        spendByImpressionsDelivered ? 'Spend to Date' : 'Total Spend',
      longTitle: ({spendByImpressionsDelivered}) =>
        spendByImpressionsDelivered
          ? 'Spend by Impressions Delivered'
          : 'Total Spend',
    },
    targetCr: {
      title: 'Conversion Rate',
    },
    targetDownload: {
      title: 'Attributed Downloads',
      description: 'Number of target podcast downloads',
    },
    targetReach: {
      title: 'Attributed Reach',
      description:
        'Distinct number of households that downloaded the target podcast',
    },
    linkViewTotal: {
      title: 'Views',
      description: 'Number of times this Link was viewed.',
    },
    linkClickTotal: {
      title: 'Clicks',
      description: 'Number of times users clicked on a action on this link.',
    },
    visitors: {
      title: 'Visitors',
      description: 'Households that visited the site or app.',
      longDescription: `Ad Analytics looks at the conversion rates of non-noisy IPs, the number of users we exactly matched and other impression and pixel modeling to determine the total number of users that visited the site or app`,
    },
    cpd: {
      title: 'CPD',
      description: 'Cost per attributed download',
    },
  },
  roles: {
    admin: {
      title: 'Admin',
      description:
        'User can add other members and view this Ad Analytics account without restrictions',
    },
    member: {
      title: 'Member',
      description: 'User has view access and cannot add other members',
    },
    'ad-ops': {
      title: 'Ad Ops',
      description: 'User can create/edit/view all campaigns',
    },
    sales: {
      title: 'Sales',
      description: 'User can view all campaigns',
    },
  },
  lift: {
    requestForm: {
      header: {
        title: 'Conversion Lift Reports',
        longTitle: 'Go further on your campaign analysis.',
        longDescription: `Conversion Lift Reports help cross channel marketers understand the impact of their podcast advertising.`,
      },
      invalidDateRange: {
        longDescription:
          'Invalid date range. The selected date range must be at least 4 weeks.',
      },
      requestButton: {title: 'Send Lift Report Request'},
      submitted: {
        title: 'Request sent successfully',
        longDescription: `Thank you. Someone from the Ad Analytics team will be in contact with you shortly.`,
      },
      help: {
        header: {title: 'How does it work?'},
        steps: () => [
          {title: 'Make your report request'},
          {title: 'We get in contact with you'},
          {title: 'Get the report'},
        ],
        chatCta: {title: 'Need help?'},
        chatButton: {title: 'Chat with support'},
      },
    },
    notAvailable: {
      description: `Lift reports are only available for campaigns that have at least 250K impressions and have been running for 4 weeks.`,
    },
  },
  permissions: {
    inheritBrandAccess: {
      description: `Permission inherit by advertiser`,
    },
  },
  tags: {
    formError: ({errorMessage}) => {
      if (errorMessage.indexOf('duplicate key') > -1) {
        return {
          description:
            'Tag name already exists. Please choose a different name.',
        };
      }
      return {
        description: 'Error',
      };
    },
  },
  settings: {
    data: {
      modelled: {
        title: 'Modeled Attribution',
        longDescription: `Ad Analytics uses a modeled approach to attribution. Due to mobile and corporate networks, the cross-device graph reports back a subset of total IP space. Ad Analytics will model events to compensate. You can turn on and off this modeling here.`,
      },
      spendByImpressionsDelivered: {
        title: 'Show spend by impressions delivered',
        longDescription: `When this toggle is on, Ad Analytics measures the spend by the impressions delivered to date, using the expected CPM. When this toggle is off, Ad Analytics measures CPM, CAC, CPV, and other spend-related metrics based on the total spend.`,
      },
      impressionsByPlacements: {
        title: 'Show impressions by embedded placements',
        longDescription: `Ad Analytics can scale the number of impressions to account for multiple ad placements in an embedded buy. Turning this off will remove that scaling.`,
      },
    },
    appearance: {
      title: 'Theme',
      longDescription: `Choose how Ad Analytics looks to you. Set your theme preference to follow your system settings, or choose to use the light or dark theme.`,
    },
  },
  bulk: {
    dynamicAds: {
      title: 'Dynamic Ads',
      longDescription: `A dynamically inserted ad is added to the episode's audio at download by the hosting provider. Hosts such as Art19, acast, AdsWhizz, and Megaphone support dynamic insertion. Ad Analytics will use a Tracking URL to ingest data from these ads.`,
    },
    columns: {
      goal: {
        title: 'Goal',
        longDescription: `Of the 500,000 downloads in this campaign, how many are you expecting from this podcast?`,
      },
      cost: {
        title: 'Cost ($)',
        longDescription: `Of the cost of this campaign ($1,000.00) how much is being spent on this podcast?`,
      },
      cpm: {
        title: 'CPM',
      },
      duration: {
        title: 'Duration',
        longDescription: `In seconds, how long is the ad supposed to be?`,
      },
      placement: {
        title: 'Roll placement',
      },
      episodes: {
        title: 'Episodes',
      },
      discountCode: {
        title: 'Discount Code',
      },
      discountUrl: {
        title: 'Discount URL',
        longDescription: `Optional - a URL that redirects to the effective URL.`,
      },
      effectiveUrl: {
        title: 'Effective URL',
        longDescription: `This should be a unique URL, indicating that any visitor to this page is from your podcast audience. Any visit to the effective URL will register as attributed regardless of campaign exposure.`,
      },
      art19Secret: {
        title: 'Art19 Secret',
        longDescription: `If you are using Art19 then we need your Encryption key. The Encryption key allows Ad Analytics to decrypt the IPs that are sent to us via the Tracking URL.`,
      },
      group: {
        title: 'Group',
      },
      dynamicAdName: {
        title: 'Name',
        longDescription: `A descriptive name of the targeting. If we have multiple dynamics, we need to be able to tell them apart.`,
      },
      podcast: {
        title: 'Podcast',
      },
      streamingAdName: {
        title: 'Name',
        longDescription: `A descriptive name of the targeting. If we have multiple streaming ads, we need to be able to tell them apart.`,
      },
    },
    embeddedAds: {
      title: 'Embedded Ads',
      longDescription: `An embedded ad or live read is part of the episode's audio. It doesn't change, no matter who downloads the podcast. To measure embedded ads, you must install the Ad Analytics Analytics Prefix on your podcast.`,
    },
    streamingAds: {
      title: 'Streaming Ads',
      longDescription:
        'Streaming ads are inserted during audio content playback. Through tracking URLs, Spotify Ad Analytics processes impressions and reach based on downloads.',
    },
  },
  advisor: {
    affinityNotAvailable: {
      title: 'Affinity',
      description:
        'Affinity will become available once Ad Analytics has received enough data from your brand pixel to generate Affinity Scores tailored to your brand.',
    },
    affinityMegaphoneUprank: {
      description:
        'This show belongs to a preferred partner. Preferred partners use Spotify tools to power their podcast business.',
    },
  },
  spotify: {
    audience: {
      alert: {
        title: 'Streaming only',
        description:
          'Streaming line items report on confirmed ad impressions, reach, and frequency, along with the age, gender, device, and listening behavior.  Streaming impressions are only served for on-Spotify platform streams.',
      },
    },
    lift: {
      title: 'Spotify Brand Lift',
      description:
        'Spotify Brand Lift is an in-app survey experience that collects responses throughout a campaign to compare lift in key brand metrics',
    },
    orders: {
      table: {
        description:
          'This table combines streaming and dynamic campaigns within one sales order which were booked through your Spotify account sales team.',
      },
      parent: {
        description:
          'This page combines your streaming campaigns and dynamic campaigns within one sales order which were booked through your Spotify account sales team.  For more detail on each, please see links below.',
      },
      child: {
        description:
          'This campaign is part of a sales order booked through your Spotify account sales team. For more detail on your campaign overview across all streaming and dynamic line items, please see link below.',
      },
    },
    streaming: {
      ads: 'Streaming ads report on-Spotify streamed impressions.',
    },
    dynamic: {
      ads: 'Dynamic ads report on and off Spotify downloaded impressions.',
    },
  },
  errors: {
    network: 'Network Error. Please try again later.',
  },
} as const;
