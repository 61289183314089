import {
  LinearProgress as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
  ThemeOptions,
} from '@mui/material';

export type LinearProgressProps = Pick<
  MuiLinearProgressProps,
  'valueBuffer' | 'variant' | 'value' | 'color'
>;

export function LinearProgress(props: LinearProgressProps) {
  return <MuiLinearProgress {...props} />;
}

export const MuiProgressBarStyles: ThemeOptions['components'] = {
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        height: 4,
      },
    },
  },
};
