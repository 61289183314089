export type TApiEnv = 'demo' | 'prod' | 'staging' | 'dev' | 'spaa';
export type TGraphQLService = 'analytics' | 'admin';

const apiEnv = process.env.API_ENV as TApiEnv;

export const getGraphQLEndpoint = (service: TGraphQLService): string => {
  if (!apiEnv) {
    console.warn(process, 'API_ENV environment variable is not set');
    throw new Error('API_ENV environment variable is not set');
  }

  switch (apiEnv) {
    case 'demo':
      return `https://podcast-graph-demo-dot-adaptive-growth.uc.r.appspot.com/graph/${service}`;
    case 'prod':
      return `https://podcast-graph-dot-adaptive-growth.appspot.com/graph/${service}`;
    case 'spaa':
      return `https://podcast-graph-ad-analytics-dot-adaptive-growth.appspot.com/graph/${service}`;
    case 'staging':
      return `https://podcast-graph-staging-dot-adaptive-growth.appspot.com/graph/${service}`;
    default:
      // dev
      return `http://localhost:8000/graph/${service}`;
  }
};

export const exporterEndpoint =
  apiEnv === 'dev'
    ? 'http://localhost:8000'
    : 'https://api.adanalytics.spotify.com';

export const podlinkEndpoint =
  apiEnv === 'dev'
    ? 'http://localhost:8000'
    : 'https://podcast-graph-dot-adaptive-growth.appspot.com';

export const isProd =
  apiEnv === 'prod' && process.env.NODE_ENV === 'production';

export const isStaging =
  apiEnv === 'staging' && process.env.NODE_ENV === 'production';

export const isDevelopment = process.env.NODE_ENV === 'development';
