import {useUpdateCampaignKindMutation} from '@admin/graphql-api';
import Select from '@core/ui/Select';
import {CampaignDetailsProps} from '.';

const CAMPAIGN_TYPE_SELECT = [
  {title: 'Attribution', value: 'attribution'},
  {title: 'Reporting', value: 'reporting'},
];

export function CampaignType({
  campaign,
  onCampaignError,
  setUpdatingCampaign,
}: CampaignDetailsProps) {
  const [updateCampaignKind] = useUpdateCampaignKindMutation();

  const onSelect = async ({value}) => {
    setUpdatingCampaign?.(true);
    try {
      await updateCampaignKind({
        variables: {
          input: {
            id: campaign.id,
            kind: value,
          },
        },
      });
    } catch (e) {
      console.warn(e);
      onCampaignError(e.message ?? 'Error updating campaign type');
    } finally {
      setUpdatingCampaign?.(false);
    }
  };

  return (
    <Select
      defaultValue={campaign?.kind ?? undefined}
      items={CAMPAIGN_TYPE_SELECT}
      onSelect={onSelect}
      rules={() => ({marginBottom: '0.5rem'})}
      small
    />
  );
}
