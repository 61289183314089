import {useState} from 'react';
import dayjs from '@core/lib/dayjs';

const useCampaigns = (campaigns) => {
  const [state] = useState(
    campaigns
      .slice(0)
      .map((c) => {
        const {reachTotal, attributionTotal} = c;

        return Object.assign({}, c, {
          organizationName: c.organization?.name ?? '',
          advertiserName: c.advertiser?.name ?? '',
          cr: reachTotal ? attributionTotal / reachTotal : 0,
          _start: dayjs(c.startAt || c.expectedStartAt),
          _end: dayjs(c.endAt || c.expectedEndAt),
        });
      })
      .sort((a, b) => {
        return b.downloadTotal - a.downloadTotal;
      })
  );

  return state;
};

export default useCampaigns;
