import {gql} from '@apollo/client';

export default gql`
  query getContacts {
    admin {
      contacts {
        id
        source
        firstName
        lastName
        email
        company
        role

        ignoredBy {
          id
          firstName
          lastName
          email
        }

        contactedBy {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;
