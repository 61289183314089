import {gql} from '@apollo/client';

export default gql`
  mutation addAccess($input: AddAccessInput!) {
    authed {
      success
    }
    addAccess(input: $input) {
      access {
        id
        name
        permission
        organization {
          id
          name
          kind
        }
      }
    }
  }
`;
