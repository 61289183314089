import {TextLink, semanticColors} from '@spotify-internal/encore-web';
import {Stack} from '@core/ui/Layout';
import {CampaignDetailsProps} from '.';
import {CampaignLink} from '../styled';
import {fmtCampaignDate} from '../utils';
import {CampaignGrantAccess} from './CampaignGrantAccess';
import {CampaignNeustar} from './CampaignNeustar';
import {CampaignPermissions} from './CampaignPermissions';
import {CampaignStartEnd} from './CampaignStartEnd';
import {CampaignState} from './CampaignState';
import {CampaignType} from './CampaignType';

export const ROWS = [
  {
    title: 'Last build',
    Component: ({campaign}: CampaignDetailsProps) => (
      <>{fmtCampaignDate(campaign?.lastBuilt)}</>
    ),
  },
  {title: 'State', Component: CampaignState},
  {title: 'Type', Component: CampaignType},
  {title: 'Start/End', Component: CampaignStartEnd},
  {
    title: 'Expected Start/End',
    Component: ({campaign}: CampaignDetailsProps) => (
      <>
        {fmtCampaignDate(campaign?.expectedStartAt)} /
        {fmtCampaignDate(campaign?.expectedEndAt)}
      </>
    ),
  },
  {
    title: 'Attribution window',
    Component: ({campaign}: CampaignDetailsProps) => (
      <>{campaign?.attributionWindow} days</>
    ),
  },
  {
    title: 'Publisher',
    Component: CampaignLink,
  },
  {
    title: 'Advertiser',
    Component: (props: CampaignDetailsProps) => (
      <CampaignLink {...props} type='brand' />
    ),
  },
  {
    title: 'Agency(s)',
    Component: ({campaign}: CampaignDetailsProps) => (
      <Stack direction='column' gap={2}>
        {(campaign?.stateOverview?.managers ?? []).map((agency) => (
          <TextLink
            key={agency?.id}
            href={`/organizations/${agency?.id}`}
            semanticColor={semanticColors.textBrightAccent}>
            {agency?.name}
          </TextLink>
        ))}
      </Stack>
    ),
  },
  {
    title: 'Grant Access (We must have the right to)',
    Component: CampaignGrantAccess,
  },
  {title: 'Neustar', Component: CampaignNeustar},
  {
    title: 'Art19',
    Component: ({campaign}: CampaignDetailsProps) => (
      <TextLink
        href={`/campaigns/${campaign?.id}/secret`}
        semanticColor={semanticColors.textBrightAccent}>
        Secret
      </TextLink>
    ),
  },
  {title: 'Permissions', Component: CampaignPermissions},
];
