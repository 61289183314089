import { buttonBorderRadius, controlMinBlockSize, cssColorSet, cssControlSize, getButtonPadding, getIconPlacement, overflowWrapFlexText, rootStyle } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
/* ButtonInner contains styles that make the button look like a button,
 * and is the Button subcomponent that is themable using ButtonPrimary's colorSet prop
 */
export var ButtonInner = styled.span.withConfig({
  displayName: "ButtonInner",
  componentId: "sc-14ud5tc-0"
})(["", ";", ""], function (_ref) {
  var UNSAFE_colorSet = _ref.UNSAFE_colorSet;
  return UNSAFE_colorSet ? cssColorSet(UNSAFE_colorSet) : '';
}, function (_ref2) {
  var fallbackSet = _ref2.fallbackSet,
    buttonSize = _ref2.buttonSize,
    iconLeading = _ref2.iconLeading,
    iconTrailing = _ref2.iconTrailing,
    iconOnly = _ref2.iconOnly,
    status = _ref2.status;
  var padding = getButtonPadding({
    buttonSize: buttonSize,
    iconPlacement: getIconPlacement({
      iconOnly: iconOnly,
      iconLeading: iconLeading,
      iconTrailing: iconTrailing
    }),
    status: status
  });
  return css(["", ";position:relative;background-color:var(--background-base,", ");color:var(--text-base,", ");display:flex;border-radius:", ";font-size:inherit;min-block-size:", ";align-items:center;justify-content:center;", ";", ";"], rootStyle(), fallbackSet.background.base, fallbackSet.text.base, buttonBorderRadius, cssControlSize(buttonSize, controlMinBlockSize[buttonSize]), padding, overflowWrapFlexText());
});