import React, {useEffect, useReducer} from 'react';
import FeedsFilter from '@admin/components/FeedsFilter';
import {Element} from '@core/style';
import {Button} from '@core/ui/Button';
import {Card, PageTitle} from '@core/ui/Content';
import {SearchInput} from '@core/ui/Form';
import Select from '@core/ui/Select';

const Feeds = () => {
  const [state, setState] = useReducer(
    (state, newState) => {
      return {...state, ...newState};
    },
    {
      order: '-created_at',
      feedState: 'all',
      localQuery: '',
      query: '',
      limit: 20,
      offset: 0,
    }
  );

  const onStateSelect = ({value}) => {
    setState({
      feedState: value,
    });
  };

  const onQueryChange = ({target: {value}}) => {
    setState({
      localQuery: value,
    });
  };

  const onOrderSelect = (params) => {
    if (params.value) {
      if (params.value !== state.order) {
        setState({order: params.value});
      }
    } else {
      setState({order: null});
    }
  };

  const loadMore = () => {
    setState({
      limit: state.limit + 20,
    });
  };

  useEffect(() => {
    const job = setTimeout(() => {
      setState({
        query: state.localQuery,
      });
    }, 500);

    return () => {
      clearTimeout(job);
    };
  }, [state.localQuery]);

  const orderBy = {
    '-ads_count': '-placementsCount',
    '-feeds_count': '-episodesCount',
    '-created_at': '-createdAt',
  }[state.order];

  return (
    <>
      <PageTitle
        rightContent={
          <Button to='/feeds/add' color='primary'>
            Add Feed
          </Button>
        }>
        Feeds
      </PageTitle>
      <Element rules={() => ({marginBottom: '1rem'})}>
        <Card
          rules={({theme}) => ({
            alignItems: 'center',
            background: theme.bgTertiary,
            border: 0,
            display: 'flex',
            padding: '1rem 1.5rem',
          })}>
          <Select
            items={[
              {title: 'Any state', value: 'all'},
              {title: 'Active', value: 'active'},
              {title: 'Archived', value: 'archived'},
            ]}
            defaultValue={state.feedState}
            onSelect={onStateSelect}
            rules={({theme}) => ({
              background: theme.bgPrimary,
              marginRight: '1rem',
            })}
          />
          <Select
            items={[
              {title: 'Recent', value: '-created_at'},
              {title: 'Most Ads', value: '-ads_count'},
              {title: 'Most Episodes', value: '-feeds_count'},
            ]}
            defaultValue={state.order}
            onSelect={onOrderSelect}
            rules={({theme}) => ({
              background: theme.bgPrimary,
            })}
          />
        </Card>
      </Element>
      <Card>
        <SearchInput
          type='text'
          name='query'
          placeholder='Search by feed title'
          onChange={onQueryChange}
          rules={() => ({marginBottom: '1rem'})}
        />
        <FeedsFilter
          offset={state.offset}
          limit={state.limit}
          order={state.order === '-created_at' ? null : state.order}
          query={state.query}
          state={state.feedState === 'all' ? null : state.feedState}
          orderBy={orderBy}
        />
        <Button
          onClick={loadMore}
          css={`
            width: 100%;
            margin-top: 1rem;
          `}>
          Load more feeds
        </Button>
      </Card>
    </>
  );
};

export default Feeds;
