import {gql} from '@apollo/client';

export default gql`
  mutation attributionPreRun($input: AttributionPreRunInput!) {
    authed {
      success
    }
    attributionPreRun(input: $input) {
      success
    }
  }
`;
