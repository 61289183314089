import React from 'react';
import {Route, Switch} from 'react-router-dom';
import ReviewerCreate from './ReviewerCreate';
import ReviewerDelete from './ReviewerDelete';
import ReviewerDetail from './ReviewerDetail';
import Reviewers from './Reviewers';

export default function () {
  return (
    <Switch>
      <Route exact path='/reviewers' component={Reviewers} />
      <Route exact path='/reviewers/create' component={ReviewerCreate} />
      <Route exact path='/reviewers/:reviewerId' component={ReviewerDetail} />
      <Route
        exact
        path='/reviewers/:reviewerId/delete'
        component={ReviewerDelete}
      />
    </Switch>
  );
}
