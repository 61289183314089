import {gql} from '@apollo/client';

export default gql`
  mutation createAsset($input: CreateAssetInput!) {
    authed {
      success
    }
    createAsset(input: $input) {
      asset {
        id
        name
        contentType
        width
        height
        size
        src
      }
    }
  }
`;
