import {FC, useState} from 'react';
import {TRules} from '@core/style';
import Input, {IInputProps} from './Input';

/**
 * @deprecated Use `<TextField />` instead.
 */
export interface ILabelInputProps extends IInputProps {
  label: string;
  rules?: TRules;
}

/**
 * @deprecated Use `<TextField />` instead.
 */
const LabelInput: FC<ILabelInputProps> = ({
  defaultValue,
  invalid,
  label,
  onBlur,
  onChange,
  onFocus,
  rules,
  value,
  ...inputProps
}): JSX.Element => {
  const [focused, setFocused] = useState(false);
  const [_value, setValue] = useState(
    typeof defaultValue !== 'undefined' ? defaultValue : value
  );

  const isActive =
    (typeof _value === 'number' && _value === 0) || !!_value || focused;

  return (
    <div css={{position: 'relative', lineHeight: 1.5}}>
      <div
        css={{
          borderRadius: '.25rem',
          bottom: '1.125rem',
          color: invalid
            ? 'var(--red-orange)'
            : focused
            ? 'var(--black)'
            : 'var(--text-muted)',
          left: '0.3125rem',
          margin: 'auto',
          padding: '0 1rem',
          pointerEvents: 'none',
          position: 'absolute',
          transform: isActive ? 'scale(.75) translateY(-1.125rem)' : 'initial',
          transformOrigin: 'left',
          transition:
            'transform 150ms cubic-bezier(0.4,0,0.2,1), opacity 150ms cubic-bezier(0.4,0,0.2,1)',
          whiteSpace: 'nowrap',
        }}>
        {label}
      </div>
      <Input
        value={value}
        defaultValue={defaultValue}
        onFocus={(evt) => {
          setFocused(true);
          onFocus?.(evt);
        }}
        onBlur={(evt) => {
          setFocused(false);
          onBlur?.(evt);
        }}
        onChange={(evt) => {
          setValue((evt.target as HTMLInputElement).value);
          onChange?.(evt);
        }}
        rules={[
          () => ({
            height: '3.75rem',
            padding: '1.625rem 1rem .5rem',
            ...(focused ? {borderColor: 'var(--black)'} : null),
          }),
          rules,
        ]}
        invalid={invalid}
        {...inputProps}
      />
    </div>
  );
};

export default LabelInput;
