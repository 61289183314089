export var ENCORE_DATA_IDS = {
  Accordion: 'accordion',
  AccordionContent: 'accordionContent',
  AccordionItem: 'accordionItem',
  AccordionTitle: 'accordionTitle',
  App: 'app',
  AppBanner: 'appBanner',
  AppBannerLayout: 'appBannerLayout',
  AppFooter: 'appFooter',
  AppFooterLink: 'appFooterLink',
  Backdrop: 'backdrop',
  Banner: 'banner',
  Box: 'box',
  BrowserDefaultFocusStyleProvider: 'browserDefaultFocusStyleProvider',
  Button: 'button',
  ButtonIcon: 'buttonIcon',
  ButtonPrimary: 'buttonPrimary',
  ButtonSecondary: 'buttonSecondary',
  ButtonTertiary: 'buttonTertiary',
  Card: 'card',
  CardDetails: 'cardDetails',
  CardImage: 'cardImage',
  CardSubtitle: 'cardSubtitle',
  CardTitle: 'cardTitle',
  Chip: 'chip',
  ChipClear: 'chipClear',
  CollapseButton: 'collapseButton',
  DialogAlert: 'dialogAlert',
  DialogConfirmation: 'dialogConfirmation',
  DialogFullScreen: 'dialogFullScreen',
  Dropdown: 'dropdown',
  DropdownItem: 'dropdownItem',
  DropdownLink: 'dropdownLink',
  DropdownList: 'dropdownList',
  DropdownTrigger: 'dropdownTrigger',
  EmptyState: 'emptyState',
  EmptyStateButton: 'emptyStateButton',
  EmptyStateText: 'emptyStateText',
  EmptyStateTitle: 'emptyStateTitle',
  EncoreProvider: 'encoreProvider',
  FormCheckbox: 'formCheckbox',
  FormGroup: 'formGroup',
  FormHelpText: 'formHelpText',
  FormInput: 'formInput',
  FormInputIcon: 'formInputIcon',
  FormPopoverTrigger: 'formPopoverTrigger',
  FormRadio: 'formRadio',
  FormSelect: 'formSelect',
  FormTextarea: 'formTextarea',
  FormToggle: 'formToggle',
  GlobalStyles: 'globalStyles',
  HorizontalRule: 'horizontalRule',
  Icon: 'icon',
  IconWithText: 'iconWithText',
  Image: 'image',
  KeyboardDetectionProvider: 'keyboardDetectionProvider',
  List: 'list',
  ListItem: 'listItem',
  ListRow: 'listRow',
  ListRowDetails: 'listRowDetails',
  ListRowImage: 'listRowImage',
  ListRowSubtitle: 'listRowSubtitle',
  ListRowTitle: 'listRowTitle',
  LoadingIndicator: 'loadingIndicator',
  LogoSpotify: 'logoSpotify',
  LogoSpotifyForArtists: 'logoSpotifyForArtists',
  NavBar: 'navBar',
  NavBarList: 'navBarList',
  NavBarListItem: 'navBarListItem',
  NavPill: 'navPill',
  NavPillList: 'navPillList',
  NavPillListItem: 'navPillListItem',
  NavPillPanel: 'navPillPanel',
  NavStepper: 'navStepper',
  NavStepperItem: 'navStepperItem',
  Navigation: 'navigation',
  NavigationAction: 'navigationAction',
  NavigationList: 'navigationList',
  NavigationListItem: 'navigationListItem',
  NavigationListTitle: 'navigationListTitle',
  OverlayPortal: 'overlayPortal',
  OverlayTrigger: 'overlayTrigger',
  PaginationControls: 'paginationControls',
  PaginationDropdown: 'paginationDropdown',
  PaginationDropdownItem: 'paginationDropdownItem',
  PaginationDropdownLink: 'paginationDropdownLink',
  PaginationDropdownList: 'paginationDropdownList',
  PaginationDropdownTrigger: 'paginationDropdownTrigger',
  Popover: 'popover',
  PopoverNavigation: 'popoverNavigation',
  PopoverNavigationItem: 'popoverNavigationItem',
  PopoverNavigationLink: 'popoverNavigationLink',
  PopoverOnboarding: 'popoverOnboarding',
  PopoverTrigger: 'popoverTrigger',
  ProgressBar: 'progressBar',
  ProgressCircle: 'progressCircle',
  ProgressDots: 'progressDots',
  SkipLink: 'skipLink',
  Snackbar: 'snackbar',
  StatusIndicator: 'statusIndicator',
  TabItem: 'tabItem',
  Table: 'table',
  TableCell: 'tableCell',
  TableCheckbox: 'tableCheckbox',
  TableContainer: 'tableContainer',
  TableHeaderCell: 'tableHeaderCell',
  TablePagination: 'tablePagination',
  TableRow: 'tableRow',
  TableSortIcon: 'tableSortIcon',
  TableThumbnail: 'tableThumbnail',
  TabList: 'tabList',
  TabPanel: 'tabPanel',
  Tabs: 'tabs',
  Tag: 'tag',
  Text: 'text',
  TextLink: 'textLink',
  Tooltip: 'tooltip',
  TooltipTrigger: 'tooltipTrigger',
  Type: 'type',
  TypeList: 'typeList',
  TypeListItem: 'typeListItem',
  UnsupportedBrowser: 'unsupportedBrowser',
  VerifiedBadge: 'verifiedBadge',
  VisuallyHidden: 'visuallyHidden'
};