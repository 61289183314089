import {ChangeEvent, ComponentType, useState} from 'react';
import styled from 'styled-components';
import searchFilter from '@core/lib/searchFilter';
import {ButtonIcon} from '../Button';
import {DropdownMenu} from '../DropdownMenu';
import {SearchInput} from '../FormElements';
import {Icon} from '../Icon';

type Props = {
  emptyText: string;
  items: any[];
  OptionContent?: ComponentType<any>;
  propertyForDescription: string;
  propertyForName: string;
  propertyForValue: string;
  searchable?: boolean;
  searchKeys: string[];
  selectedItems: any[];
  setSelectedItems: (_arr: any[]) => void;
  singleSelection: boolean;
  toggle: (_to?: boolean) => void;
};

export const _MultipleSelectOverlayContent = ({
  emptyText,
  items,
  OptionContent,
  propertyForDescription,
  propertyForName,
  propertyForValue,
  searchable,
  searchKeys,
  selectedItems,
  setSelectedItems,
  singleSelection,
  toggle,
}: Props) => {
  const [filteredItems, setFilteredItems] = useState(items);

  const onItemClick = (item: any) => {
    if (item.__removeSelected__) {
      setSelectedItems([]);
      toggle(false);
    } else if (singleSelection) {
      setSelectedItems([item]);
      toggle(false);
    } else {
      const match = findSelectedItem({item, propertyForValue, selectedItems});

      if (match) {
        selectedItems.splice(selectedItems.indexOf(match), 1);
      } else {
        selectedItems.push(item);
      }
      setSelectedItems(selectedItems.slice(0));
    }
  };

  const onSearchInput = (evt: ChangeEvent<HTMLInputElement>) => {
    const query = evt.target.value.trim();
    setFilteredItems(searchFilter({data: items, searchKeys, query}));
  };

  return (
    <div
      css={`
        display: grid;
        grid-template-rows: max-content 1fr;
        height: auto;
        overflow: hidden;
        padding: 0.375rem;
      `}>
      {searchable && (
        <_SearchContainer>
          <SearchInput
            onInput={onSearchInput}
            autofocus
            rules={() => ({width: '100%', marginRight: '0.1875rem'})}
          />
          {!singleSelection && (
            <DropdownMenu
              onSubmit={({value}) => {
                if (value === 'select-all') {
                  setSelectedItems(items.slice(0));
                } else if (value === 'unselect-all') {
                  setSelectedItems([]);
                }
              }}
              items={[
                {title: 'Select all', value: 'select-all'},
                {title: 'Unselect all', value: 'unselect-all'},
              ]}>
              <ButtonIcon
                icon='more'
                css={`
                  transform: rotate(90deg);
                `}
              />
            </DropdownMenu>
          )}
        </_SearchContainer>
      )}
      {filteredItems.length ? (
        <_ItemsContainer>
          {filteredItems.map((item: any, i: number) => {
            const active = !!findSelectedItem({
              item,
              propertyForValue,
              selectedItems,
            });

            if (typeof OptionContent === 'function') {
              return (
                <OptionContent
                  key={item[propertyForValue] || i}
                  active={active}
                  isLast={filteredItems.length - 1 === i}
                  item={item}
                  onClick={() => onItemClick(item)}
                />
              );
            }

            if (item.type === 'separator') {
              return <_Divider key={i} />;
            }
            return (
              <_Item
                tabIndex={-1}
                key={`i:${item[propertyForValue]}`}
                $active={active}
                onClick={() => onItemClick(item)}>
                {item[propertyForDescription] ? (
                  <div>
                    {item[propertyForName]}
                    <_ItemDescription $active={active}>
                      {item[propertyForDescription]}
                    </_ItemDescription>
                  </div>
                ) : (
                  <div>{item[propertyForName]}</div>
                )}
                <_CheckMarkIcon icon='checkmark' $active={active} />
              </_Item>
            );
          })}
        </_ItemsContainer>
      ) : (
        <_EmptyContainer>{emptyText}</_EmptyContainer>
      )}
    </div>
  );
};

const findSelectedItem = ({
  item,
  propertyForValue,
  selectedItems,
}: {
  item: any;
  propertyForValue: string;
  selectedItems: any[];
}) => {
  return selectedItems.find(
    (i: any) => i[propertyForValue] === item[propertyForValue]
  );
};

const _ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 30rem;
  min-height: 0;
  overflow-y: auto;
`;

const _Item = styled.button<{$active: boolean}>`
  all: unset;
  //
  align-items: center;
  border-radius: 0.375rem;
  color: ${(p) => (p.$active ? 'var(--black)' : 'var(--text-default)')};
  font-weight: ${(p) => (p.$active ? 700 : 400)};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.375rem 0.75rem;
  text-align: left;
  text-decoration: none;
  user-select: none;
  flex: 1;
  :hover {
    background: var(--bg-default-hover);
    color: var(--black);
  }
`;

const _ItemDescription = styled.div<{$active: boolean}>`
  color: ${(p) => (p.$active ? 'var(--black)' : 'var(--text-muted)')};
  font-weight: ${(p) => (p.$active ? 700 : 400)};
  font-size: 0.875rem;
  line-height: 1.3;
  margin-top: 0.0625rem;
  max-width: 100%;
`;

const _SearchContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 0.625rem;
`;

const _Divider = styled.div`
  background: var(--border-default);
  height: 0.0675rem;
  margin: 0.375rem 0;
`;

const _EmptyContainer = styled.div`
  padding: 1rem;
  color: var(--text-muted);
`;

const _CheckMarkIcon = styled(Icon)<{$active: boolean}>`
  flex-shrink: 0;
  height: 1.25rem;
  margin-left: 0.375rem;
  opacity: ${(p) => (p.$active ? 1 : 0)};
  width: 1.25rem;
`;
