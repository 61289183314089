import React, {FC, useRef, useState} from 'react';
import {toAudioPath} from '@core/lib/audio';
import {css, darken} from '@core/style';
import EmptyMessage from '@core/ui/EmptyMessage';
import AudioPlayButton from '../AudioPlayButton/AudioPlayButton';

interface BarProps {
  value: number;
  height: number;
  active: boolean;
  color?: string | null;
}

const Bar: FC<BarProps> = ({value, height, active, color}): JSX.Element => {
  const barHeight = (height * value) / 100;

  return (
    <div
      style={{
        flex: '1 0 0%',
        height: `${barHeight}px`,
        marginTop: `${height - barHeight}px`,
        transition: 'background-color 0.5s ease',
        ...(active
          ? {
              backgroundColor: color ? darken(color, 0.4) : 'var(--green)',
            }
          : {
              backgroundColor: color || '#e5e5e5',
            }),
      }}
    />
  );
};

interface MiniPlayerProps {
  enclosure?: {
    duration: number;
    mpegPath?: string | null;
    waveform?: any;
  } | null;
  placements?: Array<{color?: string; endTime: number; startTime: number}>;
  color?: string;
  height?: number;
  preload?: string;
}

const MiniPlayer: FC<MiniPlayerProps> = ({
  enclosure,
  placements,
  color,
  height = 24,
  preload = 'auto',
}): JSX.Element => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const trackRef = useRef<HTMLDivElement | null>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number | null>(null);
  const [hovered, setHovered] = useState<boolean>(false);

  if (!enclosure || !enclosure.mpegPath) {
    return (
      <EmptyMessage rules={() => ({padding: 0})}>
        Unable to load audio.
      </EmptyMessage>
    );
  }

  const waveform: number[] = enclosure.waveform
    ? JSON.parse(enclosure.waveform)
    : [];

  const colorMap = (placements ?? []).map((placement) => [
    placement.startTime / enclosure.duration,
    placement.endTime / enclosure.duration,
    placement.color ?? '',
  ]);

  const getColor = (percent: number) => {
    if (color) {
      return color;
    } else if (colorMap.length) {
      for (let i = 0; i < colorMap.length; i++) {
        if (percent >= colorMap[i][0] && percent <= colorMap[i][1]) {
          return colorMap[i][2];
        }
      }
    }
    return null;
  };

  const colorWaveform = waveform.map((v, n) => ({
    value: v,
    color: getColor(n / waveform.length) as string,
  }));

  const onTimeUpdate = (e: React.ChangeEvent<HTMLAudioElement>) => {
    setCurrentTime(e.target.currentTime);
  };

  const onReady = (e: React.ChangeEvent<HTMLAudioElement>) => {
    audioRef.current = e.target;
    setDuration(e.target.duration);

    if (audioRef.current) {
      audioRef.current.addEventListener('ended', () => {
        setPlaying(false);
      });
    }
  };

  const togglePlay = () => {
    if (audioRef.current) {
      if (playing) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setPlaying((prev) => !prev);
    }
  };

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  const onTrackClick = ({pageX}: {pageX: number}) => {
    if (trackRef.current) {
      const rect = trackRef.current.getBoundingClientRect();
      const myCurrentTime =
        ((pageX - rect.x) / rect.width) * enclosure.duration;

      if (!playing && audioRef.current) {
        setPlaying(true);
        audioRef.current.play();
      }

      setTimeout(() => {
        if (audioRef.current) {
          audioRef.current.currentTime = myCurrentTime;
        }
      }, 10);
    }
  };

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <audio
        ref={audioRef}
        onCanPlayThrough={onReady}
        onTimeUpdate={onTimeUpdate}
        preload={preload}>
        <source src={toAudioPath(enclosure.mpegPath)} type='audio/flac' />
      </audio>
      <div css={{display: 'flex', alignItems: 'center'}}>
        <AudioPlayButton
          playing={playing}
          onClick={togglePlay}
          rules={() => ({
            color: 'var(--icon-subtle)',
            cursor: 'pointer',
            display: 'flex',
            height: '1.5rem',
            marginRight: '0.375rem',
            width: '1.5rem',
            ':hover': {
              color: 'var(--icon-default)',
            },
          })}
        />
        <div css={{boxShadow: 'var(--shadow-lg)', flex: 1}}>
          <div
            ref={trackRef}
            onClick={onTrackClick}
            css={{
              cursor: 'pointer',
              display: 'flex',
              opacity: hovered ? 1 : 0.8,
              transition: 'opacity 0.5s ease',
            }}>
            {colorWaveform.map((w, n) => (
              <Bar
                key={n}
                value={w.value}
                color={w.color}
                height={height}
                active={
                  (n / colorWaveform.length) * 100 <=
                  (currentTime / (duration ?? 1)) * 100
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export {MiniPlayer};
