export type ClientIpContext = {
  name: 'context_client_ip';
  data: {
    /**
     * The ip address of the connection that sent the event from client to Spotify Backend.
     */
    value: string;
  };
};

/**
 * A builder for ClientIp
 * ClientIp is optional in Browser and Device env.
 *
 * @deprecated This will be removed in v5.0.0. This is added in the edge decorator.
 * @param clientIp - The ip address of the connection that sent the event from client to Spotify Backend.
 * @return The formatted event data for ClientIpContext
 */
export function createClientIpContext(clientIp: string): () => ClientIpContext {
  return () => {
    return {
      name: 'context_client_ip',
      data: {
        value: clientIp,
      },
    };
  };
}
