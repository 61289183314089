import React, {FC} from 'react';
import {SpotifyCampaignImportRequestObject} from '@admin/graphql-api';
import dayjs from 'dayjs';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';

interface OrganizationSpotifyImportsTableProps {
  spotifyImports: SpotifyCampaignImportRequestObject[];
}

const OrganizationSpotifyImportsTable: FC<
  OrganizationSpotifyImportsTableProps
> = ({spotifyImports}): JSX.Element => {
  const columns: DataTableColumn[] = [
    {
      accessor: 'spotifyOrderId',
      title: 'Spotify Order ID',
    },
    {
      accessor: 'createdAt',
      title: 'Created On',
      getValue: ({d}) => dayjs(d.createdAt).format('MM/DD/YYYY'),
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={spotifyImports}
      emptyMessageText='No order IDs found'
    />
  );
};

export default OrganizationSpotifyImportsTable;
