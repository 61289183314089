// NOTE: This code was generated and should not be changed

/**
 * Internal "loss" and "ratelimiter" metrics from the EventSenderSDKs. For each of the two metric types, a set of "stats" arrays is defined. For each metric type, information in the "stats" arrays is positional, which means entries for the same index in the arrays belong to the same sequence ID and/or same event name. The "sequence_ids" and "event_names" arrays are used together with other mapping fields to link each entry in the stats with their actual sequence ID/event name.
 */
export type EventSenderStats2NonAuthEventData = {
  /**
   * The sequence ID's that the events mentioned in the stats belong to.
   */
  sequence_ids?: string[] | null;
  /**
   * The names of the events that are mentioned in the stats.
   */
  event_names?: string[] | null;
  /**
   * For the "sequence_ids" value at the same index, how many loss_stats entries belong to such sequence ID. Note: entries in the loss_stats arrays are ordered by sequence ID, following the order of sequence ID's represented in the "sequence_ids" field.
   */
  loss_stats_num_entries_per_sequence_id?: number[] | null;
  /**
   * Index into "event_names" that defines which event this loss_stats entry is for.
   */
  loss_stats_event_name_index?: number[] | null;
  /**
   * The size of storage in terms of number of events.
   */
  loss_stats_storage_sizes?: number | string[] | null;
  /**
   * The minimum sequence number in storage.
   */
  loss_stats_sequence_number_mins?: number | string[] | null;
  /**
   * The next sequence number to be allocated.
   */
  loss_stats_sequence_number_nexts?: number | string[] | null;
  /**
   * Index into "event_names" that defines which event this ratelimiter_stats entry is for.
   */
  ratelimiter_stats_event_name_index?: number[] | null;
  /**
   * The number of events dropped by the rate limiter.
   */
  ratelimiter_stats_drop_count?: number | string[] | null;
  /**
   * For the "sequence_ids" value at the same index, how many drop_list entries belong to such sequence ID. Note: entries in the drop_list arrays are ordered by sequence ID, following the order of sequence ID's represented in the "sequence_ids" field.
   */
  drop_list_num_entries_per_sequence_id?: number[] | null;
  /**
   * Index into "event_names" that defines which event this drop_list entry is for.
   */
  drop_list_event_name_index?: number[] | null;
  /**
   * The total number of dropped events per event name and sequence ID.
   */
  drop_list_counts_total?: number | string[] | null;
  /**
   * The number of dropped events per event name and sequence ID that wasn’t reported yet.
   */
  drop_list_counts_unreported?: number | string[] | null;
};

export type EventSenderStats2NonAuthEvent = {
  name: 'EventSenderStats2NonAuth';
  environments: ['devicenonauth', 'browsernonauth', 'desktopnonauth'];
  data: EventSenderStats2NonAuthEventData;
};

/**
 * A builder for EventSenderStats2NonAuth
 *
 * @param data - The event data
 * @return The formatted event data for EventSenderStats2NonAuthEvent
 */
export function createEventSenderStats2NonAuth(
  data: EventSenderStats2NonAuthEventData
): EventSenderStats2NonAuthEvent {
  return {
    name: 'EventSenderStats2NonAuth',
    environments: ['devicenonauth', 'browsernonauth', 'desktopnonauth'],
    data,
  };
}
