import {DealerErrors} from './typedefs';

interface WebSocketErrorEvent {
  code?: number;
  reason?: string;
}

export class DealerError extends Error {
  /**
   * Error code.
   */
  code?: string;

  /**
   * Raw representation of the error that lead to the creation of this instance.
   */
  raw: unknown;

  /**
   * Reason from dealer.
   */
  wsReason: string | null;

  /**
   * Error errorCode from dealer.
   */
  wsCode: number | null;

  /**
   * Represents a dealer error.
   *
   * @param code - The error code.
   * @param message - The error message.
   * @param event - Remote event from dealer.
   */
  constructor(
    code: string = DealerErrors.DEALER_ERROR,
    message: string,
    event: WebSocketErrorEvent = {}
  ) {
    super(message);
    this.name = 'DealerError';
    this.message = message;
    this.code = code;

    this.raw = event;
    this.wsCode = event.code || null;
    this.wsReason = event.reason || null;
  }
}
