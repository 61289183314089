import {useContext} from 'react';
import {IStyle, type TRule, combineRules} from 'fela';
import {RendererContext} from 'react-fela';
import {useTheme} from '@core/style';
import {THEMES} from './themes';

/**
 * @deprecated
 */
export {type TRule} from 'fela';

/**
 * @deprecated
 */
export type TRules =
  | ((_: any) => IStyle)
  | TRules[]
  | TRule<RuleProps>
  | null
  | undefined;

type RuleProps = {
  themeName: 'dark' | 'light';
  /** @deprecated */
  theme: any;
};

/**
 * @deprecated
 */
export const css = (
  rules: TRules,
  {keyframes, className}: {keyframes?: any; className?: string} = {}
) => {
  const renderer = useContext(RendererContext);
  const themeName = useTheme();
  const theme = themeName ? THEMES[themeName] : THEMES.light;
  const rulesArr = rules
    ? Array.isArray(rules)
      ? rules.flat(10).filter(Boolean)
      : [rules].filter(Boolean)
    : [];

  if (rulesArr.length) {
    const keyframesMap: {[key: string]: any} = {};

    if (keyframes) {
      for (const key in keyframes) {
        if (keyframes.hasOwnProperty(key)) {
          keyframesMap[key] = renderer.renderKeyframe(keyframes[key], {});
        }
      }
    }
    const combinedRules = combineRules(...(rulesArr as any));
    return {
      className:
        renderer.renderRule(combinedRules, {
          ...keyframesMap,
          theme,
          themeName,
        }) + (className ? ` ${className}` : ''),
    };
  }
  return {className: className ?? ''};
};
