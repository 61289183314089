import {Logger, LevelName, PredicateFunc} from './typedefs';

import {LogLevel} from './enums/log_level';

import {DummyLogger} from './_internal/dummy_logger';

interface LoggerConstructor {
  new (...args: any): Logger;
}

class ConsoleLogger implements Logger {
  private _tagParts: string[] = [];
  private _tagText: string = '';
  private _enabled: boolean = true;
  private _level = LogLevel.NONE;

  tag: string = '';
  description: string = '';

  constructor(
    tag: string,
    description: string = '',
    predicate: PredicateFunc = () => false
  ) {
    this.tag = tag;
    this.description = description;
    this._predicate = predicate;
    this._tagParts = tag.split('.');
    this._tagText = `[${tag}]`;
  }

  private _predicate: PredicateFunc = function (): boolean {
    return false;
  };

  private _print(levelName: LevelName, logLevel: LogLevel, args: any[]): void {
    if (!this._enabled && !(this._level & logLevel)) {
      return;
    }
    const logObj = {
      args,
      type: levelName,
      tag: this.tag,
    };

    if (this._predicate(logObj)) {
      return;
    }

    /* eslint-disable-next-line no-console */
    console[levelName](this._tagText, ...args);
  }

  matchesTag(tag: string[] | string): boolean {
    const _tag = Array.isArray(tag) ? tag : tag.split('.');
    const tagParts = this._tagParts;
    if (_tag.length > tagParts.length) {
      return false;
    }
    for (let i = 0, l = _tag.length; i < l; i++) {
      if (_tag[i] === '*' && tagParts[i]) {
        // matches anything
        continue;
      }
      if (_tag[i] !== tagParts[i]) {
        return false;
      }
    }
    return true;
  }

  setLevel(level: LevelName): void {
    let value = 0;
    switch (level) {
      case 'error':
        value = LogLevel.ERROR;
        break;
      case 'warn':
        value = LogLevel.WARN | LogLevel.ERROR;
        break;
      case 'info':
        value = LogLevel.INFO | LogLevel.WARN | LogLevel.ERROR;
        break;
      case 'debug':
        value = LogLevel.DEBUG | LogLevel.INFO | LogLevel.WARN | LogLevel.ERROR;
        break;
      case 'log':
      default:
        value =
          LogLevel.LOG |
          LogLevel.DEBUG |
          LogLevel.INFO |
          LogLevel.WARN |
          LogLevel.ERROR;
        break;
    }
    this._level = value;
  }

  enable(): void {
    this._enabled = true;
  }

  disable(): void {
    this._enabled = false;
  }

  log(..._args: any[]): void {
    this._print('log', LogLevel.LOG, _args);
  }

  debug(..._args: any[]): void {
    this._print('debug', LogLevel.DEBUG, _args);
  }

  info(..._args: any[]): void {
    this._print('info', LogLevel.INFO, _args);
  }

  warn(..._args: any[]): void {
    this._print('warn', LogLevel.WARN, _args);
  }

  error(..._args: any[]): void {
    this._print('error', LogLevel.ERROR, _args);
  }
}

export const DebugLogger: LoggerConstructor =
  // @ts-ignore
  typeof process !== 'undefined' && process.env.DEBUG === 'true'
    ? ConsoleLogger
    : DummyLogger;
