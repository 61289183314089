import {isBuying, mapOrgKindToPathPart} from '@core/lib/organizations';
import {OrganizationKind} from '../../analytics/graphql-api';

export const toDashboardUrl = ({
  kind,
  slug,
}: {
  kind: OrganizationKind;
  slug: string;
}): string =>
  `https://dash.podsights.com/${mapOrgKindToPathPart({kind})}/${slug}`;

/**
 * @deprecated
 */
export const organizationCanViewBrandData = (
  {kind}: {kind: OrganizationKind},
  campaign: any
): boolean => isBuying({kind}) || campaign.pixels.length > 0;
