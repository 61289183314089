import {hexToBase64} from '../_internal/hex_to_base64';

/**
 * The installation id context used for conveing the 'installation id'. This id uniquely identifies the installation. For a client this will be an ID specific to the device and the application.
 */
export type InstallationIdContext = {
  name: 'context_installation_id';
  data: {
    value: string;
  };
};

/**
 * A context creator for installationId.
 *
 * Note that the installation id needs to be sent to the server as a hex bytestring.
 *
 * @param installationId - The installationId to be attached to each event
 * @return  The function that attaches the installationId to every event
 */
export function createInstallationIdContext(
  installationId: string
): () => InstallationIdContext {
  const encoded = hexToBase64(installationId);
  return () => ({
    name: 'context_installation_id',
    data: {value: encoded},
  });
}
