import React from 'react';
import {
  Skeleton as MuiSkeleton,
  SkeletonProps as MuiSkeletonProps,
  ThemeOptions,
} from '@mui/material';

export type SkeletonProps = Pick<
  MuiSkeletonProps,
  'animation' | 'children' | 'height' | 'variant' | 'width'
>;

export function Skeleton(props: SkeletonProps) {
  return <MuiSkeleton {...props} />;
}

export const MuiSkeletonStyles: ThemeOptions['components'] = {
  MuiSkeleton: {
    defaultProps: {
      animation: 'pulse',
      variant: 'rounded',
    },
  },
};
