/*
 * Mixins and values meant for control components:
 * Buttons, Chip, Dropdown, Select, FormInput, etc
 *
 * TODO: Migrate things off of legacy sizes (sm, md, lg)
 * and delete the references here
 */

export var controlSizes = ['small', 'medium', 'large'];
export var controlSizesLegacy = ['sm', 'md', 'lg'];
export var getNextControlSize = function getNextControlSize(sizeName) {
  var controlSizesMap = {
    sm: 'small',
    md: 'medium',
    lg: 'large'
  };
  return controlSizesMap[sizeName];
};
export var controlMinBlockSize = {
  small: '32px',
  medium: '48px',
  large: '56px',
  sm: '32px',
  md: '48px',
  lg: '56px'
};
export var controlPaddingBlock = {
  small: '4px',
  medium: '8px',
  large: '12px',
  sm: '4px',
  md: '8px',
  lg: '12px'
};
export var controlIconSize = {
  small: 'small',
  medium: 'medium',
  large: 'medium'
};
export var controlGraphicSize = {
  small: 'smaller',
  medium: 'base',
  large: 'base'
};