// Copied from https://ghe.spotify.net/datainfra/event-definitions-ts/blob/master/src/contexts/createDeviceDesktop.ts

/**
 * Contains information about the desktop device/computer that emitted an event
 */
export type DeviceDesktopContextData = {
  /**
   * A string identifying which platform the Spotify client is running on, `windows` | `osx` | `linux`.
   */
  platform_type?: string | null;
  /**
   * Name of the manufacturer of the device that the client is running on. On Windows, this is generated by querying WMI `Win32_ComputerSystem.Manufacturer`. On macOS, this is `IOPlatformExpertDevice/manufacturer`. On Linux, this is read from `/sys/devices/virtual/dmi/id/sys_vendor`.
   */
  device_manufacturer?: string | null;
  /**
   * Name of the model of the device that the client is running on. On Windows, this is generated by querying WMI `Win32_ComputerSystem.Model`. On macOS, this is generated using `sysctlbyname("hw.model", ...)`. On Linux, this is read from `/sys/devices/virtual/dmi/id/product_name`.
   */
  device_model?: string | null;
  /**
   * A globally unique identifier for this device. On Windows, this is generated using the SDI returned from `GetComputerNameW()`/`LookupAccountNameW()`, or using `GetCurrentHwProfile().szHwProfileGuid`. On macOS, this is `IOService:/IOPlatformUUID`, `IOService:/IOPlatformSerialNumber`, or the MAC address of the primary network interface. On Linux, this is read from `/etc/machine-id`, `/var/lib/dbus/machine-id`, generated using the MAC address of the primary network interface, or the host name as returned by `gethostname()`.
   */
  device_id?: string | null;
  /**
   * The current operating system version. On Windows, this is generated using `GetVersionEx()`. On macOS, this is the version reported by `NSProcessInfo.processInfo.operatingSystemVersion`. On Linux, this is currently always empty.
   */
  os_version?: string | null;
};

export type DeviceDesktopContext = {
  name: 'context_device_desktop';

  data: DeviceDesktopContextData;
};

/**
 * A builder for DeviceDesktop
 *
 * @param data - The event data
 * @return The formatted event data for DeviceDesktopContext
 */
export function createDeviceDesktopContext(
  data: DeviceDesktopContextData
): () => DeviceDesktopContext {
  return () => {
    return {
      name: 'context_device_desktop',
      data,
    };
  };
}
