import {useState} from 'react';
import {useUpdateCompanyArchiveMutation} from '@admin/graphql-api';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {FORM_ERRORS, Form, FormFooter, Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {CompaniesPath, toCompanyUrl} from './_routes';
import {CompanyType} from './_types';

interface Props {
  company: CompanyType;
}

export default function CompanyArchive({company}: Props) {
  const history = useHistory();
  const [updateCompanyArchive] = useUpdateCompanyArchiveMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm();

  const onSubmit = async () => {
    setLoading(true);
    try {
      await updateCompanyArchive({
        variables: {
          input: {
            id: company.id,
          },
        },
      });
      history.push(CompaniesPath);
    } catch (err) {
      setError('', FORM_ERRORS.network);
    }
  };

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={toCompanyUrl(company)}>Archive Company</CardHeader>
      <Card>
        <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
          <p>Are you sure you want to archive this company?</p>
          <FormFooter>
            <Button to={toCompanyUrl(company)}>Cancel</Button>
            <Submit value='Archive' color='error' loading={loading} />
          </FormFooter>
        </Form>
      </Card>
    </ResponsiveContent>
  );
}
