import { useEffect, useRef } from 'react';

/* Custom hook to handle forwardedRef (both ref objects and ref callback functions) */

export function useMergeRef(forwardedRef) {
  var innerRef = useRef(null);
  useEffect(function () {
    if (!forwardedRef) return;
    if (typeof forwardedRef === 'function') {
      forwardedRef(innerRef.current);
    } else {
      forwardedRef.current = innerRef.current;
    }
  });
  return innerRef;
}