import styled from 'styled-components';
import {FelaElement, FelaElementProps} from '@core/style';

export interface CardProps extends FelaElementProps<'div'> {
  /**
   * If true, the card will have a shadow.
   * @default false
   */
  elevated?: boolean;
  /**
   * If true, the card will have a 'auto' overflow.
   * @default false
   */
  scrollable?: boolean;
  /**
   * @default 'outlined'
   */
  variant?: 'outlined' | 'filled';
}

export const Card = styled(FelaElement)<CardProps>`
  border-radius: 0.5rem;
  border: 1px solid var(--border-default);
  box-shadow: ${(p) => (p.elevated ? 'var(--shadow-lg)' : '')};
  margin: 0 var(--spacing-6) var(--spacing-8);
  min-width: 0;
  overflow: ${(p) => p.scrollable && 'auto'};
  padding: 1.25rem;
  ${(p) => `
    background: ${
      p.variant === 'filled' ? 'var(--bg-muted)' : 'var(--bg-surface)'
    };
  `}
`;
