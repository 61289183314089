import {gql} from '@apollo/client';

export default gql`
  query getInvoice($id: ID!) {
    admin {
      invoice(id: $id) {
        id
        number
        created
        due
        sent
        paid
        periodStart
        periodEnd

        organization {
          id
          name
          slug
        }

        lineItems {
          id
          description
          quantity
          rate
          cost
          hidden
        }

        createdAt
      }
    }
  }
`;
