import {useUpdateCampaignIsNeustarMutation} from '@admin/graphql-api';
import {FormToggle, semanticColors} from '@spotify-internal/encore-web';
import {CampaignDetailsProps} from '.';

export function CampaignNeustar({
  campaign,
  onCampaignError,
  setUpdatingCampaign,
}: CampaignDetailsProps) {
  const [updateCampaignIsNeustar] = useUpdateCampaignIsNeustarMutation();

  const onChecked = async (e) => {
    setUpdatingCampaign?.(true);
    try {
      await updateCampaignIsNeustar({
        variables: {
          input: {
            id: campaign.id,
            isNeustar: e.target.checked,
          },
        },
      });
    } catch (e) {
      console.warn(e);
      onCampaignError(e.message ?? 'Error updating campaign Neustar setting');
    } finally {
      setUpdatingCampaign?.(false);
    }
  };

  return (
    <FormToggle
      checked={campaign?.isNeustar}
      onChange={onChecked}
      semanticColor={semanticColors.essentialBrightAccent}
    />
  );
}
