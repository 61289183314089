import React, {forwardRef} from 'react';
import InvoicesQuery from '@admin/graphql-api/_old_queries/InvoicesQuery';
import {graphql} from '@apollo/client/react/hoc';
import dayjs from '@core/lib/dayjs';
import {Card, PageTitle} from '@core/ui/Content';
import DataTable, {
  DateRangeFilter,
  MultipleSelectFilter,
} from '@core/ui/DataTable';
import Loading from '@core/ui/Loading';

const Invoices = ({data: {loading, admin}}) => {
  if (loading) {
    return <Loading absolute />;
  }

  const {invoices} = admin;

  const columns = [
    {
      title: 'Invoice',
      accessor: 'orgNumber',
      type: 'string',
      rule: () => ({fontWeight: 500}),
    },
    {
      title: 'Organization',
      accessor: 'organizationName',
      type: 'string',
      Filter: MultipleSelectFilter,
    },
    {
      title: 'Period Start',
      accessor: 'periodStart',
      type: 'date',
      Filter: forwardRef(({...props}, ref) => (
        <DateRangeFilter
          ref={ref}
          label='Period Start/End'
          startAccessor='periodStart'
          endAccessor='periodEnd'
          {...props}
        />
      )),
      fmt: (v) => (v ? dayjs(v).utcOffset(0).format('MMM DD, YYYY') : '-'),
    },
    {
      title: 'Period End',
      accessor: 'periodEnd',
      type: 'date',
      fmt: (v) => (v ? dayjs(v).utcOffset(0).format('MMM DD, YYYY') : '-'),
    },
    {
      title: 'Created',
      accessor: 'created',
      type: 'date',
      fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
    },
    {
      title: 'Due',
      accessor: 'due',
      type: 'date',
      fmt: (v) => (v ? dayjs(v).format('MMM DD, YYYY') : '-'),
    },
    {
      title: 'Sent',
      accessor: 'sent',
      type: 'date',
      fmt: (v) => (v ? dayjs(v).format('MMM DD, YYYY') : '-'),
    },
    {
      title: 'Paid',
      accessor: 'paid',
      type: 'date',
      fmt: (v) => (v ? dayjs(v).format('MMM DD, YYYY') : '-'),
    },
  ];

  const data = invoices.slice(0).map((invoice) =>
    Object.assign({}, invoice, {
      organizationName: invoice.organization.name,
      orgNumber: `${invoice.organization.slug}-${invoice.number}`,
    })
  );

  return (
    <>
      <PageTitle>Invoices</PageTitle>
      <Card>
        <DataTable
          columns={columns}
          data={data}
          orderBy='-createdAt'
          searchPlaceholder='Search by organization name'
          searchKeys={['organizationName']}
          filtersStorageKey='admin-invoices'
          onClickRowPath={({id}) => `/invoices/${id}`}
        />
      </Card>
    </>
  );
};

export default graphql(InvoicesQuery, {
  options: {
    fetchPolicy: 'network-only',
  },
})(Invoices);
