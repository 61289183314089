export const WEEK_DAYS = ['S', 'M', 'T', 'W', 'Th', 'F', 'S'];

export const areSameDates = (a, b) =>
  a && b && a.format('YYYYMMDD') === b.format('YYYYMMDD');

export const getDaysInMonthUTC = (date) => {
  const numberOfDays = date.daysInMonth();
  const days = Array.from({length: numberOfDays}, (_, i) =>
    date.clone().date(i + 1)
  );
  return days;
};
