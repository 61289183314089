import React from 'react';
import BackdateRangeMutation from '@admin/graphql-api/_old_mutations/BackdateRange';
import BackdatedQuery from '@admin/graphql-api/_old_queries/BackdatedQuery';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import dayjs from '@core/lib/dayjs';
import {comma, toFixed, toPercent} from '@core/lib/filters';
import {Element} from '@core/style';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable from '@core/ui/DataTable';
import Loading from '@core/ui/Loading';

const BackdatedGroup = ({organization, rows}) => {
  const rowColumns = [
    {
      title: 'Publisher',
      accessor: 'publisherName',
      type: 'string',
    },
    {
      title: 'Campaign',
      accessor: 'campaignName',
      type: 'string',
      Cell: ({data: {campaignName, campaignUrl}}) => (
        <a target='_blank' href={campaignUrl} rel='noreferrer'>
          {campaignName}
        </a>
      ),
    },
    {
      title: 'Podcast',
      accessor: 'feedTitle',
      type: 'string',
      Cell: ({data: {feedTitle, campaignPodcastUrl}}) => (
        <a target='_blank' href={campaignPodcastUrl} rel='noreferrer'>
          {feedTitle}
        </a>
      ),
    },
    {
      title: 'Episode',
      accessor: 'title',
      type: 'string',
      Cell: ({data: {title, campaignEpisodeUrl}}) => (
        <a target='_blank' href={campaignEpisodeUrl} rel='noreferrer'>
          {title}
        </a>
      ),
    },
    {
      title: 'Hours',
      accessor: 'hours',
      type: 'number',
      fmt: comma,
    },
    {
      title: 'Impressions',
      accessor: 'impressions',
      type: 'number',
      fmt: comma,
    },

    {
      title: 'Percent Missing',
      accessor: 'percentMissing',
      type: 'number',
      fmt: toPercent,
    },

    {
      title: 'Frequency',
      accessor: 'freq',
      type: 'number',
      fmt: toFixed,
    },

    {
      title: 'Start',
      accessor: 'start',
      type: 'date',
      fmt: (v) => dayjs(v).format('YYYY-MM-DD HH:00'),
    },
    {
      title: 'End',
      accessor: 'end',
      type: 'date',
      fmt: (v) => dayjs(v).format('YYYY-MM-DD HH:00'),
    },
    {
      title: 'Error',
      accessor: 'backdatedError',
      type: 'boolean',
    },
    {
      title: 'Error Message',
      accessor: 'backdatedErrorMessage',
      type: 'string',
    },
  ];

  const rowData = rows.map(
    ({
      campaignEpisode: {
        id,
        episode,
        campaignPodcast,
        downloadTotal,
        reachTotal,
        backdatedError,
        backdatedErrorMessage,
      },
      impressions,
      ...data
    }) => {
      const {feed} = episode;
      const {campaign} = campaignPodcast;

      const campaignUrl = `https://dash.podsights.com/p/${campaign.organization.slug}/campaigns/${campaign.slug}`;
      const campaignPodcastUrl = `${campaignUrl}/podcasts/${campaignPodcast.id}`;
      const campaignEpisodeUrl = `${campaignPodcastUrl}/episodes/${id}`;

      const percentMissing = impressions / downloadTotal;
      const freq = downloadTotal / reachTotal;

      return {
        id,
        title: episode.title,
        feedTitle: feed.title,
        campaignName: campaign.name,
        publisherName: campaign.organization.name,
        backdatedError,
        backdatedErrorMessage,
        campaignUrl,
        campaignPodcastUrl,
        campaignEpisodeUrl,
        percentMissing,
        freq,
        impressions,
        ...data,
      };
    }
  );

  return (
    <div>
      <Card>
        <CardHeader>{organization.name}</CardHeader>
        <DataTable columns={rowColumns} data={rowData} orderBy='-impressions' />
      </Card>
    </div>
  );
};

class Backdated extends React.Component {
  state = {
    loading: false,
    ranPre: false,
    ranAttribution: false,
  };

  backdateRange = (evt) => {
    evt.preventDefault();
    const {after, before, backdateRange} = this.props;

    this.setState({
      loading: true,
    });

    backdateRange({
      after: after,
      before: before,
    }).then(() => {
      this.setState({
        loading: false,
      });
    });
  };

  render() {
    const {data} = this.props;
    const {loading, complete} = this.state;

    if (data.loading || loading) {
      return <Loading />;
    }

    if (complete) {
      return (
        <Card>
          Backedated the range the best we could, there may be errors still, so
          if you refresh this page you will see them.
        </Card>
      );
    }

    const {backdated} = data.admin.management.attribution;

    return (
      <>
        {backdated.map((group) => (
          <BackdatedGroup {...group} />
        ))}

        <Card
          rules={() => ({
            display: 'grid',
            gridTemplateColumns: '4fr 1fr',
            margin: '1.5rem',
          })}>
          <p>
            Do you want to backdate this range from the publisher's table? It's
            going to take awhile.
          </p>

          <Button
            onClick={this.backdateRange}
            disabled={loading}
            color='primary'>
            Backdate the Range
          </Button>
        </Card>
      </>
    );
  }
}

export default compose(
  graphql(BackdatedQuery, {
    options: ({after, before, freqGte, byPublished}) => {
      return {
        variables: {
          after: after.format('YYYY-MM-DD'),
          before: before.format('YYYY-MM-DD'),
          freqGte,
          byPublished,
        },
      };
    },
  }),
  graphql(BackdateRangeMutation, {
    props: ({mutate}) => ({
      backdateRange: (input) => {
        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  })
)(Backdated);
