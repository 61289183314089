import {forwardRef} from 'react';
import {Link as MuiLink, LinkProps as MuiLinkProps} from '@mui/material';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import {focusVisibleCss} from '@core/style';
import {TextProps} from '../Text';

export type LinkProps = Omit<RouterLinkProps, 'to'> & {
  color?: TextProps['color'];
  to?: RouterLinkProps['to'];
  underline?: MuiLinkProps['underline'];
  variant?: TextProps['variant'];
};

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  function LinkWithRef(
    {color = 'default', to, underline = 'hover', variant, ...props},
    ref
  ) {
    return (
      <MuiLink
        ref={ref}
        variant={variant}
        underline={underline}
        {...(to ? {component: RouterLink, to} : undefined)}
        css={`
          color: var(--text-${color});
          ${color === 'default'
            ? `:hover {
              color: var(--primary);
            }`
            : ''}
          ${focusVisibleCss}
        `}
        {...props}
      />
    );
  }
);
