import ProductQuery from '@admin/graphql-api/_old_queries/ProductQuery';
import {graphql} from '@apollo/client/react/hoc';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import {Element} from '@core/style';
import {DropdownMenu} from '@core/ui/DropdownMenu';
import {Icon} from '@core/ui/Icon';
import {Link} from '@core/ui/Link';
import Loading from '@core/ui/Loading';
import Stat from '@core/ui/Stat';
import ProductDelete from './ProductDelete';
import ProductDetail from './ProductDetail';
import ProductImage from './ProductImage';
import ProductMerge from './ProductMerge';
import ProductUpdate from './ProductUpdate';

const ProductIndex = ({company, data: {loading, admin}}) => {
  if (loading) {
    return <Loading absolute />;
  }

  const {product} = admin;

  return (
    <Element rules={() => ({maxWidth: '900px', margin: '50px auto'})}>
      <Element
        rules={() => ({
          padding: '20px 0',
          borderBottom: '1px solid #ddd',
          marginBottom: '10px',
        })}>
        <Element rules={() => ({display: 'flex'})}>
          {product.image ? (
            <Element rules={() => ({flex: '0 0 150px', padding: '0 10px 0 0'})}>
              <img src={product.image.src} />
            </Element>
          ) : (
            <Link
              to={`/companies/${product.id}/image`}
              css={`
                display: block;
                background: #efefef;
                padding: 30px 20px;
              `}>
              No Image
            </Link>
          )}

          <Element rules={() => ({flex: 1, marginLeft: '10px'})}>
            <h4>
              <Link to={`/companies/${product.id}`}>{product.name}</Link>
            </h4>
            <b>{product.url}</b>
            <p>{product.description}</p>
          </Element>
          <Element rules={() => ({display: 'flex'})}>
            <Stat value={product.feedsCount} title='feedsCount' />
            <Stat value={product.episodesCount} title='episodesCount' />
            <Stat value={product.productsCount} title='productsCount' />
            <Stat value={product.adsCount} title='adsCount' />
            <DropdownMenu
              items={[
                {
                  title: 'Update',
                  to: `/companies/${company.id}/products/${product.id}/update`,
                },
                {
                  title: 'Merge',
                  to: `/companies/${company.id}/products/${product.id}/merge`,
                },
                {
                  title: 'Delete',
                  to: `/companies/${company.id}/products/${product.id}/delete`,
                },
              ]}
              css={`
                background: var(--bg-muted);
                border-radius: 0.5rem;
                color: var(icon-1);
                margin: 0 0.5rem;
                padding: 0.375rem;
                transform: rotate(90deg);
                transition: background 0.2s linear;
                :hover {
                  background: var(--bg-default-hover);
                  color: var(--blue);
                }
                :active {
                  boxshadow: var(--shadow-focus);
                }
              `}>
              <Icon icon='more' />
            </DropdownMenu>
          </Element>
        </Element>
      </Element>
      <Element>
        <Switch>
          <PropsRoute
            exact
            path='/companies/:companyId/products/:productId'
            company={company}
            product={product}
            component={ProductDetail}
          />

          <PropsRoute
            exact
            path='/companies/:companyId/products/:productId/update'
            company={company}
            product={product}
            component={ProductUpdate}
          />

          <PropsRoute
            exact
            path='/companies/:companyId/products/:productId/delete'
            company={company}
            product={product}
            component={ProductDelete}
          />

          <PropsRoute
            exact
            path='/companies/:companyId/products/:productId/image'
            company={company}
            product={product}
            component={ProductImage}
          />

          <PropsRoute
            exact
            path='/companies/:companyId/products/:productId/merge'
            company={company}
            product={product}
            component={ProductMerge}
          />
        </Switch>
      </Element>
    </Element>
  );
};

export default graphql(ProductQuery, {
  options: ({
    match: {
      params: {productId},
    },
  }) => ({
    variables: {
      id: productId,
    },
  }),
})(ProductIndex);
