import {
  Plugin,
  PluginEventTypes,
  PluginMediator,
  RequestOptions,
} from '../../typedefs';

import {PluginEvent} from '../../enums/plugin_event';

export const REQUEST_TRANSFORMER_PLUGIN_NAME = 'request-transformer';

export type RequestTransformerOptions = {
  /**
   * A function that takes a string URI and returns a new URI.
   */
  uriTransformer?: (uri: string) => string;

  /**
   * The RequestOptions overrides. All requests parsed by the transformer will
   * be modified to use these options.
   */
  optionsOverrides?: RequestOptions;

  /**
   * A function that is used to determine whether a request should be
   * transformed. If not provided, all requests are transformed.
   *
   * If the function returns is true, the request is transformed. Otherwise, the
   * request is ignored.
   */
  include?: (uri: string, options?: Readonly<RequestOptions>) => boolean;
};

class RequestTransformer implements Plugin {
  private _include?: (
    uri: string,
    options?: Readonly<RequestOptions>
  ) => boolean;
  private _uriTransformer?: (url: string) => string;
  private _optionsOverrides?: RequestOptions;

  name: string = REQUEST_TRANSFORMER_PLUGIN_NAME;

  constructor(options: RequestTransformerOptions) {
    this._uriTransformer = options.uriTransformer;
    this._optionsOverrides = options.optionsOverrides;
    this._include = options.include;

    // Rebind
    this._processRequest = this._processRequest.bind(this);
  }

  private _processRequest(
    ev: PluginEventTypes[PluginEvent.TRANSPORT_BEFORE_PROCESS_REQUEST]
  ): void {
    const requestData = ev.data;
    if (
      requestData.options?.metadata?.noRequestTransform ||
      (this._include && !this._include(requestData.uri, requestData.options))
    ) {
      return;
    }

    // If there is a URI Transformer, we pass the original URI to get the
    // new Transformed URI.
    if (this._uriTransformer) {
      requestData.uri = this._uriTransformer(requestData.uri);
    }

    const optionsOverrides = this._optionsOverrides;
    if (optionsOverrides) {
      if (!requestData.options) {
        // If there are no request options, we just used the defaults defined
        // here.
        requestData.options = optionsOverrides;
      } else {
        // We need to merge the two options.
        const mergedOptions = {
          // First merge everything
          ...requestData.options,
          ...optionsOverrides,

          // Then merge any object options
          metadata: {
            ...requestData.options.metadata,
            ...optionsOverrides.metadata,
          },
          retry: {
            ...requestData.options.retry,
            ...optionsOverrides.retry,
          },
          headers: {
            ...requestData.options.headers,
            ...optionsOverrides.headers,
          },
        };

        requestData.options = mergedOptions;
      }
    }
  }

  attach(_t: unknown, m: PluginMediator): void {
    m.on(PluginEvent.TRANSPORT_BEFORE_PROCESS_REQUEST, this._processRequest);
  }

  detach(_t: unknown, m: PluginMediator): void {
    m.removeListener(
      PluginEvent.TRANSPORT_BEFORE_PROCESS_REQUEST,
      this._processRequest
    );
  }
}

/**
 * Creates a new RequestTransformer plugin.
 *
 * This function should be passed to `transport.addPlugin()` directly.
 *
 * @param _t - The Transport instance.
 * @param options - The options for the plugin.
 * @returns A RequestTransformer plugin instance.
 */
export function requestTransformerCreator(
  _t: unknown,
  options: RequestTransformerOptions
): RequestTransformer {
  return new RequestTransformer(options);
}
