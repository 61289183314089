import {gql} from '@apollo/client';

export default gql`
  query getLiftReports {
    admin {
      liftReports {
        id
        state
        runDate
        impressionsStartAt
        impressionsEndAt

        campaigns {
          id
          name
          slug
          goal
          downloadTotal
          advertiser {
            id
            name
          }

          organization {
            id
            name
            slug
          }
        }

        requestedBy {
          id
          firstName
          lastName
          email
        }

        requesterOrganization {
          id
          name
        }

        createdAt
      }
    }
  }
`;
