export type CorrelationIdContext = {
  name: 'context_correlation_id';
  data: {
    value: string;
  };
};

/**
 * A builder for CorrelationId
 *
 * @param correlationId - For Device events: unique id per App installation. For Browser events: users of the sending Javascript SDK can set this value: the recommendation is to use the Spotify cookie (sp_t), if available.
 * @return The formatted event data for CorrelationIdContext
 */
export function createCorrelationIdContext(
  correlationId: string
): () => CorrelationIdContext {
  return () => {
    return {
      name: 'context_correlation_id',
      data: {
        value: correlationId,
      },
    };
  };
}
