import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {comma} from '@core/lib/filters';
import {clampValue} from '../../../lib/utils';
import {Input} from '../../FormElements';
import {Overlay, useOverlay} from '../../Overlay';
import RangeSlider from '../../RangeSlider';
import DataTableFilterButton from '../misc/DataTableFilterButton';
import {
  DataTableColumn,
  DataTableFilterProps,
  DataTableValuesMap,
  Interval,
} from '../types';
import {InputPrefix} from './InputPrefix';
import {getMinMax} from './utils';

const inputRule = () => ({
  fontSize: 'inherit',
  height: '2.125rem',
  padding: '0 0 .0625rem 2.5rem',
  width: '8rem',
});

interface BarsVisualizationProps {
  column: DataTableColumn;
  data: any[];
  max: number;
  valuesMap: DataTableValuesMap;
}

const BarsVisualization = ({
  column,
  data,
  max,
  valuesMap,
}: BarsVisualizationProps) => {
  const [bars, setBars] = useState([]);

  const ref = useCallback((node) => {
    if (node === null) return;
    const barsCount = Math.ceil(node.getBoundingClientRect().width / 9);
    const myBars = data.reduce(
      (acc, d) => {
        const value = valuesMap.get(column)?.get(d);
        const i = Math.floor((value / max) * barsCount);

        if (acc[i]) {
          acc[i].push(value);
        } else {
          acc[i] = [value];
        }

        return acc;
      },
      Array.from(Array(barsCount), () => [])
    );

    setBars(myBars);
  }, []);

  const maxCount = bars.reduce((acc, arr) => {
    if (arr.length > acc) {
      return arr.length;
    }
    return acc;
  }, 0);

  return (
    <div
      ref={ref}
      css={{
        alignItems: 'flex-end',
        display: 'grid',
        gridGap: '0.25rem',
        gridTemplateColumns: `repeat(${bars.length}, 1fr)`,
        height: '5rem',
      }}>
      {bars.map((values, i) => (
        <div
          key={i}
          css={{
            background: `linear-gradient(180deg, var(--green) 0%, #c8f1e2 100%)`,
            borderRadius: '0.125rem',
            height: `${
              values.length ? Math.max(6, (values.length * 100) / maxCount) : 0
            }%`,
            minHeight: 0,
            minWidth: 0,
            width: '100%',
          }}
        />
      ))}
    </div>
  );
};

interface IndexRangeFilterProps extends DataTableFilterProps {}

const IndexRangeFilter = forwardRef(
  (
    {data, defaultValue, column, valuesMap, onChange}: IndexRangeFilterProps,
    ref
  ) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const minInputRef = useRef<HTMLInputElement>(null);
    const maxInputRef = useRef<HTMLInputElement>(null);
    const rangeSliderRef = useRef<HTMLElement>(null);
    const minMaxRange = useMemo(
      () => getMinMax({data, valuesMap, column}),
      [data, valuesMap, column]
    );
    const [opened, toggleOverlay] = useOverlay();
    const selectedRangeRef = useRef<Interval>(
      defaultValue && defaultValue.length === 2
        ? [
            clampValue(defaultValue[0], minMaxRange[0], minMaxRange[1]),
            clampValue(defaultValue[1], minMaxRange[0], minMaxRange[1]),
          ]
        : minMaxRange
    );
    const [selectedRange, setSelectedRange] = useState<Interval>(
      selectedRangeRef.current
    );

    const handleOnChange = (range: Interval) => {
      selectedRangeRef.current = range;
      requestAnimationFrame(() => {
        setSelectedRange(range);
        onChange({column, defaultValue: range});
      });
    };

    const onInputChange = (
      evt: React.FormEvent<HTMLInputElement>,
      name: 'min' | 'max'
    ) => {
      const target = evt.target as HTMLInputElement;
      const newValue = clampValue(
        target.value,
        name === 'min' ? minMaxRange[0] : selectedRangeRef.current[0],
        name === 'min' ? selectedRangeRef.current[1] : minMaxRange[1]
      );
      // Update Input with clamp value
      if (newValue !== Number(target.value)) {
        target.value = String(newValue);
      }
      const newRange = selectedRangeRef.current.slice(0);
      newRange[name === 'min' ? 0 : 1] = newValue;
      // Update Slider value
      (rangeSliderRef.current as any).setRange(newRange);
      handleOnChange(newRange as Interval);
    };

    useImperativeHandle(ref, () => ({
      filter: (filteredData: any[]) =>
        filteredData.filter((d) => {
          const value = valuesMap.get(column)?.get(d);
          return (
            value >= selectedRangeRef.current[0] &&
            value <= selectedRangeRef.current[1]
          );
        }),
      clear: () => handleOnChange(minMaxRange),
    }));

    const isFilterActive =
      selectedRange[0] !== minMaxRange[0] ||
      selectedRange[1] !== minMaxRange[1];

    return (
      <>
        <DataTableFilterButton
          domRef={buttonRef}
          active={isFilterActive}
          disabled={minMaxRange[0] === minMaxRange[1]}
          onClick={() => toggleOverlay(true)}>
          {column.title}
          {isFilterActive
            ? `: ${comma(selectedRange[0])} - ${comma(selectedRange[1])}`
            : null}
        </DataTableFilterButton>
        <Overlay
          opened={opened}
          toggle={toggleOverlay}
          positionTarget={buttonRef.current}
          horizontalAlign='right'
          transparentBackdrop
          withBackdrop
          withShadow
          verticalOffset={2}
          css={`
            padding: 1rem;
          `}>
          <div
            css={{
              display: 'grid',
              gridGap: '0.625rem',
              gridTemplateColumns: '1fr 1fr',
              marginBottom: '1.25rem',
            }}>
            <InputPrefix prefix='Min'>
              <Input
                type='number'
                domRef={minInputRef}
                defaultValue={selectedRange[0]}
                onChange={(evt) => onInputChange(evt, 'min')}
                small
                rules={[inputRule, () => ({paddingLeft: '2.25rem'})]}
              />
            </InputPrefix>
            <InputPrefix prefix='Max'>
              <Input
                type='number'
                domRef={maxInputRef}
                defaultValue={selectedRange[1]}
                onChange={(evt) => onInputChange(evt, 'max')}
                small
                rules={[inputRule]}
              />
            </InputPrefix>
          </div>
          <div css={{padding: '0 0.5rem'}}>
            <BarsVisualization
              data={data}
              column={column}
              valuesMap={valuesMap}
              max={minMaxRange[1]}
            />
            <RangeSlider
              ref={rangeSliderRef}
              min={minMaxRange[0]}
              max={minMaxRange[1]}
              defaultRange={selectedRange}
              onChange={handleOnChange}
            />
          </div>
        </Overlay>
      </>
    );
  }
);

export default IndexRangeFilter;
