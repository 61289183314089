import {randomString} from './random_string';

/**
 * Create a generator instance.
 */
export class SequenceIdGenerator {
  private _sequenceId: string = randomString.generateBase64(16);
  private _sequenceNumbers: Record<string, number> = {};

  /**
   * Create a generator instance.
   *
   * @return A sequence_id and sequence_number generator
   * instance.
   */
  static create(): SequenceIdGenerator {
    return new SequenceIdGenerator();
  }

  /**
   * Get the latest sequence_id.
   *
   * @return The sequence_id used to identify events.
   */
  getSequenceId(): string {
    return this._sequenceId;
  }

  /**
   * Generate the next sequence_number to identify events.
   *
   * @param eventName - The name of the event to generate a sequence_number
   * for.
   * @return Incremental number starting from 1.
   */
  nextSequenceNumber(eventName: string): number {
    if (!this._sequenceNumbers[eventName]) {
      this._sequenceNumbers[eventName] = 0;
    }
    return ++this._sequenceNumbers[eventName];
  }

  /**
   * Get all sequence_numbers.
   *
   * @return All sequence numbers.
   */

  getAllSequenceNumberCounters(): Record<string, number> {
    return this._sequenceNumbers;
  }
}
