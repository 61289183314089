import {Redirect, Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import dayjs from '@core/lib/dayjs';
import RangeIndex from './range';

const OrganizationBackfill = ({organization}) => {
  return (
    <Redirect
      to={`/organizations/${organization.id}/backfill/${dayjs()
        .subtract(30, 'days')
        .format('YYYY-MM-DD')}/${dayjs()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')}`}
    />
  );
};

const BackfillIndex = ({organization}) => {
  return (
    <Switch>
      <PropsRoute
        exact
        path='/organizations/:organizationId/backfill'
        organization={organization}
        component={OrganizationBackfill}
      />
      <PropsRoute
        path='/organizations/:organizationId/backfill/:start/:end'
        organization={organization}
        component={RangeIndex}
      />
    </Switch>
  );
};

export default BackfillIndex;
