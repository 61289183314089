import TEXT from './texts';

const resolveTexts = (part: any, variables: {[key: string]: any}): any => {
  if (typeof part === 'string') {
    return part;
  } else if (typeof part === 'function') {
    return part(variables || {});
  }
  const resp: any = {};
  for (const key in part) {
    if (part.hasOwnProperty(key)) {
      resp[key] = resolveTexts(part[key], variables);
    }
  }
  return resp;
};

export const Text = (
  accessor: string,
  variables?: {[key: string]: any}
): any => {
  try {
    const part = accessor.split('.').reduce((_texts, key) => {
      return _texts && _texts[key];
    }, TEXT);

    if (part) {
      return resolveTexts(part, variables);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
  }

  return null;
};
