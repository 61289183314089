import {gql} from '@apollo/client';

export default gql`
  query getFutureEpisode($id: ID!) {
    admin {
      futureEpisode(id: $id) {
        id
        title
        subtitle

        expectedPublished

        podcast {
          id
          feed {
            id
            title
            description(words: 25)
            href
            image {
              id
              href
              path
            }
          }
        }

        campaignEpisodes {
          id

          episode {
            id
            title
            description(words: 25)
            image {
              id
              href
              path
            }
          }

          campaignPodcast {
            id
            campaign {
              id
              name
              slug
              organization {
                id
                slug
                kind
              }
            }
          }
        }

        updatedAt
        createdAt
      }
    }
  }
`;
