import {FC} from 'react';
import {Button} from '../Button';
import {Tooltip} from '../Tooltip';
import {DataTableBulkAction} from './types';

interface DataTableBulkActionsProps {
  bulkActions: DataTableBulkAction[];
  selectedRows: any[];
}

const DataTableBulkActions: FC<DataTableBulkActionsProps> = ({
  bulkActions,
  selectedRows,
}): JSX.Element => {
  return (
    <div
      css={`
        align-items: center;
        display: flex;
        gap: 0.5rem;
        margin-bottom: 0.875rem;
      `}>
      {bulkActions.map(
        ({title, onClick, description}, i) =>
          title && (
            <Tooltip key={i} active={!!description} description={description}>
              <Button
                onClick={() => onClick?.(selectedRows)}
                color='primary'
                css={`
                  margin-right: var(-spacing-3);
                  user-select: none;
                `}>
                {title}
              </Button>
            </Tooltip>
          )
      )}
      <div
        css={`
          color: var(--text-muted);
          font-size: 0.875rem;
          margin: 0 0.5rem;
        `}>
        {selectedRows.length} selected
      </div>
    </div>
  );
};

export default DataTableBulkActions;
