import {Link} from '@core/ui/Link';
import ErrorBase from './ErrorBase';

interface NotAuthorizedErrorProps {
  user?: {
    email?: string | null;
  };
}

const NotAuthorizedError: React.FC<NotAuthorizedErrorProps> = ({user}) => {
  const email = user?.email || 'Unknown User';

  return (
    <ErrorBase title='Not Authorized' baseColor='var(--blue)'>
      <p>
        You (<b>{email}</b>) are not authorized to view this page.
        <br />
        Please head back{' '}
        <Link
          to='/'
          css={`
            color: var(--blue);
          `}>
          Go Home
        </Link>
      </p>
    </ErrorBase>
  );
};

export default NotAuthorizedError;
