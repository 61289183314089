import {useState} from 'react';
import {FetchedAdminCampaign, useGetCampaignQuery} from '@admin/graphql-api';
import {Banner, Snackbar} from '@spotify-internal/encore-web';
import {t} from '@core/texts';
import {ModalHeader} from '@core/ui/Content';
import {Stack} from '@core/ui/Layout';
import Loading from '@core/ui/Loading';
import {CampaignDetails} from './CampaignDetails';
import {CampaignToolbar} from './CampaignToolbar';

export function Campaign({campaignId, onClose}) {
  const [updatingCampaign, setUpdatingCampaign] = useState(false);
  const [campaignError, setCampaignError] = useState<string>();

  const {data, loading, error} = useGetCampaignQuery({
    variables: {
      id: campaignId,
    },
  });

  if (loading || updatingCampaign) {
    return <Loading absolute />;
  }

  if (error) {
    return <Banner color='negative'>{t('errors.network')}</Banner>;
  }

  const campaign = data?.admin?.campaign as FetchedAdminCampaign;

  const onCampaignError = (_error: string) => {
    setCampaignError(_error);
    setTimeout(() => setCampaignError(undefined), 3000);
  };

  return (
    <>
      <Stack direction='column' css={{padding: '1.75rem'}}>
        <ModalHeader css={{position: 'sticky'}} toggle={onClose} withCloseIcon>
          {campaign?.name}
        </ModalHeader>
        <CampaignToolbar campaign={campaign} />
        <CampaignDetails
          campaign={campaign}
          onCampaignError={onCampaignError}
          setUpdatingCampaign={setUpdatingCampaign}
        />
      </Stack>
      <Snackbar
        colorSet='negative'
        onExited={() => setCampaignError(undefined)}
        showSnackbar={!!campaignError}>
        {campaignError}
      </Snackbar>
    </>
  );
}
