import { balladBold, finaleBold, violaBold } from "../index.js";
export var buttonSizesWithDeprecationWarning = {
  /**
   * @deprecated
   * Use buttonSize="sm" instead.
   */
  sm: 'sm',
  /**
   * @deprecated
   * Use buttonSize="md" instead.
   */
  md: 'md',
  /**
   * @deprecated
   * Use buttonSize="lg" instead.
   */
  lg: 'lg'
};
export var deprecatedTypeVariants = {
  sm: 'finaleBold',
  md: 'violaBold',
  lg: 'balladBold'
};
export var getDeprecatedButtonFont = function getDeprecatedButtonFont(buttonSize) {
  switch (buttonSize) {
    case 'small':
      return finaleBold();
    case 'medium':
      return violaBold();
    case 'large':
      return balladBold();
    default:
      return violaBold();
  }
};
export var deprecatedButtonPaddingTopBottom = {
  small: '8px' /* TODO: can we use an encore token here? */,
  medium: '14px' /* TODO: can we use an encore token here? */,
  large: '16px' /* TODO: can we use an encore token here? */
};