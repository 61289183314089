import {PropsWithChildren, createContext, useContext, useMemo} from 'react';
import {ThemeProvider as MuiThemeProvider} from '@mui/material';
import {getMuiTheme} from '@core/style/muiTheme';

export type Theme = 'light' | 'dark';
export const ThemeContext = createContext<Theme>('light');
export const useTheme = () => useContext(ThemeContext);

export function ThemeProvider({
  theme,
  ...props
}: PropsWithChildren<{theme: Theme}>) {
  const muiTheme = useMemo(() => getMuiTheme(theme), [theme]);

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeContext.Provider value={theme} {...props} />
    </MuiThemeProvider>
  );
}

/**
 * Utils to get the system theme.
 */
export const getSystemTheme = (): Theme =>
  window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
