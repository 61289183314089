import {useEffect, useRef, useState} from 'react';
import {IconChevronDown} from '@spotify-internal/encore-web';
import {TRules, css} from '@core/style';
import {Icon} from '../Icon';
import {MultipleSelect, MultipleSelectProps} from '../Select';

/**
 * @deprecated
 */
export interface LabelMultipleSelectProps
  extends Omit<
    MultipleSelectProps,
    'placeholder' | 'rules' | 'onSelect' | 'singleSelection'
  > {
  label: string;
  onSelect?: (_items: any[] | any) => void;
  rules?: TRules;
}

/**
 * @deprecated
 */
const LabelMultipleSelect = ({
  defaultValue,
  getSelectLabel,
  invalid = false,
  items,
  label,
  onSelect,
  outline = true,
  propertyForValue = 'value',
  propertyForName = 'title',
  rules,
  ...multipleSelectProps
}: LabelMultipleSelectProps) => {
  const updated = useRef<boolean>(false);
  const [value, setValue] = useState(
    typeof defaultValue !== 'undefined' ? defaultValue : null
  );

  const isActive = !!value?.length;

  const _onSelect = (value: any) => {
    if (typeof onSelect === 'function' && updated.current) {
      onSelect(value);
    }
    setValue(value);
  };

  const _getSelectLabel = (items: any[]) => {
    if (typeof getSelectLabel === 'function') {
      return getSelectLabel(items);
    }
    return (
      <>
        {label}: <b>{items.length}</b>
      </>
    );
  };

  useEffect(() => {
    updated.current = true;
  }, []);

  return (
    <div
      {...css([
        () => ({
          position: 'relative',
          lineHeight: '1.5rem',
          ':hover': {
            '> div': {
              color: 'var(--black)',
            },
            '> svg': {
              color: 'var(--black)',
            },
          },
        }),
        rules,
      ])}>
      <div
        css={{
          borderRadius: '.25rem',
          top: '1.275rem',
          color: invalid ? 'var(--red-orange)' : 'var(--text-muted)',
          left: '.1875rem',
          margin: 'auto',
          padding: '0 1rem',
          pointerEvents: 'none',
          position: 'absolute',
          transform: isActive ? 'scale(.75) translateY(-15px)' : undefined,
          transformOrigin: 'left',
          transition:
            'transform 150ms cubic-bezier(0.4,0,0.2,1), opacity 150ms cubic-bezier(0.4,0,0.2,1)',
        }}>
        {label}
      </div>
      <MultipleSelect
        defaultValue={
          typeof defaultValue !== 'undefined' && defaultValue !== null
            ? defaultValue
            : []
        }
        getSelectLabel={(items) => _getSelectLabel(items)}
        noChevronIcon
        onSelect={(selectedItems) => _onSelect(selectedItems)}
        outline={outline}
        invalid={invalid}
        items={items}
        placeholder={''}
        propertyForName={propertyForName}
        propertyForValue={propertyForValue}
        rules={() => ({
          background: 'var(--bg-default)',
          padding: '1.625rem 2.625rem .5rem 1rem',
          minHeight: '3.875rem',
          lineHeight: '1.75rem',
          width: '100%',
        })}
        {...multipleSelectProps}
      />

      <IconChevronDown
        style={{
          color: invalid ? 'var(--red-orange)' : 'var(--icon-subtle)',
          height: '1.625rem',
          position: 'absolute',
          right: '1.275rem',
          top: '1.275rem',
          width: '1.625rem',
        }}
      />
    </div>
  );
};

export default LabelMultipleSelect;
