import React, {Children, cloneElement} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {log} from 'console';
import {Redirect, useHistory} from 'react-router-dom';
import {auth} from '@core/components/SignInWithSpotify';
import {loginViaSpotify} from '@core/components/SignInWithSpotify/auth';
import Loading from '@core/ui/Loading';
import {NetworkError} from '../components/AppError';
import MeQuery from '../graphql-api/queries/MeQuery';

const UnAuthHome = () => <Redirect to='/welcome' />;

const UnAuthNoMatch = ({match: {url}}) => (
  <Redirect
    to={{
      pathname: '/signin',
      state: {path: url},
    }}
  />
);

const AuthedImpl = ({children, data}) => {
  const {loading, error, me, userInfo} = data;
  const currentPath = window.location.pathname;
  const history = useHistory();

  if (loading) {
    return <Loading fixed />;
  } else if (/The access token expired/i.test(error)) {
    loginViaSpotify({to: currentPath, history});
  } else if (
    userInfo?.hasSpaaUser === false ||
    /No account found for token/i.test(error)
  ) {
    if (
      currentPath !== '/account-confirm' &&
      currentPath !== '/email-confirm'
    ) {
      history.push('/account-confirm?error=new-user');
    }
  } else if (
    userInfo?.hasVerifiedEmailForSpaa === false ||
    /User needs to verify email /i.test(error)
  ) {
    if (
      currentPath !== '/account-confirm' &&
      currentPath !== '/email-confirm'
    ) {
      history.push('/account-confirm?error=email-not-confirmed');
    }
  } else if (/network\serror/i.test(error)) {
    return <NetworkError />;
  } else if (error) {
    console.warn(error);
    localStorage.removeItem('adanalytics-token');
    return <NetworkError />;
  }
  return Children.map(children, (child) => cloneElement(child, {me}));
};

const Authed = graphql(MeQuery, {
  options: ({service}) => ({
    errorPolicy: 'all',
    variables: {
      service,
    },
  }),
})(AuthedImpl);

const Auth = ({children, service, UnAuthed}) => {
  const token = localStorage.getItem('adanalytics-token');

  if (token) {
    return <Authed service={service}>{children}</Authed>;
  } else if (UnAuthed) {
    return <UnAuthed />;
  }
  return null;
};

export {UnAuthHome, UnAuthNoMatch};

export default Auth;
