import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { lightTheme } from '@spotify-internal/encore-foundation';
import { calcAccessibleContrast, getHighContrastColor, hexToRgb, hslToRgb, rgbToHex, rgbToHsl } from "./color.js";
export var semanticBackgroundColors = {
  backgroundBase: 'backgroundBase',
  backgroundHighlight: 'backgroundHighlight',
  backgroundPress: 'backgroundPress',
  backgroundElevatedBase: 'backgroundElevatedBase',
  backgroundElevatedHighlight: 'backgroundElevatedHighlight',
  backgroundElevatedPress: 'backgroundElevatedPress',
  backgroundTintedBase: 'backgroundTintedBase',
  backgroundTintedHighlight: 'backgroundTintedHighlight',
  backgroundTintedPress: 'backgroundTintedPress',
  backgroundUnsafeForSmallTextBase: 'backgroundUnsafeForSmallTextBase',
  backgroundUnsafeForSmallTextHighlight: 'backgroundUnsafeForSmallTextHighlight',
  backgroundUnsafeForSmallTextPress: 'backgroundUnsafeForSmallTextPress'
};
export var semanticTextColors = {
  textBase: 'textBase',
  textSubdued: 'textSubdued',
  textBrightAccent: 'textBrightAccent',
  textNegative: 'textNegative',
  textWarning: 'textWarning',
  textPositive: 'textPositive',
  textAnnouncement: 'textAnnouncement'
};
export var semanticEssentialColors = {
  essentialBase: 'essentialBase',
  essentialSubdued: 'essentialSubdued',
  essentialBrightAccent: 'essentialBrightAccent',
  essentialNegative: 'essentialNegative',
  essentialWarning: 'essentialWarning',
  essentialPositive: 'essentialPositive',
  essentialAnnouncement: 'essentialAnnouncement'
};
export var semanticDecorativeColors = {
  decorativeBase: 'decorativeBase',
  decorativeSubdued: 'decorativeSubdued'
};
export var semanticForegroundColors = _objectSpread(_objectSpread(_objectSpread({}, semanticTextColors), semanticEssentialColors), semanticDecorativeColors);
export var semanticColors = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, semanticBackgroundColors), semanticTextColors), semanticEssentialColors), semanticDecorativeColors);
/**
 * Theme default constants for Encore Web
 * */
export var defaultTheme = lightTheme;
export var defaultColorSetName = 'base';
export var defaultColorSet = defaultTheme[defaultColorSetName];
export var colorSets = Object.keys(defaultTheme);
var capitalize = function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Takes a deeply nested object and returns a flattend map of all the values
 * with the keys camelCased.
 * @param obj - a nested object of string values
 * @param string - the parent key of an object used for recursion.
 * @returns an object with camcelCased keys and string values
 */
export var getFlatCamelCaseMap = function getFlatCamelCaseMap(obj) {
  var previousKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return Object.entries(obj).reduce(function (prevObj, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    var camelKey = key;
    if (previousKey !== '') {
      camelKey = "".concat(previousKey).concat(capitalize(key));
    }
    if (typeof value === 'object') {
      return _objectSpread(_objectSpread({}, prevObj), getFlatCamelCaseMap(value, camelKey));
    } else if (typeof value === 'string') {
      prevObj[camelKey] = value;
      return prevObj;
    }
    return prevObj;
  }, {});
};
function camelToKebab(inputString) {
  return inputString.replace(/[A-Z0-9]/g, function (m) {
    return "-".concat(m.toLowerCase());
  });
}
export function makeColorSetClass(colorSet) {
  return colorSet ? "encore-".concat(camelToKebab(colorSet), "-set") : '';
}
export function makeCampaignCategoryColorSetClass(colorSet) {
  if (!colorSet) return '';
  var removedSetKeyword = colorSet.replace('Set', '');
  return "encore-".concat(camelToKebab(removedSetKeyword), "-set");
}
/**
 * Appends colorSet classname to the passed className string
 * @param colorSet - A colorSet (eg. 'base', 'negative', 'brightAccent')
 * @param className - Other classnames passed from the elements
 * @returns string of passed classnames plus the colorset class
 */
export function addColorSet(colorSet, className) {
  if (colorSet in lightTheme) {
    return [makeColorSetClass(colorSet), className].join(' ').trim();
  }
  return [makeCampaignCategoryColorSetClass(colorSet), className].join(' ').trim();
}

/**
 * Returns the css variable format of a semanticColor
 * @param semanticColor - a semanticColor (eg 'textBase,' 'decorativeSubdued')
 * @returns a css variable in kebabcase format with a '--' prefix
 */
export function cssColorVar(semanticColor) {
  return "--".concat(camelToKebab(semanticColor));
}
export function cssColorSet(colorSetObject) {
  return Object.entries(getFlatCamelCaseMap(colorSetObject)).map(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      token = _ref4[0],
      hex = _ref4[1];
    return "\n      ".concat(cssColorVar(token), ": ").concat(hex, " !important;\n    ");
  });
}

/**
 * Get a CSS value that can be used when authoring CSS color rules
 * @param semanticColor - a semanticColor (eg 'textBase,' 'decorativeSubdued')
 * @param [fallbackColor] - a valid CSS <color> value as fallback
 * @returns a string in the form of `var(--semantic-color-variable, <fallback>)`
 */
export function cssColorValue(semanticColor, fallbackColor) {
  var flattenedDefaultColorSet = getFlatCamelCaseMap(defaultColorSet);
  var fallback = flattenedDefaultColorSet[semanticColor];
  if (fallbackColor) {
    fallback = fallbackColor;
  }
  return "var(".concat(cssColorVar(semanticColor), ", ").concat(fallback, ")");
}

/**
 * Create a simple color set from a custom color, that meets contrast requirements
 * @param backgroundHex
 * @param [suggestedForegroundHex]
 */
export var createBasicColorSet = function createBasicColorSet(backgroundHex, suggestedForegroundHex) {
  var adjustment = 5;
  var accessibleContrast = 4.45;
  var foregroundHex = suggestedForegroundHex && calcAccessibleContrast(backgroundHex, suggestedForegroundHex) >= accessibleContrast ? suggestedForegroundHex : getHighContrastColor(backgroundHex);
  var foregroundHsl = rgbToHsl(hexToRgb(foregroundHex));
  var backgroundHsl = rgbToHsl(hexToRgb(backgroundHex));
  var foregroundIsLighter = foregroundHsl.l >= backgroundHsl.l;
  var backgroundHighlightHex = foregroundIsLighter ? rgbToHex(hslToRgb({
    h: backgroundHsl.h,
    s: backgroundHsl.s,
    l: backgroundHsl.l - adjustment
  })) : rgbToHex(hslToRgb({
    h: backgroundHsl.h,
    s: backgroundHsl.s,
    l: backgroundHsl.l + adjustment
  }));
  var backgroundPressHex = foregroundIsLighter ? rgbToHex(hslToRgb({
    h: backgroundHsl.h,
    s: backgroundHsl.s,
    l: backgroundHsl.l - adjustment * 2
  })) : rgbToHex(hslToRgb({
    h: backgroundHsl.h,
    s: backgroundHsl.s,
    l: backgroundHsl.l + adjustment * 2
  }));
  return {
    background: {
      base: backgroundHex.toUpperCase(),
      highlight: backgroundHighlightHex.toUpperCase(),
      press: backgroundPressHex.toUpperCase(),
      elevated: {
        base: backgroundHex.toUpperCase(),
        highlight: backgroundHighlightHex.toUpperCase(),
        press: backgroundPressHex.toUpperCase()
      },
      tinted: {
        base: backgroundHex.toUpperCase(),
        highlight: backgroundHighlightHex.toUpperCase(),
        press: backgroundPressHex.toUpperCase()
      },
      unsafeForSmallText: {
        base: backgroundHex.toUpperCase(),
        highlight: backgroundHighlightHex.toUpperCase(),
        press: backgroundPressHex.toUpperCase()
      }
    },
    text: {
      base: foregroundHex.toUpperCase(),
      subdued: foregroundHex.toUpperCase(),
      brightAccent: foregroundHex.toUpperCase(),
      negative: foregroundHex.toUpperCase(),
      warning: foregroundHex.toUpperCase(),
      positive: foregroundHex.toUpperCase(),
      announcement: foregroundHex.toUpperCase()
    },
    essential: {
      base: foregroundHex.toUpperCase(),
      subdued: foregroundHex.toUpperCase(),
      brightAccent: foregroundHex.toUpperCase(),
      negative: foregroundHex.toUpperCase(),
      warning: foregroundHex.toUpperCase(),
      positive: foregroundHex.toUpperCase(),
      announcement: foregroundHex.toUpperCase()
    },
    decorative: {
      base: foregroundHex.toUpperCase(),
      subdued: foregroundHex.toUpperCase()
    }
  };
};