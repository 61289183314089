import { encoreContextStatus } from "../../contexts/EncoreContext.js";
import { balladBold, borderRadiusRounded, browserFocusReset, cello, controlMinBlockSize, controlPaddingBlock, cssControlSize, cssSpacing, cursorDisabled, deprecatedButtonPaddingTopBottom, getDeprecatedButtonFont, mestoBold, opacityActive, opacityDisabled, rootStyle } from "../index.js";
import { css } from "../../styled-components.js";
export var buttonBorderRadius = borderRadiusRounded;
export var buttonTransitionFade = '33ms';
export var buttonTextTransform = 'none';
export var buttonHoverScale = '1.04';

/**
 * Observed left &/or right padding for buttons with `iconLeading` or `iconTrailing`.
 * Note: "Observed" because the true padding is calculated, see `getButtonWithIconLeftRightPadding()`.
 */

export var buttonWithIconSidePadding = function buttonWithIconSidePadding(buttonSize) {
  switch (buttonSize) {
    case 'small':
      return cssSpacing('tighter', '12px');
    case 'large':
      return cssSpacing('looser', '24px');
    default:
      /** --encore-spacing-looser resolves to 24px so we are just using hard coded values here until we refactor our buttons to be able to correcly use SL tokens */
      return '20px';
    /* TODO: can we use an encore token here? */
  }
};

/** Observed margin between icons and text (absolute positioned, not real margin) */
export var buttonWithIconMargin = {
  small: '5px',
  medium: '12px' /* TODO: can we use an encore token here? */,
  large: '10px'
};
export var defaultTypeVariants = {
  sm: 'mestoBold',
  md: 'balladBold',
  lg: 'cello'
};
export var getButtonFont = function getButtonFont(buttonSize, status) {
  if (status === encoreContextStatus.deprecated) {
    return getDeprecatedButtonFont(buttonSize);
  }
  switch (buttonSize) {
    case 'small':
      return mestoBold();
    case 'medium':
      return balladBold();
    case 'large':
      return cello();
    default:
      return balladBold();
  }
};

/** Utility so we can have a pretty style when we don't need to account for the borderWidth */
function calculatePaddingWithBorder(_ref) {
  var property = _ref.property,
    value = _ref.value,
    _ref$borderWidth = _ref.borderWidth,
    borderWidth = _ref$borderWidth === void 0 ? 0 : _ref$borderWidth;
  if (borderWidth > 0) {
    return css(["", ":calc(", " - ", "px);"], property, value, borderWidth);
  }
  return css(["", ":", ";"], property, value);
}

/**
 * Used by primary, secondary, tertiary
 * The padding block properties should be the same for all three types of buttons
 */
function getPaddingBlock(_ref2) {
  var buttonSize = _ref2.buttonSize,
    _ref2$borderWidth = _ref2.borderWidth,
    borderWidth = _ref2$borderWidth === void 0 ? 0 : _ref2$borderWidth,
    status = _ref2.status;
  if (status === 'deprecated') {
    switch (buttonSize) {
      case 'small':
        {
          return css(["", ";", ";"], calculatePaddingWithBorder({
            property: 'padding-block-start',
            value: deprecatedButtonPaddingTopBottom.small,
            borderWidth: borderWidth
          }), calculatePaddingWithBorder({
            property: 'padding-block-end',
            value: deprecatedButtonPaddingTopBottom.small,
            borderWidth: borderWidth
          }));
        }
      case 'large':
        {
          return css(["", ";", ";"], calculatePaddingWithBorder({
            property: 'padding-block-start',
            value: deprecatedButtonPaddingTopBottom.large,
            borderWidth: borderWidth
          }), calculatePaddingWithBorder({
            property: 'padding-block-end',
            value: deprecatedButtonPaddingTopBottom.large,
            borderWidth: borderWidth
          }));
        }
      default:
        {
          return css(["", ";", ";"], calculatePaddingWithBorder({
            property: 'padding-block-start',
            value: deprecatedButtonPaddingTopBottom.medium,
            borderWidth: borderWidth
          }), calculatePaddingWithBorder({
            property: 'padding-block-end',
            value: deprecatedButtonPaddingTopBottom.medium,
            borderWidth: borderWidth
          }));
        }
    }
  }
  switch (buttonSize) {
    case 'small':
      {
        return css(["", ";", ";"], calculatePaddingWithBorder({
          property: 'padding-block-start',
          value: cssSpacing('tighter-4', controlPaddingBlock.small),
          borderWidth: borderWidth
        }), calculatePaddingWithBorder({
          property: 'padding-block-end',
          value: cssSpacing('tighter-4', controlPaddingBlock.small),
          borderWidth: borderWidth
        }));
      }
    case 'large':
      {
        return css(["", ";", ";"], calculatePaddingWithBorder({
          property: 'padding-block-start',
          value: cssSpacing('tighter', controlPaddingBlock.large),
          borderWidth: borderWidth
        }), calculatePaddingWithBorder({
          property: 'padding-block-end',
          value: cssSpacing('tighter', controlPaddingBlock.large),
          borderWidth: borderWidth
        }));
      }
    default:
      {
        return css(["", ";", ";"], calculatePaddingWithBorder({
          property: 'padding-block-start',
          value: cssSpacing('tighter-2', controlPaddingBlock.medium),
          borderWidth: borderWidth
        }), calculatePaddingWithBorder({
          property: 'padding-block-end',
          value: cssSpacing('tighter-2', controlPaddingBlock.medium),
          borderWidth: borderWidth
        }));
      }
  }
}

/**
 * Used by primary, secondary, tertiary
 * Takes into account tertiary's condensed prop
 */
export function getNoneIconsPadding(_ref3) {
  var buttonSize = _ref3.buttonSize,
    _ref3$borderWidth = _ref3.borderWidth,
    borderWidth = _ref3$borderWidth === void 0 ? 0 : _ref3$borderWidth,
    status = _ref3.status,
    condensed = _ref3.condensed;
  var paddingInline = function paddingInline() {
    switch (buttonSize) {
      case 'small':
        return css(["", ";", ";"], calculatePaddingWithBorder({
          property: 'padding-inline-start',
          value: cssSpacing('base', '16px'),
          borderWidth: borderWidth
        }), calculatePaddingWithBorder({
          property: 'padding-inline-end',
          value: cssSpacing('base', '16px'),
          borderWidth: borderWidth
        }));
      case 'large':
        return css(["", ";", ";"], calculatePaddingWithBorder({
          property: 'padding-inline-start',
          value: cssSpacing('looser-3', '48px'),
          borderWidth: borderWidth
        }), calculatePaddingWithBorder({
          property: 'padding-inline-end',
          value: cssSpacing('looser-3', '48px'),
          borderWidth: borderWidth
        }));
      default:
        return css(["", ";", ";"], calculatePaddingWithBorder({
          property: 'padding-inline-start',
          value: cssSpacing('looser-2', '32px'),
          borderWidth: borderWidth
        }), calculatePaddingWithBorder({
          property: 'padding-inline-end',
          value: cssSpacing('looser-2', '32px'),
          borderWidth: borderWidth
        }));
    }
  };
  if (condensed) {
    paddingInline = function paddingInline() {
      return css(["padding-inline-start:0;padding-inline-end:0;"]);
    };
  }
  return css(["", ";", ";"], getPaddingBlock({
    buttonSize: buttonSize,
    borderWidth: borderWidth,
    status: status
  }), paddingInline);
}

/** Used by primary & secondary */
function getWithIconPadding(_ref4) {
  var buttonSize = _ref4.buttonSize,
    iconPlacement = _ref4.iconPlacement,
    _ref4$borderWidth = _ref4.borderWidth,
    borderWidth = _ref4$borderWidth === void 0 ? 0 : _ref4$borderWidth,
    status = _ref4.status;
  var paddingInline = function paddingInline() {
    switch (buttonSize) {
      case 'small':
        {
          switch (iconPlacement) {
            case 'leading':
              {
                return css(["", ";", ";"], calculatePaddingWithBorder({
                  property: 'padding-inline-start',
                  /** --encore-spacing-looser-2 resolves to 33px so we are just using hard coded values here until we refactor our buttons to be able to correcly use SL tokens */
                  value: '33px',
                  borderWidth: borderWidth
                }), calculatePaddingWithBorder({
                  property: 'padding-inline-end',
                  value: cssSpacing('base', '16px'),
                  borderWidth: borderWidth
                }));
              }
            case 'trailing':
              {
                return css(["", ";", ";"], calculatePaddingWithBorder({
                  property: 'padding-inline-start',
                  value: cssSpacing('base', '16px'),
                  borderWidth: borderWidth
                }), calculatePaddingWithBorder({
                  property: 'padding-inline-end',
                  value: '33px',
                  borderWidth: borderWidth
                }));
              }
            default:
              {
                return css(["", ";", ";"], calculatePaddingWithBorder({
                  property: 'padding-inline-start',
                  value: '33px',
                  borderWidth: borderWidth
                }), calculatePaddingWithBorder({
                  property: 'padding-inline-end',
                  value: '33px',
                  borderWidth: borderWidth
                }));
              }
          }
        }
      case 'large':
        {
          switch (iconPlacement) {
            case 'leading':
              {
                return css(["", ";", ";"], calculatePaddingWithBorder({
                  property: 'padding-inline-start',
                  /** --encore-spacing-looser-4 resolves to 64px so we are just using hard coded values here until we refactor our buttons to be able to correcly use SL tokens */
                  value: '58px',
                  borderWidth: borderWidth
                }), calculatePaddingWithBorder({
                  property: 'padding-inline-end',
                  value: cssSpacing('looser-3', '48px'),
                  borderWidth: borderWidth
                }));
              }
            case 'trailing':
              {
                return css(["", ";", ";"], calculatePaddingWithBorder({
                  property: 'padding-inline-start',
                  value: cssSpacing('looser-3', '48px'),
                  borderWidth: borderWidth
                }), calculatePaddingWithBorder({
                  property: 'padding-inline-end',
                  value: '58px',
                  borderWidth: borderWidth
                }));
              }
            default:
              {
                return css(["", ";", ";"], calculatePaddingWithBorder({
                  property: 'padding-inline-start',
                  value: '58px',
                  borderWidth: borderWidth
                }), calculatePaddingWithBorder({
                  property: 'padding-inline-end',
                  value: '58px',
                  borderWidth: borderWidth
                }));
              }
          }
        }
      default:
        {
          switch (iconPlacement) {
            case 'leading':
              {
                return css(["", ";", ";"], calculatePaddingWithBorder({
                  property: 'padding-inline-start',
                  /** --encore-spacing-looser-3 resolves to 48px so we are just using hard coded values here until we refactor our buttons to be able to correcly use SL tokens */
                  value: '56px' /* TODO: can we use an encore token here? */,
                  borderWidth: borderWidth
                }), calculatePaddingWithBorder({
                  property: 'padding-inline-end',
                  value: cssSpacing('looser-2', '32px'),
                  borderWidth: borderWidth
                }));
              }
            case 'trailing':
              {
                return css(["", ";", ";"], calculatePaddingWithBorder({
                  property: 'padding-inline-start',
                  value: cssSpacing('looser-2', '32px'),
                  borderWidth: borderWidth
                }), calculatePaddingWithBorder({
                  property: 'padding-inline-end',
                  value: '56px' /* TODO: can we use an encore token here? */,
                  borderWidth: borderWidth
                }));
              }
            default:
              {
                return css(["", ";", ";"], calculatePaddingWithBorder({
                  property: 'padding-inline-start',
                  value: '56px' /* TODO: can we use an encore token here? */,
                  borderWidth: borderWidth
                }), calculatePaddingWithBorder({
                  property: 'padding-inline-end',
                  value: '56px' /* TODO: can we use an encore token here? */,
                  borderWidth: borderWidth
                }));
              }
          }
        }
    }
  };
  return css(["", ";", ";"], getPaddingBlock({
    buttonSize: buttonSize,
    borderWidth: borderWidth,
    status: status
  }), paddingInline);
}
export function getTertiaryWithIconPadding(_ref5) {
  var buttonSize = _ref5.buttonSize,
    iconPlacement = _ref5.iconPlacement,
    condensed = _ref5.condensed,
    status = _ref5.status;
  var paddingInline = function paddingInline() {
    switch (buttonSize) {
      case 'small':
        {
          switch (iconPlacement) {
            case 'leading':
              {
                return css(["padding-inline-start:", ";padding-inline-end:", ";"], cssSpacing('tighter', '12px'), cssSpacing('base', '16px'));
              }
            case 'trailing':
              {
                return css(["padding-inline-start:", ";padding-inline-end:", ";"], cssSpacing('base', '16px'), cssSpacing('tighter', '12px'));
              }
            default:
              {
                return css(["padding-inline-start:", ";padding-inline-end:", ";"], cssSpacing('tighter', '12px'), cssSpacing('tighter', '12px'));
              }
          }
        }
      case 'large':
        {
          switch (iconPlacement) {
            case 'leading':
              {
                return css(["padding-inline-start:", ";padding-inline-end:", ";"], cssSpacing('looser-2', '24px'), cssSpacing('looser-3', '48px'));
              }
            case 'trailing':
              {
                return css(["padding-inline-start:", ";padding-inline-end:", ";"], cssSpacing('looser-3', '48px'), cssSpacing('looser-2', '24px'));
              }
            default:
              {
                return css(["padding-inline-start:", ";padding-inline-end:", ";"], cssSpacing('looser-2', '24px'), cssSpacing('looser-2', '24px'));
              }
          }
        }
      default:
        {
          switch (iconPlacement) {
            case 'leading':
              {
                return css(["padding-inline-start:20px;padding-inline-end:", ";"], cssSpacing('looser-2', '32px'));
              }
            case 'trailing':
              {
                return css(["padding-inline-start:", ";padding-inline-end:20px;"], cssSpacing('looser-2', '32px'));
              }
            default:
              {
                return css(["padding-inline-start:20px;padding-inline-end:20px;"]);
              }
          }
        }
    }
  };
  if (condensed) {
    paddingInline = function paddingInline() {
      return css(["padding-inline-start:0;padding-inline-end:0;"]);
    };
  }
  return css(["", ";", ";"], getPaddingBlock({
    buttonSize: buttonSize,
    borderWidth: buttonSize === 'medium' ? 2 : 0,
    status: status
  }), paddingInline);
}
export function getIconPlacement() {
  var icons = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var iconOnly = icons.iconOnly,
    iconLeading = icons.iconLeading,
    iconTrailing = icons.iconTrailing;
  if (iconOnly) {
    return 'only';
  }
  if (iconLeading && iconTrailing) {
    return 'both';
  }
  if (iconLeading) {
    return 'leading';
  }
  if (iconTrailing) {
    return 'trailing';
  }
  return 'none';
}

/** Used by primary & secondary */
export var getButtonPadding = function getButtonPadding(_ref6) {
  var buttonSize = _ref6.buttonSize,
    _ref6$borderWidth = _ref6.borderWidth,
    borderWidth = _ref6$borderWidth === void 0 ? 0 : _ref6$borderWidth,
    status = _ref6.status,
    iconPlacement = _ref6.iconPlacement;
  /** iconOnly buttons used fixed width & height instead of padding */
  var iconOnlyDimensions = css(["inline-size:", ";block-size:", ";"], cssControlSize(buttonSize, controlMinBlockSize[buttonSize]), cssControlSize(buttonSize, controlMinBlockSize[buttonSize]));
  if (iconPlacement === 'only') {
    return iconOnlyDimensions;
  }
  if (iconPlacement === 'none') {
    return getNoneIconsPadding({
      buttonSize: buttonSize,
      borderWidth: borderWidth,
      status: status
    });
  }
  return getWithIconPadding({
    buttonSize: buttonSize,
    iconPlacement: iconPlacement,
    borderWidth: borderWidth,
    status: status
  });
};
var getTertiaryIconOnlyPadding = function getTertiaryIconOnlyPadding(_ref7) {
  var buttonSize = _ref7.buttonSize,
    condensed = _ref7.condensed;
  /** medium & large buttons have the same padding */
  var paddingBlock = css(["padding-block-start:", ";padding-block-end:", ";"], cssSpacing('tighter', '12px'), cssSpacing('tighter', '12px'));
  var paddingInline = css(["padding-inline-start:", ";padding-inline-end:", ";"], cssSpacing('tighter', '12px'), cssSpacing('tighter', '12px'));
  if (buttonSize === 'small') {
    paddingBlock = css(["padding-block-start:", ";padding-block-end:", ";"], cssSpacing('tighter-2', '8px'), cssSpacing('tighter-2', '8px'));
    paddingInline = css(["padding-inline-start:", ";padding-inline-end:", ";"], cssSpacing('tighter-2', '8px'), cssSpacing('tighter-2', '8px'));
  }
  if (condensed) {
    paddingInline = css(["padding-inline-start:0;padding-inline-end:0;"]);
  }
  return css(["", ";", ";"], paddingBlock, paddingInline);
};
export var getTertiaryButtonPadding = function getTertiaryButtonPadding(_ref8) {
  var buttonSize = _ref8.buttonSize,
    status = _ref8.status,
    iconPlacement = _ref8.iconPlacement,
    condensed = _ref8.condensed,
    condensedAll = _ref8.condensedAll;
  if (condensedAll) {
    return css(["padding:0;"]);
  }
  if (iconPlacement === 'only') {
    return getTertiaryIconOnlyPadding({
      buttonSize: buttonSize,
      condensed: condensed
    });
  }
  if (iconPlacement === 'none') {
    return getNoneIconsPadding({
      buttonSize: buttonSize,
      status: status,
      condensed: condensed
    });
  }
  return getTertiaryWithIconPadding({
    buttonSize: buttonSize,
    iconPlacement: iconPlacement,
    condensed: condensed,
    status: status
  });
};

/** Base styles shared by most buttons, including root style, focus reset and font sizes */
export var buttonStylesBase = function buttonStylesBase(buttonSize, useBrowserDefaultFocusStyle, status) {
  return css(["", ";", " ", ";background-color:transparent;border:0;border-radius:", ";cursor:pointer;display:inline-block;position:relative;text-align:center;text-decoration:none;text-transform:", ";touch-action:manipulation;transition-duration:", ";transition-property:background-color,border-color,color,box-shadow,filter,transform;user-select:none;vertical-align:middle;transform:translate3d(0,0,0);"], rootStyle(), !useBrowserDefaultFocusStyle && browserFocusReset, getButtonFont(buttonSize, status), buttonBorderRadius, buttonTextTransform, buttonTransitionFade);
};

/** Disabled styles shared by most buttons */
export var buttonStylesDisabled = css(["cursor:", ";opacity:", ";transform:scale(1);"], cursorDisabled, opacityDisabled);

/** Active styles shares by most buttons */
export var buttonStylesActive = css(["opacity:", ";outline:none;transform:scale(1);"], opacityActive);

/** Hover styles shares by most buttons */
export var buttonStylesHover = css(["transform:scale(", ");"], buttonHoverScale);

// Reset a button to a link looking thing
export var buttonLinkReset = function buttonLinkReset() {
  var useBrowserDefaultFocusStyle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return css(["background-color:transparent;border:0;color:inherit;cursor:pointer;line-height:1;letter-spacing:inherit;padding:0;", ""], !useBrowserDefaultFocusStyle && browserFocusReset);
};