import {ReactNode} from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material';
import {
  Control,
  Controller,
  ControllerProps,
  FieldError,
  Path,
} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types/fields';
import {ToggleButton, ToggleButtonProps} from '../ToggleButton';

type SingleToggleButtonProps = Omit<ToggleButtonProps, 'value' | 'children'> & {
  id: number | string;
  label: ReactNode;
};

export type ToggleButtonGroupControlledProps<T extends FieldValues> =
  ToggleButtonGroupProps & {
    required?: boolean;
    label?: string;
    rules?: ControllerProps['rules'];
    name: Path<T>;
    parseError?: (error: FieldError) => string;
    control?: Control<T>;
    options: SingleToggleButtonProps[];
    formLabelProps?: FormLabelProps;
    helperText?: string;
  };

export default function ToggleButtonGroupControlled<
  TFieldValues extends FieldValues = FieldValues
>({
  name,
  control,
  label,
  rules = {},
  required,
  options = [],
  parseError,
  helperText: helperTextProp,
  formLabelProps,
  onChange: onChangeProp,
  ...toggleButtonGroupProps
}: ToggleButtonGroupControlledProps<TFieldValues>) {
  if (required && !rules.required) {
    rules.required = 'This field is required';
  }

  const isRequired = required || !!rules?.required;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field: {value, onChange, onBlur}, fieldState: {error}}) => {
        let helperText = helperTextProp;
        if (error) helperText = parseError?.(error) ?? error.message;

        return (
          <FormControl error={!!error} required={isRequired}>
            {label && (
              <FormLabel
                {...formLabelProps}
                error={!!error}
                required={isRequired}
                css={{marginBottom: '0.5rem'}}>
                {label}
              </FormLabel>
            )}
            <ToggleButtonGroup
              {...toggleButtonGroupProps}
              value={value}
              onBlur={onBlur}
              onChange={(event, val) => {
                onChange(val);
                onChangeProp?.(event, val);
              }}>
              {options.map(({label, id, ...toggleProps}) => (
                <ToggleButton key={id} value={id} {...toggleProps}>
                  {label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
}
