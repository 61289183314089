import {hexToBase64} from '../_internal/hex_to_base64';

/**
 * The client ID context, a UUID that identifies the application (e.g. Spotify Music or Spotify Lite), this is the same client ID that we use to identify with the AP and login.
 */
export type ClientIdContext = {
  name: 'context_client_id';
  data: {
    value: string;
  };
};

/**
 * A context creator for clientId.
 *
 * Note that the client id needs to be sent to the server as a hex bytestring.
 *
 * @param clientId - The clientId to be attached to each event
 * @return  The function that attaches the clientId to every event
 */
export function createClientIdContext(clientId: string): () => ClientIdContext {
  const encoded = hexToBase64(clientId);
  return () => ({
    name: 'context_client_id',
    data: {value: encoded},
  });
}
