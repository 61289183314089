export {
  AbortControllerLike,
  AbortSignalLike,
  Plugin,
  PluginEventTypes,
  PluginMediator,
  PluginMessage,
  PluginRequest,
  Endpoints,
  EndpointsProvider,
  RequestOptions,
  TokenProvider,
} from './typedefs';

export {TransportErrors} from './enums/errors';
export {Event as TransportEvent} from './enums/event';
export {Method} from './enums/method';
export {PluginEvent} from './enums/plugin_event';
export {DisconnectionReason} from './enums/disconnection_reason';
export {
  PublicTransportEventMap,
  PublicTransportEventTypes,
} from './_internal/public_transport';
import {StatusFamily} from './enums/status_family';

export {
  ConnectionObserver,
  ConnectionObserverCreator,
  ConnectionObserverEventMap,
  ConnectionObserverOptions,
} from './_internal/connection_observer';

export {createAbortControllerLike} from './helpers/abortcontroller';

import {dealerCreator} from './plugins/dealer';
import * as Fetch from './http/fetch';
import {
  InternalTransportOptions,
  Transport,
  TransportEventMap,
  TransportEventTypes,
  TransportOptions,
} from './transport';
import * as XHR from './http/xhr';
import {HTTPResponse} from './http/response';
import {HTTPRequest} from './http/request';
import {PublicTransport} from './_internal/public_transport';

// XResolveProvider
export {createProvider as createXResolveProvider} from './providers/endpoints/xresolve';

// spotify.com providers
export {
  createEndpointsProvider as createSpotifyComEndpointsProvider,
  createTokenProvider as createSpotifyComTokenProvider,
  createProviders as createSpotifyComProviders,
} from './providers/spotify_com';

// Plugins
export {
  DEALER_PLUGIN_NAME,
  DealerErrors,
  DealerOptions,
} from './plugins/dealer';
export {dealerCreator};

export {
  REQUEST_TRANSFORMER_PLUGIN_NAME,
  RequestTransformerOptions,
  requestTransformerCreator,
} from './plugins/request_transformer';

// Export Types, but not implementations

/**
 * The type of the StatusFamily enums.
 *
 * This helper type should be used together with the `retry.condition` function
 * in requests.
 */
export type StatusFamilyT = typeof StatusFamily;

export type {HTTPResponse, HTTPRequest, PublicTransport, Transport};

export {TransportEventMap, TransportEventTypes, TransportOptions};

// Specialized Transport types. These can be used in lieu of normal Transport or
// PublicTransport types for cases where one does not need the entire Transport
// API. It simplifies the mocking of these types for tests.
//
// Note that the event APIs are decidedly not included, as Transport and
// PublicTransport have different entry points for the events.

/**
 * A limited type used to denote a Transport or PublicTransport instance that
 * can return the initialization time of the session.
 */
export type TransportInitTimer = Pick<Transport, 'getInitTime'>;

/**
 * A limited type used to denote a Transport or PublicTransport instance that
 * can make requests.
 */
export type TransportRequester = Pick<Transport, 'request'>;

/**
 * A limited type used to denote a Transport or PublicTransport instance that
 * can handle messages from a socket-based messaging endpoint like Dealer.
 */
export type TransportMessageHandler = Pick<
  Transport,
  'getConnectionId' | 'matchMessages' | 'unmatchMessages'
>;

/**
 * A limited type used to denote a Transport or PublicTransport instance that
 * can handle push requests from a socket-based messaging endpoint like Dealer.
 */
export type TransportPushRequestHandler = Pick<
  Transport,
  'getConnectionId' | 'handlePushRequests' | 'unhandlePushRequests'
>;

/**
 * A limited type used to denote a Transport or PublicTransport instance that
 * can interact with Transport plugins.
 */
export type TransportPluginAccessor = Pick<
  Transport,
  'hasPlugin' | 'getPluginAPI'
>;

/**
 * Creates an instance of Transport.
 *
 * @param config - The configuration object for this transport instance.
 * @returns The created Transport instance.
 */
export function createBaseTransport(config: TransportOptions): Transport {
  const _config: InternalTransportOptions = {...config};
  if (!_config.XHR) {
    _config.XHR = XHR;
  }
  _config.Fetch = Fetch;

  return Transport.create(_config);
}

/**
 * Creates an instance of Transport with default plugins added.
 *
 * @param config - The configuration object for this transport instance.
 * @returns The created Transport instance.
 */
export function createTransport(config: TransportOptions): Transport {
  const transport = createBaseTransport(config);
  transport.addPlugin(dealerCreator, {WebSocket: WebSocket});
  return transport;
}
