import {gql} from '@apollo/client';

export default gql`
  query getProduct($id: ID!) {
    admin {
      product(id: $id) {
        id
        name
        slug
        description
        url

        feedsCount
        episodesCount
        adsCount

        image {
          id
          width
          height
          src
          contentType
        }
      }
    }
  }
`;
