import React from 'react';
import {createRoot} from 'react-dom/client';
import App from '@core/app/App';
import Auth from '@core/app/Auth';
import AdminApp from './screens/App';
import UnAuthed from './screens/UnAuthed';

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App service='admin'>
      <Auth service='admin' UnAuthed={UnAuthed}>
        <AdminApp me={null} />
      </Auth>
    </App>
  </React.StrictMode>
);
