import {gql} from '@apollo/client';

export default gql`
  query getCampaignArt19Secret($id: ID!) {
    admin {
      campaign(id: $id) {
        id
        art19Secret
      }
    }
  }
`;
