import {hexToBase64} from '../_internal/hex_to_base64';

export type CacheIdContext = {
  name: 'context_cache_id';
  data: {
    value: string;
  };
};

/**
 * A builder for CacheId
 *
 * @param cacheId - The cacheId to be attached to each evend
 * @return The function that attaches the cacheId to every event
 */
export function createCacheIdContext(cacheId: string): () => CacheIdContext {
  const encoded = hexToBase64(cacheId);
  return () => ({
    name: 'context_cache_id',
    data: {value: encoded},
  });
}
