import EpisodelessCampaignEpisodesQuery from '@admin/graphql-api/_old_queries/EpisodelessCampaignEpisodesQuery';
import {graphql} from '@apollo/client/react/hoc';
import dayjs from '@core/lib/dayjs';
import {Element, TEXT_MUTED, TEXT_NORMAL} from '@core/style';
import {Card, PageTitle} from '@core/ui/Content';
import DataTable from '@core/ui/DataTable';
import {Dummy, Img} from '@core/ui/Image';
import Loading from '@core/ui/Loading';

// <a
//                   href={`https://dash.podsights.com/${
//                     organization.kind == "PODCAST" ? "p" : "s"
//                   }/${campaign.organization.slug}/campaigns/${
//                     campaign.slug
//                   }/podcasts/${campaignPodcast.id}/futures/${id}`}>
//                   Future Episode Landing
//                 </a>

const Episodeless = ({data: {loading, admin}}) => {
  if (loading) {
    return <Loading absolute />;
  }

  const {episodelessCampaignEpisodes} = admin;

  const columns = [
    {
      title: 'Podcast',
      accessor: 'id',
      type: 'string',
      Cell: ({
        data: {
          url,
          campaignPodcast: {feed},
        },
      }) => (
        <Element
          tag='a'
          href={url}
          target='_blank'
          rules={() => ({display: 'flex', textDecoration: 'none'})}>
          <Element
            rules={() => ({
              flex: '0 0 4.6875rem',
              marginRight: '1rem',
              position: 'relative',
            })}>
            {feed.image ? <Img image={feed.image} width={150} /> : <Dummy />}
          </Element>

          <Element rules={() => ({flex: 1})}>
            <Element rules={() => ({fontWeight: 500, color: TEXT_NORMAL})}>
              {feed.title}
            </Element>
            <Element
              tag='p'
              rules={() => ({
                margin: '0.325rem 0 0 0',
                color: TEXT_MUTED,
              })}>
              {feed.description}
            </Element>
          </Element>
        </Element>
      ),
    },
    {
      title: 'Publisher',
      accessor: 'publisher',
      type: 'string',
      noWrap: true,
    },
    {
      title: 'Brand',
      accessor: 'brand',
      type: 'string',
      noWrap: true,
    },

    {
      title: 'Expected Published',
      accessor: 'expectedPublished',
      type: 'date',
      fmt: (v) => (v ? dayjs(v).utc().format('MMM DD, YYYY') : 'never'),
      noWrap: true,
    },
  ];

  const data = episodelessCampaignEpisodes
    .slice(0)
    .map(({id, futureEpisode, campaignPodcast, ...d}) => ({
      url: `https://dash.podsights.com/${
        campaignPodcast.campaign.organization.kind == 'PODCAST' ? 'p' : 's'
      }/${campaignPodcast.campaign.organization.slug}/campaigns/${
        campaignPodcast.campaign.slug
      }/podcasts/${campaignPodcast.id}/futures/${id}`,
      expectedPublished: futureEpisode.expectedPublished,
      publisher: campaignPodcast.campaign.organization.name,
      brand: campaignPodcast.campaign.advertiser.name,
      campaignPodcast,
      podcastTitle: campaignPodcast.feed.title,
      futureEpisode,
      ...d,
    }));

  return (
    <Element>
      <PageTitle>Episodeless Campaign Episodes</PageTitle>
      <Card>
        <DataTable
          columns={columns}
          data={data}
          searchKeys={['podcastTitle']}
          searchPlaceholder='Search podcast'
          orderBy='expectedPublished'
        />
      </Card>
    </Element>
  );
};

export default graphql(EpisodelessCampaignEpisodesQuery, {
  options: ({after, before}) => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        after: after.format('YYYY-MM-DD'),
        before: before.format('YYYY-MM-DD'),
      },
    };
  },
})(Episodeless);
