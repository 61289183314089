import {PropsWithChildren, useLayoutEffect, useRef, useState} from 'react';
import styled, {CSSProp} from 'styled-components';
import {focusVisibleCss} from '@core/style';
import {Overlay, OverlayProps, useOverlay} from '../Overlay';
import {DropdownMenuItem} from './DropdownMenuItem';
import {DropdownMenuItemType} from './types';

export type DropdownMenuProps = PropsWithChildren<{
  /**
   * If true, close the menu when a menu item is selected.
   * @default true
   */
  closeOnSubmit?: boolean;
  /**
   * The items to display in the menu.
   */
  items: DropdownMenuItemType[];
  /**
   * Fired when the dropdown overlay is opened
   */
  onOpenedChanged?: (_opened: boolean) => void;
  /**
   * Fired when a menu item is selected.
   */
  onSubmit?: (_value: DropdownMenuItemType) => void;
  /**
   * The animation for the overlay
   * @default 'opacity'
   */
  overlayAnimation?: OverlayProps['animation'];
  /**
   * Overlay properties.
   */
  overlayProps?: OverlayProps;
  /**
   * styled-components css to apply to the overlay.
   */
  overlayCss?: CSSProp<any> | undefined;
  /**
   * The default selected (if any) of the menu items.
   */
  selectedValue?: string;
  /** @deprecated use 'value' as prop */
  propertyForValue?: string;
}>;

export const DropdownMenu = ({
  children,
  closeOnSubmit = true,
  items = [],
  onOpenedChanged,
  onSubmit,
  overlayAnimation = 'opacity',
  overlayProps = {
    fixed: false,
    horizontalAlign: 'right',
    noHorizontalOverlap: false,
    verticalAlign: 'bottom',
    verticalOffset: 0,
  },
  overlayCss,
  propertyForValue = 'value',
  selectedValue,
  ...props
}: DropdownMenuProps) => {
  const toggleJob = useRef<NodeJS.Timeout>();
  const [opened, toggle] = useOverlay();
  const [triggerWidth, setTriggerWidth] = useState(0);
  const triggerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (opened && triggerRef.current) {
      setTriggerWidth(triggerRef.current.getBoundingClientRect().width);
    }
    onOpenedChanged?.(opened);
  }, [opened, onOpenedChanged]);

  return (
    <>
      <_TriggerButton
        ref={triggerRef}
        $opened={opened}
        onMouseDown={(evt: any) => {
          evt.stopPropagation();
          toggle();
        }}
        onKeyDown={(evt: any) => {
          if (evt.key === 'Enter' || evt.key === ' ') {
            toggle(true);
          }
        }}
        {...props}>
        {children}
      </_TriggerButton>
      <_Overlay
        role='menu'
        aria-hidden={!opened}
        animation={overlayAnimation}
        opened={opened}
        positionTarget={triggerRef.current}
        toggle={toggle}
        transparentBackdrop
        withBackdrop
        withShadow
        onBlur={() => {
          toggleJob.current = setTimeout(() => {
            toggle(false);
          });
        }}
        onMouseDown={() => setTimeout(() => clearTimeout(toggleJob.current))}
        $triggerWidth={triggerWidth}
        css={overlayCss}
        {...overlayProps}>
        {items.length > 0 && (
          <_ItemsContainer>
            {items.map((item, i) => (
              <DropdownMenuItem
                key={`${i}:${item[propertyForValue] ?? ''}`}
                item={item}
                propertyForValue={propertyForValue}
                closeOnSubmit={closeOnSubmit}
                onSubmit={onSubmit}
                selectedValue={selectedValue}
                toggle={toggle}
              />
            ))}
          </_ItemsContainer>
        )}
      </_Overlay>
    </>
  );
};

const _TriggerButton = styled.div<{$opened?: boolean}>`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  outline: 0;
  ${focusVisibleCss}
`;

const _Overlay = styled(Overlay)<{$triggerWidth: number}>`
  border: 1px solid var(--border-default);
  min-width: ${(p) => Math.max(p.$triggerWidth, 225)}px;
  padding: var(--spacing-2) 0;
`;

const _ItemsContainer = styled.div`
  flex: 1;
  height: 100%;
  max-height: 32rem;
  min-height: 0;
  overflow-y: auto;
`;
