import {useMemo, useState} from 'react';
import OrganizationAssociatedDomainsTable from '@admin/components/OrganizationAssociatedDomainsTable';
import OrganizationCompaniesTable from '@admin/components/OrganizationCompaniesTable';
import OrganizationMembersTable from '@admin/components/OrganizationMembersTable';
import OrganizationPermissionsTable from '@admin/components/OrganizationPermissionsTable';
import OrganizationPixelsTable from '@admin/components/OrganizationPixelsTable';
import OrganizationPodcastsTable from '@admin/components/OrganizationPodcastsTable';
import OrganizationSpotifyImportsTable from '@admin/components/OrganizationSpotifyImportsTable';
import TierBadge from '@admin/components/TierBadge';
import {OrganizationKind, OrganizationObject} from '@admin/graphql-api';
import dayjs from '@core/lib/dayjs';
import {
  capitalizeFirstLetter,
  comma,
  fmtContractDates,
} from '@core/lib/filters';
import Badge from '@core/ui/Badge';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import DetailsCard from '@core/ui/DetailsCard';
import {Stack} from '@core/ui/Layout';

interface Props {
  organization: OrganizationObject;
}

export default function OrganizationOverview({organization}: Props) {

  return (
    <>
      <Stack
        gap='var(--spacing-4)'
        css={{
          flexWrap: 'wrap',
          margin: '2.25rem 1.5rem 2rem',
        }}>
        <OverviewDetailsCard organization={organization} />
        <EnabledFeaturesCard organization={organization} />
        <OrgNotesCard organization={organization} />
      </Stack>

      <Members show organization={organization} />
      <Podcasts show organization={organization} />
      <Pixels show organization={organization} />
      <Companies show organization={organization} />
      <Managers
        show={organization.managers?.length > 0}
        organization={organization}
      />
      <Managing
        show={organization.managing?.length > 0}
        organization={organization}
      />
      <Permissions
        show={organization.permissions?.length > 0}
        organization={organization}
      />
      <ReversePermissions
        show={organization.reversePermissions?.length > 0}
        organization={organization}
      />
      <UserOrganizationDomains show organization={organization} />
      <BrandCompanies
        organization={organization}
        show={
          organization.companies?.length > 0 &&
          organization.kind === OrganizationKind.Brand
        }
      />
    </>
  );
}

type CardProps = {
  show?: boolean;
  organization: OrganizationObject;
};
function CardWithHeader({
  show,
  rightContent,
  heading,
  children,
}: {
  show?: boolean;
  rightContent?: any;
  heading?: string;
  children?: React.ReactNode;
}) {
  if (!show) return null;

  return (
    <>
      {heading && (
        <CardHeader rightContent={rightContent}>{heading}</CardHeader>
      )}
      <Card>{children}</Card>
    </>
  );
}

function Companies({show, organization}: CardProps) {
  return (
    <CardWithHeader
      show={show}
      heading={`Companies (${organization.companies?.length})`}>
      <OrganizationCompaniesTable organization={organization} />
    </CardWithHeader>
  );
}

function Pixels({show, organization}: CardProps) {
  return (
    <CardWithHeader
      show={show}
      heading={`Pixels (${organization.keys?.length})`}>
      <OrganizationPixelsTable organization={organization} />
    </CardWithHeader>
  );
}

function Podcasts({show, organization}: CardProps) {
  return (
    <CardWithHeader
      show={show}
      heading={`Podcasts (${organization.podcasts?.length})`}>
      <OrganizationPodcastsTable organization={organization} />
    </CardWithHeader>
  );
}

function Members({show, organization}: CardProps) {
  return (
    <CardWithHeader
      show={show}
      heading={`Members (${organization.memberships?.length})`}>
      <OrganizationMembersTable
        organization={organization}
        memberships={organization.memberships}
      />
    </CardWithHeader>
  );
}

function BrandCompanies({show, organization}: CardProps) {
  return (
    <CardWithHeader
      show={show}
      rightContent={
        <Button
          to={`/organizations/${organization.id}/spotify-ids/add`}
          color='secondary'>
          Add Order IDs
        </Button>
      }
      heading={'Spotify Import Requests'}>
      <OrganizationSpotifyImportsTable
        spotifyImports={
          organization.companies?.[0]?.spotifyCampaignImportRequests ?? []
        }
      />
    </CardWithHeader>
  );
}

function ReversePermissions({show, organization}: CardProps) {
  return (
    <CardWithHeader
      show={show}
      heading={'Permissions granted on this organization'}>
      <OrganizationPermissionsTable
        organization={organization}
        reversePermissions
      />
    </CardWithHeader>
  );
}

function Permissions({show, organization}: CardProps) {
  return (
    <CardWithHeader show={show} heading={'Permissions this Organization has'}>
      <OrganizationPermissionsTable organization={organization} />
    </CardWithHeader>
  );
}

function Managing({show, organization}: CardProps) {
  return (
    <CardWithHeader show={show} heading={'Managing'}>
      <DataTable
        data={organization.managing}
        columns={COLUMNS}
        orderBy='-name'
      />
    </CardWithHeader>
  );
}

function Managers({show, organization}: CardProps) {
  return (
    <CardWithHeader show={show} heading={'Managers'}>
      <DataTable
        data={organization.managers}
        columns={COLUMNS}
        orderBy='-name'
      />
    </CardWithHeader>
  );
}

function UserOrganizationDomains({show, organization}: CardProps) {
  return (
    <CardWithHeader
      show={show}
      heading={'Associated email domains'}
      rightContent={
        <Button
          to={`/organizations/${organization.id}/domains/add`}
          color='secondary'>
          Add Domain
        </Button>
      }>
      <p>
        Users with these email domains can automatically join this organization.
      </p>
      <OrganizationAssociatedDomainsTable organization={organization} />
    </CardWithHeader>
  );
}

function EnabledFeaturesCard({
  organization,
}: {
  organization: OrganizationObject;
}) {
  return (
    <DetailsCard
      inverse
      PageTitle='Enabled Features'
      data={[
        {
          title: 'Send data to Nielsen',
          value: organization.isNielsenApproved ? 'Yes' : 'No',
        },
        {
          title: 'Send data to Experian',
          value: organization.isExperianApproved ? 'Yes' : 'No',
        },
        {
          title: `Show Segments to ${organization.name}`,
          value: organization.isSegmentsEnabled ? 'Yes' : 'No',
        },
        {
          title: `Show Advisor to ${organization.name}`,
          value: organization.isAdvisor ? 'Yes' : 'No',
        },
      ]}
      css={{flexGrow: 1, minWidth: '20rem', width: '20rem'}}
    />
  );
}

function OverviewDetailsCard({
  organization,
}: {
  organization: OrganizationObject;
}) {
  return (
    <DetailsCard
      PageTitle='Overview'
      data={[
        {
          title: 'Slug',
          value: organization.slug,
        },
        {
          title: 'Kind',
          value: capitalizeFirstLetter(organization.kind),
        },
        {
          title: 'Tier',
          value: <TierBadge tier={organization.tier} />,
        },
        {
          title: 'ID',
          value: organization.id,
        },
        {
          title: 'Created At',
          value: dayjs(organization.createdAt).format('MM/DD/YYYY'),
        },
      ]}
      css={{flexGrow: 1, minWidth: '20rem', width: '20rem'}}
    />
  );
}

function OrgNotesCard({organization}: {organization: OrganizationObject}) {
  return (
    <Card
      css={{
        flexGrow: 1,
        margin: 0,
        minWidth: '20rem',
        width: '20rem',
      }}>
      <div
        css={{
          fontSize: '0.875rem',
          fontWeight: 500,
          marginBottom: '0.625rem',
        }}>
        Notes
      </div>
      <p css={{marginBottom: 0}}>{organization.note}</p>
    </Card>
  );
}

const COLUMNS: DataTableColumn[] = [
  {title: 'Name', accessor: 'name', type: 'string'},
  {title: 'Kind', accessor: 'kind', type: 'string'},
];
