interface Props {
  getSelectLabel?: (
    _selectedItems: any[]
  ) => string | JSX.Element | JSX.Element[] | React.ComponentType<any>;
  placeholder?: string;
  propertyForName: string;
  selectedItems: any[];
  singleSelection: boolean;
}

export const _MultipleSelectInputContent = ({
  getSelectLabel,
  placeholder,
  propertyForName,
  selectedItems,
  singleSelection,
}: Props) => {
  if (selectedItems.length) {
    let content;
    if (typeof getSelectLabel === 'function') {
      content = getSelectLabel(selectedItems);
    } else if (singleSelection) {
      content = selectedItems[0][propertyForName];
    } else {
      content = (
        <>
          {placeholder}: <b>{selectedItems.length}</b>
        </>
      );
    }

    return (
      <div
        css={`
          color: var(--black);
        `}>
        {content}
      </div>
    );
  }
  return (
    <div
      css={`
        font-weight: 400;
      `}>
      {placeholder}
    </div>
  );
};
