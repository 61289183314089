import InvoiceQuery from '@admin/graphql-api/_old_queries/InvoiceQuery';
import {graphql} from '@apollo/client/react/hoc';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import Loading from '@core/ui/Loading';
import Invoice from './Invoice';

const InvoiceIndex = ({data: {loading, admin}, match: {url}}) => {
  if (loading) {
    return <Loading absolute />;
  }

  const {invoice} = admin;

  return (
    <Switch>
      <PropsRoute
        exact
        path='/invoices/:invoiceId'
        invoice={invoice}
        component={Invoice}
      />
    </Switch>
  );
};

export default graphql(InvoiceQuery, {
  options: ({
    match: {
      params: {invoiceId},
    },
  }) => ({
    variables: {
      id: invoiceId,
    },
  }),
})(InvoiceIndex);
