import React from 'react';
import {TRules} from '@core/style';
import {Element} from '@core/style';
import {BLUE} from '@core/style';

/**
 * @deprecated
 */
export interface ITextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  children?: React.ReactNode;
  domRef?: any;
  rules?: TRules;
  small?: boolean;
}

/**
 * @deprecated
 */
const TextArea = ({children, rules, small, ...props}: ITextAreaProps) => (
  <Element
    tag='textarea'
    rules={[
      () => ({
        background: 'var(--bg-default)',
        border: `0.0625rem solid ${'var(--border-default)'}`,
        borderRadius: '0.375rem',
        color: 'var(--text-default)',
        display: 'block',
        minHeight: '2rem',
        outline: '0',
        overflow: 'auto',
        padding: small ? '.5rem .625rem' : '.8125rem 1rem',
        resize: 'vertical',
        width: '100%',
        ':focus': {
          borderColor: BLUE,
          boxShadow: `0 0 0 0.0625rem ${BLUE}`,
        },
      }),
      rules,
    ]}
    {...props}>
    {children}
  </Element>
);

export default TextArea;
