import {createStyledElement} from '@core/style';

/** @deprecated */
const CardHelp = createStyledElement('p', () => ({
  color: 'var(--text-muted)',
  fontSize: '0.875rem',
  margin: '-0.75rem 1.5rem 1.5rem',
  padding: '0',
}));

export default CardHelp;
