import React, {createElement, forwardRef} from 'react';
import {TRules, css} from './css';

export interface FelaElementProps<T extends React.ElementType>
  extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  as?: T;
  /** @deprecated */
  rules?: TRules;
}

export const FelaElement = forwardRef(
  <T extends React.ElementType>(
    {as, className, children, rules, ...props}: FelaElementProps<T>,
    ref
  ) => {
    return createElement(
      as ?? 'div',
      {
        ref,
        ...css(rules, {className}),
        ...props,
      },
      children
    );
  }
);
