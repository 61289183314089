export const stripPunctuation = (str: string): string =>
  // eslint-disable-next-line no-useless-escape
  str.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '').replace(/\s/g, '');

export const stripHTML = (str: string): string =>
  str ? str.replace(/<(?:.|\n)*?>/gm, '') : '';

export const cleanWord = (str: string): string =>
  stripPunctuation(str).toLowerCase();

export const truncateWords = (str: string, limit: number): string => {
  let words = (str || '').split(' ');

  if (words.length < limit) {
    return str;
  }
  words = words.slice(0, limit);

  const lastWord = words[words.length - 1];

  if (lastWord[lastWord.length - 1] === '.') {
    return words.join(' ');
  }

  if (lastWord[lastWord.length - 1] === ',') {
    return `${[
      ...words.slice(0, limit - 1),
      lastWord.slice(0, lastWord.length - 1),
    ].join(' ')}...`;
  }
  return `${words.join(' ')}...`;
};

export const truncate = (str: string, limit: number): string => {
  if (!str) {
    return '';
  }
  if (str.length < limit) {
    return str;
  }
  return `${str.substr(0, limit)}...`;
};
