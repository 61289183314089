import React, {useEffect} from 'react';
import {auth} from '@core/components/SignInWithSpotify';
import {logoutViaSpotify} from '@core/components/SignInWithSpotify/auth';
import Loading from '@core/ui/Loading';

const Logout = ({to}: {to?: string}) => {
  useEffect(() => {
    logoutViaSpotify();
  }, []);

  return <Loading fixed />;
};

export default Logout;

declare global {
  interface Window {
    gapi: any;
  }
}
