// Copied from https://ghe.spotify.net/datainfra/event-definitions-ts/blob/master/src/contexts/createApplicationDesktop.ts

/**
 * Contains information about the desktop application that emitted an event
 */
export type ApplicationDesktopContextData = {
  /**
   * The application version as a semantic version string, for example '1.2.3.4'. Usually retrived from `SP_BUILDVERSION_VERSIONSTRING`.
   */
  version_string?: string | null;
  /**
   * The application version as a revision integer, 9 base-10 digits,  1 digit each for the first two components, 2 digits for the third component and 5 digits for the last component, for example 120300004. Usually retrieved from `SP_BUILDVERSION_REVISION_ACTUAL`.
   */
  version_code?: number | string | null;
};

export type ApplicationDesktopContext = {
  name: 'context_application_desktop';

  data: ApplicationDesktopContextData;
};

/**
 * A builder for ApplicationDesktop
 *
 * @param data - The event data
 * @return The formatted event data for ApplicationDesktopContext
 */
export function createApplicationDesktopContext(
  data: ApplicationDesktopContextData
): () => ApplicationDesktopContext {
  return () => {
    return {
      name: 'context_application_desktop',
      data,
    };
  };
}
