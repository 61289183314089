import CircularProgress from '@mui/material/CircularProgress';
import {Control, Controller, ControllerProps, Path} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types/fields';
import {TextField, TextFieldProps} from '../TextField';
import {Autocomplete, AutocompleteProps} from './Autocomplete';

export type AutocompleteControlledProps<
  F extends FieldValues,
  T,
  M extends boolean | undefined
> = {
  name: Path<F>;
  control?: Control<F>;
  options: T[];
  loading?: boolean;
  multiple?: M;
  matchId?: boolean;
  rules?: ControllerProps['rules'];
  required?: boolean;
  label?: TextFieldProps['label'];
  showCheckbox?: boolean;
  autocompleteProps?: Omit<
    AutocompleteProps<T, M>,
    'name' | 'options' | 'loading' | 'renderInput'
  >;
  textFieldProps?: Omit<TextFieldProps, 'name' | 'required' | 'label'>;
};

type AutoDefault = {
  id: string | number; // must keep id in case of keepObject
  label: string;
};

export function AutocompleteControlled<TFieldValues extends FieldValues>({
  textFieldProps: {inputProps, InputProps, helperText, ...textFieldProps} = {},
  autocompleteProps: {
    isOptionEqualToValue,
    getOptionLabel,
    onBlur: onBlurProp,
    onChange: onChangeProp,
    ...autocompleteProps
  } = {},
  name,
  control,
  options,
  loading = false,
  rules = {},
  required = false,
  multiple = false,
  matchId,
  label,
}: AutocompleteControlledProps<
  TFieldValues,
  AutoDefault | string | any,
  boolean | undefined
>) {
  if (required && !rules?.required) {
    rules.required = 'This field is required';
  }

  const isRequired = required || !!rules?.required;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: {onChange, onBlur, value, ...fieldRest},
        fieldState: {error},
      }) => {
        let currentValue = multiple ? value || [] : value || null;
        if (matchId) {
          currentValue = multiple
            ? (value || []).map((i) => options.find((j) => (j.id || j) === i))
            : options.find((i) => (i.id || i) === value) || null;
        }
        return (
          <Autocomplete
            {...autocompleteProps}
            value={currentValue}
            loading={loading}
            multiple={multiple}
            options={options}
            isOptionEqualToValue={(option, value) => {
              if (isOptionEqualToValue)
                return isOptionEqualToValue(option, value);

              if (option?.id && value?.id) return option.id === value.id;

              return option === value;
            }}
            getOptionLabel={
              getOptionLabel ?? ((option) => `${option?.label ?? option}`)
            }
            onChange={(event, value, reason, details) => {
              let changedVal = value;
              if (matchId) {
                changedVal = Array.isArray(value)
                  ? value.map((i: any) => i?.id ?? i)
                  : value?.id || value;
              }
              onChange(changedVal);
              onChangeProp?.(event, value, reason, details);
            }}
            onBlur={(event) => {
              onBlur();
              onBlurProp?.(event);
            }}
            renderInput={(params) => (
              <TextField
                name={name}
                required={isRequired}
                label={label}
                {...textFieldProps}
                {...params}
                error={!!error}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && (
                        <CircularProgress color='inherit' size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  ...InputProps,
                }}
                inputProps={{...params.inputProps, ...inputProps}}
                helperText={error?.message ?? helperText}
              />
            )}
            {...fieldRest}
          />
        );
      }}
    />
  );
}
