import {PropsWithChildren} from 'react';
import {Text} from '../Text';

export type AlertTitleProps = PropsWithChildren;

export function AlertTitle({children}: AlertTitleProps) {
  return (
    <Text
      variant='subtitle1'
      css={`
        color: inherit;
        line-height: var(--line-height-sm);
        margin: 0 0 var(--spacing-1) 0;
      `}>
      {children}
    </Text>
  );
}
