import {gql} from '@apollo/client';

export default gql`
  query AdminCompany($id: ID!) {
    admin {
      company(id: $id) {
        id
        name
        slug
        description
        url

        industry

        feedsCount
        episodesCount
        productsCount
        adsCount
        archived

        organization {
          id
          name
          slug
        }

        image {
          id
          width
          height
          src
          contentType
        }

        products {
          id
          name
          slug
          description
          url

          adsCount
        }
        tags {
          id
          label
        }
      }
    }
  }
`;
