import {FC, useCallback, useMemo} from 'react';
import {TRules, css} from '@core/style';
import Select from '../Select';
import {DataTableColumn, DataTableFilter, DataTableValuesMap} from './types';

interface Props {
  columns: DataTableColumn[];
  data: any[];
  filters: DataTableFilter[];
  filtersDefaultValues?: {[key: string]: any};
  onFilterChange: (_props: {
    column: DataTableColumn;
    defaultValue: any;
  }) => void;
  onUpdateSortBy: (_value: any) => void;
  rules?: TRules;
  valuesMap: DataTableValuesMap;
  withSortFilter?: boolean;
}

export default function DataTableFilters({
  columns = [],
  data = [],
  filters,
  filtersDefaultValues,
  onFilterChange,
  onUpdateSortBy,
  rules,
  valuesMap,
  withSortFilter = false,
}: Props) {
  const sortFilterItems = useMemo(
    () =>
      withSortFilter
        ? columns
            .filter((col) => col.title && !col.noSortable)
            .map((col) => [
              {title: `${col.title} ↑`, value: `-${col.accessor}`},
              {title: `${col.title} ↓`, value: `${col.accessor}`},
            ])
            .flat(10)
        : [],
    [withSortFilter, columns]
  );

  if (filters.length || withSortFilter) {
    return (
      <div
        {...css([
          () => ({
            display: 'flex',
            fontSize: '0.875rem',
          }),
          rules,
        ])}>
        {filters.map(({Filter, column, ref}, idx) => (
          <div key={`${column.accessor}${idx}`} css={{marginRight: '0.625rem'}}>
            <Filter
              ref={ref}
              data={data}
              column={column}
              valuesMap={valuesMap}
              onChange={onFilterChange}
              {...(filtersDefaultValues &&
                filtersDefaultValues[column.accessor] && {
                  defaultValue: filtersDefaultValues[column.accessor],
                })}
            />
          </div>
        ))}
        {withSortFilter && (
          <Select
            items={sortFilterItems}
            placeholder='Sort By'
            onSelect={({value}) => onUpdateSortBy(value)}
            getSelectLabel={(items) => `Sort By: ${items[0].title}`}
            small
            outline
          />
        )}
      </div>
    );
  }
  return null;
}
