import {createStyledElement} from '@core/style';

/**
 * @deprecated
 */
const Separator = createStyledElement('div', () => ({
  background: 'var(--border-default)',
  height: '1px',
  margin: '1.5rem 0',
}));

export default Separator;
