import React, {FC} from 'react';
import {resizeImage} from '@core/lib/imgix';
import {TRules, css} from '@core/style';
import Dummy from './Dummy';

export type Image = {
  path?: string | null;
  href?: string;
  id?: string;
};

export interface ImgProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  image?: Image | null;
  width?: number;
  rules?: TRules;
  [key: string]: any;
}

const Img: FC<ImgProps> = ({
  image,
  width = 70,
  rules,
  ...props
}): JSX.Element => {
  const imgRules = [() => ({borderRadius: '.5rem', flexShrink: 0}), rules];

  if (image?.path) {
    return (
      <img src={resizeImage(image.path, width)} {...css(imgRules)} {...props} />
    );
  }
  if (image?.href && /^https:\/\//.test(image.href)) {
    return <img src={image.href} {...css(imgRules)} {...props} />;
  }
  return <Dummy rules={rules} {...props} />;
};

export default Img;
