type ModDefFn = (fn: Function) => void;

declare global {
  interface Window {
    __modDefFn: ModDefFn;
    __hasDeferredHandler: boolean;
    attachEvent?: Function;
  }
}

declare const setImmediate: Function;

const hasWindow = typeof window !== 'undefined';

const deferreds: Function[] = [];

function executeDeferreds(): void {
  const fns = deferreds.splice(0);
  if (!fns.length) {
    return;
  }
  for (let i = 0, l = fns.length; i < l; i++) {
    try {
      fns[i]?.();
    } finally {
      // Do nothing.
    }
  }
}

let send: Function;

function bindSendDom(): void {
  const origin =
    window.location.origin ||
    `${window.location.protocol}//${window.location.hostname}`;
  // @ts-ignore there seems to be an incompatibility between TS 4.3.x and 4.8.3
  send = window.postMessage.bind(window, '@execute_deferreds', origin);
  if (!window.__hasDeferredHandler) {
    if (typeof Object.defineProperty === 'function') {
      Object.defineProperty(window, '__hasDeferredHandler', {value: true});
    } else {
      window.__hasDeferredHandler = true;
    }
    const handler = function (e: any): void {
      if (e.origin !== origin && e.data !== '@execute_deferreds') {
        return;
      }
      executeDeferreds();
    };
    if (window.addEventListener) {
      window.addEventListener('message', handler);
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', handler);
    }
  }
}

function bindQueueMicroTask(): void {
  send = queueMicrotask.bind(null, executeDeferreds);
}

function bindSendImmediate(): void {
  send = setImmediate.bind(null, executeDeferreds);
}

function bindSendTimeout(): void {
  send = setTimeout.bind(null, executeDeferreds, 10);
}

function bindSendAuto(): void {
  if (hasWindow && typeof window.postMessage === 'function') {
    bindSendDom();
  } else if (typeof queueMicrotask === 'function') {
    bindQueueMicroTask();
  } else if (typeof setImmediate === 'function') {
    bindSendImmediate();
  } else {
    bindSendTimeout();
  }
}

bindSendAuto();

let defer: ModDefFn = (fn: Function) => {
  const trigger = !deferreds.length;
  deferreds.push(fn);
  if (trigger) {
    send();
  }
};

if (hasWindow) {
  if (typeof window.__modDefFn === 'function') {
    defer = window.__modDefFn;
  } else if (typeof Object.defineProperty === 'function') {
    Object.defineProperty(window, '__modDefFn', {value: defer});
  } else {
    window.__modDefFn = defer;
  }
}

export {defer};
