import {useMemo} from 'react';
import {OrganizationObject} from '@admin/graphql-api';
import {toDashboardUrl} from '@admin/lib/paths';
import {copyToClipboard} from '@core/lib/utils';
import Button from '@core/ui/Button';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {Stack} from '@core/ui/Layout';
import {useOverlay} from '@core/ui/Overlay';
import {Snackbar} from '@core/ui/Snackbar';

type Props = {
  organization: OrganizationObject;
};

export default function OrganizationPixelsTable({organization}: Props) {
  const [copied, toggleCopied] = useOverlay();
  const dashboardUrl = toDashboardUrl(organization);
  const columns = useMemo<DataTableColumn[]>(
    () => [
      {
        title: 'Id',
        accessor: 'key',
        type: 'string',
      },
      {
        title: 'Role',
        accessor: 'role',
        type: 'string',
      },
      {
        title: 'Domain',
        accessor: 'domain',
        type: 'string',
      },
      {
        Cell: ({data: {key}}) => (
          <Stack spacing='var(--spacing-2)'>
            <Button
              href={`${dashboardUrl}/manage/pixels/${key}/debug`}
              target='_blank'
              variant='outlined'>
              View in Dash
            </Button>
            <Button
              onClick={() => {
                toggleCopied(true);
                copyToClipboard(`https://podsights.com/install?id=${key}`);
              }}
              variant='outlined'>
              Copy install URL
            </Button>
          </Stack>
        ),
      },
    ],
    [dashboardUrl, toggleCopied]
  );

  return (
    <>
      <DataTable
        searchKeys={['key', 'role', 'domain']}
        data={organization.keys}
        columns={columns}
        emptyMessageText='No pixels found.'
      />
      <Snackbar
        autoHideDuration={4000}
        severity='success'
        opened={copied}
        toggle={toggleCopied}>
        Copied Install URL to clipboard
      </Snackbar>
    </>
  );
}
