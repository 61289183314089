import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import dayjs from '@core/lib/dayjs';
import {Card, CardHeader} from '@core/ui/Content';
import {DateRangeInput} from '@core/ui/DatePicker';
import FutureEpisodes from './FutureEpisodes';

const FuturesRangeIndex = ({
  history,
  match: {
    params: {start, end},
  },
}) => {
  const onDatesChange = ({startDate, endDate}) => {
    history.push(
      `/futures/${startDate.format('YYYY-MM-DD')}/${endDate.format(
        'YYYY-MM-DD'
      )}`
    );
  };

  const hardStart = dayjs('2019-09-01', 'YYYY-MM-DD');
  const hardEnd = dayjs().subtract(1, 'days');

  return (
    <>
      <CardHeader>Range</CardHeader>
      <Card
        rules={() => ({
          display: 'grid',
          fontSize: '14px',
          gridTemplateColumns: '1fr 2fr 1fr 1fr',
          alignItems: 'center',
          gridGap: '4rem',
        })}>
        <DateRangeInput
          onDatesChange={onDatesChange}
          startDate={dayjs(start)}
          endDate={dayjs(end)}
          isOutsideRange={(date) => {
            return !(
              date.isSameOrAfter(hardStart) && date.isSameOrBefore(hardEnd)
            );
          }}
        />
        <p>Find Future Episodes</p>
      </Card>

      <Switch>
        <PropsRoute
          exact
          path='/futures/:start/:end'
          after={dayjs(start)}
          before={dayjs(end).clone().add(1, 'days')}
          component={FutureEpisodes}
        />
      </Switch>
    </>
  );
};

export default FuturesRangeIndex;
