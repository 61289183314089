export type OsContext = {
  name: 'context_os';
  data: {
    /**
     * The name of the operating system
     */
    name: string;
    /**
     * The version of the operating system
     */
    version: string;
  };
};

/**
 * A builder for the operating system context
 *
 * @param data - The event data
 * @param data.name - The name of the operating system.
 * @param data.version - The version of the operating system.
 * @return The formatted event data for OsContext
 */
export function createOsContext(data: {
  name: string;
  version: string;
}): () => OsContext {
  return () => {
    return {name: 'context_os', data};
  };
}
