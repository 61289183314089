// NOTE: This code was generated and should not be changed

/**
 * Semantic Metric for Clients
 */
export type SemanticMetricClientEventData = {
  /**
   * Metric key
   */
  key?: string | null;
  /**
   * gauge, counter, meter, timer
   */
  metric_type?: string | null;
  /**
   * Component ID that this metric belongs to
   */
  component_id?: string | null;
  /**
   * What to measure, using "kebab-case"
   */
  what?: string | null;
  /**
   * Value of what is measured.
   */
  value?: number | null;
  /**
   * Additional metadata for the metric.
   */
  tags?: Record<string, string> | null;
};

export type SemanticMetricClientEvent = {
  name: 'SemanticMetricClient';
  environments: ['browser', 'device'];
  data: SemanticMetricClientEventData;
};

/**
 * A builder for SemanticMetricClient
 *
 * @param data - The event data
 * @return The formatted event data for SemanticMetricClientEvent
 */
export function createSemanticMetricClient(
  data: SemanticMetricClientEventData
): SemanticMetricClientEvent {
  return {
    name: 'SemanticMetricClient',
    environments: ['browser', 'device'],
    data,
  };
}
