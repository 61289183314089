import React from 'react';
import dayjs from '@core/lib/dayjs';
import {toTimeString} from '@core/lib/time';
import {Element} from '@core/style';
import {EpisodeImage} from '@core/ui/Image';
import {Link} from '@core/ui/Link';
import Stat from '@core/ui/Stat';

const Episode = ({episode}) => {
  const borderColor = episode.hasNoAds ? 'red' : 'green';

  let reviewDuration = 0;

  const createdAts = [];

  if (episode.reviews.length) {
    reviewDuration = episode.reviews.reduce((max, r) => {
      createdAts.push(r.createdAt);
      return r.duration && r.duration > max ? r.duration : max;
    }, 0);
  } else {
    reviewDuration = episode.placements.reduce((max, p) => {
      p.ad.reviews.forEach((r) => {
        createdAts.push(r.createdAt);
        max = r.duration && r.duration > max ? r.duration : max;
      });
      return max;
    }, 0);
  }

  const createdAt = createdAts.length
    ? createdAts
        .map((c) => dayjs(c))
        .reduce((a, b) => {
          return a.isBefore(b) ? b : a;
        })
    : null;

  const missedNoAds = episode.reviews.length > 0 && !episode.hasNoAds;

  return (
    <Element
      style-borderColor={borderColor}
      rules={({borderColor}) => ({
        borderLeft: `2px solid ${borderColor}`,
        display: 'flex',
        margin: '20px 0',
        paddingLeft: '5px',
      })}>
      <Element rules={() => ({flex: '0 0 100px', padding: '5px'})}>
        <a href={`https://reviewers.podsights.com/r/episodes/${episode.id}`}>
          <EpisodeImage episode={episode} width={100} />
        </a>
      </Element>
      <Element rules={() => ({flex: 1, marginLeft: '5px'})}>
        <Element rules={() => ({display: 'flex'})}>
          <Element tag='h6' rules={() => ({flex: 1})}>
            {isLocal ? (
              <Link to={`/r/episodes/${episode.id}`}>{episode.title}</Link>
            ) : (
              <a
                href={`https://reviewers.podsights.com/r/episodes/${episode.id}`}>
                {episode.title}
              </a>
            )}
          </Element>
          <Stat
            title='episode duration'
            value={toTimeString(episode.enclosure.length)}
          />
          <Stat title='review duration' value={toTimeString(reviewDuration)} />
          <Stat
            title='created'
            value={createdAt ? createdAt.format('MM/DD hh:mm') : 'unknown'}
          />
        </Element>
        {missedNoAds ? (
          <Element
            tag='p'
            rules={() => ({
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px',
              textAlign: 'center',
            })}>
            This episode was misclassified as having no ads. Subsequent reviewer
            found an ad, therefore this will not cound towards your minutes
          </Element>
        ) : (
          <div>
            {episode.reviews.length > 0 ? (
              <Element
                tag='p'
                rules={() => ({
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '5px',
                })}>
                no ads
              </Element>
            ) : (
              <Element>
                <h6>Found {episode.placements.length} Ads</h6>
                <Element>
                  {episode.placements.map((p) => {
                    const content = `${toTimeString(
                      p.startTime
                    )} - ${toTimeString(p.endTime)} (${toTimeString(
                      p.ad.duration
                    )})`;

                    return (
                      <Element key={p.id}>
                        {isLocal ? (
                          <Link to={`/r/ads/${p.ad.id}`}>{content}</Link>
                        ) : (
                          <a
                            href={`https://reviewers.podsights.com/r/ads/${p.ad.id}`}>
                            {content}
                          </a>
                        )}
                      </Element>
                    );
                  })}
                </Element>
              </Element>
            )}
          </div>
        )}
      </Element>
    </Element>
  );
};

export default Episode;
