import React, {FC} from 'react';
import {Icon} from '../Icon';
import {Tooltip} from '../Tooltip';
import Dummy from './Dummy';
import Img, {Image} from './Img';

interface EpisodeImageProps {
  episode?: {
    image?: Image;
    feed?: {
      image?: Image;
    };
  };
  feed?: {
    image?: Image;
  };
  verified?: boolean;
  [key: string]: any;
}

const EpisodeImage: FC<EpisodeImageProps> = ({
  episode,
  feed,
  verified = false,
  ...props
}): JSX.Element => {
  return (
    <div css={{position: 'relative'}}>
      {episode && episode.image ? (
        <Img image={episode.image} {...props} />
      ) : episode && episode.feed && episode.feed.image ? (
        <Img image={episode.feed.image} {...props} />
      ) : feed && feed.image ? (
        <Img image={feed.image} {...props} />
      ) : (
        <Dummy {...props} />
      )}
      <div
        css={{
          position: 'absolute',
          top: '-5px',
          right: '-5px',
          height: '25px',
          width: '25px',
        }}>
        {verified === true ? (
          <Tooltip description='Airchecked successfully.'>
            <Icon
              icon='aircheck-success'
              rules={() => ({color: 'var(--green)'})}
            />
          </Tooltip>
        ) : verified === false ? (
          <Tooltip description='Ad Analytics has yet to successfully aircheck this episode. Either it has yet to make our queue, or our reviewers could not find the ad. '>
            <Icon
              icon='aircheck-pending'
              rules={() => ({color: 'var(--icon-muted)'})}
            />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};

export default EpisodeImage;
