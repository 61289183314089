import {useHistory} from 'react-router-dom';
import {BackButton} from '@analytics/screens/account/AccountLayout';
import ErrorBase from './ErrorBase';

const NotFoundError = () => {
  const history = useHistory();
  return (
    <ErrorBase title='Page not found' baseColor='var(--blue)'>
      <p css={{fontSize: '1.25rem'}}>
        We can't seem to find the page you are looking for.{' '}
      </p>
      <BackButton label={'Go back'} onClick={() => history.goBack()} />
      {/* <a css={{color: 'var(--blue)'}} href="/">
        Go back
      </a> */}
    </ErrorBase>
  );
};

export default NotFoundError;
