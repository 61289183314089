import {gql} from '@apollo/client';

export default gql`
  query getInvoices {
    admin {
      invoices {
        id
        number
        created
        due
        sent
        paid
        periodStart
        periodEnd

        organization {
          id
          name
          slug
        }

        lineItems {
          cost
          hidden
        }

        createdAt
      }
    }
  }
`;
