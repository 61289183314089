import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {MultipleSelect} from '../../Select';
import {
  DataTableColumn,
  DataTableFilterProps,
  DataTableValuesMap,
} from '../types';

type GetItemsFn = (_props: {
  column: DataTableColumn;
  data: any[];
  valuesMap: DataTableValuesMap;
}) => {title: string; value: string | number}[];

const getItems: GetItemsFn = ({column, data, valuesMap}) => {
  const values = [
    ...new Set(
      data
        .map((d) => valuesMap.get(column)?.get(d))
        .flat(10)
        .map((str) => str)
    ),
  ];

  return values.map((value: string) => ({
    title:
      value.length > 1
        ? value.charAt(0).toUpperCase() + value.toLowerCase().slice(1)
        : value,
    value,
  }));
};

interface IMultipleSelectFilterProps extends DataTableFilterProps {
  defaultValue?: any[];
  items?: any[];
  searchable?: boolean;
}

const MultipleSelectFilter = forwardRef(
  (
    {
      column,
      data,
      defaultValue,
      items,
      onChange,
      searchable = true,
      valuesMap,
      ...multipleSelectProps
    }: IMultipleSelectFilterProps,
    ref
  ): JSX.Element => {
    const [updated, setUpdated] = useState<number>(0);
    const _items = useMemo(
      () =>
        items && items.length ? items : getItems({data, valuesMap, column}),
      [items, data, valuesMap, column]
    );
    const selectedItemsRef = useRef<any[]>(
      defaultValue
        ? _items.filter((item) => defaultValue.includes(item.value))
        : []
    );

    const handleOnChange = useCallback(
      (selectedItems: {title: string; value: string}[]) => {
        selectedItemsRef.current = selectedItems;
        onChange({
          column,
          defaultValue: selectedItems.map(({value}) => value),
        });
      },
      [column, onChange]
    );

    useImperativeHandle(ref, () => ({
      filter: (filteredData: any[]) => {
        if (selectedItemsRef.current?.length) {
          return filteredData.filter((d) => {
            const value: string | string[] = valuesMap.get(column)?.get(d);

            return selectedItemsRef.current.find((selectedItem) => {
              if (Array.isArray(value)) {
                return value.includes(selectedItem.value);
              }
              return selectedItem.value === value;
            });
          });
        }
        return filteredData;
      },
      clear: () => {
        setUpdated((prev) => prev + 1);
        handleOnChange([]);
      },
    }));

    return (
      <MultipleSelect
        key={`${column.accessor}-${updated}`}
        items={_items}
        placeholder={column.title}
        onSelect={handleOnChange}
        searchable={searchable}
        searchKeys={['value']}
        defaultValue={defaultValue}
        overlayProps={{
          horizontalAlign: 'right',
          verticalOffset: 2,
        }}
        {...multipleSelectProps}
      />
    );
  }
);

export default MultipleSelectFilter;
