import {gql} from '@apollo/client';

export default gql`
  mutation hideLineItem($input: HideLineItemInput!) {
    authed {
      success
    }
    hideLineItem(input: $input) {
      success
    }
  }
`;
