import React from 'react';
import ReviewersQuery from '@admin/graphql-api/_old_queries/ReviewersQuery';
import {graphql} from '@apollo/client/react/hoc';
import {comma} from '@core/lib/filters';
import {Button} from '@core/ui/Button';
import {Card, PageTitle} from '@core/ui/Content';
import DataTable from '@core/ui/DataTable';
import Loading from '@core/ui/Loading';

const Reviewers = ({data: {loading, admin}}) => {
  if (loading) {
    return <Loading absolute />;
  }
  const {reviewers} = admin;

  const hasWorkerId = reviewers.filter((d) => d.mturkWorkerId).length > 0;

  const columns = [
    {
      title: 'Name',
      accessor: 'fullName',
      type: 'string',
      rule: () => ({fontWeight: 500}),
    },
    {
      title: 'Email',
      accessor: 'email',
      type: 'string',
    },
    ...(hasWorkerId
      ? {
          title: 'Worker ID',
          accessor: 'mturkWorkerId',
          type: 'string',
          fmt: (v) => v || '-',
        }
      : {}),
    {
      title: 'Total Episode Count',
      accessor: 'totalEpisodeCount',
      type: 'number',
      fmt: comma,
    },
  ];

  const data = reviewers.map((d) =>
    Object.assign({fullName: `${d.firstName} ${d.lastName}`}, d)
  );

  return (
    <>
      <PageTitle
        rightContent={
          <Button color='primary' to='/reviewers/create'>
            Add Reviewer
          </Button>
        }>
        Reviewers
      </PageTitle>
      <Card>
        <DataTable
          columns={columns}
          data={data}
          orderBy='-totalEpisodeCount'
          searchKeys={['email']}
          searchPlaceholder='Search by email'
          pagination={false}
          onClickRowPath={({id}) => `/reviewers/${id}`}
        />
      </Card>
    </>
  );
};

export default graphql(ReviewersQuery)(Reviewers);
