import React from 'react';
import Badge from '@core/ui/Badge';

const COLORS = {
  SILVER: '#d1d5da',
  GOLD: '#ffd33d',
  BRONZE: '#bcaaa4',
};

const TierBadge = ({tier}) => (
  <Badge
    customColors={{
      background: COLORS[tier],
      borderColor: COLORS[tier],
      color: 'var(--text-default)',
    }}>
    {tier} {tier === 'GOLD' ? '🥇' : tier === 'SILVER' ? '🥈' : '🥉'}
  </Badge>
);

export default TierBadge;
