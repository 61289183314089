import {FC, HTMLProps} from 'react';

/**
 * @deprecated
 */
const CheckboxMinus: FC<HTMLProps<HTMLButtonElement>> = (
  props
): JSX.Element => {
  return (
    <button
      tabIndex={0}
      css={{
        alignItems: 'center',
        border: '0.125rem solid var(--border-default)',
        borderRadius: '0.25rem',
        display: 'inline-flex',
        flexDirection: 'center',
        height: '1.125rem',
        padding: '0.125rem',
        verticalAlign: 'middle',
        width: '1.125rem',
        ':hover': {
          borderColor: 'var(--icon-subtle)',
        },
      }}
      {...props}
      type='button'>
      <div
        css={{
          background: 'var(--icon-muted)',
          display: 'flex',
          height: '0.125rem',
          width: '100%',
        }}
      />
    </button>
  );
};

export default CheckboxMinus;
