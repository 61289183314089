import {
  sendMetric as sendMetricSingleton,
  Tags,
  TimerMetric,
  NumericMetric,
} from '../SemanticMetrics';
import { Time } from '../utils/Time';

type PerformanceNavigationTiming = PerformanceEntry & {
  connectEnd: number;
  connectStart: number;
  decodedBodySize: number;
  domComplete: number;
  domContentLoadedEventEnd: number;
  domContentLoadedEventStart: number;
  domInteractive: number;
  domainLookupEnd: number;
  domainLookupStart: number;
  duration: number;
  encodedBodySize: number;
  entryType: 'navigation';
  fetchStart: number;
  initiatorType: 'navigation';
  loadEventEnd: number;
  loadEventStart: number;
  name: string;
  nextHopProtocol: string;
  redirectCount: number;
  redirectEnd: number;
  redirectStart: number;
  requestStart: number;
  responseEnd: number;
  responseStart: number;
  secureConnectionStart: number;
  serverTiming: number[];
  startTime: number;
  transferSize: number;
  type: string;
  unloadEventEnd: number;
  unloadEventStart: number;
  workerStart: number;
};

type PerformanceResourceTiming = PerformanceEntry & {
  connectEnd: number;
  connectStart: number;
  decodedBodySize: number;
  domainLookupEnd: number;
  domainLookupStart: number;
  duration: number;
  encodedBodySize: number;
  entryType: 'resource';
  fetchStart: number;
  initiatorType: 'link' | 'script' | 'other';
  name: string;
  nextHopProtocol: string;
  redirectEnd: number;
  redirectStart: number;
  requestStart: number;
  responseEnd: number;
  responseStart: number;
  secureConnectionStart: number;
  serverTiming: number[];
  startTime: number;
  transferSize: number;
  workerStart: number;
};

export type WebVital = {
  id: string;
  name: string;
  label: string;
  value: number;
};

function getNavigationTiming(): PerformanceNavigationTiming | undefined {
  const [navigationTiming] = window.performance.getEntriesByType(
    'navigation',
  ) as Array<PerformanceNavigationTiming>;

  return navigationTiming;
}

function getResourceTiming(): Array<PerformanceResourceTiming> | undefined {
  return window.performance.getEntriesByType(
    'resource',
  ) as Array<PerformanceResourceTiming>;
}

function getPageLoadTime(): Promise<number> {
  return new Promise((resolve, reject) => {
    if (
      !window.performance ||
      typeof performance.getEntriesByType !== 'function'
    ) {
      // never fullfil the promise to silently ignore browsers without Timing API.
      return reject('window.performance api is not supported in this browser');
    }
    if (typeof getNavigationTiming() === 'undefined') {
      return reject('navigationTiming api is not supported in this browser');
    }
    const {
      loadEventEnd,
    } = getNavigationTiming() as PerformanceNavigationTiming;
    if (loadEventEnd > 0) {
      return resolve(loadEventEnd);
    }
    return window.addEventListener('load', () => {
      // Schedule for next tick since the browser performance API hasn't loaded yet
      setTimeout(() => {
        const {
          loadEventEnd: nextLoadEventEnd,
        } = getNavigationTiming() as PerformanceNavigationTiming;
        return resolve(nextLoadEventEnd);
      }, 0);
    });
  });
}

function getPaintMetric(paintMetricName: String): Promise<number> {
  return new Promise((resolve, reject) => {
    if (
      !window.performance ||
      typeof performance.getEntriesByType !== 'function'
    ) {
      return reject('window.performance api is not supported in this browser');
    }
    return window.addEventListener('load', () => {
      const paintMetrics = performance.getEntriesByType('paint');
      const timeToPaintMetric = paintMetrics.find(
        ({ name }) => name === paintMetricName,
      );
      if (paintMetrics !== undefined && timeToPaintMetric) {
        return resolve(timeToPaintMetric.startTime);
      }
      return reject('Time to paint api is not supported on this browser');
    });
  });
}

// Only works for Chrome 60 and later according to this https://css-tricks.com/paint-timing-api/ August, 2017
function getTimeToFirstPaint(): Promise<number> {
  return getPaintMetric('first-paint');
}

// Only works for Chrome 60 and later according to this https://css-tricks.com/paint-timing-api/ August, 2017
function getTimeToFirstContentfulPaint(): Promise<number> {
  return getPaintMetric('first-contentful-paint');
}

// TODO: rm when singleton is gone
// @deprecated use getWebVitalsMetric() with sendMetric() instead.
function sendWebVitalsMetric(metric: WebVital, tags?: Tags): Promise<void> {
  return sendMetricSingleton(getWebVitalsMetric(metric, tags));
}

/**
 * This takes a Google WebVital metric and transforms it into a Grafana/Heroic compatible metric
 */
function getWebVitalsMetric(
  { name, label, value: vitalValue }: WebVital,
  tags?: Tags,
) {
  // this is a bit convoluted but required for the typesystem to differentiate between
  // numeric metrics values (numbers) vs timer metrics (nanoseconds)
  let metric: NumericMetric | TimerMetric;
  if (name.toLowerCase() === 'cls') {
    metric = {
      metric_type: 'gauge',
      value: vitalValue,
    };
  } else {
    metric = {
      metric_type: 'timer',
      value: Time.fromMillis(Math.round(vitalValue)).asNanos(),
    };
  }
  return {
    what: `web_vitals_${name.toLowerCase()}`,
    ...metric,
    tags: {
      name,
      label,
      ...tags,
    },
  };
}

const BrowserMetrics = {
  getPageLoadTime,
  getResourceTiming,
  getTimeToFirstPaint,
  getTimeToFirstContentfulPaint,
  sendWebVitalsMetric,
  getWebVitalsMetric,
};

export { BrowserMetrics };
