import {PropsWithChildren} from 'react';
import styled from 'styled-components';
import {ButtonIcon} from '../Button';
import {Icon, IconName} from '../Icon';

type AlertSeverity =
  | 'warning'
  | 'error'
  | 'info'
  | 'success'
  | 'spotify'
  | 'info-highlight'
  | 'download'
  | 'insight';

export type AlertProps = PropsWithChildren<{
  /**
   * If true, the alert will be rendered in a compact form.
   * @default false
   */
  compact?: boolean;
  /**
   * The type of the alert. This defines the color and icon used.
   * @default 'warning'
   */
  severity?: AlertSeverity;
  /**
   * Fired when the close button is clicked.
   */
  onClose?: () => void;
  /**
   * If true, the alert will be rendered with a close button.
   */
  withCloseIcon?: boolean;
}>;

export function Alert({
  children,
  compact = false,
  onClose,
  severity = 'warning',
  withCloseIcon = false,
  ...props
}: AlertProps) {
  const {bgColor, color, iconName} = styleMap[severity];

  return (
    <_Alert
      role='alert'
      $bgColor={bgColor}
      $color={color}
      $compact={compact}
      $withCloseIcon={withCloseIcon}
      {...props}>
      <Icon
        icon={iconName}
        css={`
          color: ${color};
          flex-shrink: 0;
          height: 1.5rem;
          margin: -0.0625rem var(--spacing-1) 0;
          width: 1.5rem;
        `}
      />
      <div>{children}</div>
      {withCloseIcon && (
        <ButtonIcon
          icon='close'
          onClick={onClose}
          css={`
            color: ${color};
            height: 1.625rem;
            position: absolute;
            right: 0.625rem;
            top: 0.6875rem;
            width: 1.625rem;
          `}
        />
      )}
    </_Alert>
  );
}

const _Alert = styled.div<{
  $color: string;
  $bgColor: string;
  $compact: boolean;
  $withCloseIcon: boolean;
}>`
  background: ${(p) => p.$bgColor};
  border-radius: ${(p) => (p.$compact ? '0.25rem' : '0.3125rem')};
  border: 0;
  color: ${(p) => p.$color};
  display: flex;
  ${(p) => p.$withCloseIcon && `position: relative;`};
  font-size: ${(p) =>
    p.$compact ? 'var(--font-size-body-2)' : 'var(--font-size-body-1)'};
  gap: var(--spacing-2);
  padding: ${(p) => (p.$compact ? 'var(--spacing-3)' : 'var(--spacing-4)')};
`;

const styleMap: {
  [_key in AlertSeverity]: {
    bgColor: string;
    color: string;
    iconName: IconName;
  };
} = {
  error: {
    bgColor: 'var(--red-orange-10)',
    color: 'var(--text-error)',
    iconName: 'info',
  },
  warning: {
    bgColor: 'var(--highlighter-20)',
    color: 'var(--text-warning)',
    iconName: 'alert',
  },
  info: {
    color: 'var(--text-default)',
    bgColor: 'var(--bg-muted)',
    iconName: 'info-alt',
  },
  insight: {
    color: 'var(--text-default)',
    bgColor: 'var(--bg-muted)',
    iconName: 'lightblub',
  },
  success: {
    bgColor: 'var(--green-10)',
    color: 'var(--text-success)',
    iconName: 'checkmark-circle-outline',
  },
  spotify: {
    bgColor: 'var(--green-10)',
    color: 'var(--black)',
    iconName: 'spotify',
  },
  ['info-highlight']: {
    bgColor: 'var(--blue-10)',
    color: 'var(--text-info)',
    iconName: 'info-alt',
  },
  download: {
    color: 'var(--text-default)',
    bgColor: 'var(--bg-muted)',
    iconName: 'download',
  },
};
