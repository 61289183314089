import {ReactNode} from 'react';
import {Link} from '../Link';

interface Props {
  children: ReactNode;
  to?: string;
}

export const NavLink = ({children, to}: Props) => (
  <Link
    to={to}
    css={`
      background: var(--bg-muted);
      border-radius: 0.5rem;
      color: var(--text-muted);
      font-size: 0.9375rem;
      font-weight: 500;
      margin: 0 0.5rem;
      padding: 0.375rem 0.75rem 0.4375rem;
      text-decoration: none;
      :hover {
        color: var(--blue);
        background: var(--bg-nav-hover);
      }
    `}>
    {children}
  </Link>
);
