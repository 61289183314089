import {Element} from '@core/style';
import {Button} from '@core/ui/Button';

export default function ({organization, podcast, match: {url}}) {
  return (
    <div>
      <h3>
        Organization: {organization.name} | Podcast: {podcast.feed.title}
      </h3>

      <h4>Access</h4>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Permission</th>
            <th>Org</th>
          </tr>
        </thead>
        <tbody>
          {podcast.accessSet.map((access) => {
            return (
              <tr key={access.id}>
                <td>{access.name}</td>
                <td>{access.permission}</td>
                <td>{access.organization.name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Element rules={() => ({textAlign: 'right', padding: '20px 0'})}>
        <Button to={`${url}/access`} style-dark>
          Add Access
        </Button>
      </Element>
    </div>
  );
}
