import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Contact from './Contact';
import Contacts from './Contacts';

export default function () {
  return (
    <Switch>
      <Route exact path='/contacts' component={Contacts} />
      <Route exact path='/contacts/:contactId' component={Contact} />
    </Switch>
  );
}
