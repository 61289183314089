import {useState} from 'react';
import {
  AdminOrganizationDocument,
  useAddDomainMutation,
} from '@admin/graphql-api';
import {Controller, ErrorOption, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {
  Form,
  FormFooter,
  FormGroup,
  LabelInput,
  Submit,
} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';

const DomainsAdd = ({organization}) => {
  const organizationUrl = `/organizations/${organization.id}`;
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [addDomain] = useAddDomainMutation();
  const {
    control,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm({
    defaultValues: {
      domain: '',
    },
  });

  const onSubmit = async (formData: {domain: string}) => {
    setLoading(true);
    try {
      await addDomain({
        variables: {
          input: {
            organizationId: organization.id,
            domain: formData.domain,
          },
        },
        refetchQueries: [AdminOrganizationDocument],
      });
      history.push(organizationUrl);
    } catch (err) {
      setError('domain', err as ErrorOption);
    }
    setLoading(false);
  };

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={organizationUrl}>Add Email Domain</CardHeader>
      <Card>
        <p>
          Once a new domain has been added, any Podsights user who has a
          verified email address at this domain will automatically be added to
          this Organization as a Member. Current Organization Members who do not
          share the new email domain will continue to have access to their data.
        </p>
        <Form errors={errors} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Controller
              name='domain'
              control={control}
              rules={{required: 'Please provide domain name.'}}
              render={({field}) => (
                <LabelInput
                  label='Domain Name'
                  invalid={!!errors.domain}
                  {...field}
                />
              )}
            />
          </FormGroup>
          <FormFooter>
            <Button to={organizationUrl}>Cancel</Button>
            <Submit loading={loading} disabled={loading}>
              Add Domain
            </Submit>
          </FormFooter>
        </Form>
      </Card>
    </ResponsiveContent>
  );
};

export default DomainsAdd;
