import { screenXsMax } from '@spotify-internal/encore-foundation';
import { cssColorValue, cssSpacing, mestoBold, rootStyle, semanticColors } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
export var TableHeaderCellElement = styled.th.withConfig({
  displayName: "TableHeaderCell__TableHeaderCellElement",
  componentId: "sc-16kf5kl-0"
})(["", ";", " color:", ";padding:", ";text-align:", ";vertical-align:bottom;&:first-child{@media (max-width:", "){padding-inline-start:", ";}}&:last-child{@media (max-width:", "){padding-inline-end:", ";}}", ";", ";"], rootStyle(), mestoBold(), function (props) {
  return props.active ? cssColorValue(semanticColors.textBase) : cssColorValue(semanticColors.textSubdued);
}, cssSpacing('tighter', '12px'), function (props) {
  if (props.align === 'left') {
    return 'start';
  } else if (props.align === 'right') {
    return 'end';
  }
  return props.align;
}, screenXsMax, cssSpacing('tighter-4', '4px'), screenXsMax, cssSpacing('tighter-4', '4px'), function (props) {
  return props.selectable && css(["cursor:default;&:hover{color:", ";}"], cssColorValue(semanticColors.textBase));
}, function (props) {
  return props.isHover && css(["color:", ";"], cssColorValue(semanticColors.textBase));
});