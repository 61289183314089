import {FC, useEffect, useRef, useState} from 'react';
import {IconChevronDown} from '@spotify-internal/encore-web';
import {css} from '@core/style';
import {Icon} from '../Icon';
import Select from '../Select';
// TODO: Check all import Select from '../Select' : was a default export
import {SelectProps} from '../Select/Select';

/**
 * @deprecated
 */
export interface ILabelSelectProps extends SelectProps {
  label: string;
}

/**
 * @deprecated
 */
const LabelSelect: FC<ILabelSelectProps> = ({
  defaultValue,
  disabled = false,
  getSelectLabel,
  invalid,
  items,
  label,
  onSelect,
  outline = true,
  propertyForName = 'title',
  rules,
  ...selectProps
}): JSX.Element => {
  const firstRendered = useRef<boolean>(false);
  const [value, setValue] = useState(
    typeof defaultValue !== 'undefined' ? defaultValue : null
  );

  const _onSelect = (_value: any) => {
    if (firstRendered.current) onSelect?.(_value);
    setValue(_value);
  };

  const _getSelectLabel = (item: any) => {
    if (typeof getSelectLabel === 'function') {
      return getSelectLabel(item);
    }
    return (
      <div css={{fontWeight: 400, lineHeight: 1.3}}>
        {item[0][propertyForName]}
      </div>
    );
  };

  useEffect(() => {
    firstRendered.current = true;
  }, []);

  const isActive = !!value;

  return (
    <div
      {...css([
        () => ({
          lineHeight: 1.5,
          position: 'relative',
          ':hover': {
            '> div': {
              ...(!disabled && {color: 'var(--black)'}),
            },
            '> svg': {
              ...(!disabled && {color: 'var(--black)'}),
            },
          },
        }),
        rules,
      ])}>
      <div
        css={{
          borderRadius: '.25rem',
          bottom: '1.125rem',
          color: invalid ? 'var(--red-orange)' : 'var(--text-muted)',
          left: '0.3125rem',
          margin: 'auto',
          padding: '0 1rem',
          pointerEvents: 'none',
          position: 'absolute',
          transform: isActive ? 'scale(.75) translateY(-1.125rem)' : undefined,
          transformOrigin: 'left',
          transition:
            'transform 150ms cubic-bezier(0.4,0,0.2,1), opacity 150ms cubic-bezier(0.4,0,0.2,1)',
          whiteSpace: 'nowrap',
        }}>
        {label}
      </div>
      <Select
        defaultValue={defaultValue}
        disabled={disabled}
        noChevronIcon
        onSelect={(item) => _onSelect(item)}
        invalid={invalid}
        items={items}
        outline={outline}
        propertyForName={propertyForName}
        placeholder={''}
        rules={() => ({
          background: 'var(--bg-default)',
          height: '3.75rem',
          lineHeight: '1.375rem',
          overflow: 'hidden',
          padding: '1.625rem 2.625rem .5rem 1rem',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
        })}
        getSelectLabel={_getSelectLabel}
        {...selectProps}
      />
      <IconChevronDown
        style={{
          bottom: 0,
          color: invalid ? 'var(--red-orange)' : 'var(--icon-muted)',
          height: '1.625rem',
          margin: 'auto',
          pointerEvents: 'none',
          position: 'absolute',
          right: '1.275rem',
          top: 0,
          width: '1.625rem',
        }}
      />
    </div>
  );
};

export default LabelSelect;
