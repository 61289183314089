import {createTheme} from '@mui/material/styles';
import {components} from './_componentThemeOptions';
import {dark, light} from './_paletteColorOptions';
import {typography} from './_typographyOptions';

export function getMuiTheme(mode: 'dark' | 'light' = 'light') {
  // Order is important here.
  let theme = createTheme({light, dark}[mode]);
  theme = createTheme(theme, typography);
  theme = createTheme(theme, {
    spacing: (factor) => `var(--spacing-${factor})`,
  });
  return createTheme(theme, components);
}
