import {Logger, LevelName, Log} from './typedefs';

import {DebugLogger} from './debug_logger';

type DbgLoggerRegistry = {
  map: {
    [key: string]: any;
  };
  list: any[];
  loggingPredicate: Function;
};

declare namespace global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  let __dbgLoggerRegistry: DbgLoggerRegistry;
}

const GLOBAL_LOGGER_REGISTRY_KEY = '__dbgLoggerRegistry';

let globalRegistry = global[GLOBAL_LOGGER_REGISTRY_KEY];

if (!globalRegistry) {
  globalRegistry = {
    map: {},
    list: [],
    loggingPredicate: function () {},
  };
  if (Object.defineProperty) {
    Object.defineProperty(global, GLOBAL_LOGGER_REGISTRY_KEY, {
      value: globalRegistry,
    });
  } else {
    global[GLOBAL_LOGGER_REGISTRY_KEY] = globalRegistry;
  }
}

const loggerMap = globalRegistry.map;
const loggers = globalRegistry.list;

function _checkLog(logObj: Log): boolean {
  if (
    globalRegistry.loggingPredicate &&
    globalRegistry.loggingPredicate(logObj)
  ) {
    return true;
  }
  return false;
}

let _level: LevelName = 'log';

export function intercept(predicate: Function): void {
  if (typeof predicate !== 'function') {
    throw new TypeError('Logging.intercept requires a function predicate.');
  }
  globalRegistry.loggingPredicate = predicate;
}

export function unintercept(): void {
  globalRegistry.loggingPredicate = () => {};
}

export function list(asArray: boolean): [] | {} {
  const keys = Object.keys(loggerMap)
    .filter((key) => loggerMap[key])
    .sort();

  if (asArray) {
    return keys.map((key) => {
      return {
        tag: key,
        description: loggerMap[key].description || 'No description.',
      };
    });
  }

  return keys.reduce((obj, key) => {
    obj[key] = loggerMap[key].description || 'No description';
    return obj;
  }, {} as any);
}

export function enable(tag: string[] | string): void {
  const tags = Array.isArray(tag) ? tag : [tag];
  let tagLen = tags.length;
  while (tagLen--) {
    const tagName = tags[tagLen]?.toLowerCase();
    let loggerLen = loggers.length;
    while (loggerLen--) {
      const logger = loggers[loggerLen];
      if (logger.matchesTag(tagName)) {
        logger.enable();
      }
    }
  }
}

export function disable(tag: string[] | string): void {
  const tags = Array.isArray(tag) ? tag : [tag];
  let tagLen = tags.length;
  while (tagLen--) {
    const tagName = tags[tagLen]?.toLowerCase();
    let loggerLen = loggers.length;
    while (loggerLen--) {
      const logger = loggers[loggerLen];
      if (logger.matchesTag(tagName)) {
        logger.disable();
      }
    }
  }
}

export function setLevel(levelName: LevelName): void {
  _level = levelName;
  let loggerLen = loggers.length;
  while (loggerLen--) {
    const logger = loggers[loggerLen];
    if (logger) {
      logger.setLevel(levelName);
    }
  }
}

export function enableAll(): void {
  let l = loggers.length;
  while (l--) {
    if (!loggers[l]) {
      continue;
    }
    loggers[l].enable();
  }
}

export function disableAll(): void {
  let l = loggers.length;
  while (l--) {
    if (!loggers[l]) {
      continue;
    }
    loggers[l].disable();
  }
}

export function forTag(
  tag: string | {tag: string; description?: string},
  descriptor?: string
): Logger {
  let tagName;
  let description;
  if (typeof tag === 'string') {
    tagName = tag.toLowerCase();
    description = descriptor;
  } else {
    tagName = tag.tag;
    description = tag.description;
  }
  if (loggerMap.hasOwnProperty(tagName) && loggerMap[tagName]) {
    return loggerMap[tagName];
  }
  const logger = new DebugLogger(tagName, description, _checkLog);
  logger.setLevel(_level);
  loggerMap[tagName] = logger;
  loggers.push(logger);
  return logger;
}

export function remove(tag: string): void {
  const tagName = tag.toLowerCase();
  if (!loggerMap.hasOwnProperty(tagName) || !loggerMap[tagName]) {
    return;
  }
  const logger = loggerMap[tagName];
  loggerMap[tagName] = null;
  const index = loggers.indexOf(logger);
  if (index !== -1) {
    loggers.splice(index, 1);
  }
  return;
}
