import {AssertionError} from 'assert';

type Nothing = null | undefined;

export const isNone = (x: unknown): x is Nothing =>
  typeof x === 'undefined' || x === null;

export const assertIsNone = (x: any): asserts x is Nothing => {
  if (!isNone(x))
    throw new AssertionError({
      message: `${x} expected to be null or undefined, but was not.`,
      actual: x,
      expected: 'null or undefined',
    });
};

export const exists = <T>(x?: T): x is NonNullable<T> => !isNone(x);

export function assertExists<T>(val: T): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new AssertionError({
      message: `Expected 'val' to be defined, but received ${val}`,
      actual: val,
      expected: 'defined',
    });
  }
}

export const toCamelCase = (str: string) =>
  str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());

export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const jsonToCsv = (json) => {
  let csv = '';
  const keys = (json[0] && Object.keys(json[0])) || [];
  csv += `${keys.join(',')}\n`;

  for (const line of json) {
    csv += `${keys.map((key) => line[key]).join(',')}\n`;
  }

  return csv;
};

export const afterNextRender = (cb: () => void) =>
  requestAnimationFrame(() => setTimeout(cb));

export const isDescendant = (parent, child) => {
  let node = child; // child.parentNode;
  while (node != null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

export const removeNonLatinChars = (str: string) =>
  // eslint-disable-next-line no-control-regex
  str.replace(/[^\x00-\x7F]/g, '');

/**
 * Retrieve a fixed number of elements from an array, evenly distributed but
 * always including the first and last elements.
 *
 * @param   {Array} items - The array to operate on.
 * @param   {number} n - The number of elements to extract.
 * @returns {Array}
 */
export const distributedCopy = (items: any[], n: number) => {
  const elements = [items[0]];
  const totalItems = items.length - 2;
  const interval = Math.floor(totalItems / (n - 2));

  for (let i = 1; i < n - 1; i++) {
    elements.push(items[i * interval]);
  }

  elements.push(items[items.length - 1]);
  return elements;
};

/**
 * Escape a regular expression string.
 *
 * @param  {string} str
 * @return {string}
 */
export const escapeString = (str: string) =>
  str.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

export const encodeString = (str: string) =>
  str.replace(/[\u00A0-\u9999<>&]/g, (i) => `&#${i.charCodeAt(0)};`);

export const isExternalLink = (url: string) => {
  const tmp = document.createElement('a');
  tmp.href = url;
  return tmp.host !== window.location.host;
};

export const formatNumberInputLocale = (target) => {
  const value = target.value.replace(/,/gi, '').replace(/[^0-9.]/g, '');
  const isNumber = !isNaN(parseFloat(value));
  const enteringDecimal = value.slice(-1) === '.' || value.slice(-2) === '.0';

  if (!enteringDecimal) {
    if (isNumber) {
      target.value = parseFloat(value).toLocaleString('en-EN');
    } else {
      target.value = '';
    }
  }

  return value;
};

export const numberToLocale = (value: string) => {
  return !isNaN(parseFloat(value))
    ? parseFloat(value).toLocaleString('en-EN')
    : value;
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getDocumentSelection = () => {
  if (window.getSelection?.()) return window.getSelection()!.toString();
  if (document.getSelection?.()) return document.getSelection()!.toString();

  const selection =
    (document as any)?.selection && (document as any)?.selection?.createRange();
  if (selection?.text) return selection.test.toString();
  return '';
};

export const computedCSSVariable = (variable: string): string => {
  if (variable.startsWith('var(--')) {
    const cssVariable = variable.replace('var(', '').replace(')', '').trim();

    return window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(cssVariable.trim())
      .trim();
  }
  return variable;
};

export const clampValue = (
  value: string | number,
  min = 0,
  max = 100
): number => {
  value = typeof value === 'string' ? parseInt(value) : value;
  return Math.floor(Math.max(min, Math.min(value, max)));
};
