import {
  UpdateCampaignStateInput,
  useUpdateCampaignStateMutation,
} from '@admin/graphql-api';
import Select from '@core/ui/Select';
import {CampaignDetailsProps} from '.';

export function CampaignState({
  campaign,
  onCampaignError,
  setUpdatingCampaign,
}: CampaignDetailsProps) {
  const [updateState] = useUpdateCampaignStateMutation();

  const onSelect = async (item) => {
    setUpdatingCampaign?.(true);
    try {
      await updateState({
        variables: {
          input: {
            id: campaign.id,
            state: item.value.toLowerCase(),
          } as UpdateCampaignStateInput,
        },
      });
    } catch (e) {
      console.warn(e);
      onCampaignError(e.message ?? 'Error updating campaign state');
    } finally {
      setUpdatingCampaign?.(false);
    }
  };

  return (
    <Select
      defaultValue={campaign?.state}
      items={[
        {title: 'Live', value: 'LIVE'},
        {title: 'Draft', value: 'DRAFT'},
        {title: 'Ready', value: 'READY'},
        ...((campaign.stateOverview?.managers ?? []).length
          ? [{title: 'Live only for Agency', value: 'AGENCY'}]
          : []),
        {title: 'Complete', value: 'COMPLETE'},
      ]}
      onSelect={onSelect}
      rules={() => ({marginBottom: '0.5rem'})}
      small
    />
  );
}
