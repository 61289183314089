import React from 'react';
import DeleteReviewer from '@admin/graphql-api/_old_mutations/DeleteReviewer';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import {Element} from '@core/style';
import {Submit} from '@core/ui/FormElements';

class ReviewerDelete extends React.Component {
  state = {
    loading: false,
    complete: false,
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const {
      deleteReviewer,
      match: {
        params: {reviewerId},
      },
    } = this.props;

    this.setState({
      loading: true,
    });

    deleteReviewer({id: reviewerId}).then(() => {
      this.setState({
        complete: 'true',
      });
    });
  };

  render() {
    const {loading, complete, error} = this.state;

    if (complete) {
      return <Redirect to='/reviewers' />;
    }

    return (
      <form onSubmit={this.onSubmit}>
        <p>You Sure?</p>
        <Element rules={() => ({padding: '20px 0', textAlign: 'center'})}>
          <Submit
            variant='outlined'
            value='Delete Reviewer'
            disabled={loading}
          />
        </Element>
      </form>
    );
  }
}

export default graphql(DeleteReviewer, {
  props: ({mutate}) => ({
    deleteReviewer: (input) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
  }),
})(ReviewerDelete);
