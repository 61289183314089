import {gql} from '@apollo/client';

export default gql`
  query getPrefixlessCampaignPodcasts {
    admin {
      prefixlessCampaignPodcasts {
        id

        feed {
          id
          title
          href
        }

        campaign {
          id
          name
          slug

          organization {
            id
            name
            slug
          }

          advertiser {
            id
            name
          }
        }

        createdAt

        campaignEpisodes {
          id
          futureEpisode {
            id
            expectedPublished
          }
          episode {
            id
            published
          }
        }
      }
    }
  }
`;
