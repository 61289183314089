import {
  Checkbox as MuiCheckbox,
  type CheckboxProps as MuiCheckboxProps,
  ThemeOptions,
} from '@mui/material';

export const Checkbox = MuiCheckbox;
export type CheckboxProps = MuiCheckboxProps;

export const MuiCheckboxStyles: ThemeOptions['components'] = {
  MuiCheckbox: {
    defaultProps: {
      color: 'primary',
    },
    styleOverrides: {
      root: ({theme, ownerState: {color = 'primary'}}) => ({
        color: theme.palette[color].main,
      }),
    },
  },
};
