import {gql} from '@apollo/client';

export default gql`
  query getCompanyDetail($id: ID!) {
    admin {
      company(id: $id) {
        id
        feeds {
          id
          title
          subtitle
          summary
          image {
            href
            path
          }
        }

        ads(limit: 10) {
          id
          mpegPath
          flacPath
          waveform
          words
          duration
          state
          company {
            id
            name
          }
          product {
            id
            name
          }

          sponsor {
            id
            name
          }

          placementsCount
          placements(limit: 10) {
            id
            episode {
              id
              title
              subtitle
              summary
              placementsCount
              image {
                id
                href
                path
              }
              feed {
                id
                title
                image {
                  id
                  path
                }
              }
              enclosure {
                id
                length
              }
            }
          }
        }
      }
    }
  }
`;
