import {gql} from '@apollo/client';

export default gql`
  mutation deleteReviewer($input: DeleteReviewerInput!) {
    authed {
      success
    }
    deleteReviewer(input: $input) {
      success
    }
  }
`;
