import {FC, HTMLProps, PropsWithChildren, useState} from 'react';

/**
 * @deprecated
 */
export interface ToggleProps
  extends PropsWithChildren<HTMLProps<HTMLButtonElement>> {
  checkedColor?: string;
  labelStyle?: React.CSSProperties;
  defaultChecked?: boolean;
  disabled?: boolean;
  height?: number;
  onChecked?: (_checked: boolean) => void;
  reverseLabel?: boolean;
  uncheckedColor?: string;
}

/**
 * @deprecated
 */
const Toggle: FC<ToggleProps> = ({
  checkedColor = 'var(--blue)',
  children,
  labelStyle,
  defaultChecked = false,
  disabled = false,
  height = 16,
  onChecked,
  reverseLabel = false,
  style,
  uncheckedColor,
  ...props
}): JSX.Element => {
  const [checked, setChecked] = useState<boolean>(defaultChecked);
  const primaryColor = checked
    ? checkedColor
    : uncheckedColor || 'var(--border-muted)';

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (evt: any) => {
    evt.preventDefault();
    if (!disabled) {
      const value = !checked;
      onChecked?.(value);
      setChecked(value);
    }
  };

  return (
    <button
      tabIndex={0}
      role='checkbox'
      aria-checked={checked}
      onClick={onClick}
      css={`
        -webkit-tap-highlight-color: transparent;
        align-items: center;
        background: none;
        border: 0;
        cursor: ${disabled ? 'default' : 'pointer'};
        display: inline-flex;
        flex-direction: ${reverseLabel ? 'row-reverse' : 'row'};
        justify-content: space-between;
        opacity: ${disabled ? 0.4 : 'initial'};
        user-select: none;
      `}
      style={style}
      {...props}
      type='button'>
      <div
        css={`
          align-items: center;
          background: ${primaryColor};
          border-radius: 3rem;
          display: flex;
          flex-shrink: 0;
          height: ${height}px;
          margin-top: ${children ? '.0625rem' : 0};
          width: ${height * 1.75}px;
        `}>
        <div
          css={`
            background: var(--white);
            border: 2px solid ${primaryColor};
            border-radius: 3rem;
            flex-shrink: 0;
            height: ${height}px;
            transform: ${checked
              ? `translateX(${height - height * 0.25}px)`
              : 'none'};
            transition: border-color 0.1s, transform 0.1s;
            width: ${height}px;
          `}
        />
      </div>
      {children ? (
        <div
          css={`
            font-weight: 400,
            margin: ${reverseLabel ? '0 0.75rem 0 0' : '0 0 0 0.75rem'};
          `}
          style={labelStyle}>
          {children}
        </div>
      ) : null}
    </button>
  );
};

export default Toggle;
