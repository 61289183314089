import {gql} from '@apollo/client';

export default gql`
  mutation createInvoice($input: CreateInvoiceInput!) {
    authed {
      success
    }
    createInvoice(input: $input) {
      success
    }
  }
`;
