import SetupRequests from '@admin/components/SetupRequests';
import {Route, Switch} from 'react-router-dom';

export default function () {
  return (
    <Switch>
      <Route exact path='/setup-requests' component={SetupRequests} />
    </Switch>
  );
}
