import React from 'react';
import ReviewerDetailQuery from '@admin/graphql-api/_old_queries/ReviewerDetailQuery';
import {graphql} from '@apollo/client/react/hoc';
import {comma} from '@core/lib/filters';
import {Element} from '@core/style';
import {Link} from '@core/ui/Link';
import Loading from '@core/ui/Loading';
import Stat from '@core/ui/Stat';
import Episode from './ReviewersEpisode';

const ReviewerDetail = ({data: {loading, admin}}) => {
  if (loading) {
    return <Loading absolute />;
  }

  const {reviewer} = admin;

  return (
    <Element rules={() => ({maxWidth: '800px', margin: '20px auto'})}>
      <Element
        rules={() => ({
          display: 'flex',
          fontSize: '12px',
          marginBottom: '20px',
        })}>
        <Link
          to='/reviewers'
          css={`
            flex: 1;
          `}>
          &lt; Back
        </Link>
        <Link to={`/reviewers/${reviewer.id}/delete`}>Delete</Link>
      </Element>
      <Element rules={() => ({display: 'flex'})}>
        <Element tag='h5' rules={() => ({flex: 1})}>
          {reviewer.email}
        </Element>
        <Stat
          title='total minutes'
          value={comma(reviewer.totalEpisodeMinutes)}
        />
        <Stat
          title='total episode count'
          value={comma(reviewer.totalEpisodeCount)}
        />
      </Element>

      {reviewer.episodes.map((episode) => {
        return <Episode episode={episode} key={episode.id} />;
      })}
    </Element>
  );
};

export default graphql(ReviewerDetailQuery, {
  options: ({
    match: {
      params: {reviewerId},
    },
  }) => ({
    variables: {
      id: reviewerId,
    },
  }),
})(ReviewerDetail);
