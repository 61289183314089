import React from 'react';
import {Redirect, Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import dayjs from '@core/lib/dayjs';
import RangeIndex from './range';

const BackdatedRedirect = () => {
  return (
    <Redirect
      to={`/backdated/${dayjs()
        .subtract(3, 'days')
        .format('YYYY-MM-DD')}/${dayjs()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')}`}
    />
  );
};

const BackdatedIndex = () => {
  return (
    <Switch>
      <PropsRoute exact path='/backdated' component={BackdatedRedirect} />
      <PropsRoute path='/backdated/:start/:end' component={RangeIndex} />
    </Switch>
  );
};

export default BackdatedIndex;
