import styled, {keyframes} from 'styled-components';
import {Theme, useTheme} from '@core/style';

export interface LoadingSpinnerProps {
  /**
   * Center the loading spinner using an absolute position.
   * @default false
   */
  absolute?: boolean;
  /**
   * Center the loading spinner using a fixed position.
   * @default false
   */
  fixed?: boolean;
  /**
   * Wrap the loading component in a container and center it.
   * @default false
   */
  centered?: boolean;
  /**
   * Color for the loading border
   */
  color?: string;
  /**
   * Size of the circle.
   * @default default
   */
  size?: 'small' | 'medium' | 'large';
}

export default function LoadingSpinner({
  absolute = false,
  centered = false,
  color,
  fixed = false,
  size = 'large',
  ...props
}: LoadingSpinnerProps) {
  const theme = useTheme();
  const {spinnerSize, borderSize} = sizesMap[size] || sizesMap.small;

  const content = (
    <_Spinner
      spinnerSize={spinnerSize}
      borderSize={borderSize}
      themeName={theme}
      color={color}
    />
  );

  if (centered || absolute || fixed) {
    return (
      <div
        css={`
          align-items: center;
          display: flex;
          justify-content: center;
          padding: 1rem 0;
          pointer-events: none;
          ${centered && `width: 100%;`}
          ${(fixed || absolute) &&
          `
            position: ${fixed ? 'fixed' : absolute ? 'absolute' : 'initial'};
            inset: 0;
            margin: auto;
            `}
        `}
        {...props}>
        {content}
      </div>
    );
  }
  return (
    <div
      css={`
        display: inline-flex;
      `}
      {...props}>
      {content}
    </div>
  );
}

const _Spinner = styled.div<{
  borderSize: string;
  color?: string;
  spinnerSize: string;
  themeName: Theme;
}>`
  animation: ${(p) => spinningAnimation({color: p.color})} 0.9s infinite linear;
  border-left: ${(p) => p.borderSize} solid var(--white);
  border-radius: 50%;
  height: ${(p) => p.spinnerSize};
  position: relative;
  transform: translateZ(0);
  width: ${(p) => p.spinnerSize};
  ${(p) => {
    return ['border-bottom', 'border-top', 'border-right']
      .map(
        (border) => `
        ${border}: ${p.borderSize} solid
          ${
            p.themeName === 'dark'
              ? 'rgba(0, 0, 0, 0.1)'
              : 'rgba(255, 255, 255, 0.1)'
          };
      `
      )
      .join('');
  }}
`;

const spinningAnimation = ({color}: {color?: string}) => keyframes`
  0% {
    transform: rotate(0deg);
    border-left-color: ${color || 'var(--green)'};
  }
  25% {
    border-left-color: ${color || 'var(--pink)'};
  }
  50% {
    border-left-color: ${color || 'var(--blue)'};
  }
  100% {
    transform: rotate(360deg);
    border-left-color: ${color || 'var(--highlighter)'};
  }
`;

const sizesMap = {
  small: {
    spinnerSize: '22px',
    borderSize: '2px',
  },
  medium: {
    spinnerSize: '26px',
    borderSize: '2.5px',
  },
  large: {
    spinnerSize: '34px',
    borderSize: '3px',
  },
};
