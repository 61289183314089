import {gql} from '@apollo/client';

export default gql`
  query searchCompanies($query: String!) {
    me {
      companySearch(query: $query) {
        id
        name
        slug
        hasActivePixel
        industry
        industryDisplay
        company {
          campaignCount
        }
      }
    }
  }
`;
