export type DeviceContext = {
  name: 'context_device';
  data: {
    /**
     * The device manufacturer
     */
    manufacturer: string;
    /**
     * The device model
     */
    model: string;
  };
};

/**
 * A builder for Device
 *
 * @param data - The event data.
 * @param data.manufacturer - The manufacturer of the device.
 * @param data.model - The model of the device.
 * @return The formatted event data for DeviceContext
 */
export function createDeviceContext(data: {
  manufacturer: string;
  model: string;
}): () => DeviceContext {
  return () => {
    return {name: 'context_device', data};
  };
}
