import {PropsWithChildren, ReactNode} from 'react';
import {Stack} from '../Layout';
import {Text} from '../Text';

type Props = PropsWithChildren<{
  rightContent?: ReactNode | undefined;
}>;

export default function PageTitle({children, rightContent, ...props}: Props) {
  const content = (
    <Text
      as='h1'
      variant='h2'
      css={`
        line-height: 1;
        letter-spaccing: -0.02em;
        margin: ${rightContent
          ? 0
          : 'var(--spacing-4) var(--spacing-6) var(--spacing-8)'};
      `}
      {...props}>
      {children}
    </Text>
  );

  if (rightContent) {
    return (
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        css={`
          margin: var(--spacing-4) var(--spacing-6) var(--spacing-8);
        `}
        {...props}>
        {content}
        {rightContent}
      </Stack>
    );
  }

  return content;
}
