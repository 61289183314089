import React from 'react';
import {Redirect, Route} from 'react-router-dom';

export type RouteType = {
  /**
   * Unique identifier for the route.
   */
  key: string;
  /**
   * Title for the route. Usefull for navigation menus.
   */
  title?: string;
  /**
   * Path for the react-router-dom Route element. If not specified, then `key` would be used as path.
   */
  path?: string;
  to?: string;
  /**
   * If `true`, the location will match if the path matches exactly.
   */
  exact?: boolean;
  /**
   * If `true`, the route will be accessible and returned by `useRoutes`.
   * @default true
   */
  isAvailable?: boolean;
  isActive?: (_props: {path: string}) => boolean;
};

type GetRouteUrlFn = (
  _key: string,
  _props?: {[_key in string]: string}
) => string;

const useRoutes = ({
  basePath,
  baseUrl,
  routes,
}: {
  basePath: string;
  baseUrl: string;
  routes: RouteType[];
}) => {
  const generatedRoutes: RouteType[] = routes
    .filter(({isAvailable = true}) => isAvailable)
    .map(({key, title, path, to, exact = false, isActive}) => {
      const _path = typeof path === 'string' ? path : key;

      return {
        key,
        title,
        exact,
        ...(basePath && {
          path: _path ? `${basePath}/${_path}` : basePath,
        }),
        ...(baseUrl && {
          to: _path
            ? `${baseUrl}/${typeof to === 'string' ? to : _path}`
            : baseUrl,
        }),
        isActive,
      };
    });

  const renderRoutes = ({
    components,
    extraProps,
    redirects = [],
  }: {
    components: Record<string, React.FC<any>>;
    extraProps: Record<string, any>;
    redirects?: {from: string; to: string}[];
  }) => [
    ...generatedRoutes.map(({key, exact, path}) => {
      const Component = components[key];

      if (Component) {
        return (
          <Route
            key={key}
            exact={exact}
            path={path}
            render={(routeProps) => (
              <Component {...extraProps} {...routeProps} />
            )}
          />
        );
      }
      return null;
    }),
    redirects.length > 0 && basePath && baseUrl
      ? [
          ...redirects.map(({from, to}) => (
            <Route
              path={`${basePath}${from ? `/${from}` : ''}`}
              key={`__redirect_${from}_${to}`}
              exact>
              <Redirect to={`${baseUrl}${to ? `/${to}` : ''}`} />
            </Route>
          )),
        ]
      : null,
  ];

  const getRouteUrl: GetRouteUrlFn = (key, props) => {
    const route = generatedRoutes.find((route) => route.key === key);

    if (!route) {
      return '';
    }

    const {to} = route;

    if (typeof to === 'string') {
      const path = to.replace(/:([^/]+)/g, (_match, key) => {
        if (props && typeof props[key] === 'string') {
          return props[key];
        }
        return '';
      });

      return path;
    }

    return '';
  };

  return {
    renderRoutes,
    routes: generatedRoutes.filter(({title}) => !!title),
    getRouteUrl,
  };
};

export default useRoutes;
