import {
  ChangeEventHandler,
  FC,
  HTMLProps,
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {TRules, css} from '@core/style';
import {IconButton} from '../Button/LegacyButton';
import {Icon} from '../Icon';

export interface ISearchInputProps
  extends Omit<HTMLProps<HTMLInputElement>, 'onInput'> {
  autofocus?: boolean;
  domRef?: MutableRefObject<HTMLInputElement | null>;
  inputRules?: TRules;
  onCancel?: () => void;
  rules?: TRules;
  small?: boolean;
  withCancelIcon?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  onInput: ChangeEventHandler<HTMLInputElement>;
}

const SearchInput: FC<ISearchInputProps> = ({
  autoComplete = 'off',
  autofocus = false,
  domRef,
  inputRules,
  onCancel,
  rules,
  small,
  spellCheck = 'false',
  withCancelIcon,
  onInput,
  value: valueProp,
  ...inputProps
}): JSX.Element => {
  const [value, setValue] = useState(valueProp ?? '');
  const [autofocused, setAutofocused] = useState(false);
  const newRef = useRef<HTMLInputElement>(null);
  const ref = domRef ?? newRef;

  useLayoutEffect(() => {
    // Only autofocus once. Extra state variable
    // to handle dependency array requirement, even though
    // we really only want to do this once regardless.
    if (autofocused) return;

    if (autofocus) ref.current?.focus();
    setAutofocused(true);
  }, [autofocus, ref, autofocused, setAutofocused]);

  const _onCancel: ISearchInputProps['onClick'] = useCallback(() => {
    if (ref.current) ref.current.value = '';
    setValue('');
    onCancel?.();
  }, [ref, setValue, onCancel]);

  const _onInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setValue(e.target?.value);
      onInput?.(e);
    },
    [setValue, onInput]
  );

  return (
    <div
      {...css([
        () => ({
          display: 'flex',
          fontSize: '0.9375rem',
          position: 'relative',
          textAlign: 'center',
        }),
        rules,
      ])}>
      <input
        ref={ref}
        type='search'
        autoComplete={autoComplete}
        spellCheck={spellCheck}
        onInput={_onInput}
        value={valueProp ?? value}
        {...css([
          () => ({
            background: 'var(--bg-default)',
            border: '1px solid var(--border-muted)',
            borderRadius: small
              ? 'var(--input-border-radius-sm)'
              : 'var(--input-border-radius-md)',
            color: 'var(--black)',
            display: 'block',
            fontSize: 'inherit',
            height: '2.625rem',
            outline: 0,
            padding: small ? '0 .375rem 0 2rem' : '0 .625rem 0 2.25rem',
            transition: 'all .1s linear',
            width: '100%',
            ':focus': {
              background: 'var(--bg-default)',
              borderColor: 'var(--black)',
              borderWidth: '0.125rem',
            },
            '::placeholder': {
              color: 'var(--text-muted)',
              fontSize: 'inherit',
            },
          }),
          inputRules,
        ])}
        {...inputProps}
      />
      <Icon
        icon='magnify'
        rules={() => ({
          bottom: 0,
          color: 'var(--icon-subtle)',
          height: '1.25rem',
          left: small ? '0.5rem' : '0.625rem',
          margin: 'auto',
          position: 'absolute',
          top: 0,
          width: '1.25rem',
        })}
      />
      {withCancelIcon && !!value.length && (
        <IconButton
          onClick={_onCancel}
          rules={() => ({
            bottom: 0,
            margin: 'auto',
            position: 'absolute',
            right: '0.25rem',
            top: 0,
          })}>
          <Icon
            icon='close'
            rules={() => ({
              color: 'var(--icon-muted)',
              height: '1.125rem',
              width: '1.125rem',
            })}
          />
        </IconButton>
      )}
    </div>
  );
};

export default SearchInput;
