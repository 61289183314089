import React from 'react';
import FeedsQuery from '@admin/graphql-api/_old_queries/FeedsQuery';
import {graphql} from '@apollo/client/react/hoc';
import dayjs from '@core/lib/dayjs';
import {comma} from '@core/lib/filters';
import {stripHTML, truncateWords} from '@core/lib/words';
import {Element} from '@core/style';
import {GREEN, TEXT_MUTED} from '@core/style';
import Badge from '@core/ui/Badge';
import DataTable from '@core/ui/DataTable';
import {FeedImage} from '@core/ui/Image';
import Loading from '@core/ui/Loading';

const FeedsFilter = ({data: {loading, admin}, orderBy}) => {
  if (loading) {
    return <Loading centered />;
  }

  const columns = [
    {
      title: 'Feed',
      accessor: 'title',
      type: 'string',
      Cell: ({data: feed}) => {
        return (
          <Element rules={() => ({display: 'flex', minWidth: '15.5rem'})}>
            <Element
              rules={() => ({
                minWidth: '4.6875rem',
                width: '4.6875rem',
                marginRight: '1rem',
              })}>
              <FeedImage feed={feed} width={150} />
            </Element>
            <Element>
              <Element
                rules={({theme}) => ({
                  fontWeight: 500,
                  color: theme.textPrimary,
                })}>
                {feed.title}
              </Element>
              <Element
                rules={({theme}) => ({
                  color: theme.textTertiary,
                  fontSize: '.875rem',
                })}>
                {truncateWords(
                  stripHTML(feed.subtitle || feed.summary || ''),
                  50
                )}
              </Element>
            </Element>
          </Element>
        );
      },
    },
    {
      title: 'State',
      accessor: 'state',
      type: 'string',
      Cell: ({data: {state}}) => {
        const isActive = state === 'ACTIVE';

        return (
          <Badge
            variant={isActive ? 'filled' : 'outlined'}
            color={isActive ? 'secondary' : 'default'}
            showLiveDot={isActive}>
            {state}
          </Badge>
        );
      },
    },
    {
      title: 'Ads',
      accessor: 'placementsCount',
      type: 'number',
      fmt: comma,
    },
    {
      title: 'Episodes',
      accessor: 'episodesCount',
      type: 'number',
      fmt: comma,
    },
    {
      title: 'Processed Episodes',
      accessor: 'processedEpisodesCount',
      type: 'number',
      fmt: comma,
    },
    {
      title: 'Created At',
      accessor: 'createdAt',
      type: 'date',
      fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
    },
  ];

  const data = admin.feeds;

  return (
    <DataTable
      columns={columns}
      data={data}
      pagination={false}
      orderBy={orderBy}
      emptyMessageText='No feeds found.'
      onClickRowPath={({id}) => `/feeds/${id}`}
    />
  );
};

export default graphql(FeedsQuery, {
  options: ({query, offset, order, limit, state}) => ({
    variables: {
      query,
      offset,
      limit,
      order,
      state,
    },
  }),
})(FeedsFilter);
