import React from 'react';
import DeleteFutureEpisodeMutation from '@admin/graphql-api/_old_mutations/DeleteFutureEpisode';
import FutureEpisodeQuery from '@admin/graphql-api/_old_queries/FutureEpisodeQuery';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import {Redirect} from 'react-router-dom';
import {Element} from '@core/style';
import {Button} from '@core/ui/Button';
import {GraphErrors} from '@core/ui/Form';
import {Dummy, Img} from '@core/ui/Image';
import {Link} from '@core/ui/Link';
import Loading from '@core/ui/Loading';

class FutureEpisode extends React.Component {
  state = {
    loading: false,
    complete: false,
    errors: [],
  };

  deleteFutureEpisode = () => {
    const {
      deleteFutureEpisode,
      data: {
        admin: {
          futureEpisode: {id},
        },
      },
    } = this.props;

    this.setState({
      loading: true,
    });

    deleteFutureEpisode({
      id,
    })
      .then(() => {
        this.setState({
          loading: true,
          complete: true,
        });
      })
      .catch((resp) => {
        this.setState({
          loading: false,
          errors: resp.graphQLErrors,
        });
      });
  };

  render() {
    const {data} = this.props;
    const {complete, loading, errors} = this.state;

    if (data.loading) {
      return <Loading absolute />;
    }

    if (complete) {
      return <Redirect to='/futures' />;
    }

    const {
      id,
      title,
      subtitle,
      podcast: {feed},
      campaignEpisodes,
    } = data.admin.futureEpisode;

    return (
      <Element>
        <GraphErrors
          errors={errors}
          onClose={() => {
            this.setState({
              errors: [],
            });
          }}
        />

        <Element rules={() => ({display: 'flex'})}>
          <Element
            rules={() => ({
              flex: '0 0 75px',
              marginRight: '10px',
              position: 'relative',
            })}>
            {feed.image ? <Img image={feed.image} width={150} /> : <Dummy />}
          </Element>

          <Element rules={() => ({flex: 1})}>
            <h6>{feed.title}</h6>
            <Element
              tag='p'
              rules={() => ({
                fontSize: '13px',
                color: '#999',
              })}>
              {feed.description}
            </Element>
          </Element>
        </Element>

        <p>
          <b>Future Episode: "{title}"</b>
        </p>

        <ul>
          {campaignEpisodes.map(({id, episode, campaignPodcast}, n) => {
            const {campaign} = campaignPodcast;
            const {organization} = campaign;
            return (
              <li key={id}>
                {id} - {episode ? episode.title : 'no episode'} -{' '}
                <Link to={`/campaigns/${campaign.id}`}>{campaign.name}</Link>-{' '}
                <a
                  href={`https://dash.podsights.com/${
                    organization.kind == 'PODCAST' ? 'p' : 's'
                  }/${campaign.organization.slug}/campaigns/${
                    campaign.slug
                  }/podcasts/${campaignPodcast.id}/futures/${id}`}>
                  Future Episode Landing
                </a>
              </li>
            );
          })}
        </ul>

        {campaignEpisodes.length == 0 ? (
          <Element
            rules={() => ({
              textAlign: 'right',
            })}>
            <Button
              onClick={this.deleteFutureEpisode}
              color='error'
              disabled={loading}
              css={`
                margin-right: 10px;
              `}>
              Delete FutureEpisode
            </Button>
          </Element>
        ) : (
          ''
        )}
      </Element>
    );
  }
}

export default compose(
  graphql(FutureEpisodeQuery, {
    options: ({
      match: {
        params: {futureEpisodeId},
      },
    }) => ({
      variables: {
        id: futureEpisodeId,
      },
    }),
  }),

  graphql(DeleteFutureEpisodeMutation, {
    props: ({ownProps: {organization}, mutate}) => ({
      deleteFutureEpisode: (input) => {
        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  })
)(FutureEpisode);
