import { cssColorValue, cursorDisabled, focusBorderWidth, focusGapBorder, opacityActive, opacityDisabled, semanticColors } from "../../styles/index.js";
import { Indicator } from "./Indicator.js";
import styled, { css } from "../../styled-components.js";
var borderRadius = 11;
var wrapperWidth = '30px';
export var Wrapper = styled.span.withConfig({
  displayName: "Wrapper",
  componentId: "sc-16y5c87-0"
})(["", ""], function (props) {
  // checked means toggle is in the "on" position
  var checkedStyles = css(["background-color:", ";", "{right:2px;left:auto;[dir='rtl'] &{right:auto;left:2px;}}"], cssColorValue(props.semanticColor), Indicator);

  // unchecked means toggle is in the "off" position
  var uncheckedStyles = css(["background-color:", ";", "{left:2px;right:auto;[dir='rtl'] &{right:2px;left:auto;}}"], cssColorValue(semanticColors.essentialSubdued), Indicator);
  var focusStyles = css(["&::after{border-color:", ";}"], cssColorValue(semanticColors.essentialBase));
  var activeStyles = css(["opacity:", ";", "{inline-size:20px;}"], opacityActive, Indicator);
  var disabledStyles = css(["opacity:", ";cursor:", ";"], opacityDisabled, cursorDisabled);
  return css(["border-radius:", "px;block-size:16px;position:relative;min-inline-size:", ";", ";input:focus ~ &&{", "}", " input:active ~ &&{", "}", " input:disabled ~ &&{", "}input:not(:checked) ~ &&{", "}input:checked ~ &&{", "}"], borderRadius, wrapperWidth, focusGapBorder("".concat(borderRadius + focusBorderWidth, "px"), props.isUsingKeyboard), focusStyles, props.isFocus && css(["input ~ &&{", "}"], focusStyles), activeStyles, props.isActive && css(["input ~ &&{", "}"], activeStyles), disabledStyles, uncheckedStyles, checkedStyles);
});