/**
 * Encore Foundation - 5.23.1
 *
 * The contents of this file is owned and generated by the Encore team.
 * If you need to make changes to the values stored here please reach out
 * to #encore on Slack.
 *
 * Links:
 *
 * - Source & Documentation: https://ghe.spotify.net/encore/foundation
 *
 * Tack!
 *
 * Encore
 * encore.spotify.net
 */
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 * The Dark theme is used for our consumer products, like the Desktop client, Web Player, and the consumer mobile clients.
 */
export var darkTheme = {
    base: {
        background: {
            base: '#121212',
            highlight: '#1a1a1a',
            press: '#000000',
            elevated: { base: '#242424', highlight: '#2a2a2a', press: '#000000' },
            tinted: {
                base: 'rgba(255, 255, 255, 0.07)',
                highlight: 'rgba(255, 255, 255, 0.1)',
                press: 'rgba(255, 255, 255, 0.04)'
            },
            unsafeForSmallText: {
                base: '#121212',
                highlight: '#121212',
                press: '#121212'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#a7a7a7',
            brightAccent: '#1ed760',
            negative: '#f15e6c',
            warning: '#ffa42b',
            positive: '#1ed760',
            announcement: '#3d91f4'
        },
        essential: {
            base: '#ffffff',
            subdued: '#727272',
            brightAccent: '#1ed760',
            negative: '#e91429',
            warning: '#ffa42b',
            positive: '#1ed760',
            announcement: '#0d72ea'
        },
        decorative: { base: '#ffffff', subdued: '#292929' }
    },
    brightAccent: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    negative: {
        background: {
            base: '#e91429',
            highlight: '#de1327',
            press: '#920d1a',
            elevated: { base: '#de1327', highlight: '#de1327', press: '#920d1a' },
            tinted: { base: '#e91429', highlight: '#e91429', press: '#e91429' },
            unsafeForSmallText: {
                base: '#e91429',
                highlight: '#e91429',
                press: '#e91429'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#ee3a4c' }
    },
    warning: {
        background: {
            base: '#ffa42b',
            highlight: '#ffaf45',
            press: '#c57100',
            elevated: { base: '#ffaf45', highlight: '#ffaf45', press: '#c57100' },
            tinted: { base: '#ffa42b', highlight: '#ffa42b', press: '#ffa42b' },
            unsafeForSmallText: {
                base: '#ffa42b',
                highlight: '#ffa42b',
                press: '#ffa42b'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#f18900' }
    },
    positive: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    announcement: {
        background: {
            base: '#0d72ea',
            highlight: '#0c6ddf',
            press: '#084791',
            elevated: { base: '#0c6ddf', highlight: '#0c6ddf', press: '#084791' },
            tinted: { base: '#0d72ea', highlight: '#0d72ea', press: '#0d72ea' },
            unsafeForSmallText: {
                base: '#0d72ea',
                highlight: '#0d72ea',
                press: '#0d72ea'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#2a86f3' }
    },
    invertedDark: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            tinted: { base: '#000000', highlight: '#000000', press: '#000000' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#8f8f8f',
            brightAccent: '#1ed760',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#1ed760',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#5e5e5e',
            brightAccent: '#1ed760',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#1ed760',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    invertedLight: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#f6f6f6', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: { base: '#ffffff', highlight: '#ffffff', press: '#ffffff' },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#5e5e5e',
            brightAccent: '#12833a',
            negative: '#000000',
            warning: '#000000',
            positive: '#12833a',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#8f8f8f',
            brightAccent: '#17a449',
            negative: '#000000',
            warning: '#000000',
            positive: '#17a449',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    mutedAccent: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            tinted: {
                base: 'rgba(255, 255, 255, 0.1)',
                highlight: 'rgba(255, 255, 255, 0.13)',
                press: 'rgba(255, 255, 255, 0.06)'
            },
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#9e9e9e',
            brightAccent: '#1ed760',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#1ed760',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#6a6a6a',
            brightAccent: '#1ed760',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#1ed760',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    overMedia: {
        background: {
            base: 'rgba(0, 0, 0, 0.54)',
            highlight: 'rgba(0, 0, 0, 0.56)',
            press: 'rgba(0, 0, 0, 0.72)',
            unsafeForSmallText: {
                base: 'rgba(0, 0, 0, 0.42)',
                highlight: 'rgba(0, 0, 0, 0.44)',
                press: 'rgba(0, 0, 0, 0.61)'
            },
            elevated: {
                base: 'rgba(0, 0, 0, 0.56)',
                highlight: 'rgba(0, 0, 0, 0.56)',
                press: 'rgba(0, 0, 0, 0.72)'
            },
            tinted: {
                base: 'rgba(0, 0, 0, 0.54)',
                highlight: 'rgba(0, 0, 0, 0.54)',
                press: 'rgba(0, 0, 0, 0.54)'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: 'rgba(255, 255, 255, 0.13)' }
    }
};
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 * The Light theme is used for marketing contexts, like spotify.com, B2B products like Ad Studio, internal tools and generally when data density is high and text legibility is important.
 */
export var lightTheme = {
    base: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#ffffff', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: {
                base: 'rgba(0, 0, 0, 0.08)',
                highlight: 'rgba(0, 0, 0, 0.05)',
                press: 'rgba(0, 0, 0, 0.12)'
            },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#6a6a6a',
            brightAccent: '#117a37',
            negative: '#d31225',
            warning: '#9d5a00',
            positive: '#117a37',
            announcement: '#0c67d3'
        },
        essential: {
            base: '#000000',
            subdued: '#878787',
            brightAccent: '#169b45',
            negative: '#e91429',
            warning: '#c77200',
            positive: '#169b45',
            announcement: '#0d72ea'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    brightAccent: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    negative: {
        background: {
            base: '#e91429',
            highlight: '#de1327',
            press: '#920d1a',
            elevated: { base: '#de1327', highlight: '#de1327', press: '#920d1a' },
            tinted: { base: '#e91429', highlight: '#e91429', press: '#e91429' },
            unsafeForSmallText: {
                base: '#e91429',
                highlight: '#e91429',
                press: '#e91429'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#ee3a4c' }
    },
    warning: {
        background: {
            base: '#ffa42b',
            highlight: '#ffaf45',
            press: '#c57100',
            elevated: { base: '#ffaf45', highlight: '#ffaf45', press: '#c57100' },
            tinted: { base: '#ffa42b', highlight: '#ffa42b', press: '#ffa42b' },
            unsafeForSmallText: {
                base: '#ffa42b',
                highlight: '#ffa42b',
                press: '#ffa42b'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#f18900' }
    },
    positive: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    announcement: {
        background: {
            base: '#0d72ea',
            highlight: '#0c6ddf',
            press: '#084791',
            elevated: { base: '#0c6ddf', highlight: '#0c6ddf', press: '#084791' },
            tinted: { base: '#0d72ea', highlight: '#0d72ea', press: '#0d72ea' },
            unsafeForSmallText: {
                base: '#0d72ea',
                highlight: '#0d72ea',
                press: '#0d72ea'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#2a86f3' }
    },
    invertedDark: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            tinted: { base: '#000000', highlight: '#000000', press: '#000000' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#8f8f8f',
            brightAccent: '#1ed760',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#1ed760',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#5e5e5e',
            brightAccent: '#1ed760',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#1ed760',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    invertedLight: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#f6f6f6', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: { base: '#ffffff', highlight: '#ffffff', press: '#ffffff' },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#5e5e5e',
            brightAccent: '#12833a',
            negative: '#000000',
            warning: '#000000',
            positive: '#12833a',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#8f8f8f',
            brightAccent: '#17a449',
            negative: '#000000',
            warning: '#000000',
            positive: '#17a449',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    mutedAccent: {
        background: {
            base: '#121212',
            highlight: '#1a1a1a',
            press: '#000000',
            tinted: {
                base: 'rgba(255, 255, 255, 0.07)',
                highlight: 'rgba(255, 255, 255, 0.1)',
                press: 'rgba(255, 255, 255, 0.04)'
            },
            elevated: { base: '#1a1a1a', highlight: '#1a1a1a', press: '#000000' },
            unsafeForSmallText: {
                base: '#121212',
                highlight: '#121212',
                press: '#121212'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#a7a7a7',
            brightAccent: '#1ed760',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#1ed760',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#727272',
            brightAccent: '#1ed760',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#1ed760',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#292929' }
    },
    overMedia: {
        background: {
            base: 'rgba(0, 0, 0, 0.54)',
            highlight: 'rgba(0, 0, 0, 0.56)',
            press: 'rgba(0, 0, 0, 0.72)',
            unsafeForSmallText: {
                base: 'rgba(0, 0, 0, 0.42)',
                highlight: 'rgba(0, 0, 0, 0.44)',
                press: 'rgba(0, 0, 0, 0.61)'
            },
            elevated: {
                base: 'rgba(0, 0, 0, 0.56)',
                highlight: 'rgba(0, 0, 0, 0.56)',
                press: 'rgba(0, 0, 0, 0.72)'
            },
            tinted: {
                base: 'rgba(0, 0, 0, 0.54)',
                highlight: 'rgba(0, 0, 0, 0.54)',
                press: 'rgba(0, 0, 0, 0.54)'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: 'rgba(255, 255, 255, 0.13)' }
    }
};
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 * The Creator Dark theme is used for creator products, when building flows or illustrating views from the consumer app, like profile editing or canvas submission in S4A.
 */
export var creatorDarkTheme = {
    base: {
        background: {
            base: '#121212',
            highlight: '#1a1a1a',
            press: '#000000',
            elevated: { base: '#242424', highlight: '#2a2a2a', press: '#000000' },
            tinted: {
                base: 'rgba(255, 255, 255, 0.07)',
                highlight: 'rgba(255, 255, 255, 0.1)',
                press: 'rgba(255, 255, 255, 0.04)'
            },
            unsafeForSmallText: {
                base: '#121212',
                highlight: '#121212',
                press: '#121212'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#a7a7a7',
            brightAccent: '#9979ff',
            negative: '#f15e6c',
            warning: '#ffa42b',
            positive: '#1ed760',
            announcement: '#3d91f4'
        },
        essential: {
            base: '#ffffff',
            subdued: '#727272',
            brightAccent: '#794fff',
            negative: '#e91429',
            warning: '#ffa42b',
            positive: '#1ed760',
            announcement: '#0d72ea'
        },
        decorative: { base: '#ffffff', subdued: '#292929' }
    },
    brightAccent: {
        background: {
            base: '#5925ff',
            highlight: '#4e17ff',
            press: '#25009d',
            elevated: { base: '#4e17ff', highlight: '#4e17ff', press: '#25009d' },
            tinted: { base: '#5925ff', highlight: '#5925ff', press: '#5925ff' },
            unsafeForSmallText: {
                base: '#5925ff',
                highlight: '#5925ff',
                press: '#5925ff'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#7347ff' }
    },
    negative: {
        background: {
            base: '#e91429',
            highlight: '#de1327',
            press: '#920d1a',
            elevated: { base: '#de1327', highlight: '#de1327', press: '#920d1a' },
            tinted: { base: '#e91429', highlight: '#e91429', press: '#e91429' },
            unsafeForSmallText: {
                base: '#e91429',
                highlight: '#e91429',
                press: '#e91429'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#ee3a4c' }
    },
    warning: {
        background: {
            base: '#ffa42b',
            highlight: '#ffaf45',
            press: '#c57100',
            elevated: { base: '#ffaf45', highlight: '#ffaf45', press: '#c57100' },
            tinted: { base: '#ffa42b', highlight: '#ffa42b', press: '#ffa42b' },
            unsafeForSmallText: {
                base: '#ffa42b',
                highlight: '#ffa42b',
                press: '#ffa42b'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#f18900' }
    },
    positive: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    announcement: {
        background: {
            base: '#0d72ea',
            highlight: '#0c6ddf',
            press: '#084791',
            elevated: { base: '#0c6ddf', highlight: '#0c6ddf', press: '#084791' },
            tinted: { base: '#0d72ea', highlight: '#0d72ea', press: '#0d72ea' },
            unsafeForSmallText: {
                base: '#0d72ea',
                highlight: '#0d72ea',
                press: '#0d72ea'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#2a86f3' }
    },
    invertedDark: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            tinted: { base: '#000000', highlight: '#000000', press: '#000000' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#8f8f8f',
            brightAccent: '#825aff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#825aff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#5e5e5e',
            brightAccent: '#5d2aff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#5d2aff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    invertedLight: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#f6f6f6', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: { base: '#ffffff', highlight: '#ffffff', press: '#ffffff' },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#5e5e5e',
            brightAccent: '#5925ff',
            negative: '#000000',
            warning: '#000000',
            positive: '#5925ff',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#8f8f8f',
            brightAccent: '#5925ff',
            negative: '#000000',
            warning: '#000000',
            positive: '#5925ff',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    mutedAccent: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            tinted: {
                base: 'rgba(255, 255, 255, 0.1)',
                highlight: 'rgba(255, 255, 255, 0.13)',
                press: 'rgba(255, 255, 255, 0.06)'
            },
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#9e9e9e',
            brightAccent: '#906eff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#906eff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#6a6a6a',
            brightAccent: '#6f42ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#6f42ff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    overMedia: {
        background: {
            base: 'rgba(0, 0, 0, 0.54)',
            highlight: 'rgba(0, 0, 0, 0.56)',
            press: 'rgba(0, 0, 0, 0.72)',
            unsafeForSmallText: {
                base: 'rgba(0, 0, 0, 0.42)',
                highlight: 'rgba(0, 0, 0, 0.44)',
                press: 'rgba(0, 0, 0, 0.61)'
            },
            elevated: {
                base: 'rgba(0, 0, 0, 0.56)',
                highlight: 'rgba(0, 0, 0, 0.56)',
                press: 'rgba(0, 0, 0, 0.72)'
            },
            tinted: {
                base: 'rgba(0, 0, 0, 0.54)',
                highlight: 'rgba(0, 0, 0, 0.54)',
                press: 'rgba(0, 0, 0, 0.54)'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: 'rgba(255, 255, 255, 0.13)' }
    }
};
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 * The Creator Light theme is used for creator products, like Spotify for Artists, and Spotify for Publishers.
 */
export var creatorLightTheme = {
    base: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#ffffff', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: {
                base: 'rgba(0, 0, 0, 0.08)',
                highlight: 'rgba(0, 0, 0, 0.05)',
                press: 'rgba(0, 0, 0, 0.12)'
            },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#6a6a6a',
            brightAccent: '#4100f4',
            negative: '#d31225',
            warning: '#9d5a00',
            positive: '#117a37',
            announcement: '#0c67d3'
        },
        essential: {
            base: '#000000',
            subdued: '#878787',
            brightAccent: '#4100f4',
            negative: '#e91429',
            warning: '#c77200',
            positive: '#169b45',
            announcement: '#0d72ea'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    brightAccent: {
        background: {
            base: '#4100f4',
            highlight: '#3d00e6',
            press: '#1d006c',
            elevated: { base: '#3d00e6', highlight: '#3d00e6', press: '#1d006c' },
            tinted: { base: '#4100f4', highlight: '#4100f4', press: '#4100f4' },
            unsafeForSmallText: {
                base: '#4100f4',
                highlight: '#4100f4',
                press: '#4100f4'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#662fff' }
    },
    negative: {
        background: {
            base: '#e91429',
            highlight: '#de1327',
            press: '#920d1a',
            elevated: { base: '#de1327', highlight: '#de1327', press: '#920d1a' },
            tinted: { base: '#e91429', highlight: '#e91429', press: '#e91429' },
            unsafeForSmallText: {
                base: '#e91429',
                highlight: '#e91429',
                press: '#e91429'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#ee3a4c' }
    },
    warning: {
        background: {
            base: '#ffa42b',
            highlight: '#ffaf45',
            press: '#c57100',
            elevated: { base: '#ffaf45', highlight: '#ffaf45', press: '#c57100' },
            tinted: { base: '#ffa42b', highlight: '#ffa42b', press: '#ffa42b' },
            unsafeForSmallText: {
                base: '#ffa42b',
                highlight: '#ffa42b',
                press: '#ffa42b'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#f18900' }
    },
    positive: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    announcement: {
        background: {
            base: '#0d72ea',
            highlight: '#0c6ddf',
            press: '#084791',
            elevated: { base: '#0c6ddf', highlight: '#0c6ddf', press: '#084791' },
            tinted: { base: '#0d72ea', highlight: '#0d72ea', press: '#0d72ea' },
            unsafeForSmallText: {
                base: '#0d72ea',
                highlight: '#0d72ea',
                press: '#0d72ea'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#2a86f3' }
    },
    invertedDark: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            tinted: { base: '#000000', highlight: '#000000', press: '#000000' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#8f8f8f',
            brightAccent: '#8559ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#8559ff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#5e5e5e',
            brightAccent: '#6127ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#6127ff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    invertedLight: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#f6f6f6', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: { base: '#ffffff', highlight: '#ffffff', press: '#ffffff' },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#5e5e5e',
            brightAccent: '#4100f4',
            negative: '#000000',
            warning: '#000000',
            positive: '#4100f4',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#8f8f8f',
            brightAccent: '#4100f4',
            negative: '#000000',
            warning: '#000000',
            positive: '#4100f4',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    mutedAccent: {
        background: {
            base: '#121212',
            highlight: '#1a1a1a',
            press: '#000000',
            tinted: {
                base: 'rgba(255, 255, 255, 0.07)',
                highlight: 'rgba(255, 255, 255, 0.1)',
                press: 'rgba(255, 255, 255, 0.04)'
            },
            elevated: { base: '#1a1a1a', highlight: '#1a1a1a', press: '#000000' },
            unsafeForSmallText: {
                base: '#121212',
                highlight: '#121212',
                press: '#121212'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#a7a7a7',
            brightAccent: '#9c78ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9c78ff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#727272',
            brightAccent: '#7d4dff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#7d4dff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#292929' }
    },
    overMedia: {
        background: {
            base: 'rgba(0, 0, 0, 0.54)',
            highlight: 'rgba(0, 0, 0, 0.56)',
            press: 'rgba(0, 0, 0, 0.72)',
            unsafeForSmallText: {
                base: 'rgba(0, 0, 0, 0.42)',
                highlight: 'rgba(0, 0, 0, 0.44)',
                press: 'rgba(0, 0, 0, 0.61)'
            },
            elevated: {
                base: 'rgba(0, 0, 0, 0.56)',
                highlight: 'rgba(0, 0, 0, 0.56)',
                press: 'rgba(0, 0, 0, 0.72)'
            },
            tinted: {
                base: 'rgba(0, 0, 0, 0.54)',
                highlight: 'rgba(0, 0, 0, 0.54)',
                press: 'rgba(0, 0, 0, 0.54)'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: 'rgba(255, 255, 255, 0.13)' }
    }
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated
 */
export var overtimeDarkTheme = {
    base: {
        background: {
            base: '#121212',
            highlight: '#1a1a1a',
            press: '#000000',
            elevated: { base: '#242424', highlight: '#2a2a2a', press: '#000000' },
            tinted: {
                base: 'rgba(255, 255, 255, 0.07)',
                highlight: 'rgba(255, 255, 255, 0.1)',
                press: 'rgba(255, 255, 255, 0.04)'
            },
            unsafeForSmallText: {
                base: '#121212',
                highlight: '#121212',
                press: '#121212'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#a7a7a7',
            brightAccent: '#19e68c',
            negative: '#f15e6c',
            warning: '#ffa42b',
            positive: '#1ed760',
            announcement: '#3d91f4'
        },
        essential: {
            base: '#ffffff',
            subdued: '#727272',
            brightAccent: '#19e68c',
            negative: '#e91429',
            warning: '#ffa42b',
            positive: '#1ed760',
            announcement: '#0d72ea'
        },
        decorative: { base: '#ffffff', subdued: '#292929' }
    },
    brightAccent: {
        background: {
            base: '#19e68c',
            highlight: '#4beba5',
            press: '#12a967',
            elevated: { base: '#4beba5', highlight: '#4beba5', press: '#12a967' },
            tinted: { base: '#19e68c', highlight: '#19e68c', press: '#19e68c' },
            unsafeForSmallText: {
                base: '#19e68c',
                highlight: '#19e68c',
                press: '#19e68c'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#16c97a' }
    },
    negative: {
        background: {
            base: '#e91429',
            highlight: '#de1327',
            press: '#920d1a',
            elevated: { base: '#de1327', highlight: '#de1327', press: '#920d1a' },
            tinted: { base: '#e91429', highlight: '#e91429', press: '#e91429' },
            unsafeForSmallText: {
                base: '#e91429',
                highlight: '#e91429',
                press: '#e91429'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#ee3a4c' }
    },
    warning: {
        background: {
            base: '#ffa42b',
            highlight: '#ffaf45',
            press: '#c57100',
            elevated: { base: '#ffaf45', highlight: '#ffaf45', press: '#c57100' },
            tinted: { base: '#ffa42b', highlight: '#ffa42b', press: '#ffa42b' },
            unsafeForSmallText: {
                base: '#ffa42b',
                highlight: '#ffa42b',
                press: '#ffa42b'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#f18900' }
    },
    positive: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    announcement: {
        background: {
            base: '#0d72ea',
            highlight: '#0c6ddf',
            press: '#084791',
            elevated: { base: '#0c6ddf', highlight: '#0c6ddf', press: '#084791' },
            tinted: { base: '#0d72ea', highlight: '#0d72ea', press: '#0d72ea' },
            unsafeForSmallText: {
                base: '#0d72ea',
                highlight: '#0d72ea',
                press: '#0d72ea'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#2a86f3' }
    },
    invertedDark: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            tinted: { base: '#000000', highlight: '#000000', press: '#000000' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#8f8f8f',
            brightAccent: '#19e68c',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#19e68c',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#5e5e5e',
            brightAccent: '#19e68c',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#19e68c',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    invertedLight: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#f6f6f6', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: { base: '#ffffff', highlight: '#ffffff', press: '#ffffff' },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#5e5e5e',
            brightAccent: '#0e824f',
            negative: '#000000',
            warning: '#000000',
            positive: '#0e824f',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#8f8f8f',
            brightAccent: '#12a363',
            negative: '#000000',
            warning: '#000000',
            positive: '#12a363',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    mutedAccent: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            tinted: {
                base: 'rgba(255, 255, 255, 0.1)',
                highlight: 'rgba(255, 255, 255, 0.13)',
                press: 'rgba(255, 255, 255, 0.06)'
            },
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#9e9e9e',
            brightAccent: '#19e68c',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#19e68c',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#6a6a6a',
            brightAccent: '#19e68c',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#19e68c',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    overMedia: {
        background: {
            base: 'rgba(0, 0, 0, 0.54)',
            highlight: 'rgba(0, 0, 0, 0.56)',
            press: 'rgba(0, 0, 0, 0.72)',
            unsafeForSmallText: {
                base: 'rgba(0, 0, 0, 0.42)',
                highlight: 'rgba(0, 0, 0, 0.44)',
                press: 'rgba(0, 0, 0, 0.61)'
            },
            elevated: {
                base: 'rgba(0, 0, 0, 0.56)',
                highlight: 'rgba(0, 0, 0, 0.56)',
                press: 'rgba(0, 0, 0, 0.72)'
            },
            tinted: {
                base: 'rgba(0, 0, 0, 0.54)',
                highlight: 'rgba(0, 0, 0, 0.54)',
                press: 'rgba(0, 0, 0, 0.54)'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: 'rgba(255, 255, 255, 0.13)' }
    }
};
/**
 *
 * ![Status: experimental](https://img.shields.io/badge/EXPERIMENTAL-yellow|height=14)
 * The Advertising Light theme is used for advertising products, like Ad Studio, Ad Studio Admin, and the ads.spotify.com marketing page.
 */
export var advertisingLightTheme = {
    base: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#ffffff', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: {
                base: 'rgba(0, 0, 0, 0.08)',
                highlight: 'rgba(0, 0, 0, 0.05)',
                press: 'rgba(0, 0, 0, 0.12)'
            },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#6a6a6a',
            brightAccent: '#734d85',
            negative: '#d31225',
            warning: '#9d5a00',
            positive: '#117a37',
            announcement: '#0c67d3'
        },
        essential: {
            base: '#000000',
            subdued: '#878787',
            brightAccent: '#734d85',
            negative: '#e91429',
            warning: '#c77200',
            positive: '#169b45',
            announcement: '#0d72ea'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    brightAccent: {
        background: {
            base: '#734d85',
            highlight: '#6c497d',
            press: '#3b2744',
            elevated: { base: '#6c497d', highlight: '#6c497d', press: '#3b2744' },
            tinted: { base: '#734d85', highlight: '#734d85', press: '#734d85' },
            unsafeForSmallText: {
                base: '#734d85',
                highlight: '#734d85',
                press: '#734d85'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#895c9f' }
    },
    negative: {
        background: {
            base: '#e91429',
            highlight: '#de1327',
            press: '#920d1a',
            elevated: { base: '#de1327', highlight: '#de1327', press: '#920d1a' },
            tinted: { base: '#e91429', highlight: '#e91429', press: '#e91429' },
            unsafeForSmallText: {
                base: '#e91429',
                highlight: '#e91429',
                press: '#e91429'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#ee3a4c' }
    },
    warning: {
        background: {
            base: '#ffa42b',
            highlight: '#ffaf45',
            press: '#c57100',
            elevated: { base: '#ffaf45', highlight: '#ffaf45', press: '#c57100' },
            tinted: { base: '#ffa42b', highlight: '#ffa42b', press: '#ffa42b' },
            unsafeForSmallText: {
                base: '#ffa42b',
                highlight: '#ffa42b',
                press: '#ffa42b'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#f18900' }
    },
    positive: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    announcement: {
        background: {
            base: '#0d72ea',
            highlight: '#0c6ddf',
            press: '#084791',
            elevated: { base: '#0c6ddf', highlight: '#0c6ddf', press: '#084791' },
            tinted: { base: '#0d72ea', highlight: '#0d72ea', press: '#0d72ea' },
            unsafeForSmallText: {
                base: '#0d72ea',
                highlight: '#0d72ea',
                press: '#0d72ea'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#2a86f3' }
    },
    invertedDark: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            tinted: { base: '#000000', highlight: '#000000', press: '#000000' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#8f8f8f',
            brightAccent: '#9469a8',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9469a8',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#5e5e5e',
            brightAccent: '#764f88',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#764f88',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    invertedLight: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#f6f6f6', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: { base: '#ffffff', highlight: '#ffffff', press: '#ffffff' },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#5e5e5e',
            brightAccent: '#734d85',
            negative: '#000000',
            warning: '#000000',
            positive: '#734d85',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#8f8f8f',
            brightAccent: '#734d85',
            negative: '#000000',
            warning: '#000000',
            positive: '#734d85',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    mutedAccent: {
        background: {
            base: '#121212',
            highlight: '#1a1a1a',
            press: '#000000',
            tinted: {
                base: 'rgba(255, 255, 255, 0.07)',
                highlight: 'rgba(255, 255, 255, 0.1)',
                press: 'rgba(255, 255, 255, 0.04)'
            },
            elevated: { base: '#1a1a1a', highlight: '#1a1a1a', press: '#000000' },
            unsafeForSmallText: {
                base: '#121212',
                highlight: '#121212',
                press: '#121212'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#a7a7a7',
            brightAccent: '#a683b7',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#a683b7',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#727272',
            brightAccent: '#8e61a3',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#8e61a3',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#292929' }
    },
    overMedia: {
        background: {
            base: 'rgba(0, 0, 0, 0.54)',
            highlight: 'rgba(0, 0, 0, 0.56)',
            press: 'rgba(0, 0, 0, 0.72)',
            unsafeForSmallText: {
                base: 'rgba(0, 0, 0, 0.42)',
                highlight: 'rgba(0, 0, 0, 0.44)',
                press: 'rgba(0, 0, 0, 0.61)'
            },
            elevated: {
                base: 'rgba(0, 0, 0, 0.56)',
                highlight: 'rgba(0, 0, 0, 0.56)',
                press: 'rgba(0, 0, 0, 0.72)'
            },
            tinted: {
                base: 'rgba(0, 0, 0, 0.54)',
                highlight: 'rgba(0, 0, 0, 0.54)',
                press: 'rgba(0, 0, 0, 0.54)'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: 'rgba(255, 255, 255, 0.13)' }
    }
};
/**
 *
 * ![Status: experimental](https://img.shields.io/badge/EXPERIMENTAL-yellow|height=14)
 * The Advertising Dark theme is used for advertising products, like Ad Studio, Ad Studio Admin, and the ads.spotify.com marketing page.
 */
export var advertisingDarkTheme = {
    base: {
        background: {
            base: '#121212',
            highlight: '#1a1a1a',
            press: '#000000',
            elevated: { base: '#242424', highlight: '#2a2a2a', press: '#000000' },
            tinted: {
                base: 'rgba(255, 255, 255, 0.07)',
                highlight: 'rgba(255, 255, 255, 0.1)',
                press: 'rgba(255, 255, 255, 0.04)'
            },
            unsafeForSmallText: {
                base: '#121212',
                highlight: '#121212',
                press: '#121212'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#a7a7a7',
            brightAccent: '#a683b7',
            negative: '#f15e6c',
            warning: '#ffa42b',
            positive: '#1ed760',
            announcement: '#3d91f4'
        },
        essential: {
            base: '#ffffff',
            subdued: '#727272',
            brightAccent: '#8e61a3',
            negative: '#e91429',
            warning: '#ffa42b',
            positive: '#1ed760',
            announcement: '#0d72ea'
        },
        decorative: { base: '#ffffff', subdued: '#292929' }
    },
    brightAccent: {
        background: {
            base: '#734d85',
            highlight: '#6c497d',
            press: '#3b2744',
            elevated: { base: '#6c497d', highlight: '#6c497d', press: '#3b2744' },
            tinted: { base: '#734d85', highlight: '#734d85', press: '#734d85' },
            unsafeForSmallText: {
                base: '#734d85',
                highlight: '#734d85',
                press: '#734d85'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#895c9f' }
    },
    negative: {
        background: {
            base: '#e91429',
            highlight: '#de1327',
            press: '#920d1a',
            elevated: { base: '#de1327', highlight: '#de1327', press: '#920d1a' },
            tinted: { base: '#e91429', highlight: '#e91429', press: '#e91429' },
            unsafeForSmallText: {
                base: '#e91429',
                highlight: '#e91429',
                press: '#e91429'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#ee3a4c' }
    },
    warning: {
        background: {
            base: '#ffa42b',
            highlight: '#ffaf45',
            press: '#c57100',
            elevated: { base: '#ffaf45', highlight: '#ffaf45', press: '#c57100' },
            tinted: { base: '#ffa42b', highlight: '#ffa42b', press: '#ffa42b' },
            unsafeForSmallText: {
                base: '#ffa42b',
                highlight: '#ffa42b',
                press: '#ffa42b'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#f18900' }
    },
    positive: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    announcement: {
        background: {
            base: '#0d72ea',
            highlight: '#0c6ddf',
            press: '#084791',
            elevated: { base: '#0c6ddf', highlight: '#0c6ddf', press: '#084791' },
            tinted: { base: '#0d72ea', highlight: '#0d72ea', press: '#0d72ea' },
            unsafeForSmallText: {
                base: '#0d72ea',
                highlight: '#0d72ea',
                press: '#0d72ea'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#2a86f3' }
    },
    invertedDark: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            tinted: { base: '#000000', highlight: '#000000', press: '#000000' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#8f8f8f',
            brightAccent: '#9469a8',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9469a8',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#5e5e5e',
            brightAccent: '#764f88',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#764f88',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    invertedLight: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#f6f6f6', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: { base: '#ffffff', highlight: '#ffffff', press: '#ffffff' },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#5e5e5e',
            brightAccent: '#734d85',
            negative: '#000000',
            warning: '#000000',
            positive: '#734d85',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#8f8f8f',
            brightAccent: '#734d85',
            negative: '#000000',
            warning: '#000000',
            positive: '#734d85',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    mutedAccent: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            tinted: {
                base: 'rgba(255, 255, 255, 0.1)',
                highlight: 'rgba(255, 255, 255, 0.13)',
                press: 'rgba(255, 255, 255, 0.06)'
            },
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#9e9e9e',
            brightAccent: '#9f79b2',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9f79b2',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#6a6a6a',
            brightAccent: '#85599a',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#85599a',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    overMedia: {
        background: {
            base: 'rgba(0, 0, 0, 0.54)',
            highlight: 'rgba(0, 0, 0, 0.56)',
            press: 'rgba(0, 0, 0, 0.72)',
            unsafeForSmallText: {
                base: 'rgba(0, 0, 0, 0.42)',
                highlight: 'rgba(0, 0, 0, 0.44)',
                press: 'rgba(0, 0, 0, 0.61)'
            },
            elevated: {
                base: 'rgba(0, 0, 0, 0.56)',
                highlight: 'rgba(0, 0, 0, 0.56)',
                press: 'rgba(0, 0, 0, 0.72)'
            },
            tinted: {
                base: 'rgba(0, 0, 0, 0.54)',
                highlight: 'rgba(0, 0, 0, 0.54)',
                press: 'rgba(0, 0, 0, 0.54)'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: 'rgba(255, 255, 255, 0.13)' }
    }
};
/**
 *
 * ![Status: production](https://img.shields.io/badge/PRODUCTION-green|height=14)
 * The Quicksilver Light theme is used for Quicksilver, an internal Spotify product.
 */
export var quicksilverLightTheme = {
    base: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#ffffff', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: {
                base: 'rgba(0, 0, 0, 0.08)',
                highlight: 'rgba(0, 0, 0, 0.05)',
                press: 'rgba(0, 0, 0, 0.12)'
            },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#6a6a6a',
            brightAccent: '#543edd',
            negative: '#d31225',
            warning: '#9d5a00',
            positive: '#117a37',
            announcement: '#0c67d3'
        },
        essential: {
            base: '#000000',
            subdued: '#878787',
            brightAccent: '#543edd',
            negative: '#e91429',
            warning: '#c77200',
            positive: '#169b45',
            announcement: '#0d72ea'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    brightAccent: {
        background: {
            base: '#543edd',
            highlight: '#4d36dc',
            press: '#271885',
            elevated: { base: '#4d36dc', highlight: '#4d36dc', press: '#271885' },
            tinted: { base: '#543edd', highlight: '#543edd', press: '#543edd' },
            unsafeForSmallText: {
                base: '#543edd',
                highlight: '#543edd',
                press: '#543edd'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#6a57e1' }
    },
    negative: {
        background: {
            base: '#e91429',
            highlight: '#de1327',
            press: '#920d1a',
            elevated: { base: '#de1327', highlight: '#de1327', press: '#920d1a' },
            tinted: { base: '#e91429', highlight: '#e91429', press: '#e91429' },
            unsafeForSmallText: {
                base: '#e91429',
                highlight: '#e91429',
                press: '#e91429'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#ee3a4c' }
    },
    warning: {
        background: {
            base: '#ffa42b',
            highlight: '#ffaf45',
            press: '#c57100',
            elevated: { base: '#ffaf45', highlight: '#ffaf45', press: '#c57100' },
            tinted: { base: '#ffa42b', highlight: '#ffa42b', press: '#ffa42b' },
            unsafeForSmallText: {
                base: '#ffa42b',
                highlight: '#ffa42b',
                press: '#ffa42b'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#f18900' }
    },
    positive: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    announcement: {
        background: {
            base: '#0d72ea',
            highlight: '#0c6ddf',
            press: '#084791',
            elevated: { base: '#0c6ddf', highlight: '#0c6ddf', press: '#084791' },
            tinted: { base: '#0d72ea', highlight: '#0d72ea', press: '#0d72ea' },
            unsafeForSmallText: {
                base: '#0d72ea',
                highlight: '#0d72ea',
                press: '#0d72ea'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#2a86f3' }
    },
    invertedDark: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            tinted: { base: '#000000', highlight: '#000000', press: '#000000' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#8f8f8f',
            brightAccent: '#7867e4',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#7867e4',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#5e5e5e',
            brightAccent: '#5842de',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#5842de',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    invertedLight: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#f6f6f6', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: { base: '#ffffff', highlight: '#ffffff', press: '#ffffff' },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#5e5e5e',
            brightAccent: '#543edd',
            negative: '#000000',
            warning: '#000000',
            positive: '#543edd',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#8f8f8f',
            brightAccent: '#543edd',
            negative: '#000000',
            warning: '#000000',
            positive: '#543edd',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    mutedAccent: {
        background: {
            base: '#121212',
            highlight: '#1a1a1a',
            press: '#000000',
            tinted: {
                base: 'rgba(255, 255, 255, 0.07)',
                highlight: 'rgba(255, 255, 255, 0.1)',
                press: 'rgba(255, 255, 255, 0.04)'
            },
            elevated: { base: '#1a1a1a', highlight: '#1a1a1a', press: '#000000' },
            unsafeForSmallText: {
                base: '#121212',
                highlight: '#121212',
                press: '#121212'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#a7a7a7',
            brightAccent: '#9082e9',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9082e9',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#727272',
            brightAccent: '#705de3',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#705de3',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#292929' }
    },
    overMedia: {
        background: {
            base: 'rgba(0, 0, 0, 0.54)',
            highlight: 'rgba(0, 0, 0, 0.56)',
            press: 'rgba(0, 0, 0, 0.72)',
            unsafeForSmallText: {
                base: 'rgba(0, 0, 0, 0.42)',
                highlight: 'rgba(0, 0, 0, 0.44)',
                press: 'rgba(0, 0, 0, 0.61)'
            },
            elevated: {
                base: 'rgba(0, 0, 0, 0.56)',
                highlight: 'rgba(0, 0, 0, 0.56)',
                press: 'rgba(0, 0, 0, 0.72)'
            },
            tinted: {
                base: 'rgba(0, 0, 0, 0.54)',
                highlight: 'rgba(0, 0, 0, 0.54)',
                press: 'rgba(0, 0, 0, 0.54)'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: 'rgba(255, 255, 255, 0.13)' }
    }
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color theme is deprecated, consider moving to studioLight
 */
export var anchorLightTheme = {
    base: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#dadada',
            elevated: { base: '#ffffff', highlight: '#f6f6f6', press: '#dadada' },
            tinted: {
                base: 'rgba(0, 0, 0, 0.08)',
                highlight: 'rgba(0, 0, 0, 0.05)',
                press: 'rgba(0, 0, 0, 0.12)'
            },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#2a2837',
            subdued: '#6b668c',
            brightAccent: '#5000b9',
            negative: '#d31225',
            warning: '#9d5a00',
            positive: '#117a37',
            announcement: '#7f43e9'
        },
        essential: {
            base: '#2a2837',
            subdued: '#8783a5',
            brightAccent: '#5000b9',
            negative: '#e91429',
            warning: '#c77200',
            positive: '#169b45',
            announcement: '#874eea'
        },
        decorative: { base: '#2a2837', subdued: '#dedede' }
    },
    brightAccent: {
        background: {
            base: '#5000b9',
            highlight: '#4a00ac',
            press: '#080014',
            elevated: { base: '#4a00ac', highlight: '#4a00ac', press: '#080014' },
            tinted: { base: '#5000b9', highlight: '#5000b9', press: '#5000b9' },
            unsafeForSmallText: {
                base: '#5000b9',
                highlight: '#5000b9',
                press: '#5000b9'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#6500e9' }
    },
    negative: {
        background: {
            base: '#e91429',
            highlight: '#de1327',
            press: '#920d1a',
            elevated: { base: '#de1327', highlight: '#de1327', press: '#920d1a' },
            tinted: { base: '#e91429', highlight: '#e91429', press: '#e91429' },
            unsafeForSmallText: {
                base: '#e91429',
                highlight: '#e91429',
                press: '#e91429'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#ee3a4c' }
    },
    warning: {
        background: {
            base: '#ffa42b',
            highlight: '#ffaf45',
            press: '#c57100',
            elevated: { base: '#ffaf45', highlight: '#ffaf45', press: '#c57100' },
            tinted: { base: '#ffa42b', highlight: '#ffa42b', press: '#ffa42b' },
            unsafeForSmallText: {
                base: '#ffa42b',
                highlight: '#ffa42b',
                press: '#ffa42b'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#f18900' }
    },
    positive: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    announcement: {
        background: {
            base: '#874eea',
            highlight: '#8146e9',
            press: '#5116b8',
            elevated: { base: '#8146e9', highlight: '#8146e9', press: '#5116b8' },
            tinted: { base: '#874eea', highlight: '#874eea', press: '#874eea' },
            unsafeForSmallText: {
                base: '#874eea',
                highlight: '#874eea',
                press: '#874eea'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#9766ed' }
    },
    invertedDark: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            tinted: { base: '#000000', highlight: '#000000', press: '#000000' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#8f8f8f',
            brightAccent: '#9a4dff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9a4dff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#5e5e5e',
            brightAccent: '#750bff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#750bff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    invertedLight: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#f6f6f6', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: { base: '#ffffff', highlight: '#ffffff', press: '#ffffff' },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#5e5e5e',
            brightAccent: '#5000b9',
            negative: '#000000',
            warning: '#000000',
            positive: '#5000b9',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#8f8f8f',
            brightAccent: '#5000b9',
            negative: '#000000',
            warning: '#000000',
            positive: '#5000b9',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    mutedAccent: {
        background: {
            base: '#24203f',
            highlight: '#2a254a',
            press: '#000000',
            elevated: { base: '#312c56', highlight: '#373160', press: '#000000' },
            tinted: {
                base: 'rgba(255, 255, 255, 0.08)',
                highlight: 'rgba(255, 255, 255, 0.11)',
                press: 'rgba(255, 255, 255, 0.04)'
            },
            unsafeForSmallText: {
                base: '#24203f',
                highlight: '#24203f',
                press: '#24203f'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#b9b4d9',
            brightAccent: '#e8f524',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#e8f524',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#8077ba',
            brightAccent: '#e8f524',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#e8f524',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#36305e' }
    },
    overMedia: {
        background: {
            base: 'rgba(0, 0, 0, 0.54)',
            highlight: 'rgba(0, 0, 0, 0.56)',
            press: 'rgba(0, 0, 0, 0.72)',
            unsafeForSmallText: {
                base: 'rgba(0, 0, 0, 0.42)',
                highlight: 'rgba(0, 0, 0, 0.44)',
                press: 'rgba(0, 0, 0, 0.61)'
            },
            elevated: {
                base: 'rgba(0, 0, 0, 0.56)',
                highlight: 'rgba(0, 0, 0, 0.56)',
                press: 'rgba(0, 0, 0, 0.72)'
            },
            tinted: {
                base: 'rgba(0, 0, 0, 0.54)',
                highlight: 'rgba(0, 0, 0, 0.54)',
                press: 'rgba(0, 0, 0, 0.54)'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: 'rgba(255, 255, 255, 0.13)' }
    }
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated This color theme is deprecated, consider moving to s4pLight
 */
export var studioLightTheme = {
    base: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#ffffff', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: {
                base: 'rgba(0, 0, 0, 0.08)',
                highlight: 'rgba(0, 0, 0, 0.05)',
                press: 'rgba(0, 0, 0, 0.12)'
            },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#6a6a6a',
            brightAccent: '#554dff',
            negative: '#d31225',
            warning: '#9d5a00',
            positive: '#117a37',
            announcement: '#0c67d3'
        },
        essential: {
            base: '#000000',
            subdued: '#878787',
            brightAccent: '#7b75ff',
            negative: '#e91429',
            warning: '#c77200',
            positive: '#169b45',
            announcement: '#0d72ea'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    brightAccent: {
        background: {
            base: '#9691ff',
            highlight: '#9e99ff',
            press: '#5d55ff',
            elevated: { base: '#9e99ff', highlight: '#9e99ff', press: '#5d55ff' },
            tinted: { base: '#9691ff', highlight: '#9691ff', press: '#9691ff' },
            unsafeForSmallText: {
                base: '#9691ff',
                highlight: '#9691ff',
                press: '#9691ff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#807aff' }
    },
    negative: {
        background: {
            base: '#e91429',
            highlight: '#de1327',
            press: '#920d1a',
            elevated: { base: '#de1327', highlight: '#de1327', press: '#920d1a' },
            tinted: { base: '#e91429', highlight: '#e91429', press: '#e91429' },
            unsafeForSmallText: {
                base: '#e91429',
                highlight: '#e91429',
                press: '#e91429'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#ee3a4c' }
    },
    warning: {
        background: {
            base: '#ffa42b',
            highlight: '#ffaf45',
            press: '#c57100',
            elevated: { base: '#ffaf45', highlight: '#ffaf45', press: '#c57100' },
            tinted: { base: '#ffa42b', highlight: '#ffa42b', press: '#ffa42b' },
            unsafeForSmallText: {
                base: '#ffa42b',
                highlight: '#ffa42b',
                press: '#ffa42b'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#f18900' }
    },
    positive: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    announcement: {
        background: {
            base: '#0d72ea',
            highlight: '#0c6ddf',
            press: '#084791',
            elevated: { base: '#0c6ddf', highlight: '#0c6ddf', press: '#084791' },
            tinted: { base: '#0d72ea', highlight: '#0d72ea', press: '#0d72ea' },
            unsafeForSmallText: {
                base: '#0d72ea',
                highlight: '#0d72ea',
                press: '#0d72ea'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#2a86f3' }
    },
    invertedDark: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            tinted: { base: '#000000', highlight: '#000000', press: '#000000' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#8f8f8f',
            brightAccent: '#9691ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9691ff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#5e5e5e',
            brightAccent: '#9691ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9691ff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    invertedLight: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#f6f6f6', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: { base: '#ffffff', highlight: '#ffffff', press: '#ffffff' },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#5e5e5e',
            brightAccent: '#5f58ff',
            negative: '#000000',
            warning: '#000000',
            positive: '#5f58ff',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#8f8f8f',
            brightAccent: '#857fff',
            negative: '#000000',
            warning: '#000000',
            positive: '#857fff',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    mutedAccent: {
        background: {
            base: '#121212',
            highlight: '#1a1a1a',
            press: '#000000',
            tinted: {
                base: 'rgba(255, 255, 255, 0.07)',
                highlight: 'rgba(255, 255, 255, 0.1)',
                press: 'rgba(255, 255, 255, 0.04)'
            },
            elevated: { base: '#1a1a1a', highlight: '#1a1a1a', press: '#000000' },
            unsafeForSmallText: {
                base: '#121212',
                highlight: '#121212',
                press: '#121212'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#a7a7a7',
            brightAccent: '#9691ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9691ff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#727272',
            brightAccent: '#9691ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9691ff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#292929' }
    },
    overMedia: {
        background: {
            base: 'rgba(0, 0, 0, 0.54)',
            highlight: 'rgba(0, 0, 0, 0.56)',
            press: 'rgba(0, 0, 0, 0.72)',
            unsafeForSmallText: {
                base: 'rgba(0, 0, 0, 0.42)',
                highlight: 'rgba(0, 0, 0, 0.44)',
                press: 'rgba(0, 0, 0, 0.61)'
            },
            elevated: {
                base: 'rgba(0, 0, 0, 0.56)',
                highlight: 'rgba(0, 0, 0, 0.56)',
                press: 'rgba(0, 0, 0, 0.72)'
            },
            tinted: {
                base: 'rgba(0, 0, 0, 0.54)',
                highlight: 'rgba(0, 0, 0, 0.54)',
                press: 'rgba(0, 0, 0, 0.54)'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: 'rgba(255, 255, 255, 0.13)' }
    }
};
/**
 *
 * ![Status: experimental](https://img.shields.io/badge/EXPERIMENTAL-yellow|height=14)
 * The S4P Light theme is used for Spotify Creator Studio, a product used to create, distribute, and monetize podcasts.
 */
export var s4pLightTheme = {
    base: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#ffffff', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: {
                base: 'rgba(0, 0, 0, 0.08)',
                highlight: 'rgba(0, 0, 0, 0.05)',
                press: 'rgba(0, 0, 0, 0.12)'
            },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#6a6a6a',
            brightAccent: '#554dff',
            negative: '#d31225',
            warning: '#9d5a00',
            positive: '#117a37',
            announcement: '#0c67d3'
        },
        essential: {
            base: '#000000',
            subdued: '#878787',
            brightAccent: '#7b75ff',
            negative: '#e91429',
            warning: '#c77200',
            positive: '#169b45',
            announcement: '#0d72ea'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    brightAccent: {
        background: {
            base: '#9691ff',
            highlight: '#9e99ff',
            press: '#5d55ff',
            elevated: { base: '#9e99ff', highlight: '#9e99ff', press: '#5d55ff' },
            tinted: { base: '#9691ff', highlight: '#9691ff', press: '#9691ff' },
            unsafeForSmallText: {
                base: '#9691ff',
                highlight: '#9691ff',
                press: '#9691ff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#807aff' }
    },
    negative: {
        background: {
            base: '#e91429',
            highlight: '#de1327',
            press: '#920d1a',
            elevated: { base: '#de1327', highlight: '#de1327', press: '#920d1a' },
            tinted: { base: '#e91429', highlight: '#e91429', press: '#e91429' },
            unsafeForSmallText: {
                base: '#e91429',
                highlight: '#e91429',
                press: '#e91429'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#ee3a4c' }
    },
    warning: {
        background: {
            base: '#ffa42b',
            highlight: '#ffaf45',
            press: '#c57100',
            elevated: { base: '#ffaf45', highlight: '#ffaf45', press: '#c57100' },
            tinted: { base: '#ffa42b', highlight: '#ffa42b', press: '#ffa42b' },
            unsafeForSmallText: {
                base: '#ffa42b',
                highlight: '#ffa42b',
                press: '#ffa42b'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#f18900' }
    },
    positive: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    announcement: {
        background: {
            base: '#0d72ea',
            highlight: '#0c6ddf',
            press: '#084791',
            elevated: { base: '#0c6ddf', highlight: '#0c6ddf', press: '#084791' },
            tinted: { base: '#0d72ea', highlight: '#0d72ea', press: '#0d72ea' },
            unsafeForSmallText: {
                base: '#0d72ea',
                highlight: '#0d72ea',
                press: '#0d72ea'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#2a86f3' }
    },
    invertedDark: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            tinted: { base: '#000000', highlight: '#000000', press: '#000000' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#8f8f8f',
            brightAccent: '#9691ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9691ff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#5e5e5e',
            brightAccent: '#9691ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9691ff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    invertedLight: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#f6f6f6', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: { base: '#ffffff', highlight: '#ffffff', press: '#ffffff' },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#5e5e5e',
            brightAccent: '#5f58ff',
            negative: '#000000',
            warning: '#000000',
            positive: '#5f58ff',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#8f8f8f',
            brightAccent: '#857fff',
            negative: '#000000',
            warning: '#000000',
            positive: '#857fff',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    mutedAccent: {
        background: {
            base: '#121212',
            highlight: '#1a1a1a',
            press: '#000000',
            tinted: {
                base: 'rgba(255, 255, 255, 0.07)',
                highlight: 'rgba(255, 255, 255, 0.1)',
                press: 'rgba(255, 255, 255, 0.04)'
            },
            elevated: { base: '#1a1a1a', highlight: '#1a1a1a', press: '#000000' },
            unsafeForSmallText: {
                base: '#121212',
                highlight: '#121212',
                press: '#121212'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#a7a7a7',
            brightAccent: '#9691ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9691ff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#727272',
            brightAccent: '#9691ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9691ff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#292929' }
    },
    overMedia: {
        background: {
            base: 'rgba(0, 0, 0, 0.54)',
            highlight: 'rgba(0, 0, 0, 0.56)',
            press: 'rgba(0, 0, 0, 0.72)',
            unsafeForSmallText: {
                base: 'rgba(0, 0, 0, 0.42)',
                highlight: 'rgba(0, 0, 0, 0.44)',
                press: 'rgba(0, 0, 0, 0.61)'
            },
            elevated: {
                base: 'rgba(0, 0, 0, 0.56)',
                highlight: 'rgba(0, 0, 0, 0.56)',
                press: 'rgba(0, 0, 0, 0.72)'
            },
            tinted: {
                base: 'rgba(0, 0, 0, 0.54)',
                highlight: 'rgba(0, 0, 0, 0.54)',
                press: 'rgba(0, 0, 0, 0.54)'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: 'rgba(255, 255, 255, 0.13)' }
    }
};
/**
 *
 * ![Status: experimental](https://img.shields.io/badge/EXPERIMENTAL-yellow|height=14)
 * The S4P Dark theme is used for Spotify Creator Studio, a product used to create, distribute, and monetize podcasts.
 */
export var s4pDarkTheme = {
    base: {
        background: {
            base: '#121212',
            highlight: '#1a1a1a',
            press: '#000000',
            elevated: { base: '#242424', highlight: '#2a2a2a', press: '#000000' },
            tinted: {
                base: 'rgba(255, 255, 255, 0.07)',
                highlight: 'rgba(255, 255, 255, 0.1)',
                press: 'rgba(255, 255, 255, 0.04)'
            },
            unsafeForSmallText: {
                base: '#121212',
                highlight: '#121212',
                press: '#121212'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#a7a7a7',
            brightAccent: '#9691ff',
            negative: '#f15e6c',
            warning: '#ffa42b',
            positive: '#1ed760',
            announcement: '#3d91f4'
        },
        essential: {
            base: '#ffffff',
            subdued: '#727272',
            brightAccent: '#9691ff',
            negative: '#e91429',
            warning: '#ffa42b',
            positive: '#1ed760',
            announcement: '#0d72ea'
        },
        decorative: { base: '#ffffff', subdued: '#292929' }
    },
    brightAccent: {
        background: {
            base: '#9691ff',
            highlight: '#9e99ff',
            press: '#5d55ff',
            elevated: { base: '#9e99ff', highlight: '#9e99ff', press: '#5d55ff' },
            tinted: { base: '#9691ff', highlight: '#9691ff', press: '#9691ff' },
            unsafeForSmallText: {
                base: '#9691ff',
                highlight: '#9691ff',
                press: '#9691ff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#807aff' }
    },
    negative: {
        background: {
            base: '#e91429',
            highlight: '#de1327',
            press: '#920d1a',
            elevated: { base: '#de1327', highlight: '#de1327', press: '#920d1a' },
            tinted: { base: '#e91429', highlight: '#e91429', press: '#e91429' },
            unsafeForSmallText: {
                base: '#e91429',
                highlight: '#e91429',
                press: '#e91429'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#ee3a4c' }
    },
    warning: {
        background: {
            base: '#ffa42b',
            highlight: '#ffaf45',
            press: '#c57100',
            elevated: { base: '#ffaf45', highlight: '#ffaf45', press: '#c57100' },
            tinted: { base: '#ffa42b', highlight: '#ffa42b', press: '#ffa42b' },
            unsafeForSmallText: {
                base: '#ffa42b',
                highlight: '#ffa42b',
                press: '#ffa42b'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#f18900' }
    },
    positive: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    announcement: {
        background: {
            base: '#0d72ea',
            highlight: '#0c6ddf',
            press: '#084791',
            elevated: { base: '#0c6ddf', highlight: '#0c6ddf', press: '#084791' },
            tinted: { base: '#0d72ea', highlight: '#0d72ea', press: '#0d72ea' },
            unsafeForSmallText: {
                base: '#0d72ea',
                highlight: '#0d72ea',
                press: '#0d72ea'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#2a86f3' }
    },
    invertedDark: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            tinted: { base: '#000000', highlight: '#000000', press: '#000000' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#8f8f8f',
            brightAccent: '#9691ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9691ff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#5e5e5e',
            brightAccent: '#9691ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9691ff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    invertedLight: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#f6f6f6', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: { base: '#ffffff', highlight: '#ffffff', press: '#ffffff' },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#5e5e5e',
            brightAccent: '#5f58ff',
            negative: '#000000',
            warning: '#000000',
            positive: '#5f58ff',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#8f8f8f',
            brightAccent: '#857fff',
            negative: '#000000',
            warning: '#000000',
            positive: '#857fff',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    mutedAccent: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            tinted: {
                base: 'rgba(255, 255, 255, 0.1)',
                highlight: 'rgba(255, 255, 255, 0.13)',
                press: 'rgba(255, 255, 255, 0.06)'
            },
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#9e9e9e',
            brightAccent: '#9691ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9691ff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#6a6a6a',
            brightAccent: '#9691ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#9691ff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    overMedia: {
        background: {
            base: 'rgba(0, 0, 0, 0.54)',
            highlight: 'rgba(0, 0, 0, 0.56)',
            press: 'rgba(0, 0, 0, 0.72)',
            unsafeForSmallText: {
                base: 'rgba(0, 0, 0, 0.42)',
                highlight: 'rgba(0, 0, 0, 0.44)',
                press: 'rgba(0, 0, 0, 0.61)'
            },
            elevated: {
                base: 'rgba(0, 0, 0, 0.56)',
                highlight: 'rgba(0, 0, 0, 0.56)',
                press: 'rgba(0, 0, 0, 0.72)'
            },
            tinted: {
                base: 'rgba(0, 0, 0, 0.54)',
                highlight: 'rgba(0, 0, 0, 0.54)',
                press: 'rgba(0, 0, 0, 0.54)'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: 'rgba(255, 255, 255, 0.13)' }
    }
};
/**
 *
 * ![Status: experimental](https://img.shields.io/badge/EXPERIMENTAL-yellow|height=14)
 * The Megaphone Light theme is used for the Megaphone CMS, a product to publish, monetize, and measure podcasts.
 */
export var megaphoneLightTheme = {
    base: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#ffffff', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: {
                base: 'rgba(0, 0, 0, 0.08)',
                highlight: 'rgba(0, 0, 0, 0.05)',
                press: 'rgba(0, 0, 0, 0.12)'
            },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#6a6a6a',
            brightAccent: '#6447ff',
            negative: '#d31225',
            warning: '#9d5a00',
            positive: '#117a37',
            announcement: '#496d91'
        },
        essential: {
            base: '#000000',
            subdued: '#878787',
            brightAccent: '#7358ff',
            negative: '#e91429',
            warning: '#c77200',
            positive: '#169b45',
            announcement: '#5179a1'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    brightAccent: {
        background: {
            base: '#7358ff',
            highlight: '#6c50ff',
            press: '#2600eb',
            elevated: { base: '#6c50ff', highlight: '#6c50ff', press: '#2600eb' },
            tinted: { base: '#7358ff', highlight: '#7358ff', press: '#7358ff' },
            unsafeForSmallText: {
                base: '#7358ff',
                highlight: '#7358ff',
                press: '#7358ff'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#876fff' }
    },
    negative: {
        background: {
            base: '#e91429',
            highlight: '#de1327',
            press: '#920d1a',
            elevated: { base: '#de1327', highlight: '#de1327', press: '#920d1a' },
            tinted: { base: '#e91429', highlight: '#e91429', press: '#e91429' },
            unsafeForSmallText: {
                base: '#e91429',
                highlight: '#e91429',
                press: '#e91429'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#ee3a4c' }
    },
    warning: {
        background: {
            base: '#ffa42b',
            highlight: '#ffaf45',
            press: '#c57100',
            elevated: { base: '#ffaf45', highlight: '#ffaf45', press: '#c57100' },
            tinted: { base: '#ffa42b', highlight: '#ffa42b', press: '#ffa42b' },
            unsafeForSmallText: {
                base: '#ffa42b',
                highlight: '#ffa42b',
                press: '#ffa42b'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#f18900' }
    },
    positive: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    announcement: {
        background: {
            base: '#5179a1',
            highlight: '#4d7399',
            press: '#324a63',
            elevated: { base: '#4d7399', highlight: '#4d7399', press: '#324a63' },
            tinted: { base: '#5179a1', highlight: '#5179a1', press: '#5179a1' },
            unsafeForSmallText: {
                base: '#5179a1',
                highlight: '#5179a1',
                press: '#5179a1'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#658bb2' }
    },
    invertedDark: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            tinted: { base: '#000000', highlight: '#000000', press: '#000000' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#8f8f8f',
            brightAccent: '#785eff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#785eff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#5e5e5e',
            brightAccent: '#7358ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#7358ff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    invertedLight: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#f6f6f6', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: { base: '#ffffff', highlight: '#ffffff', press: '#ffffff' },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#5e5e5e',
            brightAccent: '#6e52ff',
            negative: '#000000',
            warning: '#000000',
            positive: '#6e52ff',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#8f8f8f',
            brightAccent: '#7358ff',
            negative: '#000000',
            warning: '#000000',
            positive: '#7358ff',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    mutedAccent: {
        background: {
            base: '#121212',
            highlight: '#1a1a1a',
            press: '#000000',
            tinted: {
                base: 'rgba(255, 255, 255, 0.07)',
                highlight: 'rgba(255, 255, 255, 0.1)',
                press: 'rgba(255, 255, 255, 0.04)'
            },
            elevated: { base: '#1a1a1a', highlight: '#1a1a1a', press: '#000000' },
            unsafeForSmallText: {
                base: '#121212',
                highlight: '#121212',
                press: '#121212'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#a7a7a7',
            brightAccent: '#917cff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#917cff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#727272',
            brightAccent: '#7358ff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#7358ff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#292929' }
    },
    overMedia: {
        background: {
            base: 'rgba(0, 0, 0, 0.54)',
            highlight: 'rgba(0, 0, 0, 0.56)',
            press: 'rgba(0, 0, 0, 0.72)',
            unsafeForSmallText: {
                base: 'rgba(0, 0, 0, 0.42)',
                highlight: 'rgba(0, 0, 0, 0.44)',
                press: 'rgba(0, 0, 0, 0.61)'
            },
            elevated: {
                base: 'rgba(0, 0, 0, 0.56)',
                highlight: 'rgba(0, 0, 0, 0.56)',
                press: 'rgba(0, 0, 0, 0.72)'
            },
            tinted: {
                base: 'rgba(0, 0, 0, 0.54)',
                highlight: 'rgba(0, 0, 0, 0.54)',
                press: 'rgba(0, 0, 0, 0.54)'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: 'rgba(255, 255, 255, 0.13)' }
    }
};
/**
 *
 * ![Status: experimental](https://img.shields.io/badge/EXPERIMENTAL-yellow|height=14)
 * The Ad Analytics Light theme is used for the Ad Analytics product, a product to measure the performance of advertising campaigns.
 */
export var adAnalyticsLightTheme = {
    base: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#ffffff', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: {
                base: 'rgba(0, 0, 0, 0.08)',
                highlight: 'rgba(0, 0, 0, 0.05)',
                press: 'rgba(0, 0, 0, 0.12)'
            },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#6a6a6a',
            brightAccent: '#567408',
            negative: '#d31225',
            warning: '#9d5a00',
            positive: '#117a37',
            announcement: '#0c67d3'
        },
        essential: {
            base: '#000000',
            subdued: '#878787',
            brightAccent: '#6d930a',
            negative: '#e91429',
            warning: '#c77200',
            positive: '#169b45',
            announcement: '#0d72ea'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    brightAccent: {
        background: {
            base: '#cff56a',
            highlight: '#e2f9a6',
            press: '#8bba0c',
            elevated: { base: '#e2f9a6', highlight: '#e2f9a6', press: '#8bba0c' },
            tinted: { base: '#cff56a', highlight: '#cff56a', press: '#cff56a' },
            unsafeForSmallText: {
                base: '#cff56a',
                highlight: '#cff56a',
                press: '#cff56a'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#a8e20f' }
    },
    negative: {
        background: {
            base: '#e91429',
            highlight: '#de1327',
            press: '#920d1a',
            elevated: { base: '#de1327', highlight: '#de1327', press: '#920d1a' },
            tinted: { base: '#e91429', highlight: '#e91429', press: '#e91429' },
            unsafeForSmallText: {
                base: '#e91429',
                highlight: '#e91429',
                press: '#e91429'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#ee3a4c' }
    },
    warning: {
        background: {
            base: '#ffa42b',
            highlight: '#ffaf45',
            press: '#c57100',
            elevated: { base: '#ffaf45', highlight: '#ffaf45', press: '#c57100' },
            tinted: { base: '#ffa42b', highlight: '#ffa42b', press: '#ffa42b' },
            unsafeForSmallText: {
                base: '#ffa42b',
                highlight: '#ffa42b',
                press: '#ffa42b'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#f18900' }
    },
    positive: {
        background: {
            base: '#1ed760',
            highlight: '#1fdf64',
            press: '#169c46',
            elevated: { base: '#1fdf64', highlight: '#1fdf64', press: '#169c46' },
            tinted: { base: '#1ed760', highlight: '#1ed760', press: '#1ed760' },
            unsafeForSmallText: {
                base: '#1ed760',
                highlight: '#1ed760',
                press: '#1ed760'
            }
        },
        text: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#000000',
            brightAccent: '#000000',
            negative: '#000000',
            warning: '#000000',
            positive: '#000000',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#1abc54' }
    },
    announcement: {
        background: {
            base: '#0d72ea',
            highlight: '#0c6ddf',
            press: '#084791',
            elevated: { base: '#0c6ddf', highlight: '#0c6ddf', press: '#084791' },
            tinted: { base: '#0d72ea', highlight: '#0d72ea', press: '#0d72ea' },
            unsafeForSmallText: {
                base: '#0d72ea',
                highlight: '#0d72ea',
                press: '#0d72ea'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#2a86f3' }
    },
    invertedDark: {
        background: {
            base: '#000000',
            highlight: '#0d0d0d',
            press: '#3f3f3f',
            elevated: { base: '#0d0d0d', highlight: '#0d0d0d', press: '#3f3f3f' },
            tinted: { base: '#000000', highlight: '#000000', press: '#000000' },
            unsafeForSmallText: {
                base: '#000000',
                highlight: '#000000',
                press: '#000000'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#8f8f8f',
            brightAccent: '#cff56a',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#cff56a',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#5e5e5e',
            brightAccent: '#cff56a',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#cff56a',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#1f1f1f' }
    },
    invertedLight: {
        background: {
            base: '#ffffff',
            highlight: '#f6f6f6',
            press: '#b7b7b7',
            elevated: { base: '#f6f6f6', highlight: '#f6f6f6', press: '#b7b7b7' },
            tinted: { base: '#ffffff', highlight: '#ffffff', press: '#ffffff' },
            unsafeForSmallText: {
                base: '#ffffff',
                highlight: '#ffffff',
                press: '#ffffff'
            }
        },
        text: {
            base: '#000000',
            subdued: '#5e5e5e',
            brightAccent: '#5c7c08',
            negative: '#000000',
            warning: '#000000',
            positive: '#5c7c08',
            announcement: '#000000'
        },
        essential: {
            base: '#000000',
            subdued: '#8f8f8f',
            brightAccent: '#749c0a',
            negative: '#000000',
            warning: '#000000',
            positive: '#749c0a',
            announcement: '#000000'
        },
        decorative: { base: '#000000', subdued: '#dedede' }
    },
    mutedAccent: {
        background: {
            base: '#121212',
            highlight: '#1a1a1a',
            press: '#000000',
            tinted: {
                base: 'rgba(255, 255, 255, 0.07)',
                highlight: 'rgba(255, 255, 255, 0.1)',
                press: 'rgba(255, 255, 255, 0.04)'
            },
            elevated: { base: '#1a1a1a', highlight: '#1a1a1a', press: '#000000' },
            unsafeForSmallText: {
                base: '#121212',
                highlight: '#121212',
                press: '#121212'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#a7a7a7',
            brightAccent: '#cff56a',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#cff56a',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#727272',
            brightAccent: '#cff56a',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#cff56a',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: '#292929' }
    },
    overMedia: {
        background: {
            base: 'rgba(0, 0, 0, 0.54)',
            highlight: 'rgba(0, 0, 0, 0.56)',
            press: 'rgba(0, 0, 0, 0.72)',
            unsafeForSmallText: {
                base: 'rgba(0, 0, 0, 0.42)',
                highlight: 'rgba(0, 0, 0, 0.44)',
                press: 'rgba(0, 0, 0, 0.61)'
            },
            elevated: {
                base: 'rgba(0, 0, 0, 0.56)',
                highlight: 'rgba(0, 0, 0, 0.56)',
                press: 'rgba(0, 0, 0, 0.72)'
            },
            tinted: {
                base: 'rgba(0, 0, 0, 0.54)',
                highlight: 'rgba(0, 0, 0, 0.54)',
                press: 'rgba(0, 0, 0, 0.54)'
            }
        },
        text: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        essential: {
            base: '#ffffff',
            subdued: '#ffffff',
            brightAccent: '#ffffff',
            negative: '#ffffff',
            warning: '#ffffff',
            positive: '#ffffff',
            announcement: '#ffffff'
        },
        decorative: { base: '#ffffff', subdued: 'rgba(255, 255, 255, 0.13)' }
    }
};
