export enum InternalSocketCode {
  /**
   * The internal close code.
   *
   * This is used when explicitly closing the WebSocket as part of the
   * disconnection routines. When this is encountered, reconnection mechanics
   * are not applied to the WebSocket.
   *
   * @constant
   */
  CLOSE = 4000,

  /**
   * The internal timeout code.
   *
   * This is used when explicitly marking the WebSocket as timed out.
   */
  TIMEOUT = 4001,
}
