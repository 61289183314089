/**
 * Metrics about certain operations, including the timing details about when the
 * operation was started and when it ended.
 */
export interface OperationMetrics {
  /**
   * A Unix timestamp for when the operation started.
   */
  readonly start_time: number;

  /**
   * A Unix timestamp for when the operation ended.
   */
  readonly end_time: number;
}

/**
 * A class that deals with operation metrics such as the time it takes to
 * perform an operation.
 */
export class OperationMetricsTracker {
  private _startTime: number | null = null;
  private _endTime: number | null = null;

  /**
   * Create a new OperationMetricsTracker.
   *
   * @returns OperationMetricsTracker
   */
  static create(): OperationMetricsTracker {
    return new OperationMetricsTracker();
  }
  /**
   * Start a time measurement.
   */
  public start(): void {
    if (this._startTime !== null) {
      throw new Error('Measurement is already in progress.');
    }
    this._startTime = Date.now();
  }

  /**
   * End a time measurement.
   */
  public end(): void {
    if (this._startTime === null || this._endTime !== null) {
      throw new Error('Measurement is not in progress.');
    }
    this._endTime = Date.now();
  }

  /**
   * Get the resulting metrics after a measurement has ended.
   *
   * @returns Formatted metrics.
   */
  public getMetrics(): OperationMetrics {
    if (this._startTime === null || this._endTime === null) {
      throw new Error('Measurement is not done.');
    }
    return {
      start_time: this._startTime,
      end_time: this._endTime,
    };
  }
}
