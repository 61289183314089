import { Time as TimeUtil } from './Time';
import { asNanoseconds, Nanoseconds } from '../SemanticMetrics';

export type LowResolutionTime = number;
export type HighResolutionTime = [number, number];
export type Time = LowResolutionTime | HighResolutionTime;
export type TimeMap = {
  nanoseconds: Nanoseconds<number>;
  milliseconds: number;
  seconds: number;
};

export const supportsHighResolutionTime = () => {
  return typeof window === 'undefined' && typeof process.hrtime === 'function';
};

export const getBrowserTime = (): Time => {
  return typeof performance !== 'undefined' ? performance.now() : Date.now();
};

export const getTime = (): Time => {
  return supportsHighResolutionTime() ? process.hrtime() : getBrowserTime();
};

export const getLowResolutionTime = (startTime: LowResolutionTime): TimeMap => {
  const millis = <LowResolutionTime>getTime() - <LowResolutionTime>startTime;
  const time = TimeUtil.fromMillis(millis);
  return {
    nanoseconds: asNanoseconds(time.asNanos()),
    milliseconds: time.asMillis(),
    seconds: time.asSeconds(),
  };
};

export const getHighResolutionTime = (
  startTime: HighResolutionTime,
): TimeMap => {
  const [diffSeconds, diffNanoseconds] = process.hrtime(startTime);
  const nanoseconds = diffSeconds * 1e9 + diffNanoseconds;
  const milliseconds = nanoseconds / 1e6;
  const seconds = nanoseconds / 1e9;
  return {
    nanoseconds: asNanoseconds(nanoseconds),
    milliseconds,
    seconds,
  };
};

/**
 * Starts and stops a timer.
 * Unit in browser: milliseconds.
 * Unit in NodeJS: TimeMap.
 */
export class Timer {
  constructor(protected startTime: Time) {}

  static start(): Timer {
    return new Timer(getTime());
  }

  end(): TimeMap {
    if (supportsHighResolutionTime()) {
      return getHighResolutionTime(this.startTime as HighResolutionTime);
    }

    return getLowResolutionTime(this.startTime as LowResolutionTime);
  }
}
