import { cssBorderRadius, cssColorValue, cssSpacing, finaleBold, semanticColors } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
/* TagInner contains styles that make the component look like a tag,
 * and is the Tag subcomponent that is themable using Tag's colorSet prop
 */
export var TagInner = styled.div.withConfig({
  displayName: "TagInner",
  componentId: "sc-1uxwtix-0"
})(["", ""], function (_ref) {
  var colorSet = _ref.colorSet,
    fallbackSet = _ref.fallbackSet;
  var backgroundColor = colorSet ? cssColorValue(semanticColors.backgroundBase, fallbackSet.background.base) : cssColorValue(semanticColors.backgroundTintedBase, fallbackSet.background.tinted.base);
  return css(["", ";background-color:", ";color:", ";padding-block-start:", ";padding-inline-end:", ";padding-block-end:", ";padding-inline-start:", ";border-radius:", ";transition:background-color 0.2s,color 0.2s;"], finaleBold(), backgroundColor, cssColorValue(semanticColors.textBase, fallbackSet.text.base), cssSpacing('tighter-4', '4px'), cssSpacing('tighter-2', '8px'), cssSpacing('tighter-4', '4px'), cssSpacing('tighter-2', '8px'), cssBorderRadius('base', '4px'));
});