import {gql} from '@apollo/client';

export default gql`
  mutation deleteProduct($input: DeleteProductInput!) {
    authed {
      success
    }
    deleteProduct(input: $input) {
      id
      companyId
    }
  }
`;
