import {useState} from 'react';
import {DocumentNode, useMutation} from '@apollo/client';
import {FieldValues, SubmitHandler, useForm} from 'react-hook-form';
import Button from '../Button';
import {FORM_ERRORS, Form, Submit} from '../FormElements';
import {Overlay} from './Overlay';

type DeleteOverlayProps<T extends DocumentNode> = {
  children?: React.ReactNode;
  mutation: T;
  onDelete?: () => void;
  opened: boolean;
  refetch?: () => void;
  refetchQueries?: DocumentNode[];
  submitLabel?: string;
  title?: string;
  toggle: (_to?: boolean) => void;
  toVariables: () => object;
};

export const DeleteOverlay = <T extends DocumentNode>({
  children = <p>Are you sure you want to delete this?</p>,
  mutation,
  opened,
  onDelete,
  refetch,
  refetchQueries,
  submitLabel = 'Delete',
  title = 'Delete item',
  toggle,
  toVariables,
}: DeleteOverlayProps<T>) => {
  const [mutationFn] = useMutation(mutation, {
    refetchQueries: refetchQueries ? refetchQueries : [],
  });
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm();

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    setLoading(true);

    try {
      await mutationFn({
        variables: toVariables(),
      });
      refetch?.();
      onDelete?.();
      toggle(false);
    } catch (err) {
      setError('_custom', FORM_ERRORS.network);
    }
    setLoading(false);
  };

  return (
    <Overlay
      opened={opened}
      toggle={toggle}
      withShadow
      withBackdrop
      centered
      animation='scale-in'
      css={`
        padding: 1.75rem;
        min-width: 30rem;
      `}>
      <Form onSubmit={handleSubmit(onSubmit) as any} errors={errors}>
        <h3
          css={{
            fontSize: '1.25rem',
            fontWeight: 700,
            margin: '0 0 0.625rem 0',
          }}>
          {title}
        </h3>
        {children}
        <div
          css={{
            display: 'flex',
            gap: '0.75rem',
            justifyContent: 'flex-end',
          }}>
          <Button onClick={() => toggle(false)}>Cancel</Button>
          <Submit color='error' loading={loading}>
            {submitLabel}
          </Submit>
        </div>
      </Form>
    </Overlay>
  );
};
