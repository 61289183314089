import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import dayjs from '@core/lib/dayjs';
import Button from '../../Button/LegacyButton';
import DatePicker from '../../DatePicker';
import {Icon} from '../../Icon';
import {Overlay, useOverlay} from '../../Overlay';
import DataTableFilterButton from '../misc/DataTableFilterButton';
import {DataTableFilterProps} from '../types';

const getMinMaxDates = ({
  data,
  endAccessor,
  startAccessor,
}: {
  data?: any[];
  endAccessor: string;
  startAccessor: string;
}) => {
  const defaultRange = [dayjs(), dayjs()];

  if (data) {
    return data.reduce((acc, current) => {
      const startDate = dayjs(current[startAccessor]);
      const endDate = dayjs(current[endAccessor]);

      if (
        startDate.isBefore(acc[0]) &&
        startDate.isSameOrAfter('2015', 'year')
      ) {
        acc[0] = startDate;
      }

      if (endDate.isAfter(acc[1])) {
        acc[1] = endDate;
      }

      return acc;
    }, defaultRange.slice(0));
  }
  return defaultRange;
};

interface DateRangeFilterProps extends DataTableFilterProps {
  endAccessor: string;
  inputDateFormat?: string;
  label?: string;
  startAccessor: string;
}

const DateRangeFilter = forwardRef(
  (
    {
      column,
      data,
      defaultValue,
      endAccessor,
      inputDateFormat = 'MM/DD/YY',
      label = 'Date Range',
      onChange,
      startAccessor,
      ...props
    }: DateRangeFilterProps,
    ref
  ): JSX.Element => {
    const inputRef = useRef<HTMLInputElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);
    const [opened, toggle] = useOverlay(false);
    const minMaxDates = useMemo(
      () => getMinMaxDates({data, endAccessor, startAccessor}),
      [data, startAccessor, endAccessor]
    );
    const selectedDatesRef = useRef<[dayjs.Dayjs, dayjs.Dayjs]>(
      defaultValue && defaultValue.length === 2
        ? [dayjs(defaultValue[0]), dayjs(defaultValue[1])]
        : minMaxDates
    );
    const [selectedDates, setSelectedDates] = useState(
      selectedDatesRef.current
    );

    useImperativeHandle(ref, () => ({
      filter: (data) => {
        if (selectedDatesRef.current?.length === 2) {
          const selectedStart = selectedDatesRef.current[0].toDate().getTime();
          const selectedEnd = selectedDatesRef.current[1].toDate().getTime();

          return data.filter((d) => {
            const startDate = new Date(d[startAccessor]).getTime();
            const endDate = new Date(d[endAccessor]).getTime();

            return (
              (selectedStart >= startDate && selectedStart <= endDate) ||
              (startDate >= selectedStart && startDate <= selectedEnd)
            );
          });
        }
        return data;
      },
      clear: () => {
        onDatesChange(minMaxDates);
      },
    }));

    const onDatesChange = (dates) => {
      if (dates.length === 2) {
        selectedDatesRef.current = dates;
        onChange({
          column,
          defaultValue: dates.map((d) => d.toISOString()),
        });
        setSelectedDates(dates);
      }
    };

    return (
      <>
        <DataTableFilterButton domRef={inputRef} onClick={toggle}>
          {label}
          {selectedDates.length === 2
            ? `: ${selectedDates[0].format(
                inputDateFormat
              )} - ${selectedDates[1].format(inputDateFormat)}`
            : null}
          <Icon
            icon='chevron-down-small'
            rules={() => ({
              color: 'var(--icon-subtle)',
              flexShrink: 0,
              height: '1.625rem',
              marginLeft: 'auto',
              width: '1.625rem',
            })}
          />
        </DataTableFilterButton>
        {opened ? (
          <Overlay
            domRef={overlayRef}
            opened={opened}
            toggle={toggle}
            positionTarget={inputRef.current}
            horizontalAlign='right'
            verticalAlign='bottom'
            verticalOffset={2}
            transparentBackdrop
            withBackdrop
            withShadow
            css={`
              max-width: initial;
              padding: 1rem;
            `}
            {...props}>
            <DatePicker
              isOutsideRange={(date) =>
                !(
                  date.isSameOrAfter(minMaxDates[0]) &&
                  date.isSameOrBefore(minMaxDates[1])
                )
              }
              onDatesChange={onDatesChange}
              defaultDates={selectedDates}
              twoMonthsView
              extraControls={[
                {
                  title: 'Clear Filter',
                  callback: () => {
                    onDatesChange(minMaxDates);
                    toggle(false);
                  },
                },
              ]}
            />
          </Overlay>
        ) : null}
      </>
    );
  }
);

export default DateRangeFilter;
