import React, {forwardRef} from 'react';
import CampaignStateBadge from '@core/components/CampaignStateBadge';
import dayjs from '@core/lib/dayjs';
import {toPercent} from '@core/lib/filters';
import {Element} from '@core/style';
import {HIGHLIGHTER_40} from '@core/style';
import DataTable, {
  DateRangeFilter,
  MultipleSelectFilter,
} from '@core/ui/DataTable';

const fmtDate = (v) => (v ? dayjs(v).utc().format('MM/DD/YY') : '-');

const columns = [
  {
    title: 'Campaign',
    accessor: 'name',
    type: 'string',
    rule: () => ({fontWeight: 500, minWidth: '12.5rem'}),
  },
  {
    title: 'Organization',
    accessor: 'organizationName',
    type: 'string',
    Filter: MultipleSelectFilter,
  },
  {
    title: 'Advertiser',
    accessor: 'advertiserName',
    type: 'string',
    Filter: MultipleSelectFilter,
  },
  {
    title: 'State',
    accessor: 'state',
    type: 'string',
    Filter: forwardRef(({...props}, ref) => (
      <MultipleSelectFilter ref={ref} searchable={false} {...props} />
    )),
    Cell: ({data}) => <CampaignStateBadge state={data.state} />,
  },
  {
    title: 'Start',
    accessor: 'startAt',
    type: 'date',
    Filter: forwardRef(({...props}, ref) => (
      <DateRangeFilter
        ref={ref}
        label='Start/End'
        startAccessor='_start'
        endAccessor='_end'
        {...props}
      />
    )),
    Cell: ({data: {startAt, expectedStartAt}}) => {
      return (
        <div css={{fontSize: '0.8125rem', lineHeight: 1.4}}>
          {startAt ? fmtDate(startAt) : '-'}
          {expectedStartAt && !dayjs(startAt).isSame(expectedStartAt, 'day') ? (
            <div css={{color: '--var-text-muted'}}>
              {`Exp. ${fmtDate(expectedStartAt)}`}
            </div>
          ) : null}
        </div>
      );
    },
  },
  {
    title: 'End',
    accessor: 'endAt',
    type: 'date',
    Cell: ({data: {endAt, expectedEndAt}}) => {
      return (
        <Element rules={() => ({fontSize: '0.8125rem', lineHeight: 1.4})}>
          {endAt ? fmtDate(endAt) : '-'}
          {expectedEndAt && !dayjs(endAt).isSame(expectedEndAt, 'day') ? (
            <Element rules={({theme}) => ({color: theme.textTertiary})}>
              {`Exp. ${fmtDate(expectedEndAt)}`}
            </Element>
          ) : null}
        </Element>
      );
    },
  },
  {
    title: 'Kind',
    accessor: 'kind',
    type: 'string',
    Filter: forwardRef(({...props}, ref) => (
      <MultipleSelectFilter ref={ref} searchable={false} {...props} />
    )),
    hidden: true,
  },
  {
    title: 'Last Built',
    accessor: 'lastBuilt',
    type: 'date',
    fmt: fmtDate,
  },
  {
    title: 'CR',
    accessor: 'cr',
    type: 'number',
    fmt: toPercent,
  },
  {
    title: 'Created',
    accessor: 'createdAt',
    type: 'date',
    fmt: fmtDate,
    Filter: forwardRef(({...props}, ref) => (
      <DateRangeFilter
        ref={ref}
        label='Created'
        startAccessor='createdAt'
        endAccessor='createdAt'
        {...props}
      />
    )),
  },
];

const CampaignsTable = ({campaigns, selectedCampaignId, headerContainer}) => {
  return (
    <DataTable
      columns={columns}
      data={campaigns}
      orderBy='-createdAt'
      searchKeys={['name', 'id']}
      searchPlaceholder='Search'
      emptyMessageText='No campaigns found.'
      headerContainer={headerContainer}
      onClickRowPath={({id}) => `/campaigns/${id}`}
      filtersStorageKey='admin-campaigns'
      rowRuleFn={({data: {id}}) => {
        if (id === selectedCampaignId) {
          return {
            background: HIGHLIGHTER_40,
            ':hover': {
              background: HIGHLIGHTER_40,
            },
          };
        }
        return {};
      }}
    />
  );
};

export default CampaignsTable;
