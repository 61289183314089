import {useState} from 'react';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import dayjs from '@core/lib/dayjs';
import {HIGHLIGHTER} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import {DateRangeInput} from '@core/ui/DatePicker';
import {Input} from '@core/ui/Form';
import Toggle from '@core/ui/Toggle';
import Backdated from './Backdated';

const BackdatedRangeIndex = ({
  history,
  match: {
    params: {start, end},
  },
}) => {
  const onDatesChange = ({startDate, endDate}) => {
    history.push(
      `/backdated/${startDate.format('YYYY-MM-DD')}/${endDate.format(
        'YYYY-MM-DD'
      )}`
    );
  };

  const [freqGte, setFreqGte] = useState(2.5);
  const [byPublished, setByPublished] = useState(true);

  const hardStart = dayjs('2019-09-01', 'YYYY-MM-DD');
  const hardEnd = dayjs().subtract(1, 'days');

  return (
    <>
      <Card rules={() => ({background: HIGHLIGHTER})}>
        Still questioning if this works.
      </Card>
      <Card
        rules={() => ({
          display: 'grid',
          fontSize: '14px',
          gridTemplateColumns: '1fr 2fr 1fr 1fr',
          alignItems: 'center',
          gridGap: '4rem',
        })}>
        <CardHeader>Range</CardHeader>
        <DateRangeInput
          onDatesChange={onDatesChange}
          startDate={dayjs(start)}
          endDate={dayjs(end)}
          isOutsideRange={(date) => {
            return !(
              date.isSameOrAfter(hardStart) && date.isSameOrBefore(hardEnd)
            );
          }}
        />
        <p>This tool looks for backdated episodes.</p>

        <div>
          <Input
            label='Min Frequency'
            value={freqGte}
            onChange={({target: {value}}) => setFreqGte(value)}
          />
        </div>
        <div>
          <Toggle defaultChecked={byPublished} onChecked={setByPublished}>
            By Episode Published
          </Toggle>
        </div>
      </Card>

      <Switch>
        <PropsRoute
          exact
          path='/backdated/:start/:end'
          after={dayjs(start)}
          before={dayjs(end).clone().add(1, 'days')}
          freqGte={freqGte}
          byPublished={byPublished}
          component={Backdated}
        />
      </Switch>
    </>
  );
};

export default BackdatedRangeIndex;
