import {
  TextField as MuiTextField,
  type TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import styled from 'styled-components';

export type TextFieldProps = MuiTextFieldProps;

export const TextField = styled(MuiTextField)`
  & {
    width: 100%;
    position: relative;
    line-height: 1.5;
  }
  & .MuiInputLabel-root {
    border: none;
    color: var(--text-muted);
    top: 1.125rem;
    left: 0.3125rem;
    margin: auto;
    padding: 0 1rem;
    pointer-events: none;
    position: absolute;
    transform: initial;
    transform-origin: left;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    white-space: nowrap;
    &.Mui-error {
      color: var(--red-orange);
    }
    &.Mui-focused {
      color: var(--blue);
      transform: scale(0.75) translateY(-1.125rem);
    }
    &.MuiInputLabel-shrink {
      transform: scale(0.75) translateY(-1.125rem);
    }
  }
  & fieldset {
    display: none;
  }
  & .MuiInputBase-input {
    caret-color: var(--text-default);
    box-sizing: border-box;
    border-radius: 0.25rem;
    border: 2px solid var(--border-default);
    font-size: 0.9327rem;
    height: 3.75rem;
    padding: 1.625rem 1rem 0.5rem;
    :hover,
    :focus {
      border: 2px solid var(--blue);
    }
    &.Mui-error {
      color: var(--red-orange);
    }
  }
  & .MuiFormHelperText-root {
    color: var(--text-default);
    &.Mui-error {
      color: var(--red-orange);
    }
  }
`;
