import React from 'react';
import {breakpoints} from '@core/ui/ResponsiveContent';
import SpotifyBanner from '@analytics/components/onboard/SpotifyBanner';
import {
  ContentContainer,
  PageContainer,
} from '@analytics/screens/account/AccountLayout';
import ConfirmationDialog from '@analytics/screens/account/ConfirmationDialog';
import ConfirmImage from './error.svg';

interface IErrorBaseProps {
  children?: React.ReactNode;
  baseColor?: string;
  title: string;
}

const ErrorBase = ({
  baseColor = 'var(--red-orange)',
  children,
  title,
}: IErrorBaseProps) => {
  return (
    <PageContainer>
      <SpotifyBanner advertising />
      <ContentContainer>
        <ConfirmationDialog
          wide
          imageSrc={ConfirmImage}
          boxTitle={title}
          boxBody={children}
        />
      </ContentContainer>
    </PageContainer>
  );
};

export default ErrorBase;
