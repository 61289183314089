import {RefObject, useLayoutEffect, useState} from 'react';
import {type OverlayProps} from './Overlay';

const MARGIN = 6;

const toSizeValue = (val: any, sum = 0): string => {
  if (typeof val === 'number') {
    return `${val + sum}px`;
  }
  return 'auto';
};

const toNumber = (val: any): number => {
  if (typeof val === 'number') {
    return val;
  }
  return 0;
};

type PositionizeProps = Pick<
  OverlayProps,
  | 'fixed'
  | 'horizontalAlign'
  | 'horizontalOffset'
  | 'noHorizontalOverlap'
  | 'noVerticalOverlap'
  | 'opened'
  | 'positionTarget'
  | 'verticalAlign'
  | 'verticalOffset'
> & {overlayRef: RefObject<HTMLDivElement>};

type GetPositionTarget = (
  _positionTarget: HTMLElement | null,
  _props: PositionizeProps
) => string;

const getPositionTarget: GetPositionTarget = (
  positionTarget,
  {
    fixed,
    horizontalAlign,
    horizontalOffset,
    noHorizontalOverlap,
    noVerticalOverlap,
    overlayRef,
    verticalAlign,
    verticalOffset,
  }
) => {
  const _overlayElement = overlayRef.current;
  if (!positionTarget || !_overlayElement) return '';
  // Target Rect
  const _tr = positionTarget.getBoundingClientRect();
  // Overlay Rect
  const _or = _overlayElement.getBoundingClientRect();
  let _top: string | number = 'auto';
  let _right: string | number = 'auto';
  let _bottom: string | number = 'auto';
  let _left: string | number = 'auto';

  {
    // Horizontal Position
    const _offset = toNumber(horizontalOffset);
    const _windowWidth = window.innerWidth;
    const _rectWidth = _or.width + _offset;
    const _leftEmptyWidth =
      (noHorizontalOverlap ? _tr.left : _tr.right) - MARGIN;
    const _rightEmptyWidth =
      _windowWidth - (noHorizontalOverlap ? _tr.right : _tr.left) - MARGIN;
    const _targetLeftFromCenter = _tr.left + _tr.width / 2 - MARGIN;
    const _targetRightromCenter =
      _windowWidth - _tr.right + _tr.width / 2 - MARGIN;
    const _fitsLeft = _leftEmptyWidth >= _rectWidth;
    const _fitsRight = _rightEmptyWidth >= _rectWidth;
    const _fitsCenter =
      _targetLeftFromCenter >= _rectWidth / 2 &&
      _targetRightromCenter >= _rectWidth / 2;

    switch (horizontalAlign) {
      case 'left':
        if (_fitsLeft) {
          _right =
            _windowWidth -
            (noHorizontalOverlap ? _tr.left : _tr.right) -
            _offset;
        } else {
          _left = MARGIN;
        }
        break;
      case 'center':
        if (_fitsCenter) {
          _left = _tr.left + _tr.width / 2 - (_or.width / 2 + _offset);
        } else if (_rightEmptyWidth >= _leftEmptyWidth) {
          _left = MARGIN;
        } else {
          _right = MARGIN;
        }
        break;
      default:
        // 'right'
        if (_fitsRight) {
          _left = (noHorizontalOverlap ? _tr.right : _tr.left) + _offset;
        } else {
          _right = MARGIN;
        }
        break;
    }
  }

  {
    // Vertical Position
    const _offset = toNumber(verticalOffset);
    const _windowHeight = window.innerHeight;
    const _rectHeight = _or.height + _offset;
    const _topEmptyHeight = (noVerticalOverlap ? _tr.top : _tr.bottom) - MARGIN;
    const _bottomEmptyHeight =
      _windowHeight - (noVerticalOverlap ? _tr.bottom : _tr.top) - MARGIN;
    const _targetTopFromMiddle = _tr.top + _tr.height / 2 - MARGIN;
    const _targetBottomFromMiddle =
      _windowHeight - _tr.bottom + _tr.height / 2 - MARGIN;
    const _fitsTop = _topEmptyHeight >= _rectHeight;
    const _fitsBottom = _bottomEmptyHeight >= _rectHeight;
    const _fitsMiddle =
      _targetTopFromMiddle >= _rectHeight / 2 &&
      _targetBottomFromMiddle >= _rectHeight / 2;

    switch (verticalAlign) {
      case 'top':
        if (_fitsTop) {
          _bottom =
            _windowHeight -
            (noVerticalOverlap ? _tr.top : _tr.bottom) +
            _offset;
        } else {
          _top = MARGIN;
        }
        break;
      case 'middle':
        if (_fitsMiddle) {
          _top = _tr.top + _tr.height / 2 - _or.height / 2 + _offset;
        } else if (_bottomEmptyHeight >= _topEmptyHeight) {
          _top = MARGIN;
        } else {
          _bottom = MARGIN;
        }
        break;
      default:
        // 'Bottom'
        if (_fitsBottom) {
          _top = (noVerticalOverlap ? _tr.bottom : _tr.top) + _offset;
        } else {
          _bottom = MARGIN;
        }
        break;
    }
  }

  return [
    toSizeValue(_top, fixed ? 0 : window.scrollY),
    toSizeValue(_right, fixed ? 0 : -window.scrollX),
    toSizeValue(_bottom, fixed ? 0 : -window.scrollY),
    toSizeValue(_left, fixed ? 0 : window.scrollX),
  ].join(' ');
};

export const usePositionTarget = (props: PositionizeProps): [string] => {
  const [inset, setInset] = useState<string>('');

  useLayoutEffect(() => {
    if (props.positionTarget && props.opened) {
      setInset(getPositionTarget(props.positionTarget, props));
    }
  }, [props]);

  return [inset];
};
