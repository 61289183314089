import {
  SortDirectionNum,
  getSortDirection,
  getSortKey,
  sortByBoolean,
  sortByNumber,
  sortByString,
  sortyByDate,
} from '@core/lib/sortBy';
import {DataTableColumn, DataTableFilter, DataTableValuesMap} from './types';

export const getSortFunction = (
  type: DataTableColumn['type'],
  direction: SortDirectionNum,
  getValue: any
): ((_a: any, _b: any) => number) => {
  switch (type) {
    case 'number':
      return (a, b) => sortByNumber(direction, getValue(a), getValue(b));
    case 'date':
      return (a, b) => sortyByDate(direction, getValue(a), getValue(b));
    case 'boolean':
      return (a, b) => sortByBoolean(direction, getValue(a), getValue(b));
    case 'string':
      return (a, b) => sortByString(direction, getValue(a), getValue(b));
    default:
      return () => 0;
  }
};

type SortDataParams = (_props: {
  columns: DataTableColumn[];
  data: any[];
  sortBy: string;
  valuesMap: DataTableValuesMap;
}) => any[];

export const sortData: SortDataParams = ({
  columns,
  data,
  sortBy,
  valuesMap,
}) => {
  const sortKey = getSortKey(sortBy);
  const column = sortKey
    ? columns.find((col) => col.accessor === sortKey)
    : null;

  if (column && (column.sort || column.accessor || column.getValue)) {
    const direction = getSortDirection(sortBy);
    if (column.sort) {
      return data.sort(column.sort.bind(this, direction));
    }
    const getValue = (d: any) => valuesMap.get(column).get(d);
    return data.sort(getSortFunction(column.type, direction, getValue));
  }
  return data;
};

export const applyFilters = ({
  data,
  filters,
}: {
  data: any;
  filters: DataTableFilter[];
}) => {
  return filters.reduce((acc, filter) => {
    if (filter.ref.current) {
      return (filter.ref.current as any).filter(acc) || acc;
    }
    return acc;
  }, data.slice(0));
};

const SEARCH_FILTER_REGEX = /[^\w\s]/gi;

type ApplySearchFn = <S>(_props: {
  data: S[];
  query: string;
  searchColumns: DataTableColumn[];
  valuesMap: DataTableValuesMap;
}) => S[];

export const applySearch: ApplySearchFn = ({
  data,
  query,
  valuesMap,
  searchColumns,
}) => {
  if (data) {
    let _columns = searchColumns ? searchColumns.filter(Boolean) : [];

    if (query && _columns.length > 0) {
      const queryRegex = new RegExp(
        query.replace(SEARCH_FILTER_REGEX, ''),
        'i'
      );

      if (/\w+:\w+$/.test(query)) {
        // when query is in the form of "key:value"
        // we want to search for the value of the key
        const arr = query.split(':');
        const col = _columns.find((c) => c.accessor === arr[0]);
        if (col) {
          _columns = [col];
        }
      }

      return data.filter((d: {[key: string]: any}) => {
        if (d) {
          return !!_columns.find((c) => {
            const value = valuesMap.get(c).get(d);

            return (
              typeof value === 'string' &&
              queryRegex.test(value.replace(SEARCH_FILTER_REGEX, ''))
            );
          });
        }
        return false;
      });
    }
    return data.filter(Boolean);
  }
  return [];
};
