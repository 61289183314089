import {exporterEndpoint} from './environment';

export default (
  objectType: string,
  kind: string,
  pk: string,
  params?: {[key: string]: any}
): string => {
  const token = localStorage.getItem('adanalytics-token');
  const query = Object.assign(
    {
      token,
      kind,
      pk,
      object_type: objectType,
    },
    params ? params : {}
  );

  return `${exporterEndpoint}/broker?${new URLSearchParams(query).toString()}`;
};
