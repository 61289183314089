export type ApplicationContext = {
  name: 'context_application';
  data: {
    /**
     * The application version
     */
    version: string;
  };
};

/**
 * A builder for Application
 *
 * @param data - The event data.
 * @param data.version - The application version.
 * @return The formatted event data for ApplicationContext
 */
export function createApplicationContext(data: {
  version: string;
}): () => ApplicationContext {
  return () => {
    return {name: 'context_application', data};
  };
}
