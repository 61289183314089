import {useState} from 'react';
import {
  useMarkNewBrandRequestCompleteMutation,
  useNewBrandRequestsQuery,
} from '@admin/graphql-api';
import {
  Backdrop,
  Box,
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
  DialogConfirmation,
  EmptyState,
  EmptyStateText,
  EmptyStateTitle,
  IconTrending,
  ProgressCircle,
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
  Type,
} from '@spotify-internal/encore-web';
import {PageTitle} from '@core/ui/Content';

const SetupRequests = () => {
  const {data, error, loading, refetch} = useNewBrandRequestsQuery();
  const [markNewBrandRequestCompleted] =
    useMarkNewBrandRequestCompleteMutation();
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);

  const handleMarkAsNewBrand = async () => {
    if (currentRequest && 'id' in currentRequest) {
      await markNewBrandRequestCompleted({
        variables: {input: {requestId: String(currentRequest.id)}},
      });
      setConfirmationOpen(false);
      setCurrentRequest(null);
      refetch();
    }
  };

  if (loading) {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <ProgressCircle />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <p>Error: {error.message}</p>
      </Box>
    );
  }

  if (data?.admin?.cloudsenseNewBrandRequests?.length === 0) {
    return (
      <EmptyState icon={IconTrending} variant='contextual'>
        <EmptyStateTitle>No Data Available</EmptyStateTitle>
        <EmptyStateText>
          There are currently no brand requests available.
        </EmptyStateText>
      </EmptyState>
    );
  }

  return (
    <>
      <PageTitle>Setup Requests</PageTitle>
      <Table>
        <colgroup>
          <col />
          <col width='20%' />
          <col width='20%' />
          <col width='20%' />
          <col width='20%' />
          <col width='20%' />
          <col width='20%' />
          <col width='20%' />
          <col width='20%' />
          <col width='20%' />
          <col width='20%' />
        </colgroup>
        <thead>
          <TableRow>
            <TableHeaderCell align='center'>#</TableHeaderCell>
            <TableHeaderCell>Brand Name</TableHeaderCell>
            <TableHeaderCell>Contact First Name</TableHeaderCell>
            <TableHeaderCell>Contact Last Name</TableHeaderCell>
            <TableHeaderCell>Contact Email</TableHeaderCell>
            <TableHeaderCell>Agency Name</TableHeaderCell>
            <TableHeaderCell>Campaign Kind</TableHeaderCell>
            <TableHeaderCell>Advertiser Website</TableHeaderCell>
            <TableHeaderCell>Mapping Attempts</TableHeaderCell>
            <TableHeaderCell>Requestor</TableHeaderCell>
            <TableHeaderCell align='right' active>
              Booked Status
            </TableHeaderCell>
            <TableHeaderCell>Action</TableHeaderCell>
          </TableRow>
        </thead>
        <tbody>
          {data?.admin?.cloudsenseNewBrandRequests?.map((request, index) => {
            return (
              <TableRow key={index}>
                <TableCell numerical align='center'>
                  {index + 1}
                </TableCell>
                <TableCell>{request?.brandName}</TableCell>
                <TableCell>{request?.brandContactFirstName}</TableCell>
                <TableCell>{request?.brandContactLastName}</TableCell>
                <TableCell>{request?.brandContactEmail}</TableCell>
                <TableCell>{request?.agencyName}</TableCell>
                <TableCell>{request?.campaignKind}</TableCell>
                <TableCell>{request?.advertiserWebsite}</TableCell>
                <TableCell>
                  {request?.mappingAttempts.map((attempt, index) => (
                    <div key={index}>
                      <p>Order ID: {attempt?.spotifyOrder?.id}</p>
                      <p>Order Name: {attempt?.spotifyOrder?.name}</p>
                    </div>
                  ))}
                </TableCell>
                <TableCell>
                  <p>First Name: {request?.requestor?.firstName}</p>
                  <p>Last Name: {request?.requestor?.lastName}</p>
                  <p>Email: {request?.requestor?.email}</p>
                </TableCell>
                <TableCell numerical align='right'>
                  {request?.bookedStatus}
                </TableCell>
                <TableCell>
                  <ButtonSecondary
                    style={{whiteSpace: 'nowrap'}}
                    onClick={() => {
                      setCurrentRequest(request);
                      setConfirmationOpen(true);
                    }}>
                    {'Completed'}
                  </ButtonSecondary>
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
      {isConfirmationOpen && (
        <Backdrop center>
          <DialogConfirmation
            dialogTitle='Mark as New Brand'
            id='dialogConfirmation'
            body={
              <div>
                <Type as='p'>
                  Are you sure you want to mark this completed?
                </Type>
              </div>
            }
            footer={
              <div>
                <ButtonTertiary
                  size='small'
                  condensed
                  onClick={() => {
                    setConfirmationOpen(false);
                    setCurrentRequest(null);
                  }}>
                  Cancel
                </ButtonTertiary>
                <ButtonPrimary size='small' onClick={handleMarkAsNewBrand}>
                  Accept
                </ButtonPrimary>
              </div>
            }
          />
        </Backdrop>
      )}
    </>
  );
};

export default SetupRequests;
