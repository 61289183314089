import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Icon} from '../Icon';
import {Text} from '../Text';
import {DropdownMenuProps} from './DropdownMenu';
import {DropdownMenuItemType} from './types';

interface Props
  extends Pick<
    DropdownMenuProps,
    'closeOnSubmit' | 'onSubmit' | 'selectedValue' | 'propertyForValue'
  > {
  item: DropdownMenuItemType;
  toggle: (_to?: boolean) => void;
}

export const DropdownMenuItem = ({
  closeOnSubmit,
  item,
  onSubmit,
  propertyForValue,
  selectedValue,
  toggle,
}: Props) => {
  if (item.isVisible?.() === false) return null;
  if (item.content) return item.content;

  const value = item[propertyForValue!];
  const isSelected = !!selectedValue && selectedValue === value;

  const content = (
    <>
      <div>
        <Text variant='body1'>{item.title}</Text>
        {!!item.description && (
          <Text variant='body2' color='muted'>
            {item.description}
          </Text>
        )}
      </div>
      {isSelected && (
        <Icon
          icon='checkmark'
          css={`
            flex-shrink: 0;
            height: 1.25rem;
            width: 1.25rem;
          `}
        />
      )}
      {item.target === '_blank' && (
        <Icon
          icon='external'
          css={`
            color: var(--icon-subtle);
            flex-shrink: 0;
            height: 1rem;
            width: 1rem;
          `}
        />
      )}
    </>
  );

  const menuItemProps = {
    $isSelected: isSelected,
    $disabled: item.disabled || false,
    css: item.css,
  };

  if (item.href) {
    return (
      <_MenuItem
        as='a'
        tabIndex={-1}
        href={item.href}
        target={item.target ?? '_blank'}
        rel='noopener noreferrer'
        onClick={(_) => toggle(false)}
        {...menuItemProps}>
        {content}
      </_MenuItem>
    );
  }

  if (item.to) {
    return (
      <_MenuItem
        as={Link}
        tabIndex={-1}
        to={item.to.replace(/\/$/, '')}
        onClick={(_) => toggle(false)}
        {...menuItemProps}>
        {content}
      </_MenuItem>
    );
  }

  return (
    <_MenuItem
      as='button'
      tabIndex={-1}
      onClick={(evt: any) => {
        evt.preventDefault();
        evt.stopPropagation();
        if (item.disabled) return;
        item.callback?.(item);
        onSubmit?.(item);
        if (closeOnSubmit) toggle(false);
      }}
      {...menuItemProps}>
      {content}
    </_MenuItem>
  );
};

const _MenuItem = styled.div<{$isSelected: boolean; $disabled: boolean}>`
  align-items: center;
  color: ${(p) => (p.$isSelected ? 'var(--primary)' : 'var(--text-default)')};
  display: flex;
  font-size: var(--font-size-body-1);
  gap: var(--spacing-4);
  justify-content: space-between;
  line-height: var(--line-height-md);
  padding: var(--spacing-2) var(--spacing-6);
  text-align: left;
  text-decoration: none;
  width: 100%;
  background: ${(p) => (p.$isSelected ? 'var(--bg-muted)' : 'initial')};
  :focus-visible {
    background: var(--bg-subtle);
  }
  ${(p) =>
    p.$disabled
      ? `
      cursor: default;
      opacity: 0.35;
      pointer-events: none;
      `
      : `
      cursor: pointer;
      :hover {
        background: var(--bg-default-hover);
        color: var(--primary);
      }
      :hover > svg { color: var(--primary); }
    `}
`;
