import {
  Control,
  Controller,
  ControllerProps,
  FieldError,
  FieldValues,
  Path,
} from 'react-hook-form';
import {TextField, TextFieldProps} from './TextField';

export interface TextFieldControlledProps<T extends FieldValues>
  extends Omit<TextFieldProps, 'name'> {
  control?: Control<T>;
  name: Path<T>;
  parseError?: (_error: FieldError) => string;
  validation?: ControllerProps['rules'];
}

// TODO: Finish this
export function TextFieldControlled<T extends FieldValues>({
  control,
  helperText,
  name,
  parseError,
  validation = {},
  ...props
}: TextFieldControlledProps<T>) {
  if (props.required && !validation.required) {
    validation.required = 'This field is required';
  }

  if (props.type === 'email' && !validation.pattern) {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    validation.pattern = {
      value: emailRegex,
      message: 'Please enter a valid email address',
    };
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      render={({
        field: {value = '', onChange, onBlur},
        fieldState: {invalid, error},
      }) => {
        const errorMessage = error && (parseError?.(error) ?? error.message);

        return (
          <TextField
            {...props}
            error={invalid || !!error}
            name={name}
            helperText={errorMessage ?? helperText}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        );
      }}
    />
  );
}
