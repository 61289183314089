import {
  BLUE,
  BLUE_80,
  GREEN,
  GREEN_80,
  RED_ORANGE,
  RED_ORANGE_80,
} from './colors';

/**
 * @deprecated
 */
export type CustomTheme = (typeof THEMES)['light'] | (typeof THEMES)['dark'];

/**
 * @deprecated
 */
export const THEMES = {
  light: {
    name: 'light',
    // Base
    black: '#1b1f23',
    white: '#fff',
    // Gray
    gray0: '#fafbfc',
    gray1: '#f6f8fa',
    gray2: '#e1e4e8',
    gray3: '#d1d5da',
    gray4: '#959da5',
    gray5: '#6a737d',
    gray6: '#586069',
    gray7: '#444d56',
    gray8: '#2f363d',
    gray9: '#24292e',
    // Background
    bgPrimary: '#fff',
    bgTertiary: '#F2f3f5',
    bgSurface: '#fff',
    bgOverlay: '#fff',
    bgBackdrop: 'rgba(27,31,35,0.5)',
    // Bg Alert States
    bgInfo: '#f1f8ff',
    bgInfoInverse: BLUE,
    bgDanger: '#ffeef0',
    bgDangerInverse: RED_ORANGE,
    bgSuccess: '#dcffe4',
    bgSuccessInverse: GREEN,
    bgWarning: '#fff5b1',
    bgWarningInverse: '#ffd33d',
    // Text
    textPrimary: '#24292e',
    textSecondary: '#586069',
    textTertiary: '#6a737d',
    textPlaceholder: '#6a737d',
    textDisabled: '#cecece',
    textInverse: '#fff',
    // Text Alert States
    textLink: BLUE,
    textDanger: RED_ORANGE,
    textSuccess: GREEN,
    textWarning: '#b08800',
    textWhite: '#fff',
    // Border
    borderPrimary: '#e1e4e8',
    borderSecondary: '#eaecef',
    borderTertiary: '#c4c9cc',
    borderOverSurface: '#eaecef',
    borderInfo: BLUE,
    borderDanger: RED_ORANGE,
    borderSuccess: GREEN_80,
    borderWarning: '#f9c513',
    // Icon
    iconPrimary: '#24292e',
    iconSecondary: '#586069',
    iconTertiary: '#959da5',
    iconInfo: '#0366d6',
    iconDanger: '#d73a49',
    iconSuccess: '#22863a',
    iconWarning: '#b08800',
    // Shadow
    shadowSmall: '0 1px 0 rgba(27,31,35,0.04)',
    shadowMedium: '0 1px 4px 2px rgba(149,157,165,0.33)',
    shadowLarge: '0 8px 24px rgba(149,157,165,0.2)',
    shadowExtraLarge: '0 12px 48px rgba(149,157,165,0.3)',
    shadowHighlight: 'inset 0 1px 0 hsla(0,0%,100%,0.25)',
    shadowInset: 'inset 0 1px 0 rgba(225,228,232,0.2)',
    // States
    stateHoverNav: 'rgba(195, 205, 255, 0.25)',
    stateHoverPrimaryBg: '#F2f3f5',
    stateHoverPrimaryText: BLUE,
    stateHoverPrimaryIcon: BLUE,
    stateHoverSecondaryBg: '#f8f8f9',
    stateHoverSecondaryText: '#8797FF',
    stateHoverSurfaceBg: '#F2f3f5',
    stateFocusShadow: '0 0 0 3px rgba(3,102,214,0.3)',
    // Counter
    counterPrimaryText: '#fff',
    counterPrimaryBg: '#6a737d',
    // Tooltip
    tooltipText: '#fff',
    tooltipBg: '#24292e',
    // Input
    inputBg: '#fff',
    inputBorder: '#e1e4e8',
    inputDisabledBg: '#fff',
    inputDisabledBorder: '#e1e4e8',
    inputWarningBorder: '#f9c513',
    inputErrorBorder: RED_ORANGE_80,
    inputErrorColor: RED_ORANGE,
  },
  dark: {
    name: 'dark',
    // Base
    black: '#010409',
    white: '#f0f6fc',
    // Gray
    gray0: '#f0f6fc',
    gray1: '#c9d1d9',
    gray2: '#b1bac4',
    gray3: '#8b949e',
    gray4: '#6e7681',
    gray5: '#484f58',
    gray6: '#30363d',
    gray7: '#21262d',
    gray8: '#161b22',
    gray9: '#0d1117',
    // Background
    bgPrimary: '#18191a',
    bgTertiary: '#242526',
    bgSurface: '#242526',
    bgOverlay: '#252526',
    bgBackdrop: 'rgba(1,4,9,0.8)',
    // Bg Alert States
    bgInfo: 'rgba(56,139,253,0.1)',
    bgInfoInverse: BLUE_80,
    bgDanger: 'rgba(248,81,73,0.1)',
    bgDangerInverse: RED_ORANGE,
    bgSuccess: 'rgba(46,160,67,0.1)',
    bgSuccessInverse: '#2ea043',
    bgWarning: 'rgba(187,128,9,0.1)',
    bgWarningInverse: '#bb8009',
    // Text
    textPrimary: '#e4e6eb',
    textSecondary: '#b0b3b8',
    textTertiary: '#8b949e',
    textPlaceholder: '#8a8d91',
    textDisabled: 'rgba(255, 255, 255, 0.3)',
    textInverse: '#0d1117',
    // Text Alert States
    textLink: BLUE,
    textDanger: RED_ORANGE,
    textSuccess: '#56d364',
    textWarning: '#e3b341',
    textWhite: '#f0f6fc',
    // Border
    borderPrimary: '#30363d',
    borderSecondary: '#21262d',
    borderTertiary: '#6e7681',
    borderOverSurface: 'rgb(48 54 61 / 50%)',
    borderInfo: 'rgba(56,139,253,0.4)',
    borderDanger: 'rgba(248,81,73,0.4)',
    borderSuccess: 'rgba(63,185,80,0.4)',
    borderWarning: 'rgba(187,128,9,0.4)',
    // Icon
    iconPrimary: '#e4e6eb',
    iconSecondary: '#b0b3b8',
    iconTertiary: '#484f58',
    iconInfo: '#79c0ff',
    iconDanger: '#f85149',
    iconSuccess: '#56d364',
    iconWarning: '#e3b341',
    // Shadow
    shadowSmall: '0 0 transparent',
    shadowMedium: '0 1px 4px #010409',
    shadowLarge: '0 8px 24px #010409',
    shadowExtraLarge: '0 12px 48px #010409',
    shadowHighlight: '0 0 transparent',
    shadowInset: '0 0 transparent',
    // States
    stateHoverNav: 'rgba(195, 205, 255, 0.25)',
    stateHoverPrimaryBg: 'rgba(255, 255, 255, 0.1)',
    stateHoverPrimaryText: BLUE,
    stateHoverPrimaryIcon: BLUE,
    stateHoverSecondaryText: '#8797FF',
    stateHoverSecondaryBg: '#161b22',
    stateHoverSurfaceBg: '#3a3b3d',
    stateFocusShadow: '0 0 0 3px #0c2d6b',
    // Counter
    counterPrimaryText: '#c9d1d9',
    counterPrimaryBg: '#6e7681',
    // Tooltip
    tooltipText: '#f0f6fc',
    tooltipBg: '#21262d',
    // Input
    inputBg: '#0d1117',
    inputBorder: '#21262d',
    inputDisabledBg: '#161b22',
    inputDisabledBorder: '#30363d',
    inputWarningBorder: '#d29922',
    inputErrorBorder: RED_ORANGE_80,
    inputErrorColor: RED_ORANGE,
  },
} as const;
