import {gql} from '@apollo/client';

export default gql`
  mutation signMedia($input: SignMediaInput!) {
    authed {
      success
    }
    signMedia(input: $input) {
      success
      id
      signedUrl
      path
    }
  }
`;
