import {HeaderMap} from '../typedefs';

export class HTTPRequestHeaders {
  /**
   * The actual headers.
   */
  private _headers: HeaderMap = {};

  /**
   * The number of headers present.
   */
  private _count: number = 0;

  constructor(init?: HeaderMap) {
    this._setAll(init);
  }

  private _setAll(init?: HeaderMap): void {
    let count = 0;
    for (const key in init) {
      if (!init.hasOwnProperty(key) || !init[key]) {
        continue;
      }
      this._headers[key.toLowerCase()] = init[key]!;
      count++;
    }
    this._count = count;
  }

  get(name: string): string | null {
    return this._headers[name.toLowerCase()] || null;
  }

  has(name: string): boolean {
    return !!this._headers[name.toLowerCase()];
  }

  set(name: string, value: string): void {
    this._headers[name.toLowerCase()] = value;
    this._count++;
  }

  delete(name: string): void {
    const _name = name.toLowerCase();
    if (this._headers[_name]) {
      this._count--;
    }
    delete this._headers[name.toLowerCase()];
  }

  count(): number {
    return this._count;
  }

  toJSON(): HeaderMap {
    return {...this._headers};
  }
}
