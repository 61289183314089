import * as DOMPurify from 'dompurify';
import {createAuth} from '@spotify-internal/oauth-silent-authentication';

const prodClientId = '070be456ba814a8fac7936dfdd18ecea';
const clientId = process.env.CLIENT_ID || prodClientId;

export const auth = createAuth({
  // TODO: Move to a config file
  clientId,
  scopes: [
    'app-remote-control',
    'playlist-modify',
    'playlist-modify-private',
    'playlist-modify-public',
    'playlist-read',
    'playlist-read-collaborative',
    'playlist-read-private',
    'streaming',
    'ugc-image-upload',
    'user-follow-modify',
    'user-follow-read',
    'user-library-modify',
    'user-library-read',
    'user-modify',
    'user-modify-playback-state',
    'user-modify-private',
    'user-read-currently-playing',
    'user-read-email',
    'user-read-playback-position',
    'user-read-playback-state',
    'user-read-private',
    'user-read-recently-played',
    'user-top-read',
  ],
  acrs: [],
});

type LoginParams =
  | {to: string; history: any; alreadyRetried?: boolean}
  | {to?: never; history?: never; alreadyRetried?: boolean};

export const loginViaSpotify = async (
  {to, alreadyRetried, history}: LoginParams = {
    alreadyRetried: false,
  }
) => {
  try {
    const accessToken = await auth.getToken();
    localStorage.setItem('adanalytics-token', accessToken);
    if (to) {
      const decodedTo = decodeURIComponent(DOMPurify.sanitize(to));

      // Check for invalid or disallowed redirect patterns
      // Ensure the redirect is a valid, relative URL and not a dangerous protocol like javascript:
      const urlPattern = /.*(?:javascript|data|mailto|file|ftp|tel).*/i;
      if (urlPattern.test(decodedTo)) {
        console.error('Invalid or unsafe redirect URL detected.');
        return;
      }

      if (
        decodedTo.includes('http://') ||
        decodedTo.includes('https://') ||
        decodedTo.includes('//')
      ) {
        console.error('Only relative URLs are allowed for redirection.');
        return;
      }
      history.push(decodedTo);
    } else {
      window.location.href = window.location.origin;
    }
  } catch (error) {
    console.error('Login Failed', error);
    if (error.error === 'login_required') {
      let redirectUri = `https://adanalytics.spotify.com/${
        to ? 'to' : 'redirect'
      }`;
      const host = window.location.host;
      const subdomain = host.split('.')[0];
      if (subdomain === 'admin') {
        redirectUri = `https://admin.podsights.com/${to ? 'to' : 'redirect'}`;
      }

      if (process.env.API_ENV === 'dev' || process.env.AUTH_ENV === 'dev') {
        redirectUri = `https://local.spotify.net/${to ? 'to' : 'redirect'}`;
      }
      auth.login({redirectUri, state: to});
      if (!alreadyRetried) {
        loginViaSpotify({to, alreadyRetried: true, history});
      }
    }
  }
};

export const logoutViaSpotify = () => {
  localStorage.removeItem('adanalytics-token');
  if (process.env.API_ENV === 'dev') {
    auth.logout('https://local.spotify.net/welcome');
  } else {
    auth.logout('https://adanalytics.spotify.com/welcome');
  }
};
