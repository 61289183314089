import dayjs from '@core/lib/dayjs';
import {
  DataTableColumn,
  DataTableValuesMap,
  Interval,
} from '@core/ui/DataTable/types';

export function getMinMaxDates({
  data,
  endAccessor,
  startAccessor,
}: {
  data?: any[];
  endAccessor: string;
  startAccessor: string;
}): Interval<dayjs.Dayjs> {
  const defaultRange: Interval<dayjs.Dayjs> = [dayjs(), dayjs()];

  if (!data) return defaultRange;

  return data.reduce((acc, current) => {
    const startDate = dayjs(current[startAccessor]);
    const endDate = dayjs(current[endAccessor]);

    if (startDate.isBefore(acc[0]) && startDate.isSameOrAfter('2015', 'year'))
      acc[0] = startDate;

    if (endDate.isAfter(acc[1])) acc[1] = endDate;

    return acc;
  }, defaultRange.slice(0));
}

export const getMinMax = ({
  column,
  data,
  valuesMap,
}: {
  column: DataTableColumn;
  data: any[];
  valuesMap: DataTableValuesMap;
}): Interval =>
  data.reduce(
    (acc, d) => {
      const value = valuesMap.get(column)?.get(d);
      if (value < acc[0]) acc[0] = value;
      else if (value > acc[1]) acc[1] = value;
      return acc;
    },
    [0, 0]
  );

export const getItems = ({
  column,
  data,
  valuesMap,
}: {
  column: DataTableColumn;
  data: any[];
  valuesMap: DataTableValuesMap;
}) => {
  const values = [
    ...new Set(
      data
        .map((d) => valuesMap.get(column)?.get(d))
        .flat(10)
        .map((str) => (typeof str === 'string' ? str.toLowerCase() : str))
    ),
  ];

  return values.map((value: unknown) => ({
    title:
      typeof value === 'string' && value.length > 1
        ? value.charAt(0).toUpperCase() + value.toLowerCase().slice(1)
        : value,
    value,
  }));
};
