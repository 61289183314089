import {gql} from '@apollo/client';

export default gql`
  query getReviewerDetail($id: ID!) {
    admin {
      reviewer(id: $id) {
        id
        firstName
        lastName
        email

        totalEpisodeMinutes
        totalEpisodeCount

        episodes {
          id
          title
          image {
            path
          }
          feed {
            image {
              path
            }
          }
          enclosure {
            length
          }
          hasNoAds

          reviews(reviewerId: $id, includeAdReviews: false) {
            hasNoAds
            duration

            createdAt
            updatedAt
          }

          placements(reviewerId: $id) {
            id
            startTime
            endTime
            ad {
              id
              duration
              reviews(reviewerId: $id) {
                deltas

                startState
                endState

                companyId
                sponsorId
                productId

                hasNoAds

                mturkAssignmentId
                mturkHitId

                duration

                createdAt
                updatedAt
              }
            }
          }
        }
      }
    }
  }
`;
