import {PaletteOptions, ThemeOptions} from '@mui/material';

// css variables is still under development
// https://mui.com/material-ui/experimental-api/css-variables/#cssvarsprovider-gt-props

export const palette: ThemeOptions['palette'] = {
  primary: {
    // For rebrand
    main: '#a1258a',
    light: '#a1258a',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#16c784',
    light: '#a2e9ce',
    contrastText: '#ffffff',
  },
  error: {
    main: '#ff4632',
    dark: '#000',
    light: '#000',
    contrastText: '#000',
  },
  warning: {
    main: '#ff9f1a',
    dark: '#000',
    light: '#000',
    contrastText: '#000',
  },
  /*   warning: {
    main: '#000',
    contrastText: '#000',
    light: '#000',
  },
  info: {
    main: '#0366d6',
    contrastText: '#fff',
  },
  success: {
    main: '#16c784',
    contrastText: '#fff',
  }, */
};

export const light = {
  palette: {
    ...palette,
    mode: 'light',
    text: {
      primary: '#24292E',
      secondary: '#6A737D',
      disabled: 'rgba(0, 0, 0, 0.38)', // or lower opacity
    },
  } as PaletteOptions,
};

export const dark = {
  palette: {
    ...palette,
    mode: 'dark',
    text: {
      primary: '#e4e6eb',
      secondary: '#8b949e',
      disabled: 'rgba(255,255,255,0.3)',
    },
  } as PaletteOptions,
};
