/**
 * The events that are exposed by the library.
 */
export enum DealerEvent {
  // Dealer Events

  /**
   * Emitted by the `Dealer` class when it receives a Connection ID.
   */
  AUTHENTICATED = 'authenticated',

  /**
   * Emitted by the `Dealer` class when the Dealer service encounters an error
   * during authentication.
   */
  AUTHENTICATION_FAILED = 'authentication_failed',

  /**
   * Emitted by the `Dealer` class when it has successfully connected.
   */
  CONNECTED = 'connected',

  /**
   * Emitted by the `Dealer` class when it receives a connection id from the
   * Dealer service.
   */
  CONNECTION_ID = 'connection_id',

  /**
   * Emitted by the `Dealer` class when it encounters an error.
   */
  ERROR = 'error',

  /**
   * Emitted by the `Dealer` class when it gets disconnected.
   */
  DISCONNECTED = 'disconnected',

  /*
   * Emitted by the `Dealer` class when it receives a message from the Dealer
   * service.
   */
  MESSAGE = 'message',

  /**
   * Emitted by the `Dealer` class when it receives a request from the Dealer service.
   */
  REQUEST = 'request',
}
