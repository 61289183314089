import React, {FC} from 'react';
import {TRules, css} from '@core/style';
import {SearchInput} from '../FormElements';

interface DataTableSearchProps {
  autofocus?: boolean;
  onChange?: (_event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  rules?: TRules;
}

const DataTableSearch: FC<DataTableSearchProps> = ({
  autofocus = false,
  onChange,
  placeholder,
  rules,
}): JSX.Element => (
  <div
    {...css([
      () => ({
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
      }),
      rules,
    ])}>
    <SearchInput
      onChange={onChange}
      placeholder={placeholder}
      autofocus={autofocus}
      rules={() => ({flex: '1', marginBottom: '0'})}
    />
  </div>
);

export default DataTableSearch;
