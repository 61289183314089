import React, {HTMLProps, useRef, useState} from 'react';
import Button from '../Button';

export interface FileInputProps extends HTMLProps<HTMLInputElement> {
  children: React.ReactNode;
  invalid?: boolean;
  small?: boolean;
}

const FileInput = ({
  children,
  invalid = false,
  onChange,
  ...props
}: FileInputProps) => {
  const [selectedFiles, setSelectedFiles] = useState<FileList | []>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        ref={inputRef}
        type='file'
        style={{display: 'none'}}
        onChange={(evt) => {
          evt.stopPropagation();
          onChange?.(evt);
          setSelectedFiles(evt.target.files ?? []);
        }}
        {...props}
      />
      <Button
        type='button'
        color={selectedFiles.length ? 'primary' : 'inverse'}
        onClick={() => inputRef.current?.click()}
        css={`
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          ${invalid &&
          `
            background: var(--red-orange);
            color: var(--white);
          `}
        `}>
        {selectedFiles.length > 0 ? selectedFiles[0].name : children}
      </Button>
    </>
  );
};

export default FileInput;
