import Button, {ButtonProps} from '../Button';
import {LoadingSpinner} from '../Loading';

export interface SubmitProps extends ButtonProps {
  loading?: boolean;
  value?: string;
}

export default function Submit({
  children,
  loading,
  color = 'secondary',
  value,
  ...props
}: SubmitProps) {
  return (
    <Button
      type='submit'
      color={color}
      css={`
        position: relative;
      `}
      {...(props as any)}>
      {loading && (
        <LoadingSpinner
          size='small'
          color='var(--white)'
          css={`
            align-items: center;
            inset: 0;
            justify-content: center;
            margin: auto;
            position: absolute;
          `}
        />
      )}
      <span css={{opacity: loading ? 0 : undefined}}>{children || value}</span>
    </Button>
  );
}
