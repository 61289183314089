export {
  default,
  logger,
  log,
  error,
  warn,
  info,
  debug,
  verbose,
  silly,
} from './logger.browser';
