import React, {HTMLAttributes} from 'react';
import {TRules, css} from '@core/style';
import {icons} from './icons';

export type IconName = keyof typeof icons;

export interface IconProps extends HTMLAttributes<HTMLOrSVGElement> {
  icon: IconName;
  rules?: TRules;
}

export const Icon = ({icon, rules, ...props}: IconProps) => {
  return React.createElement('svg', {
    ...css([() => ({display: 'inline-flex'}), rules]),
    xmlns: 'http://www.w3.org/2000/svg',
    ...(icons[icon]?.props ?? {}),
    ...props,
  });
};
