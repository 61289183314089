import {gql} from '@apollo/client';

export default gql`
  query getReviews {
    admin {
      reviews {
        id
        episode {
          id
          title
        }
        ad {
          id
        }

        startState
        endState

        duration
        createdAt

        reviewer {
          firstName
          lastName
          email
        }
      }
    }
  }
`;
