import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cdf564;
  flex: 1;
  height: 100%;
  padding: 80px 140px;
  @media (max-width: 1600px) {
    padding: 80px 40px;
  }
`;

const ResponsiveContainer = styled.div`
  height: 100%;
  width: 50%;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const SideGraphic = () => (
  <ResponsiveContainer>
    <Container>
      <svg
        width='501'
        height='452'
        viewBox='0 0 501 452'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M40.6938 387.65L205.854 198.708L279.765 264.66L467.905 33.0004'
          stroke='url(#paint0_linear_4096_58834)'
          strokeWidth='65'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M429 416C429 412.776 425.793 409.854 420.461 407.571C411.777 403.843 404.823 396.993 401.076 388.429C398.769 383.19 395.779 380 392.5 380C389.221 380 386.267 383.157 383.924 388.429C380.141 396.993 373.223 403.843 364.539 407.571C359.207 409.854 356 412.81 356 416C356 419.19 359.207 422.146 364.539 424.429C373.223 428.157 380.177 435.007 383.924 443.571C386.231 448.81 389.221 452 392.5 452C395.779 452 398.733 448.843 401.076 443.571C404.859 435.007 411.777 428.157 420.461 424.429C425.793 422.146 429 419.19 429 416Z'
          fill='url(#paint1_linear_4096_58834)'
        />
        <path
          d='M121 61C121 55.6269 115.685 50.7575 106.845 46.9515C92.4521 40.7388 80.9254 29.3209 74.7142 15.0486C70.8919 6.31722 65.9348 1 60.5 1C55.0652 1 50.1678 6.26125 46.2858 15.0486C40.0148 29.3209 28.5479 40.7388 14.1545 46.9515C5.31539 50.7575 0 55.6828 0 61C0 66.3172 5.31539 71.2425 14.1545 75.0485C28.5479 81.2612 40.0745 92.6791 46.2858 106.952C50.1081 115.683 55.0652 121 60.5 121C65.9348 121 70.8322 115.739 74.7142 106.952C80.9852 92.6791 92.4521 81.2612 106.845 75.0485C115.685 71.2425 121 66.3172 121 61Z'
          fill='url(#paint2_linear_4096_58834)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_4096_58834'
            x1='260.036'
            y1='32.1443'
            x2='273.216'
            y2='383.224'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='white' />
            <stop offset='1' stopColor='white' stopOpacity='0' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_4096_58834'
            x1='392.5'
            y1='380'
            x2='392.5'
            y2='452'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='white' stopOpacity='0.78' />
            <stop offset='1' stopColor='white' stopOpacity='0.26' />
          </linearGradient>
          <linearGradient
            id='paint2_linear_4096_58834'
            x1='60.5'
            y1='1'
            x2='60.5'
            y2='121'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='white' stopOpacity='0.78' />
            <stop offset='1' stopColor='white' stopOpacity='0.26' />
          </linearGradient>
        </defs>
      </svg>
    </Container>
  </ResponsiveContainer>
);

export default SideGraphic;
