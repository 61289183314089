import {useEffect, useRef} from 'react';
import {exists} from '@core/lib/utils';
import {MultipleSelect, MultipleSelectProps} from './';

export interface SelectProps
  extends Omit<MultipleSelectProps, 'defaultValue' | 'onSelect'> {
  defaultValue?: string;
  onSelect?: (_value: any) => void;
}

export default function Select({
  defaultValue,
  items,
  onSelect,
  placeholder = 'Select...',
  propertyForValue = 'value',
  removeOption,
  ...multipleSelectProps
}: SelectProps) {
  const firstUpdated = useRef<boolean>(false);

  useEffect(() => {
    firstUpdated.current = true;
  }, []);

  return (
    <MultipleSelect
      singleSelection
      items={items}
      placeholder={placeholder}
      propertyForValue={propertyForValue}
      removeOption={removeOption}
      defaultValue={exists(defaultValue) ? [defaultValue] : []}
      onSelect={(selectedItems) => {
        if (firstUpdated.current) {
          onSelect?.(selectedItems.length ? selectedItems[0] : null);
        }
      }}
      {...multipleSelectProps}
    />
  );
}
