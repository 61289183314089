import {
  Autocomplete as MuiAutocomplete,
  type AutocompleteProps as MuiAutocompleteProps,
  ThemeOptions,
} from '@mui/material';
import {Icon} from '../Icon';
import {
  defaultGetOptionLabel,
  defaultRenderInput,
  defaultRenderOption,
  defaultRenderTags,
} from './helpers';

type MuiAutocompletePartialProps<
  T,
  Multiple extends boolean | undefined = false
> = MuiAutocompleteProps<T, Multiple, false, false, 'div'>;

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined = false
> extends Omit<MuiAutocompletePartialProps<T, Multiple>, 'renderInput'> {
  renderInput?: MuiAutocompletePartialProps<T>['renderInput'];
  label?: string;
}

export function Autocomplete<
  T extends string | Record<string, any> = {label: string; value: string},
  Multiple extends boolean | undefined = false
>({
  getOptionLabel = defaultGetOptionLabel,
  label,
  placeholder,
  renderInput = defaultRenderInput(label, placeholder),
  renderOption = defaultRenderOption<T>(getOptionLabel),
  renderTags = defaultRenderTags(getOptionLabel),
  ...props
}: AutocompleteProps<T, Multiple>) {
  return (
    <MuiAutocomplete
      {...props}
      renderInput={renderInput}
      renderOption={renderOption}
      renderTags={renderTags}
    />
  );
}

export const MuiAutocompleteStyles: ThemeOptions['components'] = {
  MuiAutocomplete: {
    defaultProps: {
      clearOnBlur: true,
      disablePortal: true,
      fullWidth: false,
      handleHomeEndKeys: true,
      popupIcon: <Icon icon='chevron-down-small' />,
    },
    styleOverrides: {
      listbox: {
        padding: '1rem 0.75rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        backgroundColor: 'var(--bg-default)',
        '& .MuiListItem-root.MuiListItem-padding': {
          paddingLeft: '0.75rem',
          paddingRight: '0.75rem',
        },
      },
      option: {
        '&.MuiListItem-root': {
          minHeight: '3rem',
          backgroundColor: 'transparent',
          border: '2px solid transparent',
          borderRadius: '0.375rem',
          padding: '0.375rem 1rem',
          transition:
            'background-color 150ms cubic-bezier(0, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0, 0, 0.2, 1) 0ms',
          whiteSpace: 'pre-wrap',
          display: 'flex',
          justifyContent: 'space-between',
          '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
            color: 'var(--blue)',
          },
          '&:hover, &.Mui-focused, &.Mui-focusVisible': {
            backgroundColor: 'transparent',
            border: '2px solid var(--blue)',
            color: 'var(--blue)',
          },
          '&[aria-selected="true"]:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      paper: {
        borderRadius: '0.5rem',
        border: '1px solid var(--border-default)',
        boxShadow: 'none',
      },
      popper: {
        borderRadius: '0.5rem',
        boxShadow: 'var(--shadow-xl)',
        marginTop: '4px',
      },
      popupIndicator: {
        color: 'var(--blue)',
      },
      root: {
        '& .MuiOutlinedInput-root.MuiInputBase-root': {
          paddingTop: '16.5px',
        },
        '&:hover': {
          borderColor: 'var(--border-default)',
        },
        '& .MuiOutlinedInput-input.MuiInputBase-input': {
          paddingBottom: 0,
        },
      },
    },
  },
};
