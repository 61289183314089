import {ProgressBar} from '@spotify-internal/encore-web';
import styled from 'styled-components';

interface SpotifyAdvertisingHeaderProps {
  progress?: number;
  position?: 'center' | 'flex-start';
}

const SpotifyAdvertisingHeader = styled.div<SpotifyAdvertisingHeaderProps>`
  display: flex;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  justify-content: ${(props) => props.position ?? 'center'};
  padding-left: ${(props) => (props.position === 'flex-start' ? '45px' : '0')};
`;

const SpotifyAdvertisingSvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='263'
    height='39'
    viewBox='0 0 160 24'
    fill='none'>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M82.922 14.08h5.32l1.382 3.438h1.067L86.186 6.39h-1.192l-4.505 11.128h1.067l1.366-3.437Zm4.96-.91h-4.6l2.292-5.791 2.308 5.792ZM94.515 17.742a3.046 3.046 0 0 0 2.883-1.846v.328c-.002.433.026.865.086 1.294h1.019a11.614 11.614 0 0 1-.087-1.57V5.838h-1.035v4.999c-.294-.708-1.105-1.778-2.866-1.778-2.279 0-3.798 1.916-3.798 4.332 0 2.452 1.485 4.35 3.798 4.35Zm2.883-4.35c0 1.934-1.088 3.418-2.797 3.418-1.743 0-2.848-1.484-2.848-3.418 0-1.985 1.14-3.4 2.865-3.4 1.692 0 2.78 1.45 2.78 3.4Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      d='M103.724 17.518h-1.088l-3.263-8.234h1.174l2.624 7.06 2.659-7.06h1.156l-3.262 8.234Z'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M111.083 17.742a3.668 3.668 0 0 0 3.608-2.554l-.915-.328a2.662 2.662 0 0 1-2.693 1.95c-1.622 0-2.934-1.242-2.952-3.141v-.07h6.594c.015-.126.02-.252.018-.38-.018-2.433-1.416-4.16-3.85-4.16-2.054 0-3.815 1.727-3.815 4.316 0 2.762 1.83 4.367 4.005 4.367Zm-.172-7.75c1.726 0 2.71 1.208 2.745 2.744h-5.49c.104-1.519 1.208-2.744 2.745-2.744Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      d='M120.247 10.354a2.878 2.878 0 0 0-.725-.086c-1.398 0-2.434.897-2.434 2.78v4.47h-1.036V9.284h1.019v1.536a2.622 2.622 0 0 1 2.537-1.64c.217-.006.432.023.639.087v1.087ZM123.775 9.284h2.141v.932h-2.141v4.972c0 1.001.466 1.484 1.398 1.484.254.001.506-.046.743-.138v.915a2.724 2.724 0 0 1-1.019.156c-1.329 0-2.158-.812-2.158-2.313v-5.076h-1.743v-.932h.621c.812 0 1.157-.363 1.157-1.209V6.677h1.001v2.607Z'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M128.155 6.038a.864.864 0 0 0-.798-.533.845.845 0 0 0-.863.863.864.864 0 1 0 1.661-.33Zm-1.316 3.246v8.234h1.036V9.284h-1.036Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      d='M129.985 15.032c.156 1.002.915 1.813 2.262 1.813 1.018 0 1.829-.604 1.829-1.467 0-.76-.518-1.174-1.398-1.381l-1.329-.311c-1.208-.276-2.02-1.001-2.02-2.192 0-1.33 1.278-2.417 2.779-2.417 1.917 0 2.728 1.14 2.952 2.123l-.915.346c-.155-.795-.725-1.572-2.037-1.572-.932 0-1.76.588-1.76 1.45 0 .709.448 1.123 1.294 1.312l1.312.294c1.364.31 2.141 1.087 2.141 2.279 0 1.225-1.036 2.434-2.866 2.434-2.019 0-3.038-1.295-3.193-2.382l.949-.329Z'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M137.726 6.038a.863.863 0 0 0-.798-.533.841.841 0 0 0-.862.863.863.863 0 1 0 1.66-.33Zm-1.315 3.246v8.234h1.036V9.284h-1.036Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      d='M140.271 17.518h-1.036V9.284h1.001v1.312a2.916 2.916 0 0 1 2.676-1.52c1.934 0 2.917 1.382 2.917 3.074v5.368h-1.035v-5.196c0-1.294-.639-2.313-2.227-2.313-1.416 0-2.296 1.191-2.296 2.59v4.92Z'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M150.491 20.108a2.609 2.609 0 0 1-2.641-2.366l-.984.242c.207 1.726 1.605 3.038 3.608 3.038 3.038 0 3.884-2.192 3.884-4.108v-7.63h-1.019V10.7c-.414-.846-1.26-1.606-2.796-1.606-2.192 0-3.677 1.744-3.677 3.988 0 2.295 1.519 4.005 3.677 4.005 1.502 0 2.417-.83 2.779-1.675v1.519c0 1.709-.656 3.177-2.831 3.177Zm.173-10.116c1.622 0 2.692 1.311 2.692 3.09 0 1.795-1.07 3.107-2.692 3.107-1.657 0-2.762-1.312-2.762-3.107 0-1.796 1.122-3.09 2.762-3.09ZM.91 7.384A11.96 11.96 0 0 1 11.962 0c6.606 0 11.96 5.355 11.96 11.96A11.96 11.96 0 1 1 .912 7.385Zm16.075 10.204a.745.745 0 0 0 .214-1.362c-3.153-1.926-7.06-2.374-11.617-1.333a.745.745 0 1 0 .332 1.453c4.163-.95 7.699-.563 10.507 1.154a.745.745 0 0 0 .564.088Zm1.925-3.593a.933.933 0 0 1-1.283.307c-3.214-1.976-8.115-2.548-11.918-1.394a.932.932 0 1 1-.542-1.784c4.344-1.319 9.745-.68 13.436 1.589.439.27.576.843.307 1.282ZM5.14 9.22c3.681-1.117 10.04-.906 13.896 1.383a1.119 1.119 0 0 0 1.142-1.924C15.74 6.045 8.716 5.797 4.491 7.08a1.119 1.119 0 1 0 .65 2.141Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      d='M32.541 11.048c-2.065-.492-2.432-.838-2.432-1.564 0-.686.646-1.148 1.606-1.148.932 0 1.855.35 2.823 1.072a.135.135 0 0 0 .191-.03l1.009-1.421a.135.135 0 0 0-.026-.184 6.1 6.1 0 0 0-3.966-1.374c-2.23 0-3.787 1.338-3.787 3.253 0 2.053 1.343 2.78 3.665 3.34 1.976.456 2.31.837 2.31 1.52 0 .755-.675 1.224-1.76 1.224-1.206 0-2.189-.406-3.289-1.359a.142.142 0 0 0-.099-.032.134.134 0 0 0-.093.048l-1.13 1.345a.134.134 0 0 0 .013.188 6.74 6.74 0 0 0 4.552 1.746c2.403 0 3.956-1.313 3.956-3.345 0-1.717-1.027-2.667-3.543-3.279Z'
    />
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M38.92 10.262a3.24 3.24 0 0 1 2.6-1.25c1.935 0 3.894 1.49 3.894 4.338 0 2.847-1.96 4.337-3.895 4.337-1 .02-1.954-.413-2.6-1.177v3.318c0 .075-.06.136-.135.136h-1.849a.135.135 0 0 1-.135-.136V9.316c0-.075.06-.135.135-.135h1.85c.074 0 .134.06.134.135v.946Zm2.171 5.55c1.28 0 2.173-1.012 2.173-2.462 0-1.427-.914-2.462-2.173-2.462-1.264 0-2.218 1.058-2.218 2.462 0 1.404.954 2.462 2.218 2.462ZM46.17 13.415a4.347 4.347 0 0 1 4.265-4.404 4.36 4.36 0 0 1 4.429 4.339 4.347 4.347 0 0 1-8.694.065Zm1.94-.065c0 1.412 1 2.477 2.325 2.477 1.338 0 2.31-1.028 2.31-2.446 0-1.413-1.006-2.479-2.34-2.479-1.33 0-2.295 1.03-2.295 2.448Z'
      clipRule='evenodd'
    />
    <path
      fill='currentColor'
      d='M60.186 9.18h-2.035V7.1a.135.135 0 0 0-.135-.135h-1.849a.136.136 0 0 0-.135.135v2.08h-.89a.135.135 0 0 0-.134.136v1.59c0 .074.06.134.135.135h.889v4.112c0 1.662.827 2.504 2.458 2.504a3.373 3.373 0 0 0 1.732-.43.135.135 0 0 0 .069-.118v-1.513a.136.136 0 0 0-.196-.122 2.331 2.331 0 0 1-1.086.262c-.593 0-.858-.27-.858-.873v-3.822h2.035a.135.135 0 0 0 .135-.136V9.316a.135.135 0 0 0-.135-.135ZM67.275 9.189v-.256c0-.752.289-1.087.935-1.087.356.004.708.07 1.042.192a.135.135 0 0 0 .178-.128V6.35a.135.135 0 0 0-.096-.13 5.076 5.076 0 0 0-1.537-.22c-1.708 0-2.61.962-2.61 2.78v.391h-.889a.136.136 0 0 0-.135.136v1.597c0 .075.06.136.135.136h.889v6.343a.135.135 0 0 0 .134.135h1.85c.074 0 .135-.06.135-.135V11.04h1.727l2.644 6.34c-.3.667-.595.8-.998.8a2.134 2.134 0 0 1-1.02-.29.139.139 0 0 0-.108-.01.136.136 0 0 0-.08.073l-.626 1.375a.134.134 0 0 0 .058.175c.602.341 1.283.516 1.975.506 1.365 0 2.12-.636 2.785-2.347l3.208-8.29a.135.135 0 0 0-.125-.184H74.82a.136.136 0 0 0-.129.09l-1.971 5.633-2.16-5.636a.135.135 0 0 0-.127-.087h-3.159Z'
    />
    <rect
      width='2.12'
      height='8.338'
      x='61.181'
      y='9.18'
      fill='#000'
      rx='.258'
    />
    <path
      fill='#000'
      d='M62.25 5.507a1.326 1.326 0 1 0 0 2.653 1.326 1.326 0 0 0 0-2.653Z'
    />
    <path
      fill='#000'
      fillRule='evenodd'
      d='M75.64 16.21c0 .72.582 1.304 1.302 1.306a1.296 1.296 0 0 0 1.308-1.31 1.305 1.305 0 0 0-2.61.004Zm.216-.456c.185-.439.617-.723 1.093-.718a1.164 1.164 0 0 1 1.165 1.17 1.175 1.175 0 1 1-2.258-.451Zm1.382.587.368.515h-.31l-.332-.473h-.285v.473h-.26V15.49h.61c.317 0 .526.163.526.436a.408.408 0 0 1-.318.415Zm-.56-.617h.34c.169 0 .27.076.27.216 0 .134-.101.217-.27.217h-.34v-.432Z'
      clipRule='evenodd'
    />
  </svg>
);

const SpotifySvg = (
  <svg
    width='158'
    height='48'
    viewBox='0 0 79 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M75.6406 16.2102C75.6402 16.9299 76.2225 17.514 76.9421 17.5158C77.2902 17.5191 77.6249 17.3822 77.8709 17.1359C78.117 16.8897 78.2537 16.5549 78.2502 16.2069C78.2479 15.4872 77.6634 14.9053 76.9437 14.9063C76.2241 14.9072 75.6411 15.4906 75.6406 16.2102ZM75.8552 15.7548C76.0401 15.3156 76.472 15.0316 76.9486 15.0359C77.2587 15.0356 77.5561 15.1592 77.7747 15.3791C77.9934 15.599 78.1152 15.8971 78.1131 16.2072C78.1146 16.6837 77.8281 17.114 77.3879 17.2964C76.9477 17.4788 76.4408 17.3773 76.1048 17.0393C75.7688 16.7014 75.6702 16.194 75.8552 15.7548ZM77.2375 16.3408L77.6057 16.8562H77.2953L76.964 16.3835H76.6791V16.8562H76.4193V15.4902H77.0284C77.3457 15.4902 77.5545 15.6526 77.5545 15.9259C77.563 16.1226 77.4296 16.2973 77.2375 16.3408ZM76.6784 15.7246H77.0172H77.0174C77.1865 15.7246 77.2874 15.7999 77.2872 15.9406C77.2872 16.074 77.1864 16.1567 77.0172 16.1567H76.6784V15.7246Z'
      fill='black'
    />
    <path
      d='M62.2501 5.50684C61.7136 5.50681 61.23 5.82996 61.0248 6.32557C60.8195 6.82118 60.9331 7.39163 61.3125 7.77088C61.6918 8.15012 62.2623 8.26346 62.7579 8.05803C63.2534 7.85261 63.5764 7.36888 63.5762 6.83245C63.5758 6.10031 62.9822 5.50696 62.2501 5.50684V5.50684Z'
      fill='black'
    />
    <rect
      x='61.1816'
      y='9.18066'
      width='2.1201'
      height='8.33846'
      rx='0.258116'
      fill='black'
    />
    <path
      d='M67.275 9.18915V8.93363C67.275 8.1819 67.5632 7.84671 68.2097 7.84671C68.5654 7.85097 68.9178 7.91599 69.2516 8.03897C69.2929 8.05271 69.3383 8.04573 69.3735 8.02021C69.4088 7.9947 69.4296 7.95379 69.4294 7.91027V6.35193C69.4296 6.29234 69.3908 6.23964 69.3338 6.22217C68.8359 6.07014 68.3175 5.99564 67.7969 6.00127C66.0889 6.00127 65.1862 6.96314 65.1862 8.78167V9.17301H64.2978C64.223 9.17313 64.1624 9.23366 64.1621 9.30846V10.9058C64.1624 10.9806 64.223 11.0411 64.2978 11.0412H65.1862V17.3841C65.1861 17.4199 65.2002 17.4543 65.2255 17.4797C65.2508 17.5051 65.2851 17.5194 65.321 17.5195H67.1701C67.2449 17.5194 67.3055 17.4588 67.3056 17.3841V11.0412H69.032L71.6768 17.3824C71.3765 18.0487 71.0813 18.1812 70.6782 18.1812C70.3194 18.1721 69.9688 18.0727 69.6586 17.892C69.6256 17.8742 69.5867 17.8706 69.5509 17.8819C69.5153 17.8942 69.4864 17.9206 69.471 17.9549L68.8442 19.3298C68.8142 19.3943 68.8398 19.471 68.9024 19.5047C69.5041 19.8456 70.1858 20.0202 70.8772 20.0103C72.2427 20.0103 72.9973 19.3743 73.6627 17.6632L76.8707 9.37372C76.8871 9.33216 76.8819 9.28516 76.8568 9.2482C76.8316 9.21125 76.7898 9.18914 76.7451 9.18915H74.8202C74.7625 9.18916 74.7112 9.22555 74.6921 9.27993L72.72 14.9126L70.5601 9.27622C70.5402 9.22378 70.4899 9.18911 70.4338 9.18915H67.275Z'
      fill='black'
    />
    <path
      d='M60.1859 9.18075H58.1511V7.10029C58.1512 7.02563 58.0908 6.965 58.0161 6.96484H56.1672C56.0924 6.9649 56.0318 7.02548 56.0316 7.10029V9.18075H55.1425C55.0679 9.18102 55.0076 9.24165 55.0078 9.31623V10.9054C55.0076 10.98 55.0679 11.0406 55.1425 11.0409H56.0316V15.153C56.0316 16.8148 56.8587 17.6574 58.49 17.6574C59.0956 17.6719 59.694 17.523 60.2222 17.2263C60.2644 17.2024 60.2905 17.1576 60.2905 17.1091V15.5957C60.2905 15.5488 60.2662 15.5052 60.2263 15.4805C60.1864 15.4558 60.1366 15.4534 60.0946 15.4743C59.7593 15.6483 59.3868 15.7382 59.0091 15.7363C58.4159 15.7363 58.1511 15.4669 58.1511 14.8632V11.0409H60.186C60.2218 11.0408 60.2562 11.0265 60.2815 11.0011C60.3068 10.9757 60.321 10.9413 60.3209 10.9054V9.31623C60.321 9.28036 60.3068 9.24593 60.2815 9.22052C60.2562 9.19511 60.2218 9.18081 60.1859 9.18075Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M46.1723 13.4157C46.1408 11.0247 48.0463 9.05728 50.437 9.01226C51.6015 8.99398 52.7248 9.44227 53.5568 10.2572C54.3887 11.0721 54.8601 12.186 54.8659 13.3506C54.8702 15.7417 52.9425 17.6874 50.5514 17.7053C48.1603 17.7232 46.2037 15.8066 46.1723 13.4157ZM48.112 13.3508C48.112 14.7629 49.1113 15.828 50.437 15.828V15.8281C51.7753 15.8281 52.7466 14.7996 52.7466 13.3818C52.7466 11.9687 51.7405 10.9036 50.4064 10.9036C49.0768 10.9036 48.112 11.9328 48.112 13.3508Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M38.92 10.2627C39.543 9.46159 40.5054 8.99868 41.5202 9.012C43.4554 9.012 45.4143 10.502 45.4143 13.3503C45.4143 16.198 43.4554 17.6876 41.5202 17.6876C40.5205 17.7067 39.5654 17.2744 38.92 16.5107V19.8289C38.92 19.9035 38.8597 19.9641 38.785 19.9644H36.9359C36.8612 19.9643 36.8007 19.9036 36.8008 19.8289V9.31661C36.8007 9.24191 36.8612 9.18129 36.9359 9.18113H38.785C38.8597 9.18141 38.92 9.24198 38.92 9.31661V10.2627ZM41.092 15.8126C42.3709 15.8126 43.2642 14.8007 43.2642 13.3506C43.2642 11.9238 42.3503 10.8887 41.092 10.8887C39.8276 10.8887 38.8737 11.9465 38.8737 13.3506C38.8737 14.7548 39.8276 15.8126 41.092 15.8126Z'
      fill='black'
    />
    <path
      d='M32.5419 11.0477C30.4769 10.5552 30.1092 10.2096 30.1092 9.48339C30.1092 8.79717 30.7552 8.33554 31.7159 8.33554C32.6472 8.33554 33.5704 8.6862 34.5386 9.40806C34.5679 9.42977 34.6047 9.43875 34.6407 9.43295C34.6768 9.42753 34.709 9.40759 34.73 9.37776L35.7384 7.95635C35.7798 7.89787 35.7685 7.81725 35.7126 7.7724C34.5947 6.85971 33.1894 6.37288 31.7465 6.39842C29.5166 6.39842 27.9592 7.73649 27.9592 9.65111C27.9592 11.7043 29.3027 12.4312 31.6245 12.9924C33.6006 13.4475 33.9342 13.8288 33.9342 14.5106C33.9342 15.2661 33.2598 15.7357 32.1745 15.7357C30.9691 15.7357 29.9858 15.3296 28.8859 14.3771C28.8581 14.3541 28.8226 14.3426 28.7866 14.3448C28.7505 14.3476 28.7171 14.3648 28.6938 14.3926L27.5632 15.738C27.5158 15.7938 27.5218 15.8772 27.5766 15.9256C28.823 17.0552 30.4467 17.678 32.1287 17.6716C34.5316 17.6716 36.0842 16.3587 36.0842 14.3267C36.0842 12.6094 35.058 11.6596 32.5419 11.0477Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.910786 7.38359C2.76207 2.91415 7.12339 0 11.9611 0C18.5667 -1.73368e-05 23.9217 5.35487 23.9218 11.9605C23.9218 16.7982 21.0078 21.1596 16.5384 23.0109C12.0689 24.8623 6.9244 23.839 3.50363 20.4183C0.0828489 16.9975 -0.940493 11.853 0.910786 7.38359ZM16.9851 17.588C17.1774 17.5415 17.3432 17.4204 17.4461 17.2514C17.5494 17.0827 17.5813 16.8798 17.5349 16.6875C17.4885 16.4952 17.3677 16.3293 17.1989 16.2261C14.0462 14.2997 10.138 13.8514 5.58193 14.893C5.32236 14.9523 5.11413 15.1455 5.03569 15.4C4.95725 15.6544 5.02052 15.9314 5.20165 16.1265C5.38279 16.3217 5.65428 16.4054 5.91385 16.3461C10.0771 15.3951 13.6124 15.7827 16.4211 17.4994C16.5899 17.6026 16.7928 17.6345 16.9851 17.588ZM18.9099 13.9946C18.6403 14.4334 18.0662 14.5709 17.6272 14.3017C14.4128 12.3259 9.51154 11.7536 5.70879 12.908C5.21609 13.0576 4.69536 12.7795 4.5457 12.2868C4.39605 11.7941 4.67414 11.2734 5.16684 11.1238C9.51094 9.80555 14.9112 10.444 18.603 12.7128C19.0415 12.9822 19.1788 13.5559 18.9099 13.9946ZM5.14058 9.22057C8.82109 8.10348 15.1805 8.31398 19.036 10.6036V10.6039C19.5674 10.9192 20.2537 10.7441 20.5691 10.2127C20.8844 9.68136 20.7093 8.99498 20.1779 8.67965C15.7395 6.04481 8.71612 5.79708 4.49111 7.07975C4.10443 7.19223 3.80805 7.50388 3.71512 7.89572C3.62219 8.28757 3.74704 8.69912 4.04202 8.97328C4.337 9.24744 4.75658 9.34188 5.14058 9.22057Z'
      fill='black'
    />
  </svg>
);

const ProgressBarContainer = styled.div`
  flex: 1;
`;

type SpotifyBannerProps = {
  advertising?: boolean;
  progress?: number;
  position?: 'center' | 'flex-start';
};

const SpotifyBanner = ({
  advertising = false,
  progress,
  position = 'center',
}: SpotifyBannerProps) => {
  return (
    <>
      <SpotifyAdvertisingHeader progress={progress} position={position}>
        {advertising ? SpotifyAdvertisingSvg : SpotifySvg}
      </SpotifyAdvertisingHeader>
      {typeof progress === 'number' && (
        <ProgressBarContainer style={{width: '100%'}}>
          <ProgressBar
            value={progress}
            max={100}
            semanticColor='essentialPositive'
          />
        </ProgressBarContainer>
      )}
    </>
  );
};

export default SpotifyBanner;
