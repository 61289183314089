import React from 'react';
import MergeProductMutation from '@admin/graphql-api/_old_mutations/MergeProduct';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import CompanySelect from '@core/components/CompanySelect';
import ProductSelector from '@core/components/ProductSelector';
import {Element} from '@core/style';
import {Button} from '@core/ui/Button';
import {Submit} from '@core/ui/FormElements';

class ProductMerge extends React.Component {
  constructor(props) {
    super(props);
    const {company} = props;

    this.state = {
      companyId: '',
      companyName: '',
      companySlug: '',

      productId: '',
      productName: '',
      productSlug: '',

      loading: false,
      complete: false,
    };
  }

  onCompanyChange = ({name, id, slug}) => {
    this.setState({
      companyId: id,
      companyName: name,
      companySlug: slug,
    });
  };

  onProductChange = ({name, id, slug}) => {
    this.setState({
      productId: id,
      productName: name,
      productSlug: slug,
    });
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const {mergeProduct, product, company} = this.props;

    const {companyId, productId} = this.state;

    if (!companyId) {
      return;
    }

    this.setState({
      loading: true,
    });

    mergeProduct({
      id: product.id,
      companyId,
      productId,
    }).then(() => {
      this.setState({
        complete: company.id,
      });
    });
  };

  onCancel = () => {
    this.setState({
      companyId: '',
      companyName: '',
      companySlug: '',

      productId: '',
      productName: '',
      productSlug: '',
    });
  };

  render() {
    const {company} = this.props;

    const {
      loading,
      complete,

      companyId,
      companyName,
      companySlug,

      productId,
      productName,
      productSlug,

      error,
    } = this.state;

    if (complete) {
      return <Redirect to={`/companies/${complete}`} />;
    }

    return (
      <Element rules={() => ({maxWidth: '600px', margin: '25px auto'})}>
        <h4>Merge Product</h4>

        <form onSubmit={this.onSubmit}>
          <Element>
            <h5>Company</h5>
            <CompanySelect onSelect={this.onCompanyChange} />
            <h5>Product</h5>
            <ProductSelector
              onSelect={this.onProductChange}
              companyId={companyId}
            />
          </Element>
          <Element rules={() => ({padding: '20px 0', textAlign: 'right'})}>
            <Button onClick={this.onCancel}>Cancel</Button>
            <Submit value='Merge' disabled={loading} />
          </Element>
        </form>
      </Element>
    );
  }
}

export default graphql(MergeProductMutation, {
  props: ({mutate}) => ({
    mergeProduct: (input) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
  }),
})(ProductMerge);
