import {gql} from '@apollo/client';

export default gql`
  mutation createPodcast($input: CreatePodcastInput!) {
    authed {
      success
    }
    createPodcast(input: $input) {
      organizationId
      feedId
      podcast {
        id
      }
    }
  }
`;
