import {
  ChangeEvent,
  FC,
  Reducer,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import {useLazyQuery} from '@apollo/client';
import {ProgressCircle} from '@spotify-internal/encore-web';
import {useHistory} from 'react-router-dom';
import useSessionStorage from '@core/lib/useSessionStorage';
import EmptyMessage from '../EmptyMessage';
import {Stack} from '../Layout';
import DataTableBody from './DataTableBody';
import DataTableHeader from './DataTableHeader';
import DataTablePagination from './DataTablePagination';
import DataTableSearch from './DataTableSearch';
import {
  DataTableColumn,
  DataTableServerProps,
  DataTableServerVariables,
} from './types';

const DataTableServer: FC<DataTableServerProps> = ({
  className,
  columns,
  domRef,
  emptyMessageText,
  emptyMessageTitle,
  fetchPolicy = 'network-only',
  filtersStorageKey,
  onClickRowPath,
  orderBy,
  pagination = true,
  paginationRowsPerPage = 20,
  paginationRowsPerPageOptions = [10, 20, 50, 100],
  propertyForKey = 'id',
  query,
  rowRuleFn,
  searchAutofocus = false,
  searchKeys = [],
  searchPlaceholder = 'Search',
  searchValue,
  toData,
  toVariables,
}) => {
  const history = useHistory();
  const abortControllerRef = useRef<AbortController | null>(null);

  const [filtersDefaultValues, setFiltersDefaultValues] = useSessionStorage({
    id: filtersStorageKey ? `ui:datatable:${filtersStorageKey}` : '',
    initialState: {},
  });

  const [state, setState] = useReducer<
    Reducer<DataTableServerVariables, Partial<DataTableServerVariables>>
  >(
    (oldState, newState) => ({
      ...oldState,
      ...newState,
    }),
    {
      sortBy: orderBy,
      query: '',
      rowsPerPage: pagination ? paginationRowsPerPage : Infinity,
      rowStart: 0,
      filters: filtersDefaultValues,
    }
  );

  const [loadData, {loading, data: resp}] = useLazyQuery(query);

  const visibleColumns = useMemo(
    () => columns.filter(({hidden = false}) => !hidden),
    [columns]
  );

  const onSearchInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const {value} = evt.target;
    setState({query: value.trim()});
  };

  const onFilterChange = useCallback(
    ({
      column,
      defaultValue,
    }: {column?: DataTableColumn; defaultValue?: any} = {}) => {
      if (column) {
        const updatedFilters = {
          ...filtersDefaultValues,
          [column.accessor!]: defaultValue,
        };

        if (filtersStorageKey) {
          setFiltersDefaultValues(updatedFilters);
        }

        setState({filters: updatedFilters});
      }
    },
    [filtersStorageKey, filtersDefaultValues, setFiltersDefaultValues]
  );

  useEffect(() => {
    setState({query: searchValue});
  }, [searchValue]);

  useEffect(() => {
    if (abortControllerRef.current instanceof AbortController) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    loadData({
      context: {
        fetchPolicy,
        fetchOptions: {
          signal: abortControllerRef.current.signal,
        },
      },
      variables: toVariables(state),
    });
  }, [fetchPolicy, loadData, state, toVariables]);

  let content;

  if (loading) {
    content = (
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          padding: '1rem',
        }}>
        <ProgressCircle aria-label='ProgressCircle' size='large' />
      </div>
    );
  } else {
    const {data, count} = toData(resp);

    if (data.length < 1) {
      content = (
        <EmptyMessage title={emptyMessageTitle}>
          {emptyMessageText}
        </EmptyMessage>
      );
    } else {
      content = (
        <>
          <div css={{minHeight: 0, overflowY: 'auto'}}>
            <table
              css={{
                borderCollapse: 'collapse',
                borderSpacing: 0,
                fontSize: '0.875rem',
                margin: 0,
                textAlign: 'left',
                width: '100%',
              }}>
              <DataTableHeader
                columns={visibleColumns}
                onUpdateSortBy={(sortBy) => setState({sortBy})}
                sortBy={state.sortBy}
              />
              <DataTableBody
                columns={visibleColumns}
                currentPageData={data}
                history={history}
                propertyForKey={propertyForKey}
                onClickRowPath={onClickRowPath}
                rowRuleFn={rowRuleFn}
              />
            </table>
          </div>
          {pagination && (
            <DataTablePagination
              count={count}
              paginationRowsPerPage={paginationRowsPerPage}
              paginationRowsPerPageOptions={paginationRowsPerPageOptions}
              rowsPerPage={state.rowsPerPage}
              rowStart={state.rowStart}
              onRowStartChange={(rowStart) => setState({rowStart})}
              onRowsPerPageChange={(rowsPerPage) => setState({rowsPerPage})}
            />
          )}
        </>
      );
    }
  }

  const filters = useMemo(
    () =>
      columns.flatMap((column) =>
        column.Filter ? [{column, Filter: column.Filter, ref: createRef()}] : []
      ),
    [columns]
  );

  const displayHeader = filters.length > 0 || searchKeys.length > 0;

  return (
    <div
      ref={domRef}
      className={className}
      style={{
        display: 'grid',
        gridTemplateRows: displayHeader
          ? 'max-content 1fr max-content'
          : '1fr max-content',
        height: '100%',
      }}>
      {displayHeader && (
        <Stack gap='0.625rem' css={{marginBottom: 'var(--spacing-6)'}}>
          {searchKeys.length > 0 && (
            <DataTableSearch
              autofocus={searchAutofocus}
              onChange={onSearchInputChange}
              placeholder={searchPlaceholder}
            />
          )}
          {filters.length > 0 && (
            <Stack gap='0.625rem' css={{fontSize: '0.875rem'}}>
              {filters.map(({Filter, column}, idx) => (
                <div key={`${column.accessor}${idx}`}>
                  <Filter
                    column={column}
                    onChange={onFilterChange}
                    {...(filtersDefaultValues &&
                      filtersDefaultValues[column.accessor!] && {
                        defaultValue: filtersDefaultValues[column.accessor!],
                      })}
                  />
                </div>
              ))}
            </Stack>
          )}
        </Stack>
      )}
      {content}
    </div>
  );
};

export default DataTableServer;
