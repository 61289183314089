import React from 'react';
import {Route, Switch} from 'react-router-dom';
import CompanyClaim from './CompanyClaim';
import CompanyClaims from './CompanyClaims';

export default function () {
  return (
    <Switch>
      <Route exact path='/claims' component={CompanyClaims} />
      <Route exact path='/claims/:companyClaimId' component={CompanyClaim} />
    </Switch>
  );
}
