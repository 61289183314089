/* eslint-disable no-console */
export const logger = {
  log: (...args: any) => console.log(...args),
  error: (...args: any) => console.error(...args),
  warn: (...args: any) => console.warn(...args),
  info: (...args: any) => console.info(...args),
  debug: (...args: any) => console.debug(...args),
  verbose: (...args: any) => console.log('verbose:', ...args),
  silly: (...args: any) => console.log('silly:', ...args),
};

export default logger;

export const log = logger.log.bind(logger);
export const error = logger.error.bind(logger);
export const warn = logger.warn.bind(logger);
export const info = logger.info.bind(logger);
export const debug = logger.debug.bind(logger);
export const verbose = logger.verbose.bind(logger);
export const silly = logger.silly.bind(logger);
