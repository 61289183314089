import React, {FC, PropsWithChildren} from 'react';
import {TRules, css} from '@core/style';

type TResponsiveSizes = 'small' | 'medium' | 'large' | 'xlarge';

export const breakpoints: {
  [_key in TResponsiveSizes]: number;
} = {
  small: 576,
  medium: 768,
  large: 992,
  xlarge: 1200,
};

export const breakpointsList = [
  breakpoints.small,
  breakpoints.medium,
  breakpoints.large,
  breakpoints.xlarge,
];

interface IResponsiveContentProps {
  containerSize?: TResponsiveSizes;
  rules?: TRules;
}

const ResponsiveContent: FC<PropsWithChildren<IResponsiveContentProps>> = ({
  containerSize = 'xlarge',
  rules,
  children,
}): JSX.Element => {
  return (
    <div
      {...css([
        () => ({
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: `calc(${breakpoints[containerSize] + 60}px)`,
          paddingLeft: '1.875rem',
          paddingRight: '1.875rem',
          width: '100%',
          [`@media (max-width: ${breakpoints.medium}px)`]: {
            paddingLeft: '1.2rem',
            paddingRight: '1.2rem',
          },
        }),
        rules,
      ])}>
      {children}
    </div>
  );
};

export default ResponsiveContent;
