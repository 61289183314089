import dayjs from './dayjs';

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const fmtContractDates = (start: string, end: string) =>
  `${dayjs(start).format('MM/DD/YY')} to ${dayjs(end).format('MM/DD/YY')}`;

export const comma = (value: string | number, fixed = 0): string => {
  if (value) {
    let _val = value.toString();
    if (fixed === 0) {
      _val = parseInt(_val).toString();
    } else if (fixed < 3) {
      _val = parseFloat(_val).toFixed(fixed);
    } else {
      // eslint-disable-next-line no-console
      console.warn('comma does not work with a fixed greater than 2');
    }

    return _val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '0';
};

export const financial = (value: string | number): string => {
  let _val = value;
  if (typeof _val === 'number') {
    if (isNaN(_val)) {
      return '-';
    }
    _val = _val.toFixed(2).replace('.', '');
  }

  if (_val) {
    if (_val.length < 2) {
      return `$${_val}`;
    }
    const cents = _val.substring(_val.length - 2);
    return `$${comma(_val.substring(0, _val.length - 2))}${
      cents !== '00' ? `.${cents}` : ''
    }`;
  }
  return '$0';
};

export const fmtCurrency = (value: number, currency?: string) =>
  new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
  }).format(value);

export const toCampaignCurrency = (currency?: string) => (v: number) =>
  fmtCurrency(v, currency);

export const toPercent = (value: number, fixed = 2): string =>
  `${+(value * 100).toFixed(fixed)}%`;

export const toFixed = (value: number, fixed = 2): string => {
  if (value % 1 === 0) {
    return comma(value);
  }
  const decimal = value % 1;

  // 0.9999 will get rounded to 1.0 an ruin everything.
  const str = decimal.toFixed(fixed);
  if (str.charAt(0) === '1') {
    return comma(value + 1);
  }
  return `${comma(Math.floor(value))}${str.replace(/^0|0$/g, '')}`;
};

export const firstChar = (value: string): string =>
  value ? value.charAt(0) : '';

export const obfuscateEmail = (email: string): string => {
  if (email) {
    const parts = email.split('@');
    // They did it wrong.
    if (parts.length !== 2) {
      return email;
    }

    return `${parts[0].substring(0, 3)}****@${parts[1]}`;
  }
  return '';
};

export const capitalize = (value: string): string =>
  value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

const MATURITY_MAP: {[_key in 0 | 1 | 2]: string} = {
  0: 'PG',
  1: 'PG-13',
  2: 'R',
};

type MaturityKey = keyof typeof MATURITY_MAP;

export const contentMaturityRating = (value: MaturityKey | number) => {
  if (value in MATURITY_MAP) {
    return MATURITY_MAP[value as MaturityKey];
  }
  return '-';
};

export const usLocaleStringToNumber = (
  value: string,
  locale = 'en-US'
): number => {
  const parts = (1234.5).toLocaleString(locale).match(/(\D+)/g);
  let unformatted = value.replace(/\$/g, '');
  unformatted = unformatted.split(parts[0]).join('');
  unformatted = unformatted.split(parts[1]).join('.');

  return parseFloat(unformatted);
};
