import {Route, Switch} from 'react-router-dom';
import {UnAuthHome, UnAuthNoMatch} from '@core/app/Auth';
import Enter from '@core/app/Enter';
import SignInWithSpotify from '@core/components/SignInWithSpotify';

const UnAuthed = () => {
  return (
    <Switch>
      <Route exact path='/' component={UnAuthHome} />
      <Route
        exact
        path='/signin'
        render={(routeProps) => <SignInWithSpotify {...routeProps} />}
      />
      <Route exact path='/enter/:token' component={Enter} />
      <Route path='*' component={UnAuthNoMatch} />
    </Switch>
  );
};

export default UnAuthed;
