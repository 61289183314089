import {gql} from '@apollo/client';

export default gql`
  mutation updateInvoice($input: UpdateInvoiceInput!) {
    authed {
      success
    }
    updateInvoice(input: $input) {
      success
    }
  }
`;
