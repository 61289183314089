/**
 * The enum of environment.
 */
export enum Environment {
  BACKEND = 'BACKEND',
  BROWSER = 'BROWSER',
  BROWSER_NON_AUTH = 'BROWSER_NON_AUTH',
  DESKTOP = 'DESKTOP',
  DESKTOP_NON_AUTH = 'DESKTOP_NON_AUTH',
  DEVICE = 'DEVICE',
  DEVICE_NON_AUTH = 'DEVICE_NON_AUTH',
}
