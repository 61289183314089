import {
  ButtonTertiary,
  TextLink,
  semanticColors,
} from '@spotify-internal/encore-web';
import styled from 'styled-components';

export const ActionButton = styled(ButtonTertiary).attrs({
  rel: 'noopener noreferrer',
  size: 'small',
  target: '_blank',
  underline: 'none',
})`
  background: var(--bg-subtle);
  border-radius: 5px;
  margin: 0 1rem 0.5rem 0;
  padding: 0.25rem 0.625rem;
`;

export const CampaignLink = ({campaign, type = 'publisher'}) => {
  const org =
    type === 'publisher' ? campaign.organization : campaign.advertiser;

  if (!org) {
    return <>-</>;
  }

  return (
    <TextLink
      semanticColor={semanticColors.textBrightAccent}
      href={`/organizations/${org.id}`}>
      {org.name}
    </TextLink>
  );
};
