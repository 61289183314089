import React, {ChangeEvent, useEffect, useState} from 'react';
import CompaniesFilter from '@admin/components/CompaniesFilter';
import {Button} from '@core/ui/Button';
import {Card, PageTitle} from '@core/ui/Content';
import {SearchInput} from '@core/ui/FormElements';
import Select from '@core/ui/Select';
import Toggle from '@core/ui/Toggle';

export default function Companies() {
  const [allowDuplicates, setAllowDuplicates] = useState(false);
  const [limit, setLimit] = useState<number>(15);
  const [order, setOrder] = useState<string>('-ads_count');
  const [query, setQuery] = useState<string>();
  const [localQuery, setLocalQuery] = useState<string>();

  const onSearchInput = (evt: ChangeEvent<HTMLInputElement>) => {
    setLocalQuery(evt.target.value);
  };

  const onOrderSelect = (item: {value: string}) => {
    setOrder(item.value);
  };

  const onLoadMore = () => {
    setLimit((prev) => prev + 15);
  };

  useEffect(() => {
    const job = setTimeout(() => {
      setQuery(localQuery);
    }, 500);

    return () => {
      clearTimeout(job);
    };
  }, [localQuery]);

  return (
    <>
      <PageTitle>Companies</PageTitle>
      <div
        css={`
          margin-bottom: 1rem;
        `}>
        <Card
          css={`
            align-items: center;
            background: var(--bg-muted);
            border: 0;
            display: flex;
            gap: 1rem;
          `}>
          <Select
            items={[
              {title: 'Recent', value: '-created_at'},
              {title: 'Most Ads', value: '-ads_count'},
              {title: 'Most Feeds', value: '-feeds_count'},
              {title: 'Most Products', value: '-products_count'},
            ]}
            defaultValue={order}
            onSelect={onOrderSelect}
            outline
            small
          />
          <Toggle
            defaultChecked={allowDuplicates}
            onChecked={setAllowDuplicates}>
            Duplicates
          </Toggle>
        </Card>
      </div>
      <Card>
        <SearchInput
          type='text'
          name='query'
          placeholder='Search by company name'
          onChange={onSearchInput}
          rules={() => ({marginBottom: '1rem'})}
        />
        <CompaniesFilter
          offset={0}
          limit={limit}
          order={order}
          duplicates={allowDuplicates}
          query={query}
        />
        <Button
          onClick={onLoadMore}
          css={`
            width: 100%;
            margin-top: 1rem;
          `}>
          Load more feeds
        </Button>
      </Card>
    </>
  );
}
