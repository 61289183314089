import {gql} from '@apollo/client';

export default gql`
  mutation addTag($input: AddTagInput!) {
    authed {
      success
    }
    addTag(input: $input) {
      companyId
      productId
      tag {
        id
        label
      }
    }
  }
`;
