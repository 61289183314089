import React, {FC, useState} from 'react';
import {CreateOrganizationInput} from '@admin/graphql-api';
import {Controller, useForm} from 'react-hook-form';
import {Redirect} from 'react-router-dom';
import {Card, CardHeader} from '@core/ui/Content';
import {
  FORM_ERRORS,
  Form,
  FormFooter,
  FormGroup,
  LabelInput,
  LabelSelect,
  Submit,
} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {useCreateOrganizationMutation} from '../../../analytics/graphql-api';

const OrganizationCreate: FC = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<string>(null);
  const [createOrganization] = useCreateOrganizationMutation();

  const {
    control,
    handleSubmit,
    formState: {errors},
    setError,
  } = useForm({
    defaultValues: {
      name: '',
      kind: '',
    },
  });

  const onSubmit = async ({name, kind}: CreateOrganizationInput) => {
    setLoading(true);
    try {
      const resp = await createOrganization({
        variables: {
          input: {
            name,
            kind,
          },
        },
      });

      if (resp) {
        setComplete(resp.data.createOrganization.organization.id);
      }
    } catch (e) {
      setError(null, {message: e.toString()} || FORM_ERRORS.network);
    }
    setLoading(false);
  };

  if (complete) {
    return <Redirect to={`/organizations/${complete}`} />;
  }

  return (
    <ResponsiveContent style-containerSize='medium'>
      <CardHeader backPath='/organizations'>Create Organization</CardHeader>
      <Card>
        <Form errors={errors} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Controller
              name='name'
              control={control}
              rules={{required: 'Please provide an organization name.'}}
              render={({field}) => (
                <LabelInput
                  label='Organization Name'
                  invalid={!!errors.name}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </FormGroup>
          <Controller
            name='kind'
            control={control}
            rules={{required: 'Please provide organization kind.'}}
            render={({field}) => (
              <LabelSelect
                label='Organization Kind'
                defaultValue={field.value}
                invalid={!!errors.kind}
                items={[
                  {
                    title: 'Podcast',
                    value: 'podcast',
                  },
                  {
                    title: 'Brand',
                    value: 'brand',
                  },
                  {
                    title: 'Research',
                    value: 'research',
                  },
                  {
                    title: 'Buy Side Agency (Traditional Ad Agency)',
                    value: 'buy-side',
                  },
                  {
                    title: 'Sell Side Agency (Podcast Rep Firm)',
                    value: 'sell-side',
                  },
                ]}
                onSelect={({value}) => field.onChange(value)}
              />
            )}
          />
          <FormFooter>
            <Submit loading={loading}>Create Organization</Submit>
          </FormFooter>
        </Form>
      </Card>
    </ResponsiveContent>
  );
};

export default OrganizationCreate;
