import {PropsWithChildren, useEffect} from 'react';
import '@spotify-internal/encore-web/css/encore-ad-analytics-light-theme.css';
import '@spotify-internal/encore-web/css/encore-medium-devices-layout-theme.css';
import '@spotify-internal/encore-web/css/encore-text-styles.css';
import {RendererProvider} from 'react-fela';
import {HelmetProvider} from 'react-helmet-async';
import {BrowserRouter, useLocation} from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ress from 'ress';
import {createFelaRenderer} from '@core/style';
import globalStyles from '@core/style/global/index.css';
import ApolloProviderWrapper from './ApolloProviderWrapper';

const renderer = createFelaRenderer();
renderer.renderStatic(ress);
renderer.renderStatic(globalStyles);

const ScrollToTop = ({children}: PropsWithChildren): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
};

type Props = PropsWithChildren<{
  service: 'analytics' | 'admin';
}>;

const App = ({children, service}: Props) => {
  return (
    <div style={{height: '100%'}}>
      <HelmetProvider>
        <ApolloProviderWrapper service={service}>
          <RendererProvider renderer={renderer}>
            <BrowserRouter>
              <ScrollToTop>{children}</ScrollToTop>
            </BrowserRouter>
          </RendererProvider>
        </ApolloProviderWrapper>
      </HelmetProvider>
    </div>
  );
};

export default App;
