import {useEffect, useReducer} from 'react';

class TrackGesture {
  constructor({
    onTrack,
    onTrackEnd,
    orientation = 'horizontal',
    target,
    trackOnPointerDown = true,
  }) {
    if (!target || target.nodeType !== Element.ELEMENT_NODE) {
      throw new Error('TrackGesture needs a Target');
    }

    // Props
    this.onTrack = onTrack;
    this.onTrackEnd = onTrackEnd;
    this.orientation = orientation;
    this.target = target;
    this.trackOnPointerDown = trackOnPointerDown;
    // State
    this.trackStarted = false;
    this.targetRect = null;
    this.clientStartX = 0;
    this.clientStartY = 0;
    // Binds
    this.onPointerDown = this.onPointerDown.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);

    target.addEventListener('pointerdown', this.onPointerDown);

    return () => {
      target.removeEventListener('pointerdown', this.onPointerDown);
      this.toggleTrackListeners(false);
    };
  }

  toggleTrackListeners(add) {
    const m = add ? 'addEventListener' : 'removeEventListener';

    document[m]('pointermove', this.onPointerMove);
    document[m]('pointerup', this.onPointerUp);
    document[m]('contextmenu', this.onPointerUp);
  }

  onPointerDown({clientX, clientY}) {
    document.body.setAttribute('draggable', false);
    document.body.style.userSelect = 'none';
    document.body.style.touchAction = 'none';
    this.targetRect = this.target.getBoundingClientRect();
    this.clientStartX = clientX;
    this.clientStartY = clientY;
    this.toggleTrackListeners(true);

    if (this.trackOnPointerDown) {
      this.dispatchTrackEvent({clientX, clientY});
    }
  }

  onPointerMove(evt) {
    const {clientX, clientY} = evt;
    if (this.trackStarted) {
      evt.stopPropagation();
      evt.preventDefault();
      this.dispatchTrackEvent({clientX, clientY});
    } else if (
      ((!this.orientation || this.orientation === 'horizontal') &&
        Math.abs(this.clientStartX - clientX) > 4) ||
      ((!this.orientation || this.orientation === 'vertical') &&
        Math.abs(this.clientStartY - clientY) > 4)
    ) {
      this.trackStarted = true;
    }
  }

  dispatchTrackEvent({clientX, clientY}) {
    if (typeof this.onTrack === 'function') {
      this.onTrack({
        dx: clientX - this.clientStartX,
        dy: clientY - this.clientStartY,
        startX: this.clientStartX - this.targetRect.left,
        startY: this.clientStartY - this.targetRect.top,
        targetRect: this.targetRect,
      });
    }
  }

  onPointerUp() {
    this.stopTrack();
    if (typeof this.onTrackEnd === 'function') {
      this.onTrackEnd();
    }
  }

  stopTrack() {
    this.toggleTrackListeners(false);
    document.body.removeAttribute('draggable');
    document.body.style.userSelect = '';
    document.body.style.touchAction = '';
    this.clientStartX = 0;
    this.clientStartY = 0;
    this.trackStarted = false;
    this.targetRect = null;
  }
}

const initialState = {
  dx: 0,
  dy: 0,
  startX: 0,
  startY: 0,
  targetRect: null,
  dragging: false,
};

const useTrackGesture = (targetRef, options = {}) => {
  const [state, setState] = useReducer(
    (old, newState) => ({...old, ...newState}),
    initialState
  );

  useEffect(() => {
    let disposable;

    if (targetRef.current) {
      disposable = new TrackGesture({
        onTrack: ({dx, dy, startX, startY, targetRect}) => {
          setState({dx, dy, startX, startY, targetRect, dragging: true});
        },
        onTrackEnd: () => {
          setState({...initialState});
        },
        target: targetRef.current,
        ...options,
      });
    }

    return () => {
      if (disposable) {
        disposable();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
};

export {TrackGesture};

export default useTrackGesture;
