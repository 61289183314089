import React, {forwardRef} from 'react';
import LiftReportsQuery from '@admin/graphql-api/_old_queries/LiftReportsQuery';
import {graphql} from '@apollo/client/react/hoc';
import {CampaignLiftBadge} from '@core/components/CampaignStateBadge';
import dayjs from '@core/lib/dayjs';
import {comma} from '@core/lib/filters';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable, {MultipleSelectFilter} from '@core/ui/DataTable';
import Loading from '@core/ui/Loading';

const columns = [
  {
    title: 'Campaign',
    accessor: 'campaignName',
    type: 'string',
    Filter: MultipleSelectFilter,
    rule: () => ({fontWeight: 500}),
  },
  {
    title: 'Publisher',
    accessor: 'publisherName',
    type: 'string',
    Filter: MultipleSelectFilter,
  },
  {
    title: 'Advertiser',
    accessor: 'advertiserName',
    type: 'string',
    Filter: MultipleSelectFilter,
    rule: () => ({whiteSpace: 'nowrap'}),
  },
  {
    title: 'Requested By',
    accessor: 'requestedBy',
    type: 'string',
    Filter: MultipleSelectFilter,
  },
  {
    title: 'Requester Org',
    accessor: 'requesterOrganization',
    type: 'string',
    Filter: MultipleSelectFilter,
  },
  {
    title: 'State',
    accessor: 'state',
    type: 'string',
    Filter: forwardRef(({...props}, ref) => (
      <MultipleSelectFilter ref={ref} searchable={false} {...props} />
    )),
    Cell: ({data: {state}}) => {
      return <CampaignLiftBadge state={state} />;
    },
  },
  {
    title: 'Campaigns',
    type: 'number',
    accessor: 'campaignsLength',
    fmt: comma,
  },
  {
    title: 'Downloads',
    accessor: 'downloadTotal',
    type: 'number',
    fmt: comma,
  },
  {
    title: 'Start',
    accessor: 'impressionsStartAt',
    type: 'date',
    fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
  },
  {
    title: 'End',
    accessor: 'impressionsEndAt',
    type: 'date',
    fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
  },
  {
    title: 'Created',
    accessor: 'createdAt',
    type: 'date',
    fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
  },
];

const LiftReports = ({data: {loading, admin}}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const data = admin.liftReports
    .filter(({campaigns}) => campaigns.length)
    .map(
      ({
        id,
        requestedBy: {firstName, lastName, email},
        requesterOrganization,
        campaigns,
        ...props
      }) => {
        return {
          id,
          campaigns,
          campaignsLength: campaigns.length,
          downloadTotal: campaigns.reduce(
            (acc, {downloadTotal}) => acc + downloadTotal,
            0
          ),
          campaignName: campaigns.map(({name}) => name).join(', '),
          publisherName: [
            ...new Set(campaigns.map(({organization: {name}}) => name)),
          ].join(', '),
          url: `https://dash.podsights.com/p/${campaigns[0].organization.slug}/measure/lift/reports/${id}`,
          requestedBy: `${firstName} ${lastName} (${email})`,
          requesterOrganization: requesterOrganization.name,
          advertiserName: [
            ...new Set(campaigns.map(({advertiser}) => advertiser?.name)),
          ].join(', '),
          ...props,
        };
      }
    );

  return (
    <Card>
      <CardHeader>Conversion Lift Reports</CardHeader>
      <DataTable
        columns={columns}
        data={data}
        searchKeys={['campaignName', 'id']}
        searchPlaceholder='Search'
        orderBy='-createdAt'
        filtersStorageKey='admin-lift-reports'
        onClickRowPath={({url}) => url}
      />
    </Card>
  );
};

export default graphql(LiftReportsQuery)(LiftReports);
