import React from 'react';
import AddAccessMutation from '@admin/graphql-api/_old_mutations/AddAccess';
import OrganizationsQuery from '@admin/graphql-api/queries/AdminOrganizationsQuery';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import {Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import {Submit} from '@core/ui/FormElements';
import Loading from '@core/ui/Loading';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import Select from '@core/ui/Select';

class OrganizationAccessAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organizationId: '',
      loading: false,
      complete: false,
    };
  }

  onOrgChange = ({title, value}) => {
    this.setState({
      organizationId: value,
    });
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const {
      addAccess,
      match: {
        params: {podcastId},
      },
    } = this.props;
    const {organization} = this.props;
    const {organizationId} = this.state;

    if (!organizationId) {
      return;
    }

    this.setState({
      loading: true,
    });

    const name = `${
      organization.kind == 'BRAND' ? 'buy-side' : 'sell-side'
    }:organization`;

    addAccess({
      organizationId: organizationId,
      name,
      permission: 'can-manage',
      objectPk: organization.id,
      contentType: 'organizations.organization',
    }).then(() => {
      this.setState({
        complete: true,
      });
    });
  };

  onCancel = () => {
    this.setState({
      feedId: '',
      feedTitle: '',
    });
  };

  render() {
    const {organization, data} = this.props;

    if (organization.kind !== 'BRAND' && organization.kind !== 'PODCAST') {
      return <div>Nope</div>;
    }

    if (data.loading) {
      return <Loading absolute />;
    }

    const {complete} = this.state;

    if (complete) {
      window.location.pathname = `/organizations/${organization.id}`;
      return null;
    }

    return (
      <ResponsiveContent containerSize='medium'>
        <CardHeader>Add access to agency</CardHeader>
        <Card>
          <form onSubmit={this.onSubmit}>
            <Select
              items={data.admin.organizations.map((org) => ({
                title: org.name,
                value: org.id,
              }))}
              onSelect={this.onOrgChange}
              searchKeys={['title']}
              rules={() => ({width: '100%'})}
              searchable
            />
            <Element rules={() => ({padding: '1rem 0 0', textAlign: 'right'})}>
              <Submit value='Add Manage Access' />
            </Element>
          </form>
        </Card>
      </ResponsiveContent>
    );
  }
}

export default compose(
  graphql(OrganizationsQuery, {
    options: ({organization}) => ({
      variables: {
        kind: organization.kind == 'BRAND' ? 'buy-side' : 'agencies',
      },
    }),
  }),
  graphql(AddAccessMutation, {
    props: ({ownProps: {organization, podcast}, mutate}) => ({
      addAccess: (input) => {
        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  })
)(OrganizationAccessAdd);
