/**
 * Encore Foundation - 5.23.1
 *
 * The contents of this file is owned and generated by the Encore team.
 * If you need to make changes to the values stored here please reach out
 * to #encore on Slack.
 *
 * Links:
 *
 * - Source & Documentation: https://ghe.spotify.net/encore/foundation
 *
 * Tack!
 *
 * Encore
 * encore.spotify.net
 */
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Body Medium instead
 */
export var desktopBallad = {
    fontSize: '1rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 400
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Body Medium instead
 */
export var desktopBalladBold = {
    fontSize: '1rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Body Small instead
 */
export var desktopViola = {
    fontSize: '0.875rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 400
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Body Small instead
 */
export var desktopViolaBold = {
    fontSize: '0.875rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Body Small instead
 */
export var desktopMesto = {
    fontSize: '0.875rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 400
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Body Small instead
 */
export var desktopMestoBold = {
    fontSize: '0.875rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Headline Large instead
 */
export var desktopBass = {
    fontSize: '6rem',
    fontFamily: 'CircularSpTitle,CircularSpTitle-Tall,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 900
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Headline Medium instead
 */
export var desktopForte = {
    fontSize: '4.5rem',
    fontFamily: 'CircularSpTitle,CircularSpTitle-Tall,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 900
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Title Large instead
 */
export var desktopBrio = {
    fontSize: '3rem',
    fontFamily: 'CircularSpTitle,CircularSpTitle-Tall,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated In order to better support non-latin languages, the all-caps type styles have been deprecated. You can learn more about the reasons and replacements on http://go/all-caps-deprecation
 */
export var desktopMetronome = {
    fontSize: '0.875rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700,
    letterSpacing: '0.1em',
    textTransform: 'uppercase'
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated In order to better support non-latin languages, the all-caps type styles have been deprecated. You can learn more about the reasons and replacements on http://go/all-caps-deprecation
 */
export var desktopMinuet = {
    fontSize: '0.75rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 400,
    letterSpacing: '0.1em',
    textTransform: 'uppercase'
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated In order to better support non-latin languages, the all-caps type styles have been deprecated. You can learn more about the reasons and replacements on http://go/all-caps-deprecation
 */
export var desktopMinuetBold = {
    fontSize: '0.75rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700,
    letterSpacing: '0.1em',
    textTransform: 'uppercase'
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Marginal instead
 */
export var desktopFinale = {
    fontSize: '0.6875rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 400
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Marginal instead
 */
export var desktopFinaleBold = {
    fontSize: '0.6875rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Title Large instead
 */
export var desktopAltoBrio = {
    fontSize: '3rem',
    fontFamily: 'CircularSpTitle,CircularSpTitle-Tall,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Title Medium instead
 */
export var desktopAlto = {
    fontSize: '2rem',
    fontFamily: 'CircularSpTitle,CircularSpTitle-Tall,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Title Small instead
 */
export var desktopCanon = {
    fontSize: '1.5rem',
    fontFamily: 'CircularSpTitle,CircularSpTitle-Tall,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Title Small instead
 */
export var desktopCelloCanon = {
    fontSize: '1.5rem',
    fontFamily: 'CircularSpTitle,CircularSpTitle-Tall,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
/**
 *
 * ![Status: deprecated](https://img.shields.io/badge/DEPRECATED-red|height=14)
 * @deprecated Use Body Medium instead
 */
export var desktopCello = {
    fontSize: '1.125rem',
    fontFamily: 'CircularSp,CircularSp-Arab,CircularSp-Hebr,CircularSp-Cyrl,CircularSp-Grek,CircularSp-Deva, var(--fallback-fonts, sans-serif)',
    fontWeight: 700
};
