import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import dayjs from '@core/lib/dayjs';
import FutureEpisode from './FutureEpisode';
import FutureRangeIndex from './range';

const FuturesRedirect = () => {
  return (
    <Redirect
      to={`/futures/${dayjs()
        .subtract(7, 'days')
        .format('YYYY-MM-DD')}/${dayjs()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')}`}
    />
  );
};

export default function () {
  return (
    <Switch>
      <Route exact path='/futures' component={FuturesRedirect} />
      <Route exact path='/futures/:futureEpisodeId' component={FutureEpisode} />
      <Route path='/futures/:start/:end' component={FutureRangeIndex} />
    </Switch>
  );
}
