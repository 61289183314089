import {gql} from '@apollo/client';

export default gql`
  mutation createReviewer($input: CreateReviewerInput!) {
    authed {
      success
    }
    createReviewer(input: $input) {
      reviewer {
        id
      }
    }
  }
`;
