import {HTMLAttributes, PropsWithChildren, ReactNode} from 'react';
import styled from 'styled-components';
import {FelaElement} from '@core/style';
import {ButtonIcon} from '../Button';
import {Link} from '../Link';
import {Text} from '../Text';

type Props = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<{
    backPath?: string;
    cta?: {title: string; path: string};
    description?: string | ReactNode;
    rightContent?: any;
  }>;

export const CardHeader = ({
  backPath,
  children,
  cta,
  description,
  rightContent,
  ...props
}: Props) => {
  let right: any;

  if (cta) {
    right = (
      <Link
        to={cta.path}
        css={`
          color: var(--text-muted);
          font-size: var(--font-size-body-2);
          font-weight: 500;
          margin: 0 var(--spacing-2) 0 auto;
          text-decoration: none;
          :hover {
            color: var(--primary);
          }
        `}>
        {cta.title}
      </Link>
    );
  } else if (rightContent) {
    right = (
      <div
        css={`
          position: absolute;
          right: 0;
        `}>
        {rightContent}
      </div>
    );
  }

  return (
    <_Content {...props}>
      {!!backPath && (
        <ButtonIcon
          to={backPath}
          icon='arrow-back'
          css={`
            align-self: self-start;
            margin-right: var(--spacing-4);
            margin-top: 3px;
          `}
        />
      )}
      <div>
        <Text
          variant='h3'
          css={`
            margin: 0 auto 0 0;
          `}>
          {children}
        </Text>
        {!!description && (
          <Text
            variant='body2'
            color='muted'
            css={`
              margin: 0.125rem 0 0;
            `}>
            {description}
          </Text>
        )}
      </div>
      {right}
    </_Content>
  );
};

const _Content = styled(FelaElement)<Props>`
  align-items: center;
  display: flex;
  margin: 0 0 1.25rem;
  position: relative;
`;
