import React, {FC} from 'react';
import Dummy from './Dummy';
import Img, {Image} from './Img';

interface FeedImageProps {
  feed?: {
    image?: Image;
  };
  [key: string]: any;
}

const FeedImage: FC<FeedImageProps> = ({feed, ...props}): JSX.Element => {
  if (feed && feed.image) {
    return <Img image={feed.image} {...props} />;
  }
  return <Dummy {...props} />;
};

export default FeedImage;
