import {useState} from 'react';
import TierBadge from '@admin/components/TierBadge';
import {
  OrganizationObject,
  UpdateOrganizationInput,
  useUpdateOrganizationMutation,
} from '@admin/graphql-api';
import {Controller, useForm} from 'react-hook-form';
import {Redirect} from 'react-router-dom';
import {Card, CardHeader, Separator} from '@core/ui/Content';
import {
  FORM_ERRORS,
  Form,
  FormFooter,
  FormGroup,
  FormHelp,
  Label,
  LabelInput,
  LabelSelect,
  Submit,
  TextArea,
} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import Toggle from '@core/ui/Toggle';

type FormInput = {
  // org
  name: string;
  kind: string;
  tier: string;
  note: string;
  isSegmentsEnabled: boolean;
  isExperianApproved: boolean;
  isNielsenApproved: boolean;
  isAdvisor: boolean;
  networkError: boolean;
};

type Props = {
  organization: OrganizationObject;
};

export default function OrganizationUpdate({organization}: Props) {
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateOrganization] = useUpdateOrganizationMutation();

  const {
    control,
    handleSubmit,
    formState: {errors, isDirty},
    setError,
  } = useForm<FormInput>({
    defaultValues: {
      name: organization.name ?? '',
      kind: organization.kind.toLowerCase().replace('_', '-') ?? '',
      tier: organization.tier.toLowerCase() ?? 'silver',
      note: organization.note ?? '',
      // features
      isSegmentsEnabled: organization.isSegmentsEnabled,
      isExperianApproved: organization.isExperianApproved,
      isNielsenApproved: organization.isNielsenApproved,
      isAdvisor: organization.isAdvisor,
      //
      networkError: false,
    },
  });

  const onSubmit = async (data: FormInput) => {
    if (!isDirty) {
      setComplete(true);
      return;
    }

    setLoading(true);
    try {
      const {
        name,
        kind,
        tier,
        note,
        isSegmentsEnabled,
        isExperianApproved,
        isNielsenApproved,
        isAdvisor,
      } = data;

      const updatedOrg = await updateOrganization({
        variables: {
          input: {
            id: organization.id,
            name,
            kind,
            tier,
            note,
            isSegmentsEnabled,
            isExperianApproved,
            isNielsenApproved,
            isAdvisor,
          } as UpdateOrganizationInput,
        },
      });

      if (updatedOrg) {
        setComplete(true);
      }
    } catch (e) {
      setError('networkError', FORM_ERRORS.network);
    }
    setLoading(false);
  };

  if (complete) {
    return <Redirect to={`/organizations/${organization.id}`} />;
  }

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader>Update Organization</CardHeader>
      <Card>
        <Form errors={errors} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Controller
              name='name'
              control={control}
              rules={{required: 'Please provide organization name.'}}
              render={({field}) => (
                <LabelInput
                  label='Organization Name'
                  defaultValue={field.value}
                  invalid={!!errors.name}
                  onChange={field.onChange}
                  rules={() => ({marginBottom: '1.5rem'})}
                />
              )}
            />
            <div
              css={{
                display: 'grid',
                gap: '1rem',
                gridTemplateColumns: '1fr 1fr',
                margin: '0 0 2rem',
              }}>
              <Controller
                name='kind'
                control={control}
                rules={{required: 'Please provide organization kind.'}}
                render={({field}) => (
                  <LabelSelect
                    label='Organization Kind'
                    defaultValue={field.value}
                    invalid={!!errors.kind}
                    items={[
                      {
                        title: 'Podcast',
                        value: 'podcast',
                      },
                      {
                        title: 'Brand',
                        value: 'brand',
                      },
                      {
                        title: 'Research',
                        value: 'research',
                      },
                      {
                        title: 'Buy Side Agency (Traditional Ad Agency)',
                        value: 'buy-side',
                      },
                      {
                        title: 'Sell Side Agency (Podcast Rep Firm)',
                        value: 'sell-side',
                      },
                    ]}
                    onSelect={({value}) => field.onChange(value)}
                  />
                )}
              />
              <Controller
                name='tier'
                control={control}
                render={({field}) => (
                  <LabelSelect
                    label='Client Tier'
                    defaultValue={field.value}
                    getSelectLabel={(item) => (
                      <TierBadge tier={item[0].value.toUpperCase()} />
                    )}
                    items={[
                      {
                        title: 'Gold',
                        value: 'gold',
                      },
                      {
                        title: 'Silver',
                        value: 'silver',
                      },
                      {
                        title: 'Bronze',
                        value: 'bronze',
                      },
                    ]}
                    onSelect={({value}) => field.onChange(value)}
                  />
                )}
              />
            </div>
            <Label>Notes</Label>
            <Controller
              name='note'
              control={control}
              render={({field}) => (
                <TextArea
                  defaultValue={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FormGroup>
          <Separator rules={() => ({margin: '2.5rem 0 1.5rem'})} />
          <FormGroup>
            <div css={{marginBottom: '1.5rem'}}>
              <Label>Enable Nielsen</Label>
              <Controller
                name='isNielsenApproved'
                control={control}
                render={({field}) => (
                  <Toggle
                    defaultChecked={!!field.value}
                    onChecked={field.onChange}
                  />
                )}
              />
              <FormHelp>
                You should only set this to true if you have permission from the
                organization. Data is grabbed weekly.
              </FormHelp>
            </div>
            <div css={{marginBottom: '1.5rem'}}>
              <Label>Enable Experian</Label>
              <Controller
                name='isExperianApproved'
                control={control}
                render={({field}) => (
                  <Toggle
                    defaultChecked={!!field.value}
                    onChecked={field.onChange}
                  />
                )}
              />
              <FormHelp>
                You should only set this to true if you have permission from the
                organization. Data is grabbed weekly.
              </FormHelp>
            </div>
            <div css={{marginBottom: '1.5rem'}}>
              <Label>Enable Segments</Label>
              <Controller
                name='isSegmentsEnabled'
                control={control}
                render={({field}) => (
                  <Toggle
                    defaultChecked={!!field.value}
                    onChecked={field.onChange}
                  />
                )}
              />
              <FormHelp>
                You should set this to true a week after Nielsen and Experian
                were approved.
              </FormHelp>
            </div>
            <Label>Enable Advisor</Label>
            <Controller
              name='isAdvisor'
              control={control}
              render={({field}) => (
                <Toggle
                  defaultChecked={!!field.value}
                  onChecked={field.onChange}
                />
              )}
            />
            <FormHelp>
              You should set this to true if it's a brand. Publishers can do
              this in the dash.
            </FormHelp>
          </FormGroup>
          <FormFooter>
            <Submit disabled={loading} loading={loading}>
              Save Organization
            </Submit>
          </FormFooter>
        </Form>
      </Card>
    </ResponsiveContent>
  );
}
