import {gql} from '@apollo/client';

export default gql`
  query getReviewers {
    admin {
      reviewers {
        id
        firstName
        lastName
        email
        mturkWorkerId

        totalEpisodeCount
      }
    }
  }
`;
