import {gql} from '@apollo/client';

export default gql`
  query getFutureEpisodes($after: String!, $before: String!) {
    admin {
      futureEpisodes(after: $after, before: $before) {
        id
        title
        subtitle

        expectedPublished

        podcast {
          id
          feed {
            id
            title
            description(words: 25)
            href
            image {
              id
              href
              path
            }
          }
        }
        campaignEpisodes {
          id
        }

        updatedAt
        createdAt
      }
    }
  }
`;
