const FILTER_REGEX = /[^\w\s]/gi;

const searchFilter = <S extends Record<string, any>>({
  data,
  query,
  searchKeys,
}: {
  data: S[];
  query: string;
  searchKeys: string[];
}) => {
  if (!data) return [];

  if (!query || searchKeys.length === 0) return data.filter(Boolean);

  const queryRegex = new RegExp(query.replace(FILTER_REGEX, ''), 'i');

  if (/\w+:\w+$/.test(query)) {
    const arr = query.split(':');
    searchKeys = [arr[0]];
  }

  return data.filter((item) => {
    if (!item) return false;
    return !!searchKeys.find((propName) => {
      const value =
        propName.split('.').length > 1
          ? propName.split('.').reduce((itm, prop) => itm[prop], item)
          : item[propName];

      return (
        typeof value === 'string' &&
        queryRegex.test(value.replace(FILTER_REGEX, ''))
      );
    });
  });
};

export default searchFilter;
