import {ThemeOptions} from '@mui/material/styles';
import {Link} from 'react-router-dom';
import {MuiAutocompleteStyles} from '@core/ui/Autocomplete/Autocomplete';
import {MuiBreadcrumbsStyles} from '@core/ui/Breadcrumbs/Breadcrumbs';
import {MuiCheckboxStyles} from '@core/ui/Checkbox/Checkbox.new';
import {MuiStackStyles} from '@core/ui/Layout/Stack';
import {MuiProgressBarStyles} from '@core/ui/LinearProgress/LinearProgress';
import {MuiRadioStyles} from '@core/ui/Radio/Radio';
import {MuiSkeletonStyles} from '@core/ui/Skeleton/Skeleton';
import {MuiSliderStyles} from '@core/ui/Slider/Slider';
import {MuiSwitchStyles} from '@core/ui/Switch';
import {MuiToggleButtonStyles} from '@core/ui/ToggleButton/ToggleButton';

export const components: ThemeOptions = {
  components: {
    ...MuiAutocompleteStyles,
    ...MuiBreadcrumbsStyles,
    ...MuiCheckboxStyles,
    MuiFormHelperText: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiListItemButton: {
      defaultProps: {
        LinkComponent: Link,
      },
    },
    ...MuiRadioStyles,
    ...MuiSkeletonStyles,
    ...MuiSliderStyles,
    ...MuiStackStyles,
    ...MuiSwitchStyles,
    ...MuiToggleButtonStyles,
    ...MuiProgressBarStyles,
  },
};
