import {useState} from 'react';
import {
  AdminOrganizationDocument,
  useRemoveDomainMutation,
} from '@admin/graphql-api';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {Form, FormFooter, Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';

const DomainsDelete = ({
  organization,
  match: {
    params: {domainId},
  },
}) => {
  const organizationUrl = `/organizations/${organization.id}`;
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [removeDomain] = useRemoveDomainMutation();
  const {
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm({
    defaultValues: {
      domain: '',
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    try {
      await removeDomain({
        variables: {
          input: {
            id: domainId,
          },
        },
        refetchQueries: [AdminOrganizationDocument],
      });
      history.push(organizationUrl);
    } catch (err) {
      setError('', err);
    }
    setLoading(false);
  };

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={organizationUrl}>Delete Email Domain</CardHeader>
      <Card>
        <p>
          If an email domain is removed, new users from that domain will no
          longer be automatically added to that organization. Any exisiting
          members from that domain should retain access to the organization.
        </p>
        <Form errors={errors} onSubmit={handleSubmit(onSubmit)}>
          <FormFooter>
            <Button to={organizationUrl}>Cancel</Button>
            <Submit loading={loading} disabled={loading}>
              Delete Domain
            </Submit>
          </FormFooter>
        </Form>
      </Card>
    </ResponsiveContent>
  );
};

export default DomainsDelete;
