import {gql} from '@apollo/client';

export default gql`
  query getCompanies(
    $query: String
    $offset: Int
    $limit: Int
    $order: String
    $duplicates: Boolean
  ) {
    admin {
      companies(
        query: $query
        offset: $offset
        limit: $limit
        order: $order
        duplicates: $duplicates
      ) {
        id
        name
        slug
        productsCount
        adsCount
      }
    }
  }
`;
