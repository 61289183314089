import logger from '@spotify-internal/isomorphic-logger';

import Reporter from './Reporter';
import { Metric } from '../SemanticMetrics';

export class ConsoleReporter implements Reporter {
  constructor(
    private spacingLevel: number = 2,
    private logLevel: string = 'info',
  ) {}

  async send(metrics: Metric[]): Promise<void> {
    logger.log(
      this.logLevel,
      `[semantic-metrics] ${JSON.stringify(metrics, null, this.spacingLevel)}`,
    );
  }
}
