import {ThemeOptions} from '@mui/material';
import MuiBreadcrumbs, {
  type BreadcrumbsProps as MuiBreadcrumbsProps,
} from '@mui/material/Breadcrumbs';
import {truncate} from '@core/lib/words';
import {Stack} from '../Layout';
import {Link} from '../Link';
import {Text} from '../Text';

export interface Breadcrumb {
  title: string;
  to?: string;
}

export type BreadcrumbsProps = Pick<
  MuiBreadcrumbsProps,
  | 'aria-label'
  | 'expandText'
  | 'itemsAfterCollapse'
  | 'itemsBeforeCollapse'
  | 'maxItems'
  | 'separator'
> & {
  breadcrumbs: Breadcrumb[];
};

export function Breadcrumbs({breadcrumbs, ...props}: BreadcrumbsProps) {
  return (
    <Stack spacing={2} {...props}>
      <MuiBreadcrumbs separator='>'>
        {breadcrumbs.map(({title, to}, idx) =>
          to ? (
            <Link
              key={idx}
              to={to}
              underline='hover'
              variant='subtitle1'
              color='muted'>
              {truncate(title, 30)}
            </Link>
          ) : (
            <Text key={idx} variant='subtitle1' color='default'>
              {truncate(title, 30)}
            </Text>
          )
        )}
      </MuiBreadcrumbs>
    </Stack>
  );
}

export const MuiBreadcrumbsStyles: ThemeOptions['components'] = {
  MuiBreadcrumbs: {
    defaultProps: {
      'aria-label': 'breadcrumb',
    },
  },
};
