import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {TrackGesture} from '@core/lib/useTrackGesture';
import {clampValue} from '@core/lib/utils';
import {Interval} from '@core/ui/DataTable';
import {RangeSliderHandle} from './RangeSliderHandle';

export interface RangeSliderProps {
  defaultRange?: Interval;
  max?: number;
  min?: number;
  onChange?: (_props: Interval) => void;
}

const RangeSlider = forwardRef<
  {setRange: Dispatch<SetStateAction<Interval>>},
  RangeSliderProps
>(
  (
    {max = 100, min = 0, defaultRange = [0, 100] as Interval, onChange},
    ref
  ): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);
    const draggingHandle = useRef<string>();
    const [range, setRange] = useState<Interval>(defaultRange);

    useImperativeHandle(ref, () => ({
      setRange,
    }));

    useEffect(() => {
      let _disposable: TrackGesture | undefined = undefined;

      if (containerRef.current) {
        _disposable = new TrackGesture({
          target: containerRef.current,
          onTrack: ({startX, dx, targetRect}) => {
            const left = clampValue(startX + dx, 0, targetRect.width);
            const x = clampValue((left * max) / targetRect.width, min, max);

            setRange((prev) => {
              if (!draggingHandle.current) {
                draggingHandle.current =
                  Math.abs(prev[0] - x) <= Math.abs(prev[1] - x)
                    ? 'left'
                    : 'right';
              }

              const newRange: Interval =
                draggingHandle.current === 'left'
                  ? [Math.min(x, prev[1]), prev[1]]
                  : [prev[0], Math.max(prev[0], x)];

              return newRange;
            });
          },
          onTrackEnd: () => {
            setRange((prev) => {
              onChange?.(prev);
              return prev;
            });
            draggingHandle.current = '';
          },
        });
      }

      return () => {
        _disposable = undefined;
      };
    }, [max, min, onChange]);

    const startPct = `${(range[0] * 100) / max}%`;
    const endPct = `${(range[1] * 100) / max}%`;

    return (
      <div
        ref={containerRef}
        css={{
          cursor: 'pointer',
          padding: '0.625rem 0',
          position: 'relative',
          touchAction: 'none',
        }}>
        <RangeSliderHandle style={{left: startPct}} />
        <RangeSliderHandle style={{left: endPct}} />
        <div
          css={{
            height: '0.1875rem',
            background: `linear-gradient(90deg, var(--border-default) ${startPct}, var(--green) ${startPct}, var(--green) ${endPct}, var(--border-default) ${endPct})`,
          }}
        />
      </div>
    );
  }
);

export default RangeSlider;
