import {useState} from 'react';
import {TRules, css} from '@core/style';

/**
 * @deprecated
 */
export type CheckboxProps = {
  children?: React.ReactNode;
  defaultValue?: boolean;
  disabled?: boolean;
  inline?: boolean;
  invalid?: boolean;
  label?: string;
  onChecked?: (_checked: boolean) => void;
  readOnly?: boolean;
  rules?: TRules;
  tabIndex?: number;
};

/**
 * @deprecated
 */
const Checkbox = ({
  children,
  defaultValue = false,
  disabled = false,
  invalid = false,
  label,
  onChecked,
  readOnly = false,
  rules,
  tabIndex = 0,
}: CheckboxProps) => {
  const [checked, setChecked] = useState(defaultValue);

  return (
    <button
      type='button'
      role='checkbox'
      aria-checked={checked}
      tabIndex={tabIndex}
      disabled={disabled}
      onClick={() => {
        if (!disabled && !readOnly) {
          setChecked(!checked);
          onChecked?.(!checked);
        }
      }}
      {...css([
        () => ({
          alignItems: 'center',
          background: 0,
          border: 0,
          display: 'inline-flex',
          margin: 0,
          padding: 0,
          verticalAlign: 'middle',
          ...((disabled || readOnly) && {
            pointerEvents: 'none',
          }),
          ':focus-visible': {
            boxShadow: 'inset 0 0 0 2px var(--white)',
            outline: '2px solid var(--blue)',
          },
        }),
        rules,
      ])}>
      <div
        css={{
          alignItems: 'center',
          background: checked ? 'var(--blue)' : 'var(--bg-default)',
          border: `0.125rem solid ${
            invalid
              ? 'var(--red-orange)'
              : checked
              ? 'var(--blue)'
              : 'var(--border-default)'
          }`,
          borderRadius: '0.25rem',
          cursor: 'pointer',
          display: 'flex',
          flexShrink: 0,
          height: '1.125rem',
          justifyContent: 'center',
          position: 'relative',
          width: '1.125rem',
          ':hover': {
            borderColor: checked ? 'var(--blue)' : 'var(--icon-subtle)',
          },
        }}>
        {checked ? (
          <div
            css={{
              borderBottom: `0.125rem solid white`,
              borderRight: `0.125rem solid white`,
              color: '#fff',
              height: '0.625rem',
              left: '0.3125rem',
              position: 'absolute',
              top: '0.0625rem',
              transform: 'rotate(45deg)',
              width: '0.3125rem',
            }}
          />
        ) : null}
      </div>
      {label || children ? (
        <div
          onClick={(evt) => {
            if ((evt.target as HTMLElement)?.tagName?.toLowerCase() === 'a') {
              evt.stopPropagation();
            }
          }}
          css={{lineHeight: 1.5, marginLeft: '0.5rem', textAlign: 'left'}}>
          {label || children}
        </div>
      ) : null}
    </button>
  );
};

export default Checkbox;
