import React, {FC, HTMLProps} from 'react';
import {getSortDirection, getSortKey} from '@core/lib/sortBy';
import Checkbox from '../Checkbox';
import {Icon, IconName} from '../Icon';
import {Tooltip} from '../Tooltip';
import {DataTableBulkAction, DataTableColumn} from './types';

const UnselectAllCheckbox: FC<HTMLProps<HTMLButtonElement>> = (
  props
): JSX.Element => {
  return (
    <button
      tabIndex={0}
      css={{
        alignItems: 'center',
        border: '0.125rem solid var(--border-default)',
        borderRadius: '0.25rem',
        display: 'inline-flex',
        height: '1.125rem',
        padding: '0.125rem',
        verticalAlign: 'middle',
        width: '1.125rem',
        ':hover': {
          borderColor: 'var(--icon-subtle)',
        },
      }}
      {...props}
      type='button'>
      <div
        css={{
          background: 'var(--icon-muted)',
          display: 'flex',
          height: '0.125rem',
          width: '100%',
        }}
      />
    </button>
  );
};

interface DataTableHeaderTitle {
  /**
   * If true, the header title will be rendered with a blue color.
   * @default false
   */
  active?: boolean;
  description?: string;
  iconName?: IconName;
  longDescription?: string;
  longTitle?: string;
  title?: string;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const DataTableHeaderTitle: FC<DataTableHeaderTitle> = ({
  active = false,
  description,
  iconName,
  longDescription,
  longTitle,
  title,
}): JSX.Element => {
  const content = (
    <>
      {iconName ? (
        <Icon
          icon={iconName}
          rules={() => ({
            color: active ? 'var(--icon-default)' : 'var(--icon-subtle)',
            height: '1.25rem',
            marginRight: '0.375rem',
            width: '1.25rem',
          })}
        />
      ) : null}
      {title}
    </>
  );

  const _rule = () =>
    ({
      alignItems: 'center',
      color: active ? 'var(--text-default)' : 'var(--text-muted)',
      display: 'flex',
      fontSize: '0.875rem',
      fontWeight: 500,
      letterSpacing: '-0.5px',
      userSelect: 'none',
      whiteSpace: 'nowrap',
    } as const);

  if (longTitle || longDescription || description) {
    return (
      <Tooltip
        title={longTitle}
        description={longDescription || description}
        css={_rule()}>
        {content}
      </Tooltip>
    );
  }
  return <div css={_rule()}>{content}</div>;
};

interface DataTableHeaderColumnProps {
  column: DataTableColumn;
  onUpdateSortBy: (_str: string) => void;
  sortBy: string;
}

const DataTableHeaderColumn: FC<DataTableHeaderColumnProps> = ({
  onUpdateSortBy,
  sortBy,
  column: {
    accessor,
    alignRight,
    bgColor,
    borderColor,
    description,
    iconName,
    longDescription,
    longTitle,
    noSortable,
    onSort,
    textColor,
    title,
    type,
    width,
  },
}): JSX.Element => {
  const sortable = title && !noSortable;
  const active = sortable && getSortKey(sortBy) === accessor;
  const direction = active ? getSortDirection(sortBy) : 0;
  const shouldAlignRight =
    typeof alignRight === 'boolean' ? alignRight : type === 'number';

  const onClick = sortable
    ? () => {
        const newSortBy = !active || direction > 0 ? `-${accessor}` : accessor;

        onSort?.(newSortBy);
        onUpdateSortBy(newSortBy);
      }
    : null;

  return (
    <th
      key={accessor}
      onClick={() => onClick?.()}
      css={{
        borderBottom: `0.0625rem solid ${
          borderColor || 'var(--table-border-row)'
        }`,
        fontWeight: 500,
        padding: '12px 0.625rem 11px',
        background: bgColor || 'var(--table-header-bg)',
        color: textColor,
        minWidth: width,
      }}>
      {title ? (
        <div
          css={{
            alignItems: 'center',
            cursor: sortable ? 'pointer' : 'default',
            display: 'flex',
            flexDirection: shouldAlignRight ? 'row-reverse' : undefined,
            justifyContent: 'flex-start',
            ':hover': {
              '> svg': {
                opacity: active ? 1 : 0.5,
              },
            },
          }}>
          <DataTableHeaderTitle
            active={!!active}
            description={description}
            iconName={iconName}
            longDescription={longDescription}
            longTitle={longTitle}
            title={title}
          />
          {sortable ? (
            <Icon
              icon='arrow-up'
              rules={() => ({
                [shouldAlignRight ? 'marginRight' : 'marginLeft']: '0.25rem',
                color: active ? 'var(--icon-primary)' : 'var(--icon-muted)',
                display: 'flex',
                height: '1rem',
                opacity: active ? 1 : 0,
                transform: direction <= 0 ? 'rotate(180deg)' : undefined,
                transition: 'transform .2s linear, opacity .1s linear',
                width: '1rem',
              })}
            />
          ) : null}
        </div>
      ) : null}
    </th>
  );
};

interface DataTableHeaderProps {
  bulkActions?: DataTableBulkAction[];
  columns: DataTableColumn[];
  filteredData?: any[];
  onUpdateSortBy: (_str: string) => void;
  selectedRows?: any[];
  setSelectedRows?: (_rows: string[]) => void;
  sortBy: string;
}

const DataTableHeader: FC<DataTableHeaderProps> = ({
  bulkActions = [],
  columns,
  filteredData,
  onUpdateSortBy = () => null,
  selectedRows,
  setSelectedRows,
  sortBy,
}): JSX.Element => {
  let bulkActionsContent: React.ReactNode;

  if (bulkActions.length) {
    const displayRemoveSelection =
      selectedRows?.length && selectedRows?.length !== filteredData?.length;

    bulkActionsContent = (
      <th
        css={{
          borderBottom: '0.0625rem solid var(--table-border-row)',
          fontWeight: 500,
          padding: '0.375rem 1rem',
          verticalAlign: 'middle',
          width: '2rem',
        }}>
        {displayRemoveSelection ? (
          <UnselectAllCheckbox onClick={() => setSelectedRows?.([])} />
        ) : (
          <Checkbox
            key={selectedRows?.length === filteredData?.length ? 'all' : 'none'}
            defaultValue={selectedRows?.length === filteredData?.length}
            onChecked={(checked) => {
              if (!checked || !filteredData) {
                setSelectedRows?.([]);
              } else {
                setSelectedRows?.(filteredData);
              }
            }}
            rules={() => ({display: 'inline'})}
          />
        )}
      </th>
    );
  }

  return (
    <thead>
      <tr>
        {bulkActionsContent}
        {columns.map((column) => (
          <DataTableHeaderColumn
            key={column.accessor}
            column={column}
            sortBy={sortBy}
            onUpdateSortBy={onUpdateSortBy}
          />
        ))}
      </tr>
    </thead>
  );
};

export {DataTableHeaderColumn};

export default DataTableHeader;
