import {useAdminFeedQuery} from '@admin/graphql-api';
import {Banner} from '@spotify-internal/encore-web';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import Loading from '@core/ui/Loading';
import {Feed} from './Feed';

export function FeedIndex({
  match: {
    params: {feedId},
  },
}) {
  const {data, loading, error} = useAdminFeedQuery({
    variables: {
      id: feedId,
    },
  });

  if (loading) {
    return <Loading absolute />;
  }

  if (error) {
    return <Banner color='negative' />;
  }

  return (
    <Switch>
      <PropsRoute
        exact
        path='/feeds/:feedId'
        feed={data?.admin?.feed}
        component={Feed}
      />
    </Switch>
  );
}
