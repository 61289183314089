import React from 'react';
import {Element, GREEN, createStyledElement, lighten} from '@core/style';

const BaseForm = createStyledElement('form', ({dragging}) => ({
  background: dragging ? lighten(GREEN, 0.7) : 'var(--bg-subtle)',
  borderRadius: '1rem',
  margin: '1rem',
  padding: '3rem',
  position: 'relative',
}));

class UploadForm extends React.Component {
  state = {
    dragging: false,
  };

  onChange = (evt) => {
    this.props.addFiles(evt.target.files);
  };

  onDrop = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    this.setState({
      dragging: false,
    });

    this.props.addFiles(evt.dataTransfer.files);
  };

  onDragEnter = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({
      dragging: true,
    });
  };
  onDragLeave = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({
      dragging: false,
    });
  };

  cancelEvent = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  render() {
    return (
      <BaseForm
        method='post'
        action='.'
        encType='multipart/form-data'
        onDragOver={this.onDragEnter}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDragEnd={this.onDragLeave}
        onDrag={this.cancelEvent}
        onDragStart={this.cancelEvent}
        onSubmit={this.cancelEvent}
        onDrop={this.onDrop}
        style-dragging={this.state.dragging}
        rules={this.props.rules}>
        <Element
          rules={() => ({display: 'none'})}
          tag='input'
          id='file'
          type='file'
          multiple
          onChange={this.onChange}
        />
        {this.props.children}
      </BaseForm>
    );
  }
}

export default UploadForm;
