import { alto, altoBrio, ballad, balladBold, bass, brio, canon, cello, celloCanon, cssColorValue, finale, finaleBold, fontWeightBlack, fontWeightBold, fontWeightBook, forte, mesto, mestoBold, metronome, minuet, minuetBold, rootStyle, viola, violaBold } from "../../styles/index.js";
import { assertNever } from "../../typeHelpers.js";
import styled, { css } from "../../styled-components.js";
export var variants = {
  bass: 'bass',
  forte: 'forte',
  brio: 'brio',
  altoBrio: 'altoBrio',
  alto: 'alto',
  canon: 'canon',
  celloCanon: 'celloCanon',
  cello: 'cello',
  ballad: 'ballad',
  balladBold: 'balladBold',
  viola: 'viola',
  violaBold: 'violaBold',
  mesto: 'mesto',
  mestoBold: 'mestoBold',
  metronome: 'metronome',
  finale: 'finale',
  finaleBold: 'finaleBold',
  minuet: 'minuet',
  minuetBold: 'minuetBold'
};
export var weights = {
  book: 'book',
  bold: 'bold',
  black: 'black'
};
export var typeWeight = function typeWeight(weight) {
  switch (weight) {
    case 'book':
      return fontWeightBook;
    case 'bold':
      return fontWeightBold;
    case 'black':
      return fontWeightBlack;
    default:
      return assertNever(weight);
  }
};
var variantStyles = function variantStyles(variant) {
  switch (variant) {
    case 'bass':
      return bass();
    case 'forte':
      return forte();
    case 'brio':
      return brio();
    case 'altoBrio':
      return altoBrio();
    case 'alto':
      return alto();
    case 'canon':
      return canon();
    case 'celloCanon':
      return celloCanon();
    case 'cello':
      return cello();
    case 'ballad':
      return ballad();
    case 'balladBold':
      return balladBold();
    case 'viola':
      return viola();
    case 'violaBold':
      return violaBold();
    case 'mesto':
      return mesto();
    case 'mestoBold':
      return mestoBold();
    case 'metronome':
      return metronome();
    case 'finale':
      return finale();
    case 'finaleBold':
      return finaleBold();
    case 'minuet':
      return minuet();
    case 'minuetBold':
      return minuetBold();
    default:
      return viola();
  }
};
export var TypeElement = styled.span.withConfig({
  displayName: "Type__TypeElement",
  componentId: "sc-goli3j-0"
})(["", ";margin-block-start:0;margin-block-end:0;", ";", ";", " ", ";"], rootStyle(), function (props) {
  return props.variant ? variantStyles(props.variant) : undefined;
}, function (props) {
  return props.paddingBottom && css(["padding-block-end:", ";"], props.paddingBottom);
}, function (props) {
  return props.semanticColor ? css(["color:", ";"], cssColorValue(props.semanticColor)) : css(["color:inherit;"]);
}, function (props) {
  return props.weight && css(["font-weight:", ";"], typeWeight(props.weight));
});