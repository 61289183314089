import React from 'react';
import PublishMessage from '@admin/graphql-api/_old_mutations/PublishMessage';
import QueueQuery from '@admin/graphql-api/_old_queries/QueueQuery';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import {Element} from '@core/style';
import {Input, Label} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';

class MessagePublish extends React.Component {
  state = {
    loading: false,
    redirect: false,
    error: false,

    action: '',
    attributes: '',
  };

  onChange = ({target: {name, value}}) => {
    const update = {error: false};
    update[name] = value;
    this.setState(update);
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const {publishMessage} = this.props;
    const {action, attributes} = this.state;

    if (!action || !attributes) {
      return;
    }

    this.setState({
      loading: true,
    });

    publishMessage({action, attributes})
      .then(() => {
        this.setState({
          loading: false,
          redirect: true,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  };

  render() {
    const {loading, action, attributes, redirect, error} = this.state;
    const {topic} = this.props.match.params;

    if (redirect) {
      return <Redirect to={`/queue/${topic}`} />;
    }

    return (
      <ResponsiveContent>
        <form onSubmit={this.onSubmit}>
          <Label>Action</Label>
          <Input
            type='text'
            name='action'
            value={action}
            onChange={this.onChange}
            placeholder='Action'
            style-error={error}
            disabled={loading}
          />

          <Label>Attributes</Label>
          <Input
            type='text'
            name='attributes'
            value={attributes}
            onChange={this.onChange}
            placeholder='feed_id:one,episode_id:two'
            style-error={error}
            disabled={loading}
          />

          <Element rules={() => ({padding: '20px 0', textAlign: 'right'})}>
            <Submit variant='outlined' value='Publish' disabled={loading} />
          </Element>
        </form>
      </ResponsiveContent>
    );
  }
}

export default graphql(PublishMessage, {
  props: ({
    ownProps: {
      match: {
        params: {topic},
      },
    },
    mutate,
  }) => ({
    publishMessage: (input) => {
      input.topic = topic;
      return mutate({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: QueueQuery,
            variables: {
              topic,
            },
          },
        ],
      });
    },
  }),
})(MessagePublish);
