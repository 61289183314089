import { Nanoseconds } from '../SemanticMetrics';

export class Time {
  static fromNanos(nanos: number) {
    return new Time(nanos);
  }

  static fromMicros(micros: number) {
    return new Time(micros * 1000);
  }

  static fromMillis(ms: number) {
    return new Time(ms * 1e6);
  }

  static fromSeconds(seconds: number) {
    return new Time(seconds * 1e9);
  }

  static from(time: Time) {
    return new Time(time.asNanos());
  }

  static readonly ZERO = new Time(0);

  constructor(protected nanos: number = 0) {}

  asSeconds() {
    return this.nanos / 1e9;
  }

  asMillis() {
    return this.nanos / 1e6;
  }

  asMicros() {
    return this.nanos / 1000;
  }

  asNanos(): Nanoseconds {
    return Math.round(this.nanos) as Nanoseconds;
  }
}
