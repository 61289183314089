/**
 * Status Family for HTTP Responses.
 *
 * @enum {number}
 */
export enum StatusFamily {
  /**
   * An error in the user agent prevents the client from fetching the actual
   * status code, so a 0 is used instead.
   */
  CONNECTION_ERROR = 0,

  /**
   * An informational status code in the 1xx range.
   */
  INFORMATIONAL = 1,

  /**
   * A successful status code in the 2xx range.
   */
  SUCCESS = 2,

  /**
   * A redirectional status code in the 3xx range.
   */
  REDIRECTION = 3,

  /**
   * A client error status code in the 4xx range.
   */
  CLIENT_ERROR = 4,

  /**
   * A server error status code in the 5xx range.
   */
  SERVER_ERROR = 5,
}
