import {useState} from 'react';
import {useDeleteCompanyMutation} from '@admin/graphql-api';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {FORM_ERRORS, Form, FormFooter, Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {CompaniesPath, toCompanyUrl} from './_routes';
import {CompanyType} from './_types';

interface Props {
  company: CompanyType;
}

export default function CompanyDelete({company}: Props) {
  const history = useHistory();
  const [deleteCompany] = useDeleteCompanyMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const [issues, setIssues] = useState<Array<string | null>>([]);
  const {
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm();

  const onSubmit = async () => {
    setLoading(true);
    try {
      const resp = await deleteCompany({
        variables: {
          input: {
            id: company.id,
          },
        },
      });
      if (resp.data?.deleteCompany?.issues) {
        setIssues(resp.data.deleteCompany.issues ?? []);
      } else {
        history.push(CompaniesPath);
      }
    } catch (err) {
      setError('', FORM_ERRORS.network);
    }
  };

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={toCompanyUrl(company)}>Delete Company</CardHeader>
      <Card>
        {issues.length ? (
          <>
            <p>Before deleting you must address the following:</p>
            {issues.join(', ')}
          </>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)} errors={errors}>
            <p>Are you sure you want to delete this company?</p>
            <FormFooter>
              <Button to={toCompanyUrl(company)}>Cancel</Button>
              <Submit value='Delete' color='error' loading={loading} />
            </FormFooter>
          </Form>
        )}
      </Card>
    </ResponsiveContent>
  );
}
