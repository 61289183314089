import {ReactNode, forwardRef} from 'react';
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonProps as MuiToggleButtonProps,
  ThemeOptions,
  darken,
} from '@mui/material';
import {Icon} from '../Icon';

export interface ToggleButtonProps extends MuiToggleButtonProps {
  unselectedIcon?: ReactNode;
  selectedIcon?: ReactNode;
  iconPosition?: 'start' | 'end';
}
export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  function ToggleButton(
    {
      children,
      color = 'primary',
      iconPosition = 'start',
      selectedIcon = <Icon icon='checkmark' />,
      unselectedIcon = <Icon icon='plus' />,
      ...props
    },
    ref
  ) {
    const icon = props.selected ? selectedIcon : unselectedIcon;

    return (
      <MuiToggleButton color={color} ref={ref} {...props}>
        {iconPosition === 'start' && icon}
        {children}
        {iconPosition === 'end' && icon}
      </MuiToggleButton>
    );
  }
);

export const MuiToggleButtonStyles: ThemeOptions['components'] = {
  MuiToggleButtonGroup: {
    defaultProps: {
      color: 'primary',
      exclusive: false,
    },
    styleOverrides: {
      root: ({theme, ownerState: {color = 'primary'}}) => ({
        gap: '0.5rem',
        ['& .MuiToggleButton-root.MuiToggleButtonGroup-grouped']: {
          border: '1px solid var(--border-default)',
          borderRadius: '0.375rem',
          textTransform: 'none',
        },
        ['& .MuiToggleButton-root.MuiToggleButtonGroup-grouped.Mui-selected']: {
          border: `1px solid ${theme.palette[color].main}`,
        },
      }),
    },
  },
  MuiToggleButton: {
    defaultProps: {
      color: 'primary',
      disableRipple: true,
    },
    styleOverrides: {
      root: ({theme, ownerState: {color = 'primary', selected}}) => ({
        ...theme.typography.button,
        border: `1px solid ${
          selected ? theme.palette[color].main : 'var(--border-default)'
        }`,
        padding: '0.3125rem 0.75rem 0.3125rem 0.25rem',
        ['svg']: {
          color: 'inherit',
        },
        ['&.Mui-selected.MuiButtonBase-root']: {
          color: theme.palette[color].contrastText,
          ['svg']: {
            color: 'inherit',
          },
          backgroundColor: theme.palette[color].main,
          ['&:hover']: {
            color: theme.palette[color].contrastText,
            backgroundColor: darken(theme.palette[color].main, 0.1),
          },
        },
      }),
      sizeSmall: {
        fontSize: '0.875rem',
        padding: '0.3125rem 0.75rem',
      },
    },
  },
};
