import {Environment} from './enums/environment';

export {
  Event,
  EventData,
  ContextProvider,
  BrowserContextProvider,
} from './typedefs';

import {
  createUploader,
  FailedEvent,
  UploaderOptions,
  UploadResponse,
} from './_internal/uploader';
import {
  EventSender,
  EventSenderEvent,
  EventSenderOptions,
} from './_internal/event_sender';

export {
  Environment,
  EventSenderEvent,
  EventSenderOptions,
  FailedEvent,
  UploadResponse,
};

export type {EventSender};

/**
 * Create an EventSender instance.
 *
 * @param options - The options for the EventSender instance.
 * @return An EventSender instance.
 */
export function createEventSender<T extends Environment = Environment.BROWSER>(
  options: EventSenderOptions<T>
): EventSender<T> {
  const uploaderOptions: UploaderOptions = {
    suppressPersist: options.suppressPersist,
    transport: options.transport,
  };
  return new EventSender<T>({
    ...options,
    uploaders: {
      authorized: createUploader(uploaderOptions),
      unauthorized: createUploader({
        ...uploaderOptions,
        authorize: false,
      }),
    },
  });
}
