import {FC, PropsWithChildren} from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import {KeySpecifier} from '@apollo/client/cache/inmemory/policies';
import {onError} from '@apollo/client/link/error';
import {RetryLink} from '@apollo/client/link/retry';
import {getGraphQLEndpoint, isProd} from '@core/lib/environment';

const tokenMiddleware = (operation: any) => {
  const token = localStorage.getItem('adanalytics-token');

  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }
  return operation;
};

const retryIf = (error: any) =>
  !!error && isProd && ![400, 500].includes(error.statusCode);

type TService = 'analytics' | 'admin';

const ApolloProviderWrapper: FC<PropsWithChildren<{service: TService}>> = ({
  children,
  service,
}): JSX.Element => {
  const authLink = new ApolloLink((operation, forward) =>
    forward(tokenMiddleware(operation))
  );

  const errorLink = onError(
    ({graphQLErrors, operation: {operationName}, response}) => {
      if (graphQLErrors) {
        const errors = graphQLErrors.map(({message}) => message);
        if (
          response &&
          errors.includes('Failed Authorization' || 'Not Authorized')
        ) {
          response.errors = undefined;
        } else {
          // TODO: Capture LogRocket issue here?
        }
      }
    }
  );

  const retryLink = new RetryLink({
    attempts: {
      max: 2,
      retryIf: retryIf,
    },
  });

  const client = new ApolloClient({
    connectToDevTools: !isProd,
    link: from([
      authLink,
      retryLink,
      errorLink,
      new HttpLink({
        uri: getGraphQLEndpoint(service),
      }),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        AdvisorColumnGroup: {
          // See https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids for how Apollo Cache handles caching API responses.
          // By default, it uses the `__typename` and `id` fields to determine if a response is already in the cache. However, the `AdvisorColumnGroup` type doesn't have an `id` field, so we need to tell Apollo how to determine if a response is already in the cache - in this case, we use the `title` field. However, unlike the default `id` object identifier used by Apollo, if we do not explicitly request the `title` field in a query for an `AdvisorColumnGroup`, the request will throw a runtime error. To avoid this, we use an `AdvisorColumnGroup` fragment that specifies the `title` field for all queries that request an `AdvisorColumnGroup`, and we also wrap this `keyFields` in a function as an extra precaution -- returning `false` from the function will tell Apollo not to normalize/cache the response. We can optimize this further by adding a merge function to the `AdvisorColumnGroup` type policy for how to cache objects that could not be normalized, but that didn't seem necessary for now.
          keyFields: (obj, _context) =>
            obj?.__typename === 'AdvisorColumnGroup' && 'title' in obj
              ? (['title'] satisfies KeySpecifier)
              : false,
        },
      },
    }),
    queryDeduplication: false,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWrapper;
