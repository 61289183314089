import {ResponseHeaders} from '../typedefs';

/**
 * An HTTP Response from Transport.
 *
 * @typeParam T - The type of the `body` field.
 */
export class HTTPResponse<T = any> {
  /**
   * The resolved URL of the response.
   */
  url = '';

  /**
   * The status code of the response.
   */
  status = 0;

  /**
   * The response headers.
   *
   * This field is null unless the request was made with
   * `options.parseResponseHeaders` set to true.
   */
  headers: ResponseHeaders | null = null;

  /**
   * The body of the response, or null if there is no body.
   *
   * The type of this field is dependent on the `options.responseType` value set
   * in the request. If the `responseType` is `'json'`, the type of this value
   * will also depend on whether the request was type-hinted.
   */
  body: T | null = null;

  /**
   * A flag that indicates whether the navigator was online when the request
   * response was sent.
   */
  offline = false;

  /**
   * A timers object for the response.
   */
  timing: {completed: number} | null = null;

  /**
   * A flag that indicates whether the request is "ok" (i.e., status family of 2).
   */
  ok: boolean;

  /**
   * Data that was included in the request.
   */
  metadata: any | null = null;

  /**
   * If provided, this number indicates the amount of time to wait before
   * retrying the request, as indicated by the server.
   *
   * If the request was made with retries enabled, Transport will read the value
   * of this field to determine when to schedule the retry.
   */
  retryAfter?: number;

  /**
   * The retries counter.
   */
  retries: {
    count: number;
  } = {
    count: 0,
  };

  /**
   * @param url - An optional URL for the response.
   * @param status - An optional status for the response;
   */
  constructor(url: string, status: number = 0) {
    this.url = url;
    this.status = status;
    this.ok = status >= 200 && status <= 299;
  }

  /**
   * Return the status family of the status code.
   *
   * @returns The status family of the response.
   */
  getStatusFamily(): number {
    return 0 | (this.status / 100);
  }
}
