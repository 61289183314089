import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {FelaElement} from '@core/style';
import {createStyledElement} from '@core/style';
import {Element} from '@core/style';
import {BLUE, BLUE_80, RED_ORANGE} from '@core/style';
import {THEMES} from '@core/style';
import {Icon} from './Icon';
import Loading from './Loading';

/**
 * @deprecated
 * This component is deprecated.
 */

const Input = ({
  autofocus = false,
  domRef,
  error,
  label,
  onChange,
  rules,
  small,
  withMargin,
  ...props
}) => {
  const ref = domRef || useRef();
  const inputRule = ({error}) => ({
    background: 'var(--bg-default)',
    border: `0.0625rem solid ${error ? RED_ORANGE : 'var(--border-default)'}`,
    borderRadius: '0.375rem',
    color: 'var(--text-default)',
    display: 'block',
    fontSize: '1rem',
    marginBottom: withMargin ? '1rem' : null,
    outline: 0,
    padding: small ? '0.5rem 0.625rem' : '0.8125rem 1rem',
    width: '100%',
    '[disabled]': {
      color: 'var(--text-disabled)',
    },
    ':focus': {
      borderColor: BLUE,
      boxShadow: `0 0 0 1px ${BLUE}`,
    },
    '::placeholder': {
      color: 'var(--text-muted)',
    },
  });

  useEffect(() => {
    if (autofocus) {
      requestAnimationFrame(() => {
        ref.current.select();
      });
    }
  }, []);

  if (label) {
    const [focused, setFocused] = useState(false);
    const [value, setValue] = useState(props.defaultValue);

    return (
      <Element rules={() => ({position: 'relative', lineHeight: '24px'})}>
        <Element
          style-focused={focused}
          style-active={!!value || focused}
          rules={({active, focused}) => ({
            borderRadius: '0.25rem',
            bottom: '1.0625rem',
            color: focused ? BLUE : THEMES.light.textTertiary,
            left: '0.1875rem',
            margin: 'auto',
            padding: '0 1rem',
            pointerEvents: 'none',
            position: 'absolute',
            transformOrigin: 'left',
            transform: active ? 'scale(.75) translateY(-15px)' : '',
            transition:
              'transform 150ms cubic-bezier(0.4,0,0.2,1),opacity 150ms cubic-bezier(0.4,0,0.2,1);',
          })}>
          {label}
        </Element>
        <Element
          domRef={ref}
          tag='input'
          style-error={error}
          style-focused={focused}
          spellCheck='false'
          autoComplete='off'
          onChange={(evt) => {
            if (typeof onChange === 'function') {
              onChange(evt);
            }
            setValue(evt.target.value);
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          rules={[
            inputRule,
            ({focused}) => ({
              padding: '1.625rem 16px 0.5rem',
              ...(focused ? {borderColor: BLUE} : {}),
            }),
            rules,
          ]}
          {...props}
        />
      </Element>
    );
  }
  return (
    <Element
      domRef={ref}
      tag='input'
      style-error={error}
      rules={[inputRule, rules]}
      onChange={onChange}
      {...props}
    />
  );
};

const SearchInput = ({rules, small, domRef, autofocus, ...props}) => {
  const ref = domRef || useRef();

  useEffect(() => {
    if (autofocus) {
      setTimeout(() => {
        requestAnimationFrame(() => {
          ref.current.select();
        });
      });
    }
  }, []);

  return (
    <Element
      rules={[
        () => ({
          display: 'flex',
          position: 'relative',
          textAlign: 'center',
        }),
        rules,
      ]}>
      <Element
        domRef={ref}
        tag='input'
        type='search'
        autoComplete='off'
        spellCheck={false}
        rules={() => ({
          background: 'var(--bg-default)',
          border: '2px solid transparent',
          borderRadius: '0.375rem',
          color: 'inherit',
          display: 'block',
          fontSize: '0.9375rem',
          outline: 0,
          padding: small
            ? '0.25rem .375rem 0.25rem 2.25rem'
            : '.375rem .625rem .375rem 2.25rem',
          transition: 'all .1s linear',
          width: '100%',
          ':hover': {
            background: 'var(--bg-muted)',
          },
          ':focus': {
            background: 'var(--bg-default)',
            borderColor: BLUE_80,
          },
          '::placeholder': {
            color: 'var(--text-muted)',
          },
        })}
        {...props}
      />
      <Icon
        icon='magnify'
        rules={() => ({
          bottom: 0,
          color: 'var(--icon-subtle)',
          height: '1.25rem',
          left: '10px',
          margin: 'auto',
          position: 'absolute',
          top: 0,
          width: '1.25rem',
        })}
      />
    </Element>
  );
};

const TextArea = ({children, rules, withMargin, ...props}) => (
  <Element
    tag='textarea'
    rules={[
      ({error}) => ({
        border: `1px solid ${error ? RED_ORANGE : '#e5e5e5'}`,
        borderRadius: '0.375rem',
        display: 'block',
        marginBottom: withMargin ? '1rem' : null,
        minHeight: '2rem',
        outline: '0',
        overflow: 'auto',
        padding: '0.75rem 1rem',
        resize: 'vertical',
        width: '100%',
        ':focus': {
          borderColor: BLUE,
          boxShadow: `0 0 0 1px ${BLUE}`,
        },
      }),
      rules,
    ]}
    {...props}>
    {children}
  </Element>
);

const Label = createStyledElement('label', ({success, error}) => ({
  color:
    (success && 'var(--green)') || (error && 'var(--red-orange)') || 'inherit',
  display: 'block',
  fontSize: '0.9375rem',
  fontWeight: '500',
  marginBottom: '0.375rem',
  '> input': {
    marginTop: '.25rem',
  },
}));

const InputError = styled(FelaElement)`
  background: var(--red-orange);
  border-radius: 0.5rem;
  color: white;
  display: block;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  text-align: center;
`;

const FormError = ({children, onClose}) => (
  <Element
    rules={() => ({
      background: RED_ORANGE,
      borderRadius: '0.25rem',
      color: '#fff',
      fontSize: '0.9375rem',
      fontWeight: 500,
      marginBottom: '1.5rem',
      padding: '0.375rem',
      position: 'relative',
      textAlign: 'center',
    })}>
    {children}
    {typeof onClose === 'function' ? (
      <Element
        tag='a'
        href='#'
        rules={() => ({
          color: '#fff',
          fontSize: '1.5rem',
          position: 'absolute',
          right: '0.625rem',
          textDecoration: 'none',
          top: '0.3125rem',
        })}
        onClick={(evt) => {
          evt.preventDefault();
          onClose();
        }}>
        &times;
      </Element>
    ) : (
      ''
    )}
  </Element>
);

const GraphErrors = ({errors, onClose}) => {
  return (
    <div>
      {errors.map(({message}, n) => {
        return (
          <FormError key={n} onClose={onClose}>
            {message}
          </FormError>
        );
      })}
    </div>
  );
};

class FormController extends React.Component {
  onChange({target: {name, value}}) {
    const update = {errors: []};
    update[name] = value;
    this.setState(update);
  }

  onCheckboxChange({target: {name, value}}) {
    const update = {errors: false};
    update[name] = !this.state[name];
    this.setState(update);
  }

  clearErrors() {
    this.setState({
      errors: [],
    });
  }

  addError(message) {
    this.setState({
      errors: this.state.errors.concat({
        message,
      }),
    });
  }

  isValid(data) {
    const {required} = this;

    if (!required) {
      return true;
    }

    const missing = required.filter(
      (field) => !data.hasOwnProperty(field) || !data[field]
    );

    if (missing.length == 0) {
      return true;
    }

    this.addError(
      `The following field${
        missing.length > 1 ? 's are' : 'is'
      } required: ${missing
        .map((str) => str.replace(/([A-Z])/g, ' $1').trim())
        .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
        .join(', ')}.`
    );

    return false;
  }
}

const FieldGroup = styled(FelaElement)`
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
`;

const FieldSet = createStyledElement('fieldset', () => ({
  border: `1px solid ${'var(--border-default)'}`,
  borderRadius: '0.5rem',
  margin: '1.5rem 0',
  padding: '2rem',
}));

const Legend = createStyledElement('legend', () => ({
  padding: '0 10px',
  marginLeft: '-10px',
}));

const Help = styled(FelaElement)`
  color: var(--text-muted);
  font-size: 0.8125rem;
  line-height: 1.4;
  padding: 0.375rem 0.125rem 0.125rem;
`;

const Form = ({children, loading, errors, onSubmit, onClose, rules}) => {
  return (
    <Element
      tag='form'
      onSubmit={(evt) => {
        evt.preventDefault();
        onSubmit();
      }}
      rules={rules}>
      {loading ? <Loading centered /> : ''}
      <GraphErrors errors={errors} onClose={onClose} />
      {children}
    </Element>
  );
};

const Select = ({children, rules, ...props}) => {
  return (
    <Element rules={[() => ({position: 'relative'}), rules]}>
      <Element
        tag='select'
        rules={[
          () => ({
            border: `2px solid ${'var(--text-default)'}`,
            borderRadius: '3rem',
            padding: '.6rem 2rem .6rem 1rem',
            width: '100%',
          }),
        ]}
        {...props}>
        {children}
      </Element>
      <Icon
        icon='arrow-right'
        rules={() => ({
          bottom: 0,
          margin: 'auto',
          position: 'absolute',
          right: '0.9rem',
          top: 0,
          transform: 'rotate(90deg)',
        })}
      />
    </Element>
  );
};

const FormOutlineContainer = ({label, rules, children}) => {
  return (
    <Element
      rules={[
        () => ({
          border: `1px solid ${'var(--border-default)'}`,
          borderRadius: '.5rem',
          margin: '1.75rem 0 0.625rem',
          padding: '1rem',
          position: 'relative',
          width: '100%',
        }),
        rules,
      ]}>
      <Label
        rules={() => ({
          background: 'var(--bg-default)',
          left: '0.375rem',
          margin: '0',
          padding: '0.375rem 0.625rem',
          position: 'absolute',
          top: '-1.0625rem',
        })}>
        {label}
      </Label>
      {children}
    </Element>
  );
};

const ToggleContainer = ({helpText, title, children}) => {
  return (
    <Element
      rules={() => ({
        alignItems: 'center',
        display: 'grid',
        gridGap: '3rem',
        padding: '2px',
        gridTemplateColumns: '1fr max-content',
      })}>
      <div>
        {title ? (
          <Label rules={() => ({marginBottom: '0.375rem'})}>{title}</Label>
        ) : (
          ''
        )}
        {helpText ? (
          <Help rules={() => ({padding: 0, margin: '0'})}>{helpText}</Help>
        ) : (
          ''
        )}
      </div>
      {children}
    </Element>
  );
};

export {
  FieldGroup,
  FieldSet,
  Form,
  FormController,
  FormError,
  FormOutlineContainer,
  GraphErrors,
  Help,
  Input,
  InputError,
  Label,
  Legend,
  SearchInput,
  Select,
  TextArea,
  ToggleContainer,
};
