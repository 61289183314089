import { screenXsMin } from '@spotify-internal/encore-foundation';
import { cssBorderRadius, cssBorderWidth, cssColorValue, cssSpacing } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
export var MessageContainer = styled.div.withConfig({
  displayName: "MessageContainer",
  componentId: "sc-1y1cnt4-0"
})(["box-sizing:border-box;border:", " solid transparent;border-radius:", ";margin:auto;max-inline-size:100%;padding-block-start:", ";padding-block-end:", ";padding-inline-start:", ";padding-inline-end:", ";text-align:center;", ""], cssBorderWidth('hairline', '1px'), cssBorderRadius('larger-2', '8px'), cssSpacing('looser', '24px'), cssSpacing('looser', '24px'), cssSpacing('looser-3', '40px'), cssSpacing('looser-3', '40px'), function (_ref) {
  var background = _ref.background,
    variant = _ref.variant,
    maxInlineSize = _ref.maxInlineSize;
  return css(["@media (min-width:", "){max-inline-size:", ";}", ""], screenXsMin, maxInlineSize, variant === 'contextual' && background && css(["@media (min-width:", "){background-color:", ";border-color:", ";}"], screenXsMin, cssColorValue('backgroundElevatedBase'), cssColorValue('decorativeSubdued')));
});