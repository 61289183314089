import {TransportErrors} from '../enums/errors';

/**
 * An error that was thrown during the process of making a request.
 */
export class RequestError extends Error {
  /**
   * The code for this error.
   *
   * This value should be compared to the `TransportError` enum.
   */
  code?: string;

  /**
   * If the error occurred after the response has been received, this field will
   * be set to the status code of the response, or 0 if the status code cannot
   * be retrieved.
   */
  status: number;

  /**
   * The URL of the request that failed.
   */
  url: string;

  constructor(message: string, code: string, status: number, url: string) {
    super(message);
    this.name = 'RequestError';
    this.message = message;

    this.code = code;
    this.status = status || 0;
    this.url = url;
  }
}

export class RequestAbortedError extends RequestError {
  constructor(url: string) {
    super(
      'Request aborted by signal.',
      TransportErrors.HTTP_REQUEST_ABORTED,
      0,
      url
    );
    this.name = 'RequestAbortedError';
    this.message = 'Request aborted by signal.';
  }
}
