import { cssColorValue, semanticColors } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
var focusStyles = css(["outline:none;border:3px solid ", ";"], cssColorValue(semanticColors.essentialBase));
export var TableRowElement = styled.tr.withConfig({
  displayName: "TableRow__TableRowElement",
  componentId: "sc-1kuhzdh-0"
})(["border-bottom:1px solid ", ";color:", ";", ";", ";", ";&:focus{", "}"], cssColorValue(semanticColors.decorativeSubdued), cssColorValue(semanticColors.textSubdued), function (props) {
  return props.hover && css(["cursor:default;&:hover{background-color:", ";border-bottom:1px solid ", ";color:", ";}"], cssColorValue(semanticColors.backgroundTintedHighlight), cssColorValue(semanticColors.decorativeSubdued), cssColorValue(semanticColors.textBase));
}, function (props) {
  return props.select && css(["background-color:", ";border-bottom:1px solid ", ";color:", ";"], cssColorValue(semanticColors.backgroundTintedHighlight), cssColorValue(semanticColors.decorativeSubdued), cssColorValue(semanticColors.textBase));
}, function (props) {
  return props.isFocus && focusStyles;
}, focusStyles);