import {Logger} from '../typedefs';

export class DummyLogger implements Logger {
  tag: string = '';
  description: string = '';

  matchesTag(): boolean {
    return false;
  }

  setLevel(): void {}

  enable(): void {}

  disable(): void {}

  log(): void {}

  debug(): void {}

  info(): void {}

  warn(): void {}

  error(): void {}
}
