import dayjs from 'dayjs';

export type SortDirectionNum = -1 | 1;

export const getSortDirection = (sortBy: string): SortDirectionNum =>
  /^-/.test(sortBy) ? -1 : 1;

export const getSortKey = (sortBy: string): string =>
  sortBy ? sortBy.replace(/^-/, '') : '';

export const sortByString = (
  direction: SortDirectionNum,
  a: string,
  b: string
): number => {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b) * direction;
  }
  return 0;
};

export const sortByNumber = (
  direction: SortDirectionNum,
  a: number,
  b: number
): number => {
  if (typeof a === 'number' && typeof b === 'number') {
    return (a - b) * direction;
  }
  return a;
};

export const sortyByDate = (
  direction: SortDirectionNum,
  a: string | dayjs.Dayjs,
  b: string | dayjs.Dayjs
): number => {
  const dateA = a instanceof dayjs ? (a as dayjs.Dayjs) : dayjs(a);
  const dateB = b instanceof dayjs ? (b as dayjs.Dayjs) : dayjs(b);

  return (dateA.valueOf() - dateB.valueOf()) * direction;
};

export const sortByBoolean = (
  direction: SortDirectionNum,
  a: boolean,
  b: boolean
): number => (a === b ? 0 : a ? -1 * direction : direction);
