import React, {forwardRef} from 'react';
import NotificationsQuery from '@admin/graphql-api/_old_queries/NotificationsQuery';
import {useQuery} from '@apollo/client';
import dayjs from '@core/lib/dayjs';
import {Card, PageTitle} from '@core/ui/Content';
import DataTable, {MultipleSelectFilter} from '@core/ui/DataTable';
import Loading from '@core/ui/Loading';

const Notifications = () => {
  const token = localStorage.getItem('adanalytics-token');
  const {data, error, loading} = useQuery(NotificationsQuery, {
    variables: {
      token,
    },
  });

  if (loading) return <Loading absolute />;

  const notifications = data.admin.notifications.map((item) => ({
    ...item,
    organizationId: item.organization.id,
    organization: item.organization.name,
  }));

  const columns = [
    {
      title: 'Organization',
      accessor: 'organization',
      type: 'string',
      rule: () => ({fontWeight: 500}),
    },
    {
      title: 'Notification',
      accessor: 'kind',
      type: 'string',
      fmt: (v) => v[0].concat(v.slice(1).toLowerCase()),
      Filter: forwardRef(({...props}, ref) => (
        <MultipleSelectFilter ref={ref} searchable={false} {...props} />
      )),
    },
    {
      title: 'Recipients',
      accessor: 'recipients',
      type: 'string',
      noSortable: true,
      fmt: (v) => v.split(',').join(', '),
    },
    {
      title: 'Sent',
      accessor: 'sentAt',
      type: 'date',
      fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
    },
  ];

  return (
    <>
      <PageTitle>Notifications</PageTitle>
      <Card>
        <DataTable
          columns={columns}
          data={notifications}
          searchKeys={['name', 'recipients']}
          searchPlaceholder='Search by organization or email'
          filtersStorageKey='admin-notifications'
          orderBy='-sentAt'
        />
      </Card>
    </>
  );
};

export default Notifications;
