import {Method, Transport} from '@spotify-internal/transport';
import {logging} from '@js-sdk/debug-tools';
import {EventEmitter} from '@spotify-internal/emitter';
import {HTTPResponse} from '@spotify-internal/transport/lib/http/response';

import {Event, EventData} from '../typedefs';

import {UploadEvent} from '../enums/upload_event';

const debugLogger = logging.forTag('Uploader');

const BATCH_SIZE = 20;
const BIG_BATCH_SIZE = 100;

const AUTHORIZED_ENDPOINT = '@webgate/gabo-receiver-service/v3/events';
const UNAUTHORIZED_ENDPOINT = '@webgate/gabo-receiver-service/public/v3/events';

/**
 * The response after event uploading.
 */
export type UploadResponse = {
  /**
   * Non-acknowledged events.
   */
  nack: Event[];
  /**
   * Whether backoff has been requested.
   */
  backoff: boolean;
};

/**
 * The event object that failed to be uploaded.
 */
export type FailedEvent = {
  /**
   * The error reason from backend
   * https://ghe.spotify.net/datainfra/gabo-receiver-service/blob/master/grpc-schemas/src/main/proto/spotify/gabito/producer/v3/gabito_producer.proto
   */
  reason: number;
  /**
   * The `EventData`.
   */
  event_data: EventData;
  /**
   * Key/value pairs of the context payload.
   */
  contexts: Record<string, unknown>;
};

/**
 * A map of the events that can be emitted from the `Uploader` instance.
 */
export type UploaderEventMap = {
  /**
   * Emitted when `Uploader` successfully uploads one or more events to the
   * backend.
   */
  [UploadEvent.UPLOAD_SUCCEEDED]: {
    /**
     * Whether the upload request was authorized.
     */
    authorize: boolean;
    /**
     * A number indicating the number of events that were successfully sent.
     */
    num_events: number;
  };

  /**
   * Emitted when `Uploader` has failed to upload one or more events to the backend.
   */
  [UploadEvent.UPLOAD_FAILED]: {
    /**
     * Whether the upload request was authorized.
     */
    authorize: boolean;
    /**
     * The rejected event(s) due to non-transient reason.
     * No retry will be attempted for this error.
     */
    rejected: FailedEvent[];
    /**
     * The failed event(s) that will be re-upload.
     */
    will_retry: FailedEvent[];
  };

  [UploadEvent.UPLOAD_REQUEST_FAILED]: {
    /**
     * Whether the upload request was authorized.
     */
    authorize: boolean;
    /**
     * The failed response status code.
     */
    status: number;
  };
};

/**
 * The options to configure `Uploader` instance.
 */
export type UploaderOptions = {
  /**
   * Set whether transport should add the authorization header for requests
   * and log to the authorized endpoint (true), or skip the authorization header
   * and log to the non authorized endpoint (false).
   *
   * Defaults to true.
   */
  authorize?: boolean;
  /**
   * The `@spotify-internal/transport` instance to use for making requests.
   */
  transport: Transport; // Should be PublicTransport in the future.
  /**
   * Set to true to prevent the events sent from being persisted.
   */
  suppressPersist?: boolean;
};

function formatFailedEvent(event: Event, reason: number): FailedEvent {
  const {event_name: name, fragments} = event;
  const {message, ...contexts} = fragments;
  return {
    reason,
    contexts,
    event_data: {
      name: name,
      data: message,
    },
  };
}

/**
 * Create a new `Uploader` instance.
 * Handles uploading an array of events to the event delivery endpoint.
 */
export class Uploader extends EventEmitter<UploaderEventMap> {
  private _endpoint: string;
  private _authorize: boolean;
  private _transport: Transport;
  private _suppressPersist: boolean = false;
  private _backoff: boolean = false;

  constructor(options: UploaderOptions) {
    super();
    this._authorize = options.authorize ?? true;
    this._transport = options.transport;
    this._suppressPersist = !!options.suppressPersist;
    this._endpoint = this._authorize
      ? AUTHORIZED_ENDPOINT
      : UNAUTHORIZED_ENDPOINT;
  }

  /**
   * Upload one batch of events to the endpoint.
   *
   * @param events - The events to be uploaded.
   * @param isLastFlush - Whether this is the last attempt to upload or not.
   * @return All events that could not be acknowledged.
   */
  private _uploadBatch(events: Event[], isLastFlush = false): Promise<Event[]> {
    const transport = this._transport;

    // For last flushes, we append the token as part of the URL. This allows
    // us to use Transport's fire-and-forget mode for authenticated requests
    // as well. We only do this for authorized requests.
    const url =
      isLastFlush && this._authorize
        ? transport.appendLastTokenQuery(this._endpoint)
        : this._endpoint;

    return this._transport
      .request(url, {
        method: Method.POST,
        metadata: {
          eventSenderEventNames: events.map((event) => event.event_name),
        },
        headers: {
          'content-type': 'application/json',
        },
        responseType: 'json',
        parseResponseHeaders: true,
        payload: JSON.stringify({
          suppress_persist: this._suppressPersist,
          events,
        }),

        // These two options will be different depending on whether this is
        // the last flush. If this is the last flush, we set the forget option
        // to trigger a fire and forget requests. Since these requests are not
        // allowed to be authorized, we will set the authorize parameter to
        // false.
        forget: isLastFlush,
        authorize: isLastFlush ? false : this._authorize,
      })
      .then(this._parseUploadResponse.bind(this, events, isLastFlush));
  }

  private _parseUploadResponse(
    events: Event[],
    isLastFlush: boolean,
    response: HTTPResponse<{
      error?: [{transient: boolean; index: number; reason: number}];
    }>
  ): Promise<Event[]> {
    if (isLastFlush) {
      // This request was sent as a fire and forget request, so we don't need
      // to perform any other processing. We simply return an empty array as
      // we do not know whether the request succeeded or not.
      return Promise.resolve([]);
    }

    const {body, headers, status} = response;

    const authorize = this._authorize;

    if (status !== 200 || !body) {
      debugLogger.warn('Upload request failed', response);
      this.emit(UploadEvent.UPLOAD_REQUEST_FAILED, {authorize, status});
      this._backoff = true;
      return Promise.resolve(events);
    }

    const nackedEvents: Event[] = [];
    const willRetryEvents: FailedEvent[] = [];
    const rejectedEvents: FailedEvent[] = [];

    if (body.error && body.error.length) {
      // See https://ghe.spotify.net/datainfra/gabo-receiver-service/blob/
      // master/receiver-service/src/main/proto/spotify/event/v3/event.proto
      // for explanation of the various "reason" codes
      debugLogger.info('response errors', events, body.error);

      for (let i = 0, len = body.error.length; i < len; i++) {
        const {transient, index, reason} = body.error[i];
        const failedEvent = formatFailedEvent(events[index], reason);
        if (transient) {
          nackedEvents.push(events[index]);
          willRetryEvents.push(failedEvent);
        } else {
          rejectedEvents.push(failedEvent);
        }
      }
    }

    this._backoff = !!(headers?.get('backoff') === 'true');

    const numFailed = willRetryEvents.length + rejectedEvents.length;
    const numSucceeded = events.length - numFailed;

    if (numFailed > 0) {
      this.emit(UploadEvent.UPLOAD_FAILED, {
        authorize,
        rejected: rejectedEvents,
        will_retry: willRetryEvents,
      });
    }
    if (numSucceeded > 0) {
      this.emit(UploadEvent.UPLOAD_SUCCEEDED, {
        authorize: this._authorize,
        num_events: numSucceeded,
      });
    }

    return Promise.resolve(nackedEvents);
  }

  /**
   * Upload events to the events delivery endpoint.
   *
   * @param evts - The events to be uploaded.
   * @param nacked - The accumulator for non acknowledged events.
   * @return Will be ressolved with non acknowledged events and
   * a flag for if backoff has been triggered.
   */
  upload(evts: Event[], nacked: Event[] = []): Promise<UploadResponse> {
    let nack = nacked;

    if (!evts.length) {
      return Promise.resolve({
        nack,
        backoff: this._backoff,
      });
    }
    const events = [...evts];

    return this._uploadBatch(events.splice(0, BATCH_SIZE)).then(
      (nackedEvents) => {
        // put back nacked events in queue
        nack = [...nack, ...nackedEvents];

        // if backoff, put back rest of events in queue
        if (this._backoff) {
          nack = [...nack, ...events];
        } else if (events.length) {
          return this.upload(events, nack);
        }
        return {
          nack,
          backoff: this._backoff,
        };
      }
    );
  }

  /**
   * Last effort to upload remaining events, uses BIG_BATCH_SIZE (100) instead of
   * BATCH_SIZE (20), and does not retry nacked events or failed requests.
   * Should be used when a client exits abnormally:
   * For example when `window.onbeforeunload` is triggered.
   *
   * @param events - The events to be uploaded.
   * @return Resolves with true if all events were uploaded, false otherwise.
   */
  lastUpload(events: Event[]): Promise<boolean> {
    if (!events.length) {
      return Promise.resolve(true);
    }
    return this._uploadBatch(events.splice(0, BIG_BATCH_SIZE), true).then(
      (nacked) => nacked.length === 0,
      () => false
    );
  }

  /**
   * Check if backoff has been requested during latest upload.
   *
   * @return true if backoff has been requested, else false.
   */
  shouldBackoff(): boolean {
    return this._backoff;
  }
}

/**
 * Creates a new `Uploader` Instance.
 *
 * @param options - The options for creating a new `Uploader` Instance
 * @return The `Uploader` Instance.
 */
export function createUploader(options: UploaderOptions): Uploader {
  return new Uploader(options);
}
