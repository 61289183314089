import DataSelector, {DataSelectorProps} from '@core/ui/DataSelector';
import ProductSearchQuery from './queries/ProductSearchQuery';

const SelectOption = ({name, slug}: {name: string; slug: string}) => {
  return (
    <div>
      {name} <span css={{color: 'var(--text-muted)'}}>({slug})</span>
    </div>
  );
};

const ProductSelector = ({
  companyId,
  ...props
}: Partial<DataSelectorProps> & {companyId: string}) => {
  return (
    <DataSelector
      renderItem={(item) => <SelectOption {...item} />}
      dataQuery={ProductSearchQuery}
      propertyForName='name'
      fromResponse={(data) => data.productSearch}
      selectableInputValue
      selectableInputValuePlaceholder='Create product'
      queryVariables={companyId ? {companyId} : null}
      css={`
        width: 100%;
      `}
      {...props}
    />
  );
};

export default ProductSelector;
