import React from 'react';
import {formatDate, toTimeString} from '@core/lib/time';
import {stripHTML, truncateWords} from '@core/lib/words';
import {Element} from '@core/style';
import Stat from '@core/ui/Stat';

const Review = ({review}) => {
  return (
    <Element
      rules={() => ({
        display: 'flex',
        padding: '10px',
        margin: '10px 0',
        borderBottom: '1px solid #efefef',
      })}>
      <Element rules={() => ({flex: 1, marginLeft: '5px'})}>
        <div>{review.id}</div>
        <div>
          {review.reviewer.firstName} {review.reviewer.lastName}
        </div>
        <Stat value={review.startState} title='start state' />
        <Stat value={review.endState} title='end state' />
        <Stat value={toTimeString(review.duration)} title='duration' />
        <Stat value={formatDate(review.createdAt)} title='review created' />
      </Element>
    </Element>
  );
};

export default Review;
