interface InputPrefixProps {
  children: React.ReactNode;
  prefix: string;
}

export const InputPrefix = ({prefix, children}: InputPrefixProps) => (
  <div
    css={{
      alignItems: 'center',
      display: 'flex',
      fontSize: '0.875rem',
      position: 'relative',
    }}>
    <div
      css={{
        color: 'var(--text-muted)',
        flexShrink: 0,
        left: '.5rem',
        pointerEvents: 'none',
        position: 'absolute',
        top: '0.375rem',
      }}>
      {prefix}
    </div>
    {children}
  </div>
);
