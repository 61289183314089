import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `BigInt` scalar type represents non-fractional whole numeric values.
   * `BigInt` is not constrained to 32-bit like the `Int` type and thus is a less
   * compatible type.
   */
  BigInt: any;
  CoercedBoolean: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  JSONStringNestedSerializer: any;
  /** Mimics behavior of Int in older graphene versions; if given a float, will floor it into an int. */
  RoundedInt: any;
};

export type AcceptCompanyClaim = {
  readonly __typename: 'AcceptCompanyClaim';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type AcceptCompanyClaimInput = {
  readonly id: Scalars['ID'];
};

export type AccessObject = {
  readonly __typename: 'AccessObject';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly objectPk: Scalars['String'];
  readonly organization: LimitedOrganizationObject;
  readonly permission: Scalars['String'];
};

export type ActionMapObject = {
  readonly __typename: 'ActionMapObject';
  readonly action: Maybe<Scalars['String']>;
  readonly eventName: Scalars['String'];
  readonly id: Scalars['String'];
  readonly key: KeyObject;
};

export type ActivityObject = {
  readonly __typename: 'ActivityObject';
  readonly count: Scalars['RoundedInt'];
  readonly time: Scalars['DateTime'];
};

export type AdMarkerObject = {
  readonly __typename: 'AdMarkerObject';
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly seconds: Maybe<Scalars['Float']>;
};

export type AdObject = {
  readonly __typename: 'AdObject';
  readonly company: Maybe<CompanyObject>;
  readonly cost: Maybe<Scalars['Int']>;
  readonly createdAt: Scalars['DateTime'];
  readonly duration: Scalars['Float'];
  readonly firstPublished: Maybe<Scalars['DateTime']>;
  readonly flacPath: Maybe<Scalars['String']>;
  readonly guess: Maybe<AdSearchResult>;
  readonly id: Scalars['String'];
  readonly mpegPath: Maybe<Scalars['String']>;
  readonly placements: Maybe<ReadonlyArray<Maybe<PlacementObject>>>;
  readonly placementsCount: Maybe<Scalars['Int']>;
  readonly product: Maybe<ProductObject>;
  readonly reviews: Maybe<ReadonlyArray<Maybe<ReviewObject>>>;
  readonly sampleRate: Maybe<Scalars['Int']>;
  readonly sponsor: Maybe<CompanyObject>;
  readonly src: Maybe<Scalars['String']>;
  readonly state: AdState;
  readonly text: Maybe<Scalars['String']>;
  readonly transcript: Maybe<Scalars['JSONString']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly waveform: Maybe<Scalars['JSONString']>;
  readonly words: Maybe<Scalars['JSONString']>;
};


export type AdObjectPlacementsArgs = {
  limit: InputMaybe<Scalars['Int']>;
};


export type AdObjectPlacementsCountArgs = {
  after: InputMaybe<Scalars['String']>;
};


export type AdObjectReviewsArgs = {
  reviewerId: InputMaybe<Scalars['ID']>;
};

export type AdSearchResult = {
  readonly __typename: 'AdSearchResult';
  readonly body: Maybe<Scalars['String']>;
  readonly companyId: Maybe<Scalars['String']>;
  readonly companyName: Maybe<Scalars['String']>;
  readonly duration: Maybe<Scalars['Float']>;
  readonly id: Maybe<Scalars['String']>;
  readonly kind: Maybe<Scalars['String']>;
  readonly maxScore: Maybe<Scalars['Float']>;
  readonly productId: Maybe<Scalars['String']>;
  readonly productName: Maybe<Scalars['String']>;
  readonly score: Maybe<Scalars['Float']>;
};

/** An enumeration. */
export enum AdState {
  /** Approved */
  Approved = 'APPROVED',
  /** Duplicate */
  Duplicate = 'DUPLICATE',
  /** edited */
  Edited = 'EDITED',
  /** Initial Review */
  Initial = 'INITIAL',
  /** Media Generated */
  Media = 'MEDIA',
  /** New */
  New = 'NEW',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Split */
  Split = 'SPLIT',
  /** Transcript Generated */
  Transcript = 'TRANSCRIPT',
  /** Unknown */
  Unknown = 'UNKNOWN'
}

export type AddAccess = {
  readonly __typename: 'AddAccess';
  readonly access: Maybe<AccessObject>;
};

export type AddAccessInput = {
  readonly contentType: Scalars['String'];
  readonly name: Scalars['String'];
  readonly objectPk: Scalars['ID'];
  readonly organizationId: Scalars['ID'];
  readonly permission: Scalars['String'];
};

export type AddAdStudioAdAccount = {
  readonly __typename: 'AddAdStudioAdAccount';
  readonly linking: Maybe<LinkingRequestObject>;
  readonly success: Maybe<Scalars['Boolean']>;
};

export type AddAdStudioAdAccountInput = {
  readonly businessId: Scalars['String'];
  readonly id: Scalars['ID'];
};

export type AddAdvisorForOrganization = {
  readonly __typename: 'AddAdvisorForOrganization';
  readonly organization: Maybe<OrganizationObject>;
};

export type AddAdvisorForOrganizationInput = {
  readonly id: Scalars['String'];
};

/** We can save the secret on multiple objects, so this is just easier. */
export type AddArt19Secret = {
  readonly __typename: 'AddArt19Secret';
  readonly art19Secret: Maybe<Scalars['String']>;
};

export type AddArt19SecretInput = {
  readonly contentType: Scalars['String'];
  readonly pk: Scalars['ID'];
  readonly secret: Scalars['String'];
};

export type AddDomain = {
  readonly __typename: 'AddDomain';
  readonly organizationDomain: Maybe<OrganizationDomainObject>;
};

export type AddDomainInput = {
  readonly domain: Scalars['String'];
  readonly organizationId: Scalars['String'];
};

export type AddFeed = {
  readonly __typename: 'AddFeed';
  readonly feed: Maybe<FeedObject>;
};

export type AddFeedInput = {
  readonly href: Scalars['String'];
  readonly measure: InputMaybe<Scalars['Boolean']>;
};

export type AddMember = {
  readonly __typename: 'AddMember';
  readonly membership: Maybe<MembershipObject>;
  readonly organizationId: Maybe<Scalars['ID']>;
};

export type AddMemberInput = {
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly host: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly message: InputMaybe<Scalars['String']>;
  readonly organizationId: Scalars['String'];
  readonly role: Scalars['String'];
  readonly sendEmail: InputMaybe<Scalars['Boolean']>;
};

export type AddTag = {
  readonly __typename: 'AddTag';
  readonly companyId: Maybe<Scalars['ID']>;
  readonly productId: Maybe<Scalars['ID']>;
  readonly tag: Maybe<TagObject>;
};

export type AddTagInput = {
  readonly companyId: InputMaybe<Scalars['ID']>;
  readonly id: InputMaybe<Scalars['ID']>;
  readonly label: InputMaybe<Scalars['String']>;
  readonly productId: InputMaybe<Scalars['ID']>;
};

export type Admin = {
  readonly __typename: 'Admin';
  readonly billingRecords: Maybe<ReadonlyArray<Maybe<BillingRecord>>>;
  readonly campaign: Maybe<CampaignObject>;
  readonly campaigns: Maybe<ReadonlyArray<Maybe<CampaignObject>>>;
  readonly cloudsenseNewBrandRequests: Maybe<ReadonlyArray<Maybe<CloudsenseNewBrandRequestObject>>>;
  readonly companies: Maybe<ReadonlyArray<Maybe<CompanyObject>>>;
  readonly company: Maybe<CompanyObject>;
  readonly companyClaim: Maybe<CompanyClaimObject>;
  readonly companyClaims: Maybe<ReadonlyArray<Maybe<CompanyClaimObject>>>;
  readonly contact: Maybe<ContactObject>;
  readonly contacts: Maybe<ReadonlyArray<Maybe<ContactObject>>>;
  readonly episodelessCampaignEpisodes: Maybe<ReadonlyArray<Maybe<CampaignEpisodeObject>>>;
  readonly feed: Maybe<FeedObject>;
  readonly feedSearch: Maybe<ReadonlyArray<Maybe<FeedObject>>>;
  readonly feeds: Maybe<ReadonlyArray<Maybe<FeedObject>>>;
  readonly financialRecord: Maybe<FinancialObject>;
  readonly futureEpisode: Maybe<FutureEpisodeObject>;
  readonly futureEpisodes: Maybe<ReadonlyArray<Maybe<FutureEpisodeObject>>>;
  readonly invoice: Maybe<InvoiceObject>;
  readonly invoices: Maybe<ReadonlyArray<Maybe<InvoiceObject>>>;
  readonly liftReports: Maybe<ReadonlyArray<Maybe<LiftReportObject>>>;
  readonly management: Maybe<Management>;
  readonly notifications: Maybe<ReadonlyArray<Maybe<OrganizationNotificationObject>>>;
  readonly organization: Maybe<OrganizationObject>;
  readonly organizations: Maybe<ReadonlyArray<Maybe<OrganizationObject>>>;
  readonly prefixlessCampaignPodcasts: Maybe<ReadonlyArray<Maybe<CampaignPodcastObject>>>;
  readonly product: Maybe<ProductObject>;
  readonly queue: Maybe<QueueObject>;
  readonly reviewer: Maybe<ReviewerObject>;
  readonly reviewers: Maybe<ReadonlyArray<Maybe<ReviewerObject>>>;
  readonly reviews: Maybe<ReadonlyArray<Maybe<ReviewObject>>>;
  readonly tags: Maybe<ReadonlyArray<Maybe<TagObject>>>;
  readonly user: Maybe<UserObject>;
  readonly users: Maybe<ReadonlyArray<Maybe<UserObject>>>;
};


export type AdminBillingRecordsArgs = {
  after: Scalars['String'];
  before: InputMaybe<Scalars['String']>;
  span: InputMaybe<Scalars['String']>;
};


export type AdminCampaignArgs = {
  id: Scalars['ID'];
};


export type AdminCompaniesArgs = {
  duplicates: InputMaybe<Scalars['Boolean']>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order: InputMaybe<Scalars['String']>;
  query: InputMaybe<Scalars['String']>;
};


export type AdminCompanyArgs = {
  id: InputMaybe<Scalars['ID']>;
  slug: InputMaybe<Scalars['String']>;
};


export type AdminCompanyClaimArgs = {
  id: Scalars['ID'];
};


export type AdminCompanyClaimsArgs = {
  states: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};


export type AdminContactArgs = {
  id: Scalars['ID'];
};


export type AdminEpisodelessCampaignEpisodesArgs = {
  after: Scalars['String'];
  before: Scalars['String'];
};


export type AdminFeedArgs = {
  id: Scalars['ID'];
};


export type AdminFeedSearchArgs = {
  query: Scalars['String'];
  state: InputMaybe<Scalars['String']>;
};


export type AdminFeedsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  order: InputMaybe<Scalars['String']>;
  query: InputMaybe<Scalars['String']>;
  state: InputMaybe<Scalars['String']>;
};


export type AdminFutureEpisodeArgs = {
  id: Scalars['ID'];
};


export type AdminFutureEpisodesArgs = {
  after: Scalars['String'];
  before: Scalars['String'];
};


export type AdminInvoiceArgs = {
  id: Scalars['ID'];
};


export type AdminNotificationsArgs = {
  kind: InputMaybe<Scalars['String']>;
};


export type AdminOrganizationArgs = {
  id: Scalars['ID'];
};


export type AdminOrganizationsArgs = {
  kind: InputMaybe<Scalars['String']>;
};


export type AdminProductArgs = {
  id: InputMaybe<Scalars['ID']>;
  slug: InputMaybe<Scalars['String']>;
};


export type AdminQueueArgs = {
  topic: Scalars['String'];
};


export type AdminReviewerArgs = {
  id: InputMaybe<Scalars['ID']>;
};


export type AdminTagsArgs = {
  query: InputMaybe<Scalars['String']>;
};


export type AdminUserArgs = {
  id: Scalars['ID'];
};

export type Advisor = {
  readonly __typename: 'Advisor';
  readonly brandsByIndustry: Maybe<BrandsOverviewSearchResults>;
  readonly columnGroups: Maybe<AdvisorColumnGroups>;
  readonly feedSearch: Maybe<AdvisorFeedSearch>;
  readonly id: Scalars['ID'];
  readonly options: Maybe<AdvisorOptions>;
  /** Organization requesting advisor */
  readonly organizationId: Scalars['ID'];
  readonly podcast: Maybe<LimitedPodcastObject>;
  readonly podcastsByIndustry: Maybe<FeedOverviewSearchResults>;
  readonly publisherAffinityScores: Maybe<ReadonlyArray<PublisherAffinityScoreObject>>;
  readonly publisherSearch: Maybe<AdvisorPublisherSearch>;
  readonly publishersByIndustry: Maybe<PublisherOverviewSearchResults>;
  readonly results: Maybe<AdvisorResults>;
  readonly savedSearch: Maybe<AdvisorSavedSearchObject>;
  readonly savedSearches: Maybe<ReadonlyArray<Maybe<AdvisorSavedSearchObject>>>;
  readonly show: Maybe<AdvisorShow>;
};


export type AdvisorBrandsByIndustryArgs = {
  industry: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Scalars['String']>;
  page: InputMaybe<Scalars['Int']>;
  query: InputMaybe<Scalars['String']>;
};


export type AdvisorFeedSearchArgs = {
  query: InputMaybe<AdvisorFeedSearchQuery>;
};


export type AdvisorPodcastArgs = {
  id: Scalars['ID'];
};


export type AdvisorPodcastsByIndustryArgs = {
  industry: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Scalars['String']>;
  page: InputMaybe<Scalars['Int']>;
  query: InputMaybe<Scalars['String']>;
};


export type AdvisorPublisherAffinityScoresArgs = {
  feedId: Scalars['ID'];
};


export type AdvisorPublisherSearchArgs = {
  query: InputMaybe<AdvisorPublisherSearchQuery>;
};


export type AdvisorPublishersByIndustryArgs = {
  industry: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Scalars['String']>;
  page: InputMaybe<Scalars['Int']>;
  query: InputMaybe<Scalars['String']>;
};


export type AdvisorResultsArgs = {
  query: InputMaybe<AdvisorQuery>;
};


export type AdvisorSavedSearchArgs = {
  id: InputMaybe<Scalars['ID']>;
  slug: InputMaybe<Scalars['String']>;
};


export type AdvisorShowArgs = {
  id: Scalars['ID'];
  includeTestData: InputMaybe<Scalars['Boolean']>;
};

export type AdvisorCell = {
  readonly __typename: 'AdvisorCell';
  /** Column ID */
  readonly columnId: Scalars['String'];
  /** Column ID */
  readonly id: Scalars['ID'];
  readonly values: Maybe<ReadonlyArray<Maybe<AdvisorCellValue>>>;
};

export type AdvisorCellValue = {
  readonly __typename: 'AdvisorCellValue';
  /** If this is a filterable value, we set that here */
  readonly filterId: Maybe<Scalars['ID']>;
  /** Value of index (average of all values) for the column. */
  readonly index: Maybe<Scalars['Float']>;
  /** ID of the Object. */
  readonly objectId: Maybe<Scalars['ID']>;
  /** Standard deviation for the column. */
  readonly stddev: Maybe<Scalars['Float']>;
  /** Object Type [number, string, boolean] */
  readonly type: Maybe<Scalars['String']>;
  /** Value of Cell */
  readonly value: Maybe<Scalars['String']>;
};

export type AdvisorColumn = {
  readonly __typename: 'AdvisorColumn';
  readonly description: Maybe<Scalars['String']>;
  /** If this Column is filterable, then this is the id we should use. i.e. brand */
  readonly filterId: Maybe<Scalars['String']>;
  /** Average of all values for the column. */
  readonly filterIndex: Maybe<Scalars['Float']>;
  /** max value of the filter range. */
  readonly filterMax: Maybe<Scalars['Float']>;
  /** min value of the filter range. */
  readonly filterMin: Maybe<Scalars['Float']>;
  /** Standard deviation for the column. */
  readonly filterStddev: Maybe<Scalars['Float']>;
  /** Type of filter avalible. ['range'] */
  readonly filterType: Maybe<Scalars['String']>;
  /** Column ID, we use this in columns and sort on the input. */
  readonly id: Scalars['ID'];
  readonly title: Scalars['String'];
  /** Type of data in column. [int, float, duration, segment, string, boolean, datetime] */
  readonly type: Scalars['String'];
};

export type AdvisorColumnGroup = {
  readonly __typename: 'AdvisorColumnGroup';
  readonly columns: Maybe<ReadonlyArray<Maybe<AdvisorColumn>>>;
  /** If this Column Group is filterable, then this is the id we should use. i.e. brand */
  readonly filterId: Maybe<Scalars['String']>;
  /** Title of the Column Group */
  readonly title: Scalars['String'];
};

export type AdvisorColumnGroups = {
  readonly __typename: 'AdvisorColumnGroups';
  readonly brands: Maybe<AdvisorColumnGroup>;
  readonly clearbit: Maybe<AdvisorColumnGroup>;
  readonly columns: Maybe<ReadonlyArray<Maybe<AdvisorColumn>>>;
  readonly contentMaturity: Maybe<AdvisorColumnGroup>;
  readonly experian: Maybe<AdvisorColumnGroup>;
  readonly feedGrowth: Maybe<AdvisorColumnGroup>;
  readonly general: Maybe<AdvisorColumnGroup>;
  readonly industries: Maybe<AdvisorColumnGroup>;
  readonly nielsen: Maybe<AdvisorColumnGroup>;
  readonly overlap: Maybe<AdvisorColumnGroup>;
  readonly publishers: Maybe<AdvisorColumnGroup>;
  readonly radioMarkets: Maybe<AdvisorColumnGroup>;
  readonly tags: Maybe<AdvisorColumnGroup>;
};


export type AdvisorColumnGroupsBrandsArgs = {
  id: InputMaybe<Scalars['ID']>;
  query: InputMaybe<Scalars['String']>;
};


export type AdvisorColumnGroupsClearbitArgs = {
  id: InputMaybe<Scalars['ID']>;
  query: InputMaybe<Scalars['String']>;
};


export type AdvisorColumnGroupsColumnsArgs = {
  ids: ReadonlyArray<InputMaybe<Scalars['ID']>>;
};


export type AdvisorColumnGroupsExperianArgs = {
  id: InputMaybe<Scalars['ID']>;
  query: InputMaybe<Scalars['String']>;
};


export type AdvisorColumnGroupsFeedGrowthArgs = {
  query: InputMaybe<Scalars['String']>;
};


export type AdvisorColumnGroupsIndustriesArgs = {
  id: InputMaybe<Scalars['ID']>;
  query: InputMaybe<Scalars['String']>;
};


export type AdvisorColumnGroupsNielsenArgs = {
  id: InputMaybe<Scalars['ID']>;
  query: InputMaybe<Scalars['String']>;
};


export type AdvisorColumnGroupsOverlapArgs = {
  id: InputMaybe<Scalars['ID']>;
  query: InputMaybe<Scalars['String']>;
};


export type AdvisorColumnGroupsPublishersArgs = {
  id: InputMaybe<Scalars['ID']>;
  query: InputMaybe<Scalars['String']>;
};


export type AdvisorColumnGroupsRadioMarketsArgs = {
  id: InputMaybe<Scalars['ID']>;
  query: InputMaybe<Scalars['String']>;
};


export type AdvisorColumnGroupsTagsArgs = {
  id: InputMaybe<Scalars['ID']>;
  query: InputMaybe<Scalars['String']>;
};

export type AdvisorFeedSearch = {
  readonly __typename: 'AdvisorFeedSearch';
  readonly count: Maybe<Scalars['Int']>;
  readonly feeds: Maybe<ReadonlyArray<Maybe<LimitedFeedObject>>>;
  readonly limit: Maybe<Scalars['Int']>;
  readonly offset: Maybe<Scalars['Int']>;
  readonly publisherId: Maybe<Scalars['ID']>;
};

export type AdvisorFeedSearchQuery = {
  readonly limit: InputMaybe<Scalars['Int']>;
  readonly offset: InputMaybe<Scalars['Int']>;
  readonly publisherId: InputMaybe<Scalars['ID']>;
  readonly query: InputMaybe<Scalars['String']>;
};

export type AdvisorFilter = {
  readonly id: Scalars['String'];
  /** either OR or RANGE */
  readonly operator: Scalars['String'];
  readonly values: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type AdvisorOptions = {
  readonly __typename: 'AdvisorOptions';
  readonly capabilities: Maybe<ReadonlyArray<Maybe<CapabilityObject>>>;
  readonly id: Scalars['ID'];
};

export type AdvisorPublisherSearch = {
  readonly __typename: 'AdvisorPublisherSearch';
  readonly count: Maybe<Scalars['Int']>;
  readonly limit: Maybe<Scalars['Int']>;
  readonly results: Maybe<ReadonlyArray<Maybe<AdvisorPublisherSearchResult>>>;
};

export type AdvisorPublisherSearchQuery = {
  readonly limit: InputMaybe<Scalars['Int']>;
  readonly query: Scalars['String'];
};

export type AdvisorPublisherSearchResult = {
  readonly __typename: 'AdvisorPublisherSearchResult';
  readonly id: Maybe<Scalars['ID']>;
  readonly name: Maybe<Scalars['String']>;
};

export type AdvisorQuery = {
  /** Columns Requested */
  readonly columns: ReadonlyArray<InputMaybe<Scalars['String']>>;
  readonly filters: InputMaybe<ReadonlyArray<InputMaybe<AdvisorFilter>>>;
  readonly includeTestData: InputMaybe<Scalars['Boolean']>;
  /** Total number of rows returned */
  readonly limit: Scalars['Int'];
  /** Start index of results. */
  readonly offset: Scalars['Int'];
  readonly query: InputMaybe<Scalars['String']>;
  /** List of sort values in order, eg: [-affinity, -impressions] */
  readonly sort: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type AdvisorQuiz = {
  readonly __typename: 'AdvisorQuiz';
  readonly columnGroups: Maybe<AdvisorColumnGroups>;
};

export type AdvisorResults = {
  readonly __typename: 'AdvisorResults';
  readonly cells: Maybe<ReadonlyArray<Maybe<AdvisorCell>>>;
  /** Total number of results. */
  readonly count: Scalars['Int'];
  readonly id: Maybe<Scalars['ID']>;
  readonly limit: Scalars['Int'];
  readonly offset: Scalars['Int'];
  readonly rows: Maybe<ReadonlyArray<Maybe<AdvisorRow>>>;
};


export type AdvisorResultsRowsArgs = {
  query: InputMaybe<AdvisorQuery>;
};

export type AdvisorRow = {
  readonly __typename: 'AdvisorRow';
  readonly cells: Maybe<ReadonlyArray<Maybe<AdvisorCell>>>;
  readonly feed: Maybe<LimitedFeedObject>;
  readonly id: Maybe<Scalars['ID']>;
};

export type AdvisorSavedSearchObject = {
  readonly __typename: 'AdvisorSavedSearchObject';
  readonly createdAt: Scalars['DateTime'];
  readonly data: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly orgDefault: Scalars['Boolean'];
  readonly organization: Maybe<UnAuthedOrganizationObject>;
  readonly slug: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly user: Maybe<UnAuthedUserObject>;
  readonly version: Scalars['Float'];
};

export type AdvisorShow = {
  readonly __typename: 'AdvisorShow';
  readonly ad: Maybe<AdObject>;
  readonly adLoad: Maybe<Scalars['Float']>;
  readonly adPlacements: Maybe<AdvisorShowAdPlacements>;
  readonly adsDurationMean: Maybe<Scalars['Float']>;
  readonly adsEpisodesSampled: Maybe<Scalars['Int']>;
  readonly adsPerEpisodeMean: Maybe<Scalars['Float']>;
  readonly adsPerEpisodeMedian: Maybe<Scalars['Float']>;
  readonly adsSampled: Maybe<Scalars['Int']>;
  readonly affinity: Maybe<Scalars['RoundedInt']>;
  readonly averageEpisodeLength: Maybe<Scalars['RoundedInt']>;
  readonly averageMonthlyImpressions: Maybe<Scalars['RoundedInt']>;
  readonly averageMonthlyReach: Maybe<Scalars['RoundedInt']>;
  readonly averageWeeklyImpressions: Maybe<Scalars['RoundedInt']>;
  readonly averageWeeklyReach: Maybe<Scalars['RoundedInt']>;
  readonly cadence: Maybe<Scalars['String']>;
  readonly capabilities: Maybe<ReadonlyArray<Maybe<AdvisorShowCapability>>>;
  readonly contentMaturity: Maybe<Scalars['Int']>;
  readonly dataQualityScore: Maybe<Scalars['Float']>;
  readonly feed: FeedObject;
  readonly firstRunImpressions: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly industriesOverview: Maybe<ReadonlyArray<Maybe<AdvisorShowIndustriesOverview>>>;
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly percentNewReach: Maybe<Scalars['Float']>;
  readonly podcast: Maybe<PodcastIdObject>;
  readonly publisher: Maybe<PublisherObject>;
  readonly samePublisher: Maybe<ReadonlyArray<Maybe<FeedObject>>>;
  readonly similarAudiences: Maybe<ReadonlyArray<Maybe<FeedObject>>>;
  readonly topBrands: Maybe<AdvisorShowTopBrandsResults>;
  readonly urbanClassification: Maybe<Scalars['Float']>;
};


export type AdvisorShowAdPlacementsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  q: InputMaybe<Scalars['String']>;
  sort: InputMaybe<Scalars['String']>;
};


export type AdvisorShowCapabilitiesArgs = {
  q: InputMaybe<Scalars['String']>;
};


export type AdvisorShowIndustriesOverviewArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  q: InputMaybe<Scalars['String']>;
  sort: InputMaybe<Scalars['String']>;
};


export type AdvisorShowSamePublisherArgs = {
  limit: InputMaybe<Scalars['Int']>;
};


export type AdvisorShowSimilarAudiencesArgs = {
  limit: InputMaybe<Scalars['Int']>;
};


export type AdvisorShowTopBrandsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  q: InputMaybe<Scalars['String']>;
  sort: InputMaybe<Scalars['String']>;
};

export type AdvisorShowAdPlacement = {
  readonly __typename: 'AdvisorShowAdPlacement';
  readonly brand: Maybe<CompanyObject>;
  readonly episode: Maybe<EpisodeObject>;
  readonly id: Scalars['ID'];
};

export type AdvisorShowAdPlacements = {
  readonly __typename: 'AdvisorShowAdPlacements';
  readonly count: Maybe<Scalars['Int']>;
  readonly limit: Maybe<Scalars['Int']>;
  readonly offset: Maybe<Scalars['Int']>;
  readonly rows: Maybe<ReadonlyArray<Maybe<AdvisorShowAdPlacement>>>;
};

export type AdvisorShowCapability = {
  readonly __typename: 'AdvisorShowCapability';
  readonly category: Maybe<Scalars['String']>;
  readonly id: Maybe<Scalars['ID']>;
  readonly title: Maybe<Scalars['String']>;
};

export type AdvisorShowIndustriesOverview = {
  readonly __typename: 'AdvisorShowIndustriesOverview';
  readonly adCount: Maybe<Scalars['Int']>;
  readonly episodeCount: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly industry: Scalars['String'];
  readonly percent: Maybe<Scalars['Float']>;
  readonly totalAds: Maybe<Scalars['Int']>;
};

export type AdvisorShowTopBrand = {
  readonly __typename: 'AdvisorShowTopBrand';
  readonly adCount: Maybe<Scalars['Int']>;
  readonly brand: Maybe<CompanyObject>;
  readonly episodeCount: Maybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
};

export type AdvisorShowTopBrandsResults = {
  readonly __typename: 'AdvisorShowTopBrandsResults';
  readonly count: Maybe<Scalars['Int']>;
  readonly limit: Maybe<Scalars['Int']>;
  readonly offset: Maybe<Scalars['Int']>;
  readonly rows: Maybe<ReadonlyArray<Maybe<AdvisorShowTopBrand>>>;
};

/** Used by AgencySearchQuery so that we don't leak all the things that point to agency. */
export type AgencySearchObject = {
  readonly __typename: 'AgencySearchObject';
  readonly id: Maybe<Scalars['ID']>;
  readonly name: Maybe<Scalars['String']>;
  readonly slug: Maybe<Scalars['String']>;
};

export type ApiKeyObject = {
  readonly __typename: 'ApiKeyObject';
  readonly key: Maybe<Scalars['String']>;
  readonly secret: Maybe<Scalars['String']>;
};

export type ApprovalAgency = {
  readonly __typename: 'ApprovalAgency';
  readonly id: Scalars['ID'];
  readonly members: Maybe<ReadonlyArray<Maybe<UnAuthedUserObject>>>;
  readonly name: Scalars['String'];
};

/**
 * All the people we can request approval from.
 *
 * Root is a campaign.
 */
export type ApprovalConfig = {
  readonly __typename: 'ApprovalConfig';
  readonly brandMembers: Maybe<ReadonlyArray<Maybe<UnAuthedUserObject>>>;
  readonly buySideAgencies: Maybe<ReadonlyArray<Maybe<ApprovalAgency>>>;
  readonly publisherMembers: Maybe<ReadonlyArray<Maybe<UnAuthedUserObject>>>;
};

export type ApprovalOrganization = {
  readonly __typename: 'ApprovalOrganization';
  readonly id: Scalars['ID'];
  readonly name: Maybe<Scalars['String']>;
};

/** start processing feeds. */
export type ArchiveFeed = {
  readonly __typename: 'ArchiveFeed';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type ArchiveFeedInput = {
  readonly feedId: Scalars['ID'];
};

export type Asr = {
  readonly __typename: 'Asr';
  readonly episodeId: Scalars['ID'];
  readonly feedId: Scalars['ID'];
  readonly labels: Maybe<ReadonlyArray<Maybe<AsrRange>>>;
  readonly transcript: Maybe<AsrTranscript>;
};

export type AsrRange = {
  readonly __typename: 'AsrRange';
  readonly end: Maybe<Scalars['Float']>;
  readonly start: Maybe<Scalars['Float']>;
};

export type AsrSpeaker = {
  readonly __typename: 'AsrSpeaker';
  readonly id: Maybe<Scalars['Int']>;
  readonly ranges: Maybe<ReadonlyArray<Maybe<AsrRange>>>;
};

export type AsrTranscript = {
  readonly __typename: 'AsrTranscript';
  readonly confidence: Maybe<Scalars['Float']>;
  readonly created: Maybe<Scalars['DateTime']>;
  readonly duration: Maybe<Scalars['Float']>;
  readonly speakers: Maybe<ReadonlyArray<Maybe<AsrSpeaker>>>;
  readonly transcript: Maybe<Scalars['String']>;
  readonly words: Maybe<ReadonlyArray<Maybe<AsrTranscriptWord>>>;
};

export type AsrTranscriptWord = {
  readonly __typename: 'AsrTranscriptWord';
  readonly confidence: Maybe<Scalars['Float']>;
  readonly end: Maybe<Scalars['Float']>;
  readonly punctuatedWord: Maybe<Scalars['String']>;
  readonly speaker: Maybe<Scalars['Float']>;
  readonly start: Maybe<Scalars['Float']>;
  readonly word: Maybe<Scalars['String']>;
};

export type AssetObject = {
  readonly __typename: 'AssetObject';
  readonly contentType: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly duration: Maybe<Scalars['Float']>;
  readonly height: Maybe<Scalars['Int']>;
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly path: Scalars['String'];
  readonly podlinkDomainFavicons: ReadonlyArray<PodlinkDomainObject>;
  readonly podlinkDomainsImages: ReadonlyArray<PodlinkDomainObject>;
  readonly size: Scalars['Float'];
  readonly src: Maybe<Scalars['String']>;
  readonly transcript: Maybe<Scalars['String']>;
  readonly waveform: Maybe<Scalars['String']>;
  readonly width: Maybe<Scalars['Int']>;
};

export type AssignSpotifyOrderBrand = {
  readonly __typename: 'AssignSpotifyOrderBrand';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type AssignSpotifyOrderBrandInput = {
  readonly advertiserId: Scalars['ID'];
  readonly campaignKind: Scalars['String'];
  readonly spotifyOrderId: Scalars['ID'];
};

export type AttributionCampaignPreRangeInput = {
  readonly after: Scalars['String'];
  readonly before: Scalars['String'];
  readonly campaignId: Scalars['String'];
};

/** run the ranges. */
export type AttributionCampaignPreRun = {
  readonly __typename: 'AttributionCampaignPreRun';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type AttributionCampaignPreRunInput = {
  readonly ranges: ReadonlyArray<InputMaybe<AttributionCampaignPreRangeInput>>;
};

export type AttributionConfigObject = {
  readonly __typename: 'AttributionConfigObject';
  readonly attributionConfigs: ReadonlyArray<OrganizationObject>;
  readonly attributionInterval: Scalars['Int'];
  readonly cableInterval: Scalars['Int'];
  readonly cellularInterval: Scalars['Int'];
  readonly corporateInterval: Scalars['Int'];
  readonly householdInterval: Scalars['Int'];
  readonly id: Scalars['String'];
  readonly maxUsersPerHousehold: Scalars['Int'];
};

export type AttributionDownload = {
  readonly __typename: 'AttributionDownload';
  readonly attribution: Scalars['Float'];
  readonly campaignDynamicId: Scalars['ID'];
  readonly campaignId: Scalars['ID'];
  readonly city: Maybe<Scalars['String']>;
  readonly country: Maybe<Scalars['String']>;
  readonly did: Scalars['ID'];
  readonly episode: Maybe<EpisodeObject>;
  readonly episodeId: Scalars['ID'];
  readonly feed: Maybe<FeedObject>;
  readonly feedId: Scalars['ID'];
  readonly householdIp: Scalars['ID'];
  readonly ip: Scalars['ID'];
  readonly key: Scalars['ID'];
  readonly kind: Scalars['String'];
  readonly latitude: Maybe<Scalars['Float']>;
  readonly longitude: Maybe<Scalars['Float']>;
  readonly region: Maybe<Scalars['String']>;
  readonly source: Scalars['String'];
  readonly time: Scalars['DateTime'];
  readonly uids: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly userAgent: Maybe<UserAgent>;
};

export type AttributionDownloadLocationsQuery = AttributionLocationInterface & {
  readonly __typename: 'AttributionDownloadLocationsQuery';
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly cities: Maybe<ReadonlyArray<Maybe<AttributionLocation>>>;
  readonly city: Maybe<Scalars['String']>;
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly countries: Maybe<ReadonlyArray<Maybe<AttributionLocation>>>;
  readonly country: Maybe<Scalars['String']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly key: Scalars['ID'];
  readonly nodes: Maybe<ReadonlyArray<Maybe<AttributionLocation>>>;
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly region: Maybe<Scalars['String']>;
  readonly regions: Maybe<ReadonlyArray<Maybe<AttributionLocation>>>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};


export type AttributionDownloadLocationsQueryCitiesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type AttributionDownloadLocationsQueryCountriesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type AttributionDownloadLocationsQueryNodesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type AttributionDownloadLocationsQueryRegionsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};

export type AttributionDownloads = AttributionInterface & {
  readonly __typename: 'AttributionDownloads';
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly key: Scalars['ID'];
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly query: Maybe<AttributionDownloadsQuery>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};

export type AttributionDownloadsQuery = AttributionInterface & {
  readonly __typename: 'AttributionDownloadsQuery';
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly count: Maybe<Scalars['Int']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly householdIp: Maybe<Scalars['ID']>;
  readonly key: Scalars['ID'];
  readonly nodes: Maybe<ReadonlyArray<Maybe<AttributionDownload>>>;
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};


export type AttributionDownloadsQueryNodesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};

export type AttributionEvent = {
  readonly __typename: 'AttributionEvent';
  readonly action: Maybe<Scalars['String']>;
  readonly alias: Maybe<Scalars['String']>;
  readonly campaignDynamicIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly campaignIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly city: Maybe<Scalars['String']>;
  readonly country: Maybe<Scalars['String']>;
  readonly currency: Maybe<Scalars['String']>;
  readonly discountCode: Maybe<Scalars['String']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly householdId: Maybe<Scalars['String']>;
  readonly householdIp: Maybe<Scalars['String']>;
  readonly ip: Maybe<Scalars['String']>;
  readonly key: Scalars['ID'];
  readonly latitude: Maybe<Scalars['String']>;
  readonly leadCategory: Maybe<Scalars['String']>;
  readonly leadType: Maybe<Scalars['String']>;
  readonly lineItems: Maybe<Scalars['String']>;
  readonly longitude: Maybe<Scalars['String']>;
  readonly orderId: Maybe<Scalars['String']>;
  readonly product: Maybe<Scalars['String']>;
  readonly quantity: Maybe<Scalars['RoundedInt']>;
  readonly referrer: Maybe<Scalars['String']>;
  readonly referrerDomain: Maybe<Scalars['String']>;
  readonly referrerPath: Maybe<Scalars['String']>;
  readonly referrerType: Maybe<Scalars['String']>;
  readonly region: Maybe<Scalars['String']>;
  readonly source: Scalars['String'];
  readonly time: Maybe<Scalars['DateTime']>;
  readonly uid: Scalars['ID'];
  readonly url: Maybe<Scalars['String']>;
  readonly urlPath: Maybe<Scalars['String']>;
  readonly urlType: Maybe<Scalars['String']>;
  readonly userAgent: Maybe<UserAgent>;
  readonly value: Maybe<Scalars['Float']>;
};

export type AttributionEvents = AttributionInterface & {
  readonly __typename: 'AttributionEvents';
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly hourly: Maybe<ReadonlyArray<Maybe<AttributionEventsHourly>>>;
  readonly key: Scalars['ID'];
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly purchaseValueByDay: Maybe<ReadonlyArray<Maybe<AttributionPurchaseValueByDay>>>;
  readonly query: Maybe<AttributionEventsQuery>;
  readonly sum: Maybe<AttributionEventsSum>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};

export type AttributionEventsHourly = {
  readonly __typename: 'AttributionEventsHourly';
  readonly action: Maybe<Scalars['String']>;
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly hour: Maybe<Scalars['DateTime']>;
};

export type AttributionEventsQuery = AttributionInterface & {
  readonly __typename: 'AttributionEventsQuery';
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly count: Maybe<Scalars['Int']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly householdIp: Maybe<Scalars['ID']>;
  readonly key: Scalars['ID'];
  readonly nodes: Maybe<ReadonlyArray<Maybe<AttributionEvent>>>;
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};


export type AttributionEventsQueryCountArgs = {
  uid: InputMaybe<Scalars['String']>;
};


export type AttributionEventsQueryNodesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Scalars['String']>;
  uid: InputMaybe<Scalars['String']>;
};

export type AttributionEventsSum = {
  readonly __typename: 'AttributionEventsSum';
  readonly addtocart: Maybe<Scalars['RoundedInt']>;
  readonly checkout: Maybe<Scalars['RoundedInt']>;
  readonly init: Maybe<Scalars['RoundedInt']>;
  readonly lead: Maybe<Scalars['RoundedInt']>;
  readonly product: Maybe<Scalars['RoundedInt']>;
  readonly purchase: Maybe<Scalars['RoundedInt']>;
};

export type AttributionHousehold = AttributionInterface & {
  readonly __typename: 'AttributionHousehold';
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly downloads: Maybe<AttributionDownloadsQuery>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly events: Maybe<AttributionEventsQuery>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly ip: Scalars['ID'];
  readonly key: Scalars['ID'];
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};

export type AttributionHouseholds = AttributionInterface & {
  readonly __typename: 'AttributionHouseholds';
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly household: Maybe<AttributionHousehold>;
  readonly key: Scalars['ID'];
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};


export type AttributionHouseholdsHouseholdArgs = {
  id: Scalars['ID'];
};

export type AttributionInterface = {
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly key: Scalars['ID'];
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};

export type AttributionLift = {
  readonly __typename: 'AttributionLift';
  readonly category: Maybe<Scalars['ID']>;
  readonly hour: Maybe<Scalars['DateTime']>;
  readonly kind: Maybe<Scalars['ID']>;
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly value: Maybe<Scalars['RoundedInt']>;
};

export type AttributionLocation = {
  readonly __typename: 'AttributionLocation';
  readonly city: Maybe<Scalars['String']>;
  readonly country: Maybe<Scalars['String']>;
  readonly latitude: Maybe<Scalars['Float']>;
  readonly longitude: Maybe<Scalars['Float']>;
  readonly region: Maybe<Scalars['String']>;
  readonly total: Maybe<Scalars['RoundedInt']>;
};

export type AttributionLocationInterface = {
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly city: Maybe<Scalars['String']>;
  readonly country: Maybe<Scalars['String']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly key: Scalars['ID'];
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly region: Maybe<Scalars['String']>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};

export type AttributionLocations = AttributionLocationInterface & {
  readonly __typename: 'AttributionLocations';
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly city: Maybe<Scalars['String']>;
  readonly country: Maybe<Scalars['String']>;
  readonly downloads: Maybe<AttributionDownloadLocationsQuery>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly key: Scalars['ID'];
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly region: Maybe<Scalars['String']>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};

export type AttributionManagement = {
  readonly __typename: 'AttributionManagement';
  readonly backdated: Maybe<ReadonlyArray<Maybe<Backdated>>>;
  readonly missing: Maybe<ReadonlyArray<Maybe<Missing>>>;
};


export type AttributionManagementBackdatedArgs = {
  after: Scalars['String'];
  before: Scalars['String'];
  byPublished: InputMaybe<Scalars['Boolean']>;
  freqGte: InputMaybe<Scalars['Float']>;
};


export type AttributionManagementMissingArgs = {
  after: Scalars['String'];
  before: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type AttributionMarketing = AttributionInterface & {
  readonly __typename: 'AttributionMarketing';
  readonly acquired: Maybe<ReadonlyArray<Maybe<TargetAcquired>>>;
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly key: Scalars['ID'];
  readonly linkAttributionHourly: Maybe<ReadonlyArray<Maybe<LinkAttributionHourly>>>;
  readonly linkAttributionTargetEpisodes: Maybe<LinkTargetEpisodes>;
  readonly linkClicks: Maybe<ReadonlyArray<Maybe<LinkClicks>>>;
  readonly linkHourly: Maybe<ReadonlyArray<Maybe<LinkHourly>>>;
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly targetEpisodes: Maybe<MarketingTargetEpisodes>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};

export type AttributionObject = AttributionInterface & {
  readonly __typename: 'AttributionObject';
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly downloads: Maybe<AttributionDownloads>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly events: Maybe<AttributionEvents>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly households: Maybe<AttributionHouseholds>;
  readonly key: Scalars['ID'];
  readonly lift: Maybe<ReadonlyArray<Maybe<AttributionLift>>>;
  readonly locations: Maybe<AttributionLocations>;
  readonly marketing: Maybe<AttributionMarketing>;
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
  readonly users: Maybe<AttributionUsers>;
};


export type AttributionObjectEventsArgs = {
  action: InputMaybe<Scalars['String']>;
};


export type AttributionObjectLiftArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  categories: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  category: InputMaybe<Scalars['String']>;
  kind: InputMaybe<Scalars['String']>;
  kinds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  limit: InputMaybe<Scalars['Int']>;
};


export type AttributionObjectLocationsArgs = {
  city: InputMaybe<Scalars['String']>;
  country: InputMaybe<Scalars['String']>;
  region: InputMaybe<Scalars['String']>;
};

/** I clear out the pre directories to get a fresh start if there is an issue. */
export type AttributionPreClearRange = {
  readonly __typename: 'AttributionPreClearRange';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type AttributionPreRangeInput = {
  readonly after: Scalars['String'];
  readonly before: Scalars['String'];
  readonly organizationId: Scalars['String'];
};

/** run the ranges. */
export type AttributionPreRun = {
  readonly __typename: 'AttributionPreRun';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type AttributionPreRunInput = {
  readonly ranges: ReadonlyArray<InputMaybe<AttributionPreRangeInput>>;
};

export type AttributionPurchaseValueByDay = {
  readonly __typename: 'AttributionPurchaseValueByDay';
  readonly day: Maybe<Scalars['DateTime']>;
  readonly value: Maybe<Scalars['Float']>;
};

export type AttributionReferrer = {
  readonly __typename: 'AttributionReferrer';
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly referrer: Maybe<Scalars['String']>;
};

export type AttributionReferrerDomain = {
  readonly __typename: 'AttributionReferrerDomain';
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly referrerDomain: Maybe<Scalars['String']>;
};

export type AttributionReferrerDomains = AttributionInterface & {
  readonly __typename: 'AttributionReferrerDomains';
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly allNodes: Maybe<ReadonlyArray<Maybe<AttributionReferrerDomain>>>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly key: Scalars['ID'];
  readonly nodes: Maybe<ReadonlyArray<Maybe<AttributionReferrerDomain>>>;
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly sum: Maybe<Scalars['RoundedInt']>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};


export type AttributionReferrerDomainsNodesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};

export type AttributionReferrers = AttributionInterface & {
  readonly __typename: 'AttributionReferrers';
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly allNodes: Maybe<ReadonlyArray<Maybe<AttributionReferrer>>>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly key: Scalars['ID'];
  readonly nodes: Maybe<ReadonlyArray<Maybe<AttributionReferrer>>>;
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly sum: Maybe<Scalars['RoundedInt']>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};


export type AttributionReferrersNodesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};

export type AttributionRun = {
  readonly __typename: 'AttributionRun';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type AttributionRunInput = {
  readonly organizationId: Scalars['ID'];
  readonly reset: InputMaybe<Scalars['Boolean']>;
};

export type AttributionUrl = {
  readonly __typename: 'AttributionUrl';
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly url: Maybe<Scalars['String']>;
  readonly urlPath: Maybe<Scalars['String']>;
};

export type AttributionUrls = AttributionInterface & {
  readonly __typename: 'AttributionUrls';
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly allNodes: Maybe<ReadonlyArray<Maybe<AttributionUrl>>>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly key: Scalars['ID'];
  readonly nodes: Maybe<ReadonlyArray<Maybe<AttributionUrl>>>;
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly sum: Maybe<Scalars['RoundedInt']>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};


export type AttributionUrlsNodesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};

export type AttributionUsers = AttributionInterface & {
  readonly __typename: 'AttributionUsers';
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly hourlyVisitors: Maybe<ReadonlyArray<Maybe<AttributionVisitorsHourly>>>;
  readonly key: Scalars['ID'];
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly referrerDomains: Maybe<AttributionReferrerDomains>;
  readonly referrers: Maybe<AttributionReferrers>;
  readonly sum: Maybe<AttributionUsersSum>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
  readonly urls: Maybe<AttributionUrls>;
};


export type AttributionUsersReferrersArgs = {
  referrerDomain: InputMaybe<Scalars['String']>;
};

export type AttributionUsersSum = {
  readonly __typename: 'AttributionUsersSum';
  readonly addtocarts: Maybe<Scalars['Float']>;
  readonly addtocartsModeled: Maybe<Scalars['Float']>;
  readonly checkouts: Maybe<Scalars['Float']>;
  readonly checkoutsModeled: Maybe<Scalars['Float']>;
  readonly installed: Maybe<Scalars['Float']>;
  readonly installedModeled: Maybe<Scalars['Float']>;
  readonly leadValue: Maybe<Scalars['Float']>;
  readonly leadValueModeled: Maybe<Scalars['Float']>;
  readonly leads: Maybe<Scalars['Float']>;
  readonly leadsModeled: Maybe<Scalars['Float']>;
  readonly pages: Maybe<Scalars['Float']>;
  readonly pagesModeled: Maybe<Scalars['Float']>;
  readonly products: Maybe<Scalars['Float']>;
  readonly productsModeled: Maybe<Scalars['Float']>;
  readonly purchaseValue: Maybe<Scalars['Float']>;
  readonly purchaseValueModeled: Maybe<Scalars['Float']>;
  readonly purchases: Maybe<Scalars['Float']>;
  readonly purchasesModeled: Maybe<Scalars['Float']>;
  readonly sessions: Maybe<Scalars['Float']>;
  readonly sessionsModeled: Maybe<Scalars['Float']>;
  readonly users: Maybe<Scalars['RoundedInt']>;
  readonly usersModeled: Maybe<Scalars['Float']>;
  readonly value: Maybe<Scalars['Float']>;
  readonly valueModeled: Maybe<Scalars['Float']>;
};

export type AttributionVisitorsHourly = {
  readonly __typename: 'AttributionVisitorsHourly';
  readonly count: Maybe<Scalars['Float']>;
  readonly hour: Maybe<Scalars['DateTime']>;
};

export type AudienceInsightsMetricObject = {
  readonly __typename: 'AudienceInsightsMetricObject';
  readonly category: CampaignAudienceInsightsMetricCategory;
  readonly cr: Maybe<Scalars['Float']>;
  readonly crIndex: Maybe<Scalars['Float']>;
  readonly impressions: Maybe<Scalars['Int']>;
  readonly segment: Scalars['String'];
};

export type AudienceInsightsObject = {
  readonly __typename: 'AudienceInsightsObject';
  readonly age: ReadonlyArray<AudienceInsightsMetricObject>;
  readonly campaignId: Scalars['ID'];
  readonly device: ReadonlyArray<AudienceInsightsMetricObject>;
  readonly format: ReadonlyArray<AudienceInsightsMetricObject>;
  readonly gender: ReadonlyArray<AudienceInsightsMetricObject>;
  readonly musicGenre: ReadonlyArray<AudienceInsightsMetricObject>;
  readonly playlist: ReadonlyArray<AudienceInsightsMetricObject>;
  readonly podcastCategory: ReadonlyArray<AudienceInsightsMetricObject>;
  readonly userSegment: ReadonlyArray<AudienceInsightsMetricObject>;
};

export type Authed = {
  readonly __typename: 'Authed';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type AuthedInput = {
  readonly organizationId: InputMaybe<Scalars['ID']>;
  readonly token: InputMaybe<Scalars['String']>;
};

export type BackdateRange = {
  readonly __typename: 'BackdateRange';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type BackdateRangeInput = {
  readonly after: Scalars['String'];
  readonly before: Scalars['String'];
};

export type Backdated = {
  readonly __typename: 'Backdated';
  readonly organization: Maybe<OrganizationObject>;
  readonly rows: Maybe<ReadonlyArray<Maybe<BackdatedRow>>>;
};

export type BackdatedRow = {
  readonly __typename: 'BackdatedRow';
  readonly campaignEpisode: Maybe<CampaignEpisodeObject>;
  readonly end: Maybe<Scalars['DateTime']>;
  readonly hours: Maybe<Scalars['RoundedInt']>;
  readonly impressions: Maybe<Scalars['RoundedInt']>;
  readonly start: Maybe<Scalars['DateTime']>;
};

export type BenchmarkReportObject = {
  readonly __typename: 'BenchmarkReportObject';
  readonly analysisOverview: Maybe<Scalars['JSONStringNestedSerializer']>;
  readonly brandPerformanceCampaign: Maybe<Scalars['JSONStringNestedSerializer']>;
  readonly brandPerformancePublisher: Maybe<Scalars['JSONStringNestedSerializer']>;
  readonly industryBenchmarkReach: Maybe<Scalars['JSONStringNestedSerializer']>;
  readonly industryBenchmarkVisitor: Maybe<Scalars['JSONStringNestedSerializer']>;
  readonly quarter: Scalars['String'];
  readonly rollPlacement: Maybe<Scalars['JSONStringNestedSerializer']>;
};

export type BillingInfoObject = {
  readonly __typename: 'BillingInfoObject';
  readonly city: Maybe<Scalars['String']>;
  readonly companyName: Maybe<Scalars['String']>;
  readonly contactEmail: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly firstName: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly lastName: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly stateOrProvince: Maybe<Scalars['String']>;
  readonly streetAddress: Maybe<Scalars['String']>;
  readonly taxRegistrationNumber: Maybe<Scalars['String']>;
  readonly zipCode: Maybe<Scalars['String']>;
};

export type BillingRecord = {
  readonly __typename: 'BillingRecord';
  readonly advertiserId: Maybe<Scalars['String']>;
  readonly advertiserName: Maybe<Scalars['String']>;
  readonly agency: Maybe<Scalars['String']>;
  readonly campaignEndAt: Maybe<Scalars['String']>;
  readonly campaignId: Maybe<Scalars['String']>;
  readonly campaignInternal: Maybe<Scalars['String']>;
  readonly campaignKind: Maybe<Scalars['String']>;
  readonly campaignName: Maybe<Scalars['String']>;
  readonly campaignStartAt: Maybe<Scalars['String']>;
  readonly campaignState: Maybe<Scalars['String']>;
  readonly impressions: Maybe<Scalars['RoundedInt']>;
  readonly lineItem: Maybe<Scalars['String']>;
  readonly organizationId: Maybe<Scalars['String']>;
  readonly organizationName: Maybe<Scalars['String']>;
  readonly payer: Maybe<Scalars['String']>;
  readonly spanEndAt: Maybe<Scalars['DateTime']>;
  readonly spanStartAt: Maybe<Scalars['DateTime']>;
};

export type BrandObject = {
  readonly __typename: 'BrandObject';
  readonly brandId: Maybe<Scalars['ID']>;
  readonly brandsOverview: Maybe<BrandsOverviewSearchResults>;
  readonly slug: Maybe<Scalars['String']>;
};


export type BrandObjectBrandsOverviewArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  maxAds: InputMaybe<Scalars['Int']>;
  maxCompanyFirstPublished: InputMaybe<Scalars['String']>;
  maxCompanyLastPublished: InputMaybe<Scalars['String']>;
  maxEpisodes: InputMaybe<Scalars['Int']>;
  maxPodcasts: InputMaybe<Scalars['Int']>;
  minAds: InputMaybe<Scalars['Int']>;
  minCompanyFirstPublished: InputMaybe<Scalars['String']>;
  minCompanyLastPublished: InputMaybe<Scalars['String']>;
  minEpisodes: InputMaybe<Scalars['Int']>;
  minPodcasts: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Scalars['String']>;
  query: InputMaybe<Scalars['String']>;
};

export type BrandOverviewNode = {
  readonly __typename: 'BrandOverviewNode';
  readonly addtocarts: Maybe<Scalars['Float']>;
  readonly addtocartsModeled: Maybe<Scalars['Float']>;
  readonly advertiserName: Maybe<Scalars['String']>;
  readonly attributedDownloads: Maybe<Scalars['Float']>;
  readonly attributedDownloadsModeled: Maybe<Scalars['Float']>;
  readonly attributedReach: Maybe<Scalars['Float']>;
  readonly attributedReachModeled: Maybe<Scalars['Float']>;
  readonly campaignDynamicId: Scalars['ID'];
  readonly campaignId: Scalars['ID'];
  readonly campaignKind: Maybe<Scalars['String']>;
  readonly campaignName: Maybe<Scalars['String']>;
  readonly code: Maybe<Scalars['String']>;
  readonly episodeId: Scalars['ID'];
  readonly episodeTitle: Maybe<Scalars['String']>;
  readonly feedId: Scalars['ID'];
  readonly households: Maybe<Scalars['Float']>;
  readonly householdsModeled: Maybe<Scalars['Float']>;
  readonly impressions: Maybe<Scalars['RoundedInt']>;
  readonly installed: Maybe<Scalars['Float']>;
  readonly installedModeled: Maybe<Scalars['Float']>;
  readonly leads: Maybe<Scalars['Float']>;
  readonly leadsModeled: Maybe<Scalars['Float']>;
  readonly lineItemId: Maybe<Scalars['String']>;
  readonly lineItemName: Maybe<Scalars['String']>;
  readonly podcastTitle: Maybe<Scalars['String']>;
  readonly products: Maybe<Scalars['Float']>;
  readonly productsModeled: Maybe<Scalars['Float']>;
  readonly published: Maybe<Scalars['DateTime']>;
  readonly publisherName: Maybe<Scalars['String']>;
  readonly purchases: Maybe<Scalars['Float']>;
  readonly purchasesModeled: Maybe<Scalars['Float']>;
  readonly reach: Maybe<Scalars['RoundedInt']>;
  readonly spend: Maybe<Scalars['Float']>;
  readonly time: Maybe<Scalars['Date']>;
  readonly value: Maybe<Scalars['Float']>;
  readonly valueModeled: Maybe<Scalars['Float']>;
  readonly views: Maybe<Scalars['Float']>;
  readonly viewsModeled: Maybe<Scalars['Float']>;
  readonly visitors: Maybe<Scalars['Float']>;
  readonly visitorsModeled: Maybe<Scalars['Float']>;
};

export type BrandOverviewObject = {
  readonly __typename: 'BrandOverviewObject';
  readonly count: Scalars['RoundedInt'];
  readonly nodes: Maybe<ReadonlyArray<Maybe<BrandOverviewNode>>>;
};

export type BrandsOverviewSearchResult = {
  readonly __typename: 'BrandsOverviewSearchResult';
  readonly adFirstPublished: Maybe<Scalars['Date']>;
  readonly adsCount: Maybe<Scalars['Int']>;
  readonly companyFirstPublished: Maybe<Scalars['Date']>;
  readonly companyLastPublished: Maybe<Scalars['Date']>;
  readonly episodesCount: Maybe<Scalars['Int']>;
  readonly feedsCount: Maybe<Scalars['Int']>;
  readonly id: Maybe<Scalars['String']>;
  readonly industry: Maybe<Scalars['String']>;
  readonly name: Maybe<Scalars['String']>;
  readonly slug: Maybe<Scalars['String']>;
};

export type BrandsOverviewSearchResults = {
  readonly __typename: 'BrandsOverviewSearchResults';
  readonly results: Maybe<ReadonlyArray<Maybe<BrandsOverviewSearchResult>>>;
  readonly total: Maybe<Scalars['RoundedInt']>;
};

/** An enumeration. */
export enum CampaignAudienceInsightsMetricCategory {
  /** Age */
  Age = 'AGE',
  /** Podcast Category */
  Category = 'CATEGORY',
  /** Device */
  Device = 'DEVICE',
  /** Format */
  Format = 'FORMAT',
  /** Gender */
  Gender = 'GENDER',
  /** Music Genre */
  Genre = 'GENRE',
  /** Playlist */
  Playlist = 'PLAYLIST',
  /** User Segment */
  Segment = 'SEGMENT'
}

export type CampaignAuditLogEventObject = {
  readonly __typename: 'CampaignAuditLogEventObject';
  readonly action: Scalars['String'];
  readonly campaignOrLineItemName: Scalars['String'];
  readonly dateTimeChanged: Scalars['DateTime'];
  readonly eventType: CampaignAuditLogEventTypes;
  readonly fieldName: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly newVal: Maybe<Scalars['String']>;
  readonly oldVal: Maybe<Scalars['String']>;
  readonly userFirstName: Scalars['String'];
  readonly userLastInitial: Scalars['String'];
};

/** An enumeration. */
export enum CampaignAuditLogEventTypes {
  Campaign = 'CAMPAIGN',
  Dynamic = 'DYNAMIC',
  Embedded = 'EMBEDDED',
  Episode = 'EPISODE',
  Streaming = 'STREAMING'
}

/** An enumeration. */
export enum CampaignBroadcastCurrency {
  /** Australian Dollar */
  Aud = 'AUD',
  /** Brazilian Real */
  Brl = 'BRL',
  /** Canadian Dollar */
  Cad = 'CAD',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Euro */
  Eur = 'EUR',
  /** Pound Sterling */
  Gbp = 'GBP',
  /** Ghanian Cedi */
  Ghs = 'GHS',
  /** Kenyan Shilling */
  Kes = 'KES',
  /** Mexican Peso */
  Mxn = 'MXN',
  /** Naira */
  Ngn = 'NGN',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** New Zealand Dollar */
  Nzd = 'NZD',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore Dollar */
  Sgd = 'SGD',
  /** Tanzanian Shilling */
  Tzs = 'TZS',
  /** Uganda Shilling */
  Ugx = 'UGX',
  /** US Dollar */
  Usd = 'USD'
}

export type CampaignBroadcastObject = {
  readonly __typename: 'CampaignBroadcastObject';
  readonly addtocartTotal: Maybe<Scalars['Float']>;
  readonly addtocartTotalModeled: Maybe<Scalars['Float']>;
  readonly attribution: Maybe<AttributionObject>;
  readonly attributionTotal: Maybe<Scalars['Float']>;
  readonly campaign: CampaignObject;
  readonly campaignBroadcastStation: Maybe<CampaignBroadcastStationObject>;
  readonly campaignBroadcastStations: ReadonlyArray<CampaignBroadcastStationObject>;
  readonly checkoutTotal: Maybe<Scalars['Float']>;
  readonly checkoutTotalModeled: Maybe<Scalars['Float']>;
  readonly cost: Maybe<Scalars['Float']>;
  readonly costToDate: Maybe<Scalars['Float']>;
  readonly cpm: Maybe<Scalars['Float']>;
  readonly createdAt: Scalars['DateTime'];
  readonly currency: CampaignBroadcastCurrency;
  readonly discountCode: Maybe<Scalars['String']>;
  readonly discountUrl: Maybe<Scalars['String']>;
  readonly downloadTotal: Maybe<Scalars['Int']>;
  readonly downloads: Maybe<DownloadsObject>;
  readonly duration: Maybe<Scalars['Int']>;
  readonly effectiveUrl: Maybe<Scalars['String']>;
  readonly endAt: Maybe<Scalars['DateTime']>;
  readonly goal: Maybe<Scalars['Int']>;
  readonly id: Scalars['String'];
  readonly installedTotal: Maybe<Scalars['Float']>;
  readonly installedTotalModeled: Maybe<Scalars['Float']>;
  readonly isActive: Scalars['Boolean'];
  readonly isBaseline: Scalars['Boolean'];
  readonly leadTotal: Maybe<Scalars['Float']>;
  readonly leadTotalModeled: Maybe<Scalars['Float']>;
  readonly leadValue: Maybe<Scalars['Float']>;
  readonly leadValueModeled: Maybe<Scalars['Float']>;
  readonly maids: Maybe<MaidsObject>;
  readonly name: Scalars['String'];
  readonly productTotal: Maybe<Scalars['Float']>;
  readonly productTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseTotal: Maybe<Scalars['Float']>;
  readonly purchaseTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseValue: Maybe<Scalars['Float']>;
  readonly purchaseValueModeled: Maybe<Scalars['Float']>;
  readonly reachTotal: Maybe<Scalars['Int']>;
  readonly spotifyClickTotal: Maybe<Scalars['Int']>;
  readonly startAt: Maybe<Scalars['DateTime']>;
  readonly targetDownloadTotal: Maybe<Scalars['Float']>;
  readonly targetReachTotal: Maybe<Scalars['Float']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly userTotal: Maybe<Scalars['Float']>;
  readonly userTotalModeled: Maybe<Scalars['Float']>;
  readonly valueTotal: Maybe<Scalars['Float']>;
  readonly valueTotalModeled: Maybe<Scalars['Float']>;
};


export type CampaignBroadcastObjectAttributionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  key: InputMaybe<Scalars['ID']>;
};


export type CampaignBroadcastObjectCampaignBroadcastStationArgs = {
  id: Scalars['ID'];
};


export type CampaignBroadcastObjectCpmArgs = {
  spendByImpressionsDelivered: InputMaybe<Scalars['Boolean']>;
};


export type CampaignBroadcastObjectDownloadsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
};

export type CampaignBroadcastStationObject = {
  readonly __typename: 'CampaignBroadcastStationObject';
  readonly addtocartTotal: Maybe<Scalars['Float']>;
  readonly addtocartTotalModeled: Maybe<Scalars['Float']>;
  readonly attribution: Maybe<AttributionObject>;
  readonly attributionTotal: Maybe<Scalars['Float']>;
  readonly campaignBroadcast: CampaignBroadcastObject;
  readonly checkoutTotal: Maybe<Scalars['Float']>;
  readonly checkoutTotalModeled: Maybe<Scalars['Float']>;
  readonly cost: Maybe<Scalars['Float']>;
  readonly costToDate: Maybe<Scalars['Float']>;
  readonly cpm: Maybe<Scalars['Float']>;
  readonly createdAt: Scalars['DateTime'];
  readonly currency: Maybe<Scalars['String']>;
  readonly downloadTotal: Maybe<Scalars['Int']>;
  readonly downloads: Maybe<DownloadsObject>;
  readonly endAt: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['String'];
  readonly installedTotal: Maybe<Scalars['Float']>;
  readonly installedTotalModeled: Maybe<Scalars['Float']>;
  readonly leadTotal: Maybe<Scalars['Float']>;
  readonly leadTotalModeled: Maybe<Scalars['Float']>;
  readonly leadValue: Maybe<Scalars['Float']>;
  readonly leadValueModeled: Maybe<Scalars['Float']>;
  readonly productTotal: Maybe<Scalars['Float']>;
  readonly productTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseTotal: Maybe<Scalars['Float']>;
  readonly purchaseTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseValue: Maybe<Scalars['Float']>;
  readonly purchaseValueModeled: Maybe<Scalars['Float']>;
  readonly reachTotal: Maybe<Scalars['Int']>;
  readonly spotifyClickTotal: Maybe<Scalars['Int']>;
  readonly startAt: Maybe<Scalars['DateTime']>;
  readonly targetDownloadTotal: Maybe<Scalars['Float']>;
  readonly targetReachTotal: Maybe<Scalars['Float']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly userTotal: Maybe<Scalars['Float']>;
  readonly userTotalModeled: Maybe<Scalars['Float']>;
  readonly valueTotal: Maybe<Scalars['Float']>;
  readonly valueTotalModeled: Maybe<Scalars['Float']>;
};


export type CampaignBroadcastStationObjectAttributionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  key: InputMaybe<Scalars['ID']>;
};


export type CampaignBroadcastStationObjectCpmArgs = {
  spendByImpressionsDelivered: InputMaybe<Scalars['Boolean']>;
};


export type CampaignBroadcastStationObjectDownloadsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
};

export type CampaignBuySideAgencyMembershipObject = {
  readonly __typename: 'CampaignBuySideAgencyMembershipObject';
  readonly enabled: Maybe<Scalars['Boolean']>;
  readonly organizationId: Maybe<Scalars['String']>;
  readonly organizationName: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum CampaignCurrency {
  /** Australian Dollar */
  Aud = 'AUD',
  /** Brazilian Real */
  Brl = 'BRL',
  /** Canadian Dollar */
  Cad = 'CAD',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Euro */
  Eur = 'EUR',
  /** Pound Sterling */
  Gbp = 'GBP',
  /** Ghanian Cedi */
  Ghs = 'GHS',
  /** Kenyan Shilling */
  Kes = 'KES',
  /** Mexican Peso */
  Mxn = 'MXN',
  /** Naira */
  Ngn = 'NGN',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** New Zealand Dollar */
  Nzd = 'NZD',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore Dollar */
  Sgd = 'SGD',
  /** Tanzanian Shilling */
  Tzs = 'TZS',
  /** Uganda Shilling */
  Ugx = 'UGX',
  /** US Dollar */
  Usd = 'USD'
}

export type CampaignDebug = {
  readonly __typename: 'CampaignDebug';
  readonly after: Scalars['DateTime'];
  readonly attributionHourly: Maybe<ReadonlyArray<Maybe<HourlyNode>>>;
  readonly before: Scalars['DateTime'];
  readonly campaignId: Scalars['ID'];
  readonly embedded: Maybe<ReadonlyArray<Maybe<Embedded>>>;
  readonly events: Maybe<AttributionEvents>;
  readonly key: Scalars['ID'];
  readonly pixel: Maybe<PixelDebug>;
  readonly preHourly: Maybe<ReadonlyArray<Maybe<HourlyNode>>>;
  readonly prefixConsistency: ReadonlyArray<DataConsistencyNode>;
  readonly prefixHourly: Maybe<ReadonlyArray<Maybe<HourlyNode>>>;
  readonly webhookConsistency: ReadonlyArray<DataConsistencyNode>;
  readonly webhookHourly: Maybe<ReadonlyArray<Maybe<HourlyNode>>>;
  readonly webhookInvalidImpressions: Maybe<ReadonlyArray<Maybe<InvalidImpressionsNode>>>;
};


export type CampaignDebugPrefixConsistencyArgs = {
  campaignEpisodeId: InputMaybe<Scalars['ID']>;
  campaignPodcastId: InputMaybe<Scalars['ID']>;
};


export type CampaignDebugWebhookConsistencyArgs = {
  campaignDynamicId: InputMaybe<Scalars['ID']>;
};

/** An enumeration. */
export enum CampaignDownloadAlgorithm {
  /** Megaphone */
  Megaphone = 'MEGAPHONE',
  /** Podsights */
  Podsights = 'PODSIGHTS'
}

/** An enumeration. */
export enum CampaignDynamicCurrency {
  /** Australian Dollar */
  Aud = 'AUD',
  /** Brazilian Real */
  Brl = 'BRL',
  /** Canadian Dollar */
  Cad = 'CAD',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Euro */
  Eur = 'EUR',
  /** Pound Sterling */
  Gbp = 'GBP',
  /** Ghanian Cedi */
  Ghs = 'GHS',
  /** Kenyan Shilling */
  Kes = 'KES',
  /** Mexican Peso */
  Mxn = 'MXN',
  /** Naira */
  Ngn = 'NGN',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** New Zealand Dollar */
  Nzd = 'NZD',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore Dollar */
  Sgd = 'SGD',
  /** Tanzanian Shilling */
  Tzs = 'TZS',
  /** Uganda Shilling */
  Ugx = 'UGX',
  /** US Dollar */
  Usd = 'USD'
}

/** An enumeration. */
export enum CampaignDynamicDeleteReason {
  /** Cancellation */
  Cancel = 'CANCEL',
  /** Created In Error */
  Error = 'ERROR',
  /** No Reason Given */
  NoReason = 'NO_REASON',
  /** Other */
  Other = 'OTHER',
  /** Wrong Tracker Installed */
  Tracker = 'TRACKER'
}

export type CampaignDynamicDeliveryObject = {
  readonly __typename: 'CampaignDynamicDeliveryObject';
  readonly campaignDynamic: CampaignDynamicObject;
  readonly id: Scalars['String'];
  readonly viewedAt: Scalars['DateTime'];
  readonly viewedBy: Maybe<UnAuthedUserObject>;
};

export type CampaignDynamicEpisodeObject = {
  readonly __typename: 'CampaignDynamicEpisodeObject';
  readonly addtocartTotal: Maybe<Scalars['Float']>;
  readonly addtocartTotalModeled: Maybe<Scalars['Float']>;
  readonly attribution: Maybe<AttributionObject>;
  readonly attributionTotal: Maybe<Scalars['Float']>;
  readonly campaignDynamicPodcast: CampaignDynamicPodcastObject;
  readonly checkoutTotal: Maybe<Scalars['Float']>;
  readonly checkoutTotalModeled: Maybe<Scalars['Float']>;
  readonly cost: Maybe<Scalars['Float']>;
  readonly costToDate: Maybe<Scalars['Float']>;
  readonly cpm: Maybe<Scalars['Float']>;
  readonly createdAt: Scalars['DateTime'];
  readonly currency: Maybe<Scalars['Float']>;
  readonly downloadTotal: Maybe<Scalars['Int']>;
  readonly downloads: Maybe<DownloadsObject>;
  readonly endAt: Maybe<Scalars['DateTime']>;
  readonly episode: Maybe<EpisodeObject>;
  readonly id: Scalars['String'];
  readonly installedTotal: Maybe<Scalars['Float']>;
  readonly installedTotalModeled: Maybe<Scalars['Float']>;
  readonly leadTotal: Maybe<Scalars['Float']>;
  readonly leadTotalModeled: Maybe<Scalars['Float']>;
  readonly leadValue: Maybe<Scalars['Float']>;
  readonly leadValueModeled: Maybe<Scalars['Float']>;
  readonly productTotal: Maybe<Scalars['Float']>;
  readonly productTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseTotal: Maybe<Scalars['Float']>;
  readonly purchaseTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseValue: Maybe<Scalars['Float']>;
  readonly purchaseValueModeled: Maybe<Scalars['Float']>;
  readonly reachTotal: Maybe<Scalars['Int']>;
  readonly spotifyClickTotal: Maybe<Scalars['Int']>;
  readonly startAt: Maybe<Scalars['DateTime']>;
  readonly targetDownloadTotal: Maybe<Scalars['Float']>;
  readonly targetReachTotal: Maybe<Scalars['Float']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly userTotal: Maybe<Scalars['Float']>;
  readonly userTotalModeled: Maybe<Scalars['Float']>;
  readonly valueTotal: Maybe<Scalars['Float']>;
  readonly valueTotalModeled: Maybe<Scalars['Float']>;
};


export type CampaignDynamicEpisodeObjectAttributionArgs = {
  key: InputMaybe<Scalars['ID']>;
};


export type CampaignDynamicEpisodeObjectCpmArgs = {
  spendByImpressionsDelivered: InputMaybe<Scalars['Boolean']>;
};

export type CampaignDynamicObject = {
  readonly __typename: 'CampaignDynamicObject';
  readonly addtocartTotal: Maybe<Scalars['Float']>;
  readonly addtocartTotalModeled: Maybe<Scalars['Float']>;
  readonly approvals: ReadonlyArray<DynamicApprovalObject>;
  readonly art19Secret: Maybe<Scalars['String']>;
  readonly attribution: Maybe<AttributionObject>;
  readonly attributionTotal: Maybe<Scalars['Float']>;
  readonly auditLogEvents: ReadonlyArray<CampaignAuditLogEventObject>;
  readonly campaign: CampaignObject;
  readonly campaignDynamicPodcast: Maybe<CampaignDynamicPodcastObject>;
  readonly campaignDynamicPodcasts: ReadonlyArray<CampaignDynamicPodcastObject>;
  readonly checkoutTotal: Maybe<Scalars['Float']>;
  readonly checkoutTotalModeled: Maybe<Scalars['Float']>;
  readonly cloudsenseCost: Maybe<Scalars['Float']>;
  readonly cloudsenseExpectedEndAt: Maybe<Scalars['DateTime']>;
  readonly cloudsenseExpectedStartAt: Maybe<Scalars['DateTime']>;
  readonly cloudsenseGoal: Maybe<Scalars['Int']>;
  readonly cloudsenseName: Maybe<Scalars['String']>;
  readonly cost: Maybe<Scalars['Float']>;
  readonly costToDate: Maybe<Scalars['Float']>;
  readonly cpm: Maybe<Scalars['Float']>;
  readonly createdAt: Scalars['DateTime'];
  readonly currency: CampaignDynamicCurrency;
  readonly deleteReason: CampaignDynamicDeleteReason;
  readonly deleteReasons: Maybe<ReadonlyArray<Maybe<DeleteReasonObject>>>;
  readonly deliveries: ReadonlyArray<CampaignDynamicDeliveryObject>;
  readonly discountCode: Maybe<Scalars['String']>;
  readonly discountUrl: Maybe<Scalars['String']>;
  readonly downloadTotal: Maybe<Scalars['Int']>;
  readonly downloads: Maybe<DownloadsObject>;
  readonly duration: Maybe<Scalars['Int']>;
  readonly dynamicType: Maybe<Scalars['String']>;
  readonly effectiveUrl: Maybe<Scalars['String']>;
  readonly endAt: Maybe<Scalars['DateTime']>;
  readonly expectedEndAt: Maybe<Scalars['DateTime']>;
  readonly expectedStartAt: Maybe<Scalars['DateTime']>;
  readonly goal: Maybe<Scalars['Int']>;
  readonly id: Scalars['String'];
  readonly installedTotal: Maybe<Scalars['Float']>;
  readonly installedTotalModeled: Maybe<Scalars['Float']>;
  readonly isActive: Scalars['Boolean'];
  readonly isBaseline: Scalars['Boolean'];
  readonly isMidRoll: Scalars['Boolean'];
  readonly isPostRoll: Scalars['Boolean'];
  readonly isPpa: Scalars['Boolean'];
  readonly isPreRoll: Scalars['Boolean'];
  readonly leadTotal: Maybe<Scalars['Float']>;
  readonly leadTotalModeled: Maybe<Scalars['Float']>;
  readonly leadValue: Maybe<Scalars['Float']>;
  readonly leadValueModeled: Maybe<Scalars['Float']>;
  readonly maids: Maybe<MaidsObject>;
  readonly multiplier: Scalars['Float'];
  readonly name: Scalars['String'];
  readonly notes: Maybe<Scalars['String']>;
  readonly productTotal: Maybe<Scalars['Float']>;
  readonly productTotalModeled: Maybe<Scalars['Float']>;
  readonly providerName: Maybe<CampaignDynamicProviderName>;
  readonly providerNames: Maybe<ReadonlyArray<Maybe<ProviderNameObject>>>;
  readonly providerTrackingUrl: Maybe<Scalars['String']>;
  readonly purchaseTotal: Maybe<Scalars['Float']>;
  readonly purchaseTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseValue: Maybe<Scalars['Float']>;
  readonly purchaseValueModeled: Maybe<Scalars['Float']>;
  readonly reachTotal: Maybe<Scalars['Int']>;
  readonly source: CampaignDynamicSource;
  readonly spotifyAdFormat: Maybe<Scalars['String']>;
  readonly spotifyClickTotal: Scalars['Int'];
  readonly spotifyCtr: Scalars['Float'];
  readonly startAt: Maybe<Scalars['DateTime']>;
  readonly targetDownloadTotal: Maybe<Scalars['Float']>;
  readonly targetReachTotal: Maybe<Scalars['Float']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly userTotal: Maybe<Scalars['Float']>;
  readonly userTotalModeled: Maybe<Scalars['Float']>;
  readonly valueTotal: Maybe<Scalars['Float']>;
  readonly valueTotalModeled: Maybe<Scalars['Float']>;
};


export type CampaignDynamicObjectAttributionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  key: InputMaybe<Scalars['ID']>;
};


export type CampaignDynamicObjectCampaignDynamicPodcastArgs = {
  id: Scalars['ID'];
};


export type CampaignDynamicObjectCpmArgs = {
  spendByImpressionsDelivered: InputMaybe<Scalars['Boolean']>;
};


export type CampaignDynamicObjectDownloadsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
};

export type CampaignDynamicPodcastObject = {
  readonly __typename: 'CampaignDynamicPodcastObject';
  readonly addtocartTotal: Maybe<Scalars['Float']>;
  readonly addtocartTotalModeled: Maybe<Scalars['Float']>;
  readonly attribution: Maybe<AttributionObject>;
  readonly attributionTotal: Maybe<Scalars['Float']>;
  readonly campaignDynamic: CampaignDynamicObject;
  readonly campaignDynamicEpisode: Maybe<CampaignDynamicEpisodeObject>;
  readonly campaignDynamicEpisodes: ReadonlyArray<CampaignDynamicEpisodeObject>;
  readonly campaignDynamicEpisodesCount: Maybe<Scalars['Int']>;
  readonly checkoutTotal: Maybe<Scalars['Float']>;
  readonly checkoutTotalModeled: Maybe<Scalars['Float']>;
  readonly cost: Maybe<Scalars['Float']>;
  readonly costToDate: Maybe<Scalars['Float']>;
  readonly cpm: Maybe<Scalars['Float']>;
  readonly createdAt: Scalars['DateTime'];
  readonly currency: Maybe<Scalars['String']>;
  readonly downloadTotal: Maybe<Scalars['Int']>;
  readonly downloads: Maybe<DownloadsObject>;
  readonly endAt: Maybe<Scalars['DateTime']>;
  readonly episodes: Maybe<ReadonlyArray<Maybe<EpisodeObject>>>;
  readonly feed: Maybe<LimitedFeedObject>;
  readonly id: Scalars['String'];
  readonly installedTotal: Maybe<Scalars['Float']>;
  readonly installedTotalModeled: Maybe<Scalars['Float']>;
  readonly leadTotal: Maybe<Scalars['Float']>;
  readonly leadTotalModeled: Maybe<Scalars['Float']>;
  readonly leadValue: Maybe<Scalars['Float']>;
  readonly leadValueModeled: Maybe<Scalars['Float']>;
  readonly podcast: Maybe<PodcastIdObject>;
  readonly productTotal: Maybe<Scalars['Float']>;
  readonly productTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseTotal: Maybe<Scalars['Float']>;
  readonly purchaseTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseValue: Maybe<Scalars['Float']>;
  readonly purchaseValueModeled: Maybe<Scalars['Float']>;
  readonly reachTotal: Maybe<Scalars['Int']>;
  readonly spotifyClickTotal: Maybe<Scalars['Int']>;
  readonly startAt: Maybe<Scalars['DateTime']>;
  readonly targetDownloadTotal: Maybe<Scalars['Float']>;
  readonly targetReachTotal: Maybe<Scalars['Float']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly userTotal: Maybe<Scalars['Float']>;
  readonly userTotalModeled: Maybe<Scalars['Float']>;
  readonly valueTotal: Maybe<Scalars['Float']>;
  readonly valueTotalModeled: Maybe<Scalars['Float']>;
};


export type CampaignDynamicPodcastObjectAttributionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  key: InputMaybe<Scalars['ID']>;
};


export type CampaignDynamicPodcastObjectCampaignDynamicEpisodeArgs = {
  id: Scalars['ID'];
};


export type CampaignDynamicPodcastObjectCpmArgs = {
  spendByImpressionsDelivered: InputMaybe<Scalars['Boolean']>;
};


export type CampaignDynamicPodcastObjectDownloadsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
};


export type CampaignDynamicPodcastObjectEpisodesArgs = {
  after: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum CampaignDynamicProviderName {
  /** Acast */
  Acast = 'ACAST',
  /** Acast EU */
  AcastEu = 'ACAST_EU',
  /** AdsWizz */
  Adswizz = 'ADSWIZZ',
  /** Art19 */
  Art19 = 'ART19',
  /** Audioboom */
  Audioboom = 'AUDIOBOOM',
  /** Audiohook */
  Audiohook = 'AUDIOHOOK',
  /** Audiomeans */
  Audiomeans = 'AUDIOMEANS',
  /** Barstool HQ */
  BarstoolHq = 'BARSTOOL_HQ',
  /** Dovetail */
  Dovetail = 'DOVETAIL',
  /** DV360 */
  Dv360 = 'DV360',
  /** iHeart */
  Iheart = 'IHEART',
  /** iHeart Audience Marketplace */
  IheartAudienceMarketplace = 'IHEART_AUDIENCE_MARKETPLACE',
  /** Megaphone */
  Megaphone = 'MEGAPHONE',
  /** MTM */
  Mtm = 'MTM',
  /** No Provider Selected */
  None = 'NONE',
  /** Omny */
  Omny = 'OMNY',
  /** Podbean */
  Podbean = 'PODBEAN',
  /** RedCircle */
  Redcircle = 'REDCIRCLE',
  /** Simplecast */
  Simplecast = 'SIMPLECAST',
  /** Sounder */
  Sounder = 'SOUNDER',
  /** SPAN */
  Span = 'SPAN',
  /** Spotify DAI */
  SpotifyDai = 'SPOTIFY_DAI',
  /** Spotify SAI */
  SpotifySai = 'SPOTIFY_SAI',
  /** Spreaker */
  Spreaker = 'SPREAKER',
  /** TTD Podcast */
  TtdPodcast = 'TTD_PODCAST',
  /** Whooshkaa */
  Whooshkaa = 'WHOOSHKAA'
}

/** An enumeration. */
export enum CampaignDynamicSource {
  /** Ad Event Tracker */
  Aet = 'AET',
  /** GAM */
  Gam = 'GAM',
  /** Native (SpAA/Podsights) */
  Native = 'NATIVE',
  /** Spotify Ad Server / Ad Studio */
  Sas = 'SAS',
  /** SPAN */
  Span = 'SPAN'
}

export type CampaignEnhancedConversionsObject = {
  readonly __typename: 'CampaignEnhancedConversionsObject';
  readonly appleAttribution: Maybe<Scalars['Float']>;
  readonly appleCr: Maybe<Scalars['Float']>;
  readonly appleImpressions: Maybe<Scalars['Float']>;
  readonly appleReach: Maybe<Scalars['Float']>;
  readonly appleWeightedCr: Maybe<Scalars['Float']>;
  readonly campaignId: Scalars['ID'];
  readonly combinedOtherCr: Maybe<Scalars['Float']>;
  readonly combinedOtherImpressions: Maybe<Scalars['Float']>;
  readonly combinedOtherReach: Maybe<Scalars['Float']>;
  readonly combinedOtherWeightedCr: Maybe<Scalars['Float']>;
  readonly organizationId: Scalars['ID'];
  readonly otherCr: Maybe<Scalars['Float']>;
  readonly otherImpressions: Maybe<Scalars['Float']>;
  readonly otherReach: Maybe<Scalars['Float']>;
  readonly otherWeightedCr: Maybe<Scalars['Float']>;
  readonly spotifyAttribution: Maybe<Scalars['Float']>;
  readonly spotifyCr: Maybe<Scalars['Float']>;
  readonly spotifyImpressions: Maybe<Scalars['Float']>;
  readonly spotifyReach: Maybe<Scalars['Float']>;
  readonly totalImpressions: Maybe<Scalars['Float']>;
  readonly totalReach: Maybe<Scalars['Float']>;
};

export type CampaignEpisodeObject = {
  readonly __typename: 'CampaignEpisodeObject';
  readonly addtocartTotal: Maybe<Scalars['Float']>;
  readonly addtocartTotalModeled: Maybe<Scalars['Float']>;
  readonly attribution: Maybe<AttributionObject>;
  readonly attributionTotal: Maybe<Scalars['Float']>;
  readonly backdateAttemptDate: Maybe<Scalars['DateTime']>;
  readonly backdatedError: Scalars['Boolean'];
  readonly backdatedErrorMessage: Maybe<Scalars['String']>;
  readonly campaignPodcast: CampaignPodcastObject;
  readonly checkoutTotal: Maybe<Scalars['Float']>;
  readonly checkoutTotalModeled: Maybe<Scalars['Float']>;
  readonly cost: Maybe<Scalars['Float']>;
  readonly costToDate: Maybe<Scalars['Float']>;
  readonly cpm: Maybe<Scalars['Float']>;
  readonly createdAt: Scalars['DateTime'];
  readonly currency: Maybe<Scalars['String']>;
  readonly downloadTotal: Maybe<Scalars['Int']>;
  readonly downloads: Maybe<DownloadsObject>;
  readonly endAt: Maybe<Scalars['DateTime']>;
  readonly episode: Maybe<EpisodeObject>;
  readonly futureEpisode: Maybe<FutureEpisodeObject>;
  readonly goal: Maybe<Scalars['RoundedInt']>;
  readonly id: Scalars['String'];
  readonly installedTotal: Maybe<Scalars['Float']>;
  readonly installedTotalModeled: Maybe<Scalars['Float']>;
  readonly isBaseline: Scalars['Boolean'];
  readonly isDynamic: Scalars['Boolean'];
  readonly leadTotal: Maybe<Scalars['Float']>;
  readonly leadTotalModeled: Maybe<Scalars['Float']>;
  readonly leadValue: Maybe<Scalars['Float']>;
  readonly leadValueModeled: Maybe<Scalars['Float']>;
  readonly maids: Maybe<MaidsObject>;
  readonly name: Maybe<Scalars['String']>;
  readonly otherPlacements: Maybe<ReadonlyArray<Maybe<PlacementObject>>>;
  readonly placements: Maybe<ReadonlyArray<Maybe<PlacementObject>>>;
  readonly productTotal: Maybe<Scalars['Float']>;
  readonly productTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseTotal: Maybe<Scalars['Float']>;
  readonly purchaseTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseValue: Maybe<Scalars['Float']>;
  readonly purchaseValueModeled: Maybe<Scalars['Float']>;
  readonly reachTotal: Maybe<Scalars['Int']>;
  readonly spotifyClickTotal: Maybe<Scalars['Int']>;
  readonly startAt: Maybe<Scalars['DateTime']>;
  readonly targetDownloadTotal: Maybe<Scalars['Float']>;
  readonly targetReachTotal: Maybe<Scalars['Float']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly userTotal: Maybe<Scalars['Float']>;
  readonly userTotalModeled: Maybe<Scalars['Float']>;
  readonly valueTotal: Maybe<Scalars['Float']>;
  readonly valueTotalModeled: Maybe<Scalars['Float']>;
  readonly verified: Scalars['Boolean'];
};


export type CampaignEpisodeObjectAttributionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  key: InputMaybe<Scalars['ID']>;
};


export type CampaignEpisodeObjectCpmArgs = {
  spendByImpressionsDelivered: InputMaybe<Scalars['Boolean']>;
};

export type CampaignFilterObject = {
  readonly __typename: 'CampaignFilterObject';
  readonly results: Maybe<ReadonlyArray<Maybe<CampaignObject>>>;
  readonly resultsCount: Maybe<Scalars['Int']>;
};

export type CampaignFilterQuery = {
  readonly advertisers: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly after: InputMaybe<Scalars['String']>;
  readonly before: InputMaybe<Scalars['String']>;
  readonly impressionsMax: InputMaybe<Scalars['Int']>;
  readonly impressionsMin: InputMaybe<Scalars['Int']>;
  readonly isCreatedByBrand: InputMaybe<Scalars['Boolean']>;
  readonly isMarketing: InputMaybe<Scalars['Boolean']>;
  readonly isPublisherApproved: InputMaybe<Scalars['Boolean']>;
  readonly limit: InputMaybe<Scalars['Int']>;
  readonly name: InputMaybe<Scalars['String']>;
  readonly offset: InputMaybe<Scalars['Int']>;
  readonly sort: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly states: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly tags: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly types: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};

export type CampaignLinkObject = {
  readonly __typename: 'CampaignLinkObject';
  readonly addtocartTotal: Maybe<Scalars['Float']>;
  readonly addtocartTotalModeled: Maybe<Scalars['Float']>;
  readonly attribution: Maybe<AttributionObject>;
  readonly attributionTotal: Maybe<Scalars['Float']>;
  readonly campaign: CampaignObject;
  readonly checkoutTotal: Maybe<Scalars['Float']>;
  readonly checkoutTotalModeled: Maybe<Scalars['Float']>;
  readonly clickTotal: Maybe<Scalars['Int']>;
  readonly costToDate: Maybe<Scalars['Float']>;
  readonly cpm: Maybe<Scalars['Float']>;
  readonly createdAt: Scalars['DateTime'];
  readonly design: Maybe<Scalars['String']>;
  readonly downloadTotal: Maybe<Scalars['Int']>;
  readonly id: Scalars['String'];
  readonly installedTotal: Maybe<Scalars['Float']>;
  readonly installedTotalModeled: Maybe<Scalars['Float']>;
  readonly leadTotal: Maybe<Scalars['Float']>;
  readonly leadTotalModeled: Maybe<Scalars['Float']>;
  readonly leadValue: Maybe<Scalars['Float']>;
  readonly leadValueModeled: Maybe<Scalars['Float']>;
  readonly name: Maybe<Scalars['String']>;
  readonly productTotal: Maybe<Scalars['Float']>;
  readonly productTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseTotal: Maybe<Scalars['Float']>;
  readonly purchaseTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseValue: Maybe<Scalars['Float']>;
  readonly purchaseValueModeled: Maybe<Scalars['Float']>;
  readonly reachTotal: Maybe<Scalars['Int']>;
  readonly redirectTotal: Maybe<Scalars['Int']>;
  readonly target: Maybe<CampaignTargetObject>;
  readonly targetDownloadTotal: Maybe<Scalars['Float']>;
  readonly targetReachTotal: Maybe<Scalars['Float']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly userTotal: Maybe<Scalars['Float']>;
  readonly userTotalModeled: Maybe<Scalars['Float']>;
  readonly valueTotal: Maybe<Scalars['Float']>;
  readonly valueTotalModeled: Maybe<Scalars['Float']>;
  readonly viewTotal: Maybe<Scalars['Int']>;
};


export type CampaignLinkObjectAttributionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  key: InputMaybe<Scalars['ID']>;
};


export type CampaignLinkObjectCpmArgs = {
  spendByImpressionsDelivered: InputMaybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum CampaignMode {
  /** Default */
  Default = 'DEFAULT',
  /** Locked */
  Locked = 'LOCKED',
  /** Strict */
  Strict = 'STRICT'
}

export type CampaignNoiseObject = {
  readonly __typename: 'CampaignNoiseObject';
  readonly campaign: CampaignObject;
  readonly createdAt: Scalars['DateTime'];
  readonly endAt: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['String'];
  readonly name: Maybe<Scalars['String']>;
  readonly startAt: Maybe<Scalars['DateTime']>;
  readonly targetImpressions: Maybe<Scalars['Int']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly usePublisherImpressions: Scalars['Boolean'];
};

export type CampaignObject = {
  readonly __typename: 'CampaignObject';
  readonly addtocartTotal: Maybe<Scalars['Float']>;
  readonly addtocartTotalModeled: Maybe<Scalars['Float']>;
  readonly advertiser: Maybe<CompanyObject>;
  readonly advertiserCanView: Scalars['Boolean'];
  readonly advertiserEmail: Maybe<Scalars['String']>;
  readonly advertiserFirstName: Maybe<Scalars['String']>;
  readonly advertiserIsAgency: Scalars['Boolean'];
  readonly advertiserLastName: Maybe<Scalars['String']>;
  readonly allowBrandEditAccess: Scalars['Boolean'];
  readonly allowRetargeting: Scalars['Boolean'];
  readonly approvalConfig: Maybe<ApprovalConfig>;
  readonly art19Secret: Maybe<Scalars['String']>;
  readonly attribution: Maybe<AttributionObject>;
  readonly attributionCpm: Maybe<Scalars['Float']>;
  readonly attributionTotal: Maybe<Scalars['Float']>;
  readonly attributionWindow: Scalars['Int'];
  readonly audienceInsights: AudienceInsightsObject;
  readonly auditLogEvents: Maybe<ReadonlyArray<Maybe<CampaignAuditLogEventObject>>>;
  readonly buySideAgencies: Maybe<ReadonlyArray<Maybe<CampaignBuySideAgencyMembershipObject>>>;
  readonly campaignBroadcast: Maybe<CampaignBroadcastObject>;
  readonly campaignBroadcasts: ReadonlyArray<CampaignBroadcastObject>;
  readonly campaignDynamic: Maybe<CampaignDynamicObject>;
  readonly campaignDynamics: ReadonlyArray<CampaignDynamicObject>;
  readonly campaignEpisode: Maybe<CampaignEpisodeObject>;
  readonly campaignEpisodes: Maybe<ReadonlyArray<Maybe<CampaignEpisodeObject>>>;
  readonly campaignLink: Maybe<CampaignLinkObject>;
  readonly campaignLinks: ReadonlyArray<CampaignLinkObject>;
  readonly campaignNoises: ReadonlyArray<CampaignNoiseObject>;
  readonly campaignPodcast: Maybe<CampaignPodcastObject>;
  readonly campaignPodcasts: Maybe<ReadonlyArray<Maybe<CampaignPodcastObject>>>;
  readonly campaignStreaming: Maybe<CampaignStreamingObject>;
  readonly campaignStreamings: ReadonlyArray<CampaignStreamingObject>;
  readonly campaignTarget: Maybe<CampaignTargetObject>;
  readonly campaignTargets: ReadonlyArray<CampaignTargetObject>;
  readonly canViewBrand: Maybe<Scalars['Boolean']>;
  readonly checkoutTotal: Maybe<Scalars['Float']>;
  readonly checkoutTotalModeled: Maybe<Scalars['Float']>;
  readonly clickTotal: Maybe<Scalars['Int']>;
  readonly content: Maybe<Scalars['String']>;
  readonly conversionRows: Maybe<ReadonlyArray<Maybe<ConversionRowObject>>>;
  readonly cost: Maybe<Scalars['Float']>;
  readonly costToDate: Maybe<Scalars['Float']>;
  readonly cpm: Maybe<Scalars['Float']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy: Maybe<LimitedUserObject>;
  readonly currency: CampaignCurrency;
  readonly debug: Maybe<CampaignDebug>;
  readonly downloadAlgorithm: CampaignDownloadAlgorithm;
  readonly downloadTotal: Maybe<Scalars['Int']>;
  readonly downloads: Maybe<DownloadsObject>;
  readonly dynamicApprovals: Maybe<ReadonlyArray<Maybe<DynamicApprovalObject>>>;
  readonly endAt: Maybe<Scalars['DateTime']>;
  readonly enhancedConversions: Maybe<CampaignEnhancedConversionsObject>;
  readonly episode: Maybe<EpisodeObject>;
  readonly episodes: Maybe<ReadonlyArray<Maybe<EpisodeObject>>>;
  readonly expectedEndAt: Maybe<Scalars['DateTime']>;
  readonly expectedStartAt: Maybe<Scalars['DateTime']>;
  readonly futureEpisodes: Maybe<ReadonlyArray<Maybe<FutureEpisodeObject>>>;
  readonly genericTags: Maybe<ReadonlyArray<Maybe<GenericTagObject>>>;
  readonly goLiveDate: Maybe<Scalars['Date']>;
  readonly goal: Maybe<Scalars['Int']>;
  readonly goodReach: Maybe<Scalars['Int']>;
  readonly hideMatchedDynamics: Scalars['Boolean'];
  readonly householdReach: Maybe<Scalars['Int']>;
  readonly id: Scalars['String'];
  readonly impressions: Maybe<ImpressionsObject>;
  readonly installedTotal: Maybe<Scalars['Float']>;
  readonly installedTotalModeled: Maybe<Scalars['Float']>;
  readonly isCreatedByBrand: Scalars['Boolean'];
  readonly isExperianApproved: Scalars['Boolean'];
  readonly isHiddenFromAgency: Scalars['Boolean'];
  readonly isNeustar: Scalars['Boolean'];
  readonly isNielsenApproved: Scalars['Boolean'];
  readonly isPpa: Maybe<Scalars['Boolean']>;
  readonly isPublisherApproved: Scalars['Boolean'];
  readonly isSas: Scalars['Boolean'];
  readonly kind: Maybe<Scalars['String']>;
  readonly lastBuilt: Maybe<Scalars['DateTime']>;
  readonly leadTotal: Maybe<Scalars['Float']>;
  readonly leadTotalModeled: Maybe<Scalars['Float']>;
  readonly leadValue: Maybe<Scalars['Float']>;
  readonly leadValueModeled: Maybe<Scalars['Float']>;
  readonly liftReport: Maybe<LiftReportObject>;
  readonly liftReports: ReadonlyArray<LiftReportObject>;
  readonly liftreportSet: ReadonlyArray<LiftReportObject>;
  readonly maids: Maybe<MaidsObject>;
  readonly mode: CampaignMode;
  readonly multiplier: Scalars['Float'];
  readonly name: Scalars['String'];
  readonly noiselessReach: Maybe<Scalars['Int']>;
  readonly organization: Maybe<OrganizationObject>;
  readonly overlap: Maybe<Overlap>;
  readonly payer: Maybe<LimitedOrganizationObject>;
  readonly permission: Maybe<PermissionObject>;
  readonly permissions: Maybe<ReadonlyArray<Maybe<PermissionObject>>>;
  readonly pixel: Maybe<KeyObject>;
  readonly pixels: Maybe<ReadonlyArray<Maybe<KeyObject>>>;
  readonly podcastApprovals: Maybe<ReadonlyArray<Maybe<PodcastApprovalObject>>>;
  readonly productTotal: Maybe<Scalars['Float']>;
  readonly productTotalModeled: Maybe<Scalars['Float']>;
  readonly publisherDenyReason: Maybe<Scalars['String']>;
  readonly purchaseTotal: Maybe<Scalars['Float']>;
  readonly purchaseTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseValue: Maybe<Scalars['Float']>;
  readonly purchaseValueModeled: Maybe<Scalars['Float']>;
  readonly reachTotal: Maybe<Scalars['Int']>;
  readonly redirectTotal: Maybe<Scalars['Int']>;
  readonly slug: Scalars['String'];
  readonly spotAudAge: ReadonlyArray<SpotifyAudienceObject>;
  readonly spotAudDevice: ReadonlyArray<SpotifyAudienceObject>;
  readonly spotAudFormat: ReadonlyArray<SpotifyAudienceObject>;
  readonly spotAudGender: ReadonlyArray<SpotifyAudienceObject>;
  readonly spotAudGenre: ReadonlyArray<SpotifyAudienceObject>;
  readonly spotAudPlaylist: ReadonlyArray<SpotifyAudienceObject>;
  readonly spotAudSegment: ReadonlyArray<SpotifyAudienceObject>;
  readonly spotifyBrandLiftReports: ReadonlyArray<SpotifyBrandLiftReportObject>;
  readonly spotifyClickTotal: Scalars['Int'];
  readonly spotifyCtr: Scalars['Float'];
  readonly spotifyOrder: Maybe<SpotifyOrderObject>;
  readonly startAt: Maybe<Scalars['DateTime']>;
  readonly state: CampaignState;
  readonly stateOverview: Maybe<CampaignStateOverview>;
  readonly streamingApprovals: Maybe<ReadonlyArray<Maybe<StreamingApprovalObject>>>;
  readonly targetDownloadTotal: Maybe<Scalars['Float']>;
  readonly targetOptions: Maybe<ReadonlyArray<Maybe<PodcastIdAndFeedObject>>>;
  readonly targetReachTotal: Maybe<Scalars['Float']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly userReachTotal: Maybe<Scalars['Int']>;
  readonly userTotal: Maybe<Scalars['Float']>;
  readonly userTotalModeled: Maybe<Scalars['Float']>;
  readonly valueTotal: Maybe<Scalars['Float']>;
  readonly valueTotalModeled: Maybe<Scalars['Float']>;
  readonly verifiedReach: Maybe<Scalars['Int']>;
  readonly viewTotal: Maybe<Scalars['Int']>;
};


export type CampaignObjectAttributionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  key: InputMaybe<Scalars['ID']>;
};


export type CampaignObjectAuditLogEventsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type CampaignObjectCampaignBroadcastArgs = {
  id: Scalars['ID'];
};


export type CampaignObjectCampaignDynamicArgs = {
  id: Scalars['ID'];
};


export type CampaignObjectCampaignEpisodeArgs = {
  id: Scalars['ID'];
};


export type CampaignObjectCampaignLinkArgs = {
  id: Scalars['ID'];
};


export type CampaignObjectCampaignPodcastArgs = {
  id: Scalars['ID'];
};


export type CampaignObjectCampaignStreamingArgs = {
  id: Scalars['ID'];
};


export type CampaignObjectCampaignTargetArgs = {
  id: Scalars['ID'];
};


export type CampaignObjectConversionRowsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type CampaignObjectCpmArgs = {
  spendByImpressionsDelivered: InputMaybe<Scalars['Boolean']>;
};


export type CampaignObjectDownloadsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
};


export type CampaignObjectEpisodeArgs = {
  id: Scalars['ID'];
};


export type CampaignObjectGenericTagsArgs = {
  organizationId: Scalars['ID'];
};


export type CampaignObjectImpressionsArgs = {
  after: InputMaybe<Scalars['Date']>;
  before: InputMaybe<Scalars['Date']>;
};


export type CampaignObjectLiftReportArgs = {
  id: Scalars['ID'];
};


export type CampaignObjectOverlapArgs = {
  key: InputMaybe<Scalars['ID']>;
};


export type CampaignObjectPermissionArgs = {
  id: Scalars['ID'];
};


export type CampaignObjectPixelArgs = {
  key: Scalars['ID'];
};

export type CampaignOverlapObject = {
  readonly __typename: 'CampaignOverlapObject';
  readonly baseCampaign: Maybe<CampaignObject>;
  readonly baseCampaignId: Maybe<Scalars['ID']>;
  readonly baseDownloads: Maybe<Scalars['RoundedInt']>;
  readonly baseReach: Maybe<Scalars['RoundedInt']>;
  readonly combined: Maybe<Scalars['RoundedInt']>;
  readonly compCampaign: Maybe<CampaignObject>;
  readonly compCampaignId: Maybe<Scalars['ID']>;
  readonly compDownloads: Maybe<Scalars['RoundedInt']>;
  readonly compReach: Maybe<Scalars['RoundedInt']>;
  readonly overlap: Maybe<Scalars['RoundedInt']>;
};

/** Like the pixel but shareable. */
export type CampaignPixel = {
  readonly __typename: 'CampaignPixel';
  readonly hasData: Maybe<Scalars['Boolean']>;
  readonly historical: Maybe<ReadonlyArray<Maybe<CampaignPixelHistorical>>>;
};


/** Like the pixel but shareable. */
export type CampaignPixelHistoricalArgs = {
  after: Scalars['String'];
  before: Scalars['String'];
};

export type CampaignPixelHistorical = {
  readonly __typename: 'CampaignPixelHistorical';
  readonly day: Maybe<Scalars['DateTime']>;
  readonly init: Maybe<Scalars['RoundedInt']>;
  readonly installed: Maybe<Scalars['RoundedInt']>;
  readonly lead: Maybe<Scalars['RoundedInt']>;
  readonly purchase: Maybe<Scalars['RoundedInt']>;
};

/** An enumeration. */
export enum CampaignPodcastCurrency {
  /** Australian Dollar */
  Aud = 'AUD',
  /** Brazilian Real */
  Brl = 'BRL',
  /** Canadian Dollar */
  Cad = 'CAD',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Euro */
  Eur = 'EUR',
  /** Pound Sterling */
  Gbp = 'GBP',
  /** Ghanian Cedi */
  Ghs = 'GHS',
  /** Kenyan Shilling */
  Kes = 'KES',
  /** Mexican Peso */
  Mxn = 'MXN',
  /** Naira */
  Ngn = 'NGN',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** New Zealand Dollar */
  Nzd = 'NZD',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore Dollar */
  Sgd = 'SGD',
  /** Tanzanian Shilling */
  Tzs = 'TZS',
  /** Uganda Shilling */
  Ugx = 'UGX',
  /** US Dollar */
  Usd = 'USD'
}

/** An enumeration. */
export enum CampaignPodcastDeleteReason {
  /** Cancellation */
  Cancel = 'CANCEL',
  /** Created In Error */
  Error = 'ERROR',
  /** No Reason Given */
  NoReason = 'NO_REASON',
  /** Other */
  Other = 'OTHER',
  /** Wrong Tracker Installed */
  Tracker = 'TRACKER'
}

export type CampaignPodcastObject = {
  readonly __typename: 'CampaignPodcastObject';
  readonly addtocartTotal: Maybe<Scalars['Float']>;
  readonly addtocartTotalModeled: Maybe<Scalars['Float']>;
  readonly approvals: ReadonlyArray<PodcastApprovalObject>;
  readonly attribution: Maybe<AttributionObject>;
  readonly attributionTotal: Maybe<Scalars['Float']>;
  readonly auditLogEvents: ReadonlyArray<CampaignAuditLogEventObject>;
  readonly campaign: CampaignObject;
  readonly campaignEpisodes: ReadonlyArray<CampaignEpisodeObject>;
  readonly campaignEpisodesCount: Maybe<Scalars['Int']>;
  readonly checkoutTotal: Maybe<Scalars['Float']>;
  readonly checkoutTotalModeled: Maybe<Scalars['Float']>;
  readonly cost: Maybe<Scalars['Float']>;
  readonly costToDate: Maybe<Scalars['Float']>;
  readonly cpm: Maybe<Scalars['Float']>;
  readonly createdAt: Scalars['DateTime'];
  readonly currency: CampaignPodcastCurrency;
  readonly deleteReason: CampaignPodcastDeleteReason;
  readonly deleteReasons: Maybe<ReadonlyArray<Maybe<DeleteReasonObject>>>;
  readonly discountCode: Maybe<Scalars['String']>;
  readonly discountUrl: Maybe<Scalars['String']>;
  readonly downloadTotal: Maybe<Scalars['Int']>;
  readonly downloads: Maybe<DownloadsObject>;
  readonly drops: Maybe<Scalars['Int']>;
  readonly duration: Maybe<Scalars['Int']>;
  readonly effectiveUrl: Maybe<Scalars['String']>;
  readonly endAt: Maybe<Scalars['DateTime']>;
  readonly episodes: Maybe<ReadonlyArray<Maybe<EpisodeObject>>>;
  readonly expectedEndAt: Maybe<Scalars['DateTime']>;
  readonly expectedStartAt: Maybe<Scalars['DateTime']>;
  readonly feed: Maybe<LimitedFeedObject>;
  readonly futureEpisodes: ReadonlyArray<FutureEpisodeObject>;
  readonly goal: Maybe<Scalars['Int']>;
  readonly hasBaseline: Maybe<Scalars['Boolean']>;
  readonly hasPodsightsPrefix: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly installedTotal: Maybe<Scalars['Float']>;
  readonly installedTotalModeled: Maybe<Scalars['Float']>;
  readonly isActive: Scalars['Boolean'];
  readonly isDynamic: Scalars['Boolean'];
  readonly isMidRoll: Scalars['Boolean'];
  readonly isPostRoll: Scalars['Boolean'];
  readonly isPreRoll: Scalars['Boolean'];
  readonly leadTotal: Maybe<Scalars['Float']>;
  readonly leadTotalModeled: Maybe<Scalars['Float']>;
  readonly leadValue: Maybe<Scalars['Float']>;
  readonly leadValueModeled: Maybe<Scalars['Float']>;
  readonly maids: Maybe<MaidsObject>;
  readonly multiplier: Scalars['Float'];
  readonly name: Maybe<Scalars['String']>;
  readonly notes: Maybe<Scalars['String']>;
  readonly podcast: Maybe<PodcastIdObject>;
  readonly productTotal: Maybe<Scalars['Float']>;
  readonly productTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseTotal: Maybe<Scalars['Float']>;
  readonly purchaseTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseValue: Maybe<Scalars['Float']>;
  readonly purchaseValueModeled: Maybe<Scalars['Float']>;
  readonly reachTotal: Maybe<Scalars['Int']>;
  readonly spotifyClickTotal: Maybe<Scalars['Int']>;
  readonly startAt: Maybe<Scalars['DateTime']>;
  readonly targetDownloadTotal: Maybe<Scalars['Float']>;
  readonly targetReachTotal: Maybe<Scalars['Float']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly userTotal: Maybe<Scalars['Float']>;
  readonly userTotalModeled: Maybe<Scalars['Float']>;
  readonly valueTotal: Maybe<Scalars['Float']>;
  readonly valueTotalModeled: Maybe<Scalars['Float']>;
};


export type CampaignPodcastObjectAttributionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  key: InputMaybe<Scalars['ID']>;
};


export type CampaignPodcastObjectCpmArgs = {
  spendByImpressionsDelivered: InputMaybe<Scalars['Boolean']>;
};


export type CampaignPodcastObjectDownloadsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
};


export type CampaignPodcastObjectEpisodesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum CampaignState {
  /** Agency */
  Agency = 'AGENCY',
  /** Approved */
  Approved = 'APPROVED',
  /** Complete */
  Complete = 'COMPLETE',
  /** Draft */
  Draft = 'DRAFT',
  /** Live */
  Live = 'LIVE',
  /** Ready */
  Ready = 'READY',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Requested */
  Requested = 'REQUESTED'
}

export type CampaignStateManger = {
  readonly __typename: 'CampaignStateManger';
  readonly id: Maybe<Scalars['String']>;
  readonly name: Maybe<Scalars['String']>;
  readonly slug: Maybe<Scalars['String']>;
};

export type CampaignStateMember = {
  readonly __typename: 'CampaignStateMember';
  readonly firstName: Maybe<Scalars['String']>;
  readonly lastName: Maybe<Scalars['String']>;
};

export type CampaignStateOverview = {
  readonly __typename: 'CampaignStateOverview';
  readonly managers: Maybe<ReadonlyArray<Maybe<CampaignStateManger>>>;
  readonly members: Maybe<ReadonlyArray<Maybe<CampaignStateMember>>>;
  readonly pixel: Maybe<CampaignPixel>;
};

/** An enumeration. */
export enum CampaignStreamingCurrency {
  /** Australian Dollar */
  Aud = 'AUD',
  /** Brazilian Real */
  Brl = 'BRL',
  /** Canadian Dollar */
  Cad = 'CAD',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Euro */
  Eur = 'EUR',
  /** Pound Sterling */
  Gbp = 'GBP',
  /** Ghanian Cedi */
  Ghs = 'GHS',
  /** Kenyan Shilling */
  Kes = 'KES',
  /** Mexican Peso */
  Mxn = 'MXN',
  /** Naira */
  Ngn = 'NGN',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** New Zealand Dollar */
  Nzd = 'NZD',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore Dollar */
  Sgd = 'SGD',
  /** Tanzanian Shilling */
  Tzs = 'TZS',
  /** Uganda Shilling */
  Ugx = 'UGX',
  /** US Dollar */
  Usd = 'USD'
}

export type CampaignStreamingDeliveryObject = {
  readonly __typename: 'CampaignStreamingDeliveryObject';
  readonly campaignStreaming: CampaignStreamingObject;
  readonly id: Scalars['String'];
  readonly viewedAt: Scalars['DateTime'];
  readonly viewedBy: Maybe<UnAuthedUserObject>;
};

export type CampaignStreamingObject = {
  readonly __typename: 'CampaignStreamingObject';
  readonly addtocartTotal: Maybe<Scalars['Float']>;
  readonly addtocartTotalModeled: Maybe<Scalars['Float']>;
  readonly approvals: ReadonlyArray<StreamingApprovalObject>;
  readonly attribution: Maybe<AttributionObject>;
  readonly attributionTotal: Maybe<Scalars['Float']>;
  readonly auditLogEvents: ReadonlyArray<CampaignAuditLogEventObject>;
  readonly campaign: CampaignObject;
  readonly campaignStreamingStream: Maybe<CampaignStreamingStreamObject>;
  readonly campaignStreamingStreams: ReadonlyArray<CampaignStreamingStreamObject>;
  readonly checkoutTotal: Maybe<Scalars['Float']>;
  readonly checkoutTotalModeled: Maybe<Scalars['Float']>;
  readonly cloudsenseCost: Maybe<Scalars['Float']>;
  readonly cloudsenseExpectedEndAt: Maybe<Scalars['DateTime']>;
  readonly cloudsenseExpectedStartAt: Maybe<Scalars['DateTime']>;
  readonly cloudsenseGoal: Maybe<Scalars['Int']>;
  readonly cloudsenseName: Maybe<Scalars['String']>;
  readonly cost: Maybe<Scalars['Float']>;
  readonly costToDate: Maybe<Scalars['Float']>;
  readonly cpm: Maybe<Scalars['Float']>;
  readonly createdAt: Scalars['DateTime'];
  readonly currency: CampaignStreamingCurrency;
  readonly deliveries: ReadonlyArray<CampaignStreamingDeliveryObject>;
  readonly discountCode: Maybe<Scalars['String']>;
  readonly discountUrl: Maybe<Scalars['String']>;
  readonly downloadTotal: Maybe<Scalars['Int']>;
  readonly downloads: Maybe<DownloadsObject>;
  readonly duration: Maybe<Scalars['Int']>;
  readonly effectiveUrl: Maybe<Scalars['String']>;
  readonly endAt: Maybe<Scalars['DateTime']>;
  readonly expectedEndAt: Maybe<Scalars['DateTime']>;
  readonly expectedStartAt: Maybe<Scalars['DateTime']>;
  readonly goal: Maybe<Scalars['Int']>;
  readonly id: Scalars['String'];
  readonly installedTotal: Maybe<Scalars['Float']>;
  readonly installedTotalModeled: Maybe<Scalars['Float']>;
  readonly isActive: Scalars['Boolean'];
  readonly isBaseline: Scalars['Boolean'];
  readonly isPpa: Scalars['Boolean'];
  readonly leadTotal: Maybe<Scalars['Float']>;
  readonly leadTotalModeled: Maybe<Scalars['Float']>;
  readonly leadValue: Maybe<Scalars['Float']>;
  readonly leadValueModeled: Maybe<Scalars['Float']>;
  readonly maids: Maybe<MaidsObject>;
  readonly multiplier: Scalars['Float'];
  readonly name: Scalars['String'];
  readonly notes: Maybe<Scalars['String']>;
  readonly productTotal: Maybe<Scalars['Float']>;
  readonly productTotalModeled: Maybe<Scalars['Float']>;
  readonly providerName: Maybe<CampaignStreamingProviderName>;
  readonly providerNames: Maybe<ReadonlyArray<Maybe<ProviderNameObject>>>;
  readonly providerTrackingUrl: Maybe<Scalars['String']>;
  readonly purchaseTotal: Maybe<Scalars['Float']>;
  readonly purchaseTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseValue: Maybe<Scalars['Float']>;
  readonly purchaseValueModeled: Maybe<Scalars['Float']>;
  readonly reachTotal: Maybe<Scalars['Int']>;
  readonly source: CampaignStreamingSource;
  readonly spotifyAdFormat: Maybe<Scalars['String']>;
  readonly spotifyClickTotal: Scalars['Int'];
  readonly spotifyCtr: Scalars['Float'];
  readonly startAt: Maybe<Scalars['DateTime']>;
  readonly targetDownloadTotal: Maybe<Scalars['Float']>;
  readonly targetReachTotal: Maybe<Scalars['Float']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly userReachTotal: Maybe<Scalars['Int']>;
  readonly userTotal: Maybe<Scalars['Float']>;
  readonly userTotalModeled: Maybe<Scalars['Float']>;
  readonly valueTotal: Maybe<Scalars['Float']>;
  readonly valueTotalModeled: Maybe<Scalars['Float']>;
  readonly verifiedReach: Maybe<Scalars['Int']>;
};


export type CampaignStreamingObjectAttributionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  key: InputMaybe<Scalars['ID']>;
};


export type CampaignStreamingObjectCampaignStreamingStreamArgs = {
  id: Scalars['ID'];
};


export type CampaignStreamingObjectCpmArgs = {
  spendByImpressionsDelivered: InputMaybe<Scalars['Boolean']>;
};


export type CampaignStreamingObjectDownloadsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum CampaignStreamingProviderName {
  /** AdsWizz Streaming */
  AdswizzSt = 'ADSWIZZ_ST',
  /** audacy */
  Audacy = 'AUDACY',
  /** Empire */
  Empire = 'EMPIRE',
  /** iHeart Audience Marketplace */
  Iham = 'IHAM',
  /** iHeart */
  IheartSt = 'IHEART_ST',
  /** No Provider Selected */
  None = 'NONE',
  /** Pandora */
  Pandora = 'PANDORA',
  /** SCA */
  Sca = 'SCA',
  /** SCA GAM */
  ScaGam = 'SCA_GAM',
  /** Triton */
  Triton = 'TRITON',
  /** The Trade Desk Streaming */
  Ttdst = 'TTDST',
  /** WFUZ */
  Wfuz = 'WFUZ'
}

/** An enumeration. */
export enum CampaignStreamingSource {
  /** Ad Event Tracker */
  Aet = 'AET',
  /** GAM */
  Gam = 'GAM',
  /** Native (SpAA/Podsights) */
  Native = 'NATIVE',
  /** Spotify Ad Server / Ad Studio */
  Sas = 'SAS',
  /** SPAN */
  Span = 'SPAN'
}

export type CampaignStreamingStreamObject = {
  readonly __typename: 'CampaignStreamingStreamObject';
  readonly addtocartTotal: Maybe<Scalars['Float']>;
  readonly addtocartTotalModeled: Maybe<Scalars['Float']>;
  readonly attribution: Maybe<AttributionObject>;
  readonly attributionTotal: Maybe<Scalars['Float']>;
  readonly campaignStreaming: CampaignStreamingObject;
  readonly checkoutTotal: Maybe<Scalars['Float']>;
  readonly checkoutTotalModeled: Maybe<Scalars['Float']>;
  readonly cost: Maybe<Scalars['Float']>;
  readonly costToDate: Maybe<Scalars['Float']>;
  readonly cpm: Maybe<Scalars['Float']>;
  readonly createdAt: Scalars['DateTime'];
  readonly currency: Maybe<Scalars['String']>;
  readonly downloadTotal: Maybe<Scalars['Int']>;
  readonly downloads: Maybe<DownloadsObject>;
  readonly endAt: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['String'];
  readonly installedTotal: Maybe<Scalars['Float']>;
  readonly installedTotalModeled: Maybe<Scalars['Float']>;
  readonly leadTotal: Maybe<Scalars['Float']>;
  readonly leadTotalModeled: Maybe<Scalars['Float']>;
  readonly leadValue: Maybe<Scalars['Float']>;
  readonly leadValueModeled: Maybe<Scalars['Float']>;
  readonly productTotal: Maybe<Scalars['Float']>;
  readonly productTotalModeled: Maybe<Scalars['Float']>;
  readonly providerNames: Maybe<ReadonlyArray<Maybe<ProviderNameObject>>>;
  readonly purchaseTotal: Maybe<Scalars['Float']>;
  readonly purchaseTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseValue: Maybe<Scalars['Float']>;
  readonly purchaseValueModeled: Maybe<Scalars['Float']>;
  readonly reachTotal: Maybe<Scalars['Int']>;
  readonly spotifyClickTotal: Maybe<Scalars['Int']>;
  readonly startAt: Maybe<Scalars['DateTime']>;
  readonly targetDownloadTotal: Maybe<Scalars['Float']>;
  readonly targetReachTotal: Maybe<Scalars['Float']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly userTotal: Maybe<Scalars['Float']>;
  readonly userTotalModeled: Maybe<Scalars['Float']>;
  readonly valueTotal: Maybe<Scalars['Float']>;
  readonly valueTotalModeled: Maybe<Scalars['Float']>;
};


export type CampaignStreamingStreamObjectAttributionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  key: InputMaybe<Scalars['ID']>;
};


export type CampaignStreamingStreamObjectCpmArgs = {
  spendByImpressionsDelivered: InputMaybe<Scalars['Boolean']>;
};


export type CampaignStreamingStreamObjectDownloadsArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
};

export type CampaignTargetObject = {
  readonly __typename: 'CampaignTargetObject';
  readonly attribution: Maybe<AttributionObject>;
  readonly campaign: CampaignObject;
  readonly campaignLinks: ReadonlyArray<CampaignLinkObject>;
  readonly createdAt: Scalars['DateTime'];
  readonly downloadTotal: Maybe<Scalars['Float']>;
  readonly feed: FeedObject;
  readonly id: Scalars['String'];
  readonly podcast: Maybe<PodcastIdObject>;
  readonly reachTotal: Maybe<Scalars['Float']>;
  readonly updatedAt: Scalars['DateTime'];
};


export type CampaignTargetObjectAttributionArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  key: InputMaybe<Scalars['ID']>;
};

export type CapabilityObject = {
  readonly __typename: 'CapabilityObject';
  readonly category: Scalars['String'];
  readonly id: Scalars['String'];
  readonly title: Scalars['String'];
};

export type CheckCampaignPodcastPrefix = {
  readonly __typename: 'CheckCampaignPodcastPrefix';
  readonly campaignPodcast: Maybe<CampaignPodcastObject>;
};

export type CheckCampaignPodcastPrefixInput = {
  readonly id: Scalars['ID'];
};

export type ChoiceObject = {
  readonly __typename: 'ChoiceObject';
  readonly displayValue: Maybe<Scalars['String']>;
  readonly value: Maybe<Scalars['String']>;
};

export type Claim = {
  readonly __typename: 'Claim';
  readonly publisher: Maybe<ClaimPublisherObject>;
  readonly verify: Maybe<ClaimPublisherObject>;
};


export type ClaimPublisherArgs = {
  href: Scalars['String'];
};


export type ClaimVerifyArgs = {
  payload: Scalars['String'];
};

export type ClaimAddPodcast = {
  readonly __typename: 'ClaimAddPodcast';
  readonly organization: Maybe<OrganizationObject>;
  readonly podcast: Maybe<PodcastIdObject>;
};

/** Add a feed to an Organization by creating a podcast. */
export type ClaimAddPodcastInput = {
  readonly hash: Scalars['String'];
  readonly href: Scalars['String'];
  readonly organizationId: Scalars['ID'];
};

export type ClaimFeed = {
  readonly __typename: 'ClaimFeed';
  readonly publisher: Maybe<ClaimPublisherObject>;
};

export type ClaimFeedInput = {
  readonly host: Scalars['String'];
  readonly href: Scalars['String'];
  readonly organizationId: Scalars['ID'];
};

export type ClaimPublisherObject = {
  readonly __typename: 'ClaimPublisherObject';
  readonly email: Maybe<Scalars['String']>;
  readonly hash: Maybe<Scalars['String']>;
  readonly href: Maybe<Scalars['String']>;
  readonly name: Maybe<Scalars['String']>;
  readonly organization: Maybe<LimitedOrganizationObject>;
  readonly title: Maybe<Scalars['String']>;
};

export type ClearQueue = {
  readonly __typename: 'ClearQueue';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type ClearQueueInput = {
  readonly topic: Scalars['String'];
};

/** An enumeration. */
export enum CloudsenseBrandMappingAttemptAttemptMethod {
  /** Existing Mapping */
  Mapping = 'MAPPING',
  /** Import Request */
  Request = 'REQUEST',
  /** String Match */
  String = 'STRING'
}

/** An enumeration. */
export enum CloudsenseBrandMappingAttemptConfidence {
  /** High */
  H = 'H',
  /** Low */
  L = 'L',
  /** Medium */
  M = 'M',
  /** No Guess */
  N = 'N'
}

export type CloudsenseBrandMappingAttemptObject = {
  readonly __typename: 'CloudsenseBrandMappingAttemptObject';
  readonly attemptMethod: CloudsenseBrandMappingAttemptAttemptMethod;
  readonly cloudsenseAdvertiserId: Scalars['String'];
  readonly cloudsenseAdvertiserName: Scalars['String'];
  readonly cloudsenseBillingAccountName: Scalars['String'];
  readonly company: Maybe<CompanyObject>;
  readonly confidence: CloudsenseBrandMappingAttemptConfidence;
  readonly spotifyOrder: Maybe<SpotifyOrderObject>;
};

export type CloudsenseNewBrandRequestObject = {
  readonly __typename: 'CloudsenseNewBrandRequestObject';
  readonly advertiserWebsite: Maybe<Scalars['String']>;
  readonly agencyName: Maybe<Scalars['String']>;
  readonly bookedStatus: Scalars['String'];
  readonly brandContactEmail: Scalars['String'];
  readonly brandContactFirstName: Scalars['String'];
  readonly brandContactLastName: Scalars['String'];
  readonly brandName: Scalars['String'];
  readonly campaignKind: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['String'];
  readonly isComplete: Scalars['Boolean'];
  readonly mappingAttempts: ReadonlyArray<CloudsenseBrandMappingAttemptObject>;
  readonly requestor: Maybe<LimitedUserObject>;
};

export type CommentObject = {
  readonly __typename: 'CommentObject';
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['String'];
  readonly objectId: Scalars['String'];
  readonly organization: Maybe<UnAuthedOrganizationObject>;
  readonly state: Maybe<Scalars['Int']>;
  readonly stateDisplay: Maybe<Scalars['String']>;
  readonly text: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly user: Maybe<UnAuthedUserObject>;
};

export type CompanyClaimObject = {
  readonly __typename: 'CompanyClaimObject';
  readonly company: Maybe<CompanyObject>;
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['String'];
  readonly organization: Maybe<LimitedOrganizationObject>;
  readonly state: CompanyClaimState;
  readonly updatedAt: Scalars['DateTime'];
  readonly user: Maybe<LimitedUserObject>;
};

/** An enumeration. */
export enum CompanyClaimState {
  /** Approved */
  Approved = 'APPROVED',
  /** Denied */
  Denied = 'DENIED',
  /** Requested */
  Requested = 'REQUESTED'
}

export type CompanyObject = {
  readonly __typename: 'CompanyObject';
  readonly activity: Maybe<ReadonlyArray<Maybe<ActivityObject>>>;
  readonly ad: Maybe<AdObject>;
  readonly adFirstPublished: Maybe<Scalars['DateTime']>;
  readonly ads: Maybe<ReadonlyArray<Maybe<AdObject>>>;
  readonly adsCount: Maybe<Scalars['Int']>;
  readonly archived: Scalars['Boolean'];
  readonly campaignCount: Scalars['Int'];
  readonly campaigns: Maybe<ReadonlyArray<Maybe<CampaignObject>>>;
  readonly claims: Maybe<ReadonlyArray<Maybe<CompanyClaimObject>>>;
  readonly cloudsenseBrandMappingAttempts: ReadonlyArray<CloudsenseBrandMappingAttemptObject>;
  readonly createdAt: Scalars['DateTime'];
  readonly description: Maybe<Scalars['String']>;
  readonly episodes: Maybe<ReadonlyArray<Maybe<EpisodeObject>>>;
  readonly episodesCount: Maybe<Scalars['Int']>;
  readonly feeds: Maybe<ReadonlyArray<Maybe<FeedObject>>>;
  readonly feedsCount: Maybe<Scalars['Int']>;
  readonly hasActivePixel: Maybe<Scalars['Boolean']>;
  readonly hasCampaigns: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['String'];
  readonly image: Maybe<MediaImageObject>;
  readonly industry: Maybe<Scalars['String']>;
  readonly industryDisplay: Maybe<Scalars['String']>;
  readonly isPublisher: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly organization: Maybe<UnAuthedOrganizationObject>;
  readonly products: ReadonlyArray<ProductObject>;
  readonly productsCount: Maybe<Scalars['Int']>;
  readonly similarByEpisode: Maybe<ReadonlyArray<Maybe<CompanyObject>>>;
  readonly similarByFeed: Maybe<ReadonlyArray<Maybe<CompanyObject>>>;
  readonly slug: Scalars['String'];
  readonly sponsorships: ReadonlyArray<AdObject>;
  readonly spotifyCampaignImportRequests: ReadonlyArray<SpotifyCampaignImportRequestObject>;
  readonly tags: ReadonlyArray<TagObject>;
  readonly updatedAt: Scalars['DateTime'];
  readonly url: Maybe<Scalars['String']>;
};


export type CompanyObjectActivityArgs = {
  interval: Scalars['String'];
  kind: Scalars['String'];
};


export type CompanyObjectAdArgs = {
  id: Scalars['ID'];
};


export type CompanyObjectAdFirstPublishedArgs = {
  after: InputMaybe<Scalars['String']>;
  feedId: InputMaybe<Scalars['ID']>;
};


export type CompanyObjectAdsArgs = {
  after: InputMaybe<Scalars['String']>;
  feedId: InputMaybe<Scalars['ID']>;
  industry: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Scalars['String']>;
  q: InputMaybe<Scalars['String']>;
  state: InputMaybe<Scalars['String']>;
};


export type CompanyObjectAdsCountArgs = {
  after: InputMaybe<Scalars['String']>;
  feedId: InputMaybe<Scalars['ID']>;
};


export type CompanyObjectEpisodesArgs = {
  after: InputMaybe<Scalars['String']>;
  companyId: InputMaybe<Scalars['ID']>;
  limit: InputMaybe<Scalars['Int']>;
};


export type CompanyObjectEpisodesCountArgs = {
  after: InputMaybe<Scalars['String']>;
};


export type CompanyObjectFeedsArgs = {
  after: InputMaybe<Scalars['String']>;
  companyId: InputMaybe<Scalars['ID']>;
  limit: InputMaybe<Scalars['Int']>;
};


export type CompanyObjectFeedsCountArgs = {
  after: InputMaybe<Scalars['String']>;
};


export type CompanyObjectSimilarByEpisodeArgs = {
  limit: InputMaybe<Scalars['Int']>;
};


export type CompanyObjectSimilarByFeedArgs = {
  limit: InputMaybe<Scalars['Int']>;
};

/** Used by CompanySearchQuery so that we don't leak all the things that point to company. */
export type CompanySearchObject = {
  readonly __typename: 'CompanySearchObject';
  readonly company: Maybe<CompanyObject>;
  readonly hasActivePixel: Maybe<Scalars['Boolean']>;
  readonly id: Maybe<Scalars['ID']>;
  readonly industry: Maybe<Scalars['String']>;
  readonly industryDisplay: Maybe<Scalars['String']>;
  readonly name: Maybe<Scalars['String']>;
  readonly slug: Maybe<Scalars['String']>;
};

export type CompletePlacements = {
  readonly __typename: 'CompletePlacements';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type CompletePlacementsInput = {
  readonly episodeId: Scalars['ID'];
};

export type ContactObject = {
  readonly __typename: 'ContactObject';
  readonly address1: Maybe<Scalars['String']>;
  readonly address2: Maybe<Scalars['String']>;
  readonly city: Maybe<Scalars['String']>;
  readonly company: Maybe<Scalars['String']>;
  readonly contactedAt: Maybe<Scalars['DateTime']>;
  readonly contactedBy: Maybe<LimitedUserObject>;
  readonly country: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly email: Scalars['String'];
  readonly firstName: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly ignoredAt: Maybe<Scalars['DateTime']>;
  readonly ignoredBy: Maybe<LimitedUserObject>;
  readonly lastName: Maybe<Scalars['String']>;
  readonly message: Maybe<Scalars['String']>;
  readonly region: Maybe<Scalars['String']>;
  readonly role: Maybe<Scalars['String']>;
  readonly source: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly zipCode: Maybe<Scalars['String']>;
};

export type ContactPublisher = {
  readonly __typename: 'ContactPublisher';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type ContactPublisherInput = {
  readonly company: InputMaybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly message: InputMaybe<Scalars['String']>;
  readonly phone: InputMaybe<Scalars['String']>;
  readonly publisherId: Scalars['ID'];
};

export type ContactedContact = {
  readonly __typename: 'ContactedContact';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type ContactedContactInput = {
  readonly contactId: Scalars['ID'];
};

export type ConversionRowObject = {
  readonly __typename: 'ConversionRowObject';
  readonly avgValue: Maybe<Scalars['Float']>;
  readonly leadCategory: Maybe<Scalars['String']>;
  readonly leadType: Maybe<Scalars['String']>;
  readonly quantity: Maybe<Scalars['RoundedInt']>;
  readonly totalValue: Maybe<Scalars['Float']>;
};

export type ConversionUrlObject = {
  readonly __typename: 'ConversionURLObject';
  readonly action: Maybe<Scalars['String']>;
  readonly category: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly key: KeyObject;
  readonly type: Maybe<Scalars['String']>;
  readonly url: Scalars['String'];
  readonly value: Maybe<Scalars['Float']>;
};

export type CreateActionMap = {
  readonly __typename: 'CreateActionMap';
  readonly actionMap: Maybe<ActionMapObject>;
};

export type CreateActionMapInput = {
  readonly action: Scalars['String'];
  readonly eventName: Scalars['String'];
  readonly key: Scalars['ID'];
};

export type CreateAdvisorSavedSearch = {
  readonly __typename: 'CreateAdvisorSavedSearch';
  readonly advisorSavedSearch: Maybe<AdvisorSavedSearchObject>;
};

export type CreateAdvisorSavedSearchInput = {
  readonly data: Scalars['String'];
  readonly name: Scalars['String'];
  readonly orgDefault: InputMaybe<Scalars['Boolean']>;
  readonly organizationId: InputMaybe<Scalars['ID']>;
  readonly version: Scalars['Float'];
};

/** Used to generate an API key that can be used for querying our API directly.  We cannot retrieve this. */
export type CreateApiKey = {
  readonly __typename: 'CreateApiKey';
  readonly apiKey: Maybe<ApiKeyObject>;
};

export type CreateAsset = {
  readonly __typename: 'CreateAsset';
  readonly asset: Maybe<AssetObject>;
};

export type CreateAssetInput = {
  readonly contentType: Scalars['String'];
  readonly height: Scalars['RoundedInt'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly organizationId: Scalars['ID'];
  readonly path: Scalars['String'];
  readonly size: Scalars['RoundedInt'];
  readonly width: Scalars['RoundedInt'];
};

export type CreateBillingInfo = {
  readonly __typename: 'CreateBillingInfo';
  readonly billingInfo: Maybe<BillingInfoObject>;
};

export type CreateBillingInfoInput = {
  readonly city: InputMaybe<Scalars['String']>;
  readonly companyName: InputMaybe<Scalars['String']>;
  readonly contactEmail: InputMaybe<Scalars['String']>;
  readonly email: InputMaybe<Scalars['String']>;
  readonly firstName: InputMaybe<Scalars['String']>;
  readonly lastName: InputMaybe<Scalars['String']>;
  readonly organizationId: Scalars['ID'];
  readonly phoneNumber: InputMaybe<Scalars['String']>;
  readonly stateOrProvince: InputMaybe<Scalars['String']>;
  readonly streetAddress: InputMaybe<Scalars['String']>;
  readonly taxRegistrationNumber: InputMaybe<Scalars['String']>;
  readonly zipCode: InputMaybe<Scalars['String']>;
};

export type CreateBrandInvites = {
  readonly __typename: 'CreateBrandInvites';
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly success: Maybe<Scalars['Boolean']>;
};

/** Lots of rules about how to create an organization. */
export type CreateBrandInvitesInput = {
  readonly companyId: Scalars['ID'];
  readonly invitees: ReadonlyArray<InputMaybe<InviteesInput>>;
  readonly organizationId: Scalars['ID'];
};

export type CreateCampaign = {
  readonly __typename: 'CreateCampaign';
  readonly campaign: Maybe<CampaignObject>;
};

export type CreateCampaignApprovalRequest = {
  readonly requesteeIds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly requesteeOrganizationId: Scalars['ID'];
};

export type CreateCampaignApprovals = {
  readonly __typename: 'CreateCampaignApprovals';
  readonly dynamicApprovals: Maybe<ReadonlyArray<Maybe<DynamicApprovalObject>>>;
  readonly podcastApprovals: Maybe<ReadonlyArray<Maybe<PodcastApprovalObject>>>;
  readonly streamingApprovals: Maybe<ReadonlyArray<Maybe<StreamingApprovalObject>>>;
};

export type CreateCampaignApprovalsInput = {
  readonly campaignId: Scalars['ID'];
  readonly organizationId: Scalars['ID'];
  readonly requests: InputMaybe<ReadonlyArray<InputMaybe<CreateCampaignApprovalRequest>>>;
};

export type CreateCampaignBroadcast = {
  readonly __typename: 'CreateCampaignBroadcast';
  readonly campaignBroadcast: Maybe<CampaignBroadcastObject>;
};

export type CreateCampaignBroadcastInput = {
  readonly campaignId: Scalars['ID'];
  readonly cost: InputMaybe<Scalars['Float']>;
  readonly currency: InputMaybe<Scalars['String']>;
  readonly discountCode: InputMaybe<Scalars['String']>;
  readonly discountUrl: InputMaybe<Scalars['String']>;
  readonly duration: InputMaybe<Scalars['Int']>;
  readonly effectiveUrl: InputMaybe<Scalars['String']>;
  readonly goal: InputMaybe<Scalars['Int']>;
  readonly isBaseline: InputMaybe<Scalars['Boolean']>;
  readonly name: Scalars['String'];
};

export type CreateCampaignDynamic = {
  readonly __typename: 'CreateCampaignDynamic';
  readonly campaignDynamic: Maybe<CampaignDynamicObject>;
};

export type CreateCampaignDynamicDelivery = {
  readonly __typename: 'CreateCampaignDynamicDelivery';
  readonly campaignDynamicDelivery: Maybe<CampaignDynamicDeliveryObject>;
  readonly success: Maybe<Scalars['Boolean']>;
};

export type CreateCampaignDynamicDeliveryInput = {
  readonly campaignDynamicId: Scalars['ID'];
};

export type CreateCampaignDynamicInput = {
  readonly art19Secret: InputMaybe<Scalars['String']>;
  readonly campaignId: Scalars['ID'];
  readonly cost: Scalars['Float'];
  readonly currency: InputMaybe<Scalars['String']>;
  readonly discountCode: InputMaybe<Scalars['String']>;
  readonly discountUrl: InputMaybe<Scalars['String']>;
  readonly duration: Scalars['Int'];
  readonly dynamicType: InputMaybe<Scalars['String']>;
  readonly effectiveUrl: InputMaybe<Scalars['String']>;
  readonly expectedEndAt: InputMaybe<Scalars['DateTime']>;
  readonly expectedStartAt: InputMaybe<Scalars['DateTime']>;
  readonly goal: Scalars['Int'];
  readonly isBaseline: InputMaybe<Scalars['CoercedBoolean']>;
  readonly isMidRoll: InputMaybe<Scalars['CoercedBoolean']>;
  readonly isPostRoll: InputMaybe<Scalars['CoercedBoolean']>;
  readonly isPreRoll: InputMaybe<Scalars['CoercedBoolean']>;
  readonly name: Scalars['String'];
  readonly notes: InputMaybe<Scalars['String']>;
  readonly organizationId: InputMaybe<Scalars['ID']>;
  readonly providerName: InputMaybe<Scalars['String']>;
};

export type CreateCampaignEpisode = {
  readonly __typename: 'CreateCampaignEpisode';
  readonly campaignEpisode: Maybe<CampaignEpisodeObject>;
};

export type CreateCampaignEpisodeInput = {
  readonly campaignPodcastId: Scalars['ID'];
  readonly episodeId: InputMaybe<Scalars['ID']>;
  readonly futureEpisodeId: InputMaybe<Scalars['ID']>;
  readonly isBaseline: InputMaybe<Scalars['Boolean']>;
};

export type CreateCampaignInput = {
  readonly companyId: Scalars['ID'];
  readonly cost: Scalars['Float'];
  readonly currency: InputMaybe<Scalars['String']>;
  readonly expectedEndAt: InputMaybe<Scalars['DateTime']>;
  readonly expectedStartAt: InputMaybe<Scalars['DateTime']>;
  readonly goLiveDate: InputMaybe<Scalars['Date']>;
  readonly goal: Scalars['Int'];
  readonly isCreatedByBrand: InputMaybe<Scalars['Boolean']>;
  readonly isHiddenFromAgency: InputMaybe<Scalars['Boolean']>;
  readonly kind: Scalars['String'];
  readonly name: Scalars['String'];
  readonly organizationId: Scalars['ID'];
  readonly payerId: InputMaybe<Scalars['ID']>;
};

export type CreateCampaignLink = {
  readonly __typename: 'CreateCampaignLink';
  readonly campaignLink: Maybe<CampaignLinkObject>;
};

export type CreateCampaignLinkInput = {
  readonly campaignId: Scalars['ID'];
  readonly campaignTargetId: Scalars['ID'];
  readonly design: InputMaybe<Scalars['String']>;
  readonly name: Scalars['ID'];
};

export type CreateCampaignNoise = {
  readonly __typename: 'CreateCampaignNoise';
  readonly campaignNoise: Maybe<CampaignNoiseObject>;
};

export type CreateCampaignNoiseInput = {
  readonly campaignId: Scalars['ID'];
  readonly endAt: Scalars['Date'];
  readonly name: Scalars['String'];
  readonly startAt: Scalars['Date'];
  readonly targetImpressions: Scalars['Int'];
  readonly usePublisherImpressions: InputMaybe<Scalars['Boolean']>;
};

export type CreateCampaignPodcast = {
  readonly __typename: 'CreateCampaignPodcast';
  readonly campaignPodcast: Maybe<CampaignPodcastObject>;
};

export type CreateCampaignPodcastInput = {
  readonly campaignId: Scalars['ID'];
  readonly cost: Scalars['Float'];
  readonly currency: InputMaybe<Scalars['String']>;
  readonly discountCode: InputMaybe<Scalars['String']>;
  readonly discountUrl: InputMaybe<Scalars['String']>;
  readonly duration: Scalars['Int'];
  readonly effectiveUrl: InputMaybe<Scalars['String']>;
  readonly goal: Scalars['Int'];
  readonly isMidRoll: InputMaybe<Scalars['CoercedBoolean']>;
  readonly isPostRoll: InputMaybe<Scalars['CoercedBoolean']>;
  readonly isPreRoll: InputMaybe<Scalars['CoercedBoolean']>;
  readonly notes: InputMaybe<Scalars['String']>;
  readonly organizationId: InputMaybe<Scalars['ID']>;
  readonly podcastId: Scalars['ID'];
};

export type CreateCampaignStreaming = {
  readonly __typename: 'CreateCampaignStreaming';
  readonly campaignStreaming: Maybe<CampaignStreamingObject>;
};

export type CreateCampaignStreamingDelivery = {
  readonly __typename: 'CreateCampaignStreamingDelivery';
  readonly campaignStreamingDelivery: Maybe<CampaignStreamingDeliveryObject>;
  readonly success: Maybe<Scalars['Boolean']>;
};

export type CreateCampaignStreamingDeliveryInput = {
  readonly campaignStreamingId: Scalars['ID'];
};

export type CreateCampaignStreamingInput = {
  readonly campaignId: Scalars['ID'];
  readonly cost: Scalars['Float'];
  readonly currency: InputMaybe<Scalars['String']>;
  readonly discountCode: InputMaybe<Scalars['String']>;
  readonly discountUrl: InputMaybe<Scalars['String']>;
  readonly duration: Scalars['Int'];
  readonly effectiveUrl: InputMaybe<Scalars['String']>;
  readonly goal: Scalars['Int'];
  readonly isBaseline: InputMaybe<Scalars['CoercedBoolean']>;
  readonly name: Scalars['String'];
  readonly notes: InputMaybe<Scalars['String']>;
  readonly organizationId: InputMaybe<Scalars['ID']>;
};

export type CreateCampaignTarget = {
  readonly __typename: 'CreateCampaignTarget';
  readonly campaignTarget: Maybe<CampaignTargetObject>;
};

export type CreateCampaignTargetInput = {
  readonly campaignId: Scalars['ID'];
  readonly podcastId: Scalars['ID'];
};

export type CreateComment = {
  readonly __typename: 'CreateComment';
  readonly comment: Maybe<CommentObject>;
};

export type CreateCommentInput = {
  readonly contentType: Scalars['String'];
  readonly objectId: Scalars['ID'];
  readonly text: Scalars['String'];
};

export type CreateCompany = {
  readonly __typename: 'CreateCompany';
  readonly company: Maybe<CompanyObject>;
};

export type CreateCompanyClaim = {
  readonly __typename: 'CreateCompanyClaim';
  readonly companyClaim: Maybe<LimitedCompanyClaimObject>;
};

/** Lots of rules about how to create an organization. */
export type CreateCompanyClaimInput = {
  readonly companyId: InputMaybe<Scalars['ID']>;
  readonly companyName: Scalars['ID'];
  readonly industry: InputMaybe<Scalars['String']>;
  readonly organizationId: Scalars['ID'];
};

export type CreateCompanyInput = {
  readonly industry: Scalars['String'];
  readonly name: Scalars['String'];
};

export type CreateContact = {
  readonly __typename: 'CreateContact';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type CreateContactInput = {
  readonly company: InputMaybe<Scalars['String']>;
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly message: InputMaybe<Scalars['String']>;
  readonly role: InputMaybe<Scalars['String']>;
  readonly source: Scalars['String'];
};

export type CreateConversionUrl = {
  readonly __typename: 'CreateConversionURL';
  readonly conversionUrl: Maybe<ConversionUrlObject>;
};

export type CreateConversionUrlInput = {
  readonly action: Scalars['String'];
  readonly category: InputMaybe<Scalars['String']>;
  readonly key: Scalars['ID'];
  readonly type: InputMaybe<Scalars['String']>;
  readonly url: Scalars['String'];
  readonly value: InputMaybe<Scalars['Float']>;
};

export type CreateDiscount = {
  readonly __typename: 'CreateDiscount';
  readonly discount: Maybe<DiscountObject>;
};

export type CreateDiscountInput = {
  readonly date: Scalars['Date'];
  readonly financialId: Scalars['ID'];
  readonly impressions: Scalars['Int'];
};

export type CreateEpisodeRating = {
  readonly __typename: 'CreateEpisodeRating';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type CreateEpisodeRatingException = {
  readonly __typename: 'CreateEpisodeRatingException';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type CreateEpisodeRatingExceptionInput = {
  readonly category: Scalars['String'];
  readonly episodeId: Scalars['ID'];
  readonly exceptionText: Scalars['String'];
};

export type CreateEpisodeRatingInput = {
  readonly episodeId: Scalars['ID'];
  readonly modelVersion: Scalars['Int'];
  readonly rating: Scalars['Int'];
};

export type CreateExportInstance = {
  readonly __typename: 'CreateExportInstance';
  readonly exportInstance: Maybe<ExportInstanceObject>;
  readonly success: Scalars['Boolean'];
};

export type CreateExportInstanceInput = {
  readonly exportInstanceId: InputMaybe<Scalars['ID']>;
  readonly exportParameters: InputMaybe<ExportInstanceParameters>;
  readonly exportType: Scalars['String'];
  readonly fields: InputMaybe<ReadonlyArray<InputMaybe<FieldsInputObject>>>;
  readonly orderBy: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly organizationId: Scalars['ID'];
  readonly savedExportId: InputMaybe<Scalars['ID']>;
};

export type CreateExportToken = {
  readonly __typename: 'CreateExportToken';
  readonly token: Scalars['String'];
};

export type CreateExportTokenInput = {
  readonly entityId: Scalars['ID'];
  readonly kind: Scalars['String'];
  readonly objectType: Scalars['String'];
  readonly organizationId: Scalars['ID'];
};

export type CreateFutureEpisode = {
  readonly __typename: 'CreateFutureEpisode';
  readonly futureEpisode: Maybe<FutureEpisodeObject>;
  readonly organizationId: Scalars['ID'];
  readonly podcastId: Scalars['ID'];
};

export type CreateFutureEpisodeInput = {
  readonly campaignId: InputMaybe<Scalars['ID']>;
  readonly expectedPublished: Scalars['String'];
  readonly organizationId: Scalars['ID'];
  readonly podcastId: Scalars['ID'];
  readonly subtitle: InputMaybe<Scalars['String']>;
  readonly title: Scalars['String'];
};

export type CreateGenericTag = {
  readonly __typename: 'CreateGenericTag';
  readonly genericTag: Maybe<GenericTagObject>;
};

export type CreateGenericTagInput = {
  readonly color: Scalars['String'];
  readonly organizationId: Scalars['ID'];
  readonly title: Scalars['String'];
};

export type CreateGenericTagRelationship = {
  readonly __typename: 'CreateGenericTagRelationship';
  readonly genericTagRelationship: Maybe<GenericTagRelationshipObject>;
};

export type CreateGenericTagRelationshipInput = {
  readonly contentType: Scalars['String'];
  readonly genericTagId: Scalars['ID'];
  readonly objectPk: Scalars['ID'];
};

export type CreateImage = {
  readonly __typename: 'CreateImage';
  readonly image: Maybe<MediaImageObject>;
};

export type CreateImageInput = {
  readonly contentType: Scalars['String'];
  readonly height: Scalars['RoundedInt'];
  readonly id: Scalars['ID'];
  readonly path: Scalars['String'];
  readonly size: Scalars['RoundedInt'];
  readonly width: Scalars['RoundedInt'];
};

export type CreateInvoice = {
  readonly __typename: 'CreateInvoice';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type CreateInvoiceInput = {
  readonly invoiceId: Scalars['ID'];
};

export type CreateKey = {
  readonly __typename: 'CreateKey';
  readonly key: Maybe<KeyObject>;
};

export type CreateKeyAndCompanyClaim = {
  readonly __typename: 'CreateKeyAndCompanyClaim';
  readonly companyClaim: LimitedCompanyClaimObject;
  readonly key: KeyObject;
};

export type CreateKeyAndCompanyClaimInput = {
  readonly alias: InputMaybe<Scalars['String']>;
  readonly companyId: InputMaybe<Scalars['ID']>;
  readonly companyName: Scalars['ID'];
  readonly domain: Scalars['String'];
  readonly industry: InputMaybe<Scalars['String']>;
  readonly installations: InputMaybe<Scalars['String']>;
  readonly organizationId: Scalars['String'];
  readonly role: Scalars['String'];
};

export type CreateKeyInput = {
  readonly alias: InputMaybe<Scalars['String']>;
  readonly domain: Scalars['String'];
  readonly installations: InputMaybe<Scalars['String']>;
  readonly organizationId: Scalars['String'];
  readonly role: Scalars['String'];
};

export type CreateLiftReport = {
  readonly __typename: 'CreateLiftReport';
  readonly liftReport: Maybe<LiftReportObject>;
};

export type CreateLiftReportInput = {
  readonly campaignIds: ReadonlyArray<InputMaybe<Scalars['ID']>>;
  readonly clampUrl: InputMaybe<Scalars['String']>;
  readonly delta: InputMaybe<Scalars['Int']>;
  readonly impressionsEndAt: InputMaybe<Scalars['Date']>;
  readonly impressionsStartAt: InputMaybe<Scalars['Date']>;
  readonly name: Scalars['String'];
  readonly organizationId: Scalars['ID'];
  readonly state: InputMaybe<Scalars['String']>;
  readonly useNoise: InputMaybe<Scalars['Boolean']>;
};

export type CreateOrganization = {
  readonly __typename: 'CreateOrganization';
  readonly organization: Maybe<OrganizationObject>;
};

export type CreateOrganizationInput = {
  readonly currency: InputMaybe<Scalars['String']>;
  readonly kind: Scalars['String'];
  readonly knowledgeLevel: InputMaybe<Scalars['String']>;
  readonly location: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
};

export type CreateOrganizationWithPixel = {
  readonly __typename: 'CreateOrganizationWithPixel';
  readonly organization: Maybe<OrganizationObject>;
  readonly pixel: Maybe<KeyObject>;
};

export type CreateOrganizationWithPixelInput = {
  readonly businessId: Scalars['String'];
  readonly organizationKind: Scalars['String'];
  readonly organizationName: Scalars['String'];
  readonly pixelAlias: InputMaybe<Scalars['String']>;
  readonly pixelDomain: Scalars['String'];
  readonly pixelInstallations: InputMaybe<Scalars['String']>;
  readonly pixelRole: InputMaybe<Scalars['String']>;
};

export type CreatePaymentTier = {
  readonly __typename: 'CreatePaymentTier';
  readonly paymentTier: Maybe<PaymentTierObject>;
};

export type CreatePaymentTierInput = {
  readonly cost: InputMaybe<Scalars['Float']>;
  readonly currency: InputMaybe<Scalars['String']>;
  readonly financialId: Scalars['ID'];
  readonly impressionsCap: InputMaybe<Scalars['Int']>;
  readonly name: InputMaybe<Scalars['String']>;
};

export type CreatePixelEventRecord = {
  readonly __typename: 'CreatePixelEventRecord';
  readonly pixelEventRecord: Maybe<PixelEventRecordObject>;
};

export type CreatePixelEventRecordInput = {
  readonly category: InputMaybe<Scalars['String']>;
  readonly currency: InputMaybe<Scalars['String']>;
  readonly discountCode: InputMaybe<Scalars['String']>;
  readonly eventType: Scalars['String'];
  readonly installation: Scalars['String'];
  readonly key: Scalars['ID'];
  readonly name: InputMaybe<Scalars['String']>;
  readonly newCustomer: InputMaybe<Scalars['String']>;
  readonly orderId: InputMaybe<Scalars['String']>;
  readonly productId: InputMaybe<Scalars['String']>;
  readonly productName: InputMaybe<Scalars['String']>;
  readonly productType: InputMaybe<Scalars['String']>;
  readonly productVendor: InputMaybe<Scalars['String']>;
  readonly quantity: InputMaybe<Scalars['String']>;
  readonly type: InputMaybe<Scalars['String']>;
  readonly value: InputMaybe<Scalars['String']>;
  readonly variantId: InputMaybe<Scalars['String']>;
  readonly variantName: InputMaybe<Scalars['String']>;
};

export type CreatePodcast = {
  readonly __typename: 'CreatePodcast';
  readonly feedId: Scalars['ID'];
  readonly organizationId: Scalars['ID'];
  readonly podcast: Maybe<PodcastIdObject>;
};

export type CreatePodcastInput = {
  readonly feedId: Scalars['ID'];
  readonly organizationId: Scalars['ID'];
  readonly relation: Scalars['Int'];
};

export type CreatePodlinkDomain = {
  readonly __typename: 'CreatePodlinkDomain';
  readonly podlinkDomain: Maybe<PodlinkDomainObject>;
};

export type CreatePodlinkDomainInput = {
  readonly description: InputMaybe<Scalars['String']>;
  readonly organizationId: Scalars['ID'];
  readonly podcastIds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly slug: Scalars['ID'];
  readonly title: Scalars['String'];
};

export type CreateReviewer = {
  readonly __typename: 'CreateReviewer';
  readonly reviewer: Maybe<ReviewerObject>;
};

export type CreateReviewerInput = {
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly type: Scalars['String'];
};

export type CreateSavedExport = {
  readonly __typename: 'CreateSavedExport';
  readonly savedExport: Maybe<SavedExportObject>;
  readonly success: Maybe<Scalars['Boolean']>;
};

export type CreateSavedExportInput = {
  readonly exportParameters: InputMaybe<ExportInstanceParameters>;
  readonly exportType: Scalars['String'];
  readonly fields: InputMaybe<ReadonlyArray<InputMaybe<FieldsInputObject>>>;
  readonly name: Scalars['String'];
  readonly orderBy: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly organizationId: Scalars['ID'];
  readonly savedExport: InputMaybe<Scalars['ID']>;
  readonly slug: InputMaybe<Scalars['String']>;
};

/**
 * Used to generate an API key that is separate from any actual user,
 * giving access to only the current organization.
 */
export type CreateServiceAccountApiKey = {
  readonly __typename: 'CreateServiceAccountApiKey';
  readonly apiKey: Maybe<ApiKeyObject>;
};

export type CreateServiceAccountApiKeyInput = {
  readonly organizationId: Scalars['ID'];
};

export type CreateSpotifyCampaignImportRequests = {
  readonly __typename: 'CreateSpotifyCampaignImportRequests';
  readonly existingSpotifyCampaignImportRequests: Maybe<ReadonlyArray<Maybe<SpotifyCampaignImportRequestObject>>>;
  readonly newSpotifyCampaignImportRequests: Maybe<ReadonlyArray<Maybe<SpotifyCampaignImportRequestObject>>>;
};

export type CreateSpotifyCampaignImportRequestsInput = {
  readonly companyId: Scalars['ID'];
  readonly spotifyOrderIds: ReadonlyArray<InputMaybe<Scalars['String']>>;
};

export type CreateTermsAndConditions = {
  readonly __typename: 'CreateTermsAndConditions';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type CreateTermsAndConditionsInput = {
  readonly organizationId: Scalars['ID'];
};

export type CreateUser = {
  readonly __typename: 'CreateUser';
  readonly user: Maybe<LimitedUserObject>;
};

export type CreateUserAccess = {
  readonly __typename: 'CreateUserAccess';
  readonly userAccess: Maybe<UserAccessObject>;
};

export type CreateUserAccessInput = {
  readonly contentType: Scalars['String'];
  readonly objectPk: Scalars['ID'];
  readonly organizationId: Scalars['ID'];
  readonly permission: Scalars['String'];
  readonly userId: Scalars['ID'];
};

export type CreateUserInput = {
  readonly host: InputMaybe<Scalars['String']>;
};

export type CreateUserSkippedTerms = {
  readonly __typename: 'CreateUserSkippedTerms';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type CreateUserSkippedTermsInput = {
  readonly organizationId: Scalars['ID'];
};

export type CurrencyNameObject = {
  readonly __typename: 'CurrencyNameObject';
  readonly currencyName: Maybe<Scalars['String']>;
  readonly currencyNameDisplay: Maybe<Scalars['String']>;
};

export type DailyCampaignBillingRecord = {
  readonly __typename: 'DailyCampaignBillingRecord';
  readonly advertiserId: Maybe<Scalars['String']>;
  readonly advertiserName: Maybe<Scalars['String']>;
  readonly campaignId: Maybe<Scalars['String']>;
  readonly campaignName: Maybe<Scalars['String']>;
  readonly downloadDate: Maybe<Scalars['Date']>;
  readonly impressions: Maybe<Scalars['RoundedInt']>;
  readonly marketingImpressions: Maybe<Scalars['RoundedInt']>;
  readonly publisherId: Maybe<Scalars['String']>;
  readonly publisherName: Maybe<Scalars['String']>;
};

export type DataConsistencyNode = {
  readonly __typename: 'DataConsistencyNode';
  readonly bigquery: Scalars['RoundedInt'];
  readonly bigqueryInvalid: Maybe<Scalars['RoundedInt']>;
  readonly bigqueryValid: Maybe<Scalars['RoundedInt']>;
  readonly bigtable: Scalars['RoundedInt'];
  readonly day: Scalars['String'];
  readonly diff: Scalars['RoundedInt'];
  readonly publisher: Maybe<Scalars['RoundedInt']>;
};

export type DayRankObject = {
  readonly __typename: 'DayRankObject';
  readonly day: Maybe<Scalars['String']>;
  readonly rank: Maybe<Scalars['Int']>;
};

export type DeleteActionMap = {
  readonly __typename: 'DeleteActionMap';
  readonly success: Scalars['Boolean'];
};

export type DeleteActionMapInput = {
  readonly actionMapId: Scalars['ID'];
};

export type DeleteAdvisorSavedSearch = {
  readonly __typename: 'DeleteAdvisorSavedSearch';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type DeleteAdvisorSavedSearchInput = {
  readonly id: Scalars['ID'];
};

export type DeleteCampaign = {
  readonly __typename: 'DeleteCampaign';
  readonly success: Scalars['Boolean'];
};

export type DeleteCampaignBroadcast = {
  readonly __typename: 'DeleteCampaignBroadcast';
  readonly success: Scalars['Boolean'];
};

export type DeleteCampaignBroadcastInput = {
  readonly id: Scalars['ID'];
};

export type DeleteCampaignDynamic = {
  readonly __typename: 'DeleteCampaignDynamic';
  readonly success: Scalars['Boolean'];
};

export type DeleteCampaignDynamicDelivery = {
  readonly __typename: 'DeleteCampaignDynamicDelivery';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type DeleteCampaignDynamicDeliveryInput = {
  readonly id: Scalars['ID'];
};

export type DeleteCampaignDynamicInput = {
  readonly deleteReason: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
};

export type DeleteCampaignInput = {
  readonly id: Scalars['ID'];
};

export type DeleteCampaignNoise = {
  readonly __typename: 'DeleteCampaignNoise';
  readonly success: Scalars['Boolean'];
};

export type DeleteCampaignNoiseInput = {
  readonly campaignNoiseId: Scalars['ID'];
};

export type DeleteCampaignPodcast = {
  readonly __typename: 'DeleteCampaignPodcast';
  readonly success: Scalars['Boolean'];
};

export type DeleteCampaignPodcastInput = {
  readonly deleteReason: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
};

export type DeleteCampaignStreaming = {
  readonly __typename: 'DeleteCampaignStreaming';
  readonly success: Scalars['Boolean'];
};

export type DeleteCampaignStreamingDelivery = {
  readonly __typename: 'DeleteCampaignStreamingDelivery';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type DeleteCampaignStreamingDeliveryInput = {
  readonly id: Scalars['ID'];
};

export type DeleteCampaignStreamingInput = {
  readonly id: Scalars['ID'];
};

export type DeleteComment = {
  readonly __typename: 'DeleteComment';
  readonly success: Scalars['Boolean'];
};

export type DeleteCommentInput = {
  readonly commentId: Scalars['ID'];
};

export type DeleteCompany = {
  readonly __typename: 'DeleteCompany';
  readonly issues: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly success: Maybe<Scalars['Boolean']>;
};

export type DeleteCompanyClaim = {
  readonly __typename: 'DeleteCompanyClaim';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type DeleteCompanyClaimInput = {
  readonly id: Scalars['ID'];
};

export type DeleteCompanyInput = {
  readonly id: Scalars['ID'];
};

export type DeleteConversionUrl = {
  readonly __typename: 'DeleteConversionURL';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type DeleteConversionUrlInput = {
  readonly conversionUrlId: Scalars['ID'];
};

export type DeleteDiscount = {
  readonly __typename: 'DeleteDiscount';
  readonly success: Scalars['Boolean'];
};

export type DeleteDiscountInput = {
  readonly discountId: Scalars['ID'];
};

export type DeleteFutureEpisode = {
  readonly __typename: 'DeleteFutureEpisode';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type DeleteFutureEpisodeInput = {
  readonly id: Scalars['ID'];
};

export type DeleteGenericTag = {
  readonly __typename: 'DeleteGenericTag';
  readonly success: Scalars['Boolean'];
};

export type DeleteGenericTagInput = {
  readonly id: Scalars['ID'];
};

export type DeleteGenericTagRelationship = {
  readonly __typename: 'DeleteGenericTagRelationship';
  readonly success: Scalars['Boolean'];
};

export type DeleteGenericTagRelationshipInput = {
  readonly id: Scalars['ID'];
};

export type DeleteLiftReport = {
  readonly __typename: 'DeleteLiftReport';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type DeleteLiftReportInput = {
  readonly liftReportId: Scalars['ID'];
};

export type DeleteOrganization = {
  readonly __typename: 'DeleteOrganization';
  readonly success: Scalars['Boolean'];
};

export type DeleteOrganizationInput = {
  readonly id: Scalars['ID'];
};

export type DeletePixelEventRecord = {
  readonly __typename: 'DeletePixelEventRecord';
  readonly success: Scalars['Boolean'];
};

export type DeletePixelEventRecordInput = {
  readonly id: Scalars['ID'];
};

export type DeletePodcast = {
  readonly __typename: 'DeletePodcast';
  readonly success: Scalars['Boolean'];
};

export type DeletePodcastInput = {
  readonly id: Scalars['ID'];
};

export type DeletePodcasts = {
  readonly __typename: 'DeletePodcasts';
  readonly success: Scalars['Boolean'];
};

export type DeletePodcastsInput = {
  readonly podcastIds: ReadonlyArray<InputMaybe<Scalars['ID']>>;
};

export type DeletePodlinkDomain = {
  readonly __typename: 'DeletePodlinkDomain';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type DeletePodlinkDomainInput = {
  readonly id: Scalars['ID'];
};

export type DeleteProduct = {
  readonly __typename: 'DeleteProduct';
  readonly companyId: Maybe<Scalars['ID']>;
  readonly id: Maybe<Scalars['ID']>;
};

export type DeleteProductInput = {
  readonly id: Scalars['ID'];
};

export type DeleteReasonObject = {
  readonly __typename: 'DeleteReasonObject';
  readonly deleteReason: Maybe<Scalars['String']>;
  readonly deleteReasonDisplay: Maybe<Scalars['String']>;
};

export type DeleteReviewer = {
  readonly __typename: 'DeleteReviewer';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type DeleteReviewerInput = {
  readonly id: Scalars['ID'];
};

export type DeleteSavedExport = {
  readonly __typename: 'DeleteSavedExport';
  readonly success: Scalars['Boolean'];
};

export type DeleteSavedExportInput = {
  readonly id: Scalars['ID'];
};

/** Deletes the token in the HTTP header, or if not provided in header, in the input if provided. */
export type DeleteToken = {
  readonly __typename: 'DeleteToken';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type DeleteTokenInput = {
  readonly token: InputMaybe<Scalars['String']>;
};

export type DeleteUserAccess = {
  readonly __typename: 'DeleteUserAccess';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type DeleteUserAccessInput = {
  readonly userAccessId: Scalars['ID'];
};

export type DesignPodlinkDomain = {
  readonly __typename: 'DesignPodlinkDomain';
  readonly podlinkDomain: Maybe<PodlinkDomainObject>;
};

export type DesignPodlinkDomainInput = {
  readonly backgroundColor: InputMaybe<Scalars['String']>;
  readonly description: InputMaybe<Scalars['String']>;
  readonly faviconId: InputMaybe<Scalars['ID']>;
  readonly fontColor: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly imageId: InputMaybe<Scalars['ID']>;
  readonly primaryColor: InputMaybe<Scalars['String']>;
  readonly secondaryColor: InputMaybe<Scalars['String']>;
  readonly title: InputMaybe<Scalars['String']>;
};

export type DiscountObject = {
  readonly __typename: 'DiscountObject';
  readonly date: Scalars['Date'];
  readonly financial: FinancialObject;
  readonly id: Scalars['String'];
  readonly impressions: Scalars['Int'];
};

export type DownloadAd = {
  readonly __typename: 'DownloadAd';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type DownloadAdInput = {
  readonly id: Scalars['ID'];
  readonly padding: Scalars['Int'];
};

export type DownloadLocation = {
  readonly __typename: 'DownloadLocation';
  readonly city: Maybe<Scalars['String']>;
  readonly country: Maybe<Scalars['String']>;
  readonly latitude: Maybe<Scalars['Float']>;
  readonly longitude: Maybe<Scalars['Float']>;
  readonly region: Maybe<Scalars['String']>;
  readonly total: Maybe<Scalars['RoundedInt']>;
};

export type DownloadLocationsQuery = {
  readonly __typename: 'DownloadLocationsQuery';
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicEpisodeId: Maybe<Scalars['ID']>;
  readonly campaignDynamicFeedId: Maybe<Scalars['ID']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignDynamicIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly cities: Maybe<ReadonlyArray<Maybe<DownloadLocation>>>;
  readonly city: Maybe<Scalars['String']>;
  readonly count: Maybe<Scalars['Int']>;
  readonly countries: Maybe<ReadonlyArray<Maybe<DownloadLocation>>>;
  readonly country: Maybe<Scalars['String']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly nodes: Maybe<ReadonlyArray<Maybe<DownloadLocation>>>;
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly publisherIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly region: Maybe<Scalars['String']>;
  readonly regions: Maybe<ReadonlyArray<Maybe<DownloadLocation>>>;
};


export type DownloadLocationsQueryCitiesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type DownloadLocationsQueryCountriesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type DownloadLocationsQueryNodesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type DownloadLocationsQueryRegionsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};

export type DownloadMaids = {
  readonly __typename: 'DownloadMaids';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type DownloadMaidsInput = {
  readonly countries: ReadonlyArray<InputMaybe<Scalars['String']>>;
  readonly objectType: Scalars['String'];
  readonly pk: Scalars['ID'];
};

export type DownloadsObject = {
  readonly __typename: 'DownloadsObject';
  readonly after: Maybe<Scalars['DateTime']>;
  readonly algorithm: Maybe<Scalars['String']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicEpisodeId: Maybe<Scalars['ID']>;
  readonly campaignDynamicFeedId: Maybe<Scalars['ID']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignDynamicIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly hourly: Maybe<ReadonlyArray<Maybe<IabHourlyDownload>>>;
  readonly ips: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly kind: Maybe<Scalars['String']>;
  readonly locations: Maybe<DownloadLocationsQuery>;
  readonly organizationId: Maybe<Scalars['ID']>;
};


export type DownloadsObjectHourlyArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  interval: InputMaybe<Scalars['String']>;
  kind: InputMaybe<Scalars['String']>;
};


export type DownloadsObjectLocationsArgs = {
  city: InputMaybe<Scalars['String']>;
  country: InputMaybe<Scalars['String']>;
  region: InputMaybe<Scalars['String']>;
};

export type DuplicateObject = {
  readonly __typename: 'DuplicateObject';
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly density: Maybe<Scalars['Float']>;
  readonly distance: Maybe<Scalars['RoundedInt']>;
  readonly end: Maybe<Scalars['Float']>;
  readonly start: Maybe<Scalars['Float']>;
};

export type DynamicApprovalObject = {
  readonly __typename: 'DynamicApprovalObject';
  readonly campaignDynamic: CampaignDynamicObject;
  readonly comment: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly rejectedReason: Maybe<Scalars['String']>;
  readonly requestedAt: Scalars['DateTime'];
  readonly requestedBy: Maybe<UnAuthedUserObject>;
  readonly requestedByOrganization: Maybe<ApprovalOrganization>;
  readonly requestee: Maybe<UnAuthedUserObject>;
  readonly requesteeOrganization: Maybe<ApprovalOrganization>;
  readonly state: DynamicApprovalState;
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedBy: Maybe<UnAuthedUserObject>;
};

/** An enumeration. */
export enum DynamicApprovalState {
  /** Approved */
  Approved = 'APPROVED',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Requested */
  Requested = 'REQUESTED'
}

export type Embedded = {
  readonly __typename: 'Embedded';
  readonly episodeId: Maybe<Scalars['String']>;
  readonly feedId: Maybe<Scalars['String']>;
};

export type EnclosureObject = {
  readonly __typename: 'EnclosureObject';
  readonly barstoolEpisodeId: Maybe<Scalars['String']>;
  readonly canonical: Maybe<Scalars['String']>;
  readonly contentPath: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly duration: Maybe<Scalars['Float']>;
  readonly episode: Maybe<EpisodeObject>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly fileSize: Maybe<Scalars['Int']>;
  readonly href: Scalars['String'];
  readonly id: Scalars['String'];
  readonly length: Maybe<Scalars['String']>;
  readonly megaphoneId: Maybe<Scalars['String']>;
  readonly mpegPath: Maybe<Scalars['String']>;
  readonly podbeanId: Maybe<Scalars['String']>;
  readonly prefixChartable: Scalars['Boolean'];
  readonly prefixPodsights: Scalars['Boolean'];
  readonly prefixPodtrac: Scalars['Boolean'];
  readonly proxiedEnclosure: Maybe<ProxiedEnclosureObject>;
  readonly proxiedEnclosures: ReadonlyArray<ProxiedEnclosureObject>;
  readonly sampleRate: Maybe<Scalars['Int']>;
  readonly source: Maybe<EpisodeObject>;
  readonly sourcePath: Maybe<Scalars['String']>;
  readonly type: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly waveform: Maybe<Scalars['JSONString']>;
};


export type EnclosureObjectProxiedEnclosureArgs = {
  proxiedEnclosureId: InputMaybe<Scalars['ID']>;
};

export type EpisodeMinutesByDay = {
  readonly __typename: 'EpisodeMinutesByDay';
  readonly day: Scalars['Date'];
  readonly minutes: Scalars['Float'];
};

export type EpisodeNode = {
  readonly __typename: 'EpisodeNode';
  readonly count: Maybe<Scalars['Int']>;
  readonly hasNext: Maybe<Scalars['Boolean']>;
  readonly hasPrev: Maybe<Scalars['Boolean']>;
  readonly nodes: Maybe<ReadonlyArray<Maybe<EpisodeObject>>>;
  readonly offset: Maybe<Scalars['Int']>;
  readonly pages: Maybe<Scalars['Int']>;
};

export type EpisodeObject = {
  readonly __typename: 'EpisodeObject';
  readonly adMarkers: Maybe<ReadonlyArray<Maybe<AdMarkerObject>>>;
  readonly ads: Maybe<ReadonlyArray<Maybe<AdObject>>>;
  readonly adspottingModelVersion: Maybe<Scalars['String']>;
  readonly asr: Maybe<Asr>;
  readonly campaignEpisodes: ReadonlyArray<CampaignEpisodeObject>;
  readonly content: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly description: Maybe<Scalars['String']>;
  readonly downloads: Maybe<DownloadsObject>;
  readonly duplicates: Maybe<ReadonlyArray<Maybe<DuplicateObject>>>;
  readonly enclosure: Maybe<EnclosureObject>;
  readonly enclosures: ReadonlyArray<EnclosureObject>;
  readonly feed: Maybe<LimitedFeedObject>;
  readonly guid: Scalars['String'];
  readonly guidislink: Scalars['Boolean'];
  readonly hasAdMarkers: Scalars['Boolean'];
  readonly hasAsrLabels: Scalars['Boolean'];
  readonly hasAsrTranscript: Scalars['Boolean'];
  readonly hasDuplicates: Scalars['Boolean'];
  readonly hasKeywords: Maybe<Scalars['Boolean']>;
  readonly hasNoAds: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly image: Maybe<ImageObject>;
  readonly isReady: Scalars['Boolean'];
  readonly itunesDuration: Maybe<Scalars['String']>;
  readonly itunesEpisode: Maybe<Scalars['Int']>;
  readonly itunesEpisodetype: Maybe<Scalars['String']>;
  readonly itunesExplicit: Scalars['Boolean'];
  readonly itunesGuid: Maybe<Scalars['String']>;
  readonly itunesSeason: Maybe<Scalars['Int']>;
  readonly itunesSubtitle: Maybe<Scalars['String']>;
  readonly itunesSummary: Maybe<Scalars['String']>;
  readonly itunesTitle: Maybe<Scalars['String']>;
  readonly keywords: Maybe<ReadonlyArray<Maybe<KeywordObject>>>;
  readonly maids: Maybe<MaidsObject>;
  readonly placement: Maybe<PlacementObject>;
  readonly placements: Maybe<ReadonlyArray<Maybe<PlacementObject>>>;
  readonly placementsCount: Maybe<Scalars['Int']>;
  readonly processed: Maybe<Scalars['CoercedBoolean']>;
  readonly published: Maybe<Scalars['DateTime']>;
  readonly reviewed: Maybe<Scalars['Boolean']>;
  readonly reviews: Maybe<ReadonlyArray<Maybe<ReviewObject>>>;
  readonly reviewsCount: Maybe<Scalars['Int']>;
  readonly saveSource: Scalars['Boolean'];
  readonly subtitle: Maybe<Scalars['String']>;
  readonly summary: Maybe<Scalars['String']>;
  readonly tags: Maybe<ReadonlyArray<Maybe<FeedTagObject>>>;
  readonly title: Scalars['String'];
  readonly updated: Maybe<Scalars['DateTime']>;
  readonly updatedAt: Scalars['DateTime'];
};


export type EpisodeObjectAdsArgs = {
  companySlug: InputMaybe<Scalars['String']>;
  reviewerId: InputMaybe<Scalars['ID']>;
};


export type EpisodeObjectDescriptionArgs = {
  chars: InputMaybe<Scalars['Int']>;
  words: InputMaybe<Scalars['Int']>;
};


export type EpisodeObjectDownloadsArgs = {
  after: InputMaybe<Scalars['String']>;
  algorithm: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  kind: InputMaybe<Scalars['String']>;
};


export type EpisodeObjectPlacementArgs = {
  id: InputMaybe<Scalars['ID']>;
};


export type EpisodeObjectPlacementsArgs = {
  active: InputMaybe<Scalars['Boolean']>;
  companyId: InputMaybe<Scalars['ID']>;
  proxiedEnclosureId: InputMaybe<Scalars['ID']>;
  reviewerId: InputMaybe<Scalars['ID']>;
};


export type EpisodeObjectPlacementsCountArgs = {
  active: InputMaybe<Scalars['Boolean']>;
  companyId: InputMaybe<Scalars['ID']>;
};


export type EpisodeObjectReviewsArgs = {
  includeAdReviews: InputMaybe<Scalars['Boolean']>;
  reviewerId: InputMaybe<Scalars['ID']>;
};

export type EpisodePathVarsObject = {
  readonly __typename: 'EpisodePathVarsObject';
  readonly episodeId: Maybe<Scalars['String']>;
  readonly feedId: Maybe<Scalars['String']>;
};

export type ExperianMetric = {
  readonly __typename: 'ExperianMetric';
  readonly index: Maybe<Scalars['Float']>;
  readonly nationalMeans: Maybe<Scalars['Float']>;
  readonly percentage: Maybe<Scalars['Float']>;
  readonly variable: Maybe<Scalars['String']>;
};

export type ExperianSegment = {
  readonly __typename: 'ExperianSegment';
  readonly category: Maybe<Scalars['String']>;
  readonly classification: Maybe<Scalars['String']>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly metrics: Maybe<ReadonlyArray<Maybe<ExperianMetric>>>;
  readonly percentExperianMatching: Maybe<Scalars['Float']>;
  readonly topic: Maybe<Scalars['String']>;
  readonly uniqueExperianMatching: Maybe<Scalars['RoundedInt']>;
  readonly uniqueHhCount: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum ExportInstanceExportType {
  /** Admin */
  Admin = 'ADMIN',
  /** Campaign Conversions */
  CampaignConversions = 'CAMPAIGN_CONVERSIONS',
  /** Campaign Daily Overview */
  CampaignDailyOverview = 'CAMPAIGN_DAILY_OVERVIEW',
  /** Campaign Entrances */
  CampaignEntrances = 'CAMPAIGN_ENTRANCES',
  /** Campaign Episode */
  CampaignEpisode = 'CAMPAIGN_EPISODE',
  /** Campaign Impressions */
  CampaignImpressions = 'CAMPAIGN_IMPRESSIONS',
  /** Campaign Overview */
  CampaignOverview = 'CAMPAIGN_OVERVIEW',
  /** Campaign Placements */
  CampaignPlacements = 'CAMPAIGN_PLACEMENTS',
  /** Campaign Podcast */
  CampaignPodcast = 'CAMPAIGN_PODCAST',
  /** Company Placements */
  CompanyPlacements = 'COMPANY_PLACEMENTS',
  /** Episode Downloads Daily */
  EpisodeDownloadsDaily = 'EPISODE_DOWNLOADS_DAILY',
  /** Episode Downloads Hourly */
  EpisodeDownloadsHourly = 'EPISODE_DOWNLOADS_HOURLY',
  /** Episode Placements */
  EpisodePlacements = 'EPISODE_PLACEMENTS',
  /** Feed Placements */
  FeedPlacements = 'FEED_PLACEMENTS',
  /** Feed Segments */
  FeedSegments = 'FEED_SEGMENTS',
  /** Organization Benchmark Campaigns */
  OrganizationBenchmarkCampaigns = 'ORGANIZATION_BENCHMARK_CAMPAIGNS',
  /** Organization Benchmark Campaigns Detail */
  OrganizationBenchmarkCampaignsDetail = 'ORGANIZATION_BENCHMARK_CAMPAIGNS_DETAIL',
  /** Organization Daily Overview */
  OrganizationCampaignConversions = 'ORGANIZATION_CAMPAIGN_CONVERSIONS',
  /** Organization Daily Overview */
  OrganizationDailyOverview = 'ORGANIZATION_DAILY_OVERVIEW',
  /** Organization Interval Overlap */
  OrganizationIntervalOverlap = 'ORGANIZATION_INTERVAL_OVERLAP',
  /** Organization IDs */
  OrganizationListAllIds = 'ORGANIZATION_LIST_ALL_IDS',
  /** Organization Campaign IDs */
  OrganizationListCampaignIds = 'ORGANIZATION_LIST_CAMPAIGN_IDS',
  /** Organization Locations Overview */
  OrganizationLocations = 'ORGANIZATION_LOCATIONS',
  /** Organization Placements */
  OrganizationPlacements = 'ORGANIZATION_PLACEMENTS',
  /** Organization Segments */
  OrganizationSegments = 'ORGANIZATION_SEGMENTS',
  /** Organization Weekly Overview */
  OrganizationWeeklyOverview = 'ORGANIZATION_WEEKLY_OVERVIEW',
  /** Podcast Downloads Daily */
  PodcastDownloadsDaily = 'PODCAST_DOWNLOADS_DAILY',
  /** Podcast Downloads Full */
  PodcastDownloadsFull = 'PODCAST_DOWNLOADS_FULL',
  /** Podcast Downloads Hourly */
  PodcastDownloadsHourly = 'PODCAST_DOWNLOADS_HOURLY'
}

export type ExportInstanceObject = {
  readonly __typename: 'ExportInstanceObject';
  readonly archived: Scalars['Boolean'];
  readonly bucketObjectName: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy: Maybe<LimitedUserObject>;
  readonly downloadUrl: Maybe<Scalars['String']>;
  readonly exportJobStatus: Maybe<ExportJobStatusObject>;
  readonly exportParameters: Maybe<Scalars['JSONString']>;
  readonly exportType: ExportInstanceExportType;
  readonly fields: Maybe<Scalars['JSONString']>;
  readonly id: Scalars['String'];
  readonly lastTouch: Scalars['Boolean'];
  readonly orderBy: Maybe<Scalars['JSONString']>;
  readonly organization: Maybe<LimitedOrganizationObject>;
  readonly rowCount: Maybe<Scalars['Int']>;
  readonly savedExport: Maybe<SavedExportObject>;
  readonly status: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
};

export type ExportInstanceParameters = {
  readonly actions: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly attr: InputMaybe<Scalars['String']>;
  readonly campaignId: InputMaybe<Scalars['ID']>;
  readonly campaignIds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly endDate: InputMaybe<Scalars['String']>;
  readonly feedIds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly instanceId: Scalars['ID'];
  readonly interval: InputMaybe<Scalars['String']>;
  readonly modelled: InputMaybe<Scalars['Boolean']>;
  readonly publisherIds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly reportPeriod: InputMaybe<Scalars['Int']>;
  readonly spendByImpressions: InputMaybe<Scalars['Boolean']>;
  readonly startDate: InputMaybe<Scalars['String']>;
  readonly trailing: InputMaybe<Scalars['Int']>;
};

export type ExportJobStatusObject = {
  readonly __typename: 'ExportJobStatusObject';
  readonly createdAt: Scalars['DateTime'];
  readonly export: ExportInstanceObject;
  readonly failedReason: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly retryCount: Scalars['Int'];
  readonly status: ExportJobStatusStatus;
  readonly updatedAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum ExportJobStatusStatus {
  /** Job Completed */
  Completed = 'COMPLETED',
  /** Job Failed */
  Failed = 'FAILED',
  /** Job Received */
  Received = 'RECEIVED',
  /** Job Started */
  Started = 'STARTED'
}

export type ExportObject = {
  readonly __typename: 'ExportObject';
  readonly exportInstance: Maybe<ExportInstanceObject>;
  readonly exportInstances: Maybe<ReadonlyArray<Maybe<ExportInstanceObject>>>;
  readonly exportTypes: Maybe<ExportTypes>;
  readonly id: Maybe<Scalars['ID']>;
  readonly organizationId: Scalars['ID'];
  readonly savedExport: Maybe<SavedExportObject>;
  readonly savedExports: Maybe<ReadonlyArray<Maybe<SavedExportObject>>>;
};


export type ExportObjectExportInstanceArgs = {
  id: Scalars['ID'];
};


export type ExportObjectSavedExportArgs = {
  id: Scalars['ID'];
};

export type ExportSupportedParameter = {
  readonly __typename: 'ExportSupportedParameter';
  /** Export Supported Parameters ID */
  readonly id: Scalars['ID'];
  /** Export Parameter Name */
  readonly name: Maybe<Scalars['String']>;
  /** Export Parameter Type */
  readonly type: Maybe<Scalars['String']>;
};

export type ExportType = {
  readonly __typename: 'ExportType';
  readonly exportKind: Maybe<Scalars['String']>;
  readonly exportType: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly supportedParameters: Maybe<ReadonlyArray<Maybe<ExportSupportedParameter>>>;
};

export type ExportTypeColumn = {
  readonly __typename: 'ExportTypeColumn';
  /** Export column example */
  readonly example: Maybe<Scalars['String']>;
  /** Field ID */
  readonly id: Scalars['ID'];
  /** Export column field name */
  readonly name: Maybe<Scalars['String']>;
};

export type ExportTypes = {
  readonly __typename: 'ExportTypes';
  readonly columns: Maybe<ReadonlyArray<Maybe<ExportTypeColumn>>>;
  /** Organization id */
  readonly id: Scalars['ID'];
  readonly organizationId: Scalars['ID'];
  readonly types: Maybe<ReadonlyArray<Maybe<ExportType>>>;
};


export type ExportTypesColumnsArgs = {
  typeId: Scalars['ID'];
};

export type FeedInterface = {
  readonly description: Maybe<Scalars['String']>;
  readonly isArt19: Maybe<Scalars['Boolean']>;
  readonly isMegaphone: Maybe<Scalars['Boolean']>;
};


export type FeedInterfaceDescriptionArgs = {
  chars: InputMaybe<Scalars['Int']>;
  words: InputMaybe<Scalars['Int']>;
};

export type FeedObject = FeedInterface & {
  readonly __typename: 'FeedObject';
  readonly ads: Maybe<ReadonlyArray<Maybe<AdObject>>>;
  readonly adsCount: Maybe<Scalars['RoundedInt']>;
  readonly appleUrl: Maybe<Scalars['String']>;
  readonly author: Maybe<Scalars['String']>;
  readonly avgRank: Maybe<Scalars['Int']>;
  readonly campaignTargets: Maybe<ReadonlyArray<Maybe<CampaignTargetObject>>>;
  readonly castboxUrl: Maybe<Scalars['String']>;
  readonly companies: Maybe<ReadonlyArray<Maybe<CompanyObject>>>;
  readonly contactEmail: Maybe<Scalars['String']>;
  readonly content: Maybe<Scalars['String']>;
  readonly contentMd5: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly dayRankings: Maybe<ReadonlyArray<Maybe<DayRankObject>>>;
  readonly description: Maybe<Scalars['String']>;
  readonly duplicatesEpisodesCount: Maybe<Scalars['Int']>;
  readonly episode: Maybe<EpisodeObject>;
  readonly episodeNodes: Maybe<EpisodeNode>;
  readonly episodes: Maybe<ReadonlyArray<Maybe<EpisodeObject>>>;
  readonly episodesCount: Maybe<Scalars['Int']>;
  readonly experianSegments: Maybe<ReadonlyArray<Maybe<ExperianSegment>>>;
  readonly experianTopics: Maybe<ReadonlyArray<Maybe<Topic>>>;
  readonly generator: Maybe<Scalars['String']>;
  readonly googlePodcastsUrl: Maybe<Scalars['String']>;
  readonly hasNoAds: Scalars['Boolean'];
  readonly href: Scalars['String'];
  readonly id: Scalars['String'];
  readonly image: Maybe<ImageObject>;
  readonly isArt19: Maybe<Scalars['Boolean']>;
  readonly isExperianApproved: Scalars['Boolean'];
  readonly isIgnoredCcpa: Scalars['Boolean'];
  readonly isIgnoredGdpr: Scalars['Boolean'];
  readonly isMegaphone: Maybe<Scalars['Boolean']>;
  readonly isNielsenApproved: Scalars['Boolean'];
  readonly itunesAuthor: Maybe<Scalars['String']>;
  readonly itunesExplicit: Scalars['Boolean'];
  readonly itunesId: Maybe<Scalars['String']>;
  readonly itunesNewFeedUrl: Maybe<Scalars['String']>;
  readonly itunesSubtitle: Maybe<Scalars['String']>;
  readonly itunesSummary: Maybe<Scalars['String']>;
  readonly itunesType: Maybe<Scalars['String']>;
  readonly language: Maybe<Scalars['String']>;
  readonly lastPublished: Maybe<Scalars['DateTime']>;
  readonly link: Maybe<Scalars['String']>;
  readonly measure: Scalars['Boolean'];
  readonly mosaicTypes: Maybe<ReadonlyArray<Maybe<MosaicTypes>>>;
  readonly needsPlacementsCount: Maybe<Scalars['Int']>;
  readonly nielsenSegments: Maybe<ReadonlyArray<Maybe<NielsenSegment>>>;
  readonly nielsenTopics: Maybe<ReadonlyArray<Maybe<Topic>>>;
  readonly overcastUrl: Maybe<Scalars['String']>;
  readonly placementInfo: Maybe<FeedPlacementInfoObject>;
  readonly placementsCount: Maybe<Scalars['Int']>;
  readonly podcast: Maybe<PodcastObject>;
  readonly processedEpisodesCount: Maybe<Scalars['Int']>;
  readonly processing: Scalars['Boolean'];
  readonly published: Maybe<Scalars['DateTime']>;
  readonly publisherEmail: Maybe<Scalars['String']>;
  readonly publisherName: Maybe<Scalars['String']>;
  readonly rankings: ReadonlyArray<RankObject>;
  readonly rights: Maybe<Scalars['String']>;
  readonly saveNewEpisodeSources: Scalars['Boolean'];
  readonly segmentOverlap: Maybe<Scalars['Float']>;
  readonly spotifyId: Maybe<Scalars['String']>;
  readonly spotifyUrl: Maybe<Scalars['String']>;
  readonly state: FeedState;
  readonly subtitle: Maybe<Scalars['String']>;
  readonly summary: Maybe<Scalars['String']>;
  readonly tags: Maybe<ReadonlyArray<Maybe<FeedTagObject>>>;
  readonly title: Scalars['String'];
  readonly trailer: Maybe<EpisodeObject>;
  readonly ttl: Maybe<Scalars['Int']>;
  readonly updated: Maybe<Scalars['DateTime']>;
  readonly updatedAt: Scalars['DateTime'];
};


export type FeedObjectAdsArgs = {
  after: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Scalars['String']>;
  state: InputMaybe<Scalars['String']>;
};


export type FeedObjectCompaniesArgs = {
  after: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Scalars['String']>;
};


export type FeedObjectDayRankingsArgs = {
  after: InputMaybe<Scalars['String']>;
  category: Scalars['String'];
  provider: Scalars['String'];
};


export type FeedObjectDescriptionArgs = {
  chars: InputMaybe<Scalars['Int']>;
  words: InputMaybe<Scalars['Int']>;
};


export type FeedObjectEpisodeArgs = {
  id: InputMaybe<Scalars['ID']>;
};


export type FeedObjectEpisodeNodesArgs = {
  after: InputMaybe<Scalars['String']>;
  companyId: InputMaybe<Scalars['ID']>;
  companySlug: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Scalars['String']>;
  query: InputMaybe<Scalars['String']>;
};


export type FeedObjectEpisodesArgs = {
  after: InputMaybe<Scalars['String']>;
  companyId: InputMaybe<Scalars['ID']>;
  companySlug: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type FeedObjectEpisodesCountArgs = {
  after: InputMaybe<Scalars['String']>;
  companyId: InputMaybe<Scalars['ID']>;
  companySlug: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
};


export type FeedObjectExperianSegmentsArgs = {
  classifications: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};


export type FeedObjectNielsenSegmentsArgs = {
  classifications: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
};


export type FeedObjectPlacementsCountArgs = {
  after: InputMaybe<Scalars['String']>;
};

export type FeedOverviewSearchResults = {
  readonly __typename: 'FeedOverviewSearchResults';
  readonly results: Maybe<ReadonlyArray<Maybe<FeedObject>>>;
  readonly total: Maybe<Scalars['ID']>;
};

export type FeedPlacementInfoObject = {
  readonly __typename: 'FeedPlacementInfoObject';
  readonly avgPlacements: Maybe<Scalars['RoundedInt']>;
  readonly maxPlacements: Maybe<Scalars['RoundedInt']>;
  readonly positions: Maybe<ReadonlyArray<Maybe<FeedPlacementPositionObject>>>;
};

export type FeedPlacementPositionObject = {
  readonly __typename: 'FeedPlacementPositionObject';
  readonly avgDuration: Maybe<Scalars['Float']>;
  readonly avgStartTime: Maybe<Scalars['Float']>;
  readonly position: Maybe<Scalars['RoundedInt']>;
};

export type FeedSearch = {
  readonly __typename: 'FeedSearch';
  readonly count: Maybe<Scalars['Int']>;
  readonly limit: Maybe<Scalars['Int']>;
  readonly offset: Maybe<Scalars['Int']>;
  readonly results: Maybe<ReadonlyArray<Maybe<FeedSearchObject>>>;
};

export type FeedSearchObject = {
  readonly __typename: 'FeedSearchObject';
  readonly id: Scalars['String'];
  readonly podcast: Maybe<FeedSearchPodcastObject>;
  readonly title: Scalars['String'];
};

export type FeedSearchOrganizationObject = {
  readonly __typename: 'FeedSearchOrganizationObject';
  readonly id: Maybe<Scalars['ID']>;
};

export type FeedSearchPodcastObject = {
  readonly __typename: 'FeedSearchPodcastObject';
  readonly id: Maybe<Scalars['ID']>;
  readonly organization: Maybe<FeedSearchOrganizationObject>;
};

/** An enumeration. */
export enum FeedState {
  /** active */
  Active = 'ACTIVE',
  /** Archived */
  Archived = 'ARCHIVED'
}

export type FeedTagObject = {
  readonly __typename: 'FeedTagObject';
  readonly episodeSet: ReadonlyArray<EpisodeObject>;
  readonly id: Scalars['String'];
  readonly label: Maybe<Scalars['String']>;
  readonly scheme: Maybe<Scalars['String']>;
  readonly term: Maybe<Scalars['String']>;
};

export type FieldsInputObject = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type FillCampaignDynamics = {
  readonly __typename: 'FillCampaignDynamics';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type FillCampaignDynamicsInput = {
  readonly id: Scalars['ID'];
};

/** An enumeration. */
export enum FinancialFinancialStatus {
  /** Late */
  Late = 'LATE',
  /** Paid */
  Paid = 'PAID'
}

export type FinancialObject = {
  readonly __typename: 'FinancialObject';
  readonly PaymentTiers: Maybe<ReadonlyArray<Maybe<PaymentTierObject>>>;
  readonly annualImpressionsLimit: Scalars['Int'];
  readonly contractEndDate: Scalars['DateTime'];
  readonly contractStartDate: Scalars['DateTime'];
  readonly contractValue: Scalars['Float'];
  readonly cpmAttribution: Scalars['Float'];
  readonly cpmOverages: Scalars['Float'];
  readonly cpmRate: Scalars['Float'];
  readonly cpmStreaming: Scalars['Float'];
  readonly createdAt: Scalars['DateTime'];
  readonly discounts: ReadonlyArray<DiscountObject>;
  readonly financialStatus: FinancialFinancialStatus;
  readonly hasOverages: Scalars['Boolean'];
  readonly hasReceivedPacingAlert: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly impressions: Scalars['BigInt'];
  readonly isActive: Maybe<Scalars['Boolean']>;
  readonly isAnnual: Scalars['Boolean'];
  readonly isChartable: Scalars['Boolean'];
  readonly isMegaphone: Scalars['Boolean'];
  readonly isMonthly: Scalars['Boolean'];
  readonly isTieredCpm: Scalars['Boolean'];
  readonly monthlyRate: Scalars['Float'];
  readonly paymentTiers: ReadonlyArray<PaymentTierObject>;
  readonly paymentType: FinancialPaymentType;
  readonly showPacingAlertFlag: Maybe<Scalars['Boolean']>;
  readonly signUpDate: Scalars['DateTime'];
  readonly stripeId: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly user: Maybe<LimitedUserObject>;
};

/** An enumeration. */
export enum FinancialPaymentType {
  /** Cost Per Mille */
  Cpm = 'CPM',
  /** Flat Rate */
  FlatRate = 'FLAT_RATE'
}

export type FixRedisForFeedId = {
  readonly __typename: 'FixRedisForFeedId';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type FixRedisForFeedIdInput = {
  readonly feedId: Scalars['ID'];
};

export type FutureEpisodeNodes = {
  readonly __typename: 'FutureEpisodeNodes';
  readonly count: Maybe<Scalars['Int']>;
  readonly hasNext: Maybe<Scalars['Boolean']>;
  readonly hasPrev: Maybe<Scalars['Boolean']>;
  readonly nodes: Maybe<ReadonlyArray<Maybe<FutureEpisodeObject>>>;
  readonly offset: Maybe<Scalars['Int']>;
  readonly pages: Maybe<Scalars['Int']>;
};

export type FutureEpisodeObject = {
  readonly __typename: 'FutureEpisodeObject';
  readonly campaignEpisodes: Maybe<ReadonlyArray<Maybe<CampaignEpisodeObject>>>;
  readonly campaignpodcastSet: ReadonlyArray<CampaignPodcastObject>;
  readonly closeEpisodes: Maybe<ReadonlyArray<Maybe<EpisodeObject>>>;
  readonly closeInCampaignEpisodes: Maybe<ReadonlyArray<Maybe<EpisodeObject>>>;
  readonly createdAt: Scalars['DateTime'];
  readonly expectedPublished: Scalars['DateTime'];
  readonly id: Scalars['String'];
  readonly podcast: Maybe<PodcastIdAndFeedObject>;
  readonly subtitle: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};


export type FutureEpisodeObjectCloseEpisodesArgs = {
  excludeCampaignId: InputMaybe<Scalars['ID']>;
};


export type FutureEpisodeObjectCloseInCampaignEpisodesArgs = {
  campaignId: Scalars['ID'];
};

export type GenericTagObject = {
  readonly __typename: 'GenericTagObject';
  readonly color: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy: Maybe<UserObject>;
  readonly genericTagRelationships: ReadonlyArray<GenericTagRelationshipObject>;
  readonly id: Scalars['String'];
  readonly organization: Maybe<LimitedOrganizationObject>;
  readonly slug: Scalars['String'];
  readonly title: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type GenericTagRelationshipObject = {
  readonly __typename: 'GenericTagRelationshipObject';
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy: Maybe<UserObject>;
  readonly genericTag: GenericTagObject;
  readonly id: Scalars['String'];
  readonly objectName: Maybe<Scalars['String']>;
  readonly objectPk: Scalars['String'];
  readonly tag: Maybe<GenericTagObject>;
  readonly updatedAt: Scalars['DateTime'];
};

export type HideLineItem = {
  readonly __typename: 'HideLineItem';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type HideLineItemInput = {
  readonly lineItemId: Scalars['ID'];
};

export type HideUnmappedSpotifyOrders = {
  readonly __typename: 'HideUnmappedSpotifyOrders';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type HideUnmappedSpotifyOrdersInput = {
  readonly spotifyOrderIds: ReadonlyArray<Scalars['ID']>;
};

export type HourlyNode = {
  readonly __typename: 'HourlyNode';
  readonly hour: Maybe<Scalars['DateTime']>;
  readonly total: Maybe<Scalars['RoundedInt']>;
};

export type IabHourlyDownload = {
  readonly __typename: 'IABHourlyDownload';
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly downloadClass: Maybe<Scalars['String']>;
  readonly hour: Maybe<Scalars['DateTime']>;
};

export type IgnoredContact = {
  readonly __typename: 'IgnoredContact';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type IgnoredContactInput = {
  readonly contactId: Scalars['ID'];
};

export type ImageObject = {
  readonly __typename: 'ImageObject';
  readonly episodeSet: ReadonlyArray<EpisodeObject>;
  readonly height: Maybe<Scalars['Int']>;
  readonly href: Scalars['String'];
  readonly id: Scalars['String'];
  readonly link: Maybe<Scalars['String']>;
  readonly path: Maybe<Scalars['String']>;
  readonly src: Maybe<Scalars['String']>;
  readonly title: Maybe<Scalars['String']>;
  readonly width: Maybe<Scalars['Int']>;
};

export type ImportPodcastIssue = {
  readonly __typename: 'ImportPodcastIssue';
  readonly href: Scalars['String'];
  readonly message: Scalars['String'];
};

export type ImportPodcasts = {
  readonly __typename: 'ImportPodcasts';
  readonly added: ReadonlyArray<Maybe<PodcastObject>>;
  readonly issues: Maybe<ReadonlyArray<Maybe<ImportPodcastIssue>>>;
  readonly podcasts: ReadonlyArray<Maybe<PodcastObject>>;
  readonly remove: ReadonlyArray<Maybe<PodcastObject>>;
};

export type ImportPodcastsInput = {
  readonly hrefs: ReadonlyArray<InputMaybe<Scalars['String']>>;
  readonly organizationId: Scalars['ID'];
};

export type ImpressionsFrequencyNode = {
  readonly __typename: 'ImpressionsFrequencyNode';
  readonly frequency: Maybe<Scalars['RoundedInt']>;
  readonly total: Maybe<Scalars['RoundedInt']>;
};

export type ImpressionsFrequencyObject = ImpressionsInterface & {
  readonly __typename: 'ImpressionsFrequencyObject';
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly max: Maybe<Scalars['RoundedInt']>;
  readonly maxFrequency: Maybe<Scalars['RoundedInt']>;
  readonly nodes: Maybe<ReadonlyArray<Maybe<ImpressionsFrequencyNode>>>;
  readonly organizationId: Scalars['ID'];
  /** Number of households that revcieved over the number of max frequency. */
  readonly over: Maybe<ImpressionsFrequencyOver>;
  readonly publisherIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
};

export type ImpressionsFrequencyOver = {
  readonly __typename: 'ImpressionsFrequencyOver';
  readonly impressions: Maybe<Scalars['RoundedInt']>;
  readonly reach: Maybe<Scalars['RoundedInt']>;
};

export type ImpressionsInterface = {
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly organizationId: Scalars['ID'];
  readonly publisherIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
};

export type ImpressionsObject = ImpressionsInterface & {
  readonly __typename: 'ImpressionsObject';
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly frequency: Maybe<ImpressionsFrequencyObject>;
  readonly households: Maybe<Scalars['RoundedInt']>;
  readonly impressions: Maybe<Scalars['RoundedInt']>;
  readonly intervalReachOverlap: Maybe<ReadonlyArray<Maybe<IntervalReachOverlap>>>;
  readonly locations: Maybe<DownloadLocationsQuery>;
  readonly organizationId: Scalars['ID'];
  readonly overlap: Maybe<ImpressionsOverlapObject>;
  readonly players: Maybe<ReadonlyArray<Maybe<ImpressionsPlayer>>>;
  readonly publisherIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly publishers: Maybe<ReadonlyArray<Maybe<ImpressionsPublisherObject>>>;
  readonly reach: Maybe<Scalars['RoundedInt']>;
};


export type ImpressionsObjectFrequencyArgs = {
  maxFrequency: InputMaybe<Scalars['Int']>;
};


export type ImpressionsObjectIntervalReachOverlapArgs = {
  attr: InputMaybe<Scalars['String']>;
  interval: InputMaybe<Scalars['String']>;
  trailing: InputMaybe<Scalars['Int']>;
};


export type ImpressionsObjectLocationsArgs = {
  city: InputMaybe<Scalars['String']>;
  country: InputMaybe<Scalars['String']>;
  region: InputMaybe<Scalars['String']>;
};

export type ImpressionsOverlapObject = ImpressionsInterface & {
  readonly __typename: 'ImpressionsOverlapObject';
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly campaigns: Maybe<ReadonlyArray<Maybe<CampaignOverlapObject>>>;
  readonly organizationId: Scalars['ID'];
  readonly publisherIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly publishers: Maybe<ReadonlyArray<Maybe<PublisherOverlapObject>>>;
};

export type ImpressionsPlayer = {
  readonly __typename: 'ImpressionsPlayer';
  readonly frequency: Maybe<Scalars['Float']>;
  readonly impressionsBetween5And15: Maybe<Scalars['RoundedInt']>;
  readonly impressionsGreaterThan15: Maybe<Scalars['RoundedInt']>;
  readonly impressionsLessThan5: Maybe<Scalars['RoundedInt']>;
  readonly percentageImpressionsBetween5And15: Maybe<Scalars['Float']>;
  readonly percentageImpressionsGreaterThan15: Maybe<Scalars['Float']>;
  readonly percentageImpressionsLessThan5: Maybe<Scalars['Float']>;
  readonly percentageReachBetween5And15: Maybe<Scalars['Float']>;
  readonly percentageReachGreaterThan15: Maybe<Scalars['Float']>;
  readonly percentageReachLessThan5: Maybe<Scalars['Float']>;
  readonly player: Maybe<Scalars['String']>;
  readonly reach: Maybe<Scalars['RoundedInt']>;
  readonly reachBetween5And15: Maybe<Scalars['RoundedInt']>;
  readonly reachGreaterThan15: Maybe<Scalars['RoundedInt']>;
  readonly reachLessThan5: Maybe<Scalars['RoundedInt']>;
  readonly total: Maybe<Scalars['RoundedInt']>;
};

export type ImpressionsPublisherObject = {
  readonly __typename: 'ImpressionsPublisherObject';
  readonly campaigns: Maybe<ReadonlyArray<Maybe<CampaignObject>>>;
  readonly id: Maybe<Scalars['ID']>;
  readonly name: Maybe<Scalars['String']>;
};

export type IndustryObject = {
  readonly __typename: 'IndustryObject';
  readonly industry: Maybe<Scalars['String']>;
  readonly industryDisplay: Maybe<Scalars['String']>;
};

export type IntervalReachOverlap = {
  readonly __typename: 'IntervalReachOverlap';
  readonly combinedReach: Maybe<Scalars['RoundedInt']>;
  readonly existingReach: Maybe<Scalars['RoundedInt']>;
  readonly impressions: Maybe<Scalars['RoundedInt']>;
  readonly impressionsToNewReach: Maybe<Scalars['RoundedInt']>;
  readonly impressionsToPriorReach: Maybe<Scalars['RoundedInt']>;
  readonly interval: Maybe<Scalars['DateTime']>;
  readonly newReach: Maybe<Scalars['RoundedInt']>;
  readonly percentNew: Maybe<Scalars['Float']>;
  readonly priorImpressions: Maybe<Scalars['RoundedInt']>;
  readonly priorReach: Maybe<Scalars['RoundedInt']>;
  readonly reach: Maybe<Scalars['RoundedInt']>;
};

export type InvalidImpressionsNode = {
  readonly __typename: 'InvalidImpressionsNode';
  readonly count: Scalars['RoundedInt'];
  readonly exampleRecord: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type InviteesInput = {
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
};

export type InvoiceObject = {
  readonly __typename: 'InvoiceObject';
  readonly created: Scalars['DateTime'];
  readonly createdAt: Scalars['DateTime'];
  readonly due: Scalars['DateTime'];
  readonly id: Scalars['String'];
  readonly lineItems: ReadonlyArray<LineItemObject>;
  readonly number: Scalars['Int'];
  readonly organization: OrganizationObject;
  readonly paid: Maybe<Scalars['DateTime']>;
  readonly periodEnd: Scalars['DateTime'];
  readonly periodStart: Scalars['DateTime'];
  readonly sent: Maybe<Scalars['DateTime']>;
  readonly stripeId: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
};

export type IsProcessingPodcast = {
  readonly __typename: 'IsProcessingPodcast';
  readonly podcast: LimitedPodcastObject;
  readonly processing: Scalars['Boolean'];
};

export type IsProcessingPodcastInput = {
  readonly organizationId: Scalars['ID'];
  readonly podcastId: Scalars['ID'];
};

export type IsProcessingPodcasts = {
  readonly __typename: 'IsProcessingPodcasts';
  readonly podcasts: ReadonlyArray<Maybe<PodcastObject>>;
};

export type IsProcessingPodcastsInput = {
  readonly organizationId: Scalars['ID'];
  readonly podcastIds: ReadonlyArray<InputMaybe<Scalars['ID']>>;
};

export type Keepalive = {
  readonly __typename: 'Keepalive';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type KeepaliveInput = {
  readonly channel: Scalars['String'];
};

export type KeyObject = {
  readonly __typename: 'KeyObject';
  readonly actionMaps: ReadonlyArray<ActionMapObject>;
  readonly active: Scalars['Boolean'];
  readonly alias: Scalars['String'];
  readonly conversionUrl: Maybe<ConversionUrlObject>;
  readonly conversionUrls: ReadonlyArray<ConversionUrlObject>;
  readonly createdAt: Scalars['DateTime'];
  readonly debug: Maybe<PixelDebug>;
  readonly domain: Scalars['String'];
  readonly eventTypeChoices: Maybe<ReadonlyArray<Maybe<ChoiceObject>>>;
  readonly installationChoices: Maybe<ReadonlyArray<Maybe<ChoiceObject>>>;
  readonly installations: Scalars['String'];
  readonly key: Scalars['String'];
  readonly pixelEventRecord: Maybe<PixelEventRecordObject>;
  readonly pixelEventRecords: Maybe<ReadonlyArray<Maybe<PixelEventRecordObject>>>;
  readonly role: KeyRole;
  readonly scriptPath: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};


export type KeyObjectConversionUrlArgs = {
  id: Scalars['ID'];
};


export type KeyObjectPixelEventRecordArgs = {
  id: Scalars['ID'];
};

/** An enumeration. */
export enum KeyRole {
  /** Development */
  Development = 'DEVELOPMENT',
  /** Production */
  Production = 'PRODUCTION',
  /** Staging */
  Staging = 'STAGING'
}

export type KeywordObject = {
  readonly __typename: 'KeywordObject';
  readonly confidence: Maybe<Scalars['Float']>;
  readonly id: Maybe<Scalars['Int']>;
  readonly keyword: Maybe<Scalars['String']>;
  readonly timestamp: Maybe<Scalars['Float']>;
  readonly version: Maybe<Scalars['Int']>;
};

export type Lift = {
  readonly __typename: 'Lift';
  readonly after: Maybe<Scalars['RoundedInt']>;
  readonly averages: Maybe<ReadonlyArray<Maybe<LiftReportAveragesObject>>>;
  readonly before: Maybe<Scalars['RoundedInt']>;
  readonly campaignId: Maybe<Scalars['String']>;
  readonly campaignIds: ReadonlyArray<Maybe<Scalars['String']>>;
  readonly liftReportId: Scalars['String'];
  readonly nodes: Maybe<ReadonlyArray<Maybe<LiftReportWeekObject>>>;
};

export type LiftReportAveragesObject = {
  readonly __typename: 'LiftReportAveragesObject';
  readonly avgInitPercentage: Maybe<Scalars['Float']>;
  readonly avgInitValue: Maybe<Scalars['Float']>;
  readonly avgInstalledPercentage: Maybe<Scalars['Float']>;
  readonly avgInstalledValue: Maybe<Scalars['Float']>;
  readonly avgLeadPercentage: Maybe<Scalars['Float']>;
  readonly avgLeadValue: Maybe<Scalars['Float']>;
  readonly avgPurchasePercentage: Maybe<Scalars['Float']>;
  readonly avgPurchaseValue: Maybe<Scalars['Float']>;
  readonly campaignDynamic: Maybe<CampaignDynamicObject>;
  readonly campaignPodcast: Maybe<CampaignPodcastObject>;
  readonly campaignStreaming: Maybe<CampaignStreamingObject>;
  readonly groupCost: Maybe<Scalars['Float']>;
  readonly groupHouseholds: Maybe<Scalars['RoundedInt']>;
  readonly groupId: Maybe<Scalars['String']>;
  readonly groupName: Maybe<Scalars['String']>;
  readonly groupReach: Maybe<Scalars['RoundedInt']>;
  readonly groupType: Maybe<Scalars['String']>;
  readonly kind: Maybe<Scalars['String']>;
  readonly multiplier: Maybe<Scalars['Float']>;
};

export type LiftReportObject = {
  readonly __typename: 'LiftReportObject';
  readonly campaign: Maybe<CampaignObject>;
  readonly campaigns: ReadonlyArray<CampaignObject>;
  readonly clampUrl: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly delta: Scalars['Int'];
  readonly id: Scalars['String'];
  readonly impressionsEndAt: Maybe<Scalars['Date']>;
  readonly impressionsStartAt: Maybe<Scalars['Date']>;
  readonly lift: Maybe<Lift>;
  readonly name: Maybe<Scalars['String']>;
  readonly organization: Maybe<LimitedOrganizationObject>;
  readonly requestedBy: Maybe<LimitedUserObject>;
  readonly requesterOrganization: Maybe<LimitedOrganizationObject>;
  readonly runDate: Maybe<Scalars['Date']>;
  readonly state: LiftReportState;
  readonly updatedAt: Scalars['DateTime'];
  readonly useNoise: Scalars['Boolean'];
};


export type LiftReportObjectLiftArgs = {
  after: InputMaybe<Scalars['Int']>;
  before: InputMaybe<Scalars['Int']>;
};

/** An enumeration. */
export enum LiftReportState {
  /** Draft */
  Draft = 'DRAFT',
  /** Error */
  Error = 'ERROR',
  /** Live */
  Live = 'LIVE',
  /** Requested */
  Requested = 'REQUESTED',
  /** Running */
  Running = 'RUNNING'
}

export type LiftReportWeekObject = {
  readonly __typename: 'LiftReportWeekObject';
  readonly campaignId: Maybe<Scalars['String']>;
  readonly groupCost: Maybe<Scalars['Float']>;
  readonly groupHouseholds: Maybe<Scalars['RoundedInt']>;
  readonly groupId: Maybe<Scalars['String']>;
  readonly groupName: Maybe<Scalars['String']>;
  readonly groupReach: Maybe<Scalars['RoundedInt']>;
  readonly groupType: Maybe<Scalars['String']>;
  readonly initModelled: Maybe<Scalars['Float']>;
  readonly initPercentage: Maybe<Scalars['Float']>;
  readonly initValue: Maybe<Scalars['Float']>;
  readonly installedModelled: Maybe<Scalars['Float']>;
  readonly installedPercentage: Maybe<Scalars['Float']>;
  readonly installedValue: Maybe<Scalars['Float']>;
  readonly kind: Maybe<Scalars['String']>;
  readonly leadModelled: Maybe<Scalars['Float']>;
  readonly leadPercentage: Maybe<Scalars['Float']>;
  readonly leadValue: Maybe<Scalars['Float']>;
  readonly liftReportId: Maybe<Scalars['String']>;
  readonly purchaseModelled: Maybe<Scalars['Float']>;
  readonly purchasePercentage: Maybe<Scalars['Float']>;
  readonly purchaseValue: Maybe<Scalars['Float']>;
  readonly runDate: Maybe<Scalars['Date']>;
  readonly week: Maybe<Scalars['RoundedInt']>;
};

export type LimitedAdObject = {
  readonly __typename: 'LimitedAdObject';
  readonly createdAt: Maybe<Scalars['String']>;
  readonly duration: Maybe<Scalars['Float']>;
  readonly id: Maybe<Scalars['ID']>;
  readonly mpegPath: Maybe<Scalars['String']>;
  readonly waveform: Maybe<Scalars['String']>;
};

export type LimitedCompanyClaimObject = {
  readonly __typename: 'LimitedCompanyClaimObject';
  readonly company: Maybe<LimitedCompanyObject>;
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly id: Maybe<Scalars['ID']>;
  readonly state: Maybe<Scalars['String']>;
};

export type LimitedCompanyObject = {
  readonly __typename: 'LimitedCompanyObject';
  readonly ads: Maybe<ReadonlyArray<Maybe<LimitedAdObject>>>;
  readonly id: Maybe<Scalars['ID']>;
  readonly name: Maybe<Scalars['String']>;
  readonly organization: Maybe<LimitedOrganizationObject>;
};


export type LimitedCompanyObjectAdsArgs = {
  after: InputMaybe<Scalars['String']>;
  feedId: InputMaybe<Scalars['ID']>;
  industry: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Scalars['String']>;
  q: InputMaybe<Scalars['String']>;
  state: InputMaybe<Scalars['String']>;
};

export type LimitedFeedObject = FeedInterface & {
  readonly __typename: 'LimitedFeedObject';
  readonly description: Maybe<Scalars['String']>;
  readonly href: Maybe<Scalars['String']>;
  readonly id: Maybe<Scalars['ID']>;
  readonly image: Maybe<ImageObject>;
  readonly isArt19: Maybe<Scalars['Boolean']>;
  readonly isMegaphone: Maybe<Scalars['Boolean']>;
  readonly subtitle: Maybe<Scalars['String']>;
  readonly summary: Maybe<Scalars['String']>;
  readonly title: Maybe<Scalars['String']>;
};


export type LimitedFeedObjectDescriptionArgs = {
  chars: InputMaybe<Scalars['Int']>;
  words: InputMaybe<Scalars['Int']>;
};

export type LimitedOrganizationObject = {
  readonly __typename: 'LimitedOrganizationObject';
  readonly id: Maybe<Scalars['ID']>;
  readonly kind: Maybe<Scalars['String']>;
  readonly managers: Maybe<ReadonlyArray<Maybe<LimitedOrganizationObject>>>;
  readonly members: Maybe<ReadonlyArray<Maybe<UnAuthedUserObject>>>;
  readonly name: Maybe<Scalars['String']>;
  readonly slug: Maybe<Scalars['String']>;
};

export type LimitedPodcastObject = {
  readonly __typename: 'LimitedPodcastObject';
  readonly createdAt: Maybe<Scalars['DateTime']>;
  readonly feed: Maybe<FeedObject>;
  readonly feeds: Maybe<ReadonlyArray<Maybe<FeedObject>>>;
  readonly id: Maybe<Scalars['ID']>;
  readonly isAdvisor: Maybe<Scalars['Boolean']>;
  readonly organization: Maybe<LimitedOrganizationObject>;
  readonly processingStartedAt: Maybe<Scalars['Date']>;
};

export type LimitedUserObject = {
  readonly __typename: 'LimitedUserObject';
  readonly email: Scalars['String'];
  readonly firstName: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly lastName: Maybe<Scalars['String']>;
};

export type LineItemObject = {
  readonly __typename: 'LineItemObject';
  readonly cost: Maybe<Scalars['Float']>;
  readonly createdAt: Scalars['DateTime'];
  readonly description: Scalars['String'];
  readonly hidden: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly invoice: InvoiceObject;
  readonly quantity: Maybe<Scalars['Int']>;
  readonly rate: Maybe<Scalars['Float']>;
  readonly stripeId: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
};

export type LinkAttributionHourly = {
  readonly __typename: 'LinkAttributionHourly';
  readonly downloads: Maybe<Scalars['RoundedInt']>;
  readonly hour: Maybe<Scalars['DateTime']>;
  readonly reach: Maybe<Scalars['RoundedInt']>;
};

export type LinkClicks = {
  readonly __typename: 'LinkClicks';
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly category: Maybe<Scalars['String']>;
  readonly total: Maybe<Scalars['RoundedInt']>;
  readonly type: Maybe<Scalars['String']>;
};

export type LinkHourly = {
  readonly __typename: 'LinkHourly';
  readonly action: Maybe<Scalars['String']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly hour: Maybe<Scalars['DateTime']>;
  readonly total: Maybe<Scalars['RoundedInt']>;
};

export type LinkTargetEpisode = {
  readonly __typename: 'LinkTargetEpisode';
  readonly downloads: Maybe<Scalars['RoundedInt']>;
  readonly episode: Maybe<EpisodeObject>;
  readonly id: Maybe<Scalars['ID']>;
  readonly reach: Maybe<Scalars['Float']>;
};

export type LinkTargetEpisodes = AttributionInterface & {
  readonly __typename: 'LinkTargetEpisodes';
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly key: Scalars['ID'];
  readonly nodes: Maybe<ReadonlyArray<Maybe<LinkTargetEpisode>>>;
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};


export type LinkTargetEpisodesNodesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};

export type LinkingRequestObject = {
  readonly __typename: 'LinkingRequestObject';
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['String'];
  readonly organization: OrganizationObject;
  readonly requestor: Maybe<LimitedUserObject>;
  readonly state: LinkingRequestState;
  readonly updatedAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum LinkingRequestState {
  /** Accepted */
  Accepted = 'ACCEPTED',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Requested */
  Requested = 'REQUESTED'
}

export type LocationNameObject = {
  readonly __typename: 'LocationNameObject';
  readonly locationName: Scalars['String'];
  readonly locationNameDisplay: Scalars['String'];
};

export type LockEnd = {
  readonly __typename: 'LockEnd';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type LockInput = {
  readonly episodeId: Scalars['ID'];
  readonly reviewerId: InputMaybe<Scalars['ID']>;
  readonly userId: InputMaybe<Scalars['ID']>;
};

export type LockStart = {
  readonly __typename: 'LockStart';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type MaidLocation = {
  readonly __typename: 'MAIDLocation';
  readonly city: Maybe<Scalars['String']>;
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly country: Maybe<Scalars['String']>;
  readonly latitude: Maybe<Scalars['Float']>;
  readonly longitude: Maybe<Scalars['Float']>;
  readonly region: Maybe<Scalars['String']>;
};

export type MaidsObject = {
  readonly __typename: 'MAIDSObject';
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly locations: Maybe<ReadonlyArray<Maybe<MaidLocation>>>;
};


export type MaidsObjectCountArgs = {
  countries: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  kinds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  maidsLength: InputMaybe<Scalars['Int']>;
};


export type MaidsObjectLocationsArgs = {
  countries: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  kinds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  maidsLength: InputMaybe<Scalars['Int']>;
};

export type MachineLearningObject = {
  readonly __typename: 'MachineLearningObject';
  readonly episodesForLabeling: Maybe<ReadonlyArray<Maybe<EpisodePathVarsObject>>>;
  readonly episodesForMaturityRating: Maybe<ReadonlyArray<Maybe<EpisodePathVarsObject>>>;
  readonly feedIdsForBrandAffinity: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};


export type MachineLearningObjectEpisodesForLabelingArgs = {
  after: InputMaybe<Scalars['Date']>;
  before: InputMaybe<Scalars['Date']>;
  limit: InputMaybe<Scalars['Int']>;
};

export type Management = {
  readonly __typename: 'Management';
  readonly attribution: Maybe<AttributionManagement>;
};

export type MarkNewBrandRequestComplete = {
  readonly __typename: 'MarkNewBrandRequestComplete';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type MarkNewBrandRequestCompleteInput = {
  readonly requestId: Scalars['ID'];
};

export type MarketingTargetEpisode = {
  readonly __typename: 'MarketingTargetEpisode';
  readonly attribution: Maybe<Scalars['Float']>;
  readonly downloads: Maybe<Scalars['Float']>;
  readonly episode: Maybe<EpisodeObject>;
  readonly id: Maybe<Scalars['ID']>;
};

export type MarketingTargetEpisodes = AttributionInterface & {
  readonly __typename: 'MarketingTargetEpisodes';
  readonly action: Maybe<Scalars['String']>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly campaignDynamicId: Maybe<Scalars['ID']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly campaignLinkId: Maybe<Scalars['ID']>;
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly episodeId: Maybe<Scalars['ID']>;
  readonly episodeIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly feedIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly key: Scalars['ID'];
  readonly nodes: Maybe<ReadonlyArray<Maybe<MarketingTargetEpisode>>>;
  readonly organizationId: Maybe<Scalars['ID']>;
  readonly targetFeedId: Maybe<Scalars['ID']>;
};


export type MarketingTargetEpisodesNodesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};

/** start processing feeds. */
export type MeasureFeed = {
  readonly __typename: 'MeasureFeed';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type MeasureFeedInput = {
  readonly feedId: Scalars['ID'];
};

export type MediaImageObject = {
  readonly __typename: 'MediaImageObject';
  readonly companies: ReadonlyArray<CompanyObject>;
  readonly contentType: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly height: Scalars['Int'];
  readonly id: Scalars['String'];
  readonly path: Scalars['String'];
  readonly products: ReadonlyArray<ProductObject>;
  readonly size: Scalars['Int'];
  readonly src: Maybe<Scalars['String']>;
  readonly width: Scalars['Int'];
};

export type MembershipObject = {
  readonly __typename: 'MembershipObject';
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['String'];
  readonly role: Maybe<Scalars['String']>;
  readonly roleDisplay: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly user: Maybe<LimitedUserObject>;
};

export type MergeCompany = {
  readonly __typename: 'MergeCompany';
  readonly company: Maybe<CompanyObject>;
  readonly issues: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly success: Maybe<Scalars['Boolean']>;
};

export type MergeCompanyInput = {
  readonly companyId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly productId: InputMaybe<Scalars['ID']>;
  readonly productName: InputMaybe<Scalars['String']>;
};

export type MergeProduct = {
  readonly __typename: 'MergeProduct';
  readonly product: Maybe<ProductObject>;
};

export type MergeProductInput = {
  readonly companyId: Scalars['ID'];
  readonly id: Scalars['ID'];
  readonly productId: InputMaybe<Scalars['ID']>;
};

export type MessageObject = {
  readonly __typename: 'MessageObject';
  readonly action: Maybe<Scalars['String']>;
  readonly attributes: Maybe<Scalars['JSONString']>;
};

/**
 * We create this object with the positive and total counts for control and exposed,
 * and resolvers calculate percentages and significance.
 */
export type MessageResultGroupObject = {
  readonly __typename: 'MessageResultGroupObject';
  readonly controlPercentage: Scalars['Float'];
  readonly controlPositive: Scalars['Int'];
  readonly controlTotal: Scalars['Int'];
  readonly exposedPercentage: Scalars['Float'];
  readonly exposedPositive: Scalars['Int'];
  readonly exposedTotal: Scalars['Int'];
  readonly percentChange: Scalars['Float'];
  readonly significance: Significance;
};

export type Missing = {
  readonly __typename: 'Missing';
  readonly after: Maybe<Scalars['String']>;
  readonly before: Maybe<Scalars['String']>;
  readonly organizationId: Maybe<Scalars['ID']>;
};

export type MosaicType = {
  readonly __typename: 'MosaicType';
  readonly descr: Maybe<Scalars['String']>;
  readonly feat1: Maybe<Scalars['String']>;
  readonly feat2: Maybe<Scalars['String']>;
  readonly feat3: Maybe<Scalars['String']>;
  readonly feat4: Maybe<Scalars['String']>;
  readonly feat5: Maybe<Scalars['String']>;
  readonly feat6: Maybe<Scalars['String']>;
  readonly hh: Maybe<Scalars['Float']>;
  readonly index: Maybe<Scalars['Float']>;
  readonly name: Maybe<Scalars['String']>;
  readonly percentOfPop: Maybe<Scalars['Float']>;
  readonly pop: Maybe<Scalars['Float']>;
  readonly reach: Maybe<Scalars['Float']>;
  readonly segment: Maybe<Scalars['String']>;
  readonly totalHouseholds: Maybe<Scalars['RoundedInt']>;
  readonly type: Maybe<Scalars['String']>;
};

export type MosaicTypes = {
  readonly __typename: 'MosaicTypes';
  readonly feedId: Maybe<Scalars['ID']>;
  readonly types: Maybe<ReadonlyArray<Maybe<MosaicType>>>;
};

export type MultiBillingRecords = {
  readonly __typename: 'MultiBillingRecords';
  readonly dailyCampaignBillingRecords: Maybe<ReadonlyArray<Maybe<DailyCampaignBillingRecord>>>;
  readonly simpleBillingRecords: Maybe<ReadonlyArray<Maybe<SimpleBillingRecord>>>;
};

export type Mutation = {
  readonly __typename: 'Mutation';
  readonly acceptCompanyClaim: Maybe<AcceptCompanyClaim>;
  readonly addAccess: Maybe<AddAccess>;
  readonly addAdStudioAdAccount: Maybe<AddAdStudioAdAccount>;
  /** We can save the secret on multiple objects, so this is just easier. */
  readonly addArt19Secret: Maybe<AddArt19Secret>;
  readonly addDomain: Maybe<AddDomain>;
  readonly addFeed: Maybe<AddFeed>;
  readonly addMember: Maybe<AddMember>;
  readonly addOrganizationAdvisor: Maybe<AddAdvisorForOrganization>;
  readonly addPixel: Maybe<CreateKey>;
  readonly addPixelAndClaimCompany: Maybe<CreateKeyAndCompanyClaim>;
  readonly addTag: Maybe<AddTag>;
  /** start processing feeds. */
  readonly archiveFeed: Maybe<ArchiveFeed>;
  readonly assignSpotifyOrderBrand: Maybe<AssignSpotifyOrderBrand>;
  /** run the ranges. */
  readonly attributionCampaignPreRun: Maybe<AttributionCampaignPreRun>;
  /** I clear out the pre directories to get a fresh start if there is an issue. */
  readonly attributionPreClearRange: Maybe<AttributionPreClearRange>;
  /** run the ranges. */
  readonly attributionPreRun: Maybe<AttributionPreRun>;
  readonly attributionRun: Maybe<AttributionRun>;
  readonly authed: Maybe<Authed>;
  readonly backdateRange: Maybe<BackdateRange>;
  readonly checkCampaignPodcastPrefix: Maybe<CheckCampaignPodcastPrefix>;
  readonly claimAddPodcast: Maybe<ClaimAddPodcast>;
  readonly claimFeed: Maybe<ClaimFeed>;
  readonly clearQueue: Maybe<ClearQueue>;
  readonly completePlacements: Maybe<CompletePlacements>;
  readonly contactPublisher: Maybe<ContactPublisher>;
  readonly contactedContact: Maybe<ContactedContact>;
  readonly createActionMap: Maybe<CreateActionMap>;
  readonly createAdvisorSavedSearch: Maybe<CreateAdvisorSavedSearch>;
  /** Used to generate an API key that can be used for querying our API directly.  We cannot retrieve this. */
  readonly createApiKey: Maybe<CreateApiKey>;
  readonly createAsset: Maybe<CreateAsset>;
  readonly createBillingInfo: Maybe<CreateBillingInfo>;
  readonly createBrandInvites: Maybe<CreateBrandInvites>;
  readonly createCampaign: Maybe<CreateCampaign>;
  readonly createCampaignApprovals: Maybe<CreateCampaignApprovals>;
  readonly createCampaignBroadcast: Maybe<CreateCampaignBroadcast>;
  readonly createCampaignDynamic: Maybe<CreateCampaignDynamic>;
  readonly createCampaignDynamicDelivery: Maybe<CreateCampaignDynamicDelivery>;
  readonly createCampaignEpisode: Maybe<CreateCampaignEpisode>;
  readonly createCampaignLink: Maybe<CreateCampaignLink>;
  readonly createCampaignNoise: Maybe<CreateCampaignNoise>;
  readonly createCampaignPodcast: Maybe<CreateCampaignPodcast>;
  readonly createCampaignStreaming: Maybe<CreateCampaignStreaming>;
  readonly createCampaignStreamingDelivery: Maybe<CreateCampaignStreamingDelivery>;
  readonly createCampaignTarget: Maybe<CreateCampaignTarget>;
  readonly createComment: Maybe<CreateComment>;
  readonly createCompany: Maybe<CreateCompany>;
  readonly createCompanyClaim: Maybe<CreateCompanyClaim>;
  readonly createContact: Maybe<CreateContact>;
  readonly createConversionUrl: Maybe<CreateConversionUrl>;
  readonly createDiscount: Maybe<CreateDiscount>;
  readonly createEpisodeRating: Maybe<CreateEpisodeRating>;
  readonly createEpisodeRatingException: Maybe<CreateEpisodeRatingException>;
  readonly createExportInstance: Maybe<CreateExportInstance>;
  readonly createExportToken: Maybe<CreateExportToken>;
  readonly createFutureEpisode: Maybe<CreateFutureEpisode>;
  readonly createGenericTag: Maybe<CreateGenericTag>;
  readonly createGenericTagRelationship: Maybe<CreateGenericTagRelationship>;
  readonly createImage: Maybe<CreateImage>;
  readonly createInvoice: Maybe<CreateInvoice>;
  readonly createLiftReport: Maybe<CreateLiftReport>;
  readonly createOrganization: Maybe<CreateOrganization>;
  readonly createOrganizationWithPixel: Maybe<CreateOrganizationWithPixel>;
  readonly createPaymentTier: Maybe<CreatePaymentTier>;
  readonly createPixelEventRecord: Maybe<CreatePixelEventRecord>;
  readonly createPodcast: Maybe<CreatePodcast>;
  readonly createPodlinkDomain: Maybe<CreatePodlinkDomain>;
  readonly createReviewer: Maybe<CreateReviewer>;
  readonly createSavedExport: Maybe<CreateSavedExport>;
  /**
   * Used to generate an API key that is separate from any actual user,
   * giving access to only the current organization.
   */
  readonly createServiceAccountApiKey: Maybe<CreateServiceAccountApiKey>;
  readonly createSpotifyCampaignImportRequests: Maybe<CreateSpotifyCampaignImportRequests>;
  readonly createTermsAndConditions: Maybe<CreateTermsAndConditions>;
  readonly createUser: Maybe<CreateUser>;
  readonly createUserAccess: Maybe<CreateUserAccess>;
  readonly createUserSkippedTerms: Maybe<CreateUserSkippedTerms>;
  readonly deleteActionMap: Maybe<DeleteActionMap>;
  readonly deleteAdvisorSavedSearch: Maybe<DeleteAdvisorSavedSearch>;
  readonly deleteCampaign: Maybe<DeleteCampaign>;
  readonly deleteCampaignBroadcast: Maybe<DeleteCampaignBroadcast>;
  readonly deleteCampaignDynamic: Maybe<DeleteCampaignDynamic>;
  readonly deleteCampaignDynamicDelivery: Maybe<DeleteCampaignDynamicDelivery>;
  readonly deleteCampaignNoise: Maybe<DeleteCampaignNoise>;
  readonly deleteCampaignPodcast: Maybe<DeleteCampaignPodcast>;
  readonly deleteCampaignStreaming: Maybe<DeleteCampaignStreaming>;
  readonly deleteCampaignStreamingDelivery: Maybe<DeleteCampaignStreamingDelivery>;
  readonly deleteComment: Maybe<DeleteComment>;
  readonly deleteCompany: Maybe<DeleteCompany>;
  readonly deleteCompanyClaim: Maybe<DeleteCompanyClaim>;
  readonly deleteConversionUrl: Maybe<DeleteConversionUrl>;
  readonly deleteDiscount: Maybe<DeleteDiscount>;
  readonly deleteFutureEpisode: Maybe<DeleteFutureEpisode>;
  readonly deleteGenericTag: Maybe<DeleteGenericTag>;
  readonly deleteGenericTagRelationship: Maybe<DeleteGenericTagRelationship>;
  readonly deleteLiftReport: Maybe<DeleteLiftReport>;
  readonly deleteOrganization: Maybe<DeleteOrganization>;
  readonly deletePixelEventRecord: Maybe<DeletePixelEventRecord>;
  readonly deletePodcast: Maybe<DeletePodcast>;
  readonly deletePodcasts: Maybe<DeletePodcasts>;
  readonly deletePodlinkDomain: Maybe<DeletePodlinkDomain>;
  readonly deleteProduct: Maybe<DeleteProduct>;
  readonly deleteReviewer: Maybe<DeleteReviewer>;
  readonly deleteSavedExport: Maybe<DeleteSavedExport>;
  /** Deletes the token in the HTTP header, or if not provided in header, in the input if provided. */
  readonly deleteToken: Maybe<DeleteToken>;
  readonly deleteUserAccess: Maybe<DeleteUserAccess>;
  readonly designPodlinkDomain: Maybe<DesignPodlinkDomain>;
  readonly downloadAd: Maybe<DownloadAd>;
  readonly downloadMaids: Maybe<DownloadMaids>;
  readonly fillCampaignDynamics: Maybe<FillCampaignDynamics>;
  readonly fixRedisForFeedId: Maybe<FixRedisForFeedId>;
  readonly hideLineItem: Maybe<HideLineItem>;
  readonly hideUnmappedSpotifyOrders: Maybe<HideUnmappedSpotifyOrders>;
  readonly ignoredContact: Maybe<IgnoredContact>;
  readonly importPodcasts: Maybe<ImportPodcasts>;
  readonly isProcessingPodcast: Maybe<IsProcessingPodcast>;
  readonly isProcessingPodcasts: Maybe<IsProcessingPodcasts>;
  readonly keepalive: Maybe<Keepalive>;
  readonly lockEnd: Maybe<LockEnd>;
  readonly lockStart: Maybe<LockStart>;
  readonly markNewBrandRequestComplete: Maybe<MarkNewBrandRequestComplete>;
  /** start processing feeds. */
  readonly measureFeed: Maybe<MeasureFeed>;
  readonly mergeCompany: Maybe<MergeCompany>;
  readonly mergeProduct: Maybe<MergeProduct>;
  /** start processing feeds. */
  readonly noAdsFeed: Maybe<NoAdsFeed>;
  readonly noPlacements: Maybe<NoPlacements>;
  readonly processCampaignEpisodes: Maybe<ProcessCampaignEpisodes>;
  /** start processing feeds. */
  readonly processFeed: Maybe<ProcessFeed>;
  readonly publishMessage: Maybe<PublishMessage>;
  readonly publishSpotifyBrandLiftReport: Maybe<PublishSpotifyBrandLiftReport>;
  readonly publisherApproveCampaign: Maybe<PublisherApproveCampaign>;
  readonly publisherRejectCampaign: Maybe<PublisherRejectCampaign>;
  readonly rejectAd: Maybe<RejectAd>;
  readonly rejectCompanyClaim: Maybe<RejectCompanyClaim>;
  readonly removeAccess: Maybe<RemoveAccess>;
  readonly removeCampaignEpisode: Maybe<RemoveCampaignEpisode>;
  readonly removeDomain: Maybe<RemoveDomain>;
  readonly removeMember: Maybe<RemoveMember>;
  readonly removeOrganizationAdvisor: Maybe<RemoveAdvisorForOrganization>;
  readonly removeTag: Maybe<RemoveTag>;
  readonly requestNewBrandForSpotifyOrder: Maybe<RequestNewBrandForSpotifyOrder>;
  readonly resetContact: Maybe<ResetContact>;
  readonly runLiftReport: Maybe<RunLiftReport>;
  readonly sendTrackingUrlsConfirmationReminder: Maybe<SendTrackingUrlsConfirmationReminder>;
  /**
   * Verifies that the email, Gets the token and sends an email
   * to verify the user is able to login to SPAA
   */
  readonly sendVerifyEmail: Maybe<SendVerifyEmail>;
  readonly setAdAnalyticsTutorialProgress: Maybe<SetAdAnalyticsTutorialProgress>;
  readonly setAdvisorOptions: Maybe<SetAdvisorOptions>;
  readonly setHasSeenAdAnalyticsWelcome: Maybe<SetHasSeenAdAnalyticsWelcome>;
  readonly signMedia: Maybe<SignMedia>;
  readonly splitAd: Maybe<SplitAd>;
  readonly startCheckoutSession: Maybe<StartCheckoutSession>;
  readonly startOveragesCheckout: Maybe<StartOveragesCheckout>;
  readonly startPortalStripe: Maybe<StartPortalSession>;
  readonly subscribe: Maybe<Subscribe>;
  readonly syncUserEmailWithSpotifyAccount: Maybe<SyncUserEmailWithSpotifyAccount>;
  readonly unarchiveFeed: Maybe<UnArchiveFeed>;
  readonly unhideUnmappedSpotifyOrders: Maybe<UnhideUnmappedSpotifyOrders>;
  readonly unknownAd: Maybe<UnknownAd>;
  readonly unsubscribe: Maybe<Unsubscribe>;
  readonly updateActionMap: Maybe<UpdateActionMap>;
  readonly updateAd: Maybe<UpdateAd>;
  readonly updateAdvisorSavedSearch: Maybe<UpdateAdvisorSavedSearch>;
  readonly updateAffinityScores: Maybe<UpdateAffinityScores>;
  readonly updateAllowBrandEditAccess: Maybe<UpdateAllowBrandEditAccess>;
  readonly updateAttributionConfig: Maybe<UpdateAttributionConfig>;
  readonly updateBillingInfo: Maybe<UpdateBillingInfo>;
  readonly updateCampaign: Maybe<UpdateCampaign>;
  readonly updateCampaignApprovals: Maybe<UpdateCampaignApprovals>;
  readonly updateCampaignBrandAgencies: Maybe<UpdateCampaignBrandAgencies>;
  readonly updateCampaignBroadcast: Maybe<UpdateCampaignBroadcast>;
  readonly updateCampaignDates: Maybe<UpdateCampaignDates>;
  readonly updateCampaignDynamic: Maybe<UpdateCampaignDynamic>;
  readonly updateCampaignDynamicProvider: Maybe<UpdateCampaignDynamicProvider>;
  readonly updateCampaignEpisode: Maybe<UpdateCampaignEpisode>;
  readonly updateCampaignIsNeustar: Maybe<UpdateCampaignIsNeustar>;
  readonly updateCampaignKind: Maybe<UpdateCampaignKind>;
  readonly updateCampaignNoise: Maybe<UpdateCampaignNoise>;
  readonly updateCampaignPodcast: Maybe<UpdateCampaignPodcast>;
  readonly updateCampaignState: Maybe<UpdateCampaignState>;
  readonly updateCampaignStreaming: Maybe<UpdateCampaignStreaming>;
  readonly updateCampaignStreamingProvider: Maybe<UpdateCampaignStreamingProvider>;
  readonly updateComment: Maybe<UpdateComment>;
  readonly updateCompany: Maybe<UpdateCompany>;
  readonly updateCompanyArchive: Maybe<UpdateCompanyArchive>;
  readonly updateCompanyIndustry: Maybe<UpdateCompanyIndustry>;
  readonly updateConversionUrl: Maybe<UpdateConversionUrl>;
  readonly updateDiscount: Maybe<UpdateDiscount>;
  readonly updateEpisodeAdspottingModelVersion: Maybe<UpdateEpisodeAdspottingModelVersion>;
  readonly updateEpisodeAsrStatus: Maybe<UpdateEpisodeAsrStatus>;
  readonly updateFeedContactEmail: Maybe<UpdateFeedContactEmail>;
  /** Force an update of all the attributes on the feeds. */
  readonly updateFeeds: Maybe<UpdateFeeds>;
  readonly updateFinancial: Maybe<UpdateFinancial>;
  readonly updateGenericTag: Maybe<UpdateGenericTag>;
  readonly updateInvoice: Maybe<UpdateInvoice>;
  readonly updateIsController: Maybe<UpdateIsController>;
  readonly updateLiftReport: Maybe<UpdateLiftReport>;
  readonly updateLinkingRequest: Maybe<UpdateLinkingRequest>;
  readonly updateMegaphoneBundle: Maybe<UpdateMegaphoneBundle>;
  readonly updateMember: Maybe<UpdateMember>;
  readonly updateOrganization: Maybe<UpdateOrganization>;
  readonly updatePixel: Maybe<UpdateKey>;
  readonly updatePixelEventRecord: Maybe<UpdatePixelEventRecord>;
  readonly updatePodlinkDomain: Maybe<UpdatePodlinkDomain>;
  /** We do this serverside to send special headers. */
  readonly updatePodlinkPodcast: Maybe<UpdatePodlinkPodcast>;
  readonly updateSavedExport: Maybe<UpdateSavedExport>;
  readonly upsertPlacements: Maybe<UpsertPlacements>;
};


export type MutationAcceptCompanyClaimArgs = {
  input: AcceptCompanyClaimInput;
};


export type MutationAddAccessArgs = {
  input: AddAccessInput;
};


export type MutationAddAdStudioAdAccountArgs = {
  input: AddAdStudioAdAccountInput;
};


export type MutationAddArt19SecretArgs = {
  input: AddArt19SecretInput;
};


export type MutationAddDomainArgs = {
  input: AddDomainInput;
};


export type MutationAddFeedArgs = {
  input: AddFeedInput;
};


export type MutationAddMemberArgs = {
  input: AddMemberInput;
};


export type MutationAddOrganizationAdvisorArgs = {
  input: AddAdvisorForOrganizationInput;
};


export type MutationAddPixelArgs = {
  input: CreateKeyInput;
};


export type MutationAddPixelAndClaimCompanyArgs = {
  input: CreateKeyAndCompanyClaimInput;
};


export type MutationAddTagArgs = {
  input: AddTagInput;
};


export type MutationArchiveFeedArgs = {
  input: ArchiveFeedInput;
};


export type MutationAssignSpotifyOrderBrandArgs = {
  input: InputMaybe<AssignSpotifyOrderBrandInput>;
};


export type MutationAttributionCampaignPreRunArgs = {
  input: AttributionCampaignPreRunInput;
};


export type MutationAttributionPreClearRangeArgs = {
  input: AttributionPreRangeInput;
};


export type MutationAttributionPreRunArgs = {
  input: AttributionPreRunInput;
};


export type MutationAttributionRunArgs = {
  input: AttributionRunInput;
};


export type MutationAuthedArgs = {
  input: InputMaybe<AuthedInput>;
};


export type MutationBackdateRangeArgs = {
  input: BackdateRangeInput;
};


export type MutationCheckCampaignPodcastPrefixArgs = {
  input: CheckCampaignPodcastPrefixInput;
};


export type MutationClaimAddPodcastArgs = {
  input: ClaimAddPodcastInput;
};


export type MutationClaimFeedArgs = {
  input: ClaimFeedInput;
};


export type MutationClearQueueArgs = {
  input: ClearQueueInput;
};


export type MutationCompletePlacementsArgs = {
  input: CompletePlacementsInput;
};


export type MutationContactPublisherArgs = {
  input: ContactPublisherInput;
};


export type MutationContactedContactArgs = {
  input: ContactedContactInput;
};


export type MutationCreateActionMapArgs = {
  input: CreateActionMapInput;
};


export type MutationCreateAdvisorSavedSearchArgs = {
  input: CreateAdvisorSavedSearchInput;
};


export type MutationCreateAssetArgs = {
  input: CreateAssetInput;
};


export type MutationCreateBillingInfoArgs = {
  input: CreateBillingInfoInput;
};


export type MutationCreateBrandInvitesArgs = {
  input: CreateBrandInvitesInput;
};


export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};


export type MutationCreateCampaignApprovalsArgs = {
  input: CreateCampaignApprovalsInput;
};


export type MutationCreateCampaignBroadcastArgs = {
  input: CreateCampaignBroadcastInput;
};


export type MutationCreateCampaignDynamicArgs = {
  input: CreateCampaignDynamicInput;
};


export type MutationCreateCampaignDynamicDeliveryArgs = {
  input: CreateCampaignDynamicDeliveryInput;
};


export type MutationCreateCampaignEpisodeArgs = {
  input: CreateCampaignEpisodeInput;
};


export type MutationCreateCampaignLinkArgs = {
  input: CreateCampaignLinkInput;
};


export type MutationCreateCampaignNoiseArgs = {
  input: CreateCampaignNoiseInput;
};


export type MutationCreateCampaignPodcastArgs = {
  input: CreateCampaignPodcastInput;
};


export type MutationCreateCampaignStreamingArgs = {
  input: CreateCampaignStreamingInput;
};


export type MutationCreateCampaignStreamingDeliveryArgs = {
  input: CreateCampaignStreamingDeliveryInput;
};


export type MutationCreateCampaignTargetArgs = {
  input: CreateCampaignTargetInput;
};


export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateCompanyClaimArgs = {
  input: CreateCompanyClaimInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateConversionUrlArgs = {
  input: CreateConversionUrlInput;
};


export type MutationCreateDiscountArgs = {
  input: CreateDiscountInput;
};


export type MutationCreateEpisodeRatingArgs = {
  input: CreateEpisodeRatingInput;
};


export type MutationCreateEpisodeRatingExceptionArgs = {
  input: CreateEpisodeRatingExceptionInput;
};


export type MutationCreateExportInstanceArgs = {
  input: CreateExportInstanceInput;
};


export type MutationCreateExportTokenArgs = {
  input: CreateExportTokenInput;
};


export type MutationCreateFutureEpisodeArgs = {
  input: CreateFutureEpisodeInput;
};


export type MutationCreateGenericTagArgs = {
  input: CreateGenericTagInput;
};


export type MutationCreateGenericTagRelationshipArgs = {
  input: CreateGenericTagRelationshipInput;
};


export type MutationCreateImageArgs = {
  input: CreateImageInput;
};


export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};


export type MutationCreateLiftReportArgs = {
  input: CreateLiftReportInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreateOrganizationWithPixelArgs = {
  input: CreateOrganizationWithPixelInput;
};


export type MutationCreatePaymentTierArgs = {
  input: CreatePaymentTierInput;
};


export type MutationCreatePixelEventRecordArgs = {
  input: CreatePixelEventRecordInput;
};


export type MutationCreatePodcastArgs = {
  input: CreatePodcastInput;
};


export type MutationCreatePodlinkDomainArgs = {
  input: CreatePodlinkDomainInput;
};


export type MutationCreateReviewerArgs = {
  input: CreateReviewerInput;
};


export type MutationCreateSavedExportArgs = {
  input: CreateSavedExportInput;
};


export type MutationCreateServiceAccountApiKeyArgs = {
  input: CreateServiceAccountApiKeyInput;
};


export type MutationCreateSpotifyCampaignImportRequestsArgs = {
  input: CreateSpotifyCampaignImportRequestsInput;
};


export type MutationCreateTermsAndConditionsArgs = {
  input: CreateTermsAndConditionsInput;
};


export type MutationCreateUserArgs = {
  input: InputMaybe<CreateUserInput>;
};


export type MutationCreateUserAccessArgs = {
  input: CreateUserAccessInput;
};


export type MutationCreateUserSkippedTermsArgs = {
  input: CreateUserSkippedTermsInput;
};


export type MutationDeleteActionMapArgs = {
  input: DeleteActionMapInput;
};


export type MutationDeleteAdvisorSavedSearchArgs = {
  input: DeleteAdvisorSavedSearchInput;
};


export type MutationDeleteCampaignArgs = {
  input: DeleteCampaignInput;
};


export type MutationDeleteCampaignBroadcastArgs = {
  input: DeleteCampaignBroadcastInput;
};


export type MutationDeleteCampaignDynamicArgs = {
  input: DeleteCampaignDynamicInput;
};


export type MutationDeleteCampaignDynamicDeliveryArgs = {
  input: DeleteCampaignDynamicDeliveryInput;
};


export type MutationDeleteCampaignNoiseArgs = {
  input: DeleteCampaignNoiseInput;
};


export type MutationDeleteCampaignPodcastArgs = {
  input: DeleteCampaignPodcastInput;
};


export type MutationDeleteCampaignStreamingArgs = {
  input: DeleteCampaignStreamingInput;
};


export type MutationDeleteCampaignStreamingDeliveryArgs = {
  input: DeleteCampaignStreamingDeliveryInput;
};


export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};


export type MutationDeleteCompanyArgs = {
  input: DeleteCompanyInput;
};


export type MutationDeleteCompanyClaimArgs = {
  input: DeleteCompanyClaimInput;
};


export type MutationDeleteConversionUrlArgs = {
  input: DeleteConversionUrlInput;
};


export type MutationDeleteDiscountArgs = {
  input: DeleteDiscountInput;
};


export type MutationDeleteFutureEpisodeArgs = {
  input: DeleteFutureEpisodeInput;
};


export type MutationDeleteGenericTagArgs = {
  input: DeleteGenericTagInput;
};


export type MutationDeleteGenericTagRelationshipArgs = {
  input: DeleteGenericTagRelationshipInput;
};


export type MutationDeleteLiftReportArgs = {
  input: DeleteLiftReportInput;
};


export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};


export type MutationDeletePixelEventRecordArgs = {
  input: DeletePixelEventRecordInput;
};


export type MutationDeletePodcastArgs = {
  input: DeletePodcastInput;
};


export type MutationDeletePodcastsArgs = {
  input: DeletePodcastsInput;
};


export type MutationDeletePodlinkDomainArgs = {
  input: DeletePodlinkDomainInput;
};


export type MutationDeleteProductArgs = {
  input: DeleteProductInput;
};


export type MutationDeleteReviewerArgs = {
  input: DeleteReviewerInput;
};


export type MutationDeleteSavedExportArgs = {
  input: DeleteSavedExportInput;
};


export type MutationDeleteTokenArgs = {
  input: InputMaybe<DeleteTokenInput>;
};


export type MutationDeleteUserAccessArgs = {
  input: DeleteUserAccessInput;
};


export type MutationDesignPodlinkDomainArgs = {
  input: DesignPodlinkDomainInput;
};


export type MutationDownloadAdArgs = {
  input: DownloadAdInput;
};


export type MutationDownloadMaidsArgs = {
  input: DownloadMaidsInput;
};


export type MutationFillCampaignDynamicsArgs = {
  input: FillCampaignDynamicsInput;
};


export type MutationFixRedisForFeedIdArgs = {
  input: FixRedisForFeedIdInput;
};


export type MutationHideLineItemArgs = {
  input: HideLineItemInput;
};


export type MutationHideUnmappedSpotifyOrdersArgs = {
  input: InputMaybe<HideUnmappedSpotifyOrdersInput>;
};


export type MutationIgnoredContactArgs = {
  input: IgnoredContactInput;
};


export type MutationImportPodcastsArgs = {
  input: ImportPodcastsInput;
};


export type MutationIsProcessingPodcastArgs = {
  input: IsProcessingPodcastInput;
};


export type MutationIsProcessingPodcastsArgs = {
  input: IsProcessingPodcastsInput;
};


export type MutationKeepaliveArgs = {
  input: KeepaliveInput;
};


export type MutationLockEndArgs = {
  input: LockInput;
};


export type MutationLockStartArgs = {
  input: InputMaybe<LockInput>;
};


export type MutationMarkNewBrandRequestCompleteArgs = {
  input: InputMaybe<MarkNewBrandRequestCompleteInput>;
};


export type MutationMeasureFeedArgs = {
  input: MeasureFeedInput;
};


export type MutationMergeCompanyArgs = {
  input: MergeCompanyInput;
};


export type MutationMergeProductArgs = {
  input: MergeProductInput;
};


export type MutationNoAdsFeedArgs = {
  input: NoAdsFeedInput;
};


export type MutationNoPlacementsArgs = {
  input: NoPlacementsInput;
};


export type MutationProcessCampaignEpisodesArgs = {
  input: ProcessCampaignEpisodesInput;
};


export type MutationProcessFeedArgs = {
  input: ProcessFeedInput;
};


export type MutationPublishMessageArgs = {
  input: PublishMessageInput;
};


export type MutationPublishSpotifyBrandLiftReportArgs = {
  input: PublishSpotifyBrandLiftReportInput;
};


export type MutationPublisherApproveCampaignArgs = {
  input: InputMaybe<PublisherApproveCampaignInput>;
};


export type MutationPublisherRejectCampaignArgs = {
  input: InputMaybe<PublisherRejectCampaignInput>;
};


export type MutationRejectAdArgs = {
  input: RejectAdInput;
};


export type MutationRejectCompanyClaimArgs = {
  input: RejectCompanyClaimInput;
};


export type MutationRemoveAccessArgs = {
  input: RemoveAccessInput;
};


export type MutationRemoveCampaignEpisodeArgs = {
  input: RemoveCampaignEpisodeInput;
};


export type MutationRemoveDomainArgs = {
  input: RemoveDomainInput;
};


export type MutationRemoveMemberArgs = {
  input: RemoveMemberInput;
};


export type MutationRemoveOrganizationAdvisorArgs = {
  input: RemoveAdvisorForOrganizationInput;
};


export type MutationRemoveTagArgs = {
  input: RemoveTagInput;
};


export type MutationRequestNewBrandForSpotifyOrderArgs = {
  input: InputMaybe<RequestNewBrandForSpotifyOrderInput>;
};


export type MutationResetContactArgs = {
  input: ResetContactInput;
};


export type MutationRunLiftReportArgs = {
  input: RunLiftReportInput;
};


export type MutationSendTrackingUrlsConfirmationReminderArgs = {
  input: InputMaybe<SendTrackingUrlsConfirmationReminderInput>;
};


export type MutationSendVerifyEmailArgs = {
  input: InputMaybe<SendVerifyEmailInput>;
};


export type MutationSetAdAnalyticsTutorialProgressArgs = {
  input: SetAdAnalyticsTutorialProgressInput;
};


export type MutationSetAdvisorOptionsArgs = {
  input: SetAdvisorOptionsInput;
};


export type MutationSetHasSeenAdAnalyticsWelcomeArgs = {
  input: SetHasSeenAdAnalyticsWelcomeInput;
};


export type MutationSignMediaArgs = {
  input: SignMediaInput;
};


export type MutationSplitAdArgs = {
  input: SplitAdInput;
};


export type MutationStartCheckoutSessionArgs = {
  input: StartCheckoutSessionInput;
};


export type MutationStartOveragesCheckoutArgs = {
  input: StartOveragesCheckoutInput;
};


export type MutationStartPortalStripeArgs = {
  input: StartPortalSessionInput;
};


export type MutationSubscribeArgs = {
  input: SubscribeInput;
};


export type MutationUnarchiveFeedArgs = {
  input: UnArchiveFeedInput;
};


export type MutationUnhideUnmappedSpotifyOrdersArgs = {
  input: InputMaybe<HideUnmappedSpotifyOrdersInput>;
};


export type MutationUnknownAdArgs = {
  input: UnknownAdInput;
};


export type MutationUnsubscribeArgs = {
  input: UnsubscribeInput;
};


export type MutationUpdateActionMapArgs = {
  input: UpdateActionMapInput;
};


export type MutationUpdateAdArgs = {
  input: UpdateAdInput;
};


export type MutationUpdateAdvisorSavedSearchArgs = {
  input: UpdateAdvisorSavedSearchInput;
};


export type MutationUpdateAffinityScoresArgs = {
  input: UpdateAffinityScoresInput;
};


export type MutationUpdateAllowBrandEditAccessArgs = {
  input: UpdateAllowBrandEditAccessInput;
};


export type MutationUpdateAttributionConfigArgs = {
  input: UpdateAttributionConfigInput;
};


export type MutationUpdateBillingInfoArgs = {
  input: UpdateBillingInfoInput;
};


export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput;
};


export type MutationUpdateCampaignApprovalsArgs = {
  input: UpdateCampaignApprovalsInput;
};


export type MutationUpdateCampaignBrandAgenciesArgs = {
  input: UpdateCampaignBrandAgenciesInput;
};


export type MutationUpdateCampaignBroadcastArgs = {
  input: UpdateCampaignBroadcastInput;
};


export type MutationUpdateCampaignDatesArgs = {
  input: UpdateCampaignDatesInput;
};


export type MutationUpdateCampaignDynamicArgs = {
  input: UpdateCampaignDynamicInput;
};


export type MutationUpdateCampaignDynamicProviderArgs = {
  input: UpdateCampaignDynamicProviderInput;
};


export type MutationUpdateCampaignEpisodeArgs = {
  input: UpdateCampaignEpisodeInput;
};


export type MutationUpdateCampaignIsNeustarArgs = {
  input: UpdateCampaignIsNeustarInput;
};


export type MutationUpdateCampaignKindArgs = {
  input: UpdateCampaignKindInput;
};


export type MutationUpdateCampaignNoiseArgs = {
  input: UpdateCampaignNoiseInput;
};


export type MutationUpdateCampaignPodcastArgs = {
  input: UpdateCampaignPodcastInput;
};


export type MutationUpdateCampaignStateArgs = {
  input: UpdateCampaignStateInput;
};


export type MutationUpdateCampaignStreamingArgs = {
  input: UpdateCampaignStreamingInput;
};


export type MutationUpdateCampaignStreamingProviderArgs = {
  input: UpdateCampaignStreamingProviderInput;
};


export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


export type MutationUpdateCompanyArchiveArgs = {
  input: UpdateCompanyArchiveInput;
};


export type MutationUpdateCompanyIndustryArgs = {
  input: UpdateCompanyIndustryInput;
};


export type MutationUpdateConversionUrlArgs = {
  input: UpdateConversionUrlInput;
};


export type MutationUpdateDiscountArgs = {
  input: UpdateDiscountInput;
};


export type MutationUpdateEpisodeAdspottingModelVersionArgs = {
  input: UpdateEpisodeAdspottingModelVersionInput;
};


export type MutationUpdateEpisodeAsrStatusArgs = {
  input: UpdateEpisodeAsrStatusInput;
};


export type MutationUpdateFeedContactEmailArgs = {
  input: UpdateFeedContactEmailInput;
};


export type MutationUpdateFeedsArgs = {
  input: UpdateFeedsInput;
};


export type MutationUpdateFinancialArgs = {
  input: UpdateFinancialInput;
};


export type MutationUpdateGenericTagArgs = {
  input: UpdateGenericTagInput;
};


export type MutationUpdateInvoiceArgs = {
  input: UpdateInvoiceInput;
};


export type MutationUpdateIsControllerArgs = {
  input: UpdateIsControllerInput;
};


export type MutationUpdateLiftReportArgs = {
  input: UpdateLiftReportInput;
};


export type MutationUpdateLinkingRequestArgs = {
  input: UpdateLinkingRequestInput;
};


export type MutationUpdateMegaphoneBundleArgs = {
  input: UpdateMegaphoneBundleInput;
};


export type MutationUpdateMemberArgs = {
  input: UpdateMemberInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdatePixelArgs = {
  input: UpdateKeyInput;
};


export type MutationUpdatePixelEventRecordArgs = {
  input: UpdatePixelEventRecordInput;
};


export type MutationUpdatePodlinkDomainArgs = {
  input: UpdatePodlinkDomainInput;
};


export type MutationUpdatePodlinkPodcastArgs = {
  input: UpdatePodlinkPodcastInput;
};


export type MutationUpdateSavedExportArgs = {
  input: UpdateSavedExportInput;
};


export type MutationUpsertPlacementsArgs = {
  input: UpsertPlacementsInput;
};

export type NielsenMetric = {
  readonly __typename: 'NielsenMetric';
  readonly index: Maybe<Scalars['Float']>;
  readonly indexPercentOfPop: Maybe<Scalars['Float']>;
  readonly podcastPercentOfPop: Maybe<Scalars['Float']>;
  readonly reach: Maybe<Scalars['Float']>;
  readonly totalHouseholds: Maybe<Scalars['Float']>;
  readonly variable: Maybe<Scalars['String']>;
};

export type NielsenSegment = {
  readonly __typename: 'NielsenSegment';
  readonly category: Maybe<Scalars['String']>;
  readonly classification: Maybe<Scalars['String']>;
  readonly feedId: Maybe<Scalars['ID']>;
  readonly metrics: Maybe<ReadonlyArray<Maybe<NielsenMetric>>>;
  readonly percentNielsenMatching: Maybe<Scalars['Float']>;
  readonly topic: Maybe<Scalars['String']>;
  readonly uniqueHhCount: Maybe<Scalars['Int']>;
  readonly uniqueNielsenMatching: Maybe<Scalars['RoundedInt']>;
};

/** start processing feeds. */
export type NoAdsFeed = {
  readonly __typename: 'NoAdsFeed';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type NoAdsFeedInput = {
  readonly feedId: Scalars['ID'];
};

export type NoPlacements = {
  readonly __typename: 'NoPlacements';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type NoPlacementsInput = {
  readonly assignmentId: InputMaybe<Scalars['String']>;
  readonly episodeId: Scalars['ID'];
  readonly hitId: InputMaybe<Scalars['String']>;
  readonly reviewDuration: InputMaybe<Scalars['Int']>;
  readonly reviewerId: InputMaybe<Scalars['ID']>;
  readonly workerId: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum OrganizationCurrency {
  /** Australian Dollar */
  Aud = 'AUD',
  /** Brazilian Real */
  Brl = 'BRL',
  /** Canadian Dollar */
  Cad = 'CAD',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Euro */
  Eur = 'EUR',
  /** Pound Sterling */
  Gbp = 'GBP',
  /** Ghanian Cedi */
  Ghs = 'GHS',
  /** Kenyan Shilling */
  Kes = 'KES',
  /** Mexican Peso */
  Mxn = 'MXN',
  /** Naira */
  Ngn = 'NGN',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** New Zealand Dollar */
  Nzd = 'NZD',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore Dollar */
  Sgd = 'SGD',
  /** Tanzanian Shilling */
  Tzs = 'TZS',
  /** Uganda Shilling */
  Ugx = 'UGX',
  /** US Dollar */
  Usd = 'USD'
}

export type OrganizationDomainObject = {
  readonly __typename: 'OrganizationDomainObject';
  readonly createdAt: Scalars['DateTime'];
  readonly domain: Scalars['String'];
  readonly id: Scalars['String'];
};

/** An enumeration. */
export enum OrganizationDownloadAlgorithm {
  /** Megaphone */
  Megaphone = 'MEGAPHONE',
  /** Podsights */
  Podsights = 'PODSIGHTS'
}

/** An enumeration. */
export enum OrganizationKind {
  /** Brand */
  Brand = 'BRAND',
  /** Buy Side Agency */
  BuySide = 'BUY_SIDE',
  /** Podcast */
  Podcast = 'PODCAST',
  /** Research */
  Research = 'RESEARCH',
  /** Sell Side Agency */
  SellSide = 'SELL_SIDE'
}

/** An enumeration. */
export enum OrganizationKnowledgeLevel {
  /** Expert */
  Expert = 'EXPERT',
  /** Novice */
  Novice = 'NOVICE',
  /** Proficient */
  Proficient = 'PROFICIENT'
}

/** An enumeration. */
export enum OrganizationLocation {
  /** Afghanistan */
  Afg = 'AFG',
  /** Angola */
  Ago = 'AGO',
  /** Albania */
  Alb = 'ALB',
  /** Andorra */
  And = 'AND',
  /** United Arab Emirates */
  Are = 'ARE',
  /** Argentina */
  Arg = 'ARG',
  /** Armenia */
  Arm = 'ARM',
  /** Antigua & Barbuda */
  Atg = 'ATG',
  /** Australia */
  Aus = 'AUS',
  /** Austria */
  Aut = 'AUT',
  /** Azerbaijan */
  Aze = 'AZE',
  /** Burundi */
  Bdi = 'BDI',
  /** Belgium */
  Bel = 'BEL',
  /** Benin */
  Ben = 'BEN',
  /** Burkina Faso */
  Bfa = 'BFA',
  /** Bangladesh */
  Bgd = 'BGD',
  /** Bulgaria */
  Bgr = 'BGR',
  /** Bahrain */
  Bhr = 'BHR',
  /** Bahamas */
  Bhs = 'BHS',
  /** Bosnia & Herzegovina */
  Bih = 'BIH',
  /** Belarus */
  Blr = 'BLR',
  /** Belize */
  Blz = 'BLZ',
  /** Bolivia */
  Bol = 'BOL',
  /** Brazil */
  Bra = 'BRA',
  /** Barbados */
  Brb = 'BRB',
  /** Brunei */
  Brn = 'BRN',
  /** Bhutan */
  Btn = 'BTN',
  /** Botswana */
  Bwa = 'BWA',
  /** Central African Republic */
  Caf = 'CAF',
  /** Canada */
  Can = 'CAN',
  /** Switzerland */
  Che = 'CHE',
  /** Chile */
  Chl = 'CHL',
  /** China */
  Chn = 'CHN',
  /** Côte d’Ivoire */
  Civ = 'CIV',
  /** Cameroon */
  Cmr = 'CMR',
  /** DR Congo */
  Cod = 'COD',
  /** Congo */
  Cog = 'COG',
  /** Colombia */
  Col = 'COL',
  /** Comoros */
  Com = 'COM',
  /** Cabo Verde */
  Cpv = 'CPV',
  /** Costa Rica */
  Cri = 'CRI',
  /** Cuba */
  Cub = 'CUB',
  /** Cyprus */
  Cyp = 'CYP',
  /** Czech Republic */
  Cze = 'CZE',
  /** Germany */
  Deu = 'DEU',
  /** Djibouti */
  Dji = 'DJI',
  /** Dominica */
  Dma = 'DMA',
  /** Denmark */
  Dnk = 'DNK',
  /** Dominican Republic */
  Dom = 'DOM',
  /** Algeria */
  Dza = 'DZA',
  /** Ecuador */
  Ecu = 'ECU',
  /** Egypt */
  Egy = 'EGY',
  /** Eritrea */
  Eri = 'ERI',
  /** Spain */
  Esp = 'ESP',
  /** Estonia */
  Est = 'EST',
  /** Ethiopia */
  Eth = 'ETH',
  /** Finland */
  Fin = 'FIN',
  /** Fiji */
  Fji = 'FJI',
  /** France */
  Fra = 'FRA',
  /** Micronesia */
  Fsm = 'FSM',
  /** Gabon */
  Gab = 'GAB',
  /** United Kingdom */
  Gbr = 'GBR',
  /** Georgia */
  Geo = 'GEO',
  /** Ghana */
  Gha = 'GHA',
  /** Guinea */
  Gin = 'GIN',
  /** Gambia */
  Gmb = 'GMB',
  /** Guinea-Bissau */
  Gnb = 'GNB',
  /** Equatorial Guinea */
  Gnq = 'GNQ',
  /** Greece */
  Grc = 'GRC',
  /** Grenada */
  Grd = 'GRD',
  /** Guatemala */
  Gtm = 'GTM',
  /** Guyana */
  Guy = 'GUY',
  /** Honduras */
  Hnd = 'HND',
  /** Croatia */
  Hrv = 'HRV',
  /** Haiti */
  Hti = 'HTI',
  /** Hungary */
  Hun = 'HUN',
  /** Indonesia */
  Idn = 'IDN',
  /** India */
  Ind = 'IND',
  /** Ireland */
  Irl = 'IRL',
  /** Iran */
  Irn = 'IRN',
  /** Iraq */
  Irq = 'IRQ',
  /** Iceland */
  Isl = 'ISL',
  /** Israel */
  Isr = 'ISR',
  /** Italy */
  Ita = 'ITA',
  /** Jamaica */
  Jam = 'JAM',
  /** Jordan */
  Jor = 'JOR',
  /** Japan */
  Jpn = 'JPN',
  /** Kazakhstan */
  Kaz = 'KAZ',
  /** Kenya */
  Ken = 'KEN',
  /** Kyrgyzstan */
  Kgz = 'KGZ',
  /** Cambodia */
  Khm = 'KHM',
  /** Kiribati */
  Kir = 'KIR',
  /** Saint Kitts & Nevis */
  Kna = 'KNA',
  /** South Korea */
  Kor = 'KOR',
  /** Kuwait */
  Kwt = 'KWT',
  /** Laos */
  Lao = 'LAO',
  /** Lebanon */
  Lbn = 'LBN',
  /** Liberia */
  Lbr = 'LBR',
  /** Libya */
  Lby = 'LBY',
  /** Saint Lucia */
  Lca = 'LCA',
  /** Liechtenstein */
  Lie = 'LIE',
  /** Sri Lanka */
  Lka = 'LKA',
  /** Lesotho */
  Lso = 'LSO',
  /** Lithuania */
  Ltu = 'LTU',
  /** Luxembourg */
  Lux = 'LUX',
  /** Latvia */
  Lva = 'LVA',
  /** Morocco */
  Mar = 'MAR',
  /** Monaco */
  Mco = 'MCO',
  /** Moldova */
  Mda = 'MDA',
  /** Madagascar */
  Mdg = 'MDG',
  /** Maldives */
  Mdv = 'MDV',
  /** Mexico */
  Mex = 'MEX',
  /** Marshall Islands */
  Mhl = 'MHL',
  /** North Macedonia */
  Mkd = 'MKD',
  /** Mali */
  Mli = 'MLI',
  /** Malta */
  Mlt = 'MLT',
  /** Myanmar */
  Mmr = 'MMR',
  /** Montenegro */
  Mne = 'MNE',
  /** Mongolia */
  Mng = 'MNG',
  /** Mozambique */
  Moz = 'MOZ',
  /** Mauritania */
  Mrt = 'MRT',
  /** Mauritius */
  Mus = 'MUS',
  /** Malawi */
  Mwi = 'MWI',
  /** Malaysia */
  Mys = 'MYS',
  /** Namibia */
  Nam = 'NAM',
  /** Niger */
  Ner = 'NER',
  /** Nigeria */
  Nga = 'NGA',
  /** Nicaragua */
  Nic = 'NIC',
  /** Netherlands */
  Nld = 'NLD',
  /** Norway */
  Nor = 'NOR',
  /** Nepal */
  Npl = 'NPL',
  /** Nauru */
  Nru = 'NRU',
  /** New Zealand */
  Nzl = 'NZL',
  /** Oman */
  Omn = 'OMN',
  /** Pakistan */
  Pak = 'PAK',
  /** Panama */
  Pan = 'PAN',
  /** Peru */
  Per = 'PER',
  /** Philippines */
  Phl = 'PHL',
  /** Palau */
  Plw = 'PLW',
  /** Papua New Guinea */
  Png = 'PNG',
  /** Poland */
  Pol = 'POL',
  /** North Korea */
  Prk = 'PRK',
  /** Portugal */
  Prt = 'PRT',
  /** Paraguay */
  Pry = 'PRY',
  /** State of Palestine */
  Pse = 'PSE',
  /** Qatar */
  Qat = 'QAT',
  /** Romania */
  Rou = 'ROU',
  /** Russia */
  Rus = 'RUS',
  /** Rwanda */
  Rwa = 'RWA',
  /** Saudi Arabia */
  Sau = 'SAU',
  /** Sudan */
  Sdn = 'SDN',
  /** Senegal */
  Sen = 'SEN',
  /** Singapore */
  Sgp = 'SGP',
  /** Solomon Islands */
  Slb = 'SLB',
  /** Sierra Leone */
  Sle = 'SLE',
  /** El Salvador */
  Slv = 'SLV',
  /** San Marino */
  Smr = 'SMR',
  /** Somalia */
  Som = 'SOM',
  /** Serbia */
  Srb = 'SRB',
  /** South Sudan */
  Ssd = 'SSD',
  /** Sao Tome & Principe */
  Stp = 'STP',
  /** Suriname */
  Sur = 'SUR',
  /** Slovakia */
  Svk = 'SVK',
  /** Slovenia */
  Svn = 'SVN',
  /** Sweden */
  Swe = 'SWE',
  /** Eswatini */
  Swz = 'SWZ',
  /** Seychelles */
  Syc = 'SYC',
  /** Syria */
  Syr = 'SYR',
  /** Chad */
  Tcd = 'TCD',
  /** Togo */
  Tgo = 'TGO',
  /** Thailand */
  Tha = 'THA',
  /** Tajikistan */
  Tjk = 'TJK',
  /** Turkmenistan */
  Tkm = 'TKM',
  /** Timor-Leste */
  Tls = 'TLS',
  /** Tonga */
  Ton = 'TON',
  /** Trinidad & Tobago */
  Tto = 'TTO',
  /** Tunisia */
  Tun = 'TUN',
  /** Turkey */
  Tur = 'TUR',
  /** Tuvalu */
  Tuv = 'TUV',
  /** Tanzania */
  Tza = 'TZA',
  /** Uganda */
  Uga = 'UGA',
  /** Ukraine */
  Ukr = 'UKR',
  /** Uruguay */
  Ury = 'URY',
  /** United States */
  Usa = 'USA',
  /** Uzbekistan */
  Uzb = 'UZB',
  /** Holy See */
  Vat = 'VAT',
  /** St. Vincent & Grenadines */
  Vct = 'VCT',
  /** Venezuela */
  Ven = 'VEN',
  /** Vietnam */
  Vnm = 'VNM',
  /** Vanuatu */
  Vut = 'VUT',
  /** Samoa */
  Wsm = 'WSM',
  /** Yemen */
  Yem = 'YEM',
  /** South Africa */
  Zaf = 'ZAF',
  /** Zambia */
  Zmb = 'ZMB',
  /** Zimbabwe */
  Zwe = 'ZWE'
}

/** An enumeration. */
export enum OrganizationMode {
  /** BigQuery */
  Bigquery = 'BIGQUERY',
  /** Default */
  Default = 'DEFAULT',
  /** SQL */
  Sql = 'SQL'
}

/** An enumeration. */
export enum OrganizationNotificationKind {
  /** Upsell */
  Upsell = 'UPSELL'
}

export type OrganizationNotificationObject = {
  readonly __typename: 'OrganizationNotificationObject';
  readonly id: Scalars['String'];
  readonly kind: OrganizationNotificationKind;
  readonly organization: Maybe<LimitedOrganizationObject>;
  readonly recipients: Scalars['String'];
  readonly sentAt: Scalars['DateTime'];
};

export type OrganizationObject = {
  readonly __typename: 'OrganizationObject';
  readonly adStudioBusinessId: Maybe<Scalars['String']>;
  readonly advisor: Maybe<Advisor>;
  readonly advisorOptInDate: Maybe<Scalars['DateTime']>;
  readonly advisorQuiz: Maybe<AdvisorQuiz>;
  readonly advisorSavedSearches: ReadonlyArray<AdvisorSavedSearchObject>;
  readonly art19Secret: Maybe<Scalars['String']>;
  readonly assets: ReadonlyArray<AssetObject>;
  readonly attribution: AttributionObject;
  readonly attributionConfig: AttributionConfigObject;
  readonly attributionCpm: Maybe<Scalars['Float']>;
  readonly billingCity: Maybe<Scalars['String']>;
  readonly billingCountry: Maybe<Scalars['String']>;
  readonly billingEmail: Maybe<Scalars['String']>;
  readonly billingInfo: BillingInfoObject;
  readonly billingLine1: Maybe<Scalars['String']>;
  readonly billingLine2: Maybe<Scalars['String']>;
  readonly billingName: Maybe<Scalars['String']>;
  readonly billingPostalCode: Maybe<Scalars['String']>;
  readonly billingRecords: ReadonlyArray<BillingRecord>;
  readonly billingState: Maybe<Scalars['String']>;
  readonly brand: Maybe<BrandObject>;
  readonly campaign: CampaignObject;
  readonly campaignEpisodes: ReadonlyArray<CampaignEpisodeObject>;
  readonly campaignSet: ReadonlyArray<CampaignObject>;
  readonly campaigns: CampaignFilterObject;
  readonly campaignsCount: Scalars['Int'];
  readonly campaignsImpressionsMax: Maybe<Scalars['Int']>;
  readonly capabilities: ReadonlyArray<CapabilityObject>;
  readonly claims: ReadonlyArray<LimitedCompanyClaimObject>;
  readonly comments: ReadonlyArray<CommentObject>;
  readonly companies: ReadonlyArray<CompanyObject>;
  readonly company: CompanyObject;
  readonly contactEmail: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly currency: OrganizationCurrency;
  readonly domains: ReadonlyArray<OrganizationDomainObject>;
  readonly downloadAlgorithm: OrganizationDownloadAlgorithm;
  readonly dynamicApprovalsReceived: ReadonlyArray<DynamicApprovalObject>;
  readonly dynamicApprovalsSent: ReadonlyArray<DynamicApprovalObject>;
  readonly dynamicProvider: Maybe<Scalars['String']>;
  readonly dynamicProviderOptions: Maybe<ReadonlyArray<Maybe<ProviderNameObject>>>;
  readonly exportInstances: ReadonlyArray<ExportInstanceObject>;
  readonly exports: ExportObject;
  readonly feedSearch: FeedSearch;
  readonly financialRecords: FinancialObject;
  readonly futureEpisodes: ReadonlyArray<FutureEpisodeObject>;
  readonly genericTags: ReadonlyArray<GenericTagObject>;
  readonly hasActivePixel: Scalars['Boolean'];
  readonly hasMembers: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly invoiceDaysUntilDue: Scalars['Int'];
  readonly invoices: ReadonlyArray<InvoiceObject>;
  readonly isActive: Scalars['Boolean'];
  readonly isAdmin: Scalars['Boolean'];
  readonly isAdvisor: Scalars['Boolean'];
  readonly isController: Scalars['Boolean'];
  readonly isExperianApproved: Scalars['Boolean'];
  readonly isLockedForTerms: Scalars['Boolean'];
  readonly isLogTransfer: Scalars['Boolean'];
  readonly isManager: Scalars['Boolean'];
  readonly isNielsenApproved: Scalars['Boolean'];
  readonly isSegmentsEnabled: Scalars['Boolean'];
  readonly key: KeyObject;
  readonly keys: ReadonlyArray<KeyObject>;
  readonly kind: OrganizationKind;
  readonly knowledgeLevel: OrganizationKnowledgeLevel;
  readonly liftReport: LiftReportObject;
  readonly liftReportRequests: ReadonlyArray<LiftReportObject>;
  readonly liftReports: ReadonlyArray<LiftReportObject>;
  readonly limitedCompany: LimitedCompanyObject;
  readonly linkingrequestSet: ReadonlyArray<LinkingRequestObject>;
  readonly location: OrganizationLocation;
  readonly managers: ReadonlyArray<LimitedOrganizationObject>;
  readonly managing: ReadonlyArray<LimitedOrganizationObject>;
  readonly meRole: Maybe<Scalars['String']>;
  readonly member: UserObject;
  readonly memberAccess: ReadonlyArray<UserAccessObject>;
  readonly members: ReadonlyArray<UserObject>;
  readonly memberships: ReadonlyArray<MembershipObject>;
  readonly mode: OrganizationMode;
  readonly multiBillingRecords: MultiBillingRecords;
  readonly name: Scalars['String'];
  readonly note: Maybe<Scalars['String']>;
  readonly notifications: ReadonlyArray<OrganizationNotificationObject>;
  readonly overlap: Maybe<Overlap>;
  readonly overview: Maybe<OverviewObject>;
  readonly payerOrganizations: ReadonlyArray<PayerOrganizationObject>;
  readonly pays: ReadonlyArray<CampaignObject>;
  readonly permissions: ReadonlyArray<PermissionObject>;
  readonly podcast: PodcastObject;
  readonly podcastApprovalsReceived: ReadonlyArray<PodcastApprovalObject>;
  readonly podcastApprovalsSent: ReadonlyArray<PodcastApprovalObject>;
  readonly podcasts: ReadonlyArray<PodcastObject>;
  readonly podcastsCount: Scalars['Int'];
  readonly podlinkDomain: PodlinkDomainObject;
  readonly podlinkDomains: ReadonlyArray<PodlinkDomainObject>;
  readonly productionPixel: Maybe<KeyObject>;
  readonly publisherSearch: PublisherSearch;
  readonly renewalDate: Maybe<Scalars['Date']>;
  readonly research: Maybe<ResearchObject>;
  readonly reversePermissions: ReadonlyArray<PermissionObject>;
  readonly savedExports: ReadonlyArray<SavedExportObject>;
  readonly showAdStudioTab: Scalars['Boolean'];
  readonly showTerms: Scalars['Boolean'];
  readonly showTermsReminder: Scalars['Boolean'];
  readonly slug: Scalars['String'];
  readonly spotifyBrandLiftReport: SpotifyBrandLiftReportObject;
  readonly spotifyBrandLiftReports: ReadonlyArray<SpotifyBrandLiftReportObject>;
  readonly spotifyOrder: SpotifyOrderObject;
  readonly spotifyOrders: ReadonlyArray<SpotifyOrderObject>;
  readonly streamingApprovalsReceived: ReadonlyArray<StreamingApprovalObject>;
  readonly streamingApprovalsSent: ReadonlyArray<StreamingApprovalObject>;
  readonly streamingProvider: Maybe<Scalars['String']>;
  readonly streamingProviderOptions: Maybe<ReadonlyArray<Maybe<ProviderNameObject>>>;
  readonly tier: OrganizationTier;
  readonly unmappedSpotifyOrders: ReadonlyArray<SpotifyOrderObject>;
  readonly updatedAt: Scalars['DateTime'];
};


export type OrganizationObjectBillingRecordsArgs = {
  after: Scalars['String'];
  before: InputMaybe<Scalars['String']>;
  span: InputMaybe<Scalars['String']>;
};


export type OrganizationObjectCampaignArgs = {
  id: InputMaybe<Scalars['ID']>;
  slug: InputMaybe<Scalars['String']>;
};


export type OrganizationObjectCampaignEpisodesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  isFuture: InputMaybe<Scalars['Boolean']>;
  isMarketing: InputMaybe<Scalars['Boolean']>;
};


export type OrganizationObjectCampaignsArgs = {
  query: InputMaybe<CampaignFilterQuery>;
};


export type OrganizationObjectCampaignsCountArgs = {
  isMarketing: InputMaybe<Scalars['Boolean']>;
};


export type OrganizationObjectCompanyArgs = {
  id: Scalars['ID'];
};


export type OrganizationObjectFeedSearchArgs = {
  limit: Scalars['Int'];
  offset: InputMaybe<Scalars['Int']>;
  publisherId: InputMaybe<Scalars['ID']>;
  query: Scalars['String'];
};


export type OrganizationObjectFutureEpisodesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
};


export type OrganizationObjectKeyArgs = {
  key: Scalars['ID'];
};


export type OrganizationObjectLiftReportArgs = {
  id: Scalars['ID'];
};


export type OrganizationObjectLimitedCompanyArgs = {
  id: Scalars['ID'];
};


export type OrganizationObjectMemberArgs = {
  membershipId: Scalars['ID'];
};


export type OrganizationObjectMemberAccessArgs = {
  membershipId: Scalars['ID'];
};


export type OrganizationObjectMultiBillingRecordsArgs = {
  after: Scalars['String'];
  before: Scalars['String'];
};


export type OrganizationObjectOverlapArgs = {
  after: InputMaybe<Scalars['Date']>;
  before: InputMaybe<Scalars['Date']>;
  key: InputMaybe<Scalars['ID']>;
};


export type OrganizationObjectPayerOrganizationsArgs = {
  companyId: InputMaybe<Scalars['String']>;
};


export type OrganizationObjectPodcastArgs = {
  campaignId: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};


export type OrganizationObjectPodcastsArgs = {
  campaignId: InputMaybe<Scalars['ID']>;
};


export type OrganizationObjectPodcastsCountArgs = {
  isMarketing: InputMaybe<Scalars['Boolean']>;
};


export type OrganizationObjectPodlinkDomainArgs = {
  slug: Scalars['String'];
};


export type OrganizationObjectPublisherSearchArgs = {
  applyFilters?: InputMaybe<Scalars['Boolean']>;
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};


export type OrganizationObjectSpotifyBrandLiftReportArgs = {
  id: Scalars['ID'];
};


export type OrganizationObjectSpotifyOrderArgs = {
  id: InputMaybe<Scalars['ID']>;
};


export type OrganizationObjectUnmappedSpotifyOrdersArgs = {
  limit: InputMaybe<Scalars['Int']>;
  nameSearch: InputMaybe<Scalars['String']>;
  offset: InputMaybe<Scalars['Int']>;
};

/** An enumeration. */
export enum OrganizationTier {
  /** Bronze */
  Bronze = 'BRONZE',
  /** Gold */
  Gold = 'GOLD',
  /** Silver */
  Silver = 'SILVER'
}

export type Overlap = {
  readonly __typename: 'Overlap';
  readonly after: Maybe<Scalars['Date']>;
  readonly allowedCampaignIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly before: Maybe<Scalars['Date']>;
  readonly campaignId: Maybe<Scalars['ID']>;
  readonly key: Scalars['ID'];
  readonly organizationId: Scalars['ID'];
  readonly pairs: Maybe<ReadonlyArray<Maybe<OverlapPair>>>;
};

export type OverlapPair = {
  readonly __typename: 'OverlapPair';
  readonly baseCampaign: Maybe<CampaignObject>;
  readonly baseCampaignDynamic: Maybe<CampaignDynamicObject>;
  readonly baseCampaignDynamicId: Maybe<Scalars['ID']>;
  readonly baseCampaignId: Scalars['ID'];
  readonly baseCampaignStreaming: Maybe<CampaignStreamingObject>;
  readonly baseDownloads: Maybe<Scalars['RoundedInt']>;
  readonly baseFeed: Maybe<FeedObject>;
  readonly baseFeedId: Maybe<Scalars['ID']>;
  readonly baseReach: Maybe<Scalars['RoundedInt']>;
  readonly combined: Maybe<Scalars['RoundedInt']>;
  readonly compCampaign: Maybe<CampaignObject>;
  readonly compCampaignDynamic: Maybe<CampaignDynamicObject>;
  readonly compCampaignDynamicId: Maybe<Scalars['ID']>;
  readonly compCampaignId: Scalars['ID'];
  readonly compCampaignStreaming: Maybe<CampaignStreamingObject>;
  readonly compDownloads: Maybe<Scalars['RoundedInt']>;
  readonly compFeed: Maybe<FeedObject>;
  readonly compFeedId: Maybe<Scalars['ID']>;
  readonly compReach: Maybe<Scalars['RoundedInt']>;
  readonly overlap: Maybe<Scalars['RoundedInt']>;
};

export type OverviewObject = {
  readonly __typename: 'OverviewObject';
  readonly allowedCampaignIds: Maybe<ReadonlyArray<Maybe<Scalars['ID']>>>;
  readonly brand: Maybe<BrandOverviewObject>;
  readonly impressions: Maybe<ImpressionsObject>;
  readonly organizationId: Scalars['ID'];
  readonly reach: Maybe<Scalars['RoundedInt']>;
};


export type OverviewObjectBrandArgs = {
  after: Scalars['Date'];
  before: Scalars['Date'];
  interval: InputMaybe<Scalars['String']>;
  spendByImpressions: InputMaybe<Scalars['Boolean']>;
};


export type OverviewObjectImpressionsArgs = {
  after: InputMaybe<Scalars['Date']>;
  before: InputMaybe<Scalars['Date']>;
  campaignIds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  publisherIds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};


export type OverviewObjectReachArgs = {
  after: InputMaybe<Scalars['Date']>;
  before: InputMaybe<Scalars['Date']>;
  campaignIds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};

export type PayerOrganizationObject = {
  readonly __typename: 'PayerOrganizationObject';
  readonly id: Maybe<Scalars['ID']>;
  readonly kind: Maybe<Scalars['String']>;
  readonly name: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PaymentTierCurrency {
  /** Australian Dollar */
  Aud = 'AUD',
  /** Brazilian Real */
  Brl = 'BRL',
  /** Canadian Dollar */
  Cad = 'CAD',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Euro */
  Eur = 'EUR',
  /** Pound Sterling */
  Gbp = 'GBP',
  /** Ghanian Cedi */
  Ghs = 'GHS',
  /** Kenyan Shilling */
  Kes = 'KES',
  /** Mexican Peso */
  Mxn = 'MXN',
  /** Naira */
  Ngn = 'NGN',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** New Zealand Dollar */
  Nzd = 'NZD',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore Dollar */
  Sgd = 'SGD',
  /** Tanzanian Shilling */
  Tzs = 'TZS',
  /** Uganda Shilling */
  Ugx = 'UGX',
  /** US Dollar */
  Usd = 'USD'
}

export type PaymentTierObject = {
  readonly __typename: 'PaymentTierObject';
  readonly cost: Scalars['Float'];
  readonly currency: PaymentTierCurrency;
  readonly financial: Maybe<FinancialObject>;
  readonly id: Scalars['String'];
  readonly impressionsCap: Scalars['Int'];
  readonly name: Scalars['String'];
};

/** A permission is a normalization of the AccessObject. */
export type PermissionObject = {
  readonly __typename: 'PermissionObject';
  readonly contentObjectName: Maybe<Scalars['String']>;
  readonly description: Maybe<Scalars['String']>;
  readonly id: Maybe<Scalars['ID']>;
  readonly name: Maybe<Scalars['String']>;
  readonly objectName: Maybe<Scalars['String']>;
  readonly organization: Maybe<LimitedOrganizationObject>;
  readonly permission: Maybe<Scalars['String']>;
  readonly title: Maybe<Scalars['String']>;
};

export type PixelAction = {
  readonly __typename: 'PixelAction';
  readonly action: Maybe<Scalars['String']>;
  readonly total: Maybe<Scalars['RoundedInt']>;
};

export type PixelDebug = {
  readonly __typename: 'PixelDebug';
  readonly actions: Maybe<ReadonlyArray<Maybe<PixelAction>>>;
  readonly after: Maybe<Scalars['DateTime']>;
  readonly before: Maybe<Scalars['DateTime']>;
  readonly hasData: Maybe<Scalars['Boolean']>;
  readonly historical: Maybe<ReadonlyArray<Maybe<PixelHistorical>>>;
  readonly key: Scalars['String'];
  readonly query: Maybe<PixelQuery>;
};


export type PixelDebugHistoricalArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
};


export type PixelDebugQueryArgs = {
  action: InputMaybe<Scalars['String']>;
  showMobileOnly: InputMaybe<Scalars['Boolean']>;
};

export type PixelEvent = {
  readonly __typename: 'PixelEvent';
  readonly action: Maybe<Scalars['String']>;
  readonly alias: Maybe<Scalars['String']>;
  readonly category: Maybe<Scalars['String']>;
  readonly currency: Maybe<Scalars['String']>;
  readonly discountCode: Maybe<Scalars['String']>;
  readonly eventName: Maybe<Scalars['String']>;
  readonly key: Maybe<Scalars['String']>;
  readonly lineItems: Maybe<Scalars['String']>;
  readonly newCustomer: Maybe<Scalars['String']>;
  readonly orderId: Maybe<Scalars['String']>;
  readonly pid: Maybe<Scalars['String']>;
  readonly productId: Maybe<Scalars['String']>;
  readonly productName: Maybe<Scalars['String']>;
  readonly productType: Maybe<Scalars['String']>;
  readonly productVendor: Maybe<Scalars['String']>;
  readonly quantity: Maybe<Scalars['RoundedInt']>;
  readonly referrer: Maybe<Scalars['String']>;
  readonly referrerPath: Maybe<Scalars['String']>;
  readonly sid: Maybe<Scalars['String']>;
  readonly time: Maybe<Scalars['DateTime']>;
  readonly type: Maybe<Scalars['String']>;
  readonly uid: Maybe<Scalars['String']>;
  readonly url: Maybe<Scalars['String']>;
  readonly urlDomain: Maybe<Scalars['String']>;
  readonly urlPath: Maybe<Scalars['String']>;
  readonly userAgent: Maybe<UserAgent>;
  readonly value: Maybe<Scalars['Float']>;
};

/** An enumeration. */
export enum PixelEventRecordEventType {
  /** Add to Cart */
  Addtocart = 'ADDTOCART',
  /** Checkout */
  Checkout = 'CHECKOUT',
  /** Install */
  Install = 'INSTALL',
  /** Lead */
  Lead = 'LEAD',
  /** Page View */
  PageView = 'PAGE_VIEW',
  /** Product */
  Product = 'PRODUCT',
  /** Purchase */
  Purchase = 'PURCHASE'
}

/** An enumeration. */
export enum PixelEventRecordInstallation {
  /** Adjust */
  Adjust = 'ADJUST',
  /** AppsFlyer */
  Appsflyer = 'APPSFLYER',
  /** Branch */
  Branch = 'BRANCH',
  /** JavaScript */
  Javascript = 'JAVASCRIPT',
  /** Kochava */
  Kochava = 'KOCHAVA',
  /** Segment */
  Segment = 'SEGMENT'
}

export type PixelEventRecordObject = {
  readonly __typename: 'PixelEventRecordObject';
  readonly category: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly currency: Scalars['String'];
  readonly discountCode: Scalars['String'];
  readonly eventType: PixelEventRecordEventType;
  readonly id: Scalars['String'];
  readonly installation: PixelEventRecordInstallation;
  readonly key: KeyObject;
  readonly lastActivity: Maybe<Scalars['DateTime']>;
  readonly lastActivityFlag: Maybe<Scalars['String']>;
  readonly lastUpdatedBy: LimitedUserObject;
  readonly name: Maybe<Scalars['String']>;
  readonly newCustomer: Scalars['String'];
  readonly orderId: Scalars['String'];
  readonly productId: Scalars['String'];
  readonly productName: Scalars['String'];
  readonly productType: Scalars['String'];
  readonly productVendor: Scalars['String'];
  readonly quantity: Scalars['String'];
  readonly type: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly value: Scalars['String'];
  readonly variantId: Scalars['String'];
  readonly variantName: Scalars['String'];
};

export type PixelHistorical = {
  readonly __typename: 'PixelHistorical';
  readonly hour: Maybe<Scalars['DateTime']>;
  readonly img: Maybe<ReadonlyArray<Maybe<PixelHistoricalEvent>>>;
  readonly js: Maybe<ReadonlyArray<Maybe<PixelHistoricalEvent>>>;
  readonly mobile: Maybe<ReadonlyArray<Maybe<PixelHistoricalEvent>>>;
};

export type PixelHistoricalEvent = {
  readonly __typename: 'PixelHistoricalEvent';
  readonly eventCounts: Maybe<ReadonlyArray<Maybe<PixelHistoricalEventCount>>>;
  readonly name: Maybe<Scalars['String']>;
};

export type PixelHistoricalEventCount = {
  readonly __typename: 'PixelHistoricalEventCount';
  readonly name: Maybe<Scalars['String']>;
  readonly value: Maybe<Scalars['RoundedInt']>;
};

export type PixelQuery = {
  readonly __typename: 'PixelQuery';
  readonly action: Maybe<Scalars['String']>;
  readonly actionsMappings: Maybe<ReadonlyArray<Maybe<ActionMapObject>>>;
  readonly count: Maybe<Scalars['RoundedInt']>;
  readonly key: Scalars['String'];
  readonly nodes: Maybe<ReadonlyArray<Maybe<PixelEvent>>>;
  readonly showMobileOnly: Maybe<Scalars['Boolean']>;
};

export type PlacementInput = {
  readonly endTime: Scalars['Float'];
  readonly id: Scalars['ID'];
  readonly startTime: Scalars['Float'];
};

export type PlacementObject = {
  readonly __typename: 'PlacementObject';
  readonly ad: AdObject;
  readonly createdAt: Scalars['DateTime'];
  readonly endTime: Scalars['Float'];
  readonly episode: EpisodeObject;
  readonly id: Scalars['String'];
  readonly proxiedEnclosure: Maybe<ProxiedEnclosureObject>;
  readonly startTime: Scalars['Float'];
  readonly updatedAt: Scalars['DateTime'];
};

export type PodcastApprovalObject = {
  readonly __typename: 'PodcastApprovalObject';
  readonly campaignPodcast: CampaignPodcastObject;
  readonly comment: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly rejectedReason: Maybe<Scalars['String']>;
  readonly requestedAt: Scalars['DateTime'];
  readonly requestedBy: Maybe<UnAuthedUserObject>;
  readonly requestedByOrganization: Maybe<ApprovalOrganization>;
  readonly requestee: Maybe<UnAuthedUserObject>;
  readonly requesteeOrganization: Maybe<ApprovalOrganization>;
  readonly state: PodcastApprovalState;
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedBy: Maybe<UnAuthedUserObject>;
};

/** An enumeration. */
export enum PodcastApprovalState {
  /** Approved */
  Approved = 'APPROVED',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Requested */
  Requested = 'REQUESTED'
}

export type PodcastIdAndFeedObject = {
  readonly __typename: 'PodcastIdAndFeedObject';
  readonly feed: Maybe<FeedObject>;
  readonly id: Maybe<Scalars['ID']>;
};

export type PodcastIdObject = {
  readonly __typename: 'PodcastIdObject';
  readonly id: Maybe<Scalars['ID']>;
};

export type PodcastObject = {
  readonly __typename: 'PodcastObject';
  readonly accessSet: Maybe<ReadonlyArray<Maybe<AccessObject>>>;
  readonly campaignDynamicPodcasts: Maybe<ReadonlyArray<Maybe<CampaignDynamicPodcastObject>>>;
  readonly campaignPodcasts: Maybe<ReadonlyArray<Maybe<CampaignPodcastObject>>>;
  readonly campaignTargets: Maybe<ReadonlyArray<Maybe<CampaignTargetObject>>>;
  readonly capabilities: ReadonlyArray<CapabilityObject>;
  readonly createdAt: Scalars['DateTime'];
  readonly downloads: Maybe<DownloadsObject>;
  readonly episode: Maybe<EpisodeObject>;
  readonly feed: Maybe<FeedObject>;
  readonly feeds: Maybe<ReadonlyArray<Maybe<FeedObject>>>;
  readonly futureEpisode: Maybe<FutureEpisodeObject>;
  readonly futureEpisodeNodes: Maybe<FutureEpisodeNodes>;
  readonly futureEpisodes: Maybe<ReadonlyArray<Maybe<FutureEpisodeObject>>>;
  readonly id: Scalars['String'];
  readonly isAdvisor: Scalars['Boolean'];
  readonly isTranscriptionDisabled: Scalars['Boolean'];
  readonly maids: Maybe<MaidsObject>;
  readonly organization: Maybe<LimitedOrganizationObject>;
  readonly podlinkdomainSet: ReadonlyArray<PodlinkDomainObject>;
  readonly processingStartedAt: Maybe<Scalars['Date']>;
  readonly publisher: Maybe<PublisherObject>;
  readonly relation: PodcastRelation;
  readonly updatedAt: Scalars['DateTime'];
};


export type PodcastObjectDownloadsArgs = {
  after: InputMaybe<Scalars['String']>;
  algorithm: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  kind: InputMaybe<Scalars['String']>;
};


export type PodcastObjectEpisodeArgs = {
  id: Scalars['ID'];
};


export type PodcastObjectFutureEpisodeArgs = {
  id: Scalars['ID'];
};


export type PodcastObjectFutureEpisodeNodesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Scalars['String']>;
  query: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum PodcastRelation {
  /** Owner */
  A_0 = 'A_0',
  /** Secondary */
  A_1 = 'A_1',
  /** DAI */
  A_2 = 'A_2',
  /** Archived */
  A_3 = 'A_3'
}

export type PodlinkDomainObject = {
  readonly __typename: 'PodlinkDomainObject';
  readonly backgroundColor: Maybe<Scalars['String']>;
  readonly description: Maybe<Scalars['String']>;
  readonly favicon: Maybe<AssetObject>;
  readonly fontColor: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly image: Maybe<AssetObject>;
  readonly podcasts: Maybe<ReadonlyArray<Maybe<PodcastIdAndFeedObject>>>;
  readonly primaryColor: Maybe<Scalars['String']>;
  readonly secondaryColor: Maybe<Scalars['String']>;
  readonly slug: Scalars['String'];
  readonly title: Scalars['String'];
};

export type ProcessCampaignEpisodes = {
  readonly __typename: 'ProcessCampaignEpisodes';
  readonly created: Maybe<ReadonlyArray<Maybe<CampaignEpisodeObject>>>;
  readonly success: Scalars['Boolean'];
  readonly updated: Maybe<ReadonlyArray<Maybe<CampaignEpisodeObject>>>;
};

export type ProcessCampaignEpisodesInput = {
  readonly creates: InputMaybe<ReadonlyArray<InputMaybe<CreateCampaignEpisodeInput>>>;
  readonly organizationId: Scalars['ID'];
  readonly removes: InputMaybe<ReadonlyArray<InputMaybe<RemoveCampaignEpisodeInput>>>;
  readonly updates: InputMaybe<ReadonlyArray<InputMaybe<UpdateCampaignEpisodeInput>>>;
};

/** start processing feeds. */
export type ProcessFeed = {
  readonly __typename: 'ProcessFeed';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type ProcessFeedInput = {
  readonly episodeId: InputMaybe<Scalars['ID']>;
  readonly feedId: Scalars['ID'];
};

export type ProductObject = {
  readonly __typename: 'ProductObject';
  readonly ads: Maybe<ReadonlyArray<Maybe<AdObject>>>;
  readonly adsCount: Maybe<Scalars['Int']>;
  readonly company: Maybe<CompanyObject>;
  readonly createdAt: Scalars['DateTime'];
  readonly description: Maybe<Scalars['String']>;
  readonly discountCode: Maybe<Scalars['String']>;
  readonly episodesCount: Maybe<Scalars['Int']>;
  readonly feedsCount: Maybe<Scalars['Int']>;
  readonly id: Scalars['String'];
  readonly image: Maybe<MediaImageObject>;
  readonly name: Scalars['String'];
  readonly slug: Scalars['String'];
  readonly tags: ReadonlyArray<TagObject>;
  readonly updatedAt: Scalars['DateTime'];
  readonly url: Maybe<Scalars['String']>;
};


export type ProductObjectAdsArgs = {
  after: InputMaybe<Scalars['String']>;
  feedId: InputMaybe<Scalars['ID']>;
  limit: InputMaybe<Scalars['Int']>;
};


export type ProductObjectAdsCountArgs = {
  after: InputMaybe<Scalars['String']>;
};


export type ProductObjectEpisodesCountArgs = {
  after: InputMaybe<Scalars['String']>;
};


export type ProductObjectFeedsCountArgs = {
  after: InputMaybe<Scalars['String']>;
};

/** Used by ProductSearchQuery so that we don't leak all the things. */
export type ProductSearchObject = {
  readonly __typename: 'ProductSearchObject';
  readonly id: Maybe<Scalars['ID']>;
  readonly name: Maybe<Scalars['ID']>;
  readonly slug: Maybe<Scalars['ID']>;
};

export type ProviderNameObject = {
  readonly __typename: 'ProviderNameObject';
  readonly providerName: Maybe<Scalars['String']>;
  readonly providerNameDisplay: Maybe<Scalars['String']>;
};

export type ProxiedEnclosureObject = {
  readonly __typename: 'ProxiedEnclosureObject';
  readonly contentPath: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly differentViaProxy: Scalars['Boolean'];
  readonly enclosure: EnclosureObject;
  readonly fileSize: Maybe<Scalars['Int']>;
  readonly href: Scalars['String'];
  readonly id: Scalars['String'];
  readonly length: Maybe<Scalars['String']>;
  readonly mpegPath: Maybe<Scalars['String']>;
  readonly placements: ReadonlyArray<PlacementObject>;
  readonly proxyId: Maybe<Scalars['ID']>;
  readonly proxyName: Maybe<Scalars['String']>;
  readonly sampleRate: Maybe<Scalars['Int']>;
  readonly sourcePath: Maybe<Scalars['String']>;
  readonly type: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly waveform: Maybe<Scalars['String']>;
};

export type PublishMessage = {
  readonly __typename: 'PublishMessage';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type PublishMessageInput = {
  readonly action: Scalars['String'];
  readonly attributes: InputMaybe<Scalars['String']>;
  readonly topic: Scalars['String'];
};

export type PublishSpotifyBrandLiftReport = {
  readonly __typename: 'PublishSpotifyBrandLiftReport';
  readonly success: Scalars['Boolean'];
};

export type PublishSpotifyBrandLiftReportInput = {
  readonly id: Scalars['ID'];
  readonly isHiddenFromAgency: Scalars['Boolean'];
};

export type PublisherAffinityScoreObject = {
  readonly __typename: 'PublisherAffinityScoreObject';
  readonly industry: Scalars['String'];
  readonly percentile: Scalars['Float'];
  readonly rank: Scalars['Int'];
};

export type PublisherApproveCampaign = {
  readonly __typename: 'PublisherApproveCampaign';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type PublisherApproveCampaignInput = {
  readonly campaignId: Scalars['ID'];
  readonly organizationId: Scalars['ID'];
};

export type PublisherObject = {
  readonly __typename: 'PublisherObject';
  readonly companies: Maybe<ReadonlyArray<Maybe<CompanyObject>>>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['ID'];
  readonly podcasts: Maybe<ReadonlyArray<Maybe<PodcastIdAndFeedObject>>>;
  readonly slug: Scalars['ID'];
};


export type PublisherObjectCompaniesArgs = {
  after: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Scalars['String']>;
};

export type PublisherOverlapObject = {
  readonly __typename: 'PublisherOverlapObject';
  readonly baseDownloads: Maybe<Scalars['RoundedInt']>;
  readonly basePublisher: Maybe<ImpressionsPublisherObject>;
  readonly basePublisherId: Maybe<Scalars['ID']>;
  readonly baseReach: Maybe<Scalars['RoundedInt']>;
  readonly combined: Maybe<Scalars['RoundedInt']>;
  readonly compDownloads: Maybe<Scalars['RoundedInt']>;
  readonly compPublisher: Maybe<ImpressionsPublisherObject>;
  readonly compPublisherId: Maybe<Scalars['ID']>;
  readonly compReach: Maybe<Scalars['RoundedInt']>;
  readonly overlap: Maybe<Scalars['RoundedInt']>;
};

export type PublisherOverviewSearchResult = {
  readonly __typename: 'PublisherOverviewSearchResult';
  readonly adsCount: Maybe<Scalars['Int']>;
  readonly episodesCount: Maybe<Scalars['Int']>;
  readonly feedsCount: Maybe<Scalars['Int']>;
  readonly id: Maybe<Scalars['String']>;
  readonly name: Maybe<Scalars['String']>;
  readonly slug: Maybe<Scalars['String']>;
};

export type PublisherOverviewSearchResults = {
  readonly __typename: 'PublisherOverviewSearchResults';
  readonly results: Maybe<ReadonlyArray<Maybe<PublisherOverviewSearchResult>>>;
  readonly total: Maybe<Scalars['RoundedInt']>;
};

export type PublisherRejectCampaign = {
  readonly __typename: 'PublisherRejectCampaign';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type PublisherRejectCampaignInput = {
  readonly campaignId: Scalars['ID'];
  readonly organizationId: Scalars['ID'];
  readonly publisherDenyReason: InputMaybe<Scalars['String']>;
};

export type PublisherSearch = {
  readonly __typename: 'PublisherSearch';
  readonly count: Maybe<Scalars['Int']>;
  readonly limit: Maybe<Scalars['Int']>;
  readonly offset: Maybe<Scalars['Int']>;
  readonly results: Maybe<ReadonlyArray<Maybe<PublisherSearchObject>>>;
};

/** Used by PublisherSearchQuery so that we don't leak all the things that point to company. */
export type PublisherSearchObject = {
  readonly __typename: 'PublisherSearchObject';
  readonly id: Maybe<Scalars['ID']>;
  readonly name: Maybe<Scalars['String']>;
  readonly slug: Maybe<Scalars['String']>;
};

export type Query = {
  readonly __typename: 'Query';
  readonly admin: Maybe<Admin>;
  readonly claim: Maybe<Claim>;
  readonly currencies: ReadonlyArray<Maybe<CurrencyNameObject>>;
  readonly health: Scalars['String'];
  readonly locations: ReadonlyArray<Maybe<LocationNameObject>>;
  readonly me: Maybe<UserObject>;
  readonly productSearch: Maybe<ReadonlyArray<Maybe<ProductSearchObject>>>;
  readonly userInfo: Maybe<UserInfoObject>;
};


export type QueryAdminArgs = {
  token: InputMaybe<Scalars['String']>;
};


export type QueryCurrenciesArgs = {
  currency: InputMaybe<Scalars['String']>;
  currencyDisplay: InputMaybe<Scalars['String']>;
};


export type QueryLocationsArgs = {
  location: InputMaybe<Scalars['String']>;
  locationDisplay: InputMaybe<Scalars['String']>;
};


export type QueryMeArgs = {
  apiKeyId: InputMaybe<Scalars['String']>;
  apiKeySecret: InputMaybe<Scalars['String']>;
  token: InputMaybe<Scalars['String']>;
};


export type QueryProductSearchArgs = {
  companyId: Scalars['ID'];
  query: Scalars['String'];
};

export type QueueObject = {
  readonly __typename: 'QueueObject';
  readonly length: Maybe<Scalars['Int']>;
  readonly messages: Maybe<ReadonlyArray<Maybe<MessageObject>>>;
  readonly topic: Scalars['String'];
};


export type QueueObjectMessagesArgs = {
  start: InputMaybe<Scalars['Int']>;
  stop: InputMaybe<Scalars['Int']>;
};

/** An enumeration. */
export enum RankCategory {
  /** Arts */
  Arts = 'ARTS',
  /** Business */
  Business = 'BUSINESS',
  /** Comedy */
  Comedy = 'COMEDY',
  /** Education */
  Education = 'EDUCATION',
  /** Games & Hobbies */
  GamesHobbies = 'GAMES_HOBBIES',
  /** Government & Organizations */
  GovernmentOrganizations = 'GOVERNMENT_ORGANIZATIONS',
  /** Health */
  Health = 'HEALTH',
  /** Kids & Family */
  KidsFamily = 'KIDS_FAMILY',
  /** Music */
  Music = 'MUSIC',
  /** News & Politics */
  NewsPolitics = 'NEWS_POLITICS',
  /** Religion & Spirituality */
  ReligionSpirituality = 'RELIGION_SPIRITUALITY',
  /** Science & Medicine */
  ScienceMedicine = 'SCIENCE_MEDICINE',
  /** Society & Culture */
  SocietyCulture = 'SOCIETY_CULTURE',
  /** Sports & Recreation */
  SportsRecreation = 'SPORTS_RECREATION',
  /** Technology */
  Technology = 'TECHNOLOGY',
  /** Top 200 */
  Top_200 = 'TOP_200',
  /** TV & Film */
  TvFilm = 'TV_FILM'
}

export type RankObject = {
  readonly __typename: 'RankObject';
  readonly category: RankCategory;
  readonly createdAt: Scalars['DateTime'];
  readonly episodesCount: Scalars['Int'];
  readonly feed: Maybe<LimitedFeedObject>;
  readonly id: Scalars['ID'];
  readonly num: Scalars['Int'];
  readonly placementsCount: Scalars['Int'];
  readonly provider: RankProvider;
};

/** An enumeration. */
export enum RankProvider {
  /** Itunes */
  Itunes = 'ITUNES'
}

export type RejectAd = {
  readonly __typename: 'RejectAd';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type RejectAdInput = {
  readonly id: Scalars['ID'];
  readonly reviewDuration: InputMaybe<Scalars['Int']>;
  readonly reviewerId: InputMaybe<Scalars['ID']>;
};

export type RejectCompanyClaim = {
  readonly __typename: 'RejectCompanyClaim';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type RejectCompanyClaimInput = {
  readonly id: Scalars['ID'];
};

export type RemoveAccess = {
  readonly __typename: 'RemoveAccess';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type RemoveAccessInput = {
  readonly accessId: Scalars['ID'];
};

export type RemoveAdvisorForOrganization = {
  readonly __typename: 'RemoveAdvisorForOrganization';
  readonly organization: Maybe<OrganizationObject>;
};

export type RemoveAdvisorForOrganizationInput = {
  readonly id: Scalars['String'];
};

export type RemoveCampaignEpisode = {
  readonly __typename: 'RemoveCampaignEpisode';
  readonly success: Scalars['Boolean'];
};

export type RemoveCampaignEpisodeInput = {
  readonly id: Scalars['ID'];
};

export type RemoveDomain = {
  readonly __typename: 'RemoveDomain';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type RemoveDomainInput = {
  readonly id: Scalars['String'];
};

export type RemoveMember = {
  readonly __typename: 'RemoveMember';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type RemoveMemberInput = {
  readonly id: Scalars['String'];
};

export type RemoveTag = {
  readonly __typename: 'RemoveTag';
  readonly companyId: Maybe<Scalars['ID']>;
  readonly productId: Maybe<Scalars['ID']>;
  readonly tag: Maybe<TagObject>;
};

export type RemoveTagInput = {
  readonly companyId: InputMaybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly productId: InputMaybe<Scalars['ID']>;
};

export type RequestNewBrandForSpotifyOrder = {
  readonly __typename: 'RequestNewBrandForSpotifyOrder';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type RequestNewBrandForSpotifyOrderInput = {
  readonly advertiserWebsite: InputMaybe<Scalars['String']>;
  readonly agencyName: InputMaybe<Scalars['String']>;
  readonly bookedStatus: Scalars['String'];
  readonly brandContactEmail: Scalars['String'];
  readonly brandContactFirstName: Scalars['String'];
  readonly brandContactLastName: Scalars['String'];
  readonly brandName: Scalars['String'];
  readonly campaignKind: Scalars['String'];
  readonly spotifyOrderId: Scalars['ID'];
};

/** We need to seperate this data out from the organization. */
export type ResearchObject = {
  readonly __typename: 'ResearchObject';
  readonly ad: Maybe<AdObject>;
  readonly adsCount: Maybe<Scalars['Int']>;
  readonly brand: Maybe<BrandObject>;
  readonly companies: Maybe<ReadonlyArray<Maybe<CompanyObject>>>;
  readonly companiesCount: Maybe<Scalars['Int']>;
  readonly company: Maybe<CompanyObject>;
  readonly feed: Maybe<FeedObject>;
  readonly publisher: Maybe<PublisherObject>;
  readonly publishers: Maybe<ReadonlyArray<Maybe<PublisherObject>>>;
  readonly rankings: Maybe<ReadonlyArray<Maybe<RankObject>>>;
  readonly reviewer: Maybe<ReviewerObject>;
  readonly reviewers: Maybe<ReadonlyArray<Maybe<ReviewerObject>>>;
  readonly reviewing: Maybe<Reviewing>;
  readonly search: Maybe<SearchResults>;
};


/** We need to seperate this data out from the organization. */
export type ResearchObjectAdArgs = {
  id: Scalars['ID'];
};


/** We need to seperate this data out from the organization. */
export type ResearchObjectCompaniesArgs = {
  after: InputMaybe<Scalars['String']>;
  before: InputMaybe<Scalars['String']>;
  inactiveSince: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  maxAds: InputMaybe<Scalars['Int']>;
  maxEpisodes: InputMaybe<Scalars['Int']>;
  maxPodcasts: InputMaybe<Scalars['Int']>;
  minAds: InputMaybe<Scalars['Int']>;
  minEpisodes: InputMaybe<Scalars['Int']>;
  minPodcasts: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
  orderBy: InputMaybe<Scalars['String']>;
  query: InputMaybe<Scalars['String']>;
};


/** We need to seperate this data out from the organization. */
export type ResearchObjectCompanyArgs = {
  id: Scalars['ID'];
};


/** We need to seperate this data out from the organization. */
export type ResearchObjectFeedArgs = {
  id: Scalars['ID'];
};


/** We need to seperate this data out from the organization. */
export type ResearchObjectPublisherArgs = {
  slug: Scalars['String'];
};


/** We need to seperate this data out from the organization. */
export type ResearchObjectPublishersArgs = {
  limit: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};


/** We need to seperate this data out from the organization. */
export type ResearchObjectRankingsArgs = {
  category: Scalars['String'];
  hour: InputMaybe<Scalars['String']>;
  limit: InputMaybe<Scalars['Int']>;
  provider: Scalars['String'];
};


/** We need to seperate this data out from the organization. */
export type ResearchObjectReviewerArgs = {
  id: InputMaybe<Scalars['ID']>;
};


/** We need to seperate this data out from the organization. */
export type ResearchObjectSearchArgs = {
  limit: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type ResetContact = {
  readonly __typename: 'ResetContact';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type ResetContactInput = {
  readonly contactId: Scalars['ID'];
};

/** An enumeration. */
export enum ReviewEndState {
  /** Approved */
  Approved = 'APPROVED',
  /** Duplicate */
  Duplicate = 'DUPLICATE',
  /** edited */
  Edited = 'EDITED',
  /** Initial Review */
  Initial = 'INITIAL',
  /** Media Generated */
  Media = 'MEDIA',
  /** New */
  New = 'NEW',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Split */
  Split = 'SPLIT',
  /** Transcript Generated */
  Transcript = 'TRANSCRIPT',
  /** Unknown */
  Unknown = 'UNKNOWN'
}

export type ReviewObject = {
  readonly __typename: 'ReviewObject';
  readonly ad: Maybe<AdObject>;
  readonly companyId: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly deltas: Maybe<Scalars['String']>;
  readonly duration: Maybe<Scalars['Int']>;
  readonly endState: ReviewEndState;
  readonly episode: Maybe<EpisodeObject>;
  readonly hasNoAds: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly mturkAssignmentId: Maybe<Scalars['String']>;
  readonly mturkHitId: Maybe<Scalars['String']>;
  readonly productId: Maybe<Scalars['String']>;
  readonly reviewer: ReviewerObject;
  readonly sponsorId: Maybe<Scalars['String']>;
  readonly startState: ReviewStartState;
  readonly updatedAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum ReviewStartState {
  /** Approved */
  Approved = 'APPROVED',
  /** Duplicate */
  Duplicate = 'DUPLICATE',
  /** edited */
  Edited = 'EDITED',
  /** Initial Review */
  Initial = 'INITIAL',
  /** Media Generated */
  Media = 'MEDIA',
  /** New */
  New = 'NEW',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Split */
  Split = 'SPLIT',
  /** Transcript Generated */
  Transcript = 'TRANSCRIPT',
  /** Unknown */
  Unknown = 'UNKNOWN'
}

export type ReviewerObject = {
  readonly __typename: 'ReviewerObject';
  readonly createdAt: Scalars['DateTime'];
  readonly email: Maybe<Scalars['String']>;
  readonly episodes: Maybe<ReadonlyArray<Maybe<EpisodeObject>>>;
  readonly firstName: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly lastName: Maybe<Scalars['String']>;
  readonly mturkWorkerId: Maybe<Scalars['String']>;
  readonly reviews: Maybe<ReadonlyArray<Maybe<ReviewObject>>>;
  readonly reviewsCount: Maybe<Scalars['Int']>;
  readonly superReviewer: Scalars['Boolean'];
  readonly totalClassifyCount: Maybe<Scalars['Int']>;
  readonly totalEpisodeCount: Maybe<Scalars['Int']>;
  readonly totalEpisodeMinutes: Maybe<Scalars['Int']>;
  readonly type: ReviewerType;
  readonly updatedAt: Scalars['DateTime'];
};


export type ReviewerObjectEpisodesArgs = {
  limit: InputMaybe<Scalars['Int']>;
};


export type ReviewerObjectReviewsArgs = {
  limit: InputMaybe<Scalars['Int']>;
};

/** An enumeration. */
export enum ReviewerType {
  /** Ad */
  Ad = 'AD',
  /** All */
  All = 'ALL',
  /** Disabled */
  Disabled = 'DISABLED',
  /** Episode */
  Episode = 'EPISODE',
  /** Episode & Classify */
  EpisodeClassify = 'EPISODE_CLASSIFY'
}

export type Reviewing = {
  readonly __typename: 'Reviewing';
  readonly ad: Maybe<AdObject>;
  readonly ads: Maybe<ReadonlyArray<Maybe<AdObject>>>;
  readonly adsCount: Maybe<Scalars['Int']>;
  readonly companies: Maybe<ReadonlyArray<Maybe<CompanyObject>>>;
  readonly episodeMinutesByDay: Maybe<ReadonlyArray<Maybe<EpisodeMinutesByDay>>>;
  readonly episodes: Maybe<ReadonlyArray<Maybe<EpisodeObject>>>;
  readonly sortedAds: Maybe<ReadonlyArray<Maybe<SortedAdObject>>>;
  readonly transcribable: Maybe<ReadonlyArray<Maybe<EpisodeObject>>>;
};


export type ReviewingAdArgs = {
  id: Scalars['ID'];
};


export type ReviewingAdsArgs = {
  before: InputMaybe<Scalars['String']>;
  feedId: InputMaybe<Scalars['ID']>;
  includeUnknown: InputMaybe<Scalars['Boolean']>;
  offset: InputMaybe<Scalars['Int']>;
  reviewedById: InputMaybe<Scalars['ID']>;
  state: InputMaybe<Scalars['String']>;
};


export type ReviewingAdsCountArgs = {
  feedId: InputMaybe<Scalars['ID']>;
  includeUnknown: InputMaybe<Scalars['Boolean']>;
  reviewedById: InputMaybe<Scalars['ID']>;
  state: InputMaybe<Scalars['String']>;
};


export type ReviewingEpisodeMinutesByDayArgs = {
  after: InputMaybe<Scalars['Date']>;
  reviewerId: InputMaybe<Scalars['ID']>;
};


export type ReviewingSortedAdsArgs = {
  feedId: InputMaybe<Scalars['ID']>;
  includeUnknown: InputMaybe<Scalars['Boolean']>;
  sortedBy: InputMaybe<Scalars['String']>;
};


export type ReviewingTranscribableArgs = {
  after: InputMaybe<Scalars['Date']>;
  before: InputMaybe<Scalars['Date']>;
};

export type RunLiftReport = {
  readonly __typename: 'RunLiftReport';
  readonly liftReport: Maybe<LiftReportObject>;
};

export type RunLiftReportInput = {
  readonly liftReportId: Scalars['ID'];
};

/** An enumeration. */
export enum SavedExportExportType {
  /** Admin */
  Admin = 'ADMIN',
  /** Campaign Conversions */
  CampaignConversions = 'CAMPAIGN_CONVERSIONS',
  /** Campaign Daily Overview */
  CampaignDailyOverview = 'CAMPAIGN_DAILY_OVERVIEW',
  /** Campaign Entrances */
  CampaignEntrances = 'CAMPAIGN_ENTRANCES',
  /** Campaign Episode */
  CampaignEpisode = 'CAMPAIGN_EPISODE',
  /** Campaign Impressions */
  CampaignImpressions = 'CAMPAIGN_IMPRESSIONS',
  /** Campaign Overview */
  CampaignOverview = 'CAMPAIGN_OVERVIEW',
  /** Campaign Placements */
  CampaignPlacements = 'CAMPAIGN_PLACEMENTS',
  /** Campaign Podcast */
  CampaignPodcast = 'CAMPAIGN_PODCAST',
  /** Company Placements */
  CompanyPlacements = 'COMPANY_PLACEMENTS',
  /** Episode Downloads Daily */
  EpisodeDownloadsDaily = 'EPISODE_DOWNLOADS_DAILY',
  /** Episode Downloads Hourly */
  EpisodeDownloadsHourly = 'EPISODE_DOWNLOADS_HOURLY',
  /** Episode Placements */
  EpisodePlacements = 'EPISODE_PLACEMENTS',
  /** Feed Placements */
  FeedPlacements = 'FEED_PLACEMENTS',
  /** Feed Segments */
  FeedSegments = 'FEED_SEGMENTS',
  /** Organization Benchmark Campaigns */
  OrganizationBenchmarkCampaigns = 'ORGANIZATION_BENCHMARK_CAMPAIGNS',
  /** Organization Benchmark Campaigns Detail */
  OrganizationBenchmarkCampaignsDetail = 'ORGANIZATION_BENCHMARK_CAMPAIGNS_DETAIL',
  /** Organization Daily Overview */
  OrganizationCampaignConversions = 'ORGANIZATION_CAMPAIGN_CONVERSIONS',
  /** Organization Daily Overview */
  OrganizationDailyOverview = 'ORGANIZATION_DAILY_OVERVIEW',
  /** Organization Interval Overlap */
  OrganizationIntervalOverlap = 'ORGANIZATION_INTERVAL_OVERLAP',
  /** Organization IDs */
  OrganizationListAllIds = 'ORGANIZATION_LIST_ALL_IDS',
  /** Organization Campaign IDs */
  OrganizationListCampaignIds = 'ORGANIZATION_LIST_CAMPAIGN_IDS',
  /** Organization Locations Overview */
  OrganizationLocations = 'ORGANIZATION_LOCATIONS',
  /** Organization Placements */
  OrganizationPlacements = 'ORGANIZATION_PLACEMENTS',
  /** Organization Segments */
  OrganizationSegments = 'ORGANIZATION_SEGMENTS',
  /** Organization Weekly Overview */
  OrganizationWeeklyOverview = 'ORGANIZATION_WEEKLY_OVERVIEW',
  /** Podcast Downloads Daily */
  PodcastDownloadsDaily = 'PODCAST_DOWNLOADS_DAILY',
  /** Podcast Downloads Full */
  PodcastDownloadsFull = 'PODCAST_DOWNLOADS_FULL',
  /** Podcast Downloads Hourly */
  PodcastDownloadsHourly = 'PODCAST_DOWNLOADS_HOURLY'
}

export type SavedExportObject = {
  readonly __typename: 'SavedExportObject';
  readonly archived: Scalars['Boolean'];
  readonly createdAt: Scalars['DateTime'];
  readonly createdBy: Maybe<LimitedUserObject>;
  readonly exportInstances: ReadonlyArray<ExportInstanceObject>;
  readonly exportParameters: Maybe<Scalars['JSONString']>;
  readonly exportType: SavedExportExportType;
  readonly fields: Maybe<Scalars['JSONString']>;
  readonly id: Scalars['String'];
  readonly lastExportInstance: Maybe<ExportInstanceObject>;
  readonly name: Scalars['String'];
  readonly orderBy: Maybe<Scalars['JSONString']>;
  readonly organization: Maybe<LimitedOrganizationObject>;
  readonly slug: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedBy: Maybe<LimitedUserObject>;
};

export type SearchResult = {
  readonly __typename: 'SearchResult';
  readonly body: Maybe<Scalars['String']>;
  readonly duration: Maybe<Scalars['Float']>;
  readonly id: Maybe<Scalars['String']>;
  readonly imagePath: Maybe<Scalars['String']>;
  readonly kind: Maybe<Scalars['String']>;
  readonly organizations: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly published: Maybe<Scalars['RoundedInt']>;
  readonly slug: Maybe<Scalars['String']>;
  readonly subtitle: Maybe<Scalars['String']>;
  readonly title: Maybe<Scalars['String']>;
};

export type SearchResults = {
  readonly __typename: 'SearchResults';
  readonly results: Maybe<ReadonlyArray<Maybe<SearchResult>>>;
  readonly total: Maybe<Scalars['RoundedInt']>;
};

export type SendTrackingUrlsConfirmationReminder = {
  readonly __typename: 'SendTrackingUrlsConfirmationReminder';
  readonly error: Maybe<Scalars['String']>;
  readonly success: Maybe<Scalars['Boolean']>;
};

export type SendTrackingUrlsConfirmationReminderInput = {
  readonly campaignId: Scalars['ID'];
  readonly publisherId: Scalars['ID'];
  readonly recipientEmail: InputMaybe<Scalars['String']>;
};

/**
 * Verifies that the email, Gets the token and sends an email
 * to verify the user is able to login to SPAA
 */
export type SendVerifyEmail = {
  readonly __typename: 'SendVerifyEmail';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type SendVerifyEmailInput = {
  readonly host: InputMaybe<Scalars['String']>;
};

export type SetAdAnalyticsTutorialProgress = {
  readonly __typename: 'SetAdAnalyticsTutorialProgress';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type SetAdAnalyticsTutorialProgressInput = {
  readonly progress: Scalars['Int'];
};

export type SetAdvisorOptions = {
  readonly __typename: 'SetAdvisorOptions';
  readonly organization: Maybe<OrganizationObject>;
};

export type SetAdvisorOptionsInput = {
  readonly capabilityIds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly organizationId: Scalars['ID'];
  readonly podcastIds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
};

export type SetHasSeenAdAnalyticsWelcome = {
  readonly __typename: 'SetHasSeenAdAnalyticsWelcome';
  readonly status: Maybe<Scalars['Boolean']>;
  readonly success: Maybe<Scalars['Boolean']>;
};

export type SetHasSeenAdAnalyticsWelcomeInput = {
  readonly status: Scalars['Boolean'];
};

export type SignMedia = {
  readonly __typename: 'SignMedia';
  readonly id: Maybe<Scalars['String']>;
  readonly path: Maybe<Scalars['String']>;
  readonly signedUrl: Maybe<Scalars['String']>;
  readonly success: Maybe<Scalars['Boolean']>;
};

export type SignMediaInput = {
  readonly contentType: Scalars['String'];
  readonly prefix: InputMaybe<Scalars['String']>;
};

export enum Significance {
  Confidence_80 = 'CONFIDENCE_80',
  Confidence_90 = 'CONFIDENCE_90',
  NotDetected = 'NOT_DETECTED'
}

export type SimpleBillingRecord = {
  readonly __typename: 'SimpleBillingRecord';
  readonly downloadDate: Maybe<Scalars['Date']>;
  readonly impressions: Maybe<Scalars['RoundedInt']>;
  readonly marketingImpressions: Maybe<Scalars['RoundedInt']>;
  readonly totalImpressions: Maybe<Scalars['RoundedInt']>;
};

export type SortedAdObject = {
  readonly __typename: 'SortedAdObject';
  readonly ad: Maybe<AdObject>;
  readonly body: Maybe<Scalars['String']>;
  readonly companyId: Maybe<Scalars['String']>;
  readonly companyName: Maybe<Scalars['String']>;
  readonly maxScore: Maybe<Scalars['Float']>;
  readonly productId: Maybe<Scalars['String']>;
  readonly productName: Maybe<Scalars['String']>;
  readonly score: Maybe<Scalars['Float']>;
  readonly text: Maybe<Scalars['String']>;
};

export type SplitAd = {
  readonly __typename: 'SplitAd';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type SplitAdInput = {
  readonly id: Scalars['ID'];
  readonly reviewDuration: InputMaybe<Scalars['Int']>;
  readonly reviewerId: InputMaybe<Scalars['ID']>;
  readonly splitSeconds: Scalars['Float'];
};

export type SpotifyAudienceObject = {
  readonly __typename: 'SpotifyAudienceObject';
  readonly clicks: Scalars['RoundedInt'];
  readonly group: Scalars['String'];
  readonly impressions: Scalars['RoundedInt'];
};

export type SpotifyBrandLiftMessageContentObject = {
  readonly __typename: 'SpotifyBrandLiftMessageContentObject';
  readonly answer1: Maybe<Scalars['String']>;
  readonly answer2: Maybe<Scalars['String']>;
  readonly answer3: Maybe<Scalars['String']>;
  readonly answer4: Maybe<Scalars['String']>;
  readonly answer5: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly languageCode: Maybe<Scalars['String']>;
  readonly question: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum SpotifyBrandLiftMessageMessageType {
  /** Ad Recall */
  AdRecall = 'AD_RECALL',
  /** Aided Brand Awareness */
  AidedBrandAwareness = 'AIDED_BRAND_AWARENESS',
  /** Attribute Rating */
  AttributeRating = 'ATTRIBUTE_RATING',
  /** Favorability */
  Favorability = 'FAVORABILITY',
  /** Message Association */
  MessageAssociation = 'MESSAGE_ASSOCIATION',
  /** Preference */
  Preference = 'PREFERENCE',
  /** Purchase Intent */
  PurchaseIntent = 'PURCHASE_INTENT',
  /** Top of Mind Awareness */
  TopOfMindAwareness = 'TOP_OF_MIND_AWARENESS'
}

export type SpotifyBrandLiftMessageObject = {
  readonly __typename: 'SpotifyBrandLiftMessageObject';
  readonly brandLiftReport: SpotifyBrandLiftReportObject;
  readonly id: Scalars['String'];
  readonly messageContents: ReadonlyArray<SpotifyBrandLiftMessageContentObject>;
  readonly messageType: SpotifyBrandLiftMessageMessageType;
  readonly messageTypeDisplay: Scalars['String'];
  readonly result: Maybe<SpotifyBrandLiftMessageResultObject>;
};


export type SpotifyBrandLiftMessageObjectMessageContentsArgs = {
  languageCode: InputMaybe<Scalars['String']>;
};

export type SpotifyBrandLiftMessageResultObject = {
  readonly __typename: 'SpotifyBrandLiftMessageResultObject';
  readonly age13To17: MessageResultGroupObject;
  readonly age18To24: MessageResultGroupObject;
  readonly age25To34: MessageResultGroupObject;
  readonly age35To44: MessageResultGroupObject;
  readonly age45Plus: MessageResultGroupObject;
  readonly controlAnswer1: Maybe<Scalars['Int']>;
  readonly controlAnswer2: Maybe<Scalars['Int']>;
  readonly controlAnswer3: Maybe<Scalars['Int']>;
  readonly controlAnswer4: Maybe<Scalars['Int']>;
  readonly controlAnswer5: Maybe<Scalars['Int']>;
  readonly exposedAnswer1: Maybe<Scalars['Int']>;
  readonly exposedAnswer2: Maybe<Scalars['Int']>;
  readonly exposedAnswer3: Maybe<Scalars['Int']>;
  readonly exposedAnswer4: Maybe<Scalars['Int']>;
  readonly exposedAnswer5: Maybe<Scalars['Int']>;
  readonly female: MessageResultGroupObject;
  readonly male: MessageResultGroupObject;
  readonly overall: MessageResultGroupObject;
  readonly totalPositive: Scalars['Int'];
  readonly totalResponses: Scalars['Int'];
};

export type SpotifyBrandLiftReportObject = {
  readonly __typename: 'SpotifyBrandLiftReportObject';
  readonly campaigns: ReadonlyArray<CampaignObject>;
  readonly clicks: Maybe<Scalars['Int']>;
  readonly endDate: Maybe<Scalars['Date']>;
  readonly frequency: Maybe<Scalars['Float']>;
  readonly id: Scalars['String'];
  readonly impressions: Maybe<Scalars['Int']>;
  readonly internalLegalStatus: Maybe<Scalars['String']>;
  readonly internalStatus: Maybe<Scalars['String']>;
  readonly isComplete: Scalars['Boolean'];
  readonly isHiddenFromAgency: Scalars['Boolean'];
  readonly isPublished: Scalars['Boolean'];
  readonly messages: ReadonlyArray<SpotifyBrandLiftMessageObject>;
  readonly name: Scalars['String'];
  readonly reach: Maybe<Scalars['Int']>;
  readonly sblSurveyGamId: Scalars['String'];
  readonly startDate: Maybe<Scalars['Date']>;
};

export type SpotifyCampaignImportRequestObject = {
  readonly __typename: 'SpotifyCampaignImportRequestObject';
  readonly advertiser: CompanyObject;
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['String'];
  readonly isEnabled: Scalars['Boolean'];
  readonly spotifyOrderId: Scalars['String'];
};

export type SpotifyOrderObject = {
  readonly __typename: 'SpotifyOrderObject';
  readonly addtocartTotal: Maybe<Scalars['Float']>;
  readonly addtocartTotalModeled: Maybe<Scalars['Float']>;
  readonly brandMappingAttempt: Maybe<CloudsenseBrandMappingAttemptObject>;
  readonly campaigns: ReadonlyArray<CampaignObject>;
  readonly checkoutTotal: Maybe<Scalars['Float']>;
  readonly checkoutTotalModeled: Maybe<Scalars['Float']>;
  readonly cost: Scalars['Float'];
  readonly createdAt: Scalars['DateTime'];
  readonly downloadTotal: Maybe<Scalars['Int']>;
  readonly endAt: Scalars['DateTime'];
  readonly id: Scalars['String'];
  readonly installedTotal: Maybe<Scalars['Float']>;
  readonly installedTotalModeled: Maybe<Scalars['Float']>;
  readonly leadTotal: Maybe<Scalars['Float']>;
  readonly leadTotalModeled: Maybe<Scalars['Float']>;
  readonly leadValue: Maybe<Scalars['Float']>;
  readonly leadValueModeled: Maybe<Scalars['Float']>;
  readonly name: Scalars['String'];
  readonly productTotal: Maybe<Scalars['Float']>;
  readonly productTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseTotal: Maybe<Scalars['Float']>;
  readonly purchaseTotalModeled: Maybe<Scalars['Float']>;
  readonly purchaseValue: Maybe<Scalars['Float']>;
  readonly purchaseValueModeled: Maybe<Scalars['Float']>;
  readonly reachTotal: Maybe<Scalars['Int']>;
  readonly slug: Scalars['String'];
  readonly startAt: Scalars['DateTime'];
  readonly updatedAt: Scalars['DateTime'];
  readonly userTotal: Maybe<Scalars['Float']>;
  readonly userTotalModeled: Maybe<Scalars['Float']>;
  readonly valueTotal: Maybe<Scalars['Float']>;
  readonly valueTotalModeled: Maybe<Scalars['Float']>;
};

export type StartCheckoutSession = {
  readonly __typename: 'StartCheckoutSession';
  readonly stripeCheckoutUrl: Maybe<Scalars['String']>;
  readonly success: Maybe<Scalars['Boolean']>;
};

export type StartCheckoutSessionInput = {
  readonly organizationId: Scalars['ID'];
};

export type StartOveragesCheckout = {
  readonly __typename: 'StartOveragesCheckout';
  readonly overagesCheckoutUrl: Maybe<Scalars['String']>;
  readonly success: Maybe<Scalars['Boolean']>;
};

export type StartOveragesCheckoutInput = {
  readonly organizationId: Scalars['ID'];
  readonly quantity: Scalars['Int'];
};

export type StartPortalSession = {
  readonly __typename: 'StartPortalSession';
  readonly stripePortalUrl: Maybe<Scalars['String']>;
  readonly success: Maybe<Scalars['Boolean']>;
};

export type StartPortalSessionInput = {
  readonly organizationId: Scalars['ID'];
};

export type StreamingApprovalObject = {
  readonly __typename: 'StreamingApprovalObject';
  readonly campaignStreaming: CampaignStreamingObject;
  readonly comment: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly rejectedReason: Maybe<Scalars['String']>;
  readonly requestedAt: Scalars['DateTime'];
  readonly requestedBy: Maybe<UnAuthedUserObject>;
  readonly requestedByOrganization: Maybe<ApprovalOrganization>;
  readonly requestee: Maybe<UnAuthedUserObject>;
  readonly requesteeOrganization: Maybe<ApprovalOrganization>;
  readonly state: StreamingApprovalState;
  readonly updatedAt: Scalars['DateTime'];
  readonly updatedBy: Maybe<UnAuthedUserObject>;
};

/** An enumeration. */
export enum StreamingApprovalState {
  /** Approved */
  Approved = 'APPROVED',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Requested */
  Requested = 'REQUESTED'
}

export type Subscribe = {
  readonly __typename: 'Subscribe';
  readonly channel: Maybe<Scalars['String']>;
  readonly success: Maybe<Scalars['Boolean']>;
};

export type SubscribeInput = {
  readonly campaignEpisodeId: InputMaybe<Scalars['ID']>;
  readonly campaignId: InputMaybe<Scalars['ID']>;
  readonly campaignPodcastId: InputMaybe<Scalars['ID']>;
  readonly episodeId: InputMaybe<Scalars['ID']>;
  readonly podcastId: InputMaybe<Scalars['ID']>;
};

export type SyncUserEmailWithSpotifyAccount = {
  readonly __typename: 'SyncUserEmailWithSpotifyAccount';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type TagObject = {
  readonly __typename: 'TagObject';
  readonly companySet: ReadonlyArray<CompanyObject>;
  readonly id: Scalars['String'];
  readonly label: Scalars['String'];
  readonly productSet: ReadonlyArray<ProductObject>;
  readonly slug: Scalars['String'];
};

export type TargetAcquired = {
  readonly __typename: 'TargetAcquired';
  readonly count: Maybe<Scalars['Float']>;
  readonly day: Maybe<Scalars['DateTime']>;
};

export type Topic = {
  readonly __typename: 'Topic';
  readonly classification: Maybe<Scalars['String']>;
  readonly topic: Maybe<Scalars['String']>;
};

export type UaDeviceObject = {
  readonly __typename: 'UADeviceObject';
  readonly brand: Maybe<Scalars['String']>;
  readonly family: Maybe<Scalars['String']>;
  readonly major: Maybe<Scalars['String']>;
  readonly minor: Maybe<Scalars['String']>;
  readonly model: Maybe<Scalars['String']>;
  readonly patch: Maybe<Scalars['String']>;
  readonly patchMinor: Maybe<Scalars['String']>;
};

export type UaosObject = {
  readonly __typename: 'UAOSObject';
  readonly family: Maybe<Scalars['String']>;
  readonly major: Maybe<Scalars['String']>;
  readonly minor: Maybe<Scalars['String']>;
  readonly patch: Maybe<Scalars['String']>;
  readonly patchMinor: Maybe<Scalars['String']>;
};

export type UnArchiveFeed = {
  readonly __typename: 'UnArchiveFeed';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type UnArchiveFeedInput = {
  readonly feedId: Scalars['ID'];
};

/**
 * We use a small version of the organization object otherwise we
 * expose too much to unauthed users.
 */
export type UnAuthedOrganizationObject = {
  readonly __typename: 'UnAuthedOrganizationObject';
  readonly campaigns: Maybe<ReadonlyArray<Maybe<CampaignObject>>>;
  readonly hasMembers: Maybe<Scalars['Boolean']>;
  readonly id: Maybe<Scalars['ID']>;
  readonly keys: Maybe<ReadonlyArray<Maybe<KeyObject>>>;
  readonly kind: Maybe<Scalars['String']>;
  readonly managers: Maybe<ReadonlyArray<Maybe<UnAuthedOrganizationObject>>>;
  readonly members: Maybe<ReadonlyArray<Maybe<UnAuthedUserObject>>>;
  readonly mode: Maybe<Scalars['String']>;
  readonly name: Maybe<Scalars['String']>;
  readonly slug: Maybe<Scalars['String']>;
};

export type UnAuthedUserObject = {
  readonly __typename: 'UnAuthedUserObject';
  readonly email: Maybe<Scalars['String']>;
  readonly firstName: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly lastName: Maybe<Scalars['String']>;
};

export type UnhideUnmappedSpotifyOrders = {
  readonly __typename: 'UnhideUnmappedSpotifyOrders';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type UnknownAd = {
  readonly __typename: 'UnknownAd';
  readonly ad: Maybe<AdObject>;
};

export type UnknownAdInput = {
  readonly id: Scalars['ID'];
  readonly reviewDuration: InputMaybe<Scalars['Int']>;
  readonly reviewerId: InputMaybe<Scalars['ID']>;
};

export type Unsubscribe = {
  readonly __typename: 'Unsubscribe';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type UnsubscribeInput = {
  readonly channel: Scalars['String'];
};

export type UpdateActionMap = {
  readonly __typename: 'UpdateActionMap';
  readonly actionMap: Maybe<ActionMapObject>;
};

export type UpdateActionMapInput = {
  readonly action: InputMaybe<Scalars['String']>;
  readonly actionMapId: Scalars['ID'];
  readonly eventName: InputMaybe<Scalars['String']>;
};

export type UpdateAd = {
  readonly __typename: 'UpdateAd';
  readonly ad: Maybe<AdObject>;
};

export type UpdateAdInput = {
  readonly assignmentId: InputMaybe<Scalars['String']>;
  readonly companyId: InputMaybe<Scalars['ID']>;
  readonly companyName: Scalars['String'];
  readonly deltas: InputMaybe<Scalars['JSONString']>;
  readonly hitId: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly productId: InputMaybe<Scalars['ID']>;
  readonly productName: InputMaybe<Scalars['String']>;
  readonly productUrl: InputMaybe<Scalars['String']>;
  readonly reviewDuration: InputMaybe<Scalars['Int']>;
  readonly reviewerId: InputMaybe<Scalars['ID']>;
  readonly sponsorId: InputMaybe<Scalars['ID']>;
  readonly sponsorName: InputMaybe<Scalars['String']>;
  readonly workerId: InputMaybe<Scalars['String']>;
};

export type UpdateAdvisorSavedSearch = {
  readonly __typename: 'UpdateAdvisorSavedSearch';
  readonly advisorSavedSearch: Maybe<AdvisorSavedSearchObject>;
};

export type UpdateAdvisorSavedSearchInput = {
  readonly data: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly orgDefault: InputMaybe<Scalars['Boolean']>;
  readonly organizationId: InputMaybe<Scalars['ID']>;
  readonly version: Scalars['Float'];
};

export type UpdateAffinityScores = {
  readonly __typename: 'UpdateAffinityScores';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type UpdateAffinityScoresInput = {
  readonly brandsFileName: InputMaybe<Scalars['String']>;
  readonly publishersFileName: InputMaybe<Scalars['String']>;
};

export type UpdateAllowBrandEditAccess = {
  readonly __typename: 'UpdateAllowBrandEditAccess';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type UpdateAllowBrandEditAccessInput = {
  readonly campaignId: Scalars['ID'];
};

export type UpdateAttributionConfig = {
  readonly __typename: 'UpdateAttributionConfig';
  readonly attributionConfig: Maybe<AttributionConfigObject>;
};

/** Lots of rules about how to create an organization. */
export type UpdateAttributionConfigInput = {
  readonly attributionInterval: Scalars['Int'];
  readonly cableInterval: Scalars['Int'];
  readonly cellularInterval: Scalars['Int'];
  readonly corporateInterval: Scalars['Int'];
  readonly householdInterval: Scalars['Int'];
  readonly maxUsersPerHousehold: Scalars['Int'];
  readonly organizationId: Scalars['ID'];
};

export type UpdateBillingInfo = {
  readonly __typename: 'UpdateBillingInfo';
  readonly billingInfo: Maybe<BillingInfoObject>;
};

export type UpdateBillingInfoInput = {
  readonly city: InputMaybe<Scalars['String']>;
  readonly companyName: InputMaybe<Scalars['String']>;
  readonly contactEmail: InputMaybe<Scalars['String']>;
  readonly email: InputMaybe<Scalars['String']>;
  readonly firstName: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly lastName: InputMaybe<Scalars['String']>;
  readonly phoneNumber: InputMaybe<Scalars['String']>;
  readonly stateOrProvince: InputMaybe<Scalars['String']>;
  readonly streetAddress: InputMaybe<Scalars['String']>;
  readonly taxRegistrationNumber: InputMaybe<Scalars['String']>;
  readonly zipCode: InputMaybe<Scalars['String']>;
};

export type UpdateCampaign = {
  readonly __typename: 'UpdateCampaign';
  readonly campaign: Maybe<CampaignObject>;
};

export type UpdateCampaignApprovals = {
  readonly __typename: 'UpdateCampaignApprovals';
  readonly dynamicApprovals: Maybe<ReadonlyArray<Maybe<DynamicApprovalObject>>>;
  readonly podcastApprovals: Maybe<ReadonlyArray<Maybe<PodcastApprovalObject>>>;
  readonly streamingApprovals: Maybe<ReadonlyArray<Maybe<StreamingApprovalObject>>>;
  readonly success: Scalars['Boolean'];
};

export type UpdateCampaignApprovalsInput = {
  readonly campaignDynamicApprovalUpdates: InputMaybe<ReadonlyArray<InputMaybe<UpdateCampaignDynamicApprovalInput>>>;
  readonly campaignId: Scalars['ID'];
  readonly campaignPodcastApprovalUpdates: InputMaybe<ReadonlyArray<InputMaybe<UpdateCampaignPodcastApprovalInput>>>;
  readonly campaignStreamingApprovalUpdates: InputMaybe<ReadonlyArray<InputMaybe<UpdateCampaignStreamingApprovalInput>>>;
  readonly organizationId: Scalars['ID'];
};

export type UpdateCampaignBrandAgencies = {
  readonly __typename: 'UpdateCampaignBrandAgencies';
  readonly campaigns: Maybe<ReadonlyArray<Maybe<CampaignObject>>>;
  readonly success: Scalars['Boolean'];
};

export type UpdateCampaignBrandAgenciesInput = {
  readonly organizationId: Scalars['ID'];
  readonly updates: InputMaybe<ReadonlyArray<InputMaybe<UpdateCampaignBrandAgencyInput>>>;
};

export type UpdateCampaignBrandAgencyInput = {
  readonly campaignId: Scalars['String'];
  readonly enabled: Scalars['Boolean'];
  readonly organizationId: Scalars['String'];
};

export type UpdateCampaignBroadcast = {
  readonly __typename: 'UpdateCampaignBroadcast';
  readonly campaignBroadcast: Maybe<CampaignBroadcastObject>;
};

export type UpdateCampaignBroadcastInput = {
  readonly cost: InputMaybe<Scalars['Float']>;
  readonly currency: InputMaybe<Scalars['String']>;
  readonly discountCode: InputMaybe<Scalars['String']>;
  readonly discountUrl: InputMaybe<Scalars['String']>;
  readonly duration: InputMaybe<Scalars['Int']>;
  readonly effectiveUrl: InputMaybe<Scalars['String']>;
  readonly goal: InputMaybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly isBaseline: InputMaybe<Scalars['Boolean']>;
  readonly name: InputMaybe<Scalars['String']>;
};

export type UpdateCampaignDates = {
  readonly __typename: 'UpdateCampaignDates';
  readonly campaign: Maybe<CampaignObject>;
};

export type UpdateCampaignDatesInput = {
  readonly endAt: InputMaybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly startAt: InputMaybe<Scalars['DateTime']>;
};

export type UpdateCampaignDynamic = {
  readonly __typename: 'UpdateCampaignDynamic';
  readonly campaignDynamic: Maybe<CampaignDynamicObject>;
};

export type UpdateCampaignDynamicApprovalInput = {
  readonly comment: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly rejectedReason: InputMaybe<Scalars['String']>;
  readonly state: Scalars['String'];
};

export type UpdateCampaignDynamicInput = {
  readonly art19Secret: InputMaybe<Scalars['String']>;
  readonly cost: InputMaybe<Scalars['Float']>;
  readonly currency: InputMaybe<Scalars['String']>;
  readonly discountCode: InputMaybe<Scalars['String']>;
  readonly discountUrl: InputMaybe<Scalars['String']>;
  readonly duration: InputMaybe<Scalars['Int']>;
  readonly dynamicType: InputMaybe<Scalars['String']>;
  readonly effectiveUrl: InputMaybe<Scalars['String']>;
  readonly expectedEndAt: InputMaybe<Scalars['DateTime']>;
  readonly expectedStartAt: InputMaybe<Scalars['DateTime']>;
  readonly goal: InputMaybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly isBaseline: InputMaybe<Scalars['CoercedBoolean']>;
  readonly isMidRoll: InputMaybe<Scalars['CoercedBoolean']>;
  readonly isPostRoll: InputMaybe<Scalars['CoercedBoolean']>;
  readonly isPreRoll: InputMaybe<Scalars['CoercedBoolean']>;
  readonly name: InputMaybe<Scalars['String']>;
  readonly notes: InputMaybe<Scalars['String']>;
  readonly organizationId: InputMaybe<Scalars['ID']>;
  readonly providerName: InputMaybe<Scalars['String']>;
};

export type UpdateCampaignDynamicProvider = {
  readonly __typename: 'UpdateCampaignDynamicProvider';
  readonly campaign: Maybe<CampaignObject>;
};

export type UpdateCampaignDynamicProviderInput = {
  readonly id: Scalars['ID'];
  readonly providerName: InputMaybe<Scalars['String']>;
};

export type UpdateCampaignEpisode = {
  readonly __typename: 'UpdateCampaignEpisode';
  readonly campaignEpisode: Maybe<CampaignEpisodeObject>;
};

export type UpdateCampaignEpisodeInput = {
  readonly episodeId: InputMaybe<Scalars['ID']>;
  readonly futureEpisodeId: InputMaybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly isBaseline: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCampaignInput = {
  readonly cost: InputMaybe<Scalars['Float']>;
  readonly currency: InputMaybe<Scalars['String']>;
  readonly expectedEndAt: InputMaybe<Scalars['DateTime']>;
  readonly expectedStartAt: InputMaybe<Scalars['DateTime']>;
  readonly goLiveDate: InputMaybe<Scalars['Date']>;
  readonly goal: InputMaybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly isHiddenFromAgency: InputMaybe<Scalars['Boolean']>;
  readonly kind: InputMaybe<Scalars['String']>;
  readonly name: InputMaybe<Scalars['String']>;
  readonly payerId: InputMaybe<Scalars['ID']>;
};

export type UpdateCampaignIsNeustar = {
  readonly __typename: 'UpdateCampaignIsNeustar';
  readonly campaign: Maybe<CampaignObject>;
};

export type UpdateCampaignIsNeustarInput = {
  readonly id: Scalars['ID'];
  readonly isNeustar: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCampaignKind = {
  readonly __typename: 'UpdateCampaignKind';
  readonly campaign: Maybe<CampaignObject>;
};

export type UpdateCampaignKindInput = {
  readonly id: Scalars['ID'];
  readonly kind: Scalars['String'];
};

export type UpdateCampaignNoise = {
  readonly __typename: 'UpdateCampaignNoise';
  readonly campaignNoise: Maybe<CampaignNoiseObject>;
};

export type UpdateCampaignNoiseInput = {
  readonly campaignNoiseId: Scalars['ID'];
  readonly endAt: Scalars['Date'];
  readonly name: InputMaybe<Scalars['String']>;
  readonly startAt: Scalars['Date'];
  readonly targetImpressions: InputMaybe<Scalars['Int']>;
  readonly usePublisherImpressions: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCampaignPodcast = {
  readonly __typename: 'UpdateCampaignPodcast';
  readonly campaignPodcast: Maybe<CampaignPodcastObject>;
};

export type UpdateCampaignPodcastApprovalInput = {
  readonly comment: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly rejectedReason: InputMaybe<Scalars['String']>;
  readonly state: Scalars['String'];
};

export type UpdateCampaignPodcastInput = {
  readonly cost: InputMaybe<Scalars['Float']>;
  readonly currency: InputMaybe<Scalars['String']>;
  readonly discountCode: InputMaybe<Scalars['String']>;
  readonly discountUrl: InputMaybe<Scalars['String']>;
  readonly duration: InputMaybe<Scalars['Int']>;
  readonly effectiveUrl: InputMaybe<Scalars['String']>;
  readonly goal: InputMaybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly isMidRoll: InputMaybe<Scalars['CoercedBoolean']>;
  readonly isPostRoll: InputMaybe<Scalars['CoercedBoolean']>;
  readonly isPreRoll: InputMaybe<Scalars['CoercedBoolean']>;
  readonly name: InputMaybe<Scalars['String']>;
  readonly notes: InputMaybe<Scalars['String']>;
  readonly organizationId: InputMaybe<Scalars['ID']>;
};

export type UpdateCampaignState = {
  readonly __typename: 'UpdateCampaignState';
  readonly campaign: Maybe<CampaignObject>;
};

export type UpdateCampaignStateInput = {
  readonly advertiserEmail: InputMaybe<Scalars['String']>;
  readonly advertiserFirstName: InputMaybe<Scalars['String']>;
  readonly advertiserLastName: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly state: InputMaybe<Scalars['String']>;
};

export type UpdateCampaignStreaming = {
  readonly __typename: 'UpdateCampaignStreaming';
  readonly campaignStreaming: Maybe<CampaignStreamingObject>;
};

export type UpdateCampaignStreamingApprovalInput = {
  readonly comment: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly rejectedReason: InputMaybe<Scalars['String']>;
  readonly state: Scalars['String'];
};

export type UpdateCampaignStreamingInput = {
  readonly cost: InputMaybe<Scalars['Float']>;
  readonly currency: InputMaybe<Scalars['String']>;
  readonly discountCode: InputMaybe<Scalars['String']>;
  readonly discountUrl: InputMaybe<Scalars['String']>;
  readonly duration: InputMaybe<Scalars['Int']>;
  readonly effectiveUrl: InputMaybe<Scalars['String']>;
  readonly goal: InputMaybe<Scalars['Int']>;
  readonly id: Scalars['ID'];
  readonly isBaseline: InputMaybe<Scalars['CoercedBoolean']>;
  readonly name: InputMaybe<Scalars['String']>;
  readonly notes: InputMaybe<Scalars['String']>;
  readonly organizationId: InputMaybe<Scalars['ID']>;
  readonly providerName: InputMaybe<Scalars['String']>;
};

export type UpdateCampaignStreamingProvider = {
  readonly __typename: 'UpdateCampaignStreamingProvider';
  readonly campaign: Maybe<CampaignObject>;
};

export type UpdateCampaignStreamingProviderInput = {
  readonly id: Scalars['ID'];
  readonly providerName: InputMaybe<Scalars['String']>;
};

export type UpdateComment = {
  readonly __typename: 'UpdateComment';
  readonly comment: Maybe<CommentObject>;
};

export type UpdateCommentInput = {
  readonly commentId: Scalars['ID'];
  readonly text: Scalars['String'];
};

export type UpdateCompany = {
  readonly __typename: 'UpdateCompany';
  readonly company: Maybe<CompanyObject>;
};

export type UpdateCompanyArchive = {
  readonly __typename: 'UpdateCompanyArchive';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type UpdateCompanyArchiveInput = {
  readonly id: Scalars['ID'];
};

export type UpdateCompanyIndustry = {
  readonly __typename: 'UpdateCompanyIndustry';
  readonly company: Maybe<CompanyObject>;
};

export type UpdateCompanyIndustryInput = {
  readonly id: Scalars['ID'];
  readonly industry: Scalars['String'];
};

export type UpdateCompanyInput = {
  readonly description: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly imageId: InputMaybe<Scalars['ID']>;
  readonly industry: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly organizationId: InputMaybe<Scalars['ID']>;
  readonly slug: Scalars['String'];
  readonly url: InputMaybe<Scalars['String']>;
};

export type UpdateConversionUrl = {
  readonly __typename: 'UpdateConversionURL';
  readonly conversionUrl: Maybe<ConversionUrlObject>;
};

export type UpdateConversionUrlInput = {
  readonly action: Scalars['String'];
  readonly category: InputMaybe<Scalars['String']>;
  readonly conversionUrlId: Scalars['ID'];
  readonly type: InputMaybe<Scalars['String']>;
  readonly url: Scalars['String'];
  readonly value: InputMaybe<Scalars['Float']>;
};

export type UpdateDiscount = {
  readonly __typename: 'UpdateDiscount';
  readonly discount: Maybe<DiscountObject>;
};

export type UpdateDiscountInput = {
  readonly date: InputMaybe<Scalars['Date']>;
  readonly discountId: Scalars['ID'];
  readonly impressions: InputMaybe<Scalars['Int']>;
};

export type UpdateEpisodeAdspottingModelVersion = {
  readonly __typename: 'UpdateEpisodeAdspottingModelVersion';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type UpdateEpisodeAdspottingModelVersionInput = {
  readonly episodeId: Scalars['ID'];
  readonly modelVersion: Scalars['String'];
};

export type UpdateEpisodeAsrStatus = {
  readonly __typename: 'UpdateEpisodeAsrStatus';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type UpdateEpisodeAsrStatusInput = {
  readonly episodeId: Scalars['ID'];
  readonly state: Scalars['Int'];
  readonly status: Scalars['String'];
};

export type UpdateFeedContactEmail = {
  readonly __typename: 'UpdateFeedContactEmail';
  readonly feed: Maybe<FeedObject>;
  readonly success: Scalars['Boolean'];
};

export type UpdateFeedContactEmailInput = {
  readonly contactEmail: Scalars['String'];
  readonly feedId: Scalars['ID'];
  readonly organizationId: Scalars['ID'];
};

/** Force an update of all the attributes on the feeds. */
export type UpdateFeeds = {
  readonly __typename: 'UpdateFeeds';
  readonly feeds: Maybe<ReadonlyArray<Maybe<FeedObject>>>;
};

export type UpdateFeedsInput = {
  readonly feedIds: ReadonlyArray<InputMaybe<Scalars['ID']>>;
};

export type UpdateFinancial = {
  readonly __typename: 'UpdateFinancial';
  readonly financial: Maybe<FinancialObject>;
};

export type UpdateFinancialInput = {
  readonly annualImpressionsLimit: InputMaybe<Scalars['Int']>;
  readonly contractEndDate: InputMaybe<Scalars['DateTime']>;
  readonly contractStartDate: InputMaybe<Scalars['DateTime']>;
  readonly contractValue: InputMaybe<Scalars['Float']>;
  readonly cpmAttribution: InputMaybe<Scalars['Float']>;
  readonly cpmOverages: InputMaybe<Scalars['Float']>;
  readonly cpmStreaming: InputMaybe<Scalars['Float']>;
  readonly financialId: Scalars['ID'];
  readonly financialStatus: InputMaybe<Scalars['String']>;
  readonly hasOverages: InputMaybe<Scalars['Boolean']>;
  readonly impressions: InputMaybe<Scalars['Int']>;
  readonly isAnnual: InputMaybe<Scalars['Boolean']>;
  readonly isChartable: InputMaybe<Scalars['Boolean']>;
  readonly isMonthly: InputMaybe<Scalars['Boolean']>;
  readonly isTieredCpm: InputMaybe<Scalars['Boolean']>;
  readonly monthlyRate: InputMaybe<Scalars['Float']>;
  readonly paymentType: InputMaybe<Scalars['String']>;
  readonly signUpDate: InputMaybe<Scalars['DateTime']>;
};

export type UpdateGenericTag = {
  readonly __typename: 'UpdateGenericTag';
  readonly genericTag: Maybe<GenericTagObject>;
};

export type UpdateGenericTagInput = {
  readonly color: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly title: InputMaybe<Scalars['String']>;
};

export type UpdateInvoice = {
  readonly __typename: 'UpdateInvoice';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type UpdateInvoiceInput = {
  readonly due: InputMaybe<Scalars['String']>;
  readonly invoiceId: Scalars['ID'];
  readonly paid: InputMaybe<Scalars['String']>;
  readonly sent: InputMaybe<Scalars['String']>;
};

export type UpdateIsController = {
  readonly __typename: 'UpdateIsController';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type UpdateIsControllerInput = {
  readonly isController: Scalars['Boolean'];
  readonly orgId: Scalars['ID'];
};

export type UpdateKey = {
  readonly __typename: 'UpdateKey';
  readonly key: Maybe<KeyObject>;
};

export type UpdateKeyInput = {
  readonly alias: InputMaybe<Scalars['String']>;
  readonly domain: InputMaybe<Scalars['String']>;
  readonly installations: InputMaybe<Scalars['String']>;
  readonly key: Scalars['ID'];
};

export type UpdateLiftReport = {
  readonly __typename: 'UpdateLiftReport';
  readonly liftReport: Maybe<LiftReportObject>;
};

export type UpdateLiftReportInput = {
  readonly clampUrl: InputMaybe<Scalars['String']>;
  readonly delta: InputMaybe<Scalars['Int']>;
  readonly impressionsEndAt: InputMaybe<Scalars['Date']>;
  readonly impressionsStartAt: InputMaybe<Scalars['Date']>;
  readonly liftReportId: Scalars['ID'];
  readonly name: InputMaybe<Scalars['String']>;
  readonly state: InputMaybe<Scalars['String']>;
  readonly useNoise: InputMaybe<Scalars['Boolean']>;
};

export type UpdateLinkingRequest = {
  readonly __typename: 'UpdateLinkingRequest';
  readonly requests: Maybe<ReadonlyArray<Maybe<LinkingRequestObject>>>;
};

export type UpdateLinkingRequestInput = {
  readonly linkingRequestIds: ReadonlyArray<InputMaybe<Scalars['String']>>;
  readonly state: Scalars['String'];
};

export type UpdateMegaphoneBundle = {
  readonly __typename: 'UpdateMegaphoneBundle';
  readonly financial: Maybe<FinancialObject>;
};

export type UpdateMegaphoneBundleInput = {
  readonly contractEndDate: Scalars['DateTime'];
  readonly contractStartDate: Scalars['DateTime'];
  readonly financialId: Scalars['ID'];
  readonly isMegaphone: Scalars['Boolean'];
};

export type UpdateMember = {
  readonly __typename: 'UpdateMember';
  readonly membership: Maybe<MembershipObject>;
};

export type UpdateMemberInput = {
  readonly firstName: InputMaybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly lastName: InputMaybe<Scalars['String']>;
  readonly role: Scalars['String'];
};

export type UpdateOrganization = {
  readonly __typename: 'UpdateOrganization';
  readonly organization: Maybe<OrganizationObject>;
};

export type UpdateOrganizationInput = {
  readonly attributionCpm: InputMaybe<Scalars['Float']>;
  readonly billingEmail: InputMaybe<Scalars['String']>;
  readonly currency: InputMaybe<Scalars['String']>;
  readonly dynamicProvider: InputMaybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly invoiceDaysUntilDue: InputMaybe<Scalars['Int']>;
  readonly isAdvisor: InputMaybe<Scalars['Boolean']>;
  readonly isExperianApproved: InputMaybe<Scalars['Boolean']>;
  readonly isNielsenApproved: InputMaybe<Scalars['Boolean']>;
  readonly isSegmentsEnabled: InputMaybe<Scalars['Boolean']>;
  readonly kind: InputMaybe<Scalars['String']>;
  readonly knowledgeLevel: InputMaybe<Scalars['String']>;
  readonly location: InputMaybe<Scalars['String']>;
  readonly name: InputMaybe<Scalars['String']>;
  readonly note: InputMaybe<Scalars['String']>;
  readonly streamingProvider: InputMaybe<Scalars['String']>;
  readonly tier: InputMaybe<Scalars['String']>;
};

export type UpdatePixelEventRecord = {
  readonly __typename: 'UpdatePixelEventRecord';
  readonly pixelEventRecord: Maybe<PixelEventRecordObject>;
};

export type UpdatePixelEventRecordInput = {
  readonly category: InputMaybe<Scalars['String']>;
  readonly currency: InputMaybe<Scalars['String']>;
  readonly discountCode: InputMaybe<Scalars['String']>;
  readonly eventType: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly installation: InputMaybe<Scalars['String']>;
  readonly name: InputMaybe<Scalars['String']>;
  readonly newCustomer: InputMaybe<Scalars['String']>;
  readonly orderId: InputMaybe<Scalars['String']>;
  readonly productId: InputMaybe<Scalars['String']>;
  readonly productName: InputMaybe<Scalars['String']>;
  readonly productType: InputMaybe<Scalars['String']>;
  readonly productVendor: InputMaybe<Scalars['String']>;
  readonly quantity: InputMaybe<Scalars['String']>;
  readonly type: InputMaybe<Scalars['String']>;
  readonly value: InputMaybe<Scalars['String']>;
  readonly variantId: InputMaybe<Scalars['String']>;
  readonly variantName: InputMaybe<Scalars['String']>;
};

export type UpdatePodlinkDomain = {
  readonly __typename: 'UpdatePodlinkDomain';
  readonly podlinkDomain: Maybe<PodlinkDomainObject>;
};

export type UpdatePodlinkDomainInput = {
  readonly description: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly organizationId: Scalars['ID'];
  readonly podcastIds: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  readonly slug: Scalars['ID'];
  readonly title: InputMaybe<Scalars['String']>;
};

/** We do this serverside to send special headers. */
export type UpdatePodlinkPodcast = {
  readonly __typename: 'UpdatePodlinkPodcast';
  readonly success: Maybe<Scalars['Boolean']>;
};

export type UpdatePodlinkPodcastInput = {
  readonly backgroundColor: Scalars['String'];
  readonly fontColor: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly podcastId: Scalars['ID'];
  readonly primaryColor: Scalars['String'];
  readonly secondaryColor: Scalars['String'];
  readonly slug: Scalars['String'];
};

export type UpdateSavedExport = {
  readonly __typename: 'UpdateSavedExport';
  readonly savedExport: Maybe<SavedExportObject>;
  readonly success: Scalars['Boolean'];
};

export type UpdateSavedExportInput = {
  readonly exportParameters: InputMaybe<ExportInstanceParameters>;
  readonly exportType: InputMaybe<Scalars['String']>;
  readonly fields: InputMaybe<ReadonlyArray<InputMaybe<FieldsInputObject>>>;
  readonly id: Scalars['ID'];
  readonly name: InputMaybe<Scalars['String']>;
  readonly orderBy: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly slug: InputMaybe<Scalars['String']>;
};

export type UpsertPlacements = {
  readonly __typename: 'UpsertPlacements';
  readonly placements: Maybe<ReadonlyArray<Maybe<PlacementObject>>>;
};

export type UpsertPlacementsInput = {
  readonly assignmentId: InputMaybe<Scalars['String']>;
  readonly episodeId: Scalars['ID'];
  readonly hitId: InputMaybe<Scalars['String']>;
  readonly placements: ReadonlyArray<InputMaybe<PlacementInput>>;
  readonly proxiedEnclosureId: InputMaybe<Scalars['ID']>;
  readonly reviewDuration: InputMaybe<Scalars['Int']>;
  readonly reviewerId: InputMaybe<Scalars['ID']>;
  readonly workerId: InputMaybe<Scalars['String']>;
};

export type UserAccessObject = {
  readonly __typename: 'UserAccessObject';
  readonly appLabel: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly model: Maybe<Scalars['String']>;
  readonly objectPk: Scalars['String'];
  readonly permission: Maybe<Scalars['String']>;
  readonly permissionDisplay: Maybe<Scalars['String']>;
  readonly user: LimitedUserObject;
};

export type UserAgent = {
  readonly __typename: 'UserAgent';
  readonly device: Maybe<UaDeviceObject>;
  readonly os: Maybe<UaosObject>;
  readonly userAgent: Scalars['String'];
  readonly value: Maybe<Scalars['String']>;
};

export type UserInfoObject = {
  readonly __typename: 'UserInfoObject';
  readonly email: Maybe<Scalars['String']>;
  readonly hasSpaaUser: Maybe<Scalars['Boolean']>;
  readonly hasVerifiedEmailForSpaa: Maybe<Scalars['Boolean']>;
};

export type UserObject = {
  readonly __typename: 'UserObject';
  readonly adAnalyticsTutorialProgress: Scalars['Int'];
  readonly advisorSavedSearches: ReadonlyArray<AdvisorSavedSearchObject>;
  readonly agencySearch: ReadonlyArray<AgencySearchObject>;
  readonly benchmarkReport: Maybe<BenchmarkReportObject>;
  readonly campaignDynamicsDelivered: Maybe<ReadonlyArray<Maybe<CampaignDynamicDeliveryObject>>>;
  readonly campaignStreamingsDelivered: Maybe<ReadonlyArray<Maybe<CampaignStreamingDeliveryObject>>>;
  readonly claims: ReadonlyArray<CompanyClaimObject>;
  readonly comments: ReadonlyArray<CommentObject>;
  readonly companySearch: ReadonlyArray<CompanySearchObject>;
  readonly contactsContacted: ReadonlyArray<ContactObject>;
  readonly contactsIgnored: ReadonlyArray<ContactObject>;
  readonly createdAt: Scalars['DateTime'];
  readonly createdGenericTagRelationships: ReadonlyArray<GenericTagRelationshipObject>;
  readonly createdGenericTags: ReadonlyArray<GenericTagObject>;
  readonly dynamicApprovals: ReadonlyArray<DynamicApprovalObject>;
  readonly email: Scalars['String'];
  readonly emailHash: Scalars['String'];
  readonly financialRecords: ReadonlyArray<FinancialObject>;
  readonly firstName: Scalars['String'];
  readonly fullName: Scalars['String'];
  readonly hasSeenAdAnalyticsWelcome: Scalars['Boolean'];
  readonly hasVerifiedEmailForSpaa: Scalars['Boolean'];
  readonly id: Scalars['String'];
  readonly industries: ReadonlyArray<IndustryObject>;
  readonly isActive: Scalars['Boolean'];
  readonly isAdmin: Scalars['Boolean'];
  readonly isServiceAccount: Scalars['Boolean'];
  readonly lastLogin: Maybe<Scalars['DateTime']>;
  readonly lastName: Scalars['String'];
  readonly liftReportRequests: Maybe<ReadonlyArray<Maybe<LiftReportObject>>>;
  readonly linkingRequests: Maybe<ReadonlyArray<Maybe<LinkingRequestObject>>>;
  readonly linkingrequestSet: ReadonlyArray<LinkingRequestObject>;
  readonly machineLearning: Maybe<MachineLearningObject>;
  readonly memberships: ReadonlyArray<MembershipObject>;
  readonly organization: OrganizationObject;
  readonly organizations: ReadonlyArray<OrganizationObject>;
  readonly pixel: Maybe<KeyObject>;
  readonly pixelEventRecords: ReadonlyArray<PixelEventRecordObject>;
  readonly podcastApprovals: ReadonlyArray<PodcastApprovalObject>;
  readonly reviewer: Maybe<ReviewerObject>;
  readonly spotifyUserId: Scalars['String'];
  readonly streamingApprovals: ReadonlyArray<StreamingApprovalObject>;
  readonly updatedAt: Scalars['DateTime'];
  readonly useraccessSet: ReadonlyArray<UserAccessObject>;
};


export type UserObjectAgencySearchArgs = {
  query: Scalars['String'];
};


export type UserObjectBenchmarkReportArgs = {
  quarter: InputMaybe<Scalars['String']>;
};


export type UserObjectCompanySearchArgs = {
  query: Scalars['String'];
  requireActivePixel: InputMaybe<Scalars['Boolean']>;
};


export type UserObjectDynamicApprovalsArgs = {
  campaignId: InputMaybe<Scalars['ID']>;
  state: InputMaybe<Scalars['String']>;
};


export type UserObjectLinkingRequestsArgs = {
  state: InputMaybe<Scalars['String']>;
};


export type UserObjectOrganizationArgs = {
  id: InputMaybe<Scalars['ID']>;
  slug: InputMaybe<Scalars['String']>;
};


export type UserObjectOrganizationsArgs = {
  ids: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  service: InputMaybe<Scalars['String']>;
};


export type UserObjectPixelArgs = {
  id: Scalars['ID'];
};


export type UserObjectPodcastApprovalsArgs = {
  campaignId: InputMaybe<Scalars['ID']>;
  state: InputMaybe<Scalars['String']>;
};


export type UserObjectStreamingApprovalsArgs = {
  campaignId: InputMaybe<Scalars['ID']>;
  state: InputMaybe<Scalars['String']>;
};

export type SearchFeedsQueryVariables = Exact<{
  query: Scalars['String'];
  state: InputMaybe<Scalars['String']>;
}>;


export type SearchFeedsQuery = { readonly __typename: 'Query', readonly admin: { readonly __typename: 'Admin', readonly feedSearch: ReadonlyArray<{ readonly __typename: 'FeedObject', readonly id: string, readonly title: string, readonly subtitle: string | null, readonly href: string } | null> | null } | null };

export type SearchProductsQueryVariables = Exact<{
  query: Scalars['String'];
  companyId: Scalars['ID'];
}>;


export type SearchProductsQuery = { readonly __typename: 'Query', readonly productSearch: ReadonlyArray<{ readonly __typename: 'ProductSearchObject', readonly id: string | null, readonly name: string | null, readonly slug: string | null } | null> | null };

export type CreateAssetMutationVariables = Exact<{
  input: CreateAssetInput;
}>;


export type CreateAssetMutation = { readonly __typename: 'Mutation', readonly authed: { readonly __typename: 'Authed', readonly success: boolean | null } | null, readonly createAsset: { readonly __typename: 'CreateAsset', readonly asset: { readonly __typename: 'AssetObject', readonly id: string, readonly name: string, readonly contentType: string, readonly width: number | null, readonly height: number | null, readonly size: number, readonly src: string | null } | null } | null };

export type CreateImageMutationVariables = Exact<{
  input: CreateImageInput;
}>;


export type CreateImageMutation = { readonly __typename: 'Mutation', readonly authed: { readonly __typename: 'Authed', readonly success: boolean | null } | null, readonly createImage: { readonly __typename: 'CreateImage', readonly image: { readonly __typename: 'MediaImageObject', readonly id: string, readonly contentType: string, readonly width: number, readonly height: number, readonly size: number, readonly path: string } | null } | null };

export type SignMediaMutationVariables = Exact<{
  input: SignMediaInput;
}>;


export type SignMediaMutation = { readonly __typename: 'Mutation', readonly authed: { readonly __typename: 'Authed', readonly success: boolean | null } | null, readonly signMedia: { readonly __typename: 'SignMedia', readonly success: boolean | null, readonly id: string | null, readonly signedUrl: string | null, readonly path: string | null } | null };

export type GetEnterQueryVariables = Exact<{
  token: InputMaybe<Scalars['String']>;
}>;


export type GetEnterQuery = { readonly __typename: 'Query', readonly me: { readonly __typename: 'UserObject', readonly id: string, readonly firstName: string, readonly lastName: string, readonly email: string } | null };

export type GetMeQueryVariables = Exact<{
  service: InputMaybe<Scalars['String']>;
}>;


export type GetMeQuery = { readonly __typename: 'Query', readonly userInfo: { readonly __typename: 'UserInfoObject', readonly email: string | null, readonly hasSpaaUser: boolean | null, readonly hasVerifiedEmailForSpaa: boolean | null } | null, readonly me: { readonly __typename: 'UserObject', readonly id: string, readonly firstName: string, readonly lastName: string, readonly email: string, readonly emailHash: string, readonly createdAt: any, readonly isAdmin: boolean, readonly hasSeenAdAnalyticsWelcome: boolean, readonly adAnalyticsTutorialProgress: number, readonly reviewer: { readonly __typename: 'ReviewerObject', readonly id: string, readonly firstName: string | null, readonly lastName: string | null, readonly email: string | null, readonly type: ReviewerType } | null, readonly organizations: ReadonlyArray<{ readonly __typename: 'OrganizationObject', readonly id: string, readonly isLockedForTerms: boolean, readonly name: string, readonly slug: string, readonly kind: OrganizationKind, readonly isAdvisor: boolean, readonly isSegmentsEnabled: boolean }> } | null };

export type IndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type IndustriesQuery = { readonly __typename: 'Query', readonly me: { readonly __typename: 'UserObject', readonly industries: ReadonlyArray<{ readonly __typename: 'IndustryObject', readonly industry: string | null, readonly industryDisplay: string | null }> } | null };

export type PublisherSearchQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  query: Scalars['String'];
}>;


export type PublisherSearchQuery = { readonly __typename: 'Query', readonly me: { readonly __typename: 'UserObject', readonly id: string, readonly organization: { readonly __typename: 'OrganizationObject', readonly id: string, readonly publisherSearch: { readonly __typename: 'PublisherSearch', readonly count: number | null, readonly results: ReadonlyArray<{ readonly __typename: 'PublisherSearchObject', readonly id: string | null, readonly name: string | null, readonly slug: string | null } | null> | null } } } | null };


export const SearchFeedsDocument = gql`
    query searchFeeds($query: String!, $state: String) {
  admin {
    feedSearch(query: $query, state: $state) {
      id
      title
      subtitle
      href
    }
  }
}
    `;

/**
 * __useSearchFeedsQuery__
 *
 * To run a query within a React component, call `useSearchFeedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFeedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFeedsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useSearchFeedsQuery(baseOptions: Apollo.QueryHookOptions<SearchFeedsQuery, SearchFeedsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchFeedsQuery, SearchFeedsQueryVariables>(SearchFeedsDocument, options);
      }
export function useSearchFeedsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchFeedsQuery, SearchFeedsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchFeedsQuery, SearchFeedsQueryVariables>(SearchFeedsDocument, options);
        }
export type SearchFeedsQueryHookResult = ReturnType<typeof useSearchFeedsQuery>;
export type SearchFeedsLazyQueryHookResult = ReturnType<typeof useSearchFeedsLazyQuery>;
export type SearchFeedsQueryResult = Apollo.QueryResult<SearchFeedsQuery, SearchFeedsQueryVariables>;
export const SearchProductsDocument = gql`
    query searchProducts($query: String!, $companyId: ID!) {
  productSearch(query: $query, companyId: $companyId) {
    id
    name
    slug
  }
}
    `;

/**
 * __useSearchProductsQuery__
 *
 * To run a query within a React component, call `useSearchProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSearchProductsQuery(baseOptions: Apollo.QueryHookOptions<SearchProductsQuery, SearchProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchProductsQuery, SearchProductsQueryVariables>(SearchProductsDocument, options);
      }
export function useSearchProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProductsQuery, SearchProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchProductsQuery, SearchProductsQueryVariables>(SearchProductsDocument, options);
        }
export type SearchProductsQueryHookResult = ReturnType<typeof useSearchProductsQuery>;
export type SearchProductsLazyQueryHookResult = ReturnType<typeof useSearchProductsLazyQuery>;
export type SearchProductsQueryResult = Apollo.QueryResult<SearchProductsQuery, SearchProductsQueryVariables>;
export const CreateAssetDocument = gql`
    mutation createAsset($input: CreateAssetInput!) {
  authed {
    success
  }
  createAsset(input: $input) {
    asset {
      id
      name
      contentType
      width
      height
      size
      src
    }
  }
}
    `;
export type CreateAssetMutationFn = Apollo.MutationFunction<CreateAssetMutation, CreateAssetMutationVariables>;

/**
 * __useCreateAssetMutation__
 *
 * To run a mutation, you first call `useCreateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetMutation, { data, loading, error }] = useCreateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssetMutation, CreateAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(CreateAssetDocument, options);
      }
export type CreateAssetMutationHookResult = ReturnType<typeof useCreateAssetMutation>;
export type CreateAssetMutationResult = Apollo.MutationResult<CreateAssetMutation>;
export type CreateAssetMutationOptions = Apollo.BaseMutationOptions<CreateAssetMutation, CreateAssetMutationVariables>;
export const CreateImageDocument = gql`
    mutation createImage($input: CreateImageInput!) {
  authed {
    success
  }
  createImage(input: $input) {
    image {
      id
      contentType
      width
      height
      size
      path
    }
  }
}
    `;
export type CreateImageMutationFn = Apollo.MutationFunction<CreateImageMutation, CreateImageMutationVariables>;

/**
 * __useCreateImageMutation__
 *
 * To run a mutation, you first call `useCreateImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImageMutation, { data, loading, error }] = useCreateImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateImageMutation(baseOptions?: Apollo.MutationHookOptions<CreateImageMutation, CreateImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateImageMutation, CreateImageMutationVariables>(CreateImageDocument, options);
      }
export type CreateImageMutationHookResult = ReturnType<typeof useCreateImageMutation>;
export type CreateImageMutationResult = Apollo.MutationResult<CreateImageMutation>;
export type CreateImageMutationOptions = Apollo.BaseMutationOptions<CreateImageMutation, CreateImageMutationVariables>;
export const SignMediaDocument = gql`
    mutation signMedia($input: SignMediaInput!) {
  authed {
    success
  }
  signMedia(input: $input) {
    success
    id
    signedUrl
    path
  }
}
    `;
export type SignMediaMutationFn = Apollo.MutationFunction<SignMediaMutation, SignMediaMutationVariables>;

/**
 * __useSignMediaMutation__
 *
 * To run a mutation, you first call `useSignMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signMediaMutation, { data, loading, error }] = useSignMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignMediaMutation(baseOptions?: Apollo.MutationHookOptions<SignMediaMutation, SignMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignMediaMutation, SignMediaMutationVariables>(SignMediaDocument, options);
      }
export type SignMediaMutationHookResult = ReturnType<typeof useSignMediaMutation>;
export type SignMediaMutationResult = Apollo.MutationResult<SignMediaMutation>;
export type SignMediaMutationOptions = Apollo.BaseMutationOptions<SignMediaMutation, SignMediaMutationVariables>;
export const GetEnterDocument = gql`
    query getEnter($token: String) {
  me(token: $token) {
    id
    firstName
    lastName
    email
  }
}
    `;

/**
 * __useGetEnterQuery__
 *
 * To run a query within a React component, call `useGetEnterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnterQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetEnterQuery(baseOptions?: Apollo.QueryHookOptions<GetEnterQuery, GetEnterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnterQuery, GetEnterQueryVariables>(GetEnterDocument, options);
      }
export function useGetEnterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnterQuery, GetEnterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnterQuery, GetEnterQueryVariables>(GetEnterDocument, options);
        }
export type GetEnterQueryHookResult = ReturnType<typeof useGetEnterQuery>;
export type GetEnterLazyQueryHookResult = ReturnType<typeof useGetEnterLazyQuery>;
export type GetEnterQueryResult = Apollo.QueryResult<GetEnterQuery, GetEnterQueryVariables>;
export const GetMeDocument = gql`
    query getMe($service: String) {
  userInfo {
    email
    hasSpaaUser
    hasVerifiedEmailForSpaa
    __typename
  }
  me {
    __typename
    id
    firstName
    lastName
    email
    emailHash
    createdAt
    isAdmin
    hasSeenAdAnalyticsWelcome
    adAnalyticsTutorialProgress
    reviewer {
      id
      firstName
      lastName
      email
      type
    }
    organizations(service: $service) {
      id
      isLockedForTerms
      name
      slug
      kind
      isAdvisor
      isSegmentsEnabled
    }
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *      service: // value for 'service'
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const IndustriesDocument = gql`
    query Industries {
  me {
    industries {
      industry
      industryDisplay
    }
  }
}
    `;

/**
 * __useIndustriesQuery__
 *
 * To run a query within a React component, call `useIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<IndustriesQuery, IndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndustriesQuery, IndustriesQueryVariables>(IndustriesDocument, options);
      }
export function useIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndustriesQuery, IndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndustriesQuery, IndustriesQueryVariables>(IndustriesDocument, options);
        }
export type IndustriesQueryHookResult = ReturnType<typeof useIndustriesQuery>;
export type IndustriesLazyQueryHookResult = ReturnType<typeof useIndustriesLazyQuery>;
export type IndustriesQueryResult = Apollo.QueryResult<IndustriesQuery, IndustriesQueryVariables>;
export const PublisherSearchDocument = gql`
    query PublisherSearch($organizationId: ID!, $query: String!) {
  me {
    id
    organization(id: $organizationId) {
      id
      publisherSearch(query: $query, applyFilters: true) {
        count
        results {
          id
          name
          slug
        }
      }
    }
  }
}
    `;

/**
 * __usePublisherSearchQuery__
 *
 * To run a query within a React component, call `usePublisherSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherSearchQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function usePublisherSearchQuery(baseOptions: Apollo.QueryHookOptions<PublisherSearchQuery, PublisherSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublisherSearchQuery, PublisherSearchQueryVariables>(PublisherSearchDocument, options);
      }
export function usePublisherSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublisherSearchQuery, PublisherSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublisherSearchQuery, PublisherSearchQueryVariables>(PublisherSearchDocument, options);
        }
export type PublisherSearchQueryHookResult = ReturnType<typeof usePublisherSearchQuery>;
export type PublisherSearchLazyQueryHookResult = ReturnType<typeof usePublisherSearchLazyQuery>;
export type PublisherSearchQueryResult = Apollo.QueryResult<PublisherSearchQuery, PublisherSearchQueryVariables>;