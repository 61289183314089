import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Companies from './Companies';
import CompanyIndex from './company';

export default function CompaniesIndex() {
  return (
    <Switch>
      <Route exact path='/companies' component={Companies} />
      <Route path='/companies/:companyId' component={CompanyIndex} />
    </Switch>
  );
}
