import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Invoices from './Invoices';
import InvoiceIndex from './invoice';

export default function () {
  return (
    <Switch>
      <Route exact path='/invoices' component={Invoices} />
      <Route path='/invoices/:invoiceId' component={InvoiceIndex} />
    </Switch>
  );
}
