import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["$colorSet"];
import { cssBorderRadius, cssColorValue, cssSpacing, defaultColorSetName, defaultTheme, rootStyle, semanticColors, transitionFade } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
export var Wrapper = styled.div.withConfig({
  displayName: "Wrapper",
  componentId: "sc-62m9tu-0"
})(["", ""], function (_ref) {
  var $colorSet = _ref.$colorSet,
    props = _objectWithoutProperties(_ref, _excluded);
  var fallbackSet = defaultTheme[$colorSet || defaultColorSetName];
  var backgroundColor = $colorSet ? cssColorValue(semanticColors.backgroundBase, fallbackSet.background.base) : cssColorValue(semanticColors.backgroundTintedBase, fallbackSet.background.tinted.base);
  return css(["", ";position:relative;display:flex;align-items:flex-start;justify-content:", ";background-color:", ";color:", ";padding-block-start:", ";padding-block-end:", ";padding-inline-start:", ";padding-inline-end:", ";transition:background ", ";", ""], rootStyle(), props.$align === 'center' ? 'center' : 'space-between', backgroundColor, cssColorValue(semanticColors.textBase, fallbackSet.text.base), cssSpacing('tighter', '12px'), cssSpacing('tighter', '12px'), cssSpacing('looser', '16px'), props.$showClose ? cssSpacing('looser-4', '48px') : cssSpacing('base', '16px'), transitionFade, props.$contextual && css(["border-radius:", ";"], cssBorderRadius('base', '4px')));
});