import React from 'react';
import CreateReviewer from '@admin/graphql-api/_old_mutations/CreateReviewer';
import {graphql} from '@apollo/client/react/hoc';
import {Link, Redirect} from 'react-router-dom';
import {Element} from '@core/style';
import {Input, InputError, Label} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import Select from '@core/ui/Select';

class ReviewerCreate extends React.Component {
  state = {
    loading: false,
    redirect: false,
    error: false,
    email: '',
    firstName: '',
    lastName: '',
    type: 'episode',
  };

  onChange = ({target: {name, value}}) => {
    const update = {error: false};
    update[name] = value;
    this.setState(update);
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const {createReviewer} = this.props;
    const {email, firstName, lastName, type} = this.state;

    if (!email || !firstName || !lastName) {
      return;
    }

    this.setState({
      loading: true,
    });

    createReviewer({email, firstName, lastName, type})
      .then(({data: {createReviewer}}) => {
        if (createReviewer.reviewer) {
          setTimeout(() => {
            this.setState({
              loading: false,
              redirect: true,
            });
          }, 50);
        } else {
          this.setState({
            loading: false,
            error: true,
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  };

  render() {
    const {loading, email, firstName, lastName, redirect, error} = this.state;

    if (redirect) {
      return <Redirect to='/reviewers' />;
    }

    return (
      <ResponsiveContent>
        <form onSubmit={this.onSubmit}>
          <Label>Email Address</Label>
          <Input
            type='email'
            name='email'
            value={email}
            onChange={this.onChange}
            placeholder='Email Address'
            style-error={error}
            disabled={loading}
          />

          {error ? (
            <InputError>
              user already exists
              <Link to='/reviewers/login'>Login</Link>
            </InputError>
          ) : (
            ''
          )}

          <Label>First Name</Label>
          <Input
            type='text'
            name='firstName'
            value={firstName}
            onChange={this.onChange}
            placeholder='Jane'
            style-error={error}
            disabled={loading}
          />

          <Label>Last Name</Label>
          <Input
            type='text'
            name='lastName'
            value={lastName}
            onChange={this.onChange}
            placeholder='Smith'
            style-error={error}
            disabled={loading}
          />
          <Label>Type</Label>
          <Select
            items={[
              {title: 'Only Episodes', value: 'episode'},
              {title: 'Only Ads', value: 'ad'},
              {title: 'Both Episodes and Ads', value: 'all'},
            ]}
            defaultValue='episode'
            onSelect={({title, value}) => {
              this.setState({
                type: value,
              });
            }}
          />

          <Element rules={() => ({padding: '20px 0', textAlign: 'right'})}>
            <Submit variant='outlined' value='Create' disabled={loading} />
          </Element>
        </form>
      </ResponsiveContent>
    );
  }
}

export default graphql(CreateReviewer, {
  props: ({mutate}) => ({
    createReviewer: (input) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
  }),
})(ReviewerCreate);
