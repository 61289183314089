import { defaultTheme, rootStyle, zIndexDialogBackdrop, zIndexPreviewBackdrop } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
export var staticSet = 'overMedia';
var fallbackBackground = defaultTheme[staticSet].background.unsafeForSmallText.highlight;
export var OverlayBackdrop = styled.div.attrs({
  role: 'presentation'
}).withConfig({
  displayName: "OverlayBackdrop",
  componentId: "sc-1vbts8w-0"
})(["", ";background:var(--background-unsafe-for-small-text-highlight,", ");height:100%;left:0;position:fixed;top:0;width:100%;z-index:", ";", ";.docs-story &{position:absolute;z-index:", ";}"], rootStyle(), fallbackBackground, zIndexDialogBackdrop, function (props) {
  return props.center && css(["align-items:center;display:flex;justify-content:center;"]);
}, zIndexPreviewBackdrop);