import React from 'react';
import {TypographyOptions} from '@mui/material/styles/createTypography';

export const typography = {
  typography: (): TypographyOptions => ({
    allVariants: {
      fontFamily: 'var(--font-family-base)',
    },
    fontSize: 16,
    h1: {
      fontFamily: 'var(--font-family-title)',
      fontSize: 'var(--font-size-h1)',
      fontWeight: 'var(--font-weight-extrabold)',
      lineHeight: 'var(--line-height-xs)',
    },
    h2: {
      fontFamily: 'var(--font-family-title)',
      fontSize: 'var(--font-size-h2)',
      fontWeight: 'var(--font-weight-bold)',
      lineHeight: 'var(--line-height-sm)',
    },
    h3: {
      fontFamily: 'var(--font-family-title)',
      fontSize: 'var(--font-size-h3)',
      fontWeight: 'var(--font-weight-bold)',
      lineHeight: 'var(--line-height-md)',
    },
    h4: {
      fontFamily: 'var(--font-family-title)',
      fontSize: 'var(--font-size-h4)',
      fontWeight: 'var(--font-weight-medium)',
      lineHeight: 'var(--line-height-md)',
    },
    subtitle1: {
      fontSize: 'var(--font-size-body-1)',
      fontWeight: 'var(--font-weight-medium)',
      lineHeight: 'var(--line-height-md)',
    },
    subtitle2: {
      fontSize: 'var(--font-size-body-2)',
      fontWeight: 'var(--font-weight-medium)',
      lineHeight: 'var(--line-height-lg)',
    },
    body1: {
      fontSize: 'var(--font-size-body-1)',
      fontWeight: 'var(--font-weight-regular)',
      lineHeight: 'var(--line-height-md)',
    },
    body2: {
      fontSize: 'var(--font-size-body-2)',
      fontWeight: 'var(--font-weight-regular)',
      lineHeight: 'var(--line-height-lg)',
    },
    body3: {
      fontSize: 'var(--font-size-body-3)',
      fontWeight: 'var(--font-weight-regular)',
      lineHeight: 'var(--line-height-xl)',
    },
    overline: {
      fontSize: 'var(--font-size-body-4)',
      fontWeight: 'var(--font-weight-medium)',
      lineHeight: 'var(--line-height-md)',
      letterSpacing: '0.0313rem',
      textTransform: 'uppercase',
    },
  }),
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1: React.CSSProperties;
    h2: React.CSSProperties;
    h3: React.CSSProperties;
    h4: React.CSSProperties;
    body1: React.CSSProperties;
    body2: React.CSSProperties;
    body3: React.CSSProperties;
    subtitle1: React.CSSProperties;
    subtitle2: React.CSSProperties;
    overline: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1: React.CSSProperties;
    h2: React.CSSProperties;
    h3: React.CSSProperties;
    h4: React.CSSProperties;
    body1: React.CSSProperties;
    body2: React.CSSProperties;
    body3: React.CSSProperties;
    subtitle1: React.CSSProperties;
    subtitle2: React.CSSProperties;
    overline: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    h4: true;
    body1: true;
    body2: true;
    body3: true;
    subtitle1: true;
    subtitle2: true;
    overline: true;
    // Disabled
    h5: false;
    h6: false;
    button: false;
    body: false;
    caption: false;
  }
}
