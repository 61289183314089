import React, {Children} from 'react';
import styled from 'styled-components';
import {RouteType} from '@core/lib/useRoutes';
import {exists} from '@core/lib/utils';
import {FelaElement} from '@core/style';
import {Breadcrumb, Breadcrumbs} from '../Breadcrumbs';
import {DropdownMenu, DropdownMenuItemType} from '../DropdownMenu';
import {OverlayProps} from '../Overlay';
import {NavItem} from './NavItem';
import {_NavHeader} from './_NavHeader';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type NavItem =
  | {
      exact?: boolean;
      isActive?: (_: {path: string}) => boolean;
      title: string;
      to?: string;
    }
  | RouteType;

export interface NavProps {
  breadcrumbs?: Array<Readonly<Breadcrumb>>;
  children?: React.ReactNode;
  description?: string | React.ReactNode;
  Image?: React.FC;
  items?: Readonly<NavItem>[];
  moreDropdownItems?: Readonly<DropdownMenuItemType>[];
  moreDropdownOverlayProps?: OverlayProps;
  onDropdownSubmit?: (value: any) => void;
  contentAfterTitle?: React.ReactNode;
  title?: Readonly<string | Array<{title: string; to?: string}>>;
  titleRightContent?: React.ReactNode;
}

export const Nav = ({
  breadcrumbs,
  children,
  contentAfterTitle,
  description,
  Image,
  items = [],
  moreDropdownItems = [],
  moreDropdownOverlayProps,
  onDropdownSubmit,
  title,
  titleRightContent,
}: NavProps) => {
  const hasMenuItems =
    Children.count(children) > 0 ||
    items.length > 0 ||
    moreDropdownItems.length > 0;

  return (
    <>
      {!!breadcrumbs && (
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          css={`
            margin: 0 var(--spacing-6) 0;
            padding: 0;
          `}
        />
      )}
      <_Root
        css={{
          margin: !hasMenuItems
            ? 'var(--spacing-4) var(--spacing-6)'
            : breadcrumbs
            ? 'var(--spacing-4) var(--spacing-6) var(--spacing-8)'
            : 'var(--spacing-4) var(--spacing-6) var(--spacing-8)',
        }}>
        <_NavHeader
          title={title}
          titleRightContent={titleRightContent}
          description={description}
          Image={Image}
        />
        {contentAfterTitle}
        {hasMenuItems && (
          <_Nav aria-orientation='horizontal'>
            {items.map((item) => (
              <NavItem
                key={item.to}
                role='menuitem'
                to={item.to}
                isActive={isPathActive(item)}>
                {item.title}
              </NavItem>
            ))}
            {children}
            {moreDropdownItems.length > 0 && (
              <_MoreDropdownMenu
                items={moreDropdownItems}
                onSubmit={onDropdownSubmit}
                overlayProps={{
                  verticalOffset: 4,
                  ...moreDropdownOverlayProps,
                }}>
                More ...
              </_MoreDropdownMenu>
            )}
          </_Nav>
        )}
      </_Root>
    </>
  );
};

const _Root = styled(FelaElement)``;

const _Nav = styled.nav`
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: var(--spacing-2);
  margin-left: -10px;
`;

const _MoreDropdownMenu = styled(DropdownMenu)`
  color: var(--text-muted);
  line-height: 1rem;
  padding: 0 var(--spacing-3);
  :hover {
    color: var(--text-primary);
  }
`;

const isPathActive = (item: NavItem) => {
  return (
    item.isActive ||
    (item.exact || !exists(item.to)
      ? undefined
      : ({path}) => path.indexOf(item.to!) === 0)
  );
};
