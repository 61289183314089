import {ElementType} from 'react';
import {
  type TypographyProps as MuiTypographyProps,
  Typography,
} from '@mui/material';

export type TextVariant = MuiTypographyProps['variant'];
export type TextAlign = MuiTypographyProps['align'];
export type TextColor =
  | 'default'
  | 'subtle'
  | 'muted'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'info'
  | 'success'
  | 'placeholder'
  | 'disabled'
  | 'tooltip';

export type TextProps = Partial<
  Pick<
    MuiTypographyProps,
    'align' | 'gutterBottom' | 'noWrap' | 'paragraph' | 'children'
  > & {
    as: ElementType<any>;
    color: TextColor;
    variant: TextVariant;
  }
>;

export const Text = ({as = 'div', color = 'default', ...props}: TextProps) => (
  <Typography
    component={as}
    style={{color: `var(--text-${color})`}}
    {...props}
  />
);
