import React from 'react';
import {
  AutocompleteOwnerState,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  InputAdornment,
  TextField,
} from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {Icon} from '../Icon';
import {ListItem} from '../List';

export function defaultRenderOption<T>(getLabel: (_: any) => string) {
  return function (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    {
      inputValue,
      selected,
    }: {
      inputValue: string;
      selected: boolean;
    }
  ) {
    const matches = match(getLabel(option), inputValue);
    const parts = parse(getLabel(option), matches);

    return (
      <ListItem {...props}>
        <div>
          {parts.map(({text, highlight}, i) => (
            <span
              key={`${text}|${i}`}
              style={{fontWeight: highlight ? 500 : 400}}>
              {text}
            </span>
          ))}
        </div>
        {selected && <SelectedIcon />}
      </ListItem>
    );
  };
}

export function SelectedIcon() {
  return (
    <InputAdornment position='end' style={{color: 'inherit'}}>
      <Icon icon='checkmark' />
    </InputAdornment>
  );
}

export function defaultRenderInput(label?: string, placeholder?: string) {
  return (params: AutocompleteRenderInputParams) => (
    <TextField {...params} label={label} placeholder={placeholder} />
  );
}

export function defaultRenderTags<T>(getLabel: (_: T) => string) {
  return (
    tags: T[],
    _: AutocompleteRenderGetTagProps,
    _0: AutocompleteOwnerState<any, any, any, any, any>
  ) => (
    <span
      css={`
        padding-top: 7.5px;
        padding-left: 6px;
      `}>
      {tags.map(getLabel).map((label, i) => (
        <>
          <span key={`${label}|${i}`}>{label}</span>
          {i < tags.length - 1 ? ', ' : ''}
        </>
      ))}
    </span>
  );
}

export function defaultGetOptionLabel<T extends string | Record<string, any>>(
  option: T
): string {
  return typeof option === 'string' ? option : option.label;
}
