import { cssColorValue, cssSpacing, cursorDisabled, finale, mesto, opacityDisabled, semanticColors } from "../../styles/index.js";
import styled from "../../styled-components.js";
export var Label = styled.span.withConfig({
  displayName: "Label",
  componentId: "sc-1sbwovc-0"
})(["color:", ";", " padding-inline-start:", ";padding-inline-end:", ";min-inline-size:0;overflow-wrap:break-word;input:disabled ~ &&{cursor:", ";opacity:", ";}"], cssColorValue(semanticColors.textBase), function (_ref) {
  var size = _ref.size;
  switch (size) {
    case 'small':
      return finale();
    default:
      return mesto();
  }
}, cssSpacing('tighter', '12px'), cssSpacing('looser', '24px'), cursorDisabled, opacityDisabled);