import React from 'react';
import UserQuery from '@admin/graphql-api/_old_queries/UserQuery';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import {mapOrgKindToPathPart} from '@core/lib/organizations';
import {Element} from '@core/style';
import DataTable from '@core/ui/DataTable';
import {GraphErrors} from '@core/ui/Form';
import Loading from '@core/ui/Loading';

class User extends React.Component {
  state = {
    loading: false,
    complete: false,
    errors: [],
  };

  render() {
    const {data} = this.props;
    const {loading, errors} = this.state;

    if (data.loading) {
      return <Loading absolute />;
    }

    const {id, firstName, lastName, email, organizations} = data.admin.user;

    return (
      <Element>
        <GraphErrors
          errors={errors}
          onClose={() => {
            this.setState({
              errors: [],
            });
          }}
        />

        <p>
          User {firstName} {lastName} - ({email})
        </p>

        <Element
          rules={() => ({
            textAlign: 'right',
          })}
        />

        <Element>
          <DataTable
            data={organizations}
            columns={[
              {
                title: 'Name',
                accessor: 'name',
                type: 'string',
              },
              {
                title: 'Kind',
                accessor: 'kind',
                type: 'string',
              },
              {
                title: 'View',
                accessor: 'slug',
                type: 'string',

                Cell: ({data: {kind, slug}}) => {
                  return kind !== 'research' ? (
                    <a
                      href={`https://dash.podsights.com/${mapOrgKindToPathPart({
                        kind,
                      })}/${slug}`}>
                      View in Dashboard
                    </a>
                  ) : (
                    ''
                  );
                },
              },
            ]}
            orderBy='-name'
          />
        </Element>
      </Element>
    );
  }
}

export default compose(
  graphql(UserQuery, {
    options: ({
      match: {
        params: {userId},
      },
    }) => ({
      variables: {
        id: userId,
      },
    }),
  })
)(User);
