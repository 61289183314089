import Select from '@core/ui/Select';
import {SelectProps} from '@core/ui/Select/Select';
import {useIndustriesQuery} from '../../graphql-api';

type IndustrySelectProps = Omit<SelectProps, 'items'>;

const IndustrySelect = ({
  placeholder,
  disabled,
  rules,
  ...props
}: IndustrySelectProps): JSX.Element => {
  const {data, loading, error} = useIndustriesQuery();

  return (
    <Select
      key={`industry-${loading}`}
      disabled={disabled || loading || !!error}
      items={data?.me?.industries || []}
      outline
      placeholder={placeholder || 'Select Industry'}
      propertyForName='industryDisplay'
      propertyForValue='industry'
      searchable
      searchKeys={['industry', 'industryDisplay']}
      rules={[
        () => ({
          padding: '.8125rem 1rem',
          width: '100%',
        }),
        rules,
      ]}
      {...props}
    />
  );
};

export default IndustrySelect;
