import {Helmet} from 'react-helmet-async';
import {Redirect, Route, Switch} from 'react-router-dom';
import Logout from '@core/app/Logout';
import {Element, ThemeProvider} from '@core/style';
import {CleanLink} from '@core/ui/Link';
import favicon from '../static/favicon.png';
import BackdatedIndex from './backdated';
import CampaignsIndex from './campaigns';
import ClaimsIndex from './claims';
import CompaniesIndex from './companies';
import ContactsIndex from './contacts';
import EpisodelessIndex from './episodeless';
import FeedsIndex from './feeds';
import FuturesIndex from './futures';
import InvoicesIndex from './invoices';
import LiftIndex from './lift';
import NotificationsIndex from './notifications';
import OrganizationsIndex from './organizations';
import PrefixlessIndex from './prefixless';
import QueueIndex from './queue';
import ReviewersIndex from './reviewers';
import ReviewsIndex from './reviews';
import SetupRequestsIndex from './setup-requests';
import UsersIndex from './users';

const AdminHome = () => <Redirect to='/campaigns' />;

const Separator = () => (
  <Element
    rules={() => ({
      background: 'rgba(255,255,255,0.16)',
      height: '0.0625rem',
      margin: '1rem 0',
    })}
  />
);

const NavLink = ({children, to, ...props}) => {
  const active = window.location.pathname.match(to);

  return (
    <Element>
      <CleanLink
        to={to}
        css={`
          display: block;
          padding: 0.25rem 1.5rem 0.25rem;
          text-decoration: none;
          color: ${active ? 'var(--text-default)' : '#dde2ff'};
          background: ${active ? 'var(--pink)' : ''};
          font-weight: ${active ? 500 : ''};
          :hover {
            background: ${active ? 'var(--pink)' : '#596de6'};
            color: ${active ? 'var(--text-default)' : '#dde2ff'};
          }
        `}
        {...props}>
        {children}
      </CleanLink>
    </Element>
  );
};

const Nav = ({me}) => (
  <Element
    rules={() => ({
      background: 'var(--blue)',
      bottom: 0,
      fontSize: '0.9375rem',
      left: 0,
      padding: '1.5rem 0',
      position: 'fixed',
      top: 0,
      width: '15rem',
    })}>
    <Element
      rules={() => ({
        alignItems: 'start',
        color: 'var(--white)',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '0.9rem',
        fontWeight: 500,
        padding: '0 1.5rem 0',
      })}>
      <Element
        rules={() => ({
          flex: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
        })}>
        {me.email}
      </Element>
      <CleanLink
        to='/logout'
        css={`
          color: var(--white);
          :hover {
            color: var(--pink);
          }
        `}>
        Sign out
      </CleanLink>
    </Element>
    <Separator />
    <NavLink to='/campaigns'>
      <Emoji>📣</Emoji>Campaigns
    </NavLink>
    <NavLink to='/feeds'>
      <Emoji>🎤</Emoji>Feeds
    </NavLink>
    <NavLink to='/lift'>
      <Emoji>📊</Emoji>Lift
    </NavLink>
    <NavLink to='/prefixless'>
      <Emoji>🚨</Emoji>Prefixless
    </NavLink>
    <Separator />
    <NavLink to='/companies'>
      <Emoji>🏭</Emoji>Companies
    </NavLink>
    <NavLink to='/organizations'>
      <Emoji>🏴‍☠️</Emoji>Organizations
    </NavLink>
    <NavLink to='/users'>
      <Emoji>👩‍🦰</Emoji>Users
    </NavLink>
    <NavLink to='/reviewers'>
      <Emoji>🔍</Emoji>Reviewers
    </NavLink>
    <NavLink to='/notifications'>
      <Emoji>🔔</Emoji>Notifications
    </NavLink>
    <Separator />

    <NavLink to='/episodeless'>
      <Emoji>🤷‍♀️</Emoji>Episodeless
    </NavLink>
    <NavLink to='/backdated'>
      <Emoji>🔙</Emoji>Backdated
    </NavLink>

    <NavLink to='/futures'>
      <Emoji>🔮</Emoji>Future Episodes
    </NavLink>

    <Separator />
    <NavLink to='/contacts'>
      <Emoji>📫</Emoji>Inbox
    </NavLink>
    <NavLink to='/claims'>
      <Emoji>🧲</Emoji>Claims
    </NavLink>
    <NavLink to='/invoices'>
      <Emoji>💵</Emoji>Invoices
    </NavLink>
    <NavLink to='/queue'>
      <Emoji>⏱</Emoji>Queue
    </NavLink>
    <NavLink to='/setup-requests'>
      <Emoji>🧾</Emoji>Set-up Requests
    </NavLink>
  </Element>
);

const ContentContainer = ({children}) => (
  <Element
    rules={() => ({
      background: 'white',
      minHeight: '100vh',
      padding: '0 0 0 15rem',
    })}>
    <Element
      rules={() => ({
        padding: '1rem',
        position: 'relative',
        minHeight: '100vh',
      })}>
      {children}
    </Element>
  </Element>
);

const Emoji = ({children}) => (
  <Element
    rules={() => ({
      display: 'inline-block',
      fontSize: '1rem',
      marginRight: '1rem',
      minWidth: '1.5rem',
      textAlign: 'center',
    })}>
    {children}
  </Element>
);

const routes = [
  {exact: true, path: '/', Component: AdminHome},
  {path: '/backdated', Component: BackdatedIndex},
  {path: '/campaigns', Component: CampaignsIndex},
  {path: '/claims', Component: ClaimsIndex},
  {path: '/companies', Component: CompaniesIndex},
  {path: '/contacts', Component: ContactsIndex},
  {path: '/episodeless', Component: EpisodelessIndex},
  {path: '/feeds', Component: FeedsIndex},
  {path: '/futures', Component: FuturesIndex},
  {path: '/invoices', Component: InvoicesIndex},
  {path: '/lift', Component: LiftIndex},
  {path: '/notifications', Component: NotificationsIndex},
  {path: '/organizations', Component: OrganizationsIndex},
  {path: '/prefixless', Component: PrefixlessIndex},
  {path: '/queue', Component: QueueIndex},
  {path: '/reviewers', Component: ReviewersIndex},
  {path: '/reviews', Component: ReviewsIndex},
  {path: '/users', Component: UsersIndex},
  {path: '/logout', Component: Logout},
  {path: '/setup-requests', Component: SetupRequestsIndex},
  {path: '/enter/:slug', Component: () => <Redirect to='/' />},
];

const AdminIndex = ({me}) => {
  return (
    <ThemeProvider theme='light'>
      <Helmet>
        <meta charSet='UTF-8' />
        <title>Admin | Podsights</title>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link rel='icon' type='image/png' href={favicon} />
      </Helmet>
      <Switch>
        {routes.map(({exact = false, path, Component}) => (
          <Route
            key={path}
            exact={exact}
            path={path}
            render={(routeProps) => {
              return (
                <ContentContainer>
                  <Nav me={me} />
                  <Component user={me} {...routeProps} />
                </ContentContainer>
              );
            }}
          />
        ))}
      </Switch>
    </ThemeProvider>
  );
};

export default AdminIndex;
