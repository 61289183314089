import React from 'react';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import dayjs from '@core/lib/dayjs';
import {Element} from '@core/style';
import {ToastMessage} from '@core/ui/Alert';
import {Card} from '@core/ui/Content';
import {DateRangeInput} from '@core/ui/DatePicker';
import {Label} from '@core/ui/FormElements';
import RangeBackfill from './RangeBackfill';

class BackfillIndex extends React.Component {
  onDatesChange = ({startDate, endDate}) => {
    const {organization} = this.props;

    this.props.history.push(
      `/organizations/${organization.id}/backfill/${startDate.format(
        'YYYY-MM-DD'
      )}/${endDate.format('YYYY-MM-DD')}`
    );
  };

  render() {
    const {
      organization,
      match: {
        params: {start, end},
      },
    } = this.props;

    const hardStart = dayjs('2019-09-01', 'YYYY-MM-DD');
    const hardEnd = dayjs().subtract(1, 'days');

    return (
      <>
        <ToastMessage>
          Please don't touch anything on this page unless you are absolutely
          sure you know what you are doing.
        </ToastMessage>
        <Card rules={() => ({marginTop: '1.5rem'})}>
          <Label>Range:</Label>
          <DateRangeInput
            onDatesChange={this.onDatesChange}
            startDate={dayjs(start)}
            endDate={dayjs(end)}
            isOutsideRange={(date) => {
              return !(
                date.isSameOrAfter(hardStart) && date.isSameOrBefore(hardEnd)
              );
            }}
          />
          <Element rules={() => ({marginTop: '1rem'})}>
            This tool is to help fix broken campaigns or refill data into pre.
            Everything operates on the range that you select here.
          </Element>
        </Card>
        <Switch>
          <PropsRoute
            exact
            path='/organizations/:organizationId/backfill/:start/:end'
            organization={organization}
            after={dayjs(start)}
            before={dayjs(end).clone().add(1, 'days')}
            component={RangeBackfill}
          />
        </Switch>
      </>
    );
  }
}

export default BackfillIndex;
