import {FC, MouseEvent, ReactNode, useState} from 'react';
import {getDocumentSelection, isDescendant} from '@core/lib/utils';
import {css} from '@core/style';
import Checkbox from '../Checkbox';
import {
  DataTableBulkAction,
  DataTableColumn,
  DataTablePropertyForKey,
  DataTableRowRuleFn,
  DataTableValuesMap,
} from './types';

interface DataTableBodyRowProps
  extends Omit<DataTableBodyProps, 'currentPageData' | 'valuesMap'> {
  d: {[key: string]: any};
  valuesMap?: DataTableValuesMap;
  _key: string;
}

const DataTableBodyRow: FC<DataTableBodyRowProps> = ({
  _key,
  bulkActions = [],
  columns,
  d,
  history,
  onClickRowPath,
  propertyForKey,
  rowRuleFn,
  selectedRows,
  setSelectedRows,
  valuesMap,
}): JSX.Element => {
  const [active, setActive] = useState(false);
  const path = onClickRowPath && onClickRowPath(d);

  const onClick = path
    ? (evt: MouseEvent<HTMLTableRowElement>): void => {
        const {currentTarget, target, ctrlKey, metaKey} = evt;
        if (
          // Fixing scope bug
          !isDescendant(currentTarget, target) ||
          // Handle text selection
          getDocumentSelection()
        ) {
          return;
        }
        if (ctrlKey || metaKey) {
          window.open(path, '_blank');
        } else if (path.indexOf('http') === 0) {
          window.location.href = path;
        } else if (history) {
          history.push(path);
        }
      }
    : null;

  const selected =
    (selectedRows ?? []).indexOf(d) > -1 ||
    (selectedRows ?? [])
      .map((row) =>
        typeof propertyForKey === 'function'
          ? propertyForKey({d: row})
          : row[propertyForKey]
      )
      .indexOf(_key) > -1;

  return (
    <tr
      onClick={(e) => onClick?.(e)}
      {...css([
        path
          ? () => ({
              cursor: 'pointer',
              ':hover': {
                background: 'var(--bg-surface-hover)',
              },
              ':hover td:first-child': {
                background: 'var(--bg-surface-hover)',
              },
            })
          : null,
        // TODO: Remove theme from rowRuleFn
        rowRuleFn ? ({theme}: any) => rowRuleFn({data: d, d, theme}) : null,
      ])}>
      {bulkActions.length > 0 && (
        <th
          {...{onClick: (evt) => evt.stopPropagation()}}
          css={{
            borderBottom: '0.0625rem solid var(--table-border-row)',
            padding: '0.75rem 1rem',
            verticalAlign: 'middle',
          }}>
          <Checkbox
            key={`${selected}-${_key}`}
            defaultValue={selected}
            onChecked={(checked) => {
              if (checked) {
                if (!selected) {
                  setSelectedRows?.([...(selectedRows ?? []), d]);
                }
              }
            }}
            rules={() => ({display: 'inline'})}
          />
        </th>
      )}
      {columns.map((column, i) => {
        const {
          accessor,
          alignRight,
          bgColor,
          borderColor,
          Cell,
          rule,
          textColor,
          type,
          width,
          fmt,
        } = column;
        const shouldAlignRight =
          typeof alignRight === 'boolean' ? alignRight : type === 'number';
        const value = valuesMap ? valuesMap.get(column)?.get(d) : d[accessor];

        let content: ReactNode = null;

        if (Cell) {
          content = (
            <Cell data={d} d={d} value={value} toggleActiveRow={setActive} />
          );
        } else {
          content = fmt ? fmt(value) : value;
        }

        return (
          <td
            key={`${accessor}|${_key}|${i}`}
            width={width}
            {...css([
              () => ({
                background: active
                  ? 'var(--bg-surface-hover) !important'
                  : bgColor ?? 'initial',
                borderBottom: `0.0625rem solid ${
                  borderColor || 'var(--table-border-row)'
                }`,
                color: textColor ?? 'var(--text-muted)',
                padding: '0.875rem 0.625rem',
                textAlign: shouldAlignRight ? 'right' : 'initial',
                whiteSpace:
                  shouldAlignRight || type === 'date' ? 'nowrap' : 'initial',
              }),
              rule,
            ])}>
            {content}
          </td>
        );
      })}
    </tr>
  );
};

interface DataTableBodyProps {
  bulkActions?: DataTableBulkAction[];
  columns: DataTableColumn[];
  currentPageData: {[key: string]: any}[];
  history: any;
  onClickRowPath?: (_row: object) => string;
  propertyForKey: DataTablePropertyForKey;
  rowRuleFn?: DataTableRowRuleFn;
  selectedRows?: any[];
  setSelectedRows?: (_rows: string[]) => void;
  valuesMap?: DataTableValuesMap;
}

const DataTableBody: FC<DataTableBodyProps> = ({
  bulkActions,
  columns,
  currentPageData,
  history,
  onClickRowPath,
  propertyForKey,
  rowRuleFn,
  selectedRows,
  setSelectedRows,
  valuesMap,
}) => {
  if (currentPageData.length) {
    return (
      <tbody>
        {currentPageData.map((d, i) => {
          const key =
            typeof propertyForKey === 'function'
              ? propertyForKey({d})
              : d[propertyForKey];
          return (
            <DataTableBodyRow
              _key={`${key}|${i}`}
              bulkActions={bulkActions}
              columns={columns}
              d={d}
              history={history}
              key={key}
              onClickRowPath={onClickRowPath}
              propertyForKey={propertyForKey}
              rowRuleFn={rowRuleFn}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              valuesMap={valuesMap}
            />
          );
        })}
      </tbody>
    );
  }
  return null;
};

export default DataTableBody;
