import {ToastMessage} from '@core/ui/Alert';
import {Card, CardHeader} from '@core/ui/Content';
import DataTable, {DataTableColumn} from '@core/ui/DataTable';
import {Link} from '@core/ui/Link';
import {CardStat} from '@core/ui/Stat';
import {CompanyType} from './_types';

const columns: DataTableColumn[] = [
  {
    title: 'Product',
    accessor: 'name',
    type: 'string',
  },
  {
    title: 'Ads Count',
    accessor: 'adsCount',
    type: 'number',
  },
];

interface Props {
  company: CompanyType;
}

export default function Company({company}: Props) {
  return (
    <div>
      {company.archived && (
        <ToastMessage alertType='warning'>
          This company is archived.
        </ToastMessage>
      )}
      {company.organization ? (
        <>
          <CardHeader>Organization</CardHeader>
          <Card rules={() => ({marginBottom: '1.5rem'})}>
            <Link to={`/organizations/${company.organization.id}`}>
              {company.organization.name} ({company.organization.slug})
            </Link>
          </Card>
        </>
      ) : null}
      <div
        css={`
          display: grid;
          grid-gap: 1rem;
          grid-template-columns: repeat(4, 1fr);
          margin: 1rem 1.5rem;
        `}>
        <CardStat value={company.feedsCount} title='Feeds' />
        <CardStat value={company.episodesCount} title='Episodes' />
        <CardStat value={company.productsCount} title='Products' />
        <CardStat value={company.adsCount} title='Ads' />
      </div>
      <Card>
        <CardHeader>Products</CardHeader>
        <DataTable
          columns={columns}
          data={company.products}
          searchKeys={['name']}
          searchPlaceholder='Search by product name'
          orderBy='-adsCount'
          onClickRowPath={({id}) => `/companies/${company.id}/products/${id}`}
        />
      </Card>
    </div>
  );
}
