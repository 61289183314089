import React, {useMemo, useRef} from 'react';
import dayjs from '@core/lib/dayjs';
import {TRules} from '@core/style';
import {Element} from '@core/style';
import {Icon} from '../Icon';
import {Overlay, useOverlay} from '../Overlay';
import SegmentedControls from '../SegmentedControls';
import DatePicker from './DatePicker';

interface IDateRangeBarProps {
  endDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
  minDate?: dayjs.Dayjs;
  onDatesChange: (_dates: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) => void;
  rules: TRules;
  startDate?: dayjs.Dayjs;
}

const DateRangeBar = ({
  endDate,
  maxDate = dayjs(),
  minDate,
  onDatesChange,
  rules,
  startDate,
  ...props
}: IDateRangeBarProps) => {
  const today = dayjs();
  const scRef = useRef<HTMLDivElement>(null);
  const [openedOverlay, toggleOverlay] = useOverlay(false);

  const handleDatesChange = (dates) => {
    if (onDatesChange && dates[0] && dates[1]) {
      onDatesChange({startDate: dates[0], endDate: dates[1]});
    }
  };

  const onScChange = ({title, startDate, endDate}) => {
    if (title === 'custom') {
      toggleOverlay(true);
    } else {
      toggleOverlay(false);

      if (onDatesChange) {
        onDatesChange({startDate, endDate});
      }
    }
  };

  const isOutsideRange = (date) => {
    return !(date.isSameOrAfter(minDate) && date.isSameOrBefore(maxDate));
  };

  const menuItems = useMemo(
    () => [
      {
        title: '7D',
        startDate: dayjs().subtract(7, 'd'),
        endDate: today,
      },
      {
        title: '1M',
        startDate: dayjs().subtract(1, 'M'),
        endDate: today,
      },
      {
        title: '3M',
        startDate: dayjs().subtract(3, 'M'),
        endDate: today,
      },
      {
        title: 'All',
        startDate: minDate,
        endDate: today,
      },
      {
        title: 'custom',
        render: () => <Icon icon='date-alternative' />,
      },
    ],
    []
  );

  return (
    <Element rules={rules} {...props}>
      <SegmentedControls
        ref={scRef}
        propertyForValue='title'
        items={menuItems}
        onChange={onScChange}
        segmentWidth={42}
      />
      <Overlay
        opened={openedOverlay}
        toggle={toggleOverlay}
        positionTarget={scRef.current}
        verticalAlign='bottom'
        horizontalAlign='right'
        verticalOffset={2}
        withBackdrop
        transparentBackdrop
        withShadow
        css={`
          max-width: initial;
          padding: 1rem;
        `}>
        <DatePicker
          isOutsideRange={isOutsideRange}
          onDatesChange={handleDatesChange}
          defaultDates={[startDate, endDate]}
          twoMonthsView
        />
      </Overlay>
    </Element>
  );
};

export default DateRangeBar;
