import React, {useState} from 'react';
import {forwardRef} from 'react';
import styled from 'styled-components';
import {useTheme} from '@core/style';

export interface SegmentedControlsItem {
  title?: string;
  value?: string;
  render?: () => React.ReactNode;
  [key: string]: any;
}

export interface SegmentedControlsProps {
  defaultValue?: string;
  items: SegmentedControlsItem[];
  onChange?: (_item: SegmentedControlsItem) => void;
  /**
   * The property of the item to use as the value.
   */
  propertyForValue?: string;
  /**
   * The width of each segment. Each have the same width.
   */
  segmentWidth?: number;
  size?: 'small' | 'medium';
}

export default forwardRef<HTMLDivElement, SegmentedControlsProps>(
  function SegmentedControls(
    {
      defaultValue,
      items = [],
      onChange,
      propertyForValue = 'value',
      segmentWidth = 124,
      size = 'small',
      ...props
    }: SegmentedControlsProps,
    ref
  ) {
    const theme = useTheme();
    const [selectedValue, setSelectedValue] = useState<string>(
      typeof defaultValue !== 'undefined'
        ? defaultValue
        : items[0][propertyForValue]
    );
    const selectedIndex = items.indexOf(
      items.find((item) => item[propertyForValue] === selectedValue)!
    );

    return (
      <_Root ref={ref} $themeName={theme} $size={size} {...props}>
        {items.map((item) => (
          <_SegmentText
            key={item[propertyForValue]}
            $segmentWidth={segmentWidth}
            onClick={() => {
              setSelectedValue(item[propertyForValue]);

              if (onChange) {
                onChange(item);
              }
            }}
            css={
              selectedValue === item[propertyForValue]
                ? `color: var(--text-default);`
                : ``
            }>
            {typeof item.render === 'function' ? item.render() : item.title}
          </_SegmentText>
        ))}
        <_SegmentBg $segmentWidth={segmentWidth} $selectedIndex={selectedIndex}>
          <_SegmentBgInner $themeName={theme} />
        </_SegmentBg>
      </_Root>
    );
  }
);

const _Root = styled.div<{
  $themeName: string;
  $size: SegmentedControlsProps['size'];
}>`
  align-items: center;
  background: var(--bg-default);
  border: var(--input-border);
  border-radius: var(--input-border-radius-md);
  color: var(--text-muted);
  cursor: pointer;
  display: inline-flex;
  font-size: ${(p) => (p.$size === 'small' ? '0.875rem' : '1rem')};
  font-weight: 500;
  height: ${(p) => (p.$size === 'small' ? '2.75rem' : '3.5rem')};
  position: relative;
  user-select: none;
`;

const _SegmentText = styled.div<{$segmentWidth: number}>`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: ${(p) => `${p.$segmentWidth}px`};
  z-index: 1;
`;

const _SegmentBg = styled.div<{$segmentWidth: number; $selectedIndex: number}>`
  bottom: 0;
  left: 0;
  padding: 0.125rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: ${(p) => `translateX(${p.$selectedIndex * p.$segmentWidth}px)`};
  transition: transform 0.2s cubic-bezier(0.22, 1, 0.36, 1);
  width: ${(p) => `${p.$segmentWidth}px`};
`;

const _SegmentBgInner = styled.div<{$themeName: string}>`
  background: var(--bg-muted);
  border-radius: var(--input-border-radius-md);
  border: 1px solid var(--border-default);
  height: 100%;
  width: 100%;
`;
