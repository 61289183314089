import React from 'react';
import {Icon} from '../Icon';
import {ButtonProps} from './Button';

type ButtonChildrenProps = Pick<
  ButtonProps,
  'iconLeading' | 'iconTrailing' | 'children' | 'buttonSize'
>;

export const ButtonChildren = ({
  buttonSize,
  children,
  iconLeading,
  iconTrailing,
}: ButtonChildrenProps) => {
  const _size = buttonSize === 'sm' ? '1.25rem' : '1.5rem';
  const _margin = buttonSize === 'lg' ? 'var(--spacing-4)' : 'var(--spacing-2)';

  return (
    <>
      {iconLeading && (
        <Icon
          icon={iconLeading}
          css={`
            margin-right: ${_margin};
            width: ${_size};
            height: ${_size};
          `}
        />
      )}
      {children}
      {iconTrailing && (
        <Icon
          icon={iconTrailing}
          css={`
            margin-left: ${_margin};
            width: ${_size};
            height: ${_size};
          `}
        />
      )}
    </>
  );
};
