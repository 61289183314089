export function nodeIsString(node) {
  return node && typeof node.valueOf() === 'string';
}
export function renderConfigOrCustom(node, DefaultEl) {
  if (nodeIsString(node)) {
    return DefaultEl;
  }
  return node;
}
export var isWindowDefined = function isWindowDefined() {
  return typeof window !== 'undefined';
};