import { cssColorValue, cssGraphicSize } from "../../styles/index.js";
import { IconSizeMap } from "./index.js";
import styled, { css } from "../../styled-components.js";
var graphicSizeMap = {
  xsmall: 'smaller-2',
  small: 'smaller',
  medium: 'base',
  large: 'larger',
  xlarge: 'larger-3',
  xxlarge: 'larger-4'
};

/* Now that width and height are defined in css
   we need to listen for width and height attributes
   and apply them, with units, when they are present
*/
var attrToStyle = function attrToStyle(attr) {
  // check if attr aleady has units
  var hasUnits = isNaN(Number(attr));
  // append px only if needed
  return hasUnits ? attr : "".concat(attr, "px");
};
export var Svg = styled.svg.withConfig({
  displayName: "Svg",
  componentId: "sc-ytk21e-0"
})(["*{vector-effect:non-scaling-stroke;}", " ", " width:", ";height:", ";"], function (props) {
  return props.$autoMirror && css(["[dir='rtl'] &&{transform:scaleX(-1);}"]);
}, function (props) {
  return props.$iconColor ? css(["fill:", ";"], cssColorValue(props.$iconColor)) : css(["fill:currentcolor;"]);
}, function (_ref) {
  var $width = _ref.$width,
    $size = _ref.$size,
    _ref$$tokenType = _ref.$tokenType,
    $tokenType = _ref$$tokenType === void 0 ? 'decorative' : _ref$$tokenType;
  return $width ? attrToStyle($width) : cssGraphicSize(graphicSizeMap[$size], $tokenType, "".concat(IconSizeMap[$size], "px"));
}, function (_ref2) {
  var $height = _ref2.$height,
    $size = _ref2.$size,
    _ref2$$tokenType = _ref2.$tokenType,
    $tokenType = _ref2$$tokenType === void 0 ? 'decorative' : _ref2$$tokenType;
  return $height ? attrToStyle($height) : cssGraphicSize(graphicSizeMap[$size], $tokenType, "".concat(IconSizeMap[$size], "px"));
});