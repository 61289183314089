import {ReactNode} from 'react';
import {Icon, IconName} from '@core/ui/Icon';
import {Text} from '@core/ui/Text';

export type StatProps = {
  color?: string;
  description?: string;
  displayValue?: string;
  horizontal?: boolean;
  iconName?: IconName;
  longTitle?: string;
  show?: boolean;
  title?: string;
  value?: string | number | ReactNode;
};

export function Stat({
  color,
  description,
  displayValue,
  horizontal,
  iconName,
  longTitle,
  show = true,
  title,
  value,
}: StatProps) {
  if (!show) {
    return null;
  }
  const _value = typeof displayValue !== 'undefined' ? displayValue : value;

  if (horizontal) {
    return (
      <div
        css={`
          color: ${color || 'inherit'};
          display: flex;
          align-items: center;
          gap: var(--spacing-3);
          line-height: 1;
        `}>
        <Text
          variant='body2'
          css={`
            font-weight: 500;
          `}>
          {title}
        </Text>
        <Text
          variant='h4'
          css={`
            font-weight: 700;
          `}>
          {_value}
        </Text>
      </div>
    );
  }

  return (
    <div
      css={`
        color: ${color || 'inherit'};
      `}>
      <div
        css={`
          align-items: center;
          color: ${color || 'var(--text-default)'};
          display: flex;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1;
          margin-bottom: 0.5rem;
        `}>
        {title}
        {!!iconName ? (
          <Icon
            icon={iconName}
            css={`
              color: ${color || 'var(--icon-primary)'};
              height: 1.25rem;
              margin-left: 0.5rem;
              width: 1.25rem;
            `}
          />
        ) : null}
      </div>
      <div
        css={`
          font-size: 1.625rem;
          font-weight: 700;
        `}>
        {_value}
      </div>
      {description || longTitle ? (
        <div
          css={`
            color: ${color || 'var(--text-muted)'};
            font-size: 0.8125rem;
            line-height: 1.3;
            margin-top: var(--spacing-3);
          `}>
          {description || longTitle}
        </div>
      ) : null}
    </div>
  );
}

export default Stat;
