import React, {useRef, useState} from 'react';
import dayjs from 'dayjs';
import {Icon} from '../Icon';
import {Overlay, OverlayProps, useOverlay} from '../Overlay';
import DatePicker from './DatePicker';

interface IDatePickerInputProps {
  date?: dayjs.Dayjs;
  disabled?: boolean;
  inputDateFormat?: string;
  invalid?: boolean;
  isOutsideRange?: (_date: dayjs.Dayjs) => boolean;
  onDateChange?: (_selectedDate: dayjs.Dayjs) => void;
  overlayProps?: OverlayProps;
  small?: boolean;
}

const DatePickerInput = ({
  date,
  disabled,
  invalid,
  inputDateFormat = 'MM/DD/YY',
  isOutsideRange,
  onDateChange,
  overlayProps = {
    horizontalAlign: 'right',
    verticalAlign: 'bottom',
    verticalOffset: 2,
  },
  small,
}: IDatePickerInputProps): JSX.Element => {
  const inputRef = useRef<HTMLButtonElement>(null);
  const [opened, toggle] = useOverlay(false);
  const [selectedDate, setSelectDate] = useState<dayjs.Dayjs | undefined>(date);

  const handleDateChange = (dates: dayjs.Dayjs[]): void => {
    setSelectDate(dates[0]);

    if (onDateChange) {
      onDateChange(dates[0]);
    }
  };

  return (
    <>
      <div css={{display: 'flex', alignItems: 'center'}}>
        {small ? null : (
          <Icon
            icon='date'
            rules={() => ({
              marginRight: '0.625rem',
              color: invalid
                ? 'var(--red-orange)'
                : disabled
                ? 'var(--icon-muted)'
                : 'var(--icon-subtle)',
            })}
          />
        )}
        <button
          type='button'
          ref={inputRef}
          onClick={() => toggle(true)}
          css={{
            background: disabled ? 'var(--bg-muted)' : 'var(--bg-default)',
            border: `0.0625rem solid ${
              opened ? 'vaR(--blue)' : 'var(--border-default)'
            }`,
            borderRadius: '0.375rem',
            boxShadow: opened ? '0 0 0 0.0625rem var(--blue)' : undefined,
            color:
              opened || selectedDate
                ? 'var(--text-default)'
                : 'var(--text-muted)',
            cursor: 'pointer',
            fontSize: 'inherit',
            minHeight: small ? '2.125rem ' : '2.5rem',
            minWidth: '7.8125rem',
            outline: 0,
            padding: small ? '0 0.625rem' : '0.5rem 0.625rem',
            textAlign: 'left',
            userSelect: 'none',
            '[disabled]': {
              color: 'var(--text-disabled)',
            },
            ':focus': {
              borderColor: 'var(--blue)',
              boxShadow: '0 0 0 0.0625rem var(--blue)',
            },
          }}>
          {selectedDate
            ? selectedDate.format(inputDateFormat)
            : 'Select date...'}
        </button>
      </div>
      {opened ? (
        <Overlay
          opened={opened}
          toggle={toggle}
          positionTarget={inputRef.current}
          withShadow
          withBackdrop
          transparentBackdrop
          css={`
            max-width: initial;
            padding: 1rem;
          `}
          {...overlayProps}>
          <DatePicker
            mode='day'
            isOutsideRange={isOutsideRange}
            onDatesChange={handleDateChange}
            defaultDates={[selectedDate]}
            extraControls={[
              {
                title: 'Clear',
                callback: () => {
                  handleDateChange([]);
                  toggle(false);
                },
              },
            ]}
          />
        </Overlay>
      ) : null}
    </>
  );
};

export default DatePickerInput;
