import {EventEmitter} from '@spotify-internal/emitter';

import {Event} from '../enums/event';

export interface ConnectionObserverEventMap {
  /**
   * Emitted by the connection observer when the navigator goes online.
   */
  [Event.CONNECTION_ONLINE]: null;

  /**
   * Emitted by the connection observer when the navigator goes offline.
   */
  [Event.CONNECTION_OFFLINE]: null;

  /**
   * Emitted by the connection observer when it detects that the navigator is
   * about to unload the page..
   */
  [Event.WINDOW_BEFORE_UNLOAD]: null;
}

export interface ConnectionObserverOptions {
  /**
   * Indicates whether the ConnectionObserver should emit a
   * `WINDOW_BEFORE_UNLOAD` event when it detects that the navigator is about to
   * unload the current page.
   */
  notifyBeforeUnload: boolean;
}

export abstract class ConnectionObserver extends EventEmitter<ConnectionObserverEventMap> {
  /**
   * Returns whether the navigator is currently online.
   */
  abstract isOnline(): boolean;
}

export type ConnectionObserverCreator = (
  options?: ConnectionObserverOptions
) => ConnectionObserver;

export class BrowserConnectionObserver extends ConnectionObserver {
  private _navigator: Navigator | null;

  constructor(options?: {notifyBeforeUnload: boolean}) {
    super();
    const hasWindow = typeof window !== 'undefined';
    const notifyBeforeUnload = options && options.notifyBeforeUnload;

    this._navigator = (hasWindow && window.navigator) || null;

    if (hasWindow && typeof window.addEventListener === 'function') {
      window.addEventListener(
        'online',
        this.emitSync.bind(this, Event.CONNECTION_ONLINE, null)
      );
      window.addEventListener(
        'offline',
        this.emitSync.bind(this, Event.CONNECTION_OFFLINE, null)
      );

      if (notifyBeforeUnload) {
        window.addEventListener(
          Event.WINDOW_BEFORE_UNLOAD,
          this.emitSync.bind(this, Event.WINDOW_BEFORE_UNLOAD, null)
        );
      }
    }
  }

  static create(options?: {
    notifyBeforeUnload: boolean;
  }): BrowserConnectionObserver {
    return new BrowserConnectionObserver(options);
  }

  isOnline(): boolean {
    const navigator = this._navigator;
    if (navigator && 'onLine' in navigator) {
      return navigator.onLine;
    }
    return true;
  }
}
