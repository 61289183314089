import {gql} from '@apollo/client';

export default gql`
  query getNotifications($token: String) {
    admin(token: $token) {
      notifications {
        organization {
          id
          name
        }
        kind
        recipients
        sentAt
      }
    }
  }
`;
