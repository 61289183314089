import React from 'react';
import DeleteProductMutation from '@admin/graphql-api/_old_mutations/DeleteProduct';
import CompanyQuery from '@admin/graphql-api/queries/AdminCompanyQuery';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import {Element} from '@core/style';
import {Submit} from '@core/ui/FormElements';

class ProductDelete extends React.Component {
  constructor(props) {
    super(props);
    const {product} = props;

    this.state = {
      loading: false,
      complete: false,
    };
  }

  onSubmit = (evt) => {
    evt.preventDefault();

    const {deleteProduct, product} = this.props;

    this.setState({
      loading: true,
    });

    deleteProduct({
      id: product.id,
    }).then(() => {
      this.setState({
        complete: true,
      });
    });
  };

  render() {
    const {company, product} = this.props;

    const {loading, complete} = this.state;

    if (complete) {
      return <Redirect to={`/companies/${company.id}`} />;
    }

    return (
      <Element rules={() => ({maxWidth: '600px', margin: '25px auto'})}>
        <h3>Delete Product</h3>
        <p>This cannot be undone.</p>

        <form onSubmit={this.onSubmit}>
          <Element rules={() => ({padding: '20px 0', textAlign: 'center'})}>
            <Submit value='Delete' disabled={loading} />
          </Element>
        </form>
      </Element>
    );
  }
}

export default graphql(DeleteProductMutation, {
  props: ({mutate}) => ({
    deleteProduct: (input) => {
      return mutate({
        variables: {
          input,
        },
        update: (
          store,
          {
            data: {
              deleteProduct: {id, companyId},
            },
          }
        ) => {
          // Stuff the new journey into the cache.
          const query = {
            query: CompanyQuery,
            variables: {
              id: companyId,
            },
          };

          query.data = store.readQuery(query);

          query.data.admin.company.products =
            query.data.admin.company.products.filter((p) => p.id !== id);

          store.writeQuery(query);
        },
      });
    },
  }),
})(ProductDelete);
