import Badge from '@core/ui/Badge';

const CampaignStateBadge = ({state}: {state: string}) => {
  const BADGE_PROPS_MAP = {
    PENDING: {
      color: 'default',
    },
    READY: {
      color: 'warning',
    },
    LIVE: {
      color: 'success',
    },
    AGENCY: {
      color: 'success',
    },
    COMPLETE: {
      color: 'default',
    },
    REJECTED: {
      color: 'error',
    },
    DEFAULT: {
      color: 'default',
    },
  };

  return (
    <Badge {...(BADGE_PROPS_MAP[state] || BADGE_PROPS_MAP.DEFAULT)}>
      {state}
    </Badge>
  );
};

export const CampaignsLiveBadge = ({count = 0}: {count: number}) => {
  if (count > 0) {
    return (
      <Badge color='secondary' showLiveDot>
        {count} LIVE
      </Badge>
    );
  }
  return <Badge color='default'>{count}</Badge>;
};

export const CampaignLiftBadge = ({state}: {state: string}) => {
  const BADGE_PROPS_MAP = {
    RUNNING: {
      color: 'success',
    },
    LIVE: {
      color: 'success',
    },
    DEFAULT: {
      color: 'default',
    },
  };

  return (
    <Badge {...(BADGE_PROPS_MAP[state] || BADGE_PROPS_MAP.DEFAULT)}>
      {state}
    </Badge>
  );
};

export default CampaignStateBadge;
