import {gql} from '@apollo/client';

export default gql`
  mutation publishMessage($input: PublishMessageInput!) {
    authed {
      success
    }
    publishMessage(input: $input) {
      success
    }
  }
`;
