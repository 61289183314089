import {gql} from '@apollo/client';

export default gql`
  query getUsers {
    admin {
      users {
        id
        firstName
        lastName
        email
        createdAt
      }
    }
  }
`;
