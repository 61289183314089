import {EndpointsProvider, Endpoints} from '../../typedefs';

import {TransportErrors} from '../../enums/errors';
import {StatusFamily} from '../../enums/status_family';

import {XResolveError} from '../../error/xresolve';

import {HTTPResponse} from '../../http/response';
import {PublicTransport} from '../../_internal/public_transport';

const RESOLVER_URL = 'https://apresolve.spotify.com/';

const WellKnownMapping = {
  DEALER: 'dealer',
  WEBGATE: 'spclient',
};

const DEALER_FALLBACK_URL = 'dealer.spotify.com';
const WEB_API = 'https://api.spotify.com/';

type Mappings = {
  dealer?: string;
  webgate?: string;
};

interface XResolveResponse {
  [key: string]: string[];
}

const WEBGATE_FALLBACKS: Record<string, string> = {
  spclient: 'spclient.wg.spotify.com',
  exp: 'exp.wg.spotify.com',
  partners: 'partners.wg.spotify.com',
};

/**
 * Creates a new XResolve Provider for use as an EndpointsProvider for a
 * Transport instance.
 *
 * Note that this function is a factory for an EndpointsProvider function. You
 * must call it prior to giving it to Transport
 *
 * @example // INCORRECT createTransport({ providers: { endpoints:
 * createProvider // WRONG! } });
 *
 * // Correct createTransport({ providers: { endpoints: createProvider() // Works! } });
 *
 * @param mappings - A map of endpoint types to the desired endpoint names. By
 *   default, the mappings used are {DEALER: 'dealer', WEBGATE: 'webgate'}. An
 *   application can change the type of dealer or webgate to use using this
 *   mapping. For example, setting {WEBGATE: 'exp'} will make the XResolve
 *   provider request experimental webgate endpoints instead.
 * @returns An EndpointsProvider function that can be passed to the
 *   createTransport() function.
 */
export function createProvider(mappings: Mappings = {}): EndpointsProvider {
  const types = {
    dealer: mappings.dealer || WellKnownMapping.DEALER,
    webgate: mappings.webgate || WellKnownMapping.WEBGATE,
  };

  const url = `${RESOLVER_URL}?type=${types.dealer}&type=${types.webgate}`;

  return function (transport: PublicTransport): Promise<Endpoints> {
    return transport
      .request(url, {
        forcePolyfill: true, // Some browsers can't JSON.
        responseType: 'json',
        retry: {
          maxRetries: 3,
          curve: 'exponential',
          condition: function (
            response: HTTPResponse,
            statusFamily: typeof StatusFamily
          ) {
            // We have a fairly strict 2xx requirement here, since we want to
            // ensure that we can connect.
            return response.getStatusFamily() !== statusFamily.SUCCESS;
          },
        },
        metadata: {
          // Ensure that this goes through always.
          noRequestTransform: true,
        },
      })
      .then((response: HTTPResponse<XResolveResponse>) => {
        const body = response.body;
        const result: Endpoints & {dealer: string} = {
          dealer: body?.[types.dealer]?.[0] ?? DEALER_FALLBACK_URL,
          webgate:
            body?.[types.webgate]?.[0] ??
            WEBGATE_FALLBACKS[types.webgate] ??
            WEBGATE_FALLBACKS.spclient!,
          webapi: WEB_API,
        };
        if (!result.dealer || !result.webgate) {
          throw new XResolveError(
            TransportErrors.XRESOLVE_INCOMPLETE_RESPONSE,
            'X-Resolve responded with incomplete results.',
            response.status
          );
        }
        return result;
      })
      .then((result) => {
        // Ensure that we prefix with protocols.
        result.dealer = `wss://${result.dealer.replace(/:443$/, '')}`;
        result.webgate = `https://${result.webgate.replace(/:443$/, '')}`;
        return result;
      });
  };
}
