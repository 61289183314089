import {FC, useEffect} from 'react';
import {getQueryParams} from '@core/lib/urls';
import {encodeString, isExternalLink} from '@core/lib/utils';
import Loading from '@core/ui/Loading';

const getSafeRedirectPath = () => {
  const {to} = getQueryParams();
  const safePath = to
    ? // eslint-disable-next-line no-script-url
      encodeString(to).replace('javascript:', '').replace('vbscript:', '')
    : '/';

  return isExternalLink(safePath) ? '/' : safePath;
};

const Enter = ({
  match: {
    params: {token},
  },
}: {
  match: {params: {token: string}};
}) => {
  useEffect(() => {
    localStorage.setItem('adanalytics-token', token);
    window.location = getSafeRedirectPath();
  }, [token]);

  return <Loading fixed />;
};

export default Enter;
