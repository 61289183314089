import {FC, HTMLProps, useMemo, useState} from 'react';
import dayjs from '@core/lib/dayjs';
import {TRules, css} from '@core/style';
import Button, {ButtonIcon, ButtonIconProps} from '../Button';
import {WEEK_DAYS, areSameDates, getDaysInMonthUTC} from './helpers';

type DatePickerMode = 'range' | 'day' | 'days';
const CAL_WIDTH = 21; // rem
const CAL_GAP = 1.25; // rem

const getRenderedMonths = ({
  date,
  twoMonthsView,
}: {
  date: dayjs.Dayjs;
  twoMonthsView: boolean;
}): dayjs.Dayjs[] => {
  const _date = date || dayjs();
  const months = [
    _date.clone().subtract(1, 'M'),
    _date,
    _date.clone().add(1, 'M'),
  ];

  if (twoMonthsView) {
    months.push(_date.clone().add(2, 'M'));
  }

  return months;
};

type ArrowButtonProps = Omit<ButtonIconProps, 'ref' | 'icon'> & {
  direction: 'left' | 'right';
};

const ArrowButton = ({direction, ...props}: ArrowButtonProps) => (
  <ButtonIcon
    icon='arrow-right'
    css={`
      ${direction}: 0;
      background: var(--bg-overlay);
      position: absolute;
      top: 0.375rem;
      transform: ${direction === 'left' ? 'rotate(180deg)' : ''};
      width: 2.125rem;
      z-index: 1;
    `}
    {...props}
  />
);

export interface DatePickerDayProps extends HTMLProps<HTMLDivElement> {
  date: dayjs.Dayjs;
  disabled?: boolean;
  mode: DatePickerMode;
  selectedDates: dayjs.Dayjs[];
}

const DatePickerDay: FC<DatePickerDayProps> = ({
  date,
  disabled,
  mode,
  selectedDates,
  ...props
}): JSX.Element => {
  const selected = !!selectedDates.find((d) => areSameDates(d, date));
  const inRange =
    mode === 'range' &&
    selectedDates[0] &&
    selectedDates[1] &&
    date.isBetween(selectedDates[0], selectedDates[1]);

  return (
    <div
      css={{
        background: inRange || selected ? 'var(--bg-default-hover)' : '',
        pointerEvents: disabled ? 'none' : '',
      }}
      {...props}>
      <div
        css={{
          alignItems: 'center',
          background: selected ? 'var(--blue)' : '',
          border: selected ? '1px solid var(--blue)' : '1px solid transparent',
          borderRadius: '.5rem',
          minHeight: '3rem',
          color: selected
            ? 'var(--white)'
            : disabled
            ? 'var(--text-disabled)'
            : 'inherit',
          cursor: 'pointer',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          position: 'relative',
          width: '100%',
          ':hover': {
            borderColor: 'var(--text-default)',
          },
        }}>
        {date.get('date')}
      </div>
    </div>
  );
};

interface DatePickerMonthProps extends HTMLProps<HTMLDivElement> {
  isOutsideRange?: (_props: dayjs.Dayjs[]) => boolean;
  mode?: DatePickerMode;
  onDayClick?: (_props: dayjs.Dayjs) => void;
  selectedDates?: dayjs.Dayjs[];
  shownMonth: dayjs.Dayjs;
}

const DatePickerMonth: FC<DatePickerMonthProps> = ({
  isOutsideRange,
  mode,
  onDayClick,
  selectedDates,
  shownMonth,
  ...props
}): JSX.Element => {
  const days = getDaysInMonthUTC(shownMonth);

  return (
    <div css={{display: 'inline-block'}} {...props}>
      <div
        css={{
          display: 'flex',
          height: '2.125rem',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {shownMonth.format('MMMM YYYY')}
      </div>
      <div
        css={{
          color: 'var(--text-muted)',
          display: 'grid',
          fontSize: '0.75rem',
          gridTemplateColumns: 'repeat(7, 3rem)',
          marginTop: '1rem',
          textAlign: 'center',
        }}>
        {WEEK_DAYS.map((weekDay, i) => (
          <div key={`${shownMonth.format('MMM')}-${weekDay}-${i}`}>
            {weekDay}
          </div>
        ))}
      </div>
      <div css={{display: 'grid', gridTemplateColumns: 'repeat(7, 3rem)'}}>
        {days.length
          ? [...Array(days[0].toDate().getDay()).keys()].map((_, i) => (
              <div key={`date-${i}`} />
            ))
          : null}
        {days.map((date) => (
          <DatePickerDay
            key={date.format('YYYY/MM/DD')}
            date={date}
            selectedDates={selectedDates}
            mode={mode}
            disabled={isOutsideRange(date)}
            onClick={() => onDayClick(date)}
          />
        ))}
      </div>
    </div>
  );
};

export interface DatePickerProps {
  defaultDates?: dayjs.Dayjs[];
  extraControls?: any;
  isOutsideRange?: (_date: dayjs.Dayjs) => boolean;
  mode?: DatePickerMode;
  onDatesChange?: (_dates: dayjs.Dayjs[]) => void;
  onFocusedInputChange?: (_focusedInput: 'start' | 'end') => void;
  rules?: TRules;
  twoMonthsView?: boolean;
}

const DatePicker: FC<DatePickerProps> = ({
  defaultDates = [],
  extraControls = [],
  isOutsideRange,
  mode = 'range',
  onDatesChange,
  onFocusedInputChange,
  rules,
  twoMonthsView = false,
}): JSX.Element => {
  const [focusedInput, setFocusedInput] = useState<'start' | 'end'>('start');
  const [selectedDates, setSelectedDates] =
    useState<dayjs.Dayjs[]>(defaultDates);
  const [transitionJob, setTransitionJob] = useState<{
    date: dayjs.Dayjs;
  } | null>(null);
  const [renderedMonths, setRenderedMonths] = useState(
    getRenderedMonths({date: defaultDates[0], twoMonthsView})
  );

  const dispatchChange = (selectedDates) => {
    if (onDatesChange) {
      onDatesChange(selectedDates);
    }
  };

  const handleDayClick = (date) => {
    let newSelectedDates;
    if (mode === 'day') {
      // If singleDayPicker is true, we only allow one day to be selected
      newSelectedDates = [date];
    } else if (mode === 'days') {
      const filtered = selectedDates.filter((d) => !d.isSame(date));

      if (filtered.length === selectedDates.length) {
        newSelectedDates = [...selectedDates, date];
      } else {
        newSelectedDates = filtered;
      }
    } else {
      if (
        focusedInput === 'start' ||
        !selectedDates[0] ||
        date.isBefore(selectedDates[0])
      ) {
        newSelectedDates = [date];
        setFocusedInput('end');
      } else {
        selectedDates[1] = date;
        newSelectedDates = selectedDates.slice(0);
        setFocusedInput('start');
      }
      if (onFocusedInputChange) {
        onFocusedInputChange(focusedInput);
      }
    }
    setSelectedDates(newSelectedDates);
    dispatchChange(newSelectedDates);
  };

  const handleIsOutsideRange = (date) => {
    if (typeof isOutsideRange === 'function') {
      return isOutsideRange(date);
    }
    return false;
  };

  const onTransitionEnd = () => {
    if (transitionJob) {
      setRenderedMonths(
        getRenderedMonths({date: transitionJob.date, twoMonthsView})
      );
      setTransitionJob(null);
    }
  };

  const controls = useMemo(
    () =>
      [
        {
          title: 'Show Today',
          callback: () => {
            setRenderedMonths(
              getRenderedMonths({
                date: dayjs(),
                twoMonthsView,
              })
            );
          },
        },
      ].concat(extraControls),
    [extraControls, twoMonthsView]
  );

  return (
    <div {...css([() => ({position: 'relative'}), rules])}>
      <ArrowButton
        direction='left'
        onClick={() =>
          setTransitionJob({
            date: renderedMonths[0],
          })
        }
      />
      <ArrowButton
        direction='right'
        onClick={() =>
          setTransitionJob({
            date: renderedMonths[2],
          })
        }
      />
      <div
        css={{
          overflow: 'hidden',
          width: twoMonthsView
            ? `${CAL_WIDTH * 2 + CAL_GAP}rem`
            : `${CAL_WIDTH}rem`,
        }}>
        <div
          onTransitionEnd={onTransitionEnd}
          css={{
            display: 'grid',
            gridGap: `${CAL_GAP}rem`,
            gridTemplateColumns: `repeat(${
              twoMonthsView ? 4 : 3
            }, ${CAL_WIDTH}rem)`,
            transition: transitionJob
              ? 'transform 300ms cubic-bezier(0.22, 1, 0.36, 1)'
              : '',
            transform: `translateX(${
              transitionJob
                ? transitionJob.date === renderedMonths[0]
                  ? '0'
                  : `-${CAL_WIDTH * 2 + CAL_GAP * 2}rem`
                : `-${CAL_WIDTH + CAL_GAP}rem`
            })`,
            minHeight: '21rem',
            width: twoMonthsView
              ? `${CAL_WIDTH * 4 + CAL_GAP * 3}rem`
              : `${CAL_WIDTH * 3 + CAL_GAP * 2}rem`,
          }}>
          {renderedMonths.map((date) => (
            <DatePickerMonth
              key={date.format('MM/YYYY')}
              isOutsideRange={handleIsOutsideRange}
              mode={mode}
              onDayClick={handleDayClick}
              selectedDates={selectedDates}
              shownMonth={date}
            />
          ))}
        </div>
      </div>
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          gap: '.625rem',
          marginTop: '.75rem',
        }}>
        {controls.map(({title, callback}) => (
          <Button
            key={title}
            variant='outlined'
            buttonSize='sm'
            onClick={callback}>
            {title}
          </Button>
        ))}
      </div>
      {mode === 'days' ? (
        <div
          css={{
            border: '0.0625rem solid var(--border-default)',
            borderRadius: '.5rem',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '.75rem',
          }}>
          <div
            css={{
              color: 'var(--text-muted)',
              display: 'flex',
              fontSize: '0.875rem',
              fontWeight: 500,
              justifyContent: 'space-between',
              marginBottom: '.5rem',
              padding: '0.625rem 0.625rem 0 0.625rem',
            }}>
            Selected dates ({selectedDates.length})
            <button
              type='button'
              onClick={() => {
                setSelectedDates([]);
                dispatchChange([]);
              }}
              css={{
                background: 'none',
                border: 0,
                margin: 0,
                padding: 0,
                ':hover': {color: 'var(--blue)'},
              }}>
              Clear
            </button>
          </div>
          {selectedDates.length ? (
            <div
              css={{
                display: 'grid',
                flex: 1,
                gridGap: '0.25rem',
                gridTemplateColumns: 'repeat(4, .25fr)',
                maxHeight: '8.5rem',
                overflowX: 'hidden',
                overflowY: 'auto',
                padding: '0 0.625rem 0.625rem 0.625rem',
              }}>
              {selectedDates
                .sort((a: dayjs.Dayjs, b: dayjs.Dayjs) =>
                  a.isAfter(b) ? 1 : -1
                )
                .map((date) => (
                  <div
                    key={date.toISOString()}
                    css={{
                      border: '0.0625rem solid var(--blue-80)',
                      borderRadius: '5rem',
                      color: 'var(--blue)',
                      display: 'inline-flex',
                      fontSize: '0.875rem',
                      justifyContent: 'space-between',
                      lineHeight: 1.3,
                      padding: '0.25rem 0.5rem 0.25rem 0.625rem',
                      pointerEvents: 'none',
                      userSelect: 'none',
                      whiteSpace: 'nowrap',
                      width: '100%',
                    }}>
                    {date.format('MM/DD')}
                    <ButtonIcon
                      icon='close'
                      onClick={() => {
                        const dates = selectedDates.filter(
                          (d) => !d.isSame(date)
                        );
                        setSelectedDates(dates);
                        dispatchChange(dates);
                      }}
                      css={`
                        border-radius: 4rem;
                        color: var(--blue);
                        cursor: pointer;
                        flex-shrink: 0;
                        height: 1.125rem;
                        margin-left: 0.25rem;
                        padding: 0.125rem;
                        pointer-events: all;
                        width: 1.125rem;
                        :hover svg {
                          background: var(--blue);
                          color: #fff;
                        }
                      `}
                    />
                  </div>
                ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default DatePicker;
