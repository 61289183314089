import {
  PropsWithChildren,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {CSSTransition, TransitionStatus} from 'react-transition-group';
import styled from 'styled-components';
import {ButtonIcon, IconButton} from '../Button';
import {Icon, IconName} from '../Icon';
import {Overlay} from '../Overlay';

type SnackbarOrigin = {
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
};

type SnackbarSeverity = 'success' | 'info' | 'warning' | 'error';

export type SnackbarProps = PropsWithChildren<{
  /**
   * The type of the alert. This defines the color and icon used.
   * @default 'success'
   */
  severity?: SnackbarSeverity;
  /**
   * The anchor of the `Snackbar`.
   * On smaller screens, the component grows to occupy all the available width,
   * the horizontal alignment is ignored.
   * @default { vertical: 'bottom', horizontal: 'center' }
   */
  anchorOrigin?: SnackbarOrigin;
  /**
   * The number of milliseconds to wait before automatically closing the overlay.
   * @default null
   */
  autoHideDuration?: number | null;
  /**
   * If present, displays an image instead of the default icons.
   * @default false
   */
  customImage?: ReactNode;
  /**
   * If `true`, the close button won't be rendered.
   * @default false
   */
  noCloseButton?: boolean;
  /**
   * If `true`, the default icons won't be rendered.
   * @default false
   */
  noIcon?: boolean;
  /**
   * If `true`, the component is shown.
   * @default false
   */
  opened: boolean;
  toggle: (_to?: boolean) => void;
}>;

export function Snackbar({
  severity = 'success',
  anchorOrigin = {vertical: 'bottom', horizontal: 'center'},
  autoHideDuration = null,
  children,
  customImage,
  noCloseButton = false,
  noIcon = false,
  opened = false,
  toggle,
}: SnackbarProps) {
  const [animateIn, setAnimateIn] = useState<boolean>(false);
  const timerAutoHide = useRef<number>();

  useLayoutEffect(() => {
    setAnimateIn(opened);

    if (opened && autoHideDuration !== null) {
      clearTimeout(timerAutoHide.current);
      timerAutoHide.current = window.setTimeout(() => {
        setAnimateIn(false);
      }, autoHideDuration);
    }

    return () => {
      clearTimeout(timerAutoHide.current);
    };
  }, [autoHideDuration, opened]);

  const {bgColor, color, iconName} = styleMap[severity];
  const hasCustomImage = !!customImage;

  return (
    <Overlay
      opened={opened}
      toggle={toggle}
      closeOnEscKey={false}
      closeOnOutsideClick={false}
      fixed
      css={`
        background: none;
        border-radius: 0;
        border: 0;
        bottom: ${anchorOrigin.vertical === 'bottom' ? '1rem' : 'auto'};
        left: 0;
        padding: 0 2rem;
        pointer-events: none;
        right: 0;
        top: ${anchorOrigin.vertical === 'top' ? '1rem' : 'auto'};
      `}>
      <div
        css={`
          display: flex;
          height: 100%;
          justify-content: ${anchorOrigin.horizontal === 'left'
            ? 'left'
            : anchorOrigin.horizontal === 'center'
            ? 'center'
            : 'flex-end'};
        `}>
        <CSSTransition
          in={animateIn}
          timeout={100}
          transitionName='snackbar'
          onExited={() => toggle(false)}>
          {(state) => (
            // state change: exited -> entering -> entered -> exiting -> exited
            <_OverlayContent
              $state={state}
              $bgColor={bgColor}
              $color={color}
              $hasCustomImage={hasCustomImage}>
              {hasCustomImage && customImage}
              {!noIcon && !hasCustomImage && (
                <Icon
                  icon={iconName}
                  css={`
                    color: ${color};
                    height: 1.5rem;
                    width: 1.5rem;
                  `}
                />
              )}
              <div
                css={`
                  margin: 0 var(--spacing-3);
                `}>
                {children}
              </div>
              {!noCloseButton && (
                <ButtonIcon
                  icon='close'
                  onClick={() => {
                    clearTimeout(timerAutoHide.current);
                    setAnimateIn(false);
                  }}
                  css={`
                    color: inherit;
                    margin-right: -0.125rem;
                    padding: 0;
                    :hover svg {
                      color: ${color};
                    }
                  `}
                />
              )}
            </_OverlayContent>
          )}
        </CSSTransition>
      </div>
    </Overlay>
  );
}

const _OverlayContent = styled.div<{
  $bgColor: string;
  $color: string;
  $hasCustomImage: boolean;
  $state: TransitionStatus;
}>`
  align-items: center;
  background: ${(p) => p.$bgColor};
  border-radius: var(--radius-2);
  box-shadow: var(--shadow-xl);
  color: ${(p) => p.$color};
  display: flex;
  padding: ${(p) =>
    p.$hasCustomImage
      ? 'var(--spacing-4)'
      : 'var(--spacing-4) var(--spacing-5)'};
  pointer-events: all;
  will-change: transform;
  ${(p) => {
    switch (p.$state) {
      case 'entering':
        return `
          opacity: 0;
          transform: scale(0.9);
        `;
      case 'entered':
        return `
          opacity: 1;
          transform: translateX(0);
          transition: opacity 300ms, transform 300ms;
        `;
      case 'exiting':
        return `
          opacity: 1;
        `;
      case 'exited':
        return `
          opacity: 0;
          transform: scale(0.9);
          transition: opacity 300ms, transform 300ms;
        `;
    }
  }}
`;

const styleMap: {
  [_key in SnackbarSeverity]: {
    bgColor: string;
    color: string;
    iconName: IconName;
  };
} = {
  success: {
    bgColor: 'var(--success)',
    color: 'var(--text-on-success)',
    iconName: 'checkbox-marked-circle',
  },
  info: {
    bgColor: 'var(--info)',
    color: 'var(--text-on-info)',
    iconName: 'info',
  },
  warning: {
    bgColor: 'var(--warning)',
    color: 'var(--text-on-warning)',
    iconName: 'alert',
  },
  error: {
    bgColor: 'var(--error)',
    color: 'var(--text-on-error)',
    iconName: 'alert',
  },
};
