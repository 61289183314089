import {ButtonHTMLAttributes} from 'react';
import styled, {css} from 'styled-components';
import Loading from '@core/ui/Loading';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 70px;
  padding: 48px;
  @media (max-width: 768px) {
    padding: 40px 0px;
    gap: 40px;
    flex-direction: column;
  }
  align-content: center;
  flex-wrap: wrap;
`;

export const Footer = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6a6a6a;
  text-align: center;
  justify-content: center;
`;

export const FooterLink = styled.a`
  color: #6a6a6a;
  text-decoration: bold;
  margin-left: 2px;
`;

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
};

const RawButton = styled.button<ButtonProps>`
  display: flex;
  padding: 12px 24px;
  position: relative;
  ${({disabled}) => disabled && 'opacity: 0.3;'}
  ${({loading}) =>
    loading
      ? css`
          /* Styles for loading state */
        `
      : css`
          /* Styles for non-loading state */
          background-color: #cdf564;
          border-radius: 40px;
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
        `}
`;

const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
`;

type LabelContainerProps = {
  visible?: boolean;
};

const LabelContainer = styled.div<LabelContainerProps>`
  ${({visible}) => !visible && 'visibility: hidden;'}
`;

export const OnboardingButton: React.FC<ButtonProps> = ({
  loading,
  children,
  ...buttonProps
}) => {
  return (
    <RawButton {...buttonProps} loading={loading}>
      {loading ? (
        <>
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
          <LabelContainer visible={!loading}>{children}</LabelContainer>
        </>
      ) : (
        <LabelContainer visible={!loading}>{children}</LabelContainer>
      )}
    </RawButton>
  );
};

const ArrowBack = (
  <svg width='19' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.914 1.336A1 1 0 0 0 9.207.63L0 9.83l9.207 9.202a1.001 1.001 0 0 0 1.644-1.088 1 1 0 0 0-.23-.325l-6.786-6.782H18a1 1 0 0 0 0-2H3.822l6.799-6.794a1 1 0 0 0 .293-.706z'
      fill='#6A6A6A'
    />
  </svg>
);

const BackButtonContainer = styled.button`
  color: #6a6a6a;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  align-items: center;
  gap: 8px;
`;

export const BackButton = ({label, onClick}) => (
  <BackButtonContainer type='button' onClick={onClick}>
    {ArrowBack}
    {label}
  </BackButtonContainer>
);

const ProgressRing = ({selected}) => (
  <svg
    width='8'
    height='8'
    viewBox='0 0 8 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <circle cx='4' cy='4' r='4' fill={selected ? '#222222' : '#878787'} />
  </svg>
);

const ProgressContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ProgressRings = ({count, selected}) => (
  <ProgressContainer>
    <div style={{display: 'flex', gap: '8px'}}>
      {Array(count)
        .fill(0)
        .map((_, idx) => (
          <ProgressRing selected={idx === selected} key={idx} />
        ))}
    </div>
  </ProgressContainer>
);
