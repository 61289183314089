import React from 'react';
import CompaniesQuery from '@admin/graphql-api/_old_queries/CompaniesQuery';
import {graphql} from '@apollo/client/react/hoc';
import {comma} from '@core/lib/filters';
import DataTable from '@core/ui/DataTable';
import Loading from '@core/ui/Loading';

const CompaniesFilter = ({data, order}) => {
  if (data.loading) {
    return <Loading centered />;
  }

  const columns = [
    {
      title: 'Company',
      accessor: 'name',
      type: 'string',
    },
    {
      title: 'Products',
      accessor: 'productsCount',
      type: 'number',
      fmt: comma,
    },
    {
      title: 'Ads',
      accessor: 'adsCount',
      type: 'number',
      fmt: comma,
    },
  ];

  let companies = [];
  if (data.companies) {
    companies = data.companies;
  } else if (data.admin && data.admin.companies) {
    companies = data.admin.companies;
  }

  const orderBy = {
    '-ads_count': '-adsCount',
    '-feeds_count': null,
    '-products_count': '-productsCount',
    '-created_at': null,
  }[order];

  return (
    <DataTable
      columns={columns}
      data={companies}
      pagination={false}
      orderBy={orderBy}
      emptyMessageText='No companies found.'
      onClickRowPath={({id}) => `/companies/${id}`}
    />
  );
};

export default graphql(CompaniesQuery, {
  options: ({query, offset, order, limit, duplicates}) => ({
    variables: {
      query,
      offset,
      limit,
      order,
      duplicates,
    },
  }),
})(CompaniesFilter);
