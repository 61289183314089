import {Route, Switch} from 'react-router-dom';
import FeedAdd from './FeedAdd';
import Feeds from './Feeds';
import {FeedIndex} from './feed';

export default function () {
  return (
    <Switch>
      <Route exact path='/feeds' component={Feeds} />
      <Route exact path='/feeds/add' component={FeedAdd} />
      <Route path='/feeds/:feedId' component={FeedIndex} />
    </Switch>
  );
}
