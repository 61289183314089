import React from 'react';
import {TRules} from '@core/style';
import {Element} from '@core/style';
import {Text} from '../Text';

export interface EmptyMessageProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  children?: React.ReactNode | string;
  rules?: TRules;
}
const EmptyMessage = ({
  title,
  rules,
  children,
  ...props
}: EmptyMessageProps) => (
  <Element
    rules={[
      () => ({
        padding: '1.25rem',
        textAlign: 'center',
      }),
      rules,
    ]}
    {...props}>
    <div
      css={{
        fontSize: 'var(--font-size-h5)',
        fontWeight: 'var(--font-weight-medium)',
        marginBottom: children ? 'var(--spacing-2)' : 0,
      }}>
      {title}
    </div>
    <Text variant='body2'>{children}</Text>
  </Element>
);

export default EmptyMessage;
