import {gql} from '@apollo/client';

export default gql`
  mutation deleteFutureEpisode($input: DeleteFutureEpisodeInput!) {
    authed {
      success
    }
    deleteFutureEpisode(input: $input) {
      success
    }
  }
`;
