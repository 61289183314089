import {FC} from 'react';
import {useAdminOrganizationQuery} from '@admin/graphql-api';
import {toDashboardUrl} from '@admin/lib/paths';
import organizationRoutes from '@admin/lib/routes/organizationRoutes';
import {Redirect, Switch} from 'react-router-dom';
import useRoutes from '@core/lib/useRoutes';
import Loading from '@core/ui/Loading';
import {Nav, NavItem} from '@core/ui/Nav';
import AccessAdd from './AccessAdd';
import AccessRemove from './AccessRemove';
import AddSpotifyIds from './AddSpotifyIds';
import OrganizationCreatePodcast from './OrganizationCreatePodcast';
import OrganizationOverview from './OrganizationOverview';
import OrganizationUpdate from './OrganizationUpdate';
import BackfillIndex from './backfill';
import DomainsIndex from './domains';
import PodcastsIndex from './podcasts';

interface OrganzationIndexProps {
  match: {
    params: {organizationId: string};
  };
}

const OrganizationIndex: FC<OrganzationIndexProps> = ({
  match: {
    params: {organizationId},
  },
}): JSX.Element => {
  const {data, loading, error} = useAdminOrganizationQuery({
    variables: {
      id: organizationId,
    },
  });

  const organization = data?.admin?.organization;

  if (loading) return <Loading fixed />;

  if (error || !organization) {
    return <Redirect to='/organizations' />;
  }

  const dashboardUrl = toDashboardUrl(organization);
  const {routes, renderRoutes} = useRoutes({
    baseUrl: `/organizations/${organization.id}`,
    basePath: '/organizations/:organizationId',
    routes: organizationRoutes({organization}),
  });

  return (
    <>
      <Nav title={organization.name} items={routes}>
        <NavItem href={`${dashboardUrl}/manage/members/add`} target='_blank'>
          Add Member
        </NavItem>
        {organization.kind !== 'RESEARCH' ? (
          <NavItem href={dashboardUrl} target='_blank'>
            View in Dash
          </NavItem>
        ) : null}
      </Nav>
      <Switch>
        {renderRoutes({
          components: {
            overview: OrganizationOverview,
            update: OrganizationUpdate,
            podcast: OrganizationCreatePodcast,
            access: AccessAdd,
            backfill: BackfillIndex,
            podcasts: PodcastsIndex,
            domains: DomainsIndex,
            permission: AccessRemove,
            'spotify-ids': AddSpotifyIds,
          },
          extraProps: {
            organization,
          },
          redirects: [{from: '*', to: ''}],
        })}
      </Switch>
    </>
  );
};

export default OrganizationIndex;
