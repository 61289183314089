import {HeaderMap, InternalRequestOptions, RetryOptions} from '../typedefs';

import {Method} from '../enums/method';

import {HTTPRequestHeaders} from './request_headers';

export type HTTPRequestParams = {
  headers?: HeaderMap;
  options?: InternalRequestOptions;
  method?: keyof typeof Method | Method;
  payload?: BodyInit;
  urlExpanded?: boolean;
  retryOptions?: RetryOptions;
};

export class HTTPRequest {
  url: string;

  method: keyof typeof Method | Method;

  headers: HTTPRequestHeaders;

  options: InternalRequestOptions;

  payload?: BodyInit;

  urlExpanded: boolean;

  retryOptions: RetryOptions;

  private constructor(url: string, params?: HTTPRequestParams) {
    this.url = url;
    this.method = params?.method || 'GET';
    this.headers = new HTTPRequestHeaders(params?.headers);
    this.options = params?.options || {};
    this.payload = params?.payload;
    this.urlExpanded = params?.urlExpanded || false;
    this.retryOptions = params?.retryOptions || {};
  }

  static create(url: string, params?: HTTPRequestParams): HTTPRequest {
    return new HTTPRequest(url, params);
  }

  clone(): HTTPRequest {
    const copy = new HTTPRequest(this.url);
    copy.method = this.method;
    copy.headers = new HTTPRequestHeaders(this.headers.toJSON());
    copy.options = this.options;
    copy.payload = this.payload;
    copy.urlExpanded = this.urlExpanded;
    copy.retryOptions = this.retryOptions;
    return copy;
  }
}
