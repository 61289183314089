import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import dayjs from '@core/lib/dayjs';
import EpisodelessRangeIndex from './range';

const EpisodelessRedirect = () => {
  return (
    <Redirect
      to={`/episodeless/${dayjs()
        .subtract(7, 'days')
        .format('YYYY-MM-DD')}/${dayjs()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')}`}
    />
  );
};

export default function () {
  return (
    <Switch>
      <Route exact path='/episodeless' component={EpisodelessRedirect} />
      <Route
        path='/episodeless/:start/:end'
        component={EpisodelessRangeIndex}
      />
    </Switch>
  );
}
