import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import Select from '../../Select';
import {DataTableFilterProps} from '../types';
import {getItems} from './utils';

interface SelectFilterProps extends DataTableFilterProps {
  items?: any[];
}

const SelectFilter = forwardRef(
  (
    {
      column,
      data,
      defaultValue,
      items,
      onChange,
      valuesMap,
      ...props
    }: SelectFilterProps,
    ref
  ): JSX.Element => {
    const _items = useMemo(
      () =>
        items && items.length ? items : getItems({data, valuesMap, column}),
      [items, data, column, valuesMap]
    );
    const [selectedItem, setSelectedItem] = useState(
      defaultValue ? _items.find((itm) => itm.value === defaultValue) : null
    );

    useImperativeHandle(ref, () => ({
      filter: (filteredData: any[]) => {
        if (selectedItem) {
          return filteredData.filter((d) => {
            const value = valuesMap.get(column)?.get(d);
            return (
              (typeof value === 'string' ? value.toLowerCase() : value) ===
              selectedItem.value
            );
          });
        }
        return filteredData;
      },
      clear: () => {
        setSelectedItem(null);
      },
    }));

    useEffect(() => {
      onChange({
        column,
        defaultValue: selectedItem ? selectedItem.value : '',
      });
    }, [column, onChange, selectedItem]);

    return (
      <Select
        removeOption
        items={_items}
        placeholder={column.title}
        onSelect={setSelectedItem}
        searchKeys={['value']}
        outline
        small
        defaultValue={defaultValue}
        overlayProps={{
          horizontalAlign: 'right',
          verticalOffset: 2,
        }}
        {...props}
      />
    );
  }
);

export default SelectFilter;
