import React from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  type RadioGroupProps as MuiRadioGroupProps,
  type RadioProps as MuiRadioProps,
  ThemeOptions,
} from '@mui/material';

export interface RadioGroupProps extends MuiRadioGroupProps {
  children?: React.ReactNode;
  defaultValue?: string;
  name?: string;
  onChange?: (_e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}
export const RadioGroup = MuiRadioGroup;

export type RadioProps = Omit<
  FormControlLabelProps & {inputProps?: MuiRadioProps; id?: string},
  'control'
>;
export function Radio({
  id,
  inputProps,
  label,
  name,
  onChange,
  value,
  ...labelProps
}: RadioProps) {
  return (
    <FormControlLabel
      htmlFor={id}
      control={<MuiRadio name={name} id={id} {...inputProps} />}
      label={label}
      onChange={onChange}
      value={value}
      {...labelProps}
    />
  );
}

export const MuiRadioStyles: ThemeOptions['components'] = {
  MuiFormControlLabel: {
    defaultProps: {
      componentsProps: {
        typography: {
          variant: 'subtitle2',
        },
      },
    },
  },
  // TODO: Make sure FormGroup is not styled anywhere else, e.g. for Checkbox groups, toggle groups, etc.
  MuiFormGroup: {
    styleOverrides: {
      root: {
        [`& label.MuiFormControlLabel-root`]: {
          margin: 0,
          border: '1px solid var(--border-default)',
          borderBottom: 'none',
          padding: '0.5rem',
          '&:first-child': {
            borderTopLeftRadius: '0.25rem',
            borderTopRightRadius: '0.25rem',
          },
          '&:last-child': {
            borderBottomLeftRadius: '0.25rem',
            borderBottomRightRadius: '0.25rem',
            borderBottom: '1px solid var(--border-default)',
          },
        },
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      color: 'primary',
    },
    styleOverrides: {
      root: {color: 'var(--text-muted)'},
    },
  },
};
