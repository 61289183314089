import { color as colorTokens } from '@spotify-internal/encore-foundation';

// Helper for any prop that behaves like `as` from styled-components (ie `component` in Button)

/**
 * Compatibility type to include all html attributes with a fix for the `as` prop collision.
 * TODO: improve the type definition for the `css` prop to better mimic styled-components `css` prop definition.
 * https://ghe.spotify.net/encore/web/issues/236
 */

// Compatibility type to include all svg attributes with a fix for the `as` prop collision

export function assertNever(_) {
  throw new Error('Unreachable value');
}

/**
 * Export Foundation tokens
 *
 * TODO: If Foundation merges this PR (https://ghe.spotify.net/encore/foundation/pull/239),
 * remove and re-export from Foundation.
 */

// Helper for validating whether a color string is a foundation token
export function isColorToken(color) {
  return color !== undefined && color in colorTokens;
}

/**
 * Helper for converting a token (in string px format) to an integer.
 * @example
 * pxToInt(spacer16) // returns 16
 *  */
export var pxToInt = function pxToInt(px) {
  return parseInt(px, 10);
};

/**
 * Construct a type with the properties of T where T's optional properties (K)
 * are required in the new type.
 */

/**
 * Utility type to make it easy to add documented pseudo state types to components.
 * @example
 * export type ComponentProps = StyledWrapperProps & Pick<PseudoStateProps, 'isHover' | 'isFocus'>;
 */

/*
 * If T matches any of the strings in K then append a "$" to T
 */

/*
 * Takes a type T and prefixes all of the keys in P with a "$"
 * Useful when needing to convert some React component props to be
 * styled-components "transient props"
 * See: https://styled-components.com/docs/api#transient-props
 */