import { screenMdMin, screenSmMax, screenSmMin, screenXsMax } from '@spotify-internal/encore-foundation';
import { cssColorValue, cssSpacing, rootStyle, semanticColors, textOverflow, viola, violaBold } from "../../styles/index.js";
import { Button } from "../ButtonIcon/Button.js";
import { Svg } from "../Icon/Svg.js";
import { Overlay } from "../OverlayTrigger/Overlay.js";
import { Trigger } from "../OverlayTrigger/Trigger.js";
import styled, { css } from "../../styled-components.js";
export var TableCellElement = styled.td.withConfig({
  displayName: "TableCell__TableCellElement",
  componentId: "sc-1nn7cfv-0"
})(["", ";", ";font-feature-settings:", ";", " text-align:", ";vertical-align:middle;padding:", ";color:", ";&:first-child{@media (max-width:", "){padding-inline-start:", ";}}&:last-child{@media (max-width:", "){padding-inline-end:", ";}}&:focus{outline:none;color:", ";", ";}", ";> ", ",> ", ",> ", " span:not(", ") ", "{position:relative;top:3px;}"], rootStyle(), viola(), function (props) {
  return props.numerical ? "'tnum'" : undefined;
}, function (props) {
  return props.highlight && violaBold();
}, function (props) {
  if (props.align === 'left') {
    return 'start';
  } else if (props.align === 'right') {
    return 'end';
  }
  return props.align;
}, function (props) {
  return props.condensed ? 0 : cssSpacing('tighter', '12px');
}, function (props) {
  return props.highlight ? cssColorValue(semanticColors.textBase) : 'inherit';
}, screenXsMax, function (props) {
  return props.condensed ? 0 : cssSpacing('tighter-4', '4px');
}, screenXsMax, function (props) {
  return props.condensed ? 0 : cssSpacing('tighter-4', '4px');
}, cssColorValue(semanticColors.textBase), violaBold(), function (props) {
  return props.truncate && css(["", ";max-inline-size:0;inline-size:100%;@media (min-width:", "){max-inline-size:150px;inline-size:auto;}@media (min-width:", ") and (max-width:", "){max-inline-size:85px;inline-size:auto;}"], textOverflow(), screenMdMin, screenSmMin, screenSmMax);
}, Svg, Button, Trigger, Overlay, Button);