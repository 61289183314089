import React from 'react';
import ContactsQuery from '@admin/graphql-api/_old_queries/ContactsQuery';
import {graphql} from '@apollo/client/react/hoc';
import exporter from '@core/lib/exporter';
import {Element} from '@core/style';
import {BLUE, TEXT_MUTED} from '@core/style';
import {buttonRule} from '@core/ui/Button';
import {Card, PageTitle} from '@core/ui/Content';
import DataTable, {MultipleSelectFilter} from '@core/ui/DataTable';
import Loading from '@core/ui/Loading';

const Contacts = ({data: {loading, admin}}) => {
  if (loading) {
    return <Loading absolute />;
  }

  const columns = [
    {
      title: 'Source',
      accessor: 'source',
      type: 'string',
      Filter: MultipleSelectFilter,
    },
    {
      title: 'First Name',
      accessor: 'firstName',
      type: 'string',
    },
    {
      title: 'Last Name',
      accessor: 'lastName',
      type: 'string',
    },
    {
      title: 'Email',
      accessor: 'email',
      type: 'string',
      Cell: ({data: {email, domain}}) => (
        <Element rules={() => ({display: 'flex', alignItems: 'center'})}>
          {email}
          {domain.indexOf('gmail') < 0 ? (
            <Element
              tag='a'
              target='_blank'
              href={domain}
              onClick={(evt) => evt.stopPropagation()}
              rules={() => ({
                color: TEXT_MUTED,
                display: 'flex',
                marginLeft: '0.5rem',
                ':hover': {
                  color: BLUE,
                },
              })}>
              <svg
                height='18'
                viewBox='0 0 24 24'
                width='18'
                xmlns='http://www.w3.org/2000/svg'>
                <path d='M19 6.41L8.7 16.71a1 1 0 1 1-1.4-1.42L17.58 5H14a1 1 0 0 1 0-2h6a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V6.41zM17 14a1 1 0 0 1 2 0v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7c0-1.1.9-2 2-2h5a1 1 0 0 1 0 2H5v12h12v-5z' />
              </svg>
            </Element>
          ) : null}
        </Element>
      ),
    },
    {
      title: 'Company',
      accessor: 'company',
      type: 'string',
      Filter: MultipleSelectFilter,
    },
    {
      title: 'Role',
      accessor: 'role',
      type: 'string',
    },

    {
      title: 'Ignored',
      accessor: 'ignored',
      type: 'bool',
      Cell: ({data: {ignored}}) => <span>{ignored ? '\u2705' : ''}</span>,
    },

    {
      title: 'Contacted',
      accessor: 'contacted',
      type: 'bool',
      Cell: ({data: {contacted}}) => <span>{contacted ? '\u2705' : ''}</span>,
    },
  ];

  const data = admin.contacts.map(
    ({ignoredBy, contactedBy, email, ...props}) => ({
      ignored: ignoredBy ? true : false,
      contacted: contactedBy ? true : false,
      email,
      domain: `https://${email.split('@')[1]}`,
      ...props,
    })
  );

  return (
    <>
      <PageTitle
        rightContent={
          <Element
            tag='a'
            href={exporter('admin', 'contacts', 'n/a')}
            style-primary
            rules={[buttonRule]}>
            Export All
          </Element>
        }>
        Inbox
      </PageTitle>
      <Card>
        <DataTable
          columns={columns}
          data={data}
          orderBy='-createdAt'
          searchPlaceholder='Search'
          searchKeys={['firstName', 'lastName', 'company', 'email']}
          filtersStorageKey='admin-contacts'
          onClickRowPath={({id}) => `/contacts/${id}`}
        />
      </Card>
    </>
  );
};

export default graphql(ContactsQuery, {
  options: {
    fetchPolicy: 'network-only',
  },
})(Contacts);
