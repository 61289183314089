/**
 * The disconnection reason.
 */
export enum DisconnectionReason {
  /**
   * When the reconnection is failed.
   */
  RECONNECTION_FAILED = 'reconnection_failed',

  /**
   * When the `disconnect` or `forceDisconnect` method is called.
   */
  EXPLICIT = 'explicit',

  /**
   * When backend sends logout message.
   */
  LOG_OUT = 'log_out',

  /**
   * When the browser about to close.
   */
  WINDOW_BEFORE_UNLOAD = 'window_before_unload',
}
