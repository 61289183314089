import React, {ReactNode, useContext} from 'react';
import {TRule, combineRules} from 'fela';
import {RendererContext} from 'react-fela';
import {useTheme} from '@core/style';
import {TRules} from './css';
import {CustomTheme, THEMES} from './themes';

interface IKeyframe {
  [stop: string]: {
    [propName: string]: string | number;
  };
}

const flattenDeep = (arr) =>
  arr.reduce(
    (acc, val) =>
      Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val),
    []
  );

const getCombinedRules = ({rules, parentRules}) => {
  let myRules: any[] = [];
  if (Array.isArray(rules)) {
    myRules = rules.flat(10).filter(Boolean);
  } else if (typeof rules === 'function') {
    myRules = [rules];
  }

  return combineRules(...flattenDeep(parentRules.concat(myRules)));
};

interface StyledElementProps {
  children?: ReactNode;
  domRef?: React.Ref<HTMLElement>;
  keyframes?: {[animationName: string]: () => IKeyframe};
  tag?: string;
  rules?: TRules;
  [x: string]: any;
}

/**
 * @deprecated
 */
export const createStyledElement = (
  parentTag?: string | React.FC,
  ...parentRules: TRule[]
) => {
  const StyledElement = ({
    children,
    domRef,
    keyframes,
    rules,
    tag,
    ...props
  }: StyledElementProps) => {
    const themeName = useTheme();
    const theme = themeName ? THEMES[themeName] : THEMES.light;
    const renderer = useContext(RendererContext);
    const combinedRules = getCombinedRules({rules, parentRules});
    const rulesProps: {
      theme: CustomTheme;
      themeName: 'dark' | 'light';
      [key: string]: any;
    } = {theme, themeName};
    const elementProps: any = {};

    for (const propName in props) {
      if (props.hasOwnProperty(propName)) {
        const match = /style-(.*)/.exec(propName);

        if (match) {
          rulesProps[match[1]] = props[propName];
        } else if (
          ['tag', 'rules', 'domRef', 'keyframes'].indexOf(propName) < 0
        ) {
          elementProps[propName] = props[propName];
        }
      }
    }
    // React ref
    if (domRef) {
      elementProps.ref = domRef;
    }

    // Keyframes
    if (keyframes) {
      for (const key in keyframes) {
        if (keyframes.hasOwnProperty(key)) {
          rulesProps[key] = renderer.renderKeyframe(keyframes[key], rulesProps);
        }
      }
    }
    elementProps.className = renderer.renderRule(combinedRules, rulesProps);

    return React.createElement(
      tag || parentTag || 'div',
      elementProps,
      children
    );
  };

  return StyledElement;
};
