import {gql} from '@apollo/client';

export default gql`
  query getTags($query: String) {
    admin {
      tags(query: $query) {
        id
        label
        slug
      }
    }
  }
`;
