import Reporter from './reporters/Reporter';
import logger from '@spotify-internal/isomorphic-logger';
import { NoopReporter } from './reporters/Noop';

// enum would be nice but we need to pass a string to metric_type in createSemanticMetricClient()....
// https://github.com/spotify/semantic-metrics#metric-types
export const MetricTypes = {
  COUNTER: 'counter',
  GAUGE: 'gauge',
  // METER: 'meter', // use counters and ratePerSecond aggregation in Heroic
  // HISTOGRAM: 'histogram' // not yet supported in Grafana
  TIMER: 'timer',
} as const;

export type Tags = Record<string, string> | null;

export type Nanoseconds<T = number> = T & { _isNanoseconds: true };
export function asNanoseconds(n: number) {
  return n as Nanoseconds;
}

export type TimerMetric = {
  metric_type: 'timer';
  value: Nanoseconds;
};

export type NumericMetric = {
  metric_type: 'counter' | 'gauge';
  value: number;
};

type BaseMetricProps = {
  key?: string;
  component_id?: string;
  what: string;
  tags?: Tags;
  timestamp?: Number;
};

export type Metric = BaseMetricProps & (TimerMetric | NumericMetric);

export type BatchingOptions = {
  /**
   * @type {number} delay in milliseconds
   */
  delay: number;
};

export type SemanticMetricsConfig = {
  component_id?: string;
  app?: string;
  key?: string;
  reporter: Reporter | Reporter[];
  dev?: boolean;
  batching?: false | BatchingOptions;
};

const reportMetrics = async (
  metrics: Metric[],
  config: SemanticMetricsConfig,
): Promise<void> => {
  if (Array.isArray(config.reporter)) {
    const reporterPromises = config.reporter.map(reporter =>
      reporter.send(metrics),
    );
    await Promise.all(reporterPromises);
  } else {
    await config.reporter.send(metrics);
  }
};

export type SemanticMetrics = {
  sendMetric(metric: Metric): Promise<void>;
  config: SemanticMetricsConfig;
  isWindowUnloading: boolean;
  flush: () => Promise<void>;
};

const createSemanticMetrics = (
  config: SemanticMetricsConfig,
): SemanticMetrics => {
  if (
    typeof config.dev !== 'undefined'
      ? config.dev
      : process.env &&
        (process.env.NODE_ENV === 'dev' ||
          process.env.NODE_ENV === 'development')
  ) {
    config.reporter = new NoopReporter();
  }

  const Batching: {
    queue: Metric[];
    timeout?: ReturnType<typeof setTimeout>;
    flush: () => Promise<void>;
  } = {
    queue: [],
    timeout: undefined,
    async flush() {
      await reportMetrics(this.queue, config);
      this.queue = [];
      this.timeout = undefined;
    },
  };

  /**
   * Keeps track on if the user closed the tab or is navigating away. The next metric to be sent
   * must be sent immeditately, even if batching is enabled
   * @static
   * @memberof SemanticMetrics
   */
  let isWindowUnloading = false;
  // Enable batching by default if not specified in options
  if (typeof config.batching === 'undefined') {
    config.batching = { delay: 4000 };
  }

  if (
    config.batching &&
    typeof window === 'object' &&
    typeof window.addEventListener === 'function'
  ) {
    window.addEventListener('beforeunload', () => {
      isWindowUnloading = true;
      Batching.flush();
    });
  }

  const sendMetric = async (metric: Metric): Promise<void> => {
    const clonedMetric = { ...metric };
    if (metric.tags) {
      clonedMetric.tags = { ...metric.tags };
    }

    clonedMetric.key = clonedMetric.key || config.key;
    if (!clonedMetric.key) {
      throw new Error(
        `metric.key is required. Got: "${clonedMetric.key}". Set it in createSemanticMetrics(...) or pass it to sendMetric(...).`,
      );
    }

    clonedMetric.tags = {
      app: config.app ?? clonedMetric.key,
      application: config.app ?? clonedMetric.key,
      ...clonedMetric.tags,
    };

    if (config.component_id) {
      clonedMetric.component_id =
        clonedMetric.component_id || config.component_id;
    } else {
      clonedMetric.component_id = config.key;
    }

    if (config.batching && !isWindowUnloading) {
      Batching.queue.push(clonedMetric);
      if (!Batching.timeout) {
        Batching.timeout = setTimeout(
          () => Batching.flush(),
          config.batching.delay,
        );
      }
    } else {
      await reportMetrics([clonedMetric], config);
    }

    return;
  };

  const flush = () => {
    if (config.batching) {
      return Batching.flush();
    }
    logger.warn(
      'Manual flushing only supported in batch mode. Set config.batching: true',
    );

    return Promise.resolve();
  };

  return {
    sendMetric,
    config,
    isWindowUnloading,
    flush,
  };
};

export { createSemanticMetrics, reportMetrics };

// Support the old singleton way
export default class SemanticMetricsSingleton {
  static isWindowUnloading: boolean;
  static config: SemanticMetricsConfig;
  static sendMetric: Function;
  static init(config: SemanticMetricsConfig) {
    if (SemanticMetricsSingleton.config) {
      logger.error(
        'SemanticMetrics has already been initialized. This may lead to dangerous side effects such as your metrics disappearing from Grafana due to the `key` value being overwritten by something else',
      );
    }
    logger.warn(
      'The singleton usage of SemanticMetrics will be deprecated. Please use createSemanticMetrics()',
    );
    SemanticMetricsSingleton.config = config;
    const { sendMetric, isWindowUnloading } = createSemanticMetrics(config);
    SemanticMetricsSingleton.sendMetric = sendMetric;
    SemanticMetricsSingleton.isWindowUnloading = isWindowUnloading;
  }
}
const sendMetricSingleton = async (metric: Metric): Promise<void> => {
  if (!SemanticMetricsSingleton.config) {
    throw new Error(
      'SemanticMetrics has not been initialized. Call SemanticMetrics.init()',
    );
  }
  return SemanticMetricsSingleton.sendMetric(metric);
};
export { sendMetricSingleton as sendMetric };
