import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Prefixeless from './Prefixeless';

export default function () {
  return (
    <Switch>
      <Route exact path='/prefixless' component={Prefixeless} />
    </Switch>
  );
}
