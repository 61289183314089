import React, {FC} from 'react';
import {CleanLink, LinkProps} from '../../Link';

/** @deprecated */
export const DataTableLink: FC<LinkProps> = ({
  onClick,
  ...props
}: LinkProps) => (
  <CleanLink
    onClick={(evt) => {
      evt.stopPropagation();
      onClick?.(evt);
    }}
    css={`
      :hover {
        color: var(--blue);
        text-decoration: underline;
      }
    `}
    {...props}
  />
);

/** @deprecated */
export const DataTableLinkButton = ({...props}: LinkProps) => (
  <DataTableLink
    css={`
      background: var(--bg-surface);
      border: 0.0625rem solid var(--border-default);
      border-radius: 0.5rem;
      color: inherit;
      padding: 0.25rem 0.75rem 0.3125rem;
      white-space: nowrap;
      :hover {
        color: var(--blue);
        text-decoration: none;
      }
    `}
    {...props}
  />
);
