import React from 'react';
import PrefixlessCampaignPodcastsQuery from '@admin/graphql-api/_old_queries/PrefixlessCampaignPodcasts';
import {graphql} from '@apollo/client/react/hoc';
import dayjs from '@core/lib/dayjs';
import {BLUE} from '@core/style';
import {Card, PageTitle} from '@core/ui/Content';
import DataTable, {
  DataTableLink,
  DataTableLinkButton,
  MultipleSelectFilter,
  SelectFilter,
} from '@core/ui/DataTable';
import Loading from '@core/ui/Loading';

const Prefixless = ({data: {loading, admin}}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const columns = [
    {
      title: 'Publisher',
      accessor: 'organizationName',
      type: 'string',
      Filter: MultipleSelectFilter,
    },
    {
      title: 'Advertiser',
      accessor: 'advertiserName',
      type: 'string',
      Filter: MultipleSelectFilter,
    },

    {
      title: 'Campaign',
      accessor: 'campaignName',
      type: 'string',
      Filter: MultipleSelectFilter,
    },
    {
      title: 'Podcast',
      accessor: 'feedTitle',
      type: 'string',
      Filter: MultipleSelectFilter,
    },
    {
      title: 'RSS',
      accessor: 'href',
      type: 'string',
      Cell: ({data: {href}}) => (
        <DataTableLink
          href={href}
          target='_blank'
          css={`
            color: var(--blue);
          `}>
          {href}
        </DataTableLink>
      ),
    },

    {
      title: 'Drops',
      accessor: 'drops',
      type: 'number',
      Filter: SelectFilter,
    },

    {
      title: 'First Drop',
      accessor: 'firstDrop',
      type: 'date',
      fmt: (v) =>
        v ? dayjs(v).format('MMM DD, YYYY') : 'No Upcoming Episodes',
    },

    {
      title: 'Created',
      accessor: 'createdAt',
      type: 'date',
      fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
    },

    {
      title: 'View',
      accessor: 'url',
      type: 'string',
      Cell: ({data: {url}}) => (
        <DataTableLinkButton href={url} target='_blank'>
          Open
        </DataTableLinkButton>
      ),
    },
  ];

  const data = admin.prefixlessCampaignPodcasts.map(
    ({
      id,
      campaign: {name, slug, advertiser, organization},
      feed: {title, href},
      createdAt,
      campaignEpisodes,
    }) => ({
      id,
      campaignName: name,
      createdAt,
      href,
      feedTitle: title,
      advertiserName: advertiser.name,
      organizationName: organization.name,
      drops: campaignEpisodes.filter(({episode}) => !!episode).length,
      firstDrop: campaignEpisodes
        .map(({episode, futureEpisode}) =>
          episode ? episode.published : futureEpisode.expectedPublished
        )
        .reduce(
          (min, published) =>
            min ? (min > published ? published : min) : published,
          null
        ),
      url: `https://dash.podsights.com/p/${organization.slug}/campaigns/${slug}/podcasts/${id}`,
    })
  );

  return (
    <div>
      <PageTitle>Prefixless Campaign Podcasts</PageTitle>
      <Card>
        <DataTable
          columns={columns}
          data={data}
          orderBy='-createdAt'
          filtersStorageKey='admin-prefixless'
        />
      </Card>
    </div>
  );
};

export default graphql(PrefixlessCampaignPodcastsQuery, {
  options: {
    fetchPolicy: 'network-only',
  },
})(Prefixless);
