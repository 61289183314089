/**
 * Standard methods for Hermes and HTTP requests.
 *
 * @enum {string}
 */
export enum Method {
  DELETE = 'DELETE',
  GET = 'GET',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE',
}
