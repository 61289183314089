import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Reviews from './Reviews';

export default function () {
  return (
    <Switch>
      <Route exact path='/reviews' component={Reviews} />
    </Switch>
  );
}
