import Review from '@admin/components/Review';
import ReviewsQuery from '@admin/graphql-api/_old_queries/ReviewsQuery';
import {graphql} from '@apollo/client/react/hoc';
import {Element} from '@core/style';
import Loading from '@core/ui/Loading';

const Reviews = ({data: {loading, admin}}) => {
  if (loading) {
    return <Loading centered />;
  }

  const {reviews} = admin;

  return (
    <Element rules={() => ({maxWidth: '700px', margin: '50px auto'})}>
      <Element rules={() => ({display: 'flex'})}>
        <Element tag='h5' rules={() => ({flex: 1})}>
          Reviews
        </Element>
      </Element>
      {reviews.slice(0).map((review) => {
        return (
          <Element key={review.id} rules={() => ({display: 'flex'})}>
            <Review review={review} key={review.id} />
          </Element>
        );
      })}
    </Element>
  );
};

export default graphql(ReviewsQuery)(Reviews);
