import { cssColorValue, semanticColors } from "../../styles/index.js";
import { TagInner } from "./TagInner.js";
import styled, { css } from "../../styled-components.js";

/* TagInnerSelected is a variation of TagInner with some styling differences.
 * It is themable using Tag's selectedColorSet prop.
 */
export var TagInnerSelected = styled(TagInner).withConfig({
  displayName: "TagInnerSelected",
  componentId: "sc-19i8max-0"
})(["", ""], function (_ref) {
  var fallbackSet = _ref.fallbackSet;
  return css(["background-color:", ";color:", ";"], cssColorValue(semanticColors.backgroundBase, fallbackSet.background.base), cssColorValue(semanticColors.textBase, fallbackSet.text.base));
});