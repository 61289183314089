import React from 'react';
import UpdateCompanyMutation from '@admin/graphql-api/mutations/UpdateCompany';
import CompanyQuery from '@admin/graphql-api/queries/AdminCompanyQuery';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import {ImageUploader} from '@core/components/Uploader';
import Loading from '@core/ui/Loading';

class CompanyImage extends React.Component {
  state = {
    loading: false,
    complete: false,
  };

  onChange = (action, media) => {
    const {
      updateCompany,
      company: {id, name, slug},
    } = this.props;

    if (action == 'added') {
      this.setState({loading: true});
    }

    if (action == 'uploaded') {
      updateCompany({
        id,
        name,
        slug,
        imageId: media.object.id,
      }).then((resp) => {
        this.setState({loading: false, complete: 'true'});
      });
    }
  };

  render() {
    const {company} = this.props;
    const {loading, complete} = this.state;

    if (complete) {
      return <Redirect to={`/companies/${company.id}`} />;
    }

    return (
      <div>
        {loading && <Loading absolute />}
        <ImageUploader onChange={this.onChange} />
      </div>
    );
  }
}

export default graphql(UpdateCompanyMutation, {
  props: ({mutate}) => ({
    updateCompany: (input) => {
      return mutate({
        variables: {
          input,
        },
        update: (
          store,
          {
            data: {
              updateCompany: {
                company: {id, image},
              },
            },
          }
        ) => {
          // Stuff the new journey into the cache.
          const query = {
            query: CompanyQuery,
            variables: {
              id,
            },
          };

          query.data = store.readQuery(query);

          query.data.admin.company.image = Object.assign(
            {},
            query.data.admin.company.image,
            image
          );

          store.writeQuery(query);
        },
      });
    },
  }),
})(CompanyImage);
