import {MouseEventHandler} from 'react';
import {ButtonPrimary, ProgressCircle} from '@spotify-internal/encore-web';
import styled from 'styled-components';
import {BackButton} from './AccountLayout';

interface BoxProps {
  centered?: boolean;
  wide?: boolean;
}

const Box = styled.div<BoxProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({centered}) => (centered ? 'center' : 'flex-start')};
  gap: 16px;
  width: ${({wide}) => (wide ? '650px' : '450px')};
  box-shadow: 0px 4px 12px 0px #00000033;
  padding: 32px 36px;
  border-radius: 16px;
  @media (max-width: 768px) {
    width: 340px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px 16px;
  }
`;

const BoxTitle = styled.div<BoxProps>`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  width: 100%;
  text-align: ${({centered}) => (centered ? 'center' : 'left')};
`;

interface BoxBodyProps {
  fixed?: boolean;
  bodyLeftAligned?: boolean;
}

const BoxBody = styled.div<BoxBodyProps>`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  ${({fixed}) => fixed && 'min-height: 120px;'}
  text-align: ${({bodyLeftAligned}) => (bodyLeftAligned ? 'left' : 'center')};
`;

const Footer = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6a6a6a;
  text-align: center;
  justify-content: center;
`;

const FooterLink = styled.button`
  color: #6a6a6a;
  text-decoration: underline;
  margin-left: 2px;
  font-weight: 700;
`;

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
`;

interface ConfirmationDialogProps {
  imageSrc?: string | JSX.Element;
  boxTitle: string | JSX.Element;
  boxBody?: string | JSX.Element;
  onContinue?: MouseEventHandler;
  onBackButtonClick?: MouseEventHandler;
  backButtonLabel?: string;
  footerText?: string;
  footerLinkText?: string;
  onFooterLinkClick?: MouseEventHandler;
  ctaLabel?: string;
  ctaIsLoading?: boolean;
  centered?: boolean;
  wide?: boolean;
  fixedHeight?: boolean;
  ctaDisabled?: boolean;
  bodyLeftAligned?: boolean;
  preTitle?: string | JSX.Element;
}

const PreHeader = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #6a6a6a;
`;

const ConfirmationDialog = ({
  imageSrc,
  boxTitle,
  boxPreTitle,
  boxBody,
  bodyLeftAligned = false,
  onContinue = () => {},
  backButtonLabel,
  footerText,
  footerLinkText,
  onFooterLinkClick,
  onBackButtonClick,
  ctaLabel,
  ctaIsLoading = false,
  centered = true,
  wide = false,
  fixedHeight = false,
  ctaDisabled = false,
}: ConfirmationDialogProps) => {
  return (
    <Box centered={centered} wide={wide}>
      {imageSrc && typeof imageSrc === 'string' ? (
        <img src={imageSrc} alt={boxTitle} />
      ) : (
        imageSrc
      )}
      {boxPreTitle && typeof boxPreTitle === 'string' ? (
        <PreHeader>{boxPreTitle}</PreHeader>
      ) : (
        boxPreTitle
      )}
      {boxTitle ? (
        typeof boxTitle === 'string' ? (
          <BoxTitle centered={centered}>{boxTitle}</BoxTitle>
        ) : (
          boxTitle
        )
      ) : null}

      {boxBody && (
        <BoxBody fixed={fixedHeight} bodyLeftAligned={bodyLeftAligned}>
          {boxBody}
        </BoxBody>
      )}
      {footerText ? (
        <Footer>
          {footerText}
          {footerLinkText && (
            <FooterLink onClick={onFooterLinkClick}>
              {footerLinkText}
            </FooterLink>
          )}
        </Footer>
      ) : null}
      <ButtonContainer>
        {backButtonLabel ? (
          <BackButton label={backButtonLabel} onClick={onBackButtonClick} />
        ) : null}
        {ctaLabel && (
          <ButtonPrimary
            onClick={onContinue}
            disabled={ctaDisabled || ctaIsLoading}>
            {ctaIsLoading ? <ProgressCircle /> : ctaLabel}
          </ButtonPrimary>
        )}
      </ButtonContainer>
    </Box>
  );
};

export default ConfirmationDialog;
