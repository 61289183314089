import { borderRadiusRounded, cssColorValue } from "../../styles/index.js";
import styled from "../../styled-components.js";
export var ProgressBarIndicator = styled.div.attrs(function (props) {
  return {
    // Every change to props causes styled components to create a new CSS class and inject it into the DOM
    // For values that can change frequently and in small increments, like progressValue, it is better to
    // update the inline style property
    style: {
      inlineSize: "".concat(props.progressValue, "%")
    }
  };
}).withConfig({
  displayName: "ProgressBarIndicator",
  componentId: "sc-1b6tcn5-0"
})(["background-color:", ";block-size:6px;border-radius:", ";"], function (props) {
  return cssColorValue(props.progressColor);
}, borderRadiusRounded);