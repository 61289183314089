import {FeedOptionsDropdown} from '@admin/components/FeedOptionsDropdown';
import {
  Tag,
  TextLink,
  Type,
  semanticColors,
} from '@spotify-internal/encore-web';
import EpisodesTable from '@core/components/EpisodesTable';
import {Card, CardHeader} from '@core/ui/Content';
import DetailsCard from '@core/ui/DetailsCard';
import {EpisodeImage} from '@core/ui/Image';
import {Link} from '@core/ui/Link';

export function Feed({feed}) {
  return (
    <div>
      <div
        css={{
          border: 0,
          display: 'grid',
          gridGap: '1.5rem',
          gridTemplateColumns: '1fr 28rem',
          margin: '0 1.5rem 0',
        }}>
        <Card>
          <EpisodeImage
            episode={feed?.episodes?.[0]}
            width={150}
            rules={() => ({
              width: 'auto',
              height: '8rem',
              marginRight: '1.25rem',
            })}
          />
          <div>
            <Type as='h4'>{feed.title}</Type>
            <Type as='p' semanticColor={semanticColors.textSubdued}>
              {feed.subtitle}
            </Type>
            <FeedOptionsDropdown feed={feed} />
          </div>
        </Card>
        <DetailsCard
          data={[
            {
              title: 'State',
              value: <Tag>{feed.state}</Tag>,
            },
            {
              title: 'Measure Ads',
              value: (
                <Type>
                  {feed.measure ? 'Yes' : 'No'}
                  <Type as='span' css={{fontStyle: 'italic'}}>
                    {' '}
                    (Ad pull set manually)
                  </Type>
                </Type>
              ),
            },
            {
              title: 'Processing Ads',
              value: (
                <Type>
                  {feed.processing ? 'Yes' : 'No'}
                  <Type as='span' css={{fontStyle: 'italic'}}>
                    {' '}
                    (Ad pull due to popularity)
                  </Type>
                </Type>
              ),
            },
            {
              title: 'Has No Ads',
              value: feed.hasNoAds ? 'Yes' : 'No',
            },
            {
              title: 'RSS Feed',
              value: (
                <TextLink href={feed.href} target='_blank'>
                  {feed.href}
                </TextLink>
              ),
            },
          ]}
        />
      </div>
      {feed.podcast && (
        <>
          <Card>
            <CardHeader>Organizations</CardHeader>
            <Link to={`/organizations/${feed.podcast.organization.id}`}>
              {feed.podcast.organization.name}
            </Link>
          </Card>
        </>
      )}
      <Card>
        <CardHeader>Episodes</CardHeader>
        <EpisodesTable episodes={feed.episodes} />
      </Card>
    </div>
  );
}
