import React from 'react';
import DataTable, {DataTableLinkButton} from '@core/ui/DataTable';

const parseData = (array, organizationId) => {
  return array.map((data) =>
    Object.assign({}, data, {
      to: `/organizations/${organizationId}/permissions/${data.id}`,
      organizationName: data.organization.name,
    })
  );
};

const OrganizationPermissionsTable = ({
  organization,
  reversePermissions = false,
}) => {
  const columns = [
    {
      title: 'Object',
      accessor: 'contentObjectName',
      type: 'string',
    },
    {
      title: 'Name',
      accessor: 'name',
      type: 'string',
    },
    {
      title: 'Permission',
      accessor: 'permission',
      type: 'string',
    },
    {
      title: 'Organization',
      accessor: 'organizationName',
      type: 'string',
    },
    {
      Cell: ({data: {to}}) =>
        to ? <DataTableLinkButton to={to}>Remove</DataTableLinkButton> : null,
    },
  ];

  return (
    <DataTable
      searchKeys={['contentObjectName', 'name', 'organizationName']}
      data={
        reversePermissions
          ? parseData(organization.reversePermissions, organization.id)
          : parseData(organization.permissions, organization.id)
      }
      columns={columns}
      orderBy='-name'
    />
  );
};

export default OrganizationPermissionsTable;
