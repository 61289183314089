import {gql} from '@apollo/client';

export default gql`
  mutation createImage($input: CreateImageInput!) {
    authed {
      success
    }
    createImage(input: $input) {
      image {
        id
        contentType
        width
        height
        size
        path
      }
    }
  }
`;
