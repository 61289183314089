/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import CreateInvoice from '@admin/graphql-api/_old_mutations/CreateInvoice';
import HideLineItem from '@admin/graphql-api/_old_mutations/HideLineItem';
import UpdateInvoice from '@admin/graphql-api/_old_mutations/UpdateInvoice';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import dayjs from '@core/lib/dayjs';
import {Element} from '@core/style';
import {Button} from '@core/ui/Button';
import DataTable from '@core/ui/DataTable';

class Invoice extends React.Component {
  state = {
    loading: false,
    complete: false,
    errors: [],
  };

  hideLineItem = (id) => {
    const {hideLineItem} = this.props;

    this.setState({
      loading: true,
    });

    hideLineItem(id).then(() => {
      this.setState({
        loading: false,
      });
    });
  };

  updateSent = () => {
    const {updateInvoice} = this.props;

    this.setState({
      loading: true,
    });

    updateInvoice({sent: dayjs().format('YYYY-MM-DD')}).then(() => {
      this.setState({
        loading: false,
      });
    });
  };

  updatePaid = () => {
    const {updateInvoice} = this.props;

    this.setState({
      loading: true,
    });

    updateInvoice({paid: dayjs().formaT('YYYY-MM-DD')}).then(() => {
      this.setState({
        loading: false,
      });
    });
  };

  createInvoice = () => {
    const {createInvoice} = this.props;

    this.setState({
      loading: true,
    });

    createInvoice().then(() => {
      this.setState({
        loading: false,
      });
    });
  };

  render() {
    const {invoice} = this.props;
    const {complete, loading, errors} = this.state;

    const {
      id,
      lineItems,
      organization,
      number,
      periodStart,
      periodEnd,
      created,
      due,
      sent,
      paid,
    } = invoice;

    const columns = [
      {
        title: 'Description',
        accessor: 'id',
        type: 'string',
        Cell: ({data: {id, description}}) => <p>{description}</p>,
      },
      {
        title: 'Quantity',
        accessor: 'quantity',
        type: 'number',
      },
      {
        title: 'Rate',
        accessor: 'rate',
        type: 'number',
      },
      {
        title: 'Cost',
        accessor: 'cost',
        type: 'number',
      },
      {
        title: 'Hidden',
        accessor: 'hidden',
        type: 'string',
        Cell: ({data: {id, hidden}}) => (
          <div>
            {hidden ? (
              <Button variant='outlined' onClick={() => this.hideLineItem(id)}>
                Show line item
              </Button>
            ) : (
              <Button color='error' onClick={() => this.hideLineItem(id)}>
                Hide line item
              </Button>
            )}
          </div>
        ),
      },
    ];

    return (
      <Element>
        <h2>{organization.name}</h2>
        <h3>Invoice {number}</h3>

        <Button onClick={this.updateSent}>Sent</Button>
        <Button onClick={this.updatePaid}>Paid</Button>
        <Button onClick={this.createInvoice}>Create</Button>

        <ul>
          <li>
            <a href=''>Download PDF</a>
          </li>
          <li>Period start: {periodStart}</li>
          <li>Period end: {periodEnd}</li>
          <li>Created: {created}</li>
          <li>Due: {due}</li>
          <li>Sent: {sent}</li>
          <li>Paid: {paid}</li>
        </ul>
        <Element>
          <DataTable columns={columns} data={lineItems} orderBy='id' />
        </Element>
      </Element>
    );
  }
}

export default compose(
  graphql(HideLineItem, {
    props: ({ownProps: {invoice}, mutate}) => ({
      hideLineItem: (lineItemId) => {
        return mutate({
          variables: {
            input: {
              lineItemId: lineItemId,
            },
          },
          // update: (store, { data: { hideLineItem } }) => {
          //   const query = {
          //     query: FeedQuery,
          //     variables: { id: feed.id }
          //   }

          //   query.data = store.readQuery(query)
          //   query.data.feed.processing = true
          //   store.writeQuery(query)
          // }
        });
      },
    }),
  }),
  graphql(UpdateInvoice, {
    props: ({ownProps: {invoice}, mutate}) => ({
      updateInvoice: (input) => {
        input.invoiceId = invoice.id;

        return mutate({
          variables: {
            input: input,
          },
          // update: (store, { data: { measureFeed } }) => {
          //   const query = {
          //     query: FeedQuery,
          //     variables: { id: feed.id }
          //   }

          //   query.data = store.readQuery(query)
          //   query.data.feed.measure = true
          //   store.writeQuery(query)
          // }
        });
      },
    }),
  }),
  graphql(CreateInvoice, {
    props: ({ownProps: {invoice}, mutate}) => ({
      createInvoice: () => {
        return mutate({
          variables: {
            input: {
              invoiceId: invoice.id,
            },
          },
          // update: (store, { data: { measureFeed } }) => {
          //   const query = {
          //     query: FeedQuery,
          //     variables: { id: feed.id }
          //   }

          //   query.data = store.readQuery(query)
          //   query.data.feed.measure = true
          //   store.writeQuery(query)
          // }
        });
      },
    }),
  })
  // graphql(MeasureFeed, {
  //   props: ({ ownProps: { feed }, mutate }) => ({
  //     measureFeed: () => {
  //       return mutate({
  //         variables: {
  //           input: {
  //             feedId: feed.id
  //           }
  //         },
  //         update: (store, { data: { measureFeed } }) => {
  //           const query = {
  //             query: FeedQuery,
  //             variables: { id: feed.id }
  //           }

  //           query.data = store.readQuery(query)
  //           query.data.feed.measure = true
  //           store.writeQuery(query)
  //         }
  //       })
  //     }
  //   })
  // })
)(Invoice);
