import {useEffect, useState} from 'react';
import {
  ButtonPrimary,
  IconArrowTopRight,
  IconSpotifyLogo,
  ProgressCircle,
  Text,
  TextLink,
} from '@spotify-internal/encore-web';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {loginViaSpotify} from '@core/components/SignInWithSpotify/auth';
import {
  HadAccountMessage,
  OnboardBodyContainer,
  OnboardContentContainer,
  OnboardPageContainer,
  OnboardTitleText,
} from '@analytics/components/onboard/OnboardLayout';
import SideGraphic from '@analytics/components/onboard/SideGraphic';
import {useUserInfoQuery} from '@analytics/graphql-api';
import SpotifyBanner from '../../components/onboard/SpotifyBanner';

enum IsLoadingStates {
  LoginLoading = 'LoginLoading',
  SignUpLoading = 'SignUpLoading',
}

const WelcomeScreen = () => {
  const [isLoading, setIsLoading] = useState<IsLoadingStates | null>(null);
  const {data: userData} = useUserInfoQuery();
  const history = useHistory();
  useEffect(() => {
    if (userData?.userInfo?.hasVerifiedEmailForSpaa) {
      history.push('/');
    }
  }, [userData?.userInfo?.hasVerifiedEmailForSpaa]);

  const onContinueLogin = async () => {
    setIsLoading(IsLoadingStates.LoginLoading);
    await loginViaSpotify();
  };

  return (
    <OnboardPageContainer>
      <OnboardContentContainer>
        <SpotifyBanner advertising position='flex-start' />
        <OnboardBodyContainer>
          <Text variant='bodySmall' style={{fontSize: 14}}>
            Welcome to Spotify Ad Analytics
          </Text>
          <OnboardTitleText
            variant='bodyMedium'
            style={{fontSize: 32, fontWeight: 700}}>
            Sign up for a free account
          </OnboardTitleText>
          <Text
            variant='bodySmall'
            style={{fontSize: 14, paddingTop: 44, paddingBottom: 44}}>
            Create a new Spotify account using your{' '}
            <strong>business email address</strong>, or log in to an account
            that uses the same email.
          </Text>
          <ButtonPrimary
            colorSet='invertedDark'
            iconLeading={IconSpotifyLogo}
            style={{width: '100%'}}
            disabled={isLoading === IsLoadingStates.LoginLoading}
            onClick={onContinueLogin}>
            Continue with Spotify
            {isLoading === IsLoadingStates.LoginLoading && (
              <ProgressCircle
                style={{
                  marginLeft: 8,
                }}
              />
            )}
          </ButtonPrimary>
          <HadAccountMessage />
        </OnboardBodyContainer>
      </OnboardContentContainer>
      <SideGraphic />
    </OnboardPageContainer>
  );
};

export default WelcomeScreen;
