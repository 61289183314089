import {css} from 'styled-components';

export const focusVisibleStyleObj = {
  boxShadow: 'inset 0 0 0 2px var(--white)',
  outline: '2px solid var(--primary)',
};

export const focusVisibleCss = css({
  '&:focus-visible': focusVisibleStyleObj,
});
