import {createStyledElement} from '@core/style';
import {
  BLUE,
  DARK_80,
  GREEN,
  HIGHLIGHTER,
  PINK,
  RED_ORANGE,
  lighten,
} from '@core/style';

const getColors = ({theme, outline}) => ({
  default: {
    ...(outline
      ? {
          background: 'none',
          borderColor: 'var(--border-default)',
          color: 'var(--text-muted)',
          hoverBackground: 'var(--bg-default)',
          hoverBorderColor: 'var(--blue)',
          hoverColor: 'var(--blue)',
        }
      : {
          background: 'none',
          borderColor: 'transparent',
          color: 'var(--text-muted)',
          hoverBackground: 'var(--bg-muted)',
          hoverColor: 'var(--blue)',
        }),
  },
  primary: {
    ...(outline
      ? {
          background: 'none',
          borderColor: GREEN,
          color: GREEN,
          hoverBackground: GREEN,
          hoverColor: '#fff',
        }
      : {
          background: GREEN,
          color: '#fff',
          hoverColor: '#fff',
        }),
  },
  secondary: {
    ...(outline
      ? {
          background: 'none',
          borderColor: 'var(--blue)',
          color: 'var(--blue)',
          hoverBackground: 'var(--blue)',
          hoverColor: '#fff',
        }
      : {
          background: 'var(--blue)',
          color: '#fff',
          hoverColor: '#fff',
        }),
  },
  callout: {
    background: HIGHLIGHTER,
    color: 'var(--text-default)',
    hoverColor: '#fff',
  },
  destructive: {
    ...(outline
      ? {
          background: 'none',
          borderColor: RED_ORANGE,
          color: RED_ORANGE,
          hoverBackground: RED_ORANGE,
          hoverColor: '#fff',
        }
      : {
          background: RED_ORANGE,
          color: '#fff',
        }),
  },
  // OTHER COLORS:
  dark: {
    background: theme.name === 'light' ? DARK_80 : '#fff',
    color: theme.name === 'light' ? '#fff' : 'var(--gray-9)',
    hoverBackground: theme.name === 'light' ? 'var(--black)' : 'var(--white)',
    hoverColor: theme.name === 'light' ? '#fff' : 'var(--gray-9)',
  },
  pink: {
    background: PINK,
    color: 'var(--text-default)',
    hoverColor: 'var(--text-default)',
  },
});

const sizes = {
  default: {
    borderRadius: '3.125rem',
    borderWidth: '0.125rem',
    fontSize: '0.9375rem',
    height: '2.375rem',
    padding: '0 1.25rem .0625rem',
  },
  small: {
    borderRadius: '0.375rem',
    borderWidth: '0.125rem',
    fontSize: '0.875rem',
    height: '2.125rem',
    padding: '0 1.125rem',
  },
  large: {
    borderRadius: '3.125rem',
    borderWidth: '0.125rem',
    fontSize: '1rem',
    height: '3rem',
    padding: '0 1.75rem .0625rem',
  },
};

const getTheme = ({large, small, outline, theme, props}) => {
  const styleName =
    ['primary', 'secondary', 'callout', 'destructive', 'dark', 'pink'].find(
      (name) => !!props[name]
    ) || 'default';

  let sizeName = 'default';

  if (large) {
    sizeName = 'large';
  } else if (small) {
    sizeName = 'small';
  }

  return {
    ...getColors({theme, outline})[styleName],
    ...sizes[sizeName],
  };
};

/**
 * @deprecated
 */
const buttonRule = ({large, small, outline, theme, ...props}) => {
  const {
    background,
    borderColor,
    borderRadius,
    borderWidth,
    color,
    fontSize,
    height,
    hoverBackground,
    hoverBorderColor,
    hoverColor,
    padding,
  } = getTheme({large, small, outline, theme, props});

  return {
    alignItems: 'center',
    background,
    borderColor: borderColor || 'transparent',
    borderRadius,
    borderStyle: 'solid',
    borderWidth,
    color,
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize,
    fontWeight: 500,
    height,
    justifyContent: 'center',
    lineHeight: 1,
    padding,
    textDecoration: 'none',
    transition: 'all 420ms cubic-bezier(.165,.84,.44,1)',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    ':hover:not([disabled])': {
      background: hoverBackground || lighten(background, 0.18),
      borderColor: hoverBorderColor || null,
      textDecoration: 'none',
    },
    ':hover': {
      color: hoverColor,
    },
    ':focus-visible': {
      boxShadow: 'inset 0 0 0 2px var(--white)',
      /* outline: '2px solid var(--blue)', */
    },
    '[disabled]': {
      cursor: 'default',
      opacity: '0.8',
      pointerEvents: 'none',
    },
  };
};

/**
 * @deprecated
 * This component is deprecated. Just use Stack and gap instead.
 */
const ButtonContainer = createStyledElement(
  'div',
  ({
    alignRight,
    spaceBetween,
    marginTop,
  }: {
    alignRight: any;
    spaceBetween: any;
    marginTop: any;
  }) => {
    let justifyContent = 'start';
    let childMargin = '0 0.75rem 0 0';

    if (alignRight) {
      justifyContent = 'flex-end';
      childMargin = '0 0 0 0.75rem';
    } else if (spaceBetween) {
      justifyContent = 'space-between';
      childMargin = '0';
    }

    return {
      display: 'flex',
      justifyContent,
      margin: marginTop ? '2rem 0 0 0' : '0',
      '> *': {
        margin: childMargin,
      },
    };
  }
);

/**
 * @deprecated
 * This component is deprecated.
 */
const Button = createStyledElement('button', buttonRule);

/**
 * @deprecated
 * This component is deprecated. Use ButtonIcon instead.
 */
const IconButton = createStyledElement('button', () => ({
  alignItems: 'center',
  background: 'none',
  border: 0,
  color: 'var(--icon-subtle)',
  cursor: 'pointer',
  display: 'flex',
  flexShrink: '0',
  outline: '0',
  padding: '0 0.25rem',
  ':hover': {
    color: 'var(--blue)',
  },
  ':hover > svg': {
    color: 'var(--blue)',
  },
}));

export {IconButton, buttonRule, ButtonContainer};

export default Button;
