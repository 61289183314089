import React from 'react';
import ProductDetailQuery from '@admin/graphql-api/_old_queries/ProductDetailQuery';
import {graphql} from '@apollo/client/react/hoc';
import {MiniPlayer} from '@core/components/AudioMiniPlayer';
import Episode from '@core/components/Episode';
import {Element} from '@core/style';
import Loading from '@core/ui/Loading';

class ProductDetail extends React.Component {
  render() {
    const {
      data: {loading, admin},
    } = this.props;

    if (loading) {
      return <Loading absolute />;
    }
    const {product} = admin;
    return (
      <div>
        <h3>{product.name}</h3>
        <div>
          {product.ads.map((ad) => {
            return (
              <Element key={ad.id}>
                <div>
                  {ad.placements.map((placement) => {
                    return (
                      <Episode key={placement.id} episode={placement.episode} />
                    );
                  })}
                </div>
                <Element>
                  <a href={`https://reviewers.podsights.com/r/ads/${ad.id}`}>
                    Review
                  </a>
                </Element>
                <MiniPlayer enclosure={ad} />
              </Element>
            );
          })}
        </div>
      </div>
    );
  }
}

export default graphql(ProductDetailQuery, {
  options: ({
    match: {
      params: {productId},
    },
  }) => ({
    variables: {
      id: productId,
    },
  }),
})(ProductDetail);
