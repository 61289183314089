import {scaleUtc} from 'd3-scale';
import {utcDay} from 'd3-time';
import dayjs from 'dayjs';

const STEPS = {
  minute: 60000,
  hour: 3600000,
  day: 86400000,
  week: 604800000,
};

export const getSeriesMax = (series) => {
  return Object.keys(series).reduce((max, key) => {
    const local = series[key].reduce((m, {y}) => (m < y ? y : m), 0);
    return max < local ? local : max;
  }, 0);
};

export const getSeriesLength = (series) => {
  return Object.keys(series).reduce((max, key) => {
    return series[key].length > max ? series[key].length : max;
  }, 0);
};

export const getSeriesInterval = (series) => {
  return getSeriesLength(series) < 72 ? 'hour' : 'day';
};

export const getSeriesRange = (series, interval) => {
  let start = null;
  let end = null;

  Object.keys(series).forEach((key) => {
    series[key].forEach(({hour, x}) => {
      const v = x ? x : dayjs(hour).valueOf();

      if (v < start || !start) {
        start = v;
      } else if (v > end || !end) {
        end = v;
      }
    });
  });

  start = dayjs(start).utc().startOf(interval).valueOf();
  end = dayjs(end).utc().endOf(interval).valueOf();

  return [start, end];
};

export const toTimeSeries = (data, interval, start, end) => {
  const inter = interval ? interval : 'day';

  const resp = data.reduce((obj, {x, y}) => {
    const key = dayjs(x).utc().startOf(inter).valueOf();

    if (key in obj) {
      obj[key] += y;
    } else {
      obj[key] = y;
    }
    return obj;
  }, {});

  const array = Object.keys(resp)
    .map((key) => {
      return {
        x: parseInt(key),
        y: resp[key],
      };
    })
    .sort((a, b) => {
      return a.x - b.x;
    });

  // If we don't have and array, deal with it.
  if (array.length == 0 && (!start || !end)) {
    return [];
  }

  let index = start ? dayjs(start).utc().startOf(inter).valueOf() : array[0].x;

  const stop = end
    ? dayjs(end).utc().endOf(inter).valueOf()
    : array[array.length - 1].x;

  const step = STEPS[inter];

  const series = [];

  while (index < stop) {
    // Deal with the start better.
    if (array.length && index > array[0].x) {
      array.shift();
      continue;
    } else if (array.length && index == array[0].x) {
      series.push(array.shift());
    } else {
      series.push({
        x: index,
        y: 0,
      });
    }
    index += step;
  }

  return series;
};

export const getTicks = (series, interval) => {
  // Deals with setting up ticks so they are only on days.
  // I blame thirdlove for this mess.
  let [start, end] = getSeriesRange(series);

  start = dayjs(start).add(1, 'day').startOf('day');

  end = dayjs(end).startOf('day');

  const x = scaleUtc()
    .domain([start.toDate(), end.toDate()])
    .nice(utcDay)
    .clamp(true) // This doesn't do anything.
    .ticks(15)
    .map((d) => {
      return dayjs(d).utc().startOf('day');
    })
    .filter((d) => {
      return d.isSameOrAfter(start, 'day') && d.isBefore(end);
    })
    .map((d) => {
      return d.valueOf();
    });

  return x;
};
