/**
 * @deprecated
 */
export const DARK = '#000';
export const DARK_80 = '#333';
export const DARK_40 = '#999';
export const BLUE = '#a1258a';
export const BLUE_80 = '#b551a1';
export const BLUE_40 = '#d9a8d0';
export const GREEN = '#1ED760';
export const GREEN_80 = '#45e57e';
export const GREEN_40 = '#a2f2bf';
export const HIGHLIGHTER = '#E7FE02';
export const HIGHLIGHTER_80 = '#E7FE02';
export const HIGHLIGHTER_40 = '#F5FF99';
export const PINK = '#FF94D5';
export const PINK_80 = '#FFA9DD';
export const PINK_40 = '#FFD4EE';
export const RED_ORANGE = '#FF4632'; /* '#FF5902';  */
export const RED_ORANGE_80 = '#ff6b5b';
export const RED_ORANGE_40 = '#ffb5ad';
export const TEXT_NORMAL = '#2e3338';
export const TEXT_MUTED = '#6A747F';
export const TEXT_LINK = 'BLUE';
export const BACKGROUND_PRIMARY = '#E4E6EB';
export const BACKGROUND_SECONDARY = '#F2f3f5';
export const BACKGROUND_MODIFIER_HOVER = 'rgba(116,127,141,0.08)';
export const BACKGROUND_MODIFIER_SELECTED = 'rgba(116,127,141,0.24)';
export const ICON_MUTED = '#cfd0d6';
export const BRAND_TWITTER = '#1da1f2';
export const BRAND_FACEBOOK = '#3b5998';
export const BRAND_LINKEDIN = '#0077b5';
export const DARK_BLUE = '#333549';
export const DARK_GRAY = '#767676';
export const LIGHT_BLUE = '#48AFE0';
export const LIGHT_GRAY = '#f9f9fb';
export const ORANGE = '#ff9800';
export const PURPLE = '#ab47bc';
export const RED = '#EA1907';
export const YELLOW = '#FFCC00';
export const COLORS = [
  BLUE,
  GREEN,
  PINK,
  RED_ORANGE,
  PURPLE,
  '#d08bba',
  '#0fb3a4',
  '#3568b1',
  '#662870',
  '#d72527',
  '#ef3f36',
  '#18618c',
];

/**
 * @deprecated
 */
export function getColor(index) {
  return COLORS[index % COLORS.length];
}

/**
 * @deprecated
 */
export function shade(color, percent) {
  const f = parseInt(color.slice(1), 16);
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;
  const R = f >> 16;
  const G = (f >> 8) & 0x00ff;
  const B = f & 0x0000ff;
  return `#${(
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  )
    .toString(16)
    .slice(1)}`;
}

/**
 * @deprecated
 */
export function lighten(color, percent) {
  return shade(color, percent);
}

/**
 * @deprecated
 */
export function darken(color, percent) {
  return shade(color, -percent);
}

/**
 * @deprecated
 */
export function hexToRgba(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: 1,
      }
    : null;
}

/**
 * @deprecated
 */
export function toRgba(value) {
  // #aaaaaa
  // rgba(121,121,12,0.1)

  if (typeof value === 'string') {
    if (value.charAt(0) == '#') {
      return hexToRgba(value);
    }
    const rgba = /rgba\((\d+)[,\s]+(\d+)[,\s]+(\d+)[,\s]+([\.\d]+)\)/.exec(
      value
    );
    if (rgba) {
      return {
        r: parseInt(rgba[1]),
        g: parseInt(rgba[2]),
        b: parseInt(rgba[3]),
        a: parseFloat(rgba[4]),
      };
    }
    const rgb = /rgb\((\d+)[,\s]+(\d+)[,\s]+(\d+)\)/.exec(k);
    if (rgb) {
      return {
        r: parseInt(rgb[1]),
        g: parseInt(rgb[2]),
        b: parseInt(rgb[3]),
        a: 1,
      };
    }
    return null;
  }

  if (
    value &&
    value.hasOwnProperty('r') &&
    value.hasOwnProperty('g') &&
    value.hasOwnProperty('b')
  ) {
    return {
      r: parseInt(value.r, 16),
      g: parseInt(value.g, 16),
      b: parseInt(value.b, 16),
      a: value.a == undefined ? 1 : value.a,
    };
  }
}

export function toRgbaStr(rgba) {
  return `rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`;
}

export function setOpacity(value, opacity) {
  const rgba = toRgba(value);
  rgba.a = opacity;
  return toRgbaStr(rgba);
}
