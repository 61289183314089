import {gql} from '@apollo/client';

export default gql`
  query AdminOrganizations($kind: String) {
    admin {
      organizations(kind: $kind) {
        id
        name
        slug
        kind
        tier
        createdAt
      }
    }
  }
`;
