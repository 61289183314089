import React from 'react';
import CampaignsQuery from '@admin/graphql-api/_old_queries/CampaignsQuery';
import useCampaigns from '@admin/lib/hooks/useCampaigns';
import {graphql} from '@apollo/client/react/hoc';
import {Route, Switch} from 'react-router-dom';
import Loading from '@core/ui/Loading';
import Campaigns from './Campaigns';
import CampaignIndex from './campaign';

const routes = [
  {
    path: '/campaigns/:campaignId/secret',
    Component: CampaignIndex,
  },
  {
    path: '/campaigns/:campaignId?',
    Component: Campaigns,
  },
];

const CampaignsIndex = ({data: {loading, admin}}) => {
  if (loading) {
    return <Loading absolute />;
  }
  const campaigns = useCampaigns(admin.campaigns);

  return (
    <Switch>
      {routes.map(({exact = false, path, Component}) => (
        <Route
          key={path}
          exact={exact}
          path={path}
          render={(routeProps) => (
            <Component campaigns={campaigns} {...routeProps} />
          )}
        />
      ))}
    </Switch>
  );
};

export default graphql(CampaignsQuery, {
  options: {
    fetchPolicy: 'network-only',
  },
})(CampaignsIndex);
