/**
 * An error originating from the Transport class.
 */
export class TransportError extends Error {
  /**
   * The code for this error.
   *
   * This value should be compared to the `TransportError` enum.
   */
  code?: string;

  /**
   * The native error that triggered this TransportError, if any.
   */
  origin: Error | null;

  constructor(code: string, message: string, origin: Error | null = null) {
    super(message);
    this.name = 'TransportError';
    this.message = message;
    this.code = code;
    this.origin = origin;
  }
}
