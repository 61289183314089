// NOTE: This code was generated and should not be changed

/**
 * Do not use this event - this is a data recovery mechanism, for internal use only. This event is emitted by client SDKs to persist any unknown or unparsable events received, for later analysis in case of bugs.
 */
export type RejectedClientEventNonAuthEventData = {
  /**
   * Why the event was rejected. INVALID_PAYLOAD|INVALID_NAME|UNKNOWN_OWNER|PAYLOAD_SIZE_LIMIT_EXCEEDED.
   */
  reject_reason?: string | null;
  /**
   * Nullable. Event name (type) of the rejected event. E.g. ViewLoadSequence
   */
  event_name?: string | null;
};

export type RejectedClientEventNonAuthEvent = {
  name: 'RejectedClientEventNonAuth';
  environments: ['browsernonauth', 'desktopnonauth', 'devicenonauth'];
  data: RejectedClientEventNonAuthEventData;
};

/**
 * A builder for RejectedClientEventNonAuth
 *
 * @param data - The event data
 * @return The formatted event data for RejectedClientEventNonAuthEvent
 */
export function createRejectedClientEventNonAuth(
  data: RejectedClientEventNonAuthEventData
): RejectedClientEventNonAuthEvent {
  return {
    name: 'RejectedClientEventNonAuth',
    environments: ['browsernonauth', 'desktopnonauth', 'devicenonauth'],
    data,
  };
}
