import React, {FC, useState} from 'react';
import {
  AdminOrganizationDocument,
  OrganizationObject,
  useCreateSpotifyImportRequstMutation,
} from '@admin/graphql-api';
import {Controller, useForm} from 'react-hook-form';
import {Redirect, useHistory} from 'react-router-dom';
import {Button, ButtonIcon} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {
  FORM_ERRORS,
  Form,
  FormFooter,
  FormGroup,
  Input,
  Submit,
} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';

interface OrderIdsInputProps {
  invalid?: boolean;
  onChange: (_ids: string[]) => void;
}

const OrderIdsInput: FC<OrderIdsInputProps> = ({
  invalid,
  onChange,
}): JSX.Element => {
  const [orderIds, setOrderIds] = useState<string[]>(['']);
  const [reset, setReset] = useState<number>(0);

  const onAddId = () => {
    setOrderIds([...orderIds, '']);
  };

  const onRemoveId = (index: number) => {
    orderIds.splice(index, 1);
    setOrderIds([...orderIds]);
    onChange([...orderIds]);
    setReset(reset + 1);
  };

  const _onChange = (value: string, index: number) => {
    orderIds[index] = value;
    const _orderIds = [...orderIds];

    setOrderIds(_orderIds);
    onChange(_orderIds);
  };

  return (
    <div css={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
      {orderIds.map((id, idx) => (
        <div
          key={idx}
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr 2rem',
            gap: '1rem',
          }}>
          <Input
            key={`${reset}-${idx}`}
            defaultValue={id}
            invalid={invalid}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
              _onChange(evt.target.value, idx)
            }
            placeholder='Order ID'
          />
          {idx ? (
            <ButtonIcon icon='minus' onClick={() => onRemoveId(idx)} />
          ) : (
            <div />
          )}
        </div>
      ))}
      <ButtonIcon icon='add-circle-outline' onClick={onAddId} />
    </div>
  );
};

type FormData = {
  orderIds: string[];
  networkError: boolean;
};

interface AddSpotifyIdsProps {
  organization: OrganizationObject;
}

const AddSpotifyIds: FC<AddSpotifyIdsProps> = ({organization}) => {
  const company = organization?.companies?.[0] ?? null;
  const organizationUrl = `/organizations/${organization.id}`;

  if (!company) return <Redirect to={organizationUrl} />;

  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [createSpotifyImportRequest] = useCreateSpotifyImportRequstMutation();

  const {
    control,
    clearErrors,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm<FormData>({
    defaultValues: {
      orderIds: [],
      networkError: false,
    },
  });

  const onSubmit = async (formData: FormData) => {
    clearErrors('networkError');
    setLoading(true);
    try {
      const spotifyOrderIds = formData.orderIds.filter((id) => !!id);
      await createSpotifyImportRequest({
        variables: {
          input: {
            companyId: company.id,
            spotifyOrderIds,
          },
        },
        refetchQueries: [AdminOrganizationDocument],
      });

      history.push(organizationUrl);
    } catch (err) {
      setError('networkError', FORM_ERRORS.network);
    }
    setLoading(false);
  };

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={organizationUrl}>Add Spotify Order IDs</CardHeader>
      <Card>
        <Form errors={errors} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Controller
              name='orderIds'
              control={control}
              rules={{
                validate: (orderIds) => {
                  return !orderIds.length ||
                    !orderIds.filter((id) => !!id).length
                    ? 'Please provide at least 1 order ID.'
                    : true;
                },
              }}
              render={({field}) => (
                <OrderIdsInput
                  invalid={!!errors.orderIds}
                  onChange={(values) => field.onChange(values)}
                />
              )}
            />
          </FormGroup>
          <FormFooter>
            <Button to={organizationUrl}>Cancel</Button>
            <Submit loading={loading} disabled={loading}>
              Add Order IDs
            </Submit>
          </FormFooter>
        </Form>
      </Card>
    </ResponsiveContent>
  );
};

export default AddSpotifyIds;
