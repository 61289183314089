import {gql} from '@apollo/client';

export default gql`
  query getUser($id: ID!) {
    admin {
      user(id: $id) {
        id
        firstName
        lastName
        email

        organizations {
          id
          slug
          name
          kind
        }
      }
    }
  }
`;
