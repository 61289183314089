import {Element} from '@core/style';

const AudioPlayButton = ({playing, rules, ...props}) => {
  if (playing) {
    return (
      <Element
        tag='svg'
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        rules={rules}
        {...props}>
        <path d='M14 19h4V5h-4M6 19h4V5H6v14z' fill='currentColor' />
      </Element>
    );
  }
  return (
    <Element
      tag='svg'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      rules={rules}
      {...props}>
      <path d='M8 5.14v14l11-7-11-7z' fill='currentColor' />
    </Element>
  );
};

export default AudioPlayButton;
