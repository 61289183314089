import {PropsWithChildren} from 'react';
import {ButtonIcon} from '../Button';
import {Text} from '../Text';

type Props = PropsWithChildren<{
  toggle?: (_to: boolean) => void;
  /**
   * If `true`, renders the header with a close button.
   * @default false
   */
  withCloseIcon?: boolean;
}>;

export const ModalHeader = ({
  toggle,
  withCloseIcon = false,
  children,
  ...props
}: Props) => (
  <div
    css={`
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 0 0 1.5rem 0;
    `}
    {...props}>
    <Text variant='h3'>{children}</Text>
    {!!withCloseIcon && typeof toggle === 'function' && (
      <ButtonIcon
        onClick={toggle as any}
        icon='close'
        css={`
          margin-right: -0.125rem;
          padding: 0;
        `}
      />
    )}
  </div>
);
