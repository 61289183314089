import dayjs from 'dayjs';

export const toTimeString = (seconds: string) => {
  if (seconds === null) {
    return '-';
  }
  const m = dayjs.duration(parseInt(seconds), 'seconds');
  const pad = (n) => `00${n}`.slice(-2);
  const parts = [m.minutes(), m.seconds()];

  if (m.hours()) {
    parts.unshift(m.hours());
  }

  return parts.map(pad).join(':');
};

export const toFormattedTimeString = (seconds: string) => {
  const m = dayjs.duration(parseInt(seconds), 'seconds');
  const parts = {
    h: m.hours(),
    m: m.minutes(),
  };

  if (Math.round(m.seconds() / 60)) {
    parts.m += 1;
  }

  return parts.h ? `${parts.h}h ${parts.m}min` : `${parts.m}min`;
};

export const getTimestamp = () => Math.round(new Date().getTime() / 1000);

export const formatDate = (d: Parameters<typeof dayjs>[0], fmt?: string) => {
  const m = dayjs(d).utc();

  return m.format(fmt || 'MM/DD/YYYY');
};
