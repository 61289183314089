import {gql} from '@apollo/client';

export default gql`
  mutation rejectCompanyClaim($input: RejectCompanyClaimInput!) {
    authed {
      success
    }
    rejectCompanyClaim(input: $input) {
      success
    }
  }
`;
