import React from 'react';
import {toDashboardUrl} from '@admin/lib/paths';
import DataTable, {
  DataTableLinkButton,
  MultipleSelectFilter,
} from '@core/ui/DataTable';

const OrganizationMembersTable = ({organization, memberships}) => {
  const dashboardUrl = toDashboardUrl(organization);
  const columns = [
    {
      title: 'First Name',
      accessor: 'firstName',
      type: 'string',
    },
    {
      title: 'Last Name',
      accessor: 'lastName',
      type: 'string',
    },
    {
      title: 'Email',
      accessor: 'email',
      type: 'string',
    },
    {
      title: 'Role',
      accessor: 'role',
      type: 'string',
      Filter: MultipleSelectFilter,
    },
    {
      Cell: ({data: {id, userId}}) => (
        <div
          css={`
            display: flex;
          `}>
          <DataTableLinkButton to={`/users/${userId}`}>
            Assume
          </DataTableLinkButton>
          <DataTableLinkButton
            to='/'
            href={`${dashboardUrl}/manage/members/${id}/update`}
            css={`
              margin-left: 1rem;
            `}>
            Update in Dash
          </DataTableLinkButton>
        </div>
      ),
    },
  ];

  const data = memberships.map((m) => ({
    id: m.id,
    userId: m.user.id,
    firstName: m.user.firstName,
    lastName: m.user.lastName,
    email: m.user.email,
    role: m.role,
  }));

  return (
    <DataTable
      columns={columns}
      data={data}
      searchKeys={['firstName', 'lastName', 'role', 'email']}
      filtersStorageKey={`admin-members-${organization.id}`}
      emptyMessageText='No members found.'
    />
  );
};

export default OrganizationMembersTable;
