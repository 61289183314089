export const getDefaultSelectedItems = ({
  items,
  value,
  propertyForValue,
}: {
  items: any[];
  value: string[];
  propertyForValue: string;
}): any[] => {
  if (Array.isArray(value) && value.length) {
    return items.filter((item) => value.indexOf(item[propertyForValue]) > -1);
  } else if (items.length) {
    return items.filter(({selected}) => selected);
  }
  return [];
};
