import {gql} from '@apollo/client';

export default gql`
  query getBackdated(
    $after: String!
    $before: String!
    $freqGte: Float
    $byPublished: Boolean
  ) {
    admin {
      management {
        attribution {
          backdated(
            after: $after
            before: $before
            freqGte: $freqGte
            byPublished: $byPublished
          ) {
            organization {
              id
              name
            }
            rows {
              campaignEpisode {
                id
                downloadTotal
                reachTotal
                backdatedError
                backdatedErrorMessage

                campaignPodcast {
                  id
                  campaign {
                    id
                    slug
                    name
                    organization {
                      id
                      slug
                      name
                    }
                  }
                }

                episode {
                  id
                  title
                  published

                  feed {
                    id
                    title
                  }
                }
              }
              hours
              impressions
              start
              end
            }
          }
        }
      }
    }
  }
`;
