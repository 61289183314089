import {AdminOrganizationQuery} from '@admin/graphql-api';
import {RouteType} from '@core/lib/useRoutes';

interface Props {
  organization: AdminOrganizationQuery['admin']['organization'];
}

const organizationRoutes = ({organization}: Props): RouteType[] => {
  const isKind = (arr: string[]) => arr.indexOf(organization.kind) > -1;

  return [
    {
      key: 'overview',
      title: 'Overview',
      path: '',
      exact: true,
    },
    {
      key: 'update',
      title: 'Update',
    },
    {
      key: 'podcast',
      title: 'Add Podcast',
      exact: true,
      isAvailable: isKind(['PODCAST']),
    },
    {
      key: 'access',
      title: 'Add Manager',
      exact: true,
      isAvailable: isKind(['BRAND', 'PODCAST']),
    },
    {
      key: 'backfill',
      title: 'Attribution Debug',
      isAvailable: isKind(['BRAND', 'PODCAST']),
    },
    {
      key: 'podcasts',
      isAvailable: organization.podcasts.length > 0,
    },
    {
      key: 'domains',
    },
    {
      key: 'permission',
      path: 'permissions/:permissionId',
      isAvailable:
        organization.permissions.length > 0 ||
        organization.reversePermissions.length > 0,
    },
    {
      key: 'spotify-ids',
      path: 'spotify-ids/add',
      exact: true,
      isAvailable:
        organization.kind === 'BRAND' && !!organization.companies.length,
    },
  ];
};

export default organizationRoutes;
