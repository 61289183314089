import React from 'react';
import {Route, Switch} from 'react-router-dom';
import User from './User';
import Users from './Users';

export default function () {
  return (
    <Switch>
      <Route exact path='/users' component={Users} />
      <Route exact path='/users/:userId' component={User} />
    </Switch>
  );
}
