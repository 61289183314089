import Reporter from './Reporter';
import { EventData, createEventSender } from '@spotify-internal/event-sender';
import { createSemanticMetricClient } from '@spotify-internal/event-definitions/es5/events/createSemanticMetricClient';
import { createSemanticMetricClientNonAuth } from '@spotify-internal/event-definitions/es5/events/createSemanticMetricClientNonAuth';
import log from '@spotify-internal/isomorphic-logger';
import {
  createBaseTransport,
  createXResolveProvider,
  PublicTransport,
  Transport,
  TransportEvent,
} from '@spotify-internal/transport';

import { Metric } from '../SemanticMetrics';
import { INTERNAL_WEBGATE_URL } from '../constants';
import { OwnerProvider } from '@spotify-internal/event-sender/lib/_internal/event_sender';
import { EnvironmentContext } from '@spotify-internal/event-sender/lib/typedefs';

type TokenCallback = (token: string, ttl?: number) => void;
type TokenCallbackProvider = (cb: TokenCallback) => void;

export type Environment = 'browser' | 'nodejs';

export type AuthenticatedEventSenderReporterConfig = {
  getToken: TokenCallbackProvider;
  ownerProvider?: OwnerProvider;
  internal?: boolean;
  context?: EnvironmentContext[];
  // Added as a TAG to the emitted metric
  environment: Environment;
};

export type UnauthenticatedEventSenderReporterConfig = Omit<
  AuthenticatedEventSenderReporterConfig,
  'getToken' | 'ownerProvider'
>;

export type EventSenderReporterConfig =
  | AuthenticatedEventSenderReporterConfig
  | UnauthenticatedEventSenderReporterConfig;

export type CustomAuthenticatedEventSenderReporterConfig = {
  transport: Transport;
  ownerProvider?: OwnerProvider;
  // Added as a TAG to the emitted metric
  environment: Environment;
  context?: EnvironmentContext[];
};

export type CustomUnauthenticatedEventSenderReporterConfig = Omit<
  CustomAuthenticatedEventSenderReporterConfig,
  'ownerProvider'
>;

export type CustomEventSenderReporterConfig =
  | CustomAuthenticatedEventSenderReporterConfig
  | CustomUnauthenticatedEventSenderReporterConfig;

export type MetricWithKey = Metric & {
  key: string;
};

function getEventCreator(transport: Transport) {
  return (metric: MetricWithKey) => {
    return transport.isAuthenticated()
      ? createSemanticMetricClient(metric)
      : createSemanticMetricClientNonAuth(metric);
  };
}

export class EventSenderReporter implements Reporter {
  protected constructor(
    private eventSender: ReturnType<typeof createEventSender>,
    private createEvent: (metric: MetricWithKey) => EventData,
    private environment: Environment = 'browser',
    private transport: Transport,
  ) {}

  static create(config: EventSenderReporterConfig): EventSenderReporter {
    const getToken = 'getToken' in config ? config.getToken : null;
    const ownerProvider =
      'ownerProvider' in config ? config.ownerProvider : null;
    const context = config.context ? [...config.context] : undefined;
    const environment = config.environment;

    const transport = createBaseTransport({
      providers: {
        endpoints: (() => {
          // The XResolve provider does not come with a way to override or extend endpoints by default,
          // so we have to wrap it in order to point to the internal webgate for internal use
          const xresolveProvider = createXResolveProvider();
          return async (publicTransport: PublicTransport) => {
            const endpoints = await xresolveProvider(publicTransport);
            return {
              ...endpoints,
              ...(config.internal && { webgate: INTERNAL_WEBGATE_URL }),
            };
          };
        })(),
        token: () =>
          new Promise(resolve => {
            if (!getToken) {
              resolve('');
              return;
            }

            getToken((token: string, ttl?: number) => {
              if (typeof ttl !== 'undefined') {
                resolve([token, ttl]);
                return;
              }
              resolve(token);
            });
          }),
      },
    });

    if (getToken) {
      transport.on(TransportEvent.CONNECTED, () => {
        transport.authenticate().catch(error => {
          // It is also handled by TOKEN_PROVIDER_ERROR listener
          log.debug(
            `authentication fail.`,
            error?.message ?? error?.code ?? '',
          );
        });
      });

      transport.on(TransportEvent.AUTHENTICATED, () => {
        log.debug('created authenticated browser transport');
      });
      transport.on(TransportEvent.TOKEN_PROVIDER_ERROR, () => {
        log.debug(`the token provided isn't working.`);
      });
    }

    transport.connect();

    const eventSender = createEventSender({
      transport,
      context,
      ...(ownerProvider && { ownerProvider }),
    });
    const createEvent = getEventCreator(transport);

    return new EventSenderReporter(
      eventSender,
      createEvent,
      environment,
      transport,
    );
  }

  static createWithCustomTransport(
    config: CustomEventSenderReporterConfig,
  ): EventSenderReporter {
    const ownerProvider =
      'ownerProvider' in config ? config.ownerProvider : null;
    const context = config.context ? [...config.context] : undefined;
    const eventSender = createEventSender({
      transport: config.transport,
      context,
      ...(ownerProvider && { ownerProvider }),
    });
    const createEvent = getEventCreator(config.transport);
    return new EventSenderReporter(
      eventSender,
      createEvent,
      config.environment,
      config.transport,
    );
  }

  async send(metrics: MetricWithKey[]): Promise<void> {
    for (const metric of metrics) {
      const clonedMetric = { ...metric };
      clonedMetric.tags = { ...clonedMetric.tags };
      if (clonedMetric.tags.environment) {
        log.error(
          '`tags.environment` must not be set. It is a reserved tag and will be overwritten.',
        );
      }
      clonedMetric.tags.environment = this.environment;
      const event = this.createEvent({ ...clonedMetric });
      this.eventSender.send(event, {
        flush: false, // don't flush right away
      });
    }

    return this.eventSender.flush(
      this.transport.isAuthenticated(),
    ) as Promise<void>;
  }
}
