import {gql} from '@apollo/client';

export default gql`
  mutation removeTag($input: RemoveTagInput!) {
    authed {
      success
    }
    removeTag(input: $input) {
      companyId
      productId
      tag {
        id
        label
      }
    }
  }
`;
