import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import DomainDelete from './DomainDelete';

const DomainIndex = ({organization}) => {
  return (
    <Switch>
      <PropsRoute
        path='/organizations/:organizationId/domains/:domainId/:delete'
        organization={organization}
        component={DomainDelete}
      />
    </Switch>
  );
};

export default DomainIndex;
