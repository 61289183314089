import {AnchorHTMLAttributes, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Icon} from '../Icon';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  href?: string;
  isActive?: (_prop: {path: string}) => boolean;
  to?: string;
}

export const NavItem = ({children, href, isActive, to, ...props}: Props) => {
  const path = window.location.pathname.replace(/\/$/, '');
  const active = isActive?.({path}) ?? path === to;

  return (
    <_NavItem
      as={to ? Link : href ? 'a' : 'div'}
      to={to}
      href={href}
      $active={active}
      {...props}>
      <_NavItemInner>
        {children}
        {!!href && (
          <Icon
            icon='external'
            css={`
              color: var(--icon-subtle);
              flex-shrink: 0;
              height: 1rem;
              max-width: 1rem;
              margin-left: 0.5rem;
            `}
          />
        )}
      </_NavItemInner>
    </_NavItem>
  );
};

const _NavItem = styled.div<{$active?: boolean}>`
  color: ${(p) => (p.$active ? 'var(--blue)' : 'var(--text-muted)')};
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  padding: 0 var(--spacing-3);
  position: relative;
  text-decoration: none;
  transition: all 0.2s linear;
  white-space: nowrap;
  ${(p) =>
    p.$active
      ? `
      :after {
        background-color: var(--blue);
        block-size: 2px;
        content: '';
        display: block;
        inline-size: 30px;
        margin-block-end: calc(-1 * 2px);
        margin-block-start: 0px;
        margin-inline: auto;
        position: relative;
        top: 0.25rem;
        transition: background-color 0.3s ease 0s;
      }
      `
      : `
          :hover {
            color: var(--text-default);
          }
          :active {
            box-shadow: var(--shadow-focus);
          }
      `}
`;

const _NavItemInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  padding: 0.625rem 0 0.6875rem;
  position: relative;
`;
