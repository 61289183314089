import CampaignArt19SecretQuery from '@admin/graphql-api/_old_queries/CampaignArt19SecretQuery';
import {graphql} from '@apollo/client/react/hoc';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import Loading from '@core/ui/Loading';
import CampaignSecret from './CampaignSecret';

const CampaignIndex = ({data: {loading, admin}}) => {
  if (loading) {
    return <Loading fixed />;
  }

  const {campaign} = admin;

  return (
    <Switch>
      <PropsRoute
        exact
        path='/campaigns/:campaignId/secret'
        campaign={campaign}
        component={CampaignSecret}
      />
    </Switch>
  );
};

export default graphql(CampaignArt19SecretQuery, {
  options: ({
    match: {
      params: {campaignId},
    },
  }) => ({
    variables: {
      id: campaignId,
    },
    fetchPolicy: 'network-only',
  }),
})(CampaignIndex);
