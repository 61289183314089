import React from 'react';
import {Route, Switch} from 'react-router-dom';
import LiftReports from './LiftReports';

export default function () {
  return (
    <Switch>
      <Route exact path='/lift' component={LiftReports} />
    </Switch>
  );
}
