import {SliderUnstyledOwnProps} from '@mui/base/SliderUnstyled/SliderUnstyled.types';
import {
  Slider as MuiSlider,
  type SliderProps as MuiSliderProps,
  ThemeOptions,
} from '@mui/material';

export type SliderProps = Pick<MuiSliderProps, 'size' | 'color'> &
  Omit<SliderUnstyledOwnProps, 'components' | 'componentsProps' | 'classes'>;

export function Slider(props: SliderProps) {
  return <MuiSlider {...props} />;
}

export const MuiSliderStyles: ThemeOptions['components'] = {
  MuiSlider: {
    defaultProps: {
      min: 0,
      max: 100,
      valueLabelDisplay: 'auto',
      color: 'secondary',
    },
    styleOverrides: {
      rail: {
        backgroundColor: 'var(--border-default)',
      },
      track: () => ({
        backgroundColor: 'var(--secondary)',
        border: 0,
      }),
      thumb: () => ({
        backgroundColor: 'var(--bg-overlay)',
        border: '0.1875rem solid var(--secondary)',
        height: '1.5rem',
        width: '1.5rem',
        ['&:hover, &.Mui-focusVisible']: {
          boxShadow: '0 0 0 0.5rem var(--shadow-md)',
          width: '1.65rem',
          height: '1.65rem',
        },
        ['&.Mui-active']: {
          boxShadow: '0 0 0 0.75rem var(--shadow-md)',
        },
      }),
      mark: {
        width: '0.25rem',
        height: '0.25rem',
        opacity: '0.7',
        backgroundColor: 'var(--border-subtle)',
      },
      markLabel: () => ({
        fontSize: 'var(--font-size-body-2)',
        color: 'var(--text-muted)',
      }),
    },
  },
};
