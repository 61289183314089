import { cssBorderRadius, focusBorderWidth, focusDimensionsAndPosition } from "../../styles/index.js";
import styled from "../../styled-components.js";

/* TagFocus contains Tag's focus state, which is themed using the color set
 * of Tag's _parent_, and not the colorSet of Tag itself.
 * TagFocus is always in the DOM, but is invisible unless the tag is focused & isUsingKeyboard is true.
 */
export var TagFocus = styled.div.withConfig({
  displayName: "TagFocus",
  componentId: "sc-oozknb-0"
})(["", " display:block;position:absolute;pointer-events:none;background:transparent;border:", "px solid transparent;border-radius:", ";box-sizing:border-box;"], focusDimensionsAndPosition(), focusBorderWidth, cssBorderRadius('larger-2', '8px'));