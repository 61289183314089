/**
 * Error codes of all errors thrown by Transport.
 */
export enum TransportErrors {
  /**
   * Throw when a request was aborted.
   */
  HTTP_REQUEST_ABORTED = 'HTTP_REQUEST_ABORTED',

  /**
   * Thrown when an error occurs during the sending of an HTTP request.
   */
  HTTP_REQUEST_FAILED = 'HTTP_REQUEST_FAILED',

  /**
   * Indicates an error when sending a log via the Melody logging endpoints.
   */
  LOGGING_REQUEST_FAILED = 'LOGGING_REQUEST_FAILED',

  /**
   * Thrown when the application provided EndpointsProvider throws an error.
   */
  TRANSPORT_ENDPOINTS_PROVIDER_ERROR = 'TRANSPORT_ENDPOINTS_PROVIDER_ERROR',

  /**
   * Thrown when a lifecycle method on Transport is called in the wrong part of
   * the lifecycle, such as calling authenticate() before calling connect().
   */
  TRANSPORT_INVALID_STATE = 'TRANSPORT_INVALID_STATE',

  /**
   * Thrown when the TokenProvider returns an invalid (empty or null) token.
   */
  TRANSPORT_INVALID_TOKEN = 'TRANSPORT_INVALID_TOKEN',

  /**
   * Thrown when the TokenProvider returns the same token twice.
   */
  TRANSPORT_STALE_TOKEN = 'TRANSPORT_STALE_TOKEN ',

  /**
   * Thrown when the TokenProvider error throws an error.
   */
  TRANSPORT_TOKEN_PROVIDER_ERROR = 'TRANSPORT_TOKEN_PROVIDER_ERROR',

  /**
   * Thrown by a Transport-based library when calling library-provided lifecycle
   * methods on an injected Transport instance.
   */
  TRANSPORT_LIFECYCLE_DISABLED = 'TRANSPORT_LIFECYCLE_DISABLED',

  /**
   * Thrown when an unsupported set of options is passed to Transport.
   */
  TRANSPORT_UNSUPPORTED_OPTION = 'TRANSPORT_UNSUPPORTED_OPTION',

  /**
   * Thrown by the XResolve EndpointsProvider when XResolve returns a response
   * that is incomplete.
   */
  XRESOLVE_INCOMPLETE_RESPONSE = 'XRESOLVE_INCOMPLETE_RESPONSE',

  /**
   * Thrown by the XResolve EndpointsProvider when XResolve returns a response
   * that is invalid.
   */
  XRESOLVE_INVALID_RESPONSE = 'XRESOLVE_INVALID_RESPONSE',

  /**
   * Thrown when the request to the APResolve service inside the XResolve
   * EndpointsProvider fails.
   */
  XRESOLVE_REQUEST_FAILED_WITH_STATUS = 'XRESOLVE_REQUEST_FAILED_WITH_STATUS',
}
