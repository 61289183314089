import {gql} from '@apollo/client';

export default gql`
  query getCampaigns {
    admin {
      campaigns {
        id
        name
        startAt
        endAt
        lastBuilt
        attributionTotal
        reachTotal
        state
        expectedStartAt
        expectedEndAt
        kind

        organization {
          id
          name
          attributionCpm
          note
        }

        advertiser {
          id
          name
          organization {
            id
            name
          }
        }

        payer {
          id
        }

        createdAt
      }
    }
  }
`;
