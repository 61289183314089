import {HTMLAttributes, Ref, forwardRef, useContext} from 'react';
import {Link, LinkProps} from 'react-router-dom';
import styled from 'styled-components';
import {focusVisibleCss} from '@core/style';
import SettingsContext from '../../../analytics/components/settings/SettingsContext';
import {IconName} from '../Icon';
import {ButtonChildren} from './ButtonChildren';
import {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
  buttonStyleBase,
  buttonStyleColors,
  buttonStyleLayout,
} from './buttonStyles';

export type ButtonProps = HTMLAttributes<HTMLElement> &
  Pick<LinkProps, 'target' | 'href' | 'rel' | 'download'> & {
    /**
     * Choose one of three button sizes.
     * @default 'md'
     */
    buttonSize?: ButtonSize;
    disabled?: boolean;
    /**
     * If `true`, the button will stretch to the full width of its parent.
     */
    fullWidth?: boolean;
    /**
     * Icon to place at the front of the button.
     */
    iconLeading?: IconName;
    /**
     * Icon to place at the rear of the button.
     */
    iconTrailing?: IconName;
    variant?: ButtonVariant;
    color?: ButtonColor;
    /**
     * When true, clicking the link will replace the current entry in the
     * history stack instead of adding a new one.
     */
    replace?: LinkProps['replace'];
    /**
     * A string representation of the Link location, created by concatenating the
     * ocation’s pathname, search, and hash properties.
     *
     * Alternatively, it can be an object or a function: https://v5.reactrouter.com/web/api/Link
     */
    to?: LinkProps['to'];
    /**
     * Button type.
     * @default 'button'
     */
    type?: 'button' | 'submit' | 'reset';
  };

export default forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  function Button(
    {
      buttonSize = 'md',
      children,
      color = 'default',
      disabled = false,
      fullWidth,
      href,
      iconLeading,
      iconTrailing,
      to,
      type = 'button',
      variant = 'filled',
      ...props
    },
    ref
  ) {
    const [settings] = useContext(SettingsContext);
    const content = (
      <ButtonChildren
        iconLeading={iconLeading}
        iconTrailing={iconTrailing}
        buttonSize={buttonSize}>
        {children}
      </ButtonChildren>
    );

    const buttonProps = {
      $buttonSize: buttonSize,
      $color: color,
      $fullWidth: !!fullWidth,
      $variant: variant,
      disabled: disabled,
      tabIndex: disabled ? -1 : 0,
    };

    if (href) {
      return (
        <_Button
          ref={ref as Ref<HTMLAnchorElement>}
          as='a'
          href={href}
          {...buttonProps}
          {...props}>
          {content}
        </_Button>
      );
    } else if (to) {
      return (
        <_Button
          ref={ref as Ref<HTMLAnchorElement>}
          as={Link}
          to={to}
          {...buttonProps}
          {...props}>
          {content}
        </_Button>
      );
    }
    return (
      <_Button
        ref={ref as Ref<HTMLButtonElement>}
        type={type}
        {...buttonProps}
        {...props}>
        {content}
      </_Button>
    );
  }
);

const _Button = styled.button<{
  $buttonSize: ButtonSize;
  $color: ButtonColor;
  $fullWidth: boolean;
  $variant: ButtonVariant;
  disabled: boolean;
}>`
  ${(p) =>
    p.$variant !== 'unstyled'
      ? [buttonStyleBase, buttonStyleColors, buttonStyleLayout]
      : focusVisibleCss}
`;
