import {gql} from '@apollo/client';

export default gql`
  query getContact($id: ID!) {
    admin {
      contact(id: $id) {
        id
        source
        firstName
        lastName
        email
        company
        role
        message

        ignoredBy {
          id
          firstName
          lastName
          email
        }

        contactedBy {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;
