import {gql} from '@apollo/client';

export default gql`
  mutation addFeed($input: AddFeedInput!) {
    authed {
      success
    }
    addFeed(input: $input) {
      feed {
        id
        createdAt
      }
    }
  }
`;
