import {gql} from '@apollo/client';

export default gql`
  query getMe($service: String) {
    userInfo {
      email
      hasSpaaUser
      hasVerifiedEmailForSpaa
      __typename
    }
    me {
      __typename
      id
      firstName
      lastName
      email
      emailHash
      createdAt
      isAdmin
      hasSeenAdAnalyticsWelcome
      adAnalyticsTutorialProgress
      reviewer {
        id
        firstName
        lastName
        email
        type
      }
      organizations(service: $service) {
        id
        isLockedForTerms
        name
        slug
        kind
        isAdvisor
        isSegmentsEnabled
      }
    }
  }
`;
