import {Base64} from '@js-sdk/encoding';

/**
 * @param hexString - a string representation of a hexadecimal number.
 * @return a base 64 encoded string representing the same value as the hexString input.
 */
export function hexToBase64(hexString: string): string {
  if (!hexString || hexString.length % 2 !== 0) {
    throw new TypeError('Invalid hexString value.');
  }

  // Each 8-bit byte is represented by two characters 00 - ff
  const bytes = hexString
    .split('')
    .reduce((acc: string[], character, index) => {
      if (index % 2 === 0) {
        acc.push(character);
        return acc;
      }
      acc[acc.length - 1] = `${acc[acc.length - 1]}${character}`;
      return acc;
    }, []);

  const bytestr = String.fromCharCode.apply(
    null,
    bytes.map((i) => {
      const asInt = parseInt(i, 16);

      if (isNaN(asInt)) {
        throw new TypeError('Invalid hexString value.');
      }
      return asInt;
    })
  );
  return Base64.encode(bytestr);
}
