import DataTable from '@core/ui/DataTable';
import {CampaignDetailsProps} from '.';

export function CampaignPermissions({campaign}: CampaignDetailsProps) {
  return (
    <>
      {(campaign?.permissions ?? []).length > 0 ? (
        <DataTable
          data={campaign.permissions ?? []}
          columns={[
            {
              title: 'Title',
              accessor: 'title',
              type: 'string',
            },
            {
              title: 'Description',
              accessor: 'description',
              type: 'string',
            },
          ]}
          orderBy='-title'
          rules={() => ({
            marginLeft: '-0.625rem',
            width: 'calc(100% + 1.25rem)',
          })}
        />
      ) : (
        '-'
      )}
    </>
  );
}
