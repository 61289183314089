const DIGITS = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
const INV_HEX_DIGITS: {[key: string]: number} = {};
const INV_DIGITS: {[key: string]: number} = {};

// Prepare tables.
(function () {
  for (let i = 0, l = DIGITS.length; i < l; ++i) {
    INV_DIGITS[DIGITS[i]!] = i;
  }
  for (let i = 0; i < 16; ++i) {
    INV_HEX_DIGITS['0123456789abcdef'[i]!] = i;
  }
  for (let i = 0; i < 16; ++i) {
    INV_HEX_DIGITS['0123456789ABCDEF'[i]!] = i;
  }
})();

function mul(lhs: number[], rhs: number, base: number): void {
  let rest = 0;
  for (let i = 0; i < lhs.length; ++i) {
    const tmp = lhs[i]! * rhs + rest;
    lhs[i] = tmp % base;
    rest = ~~(tmp / base);
  }
  while (rest) {
    lhs.push(rest % base);
    rest = ~~(rest / base);
  }
}

function madd(acc: number[], lhs: number[], rhs: number, base: number): void {
  let rest = 0;
  let tmp;
  let i;
  for (i = 0; i < lhs.length; ++i) {
    tmp = ~~acc[i]! + lhs[i]! * rhs + rest;
    acc[i] = tmp % base;
    rest = ~~(tmp / base);
  }
  while (rest) {
    tmp = ~~acc[i]! + rest;
    acc[i] = tmp % base;
    rest = ~~(tmp / base);
    ++i;
  }
}

function convert(data: number[], fromBase: number, toBase: number): number[] {
  const r = [0];
  const b = [1];
  for (const datum of data) {
    madd(r, b, datum, toBase);
    mul(b, fromBase, toBase);
  }
  return r;
}

function mapr<T, K extends keyof T>(data: K[], mapping: T): T[K][] {
  const r = [];
  for (const datum of data) {
    r.push(mapping[datum]);
  }
  return r.reverse();
}

function pad(data: number[], length: number = 0): number[] {
  while (data.length < length) {
    data.push(0);
  }
  return data;
}

export function fromBytes(data: number[], length?: number): string {
  const r = convert(data.slice(0).reverse(), 256, 62);
  return mapr(pad(r, length), DIGITS).join('');
}

export function toBytes(str: string, length?: number): number[] {
  const r = convert(mapr(str.split(''), INV_DIGITS), 62, 256);
  return pad(r, length).reverse();
}

export function toHex(str: string, length?: number): string {
  const r = convert(mapr(str.split(''), INV_DIGITS), 62, 16);
  return mapr(pad(r, length), DIGITS).join('');
}

export function fromHex(str: string, length?: number): string {
  const r = convert(mapr(str.split(''), INV_HEX_DIGITS), 16, 62);
  return mapr(pad(r, length), DIGITS).join('');
}
