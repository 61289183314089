import {HTMLProps, PropsWithChildren} from 'react';
import styled from 'styled-components';
import {createStyledElement} from '@core/style';

/**
 * Form errors for react-hook-form
 * https://react-hook-form.com/api/useform/seterror
 */
const FORM_ERRORS = {
  network: {
    type: 'manual',
    message: 'Network error. Please try again.',
  },
};

interface FormProps extends PropsWithChildren<HTMLProps<HTMLFormElement>> {
  errors?: object;
}

const Form = ({errors = {}, children, ...props}: FormProps) => {
  const errorList = Object.values(errors);

  return (
    <>
      {errorList.length ? (
        <div
          css={`
            background: var(--red-orange-20);
            border-radius: 0.5rem;
            color: var(--red-orange);
            margin-bottom: 1.25rem;
            text-align: center;
          `}>
          {errorList.map(({message}) => (
            <div
              key={message}
              css={`
                padding: 0.5rem 0.625rem;
              `}>
              {message}
            </div>
          ))}
        </div>
      ) : null}
      <form {...props}>{children}</form>
    </>
  );
};

/**
 * @deprecated
 */
const Label = createStyledElement('label', () => ({
  color: 'inherit',
  display: 'block',
  fontSize: '.9375rem',
  fontWeight: 500,
  marginBottom: '.5rem',
  '> input': {
    marginTop: '.25rem',
  },
}));

const FormFooter = styled.div`
  display: flex;
  justify-content: center;
  margin: 2.5rem 0 0 0;
  > button {
    margin-left: 1rem;
  }
`;

/**
 * @deprecated
 */
const FormGroup = styled.div`
  margin: 0 0 2rem 0;
`;

/**
 * @deprecated
 */
const FormHelp = styled.div`
  color: 'var(--text-muted)';
  font-size: 0.8125rem;
  line-height: 1.5;
  margin: 0.5rem 0 0 0;
  > p {
    margin: 0 0 0.625rem;
  }
`;

/**
 * @deprecated
 */
const FormSeparator = styled.div`
  background: var(--border-default);
  height: 1px;
  margin: 1.5rem 0;
`;

export {
  FORM_ERRORS,
  Form,
  FormFooter,
  FormGroup,
  FormHelp,
  FormSeparator,
  Label,
};
