/*
 * resizeImage(image.path, 100)
 */
const BUCKET_MAP = {
  'podcast-audio-local': 'podsights-local.imgix.net',
  'podcast-audio-production': 'podsights-prod.imgix.net',
};

export const resizeImage = (
  path: string,
  width: number,
  height?: number
): string => {
  const match = /^gs:\/\/([-\w]+)\/(.*)$/.exec(path);

  if (!match) {
    return '';
  }

  let src = `https://${BUCKET_MAP[match[1]]}/${match[2]}?w=${width * 2}`;

  if (height) {
    src = `${src}&h=${height * 2}`;
  }

  return src;
};
