import {PropsWithChildren} from 'react';
import styled from 'styled-components';
import {TRules} from '@core/style';
import {FelaElement} from '@core/style';
import {Icon, IconName} from '../Icon';

type ToastAlertName = 'success' | 'info' | 'warning' | 'error';

/**
 * @deprecated
 */
export type ToastMessageProps = PropsWithChildren<{
  /**
   * The type of the alert. This defines the color and icon used.
   * @default 'warning'
   */
  alertType?: ToastAlertName;
  /** @deprecated */
  rules?: TRules;
}>;

const styleMap: {
  [_key in ToastAlertName]: {
    bgColor: string;
    color: string;
    iconColor: string;
    iconName: IconName;
  };
} = {
  warning: {
    bgColor: '#fff5b1',
    color: 'var(--red-orange)',
    iconColor: 'var(--red-orange)',
    iconName: 'alert',
  },
  error: {
    bgColor: 'var(--red-orange-20)',
    color: 'var(--red-orange)',
    iconColor: 'var(--red-orange)',
    iconName: 'alert',
  },
  info: {
    bgColor: 'var(--blue-20)',
    color: 'var(--blue)',
    iconColor: 'var(--blue)',
    iconName: 'info',
  },
  success: {
    bgColor: 'var(--green-20)',
    color: 'var(--green)',
    iconColor: 'var(--green)',
    iconName: 'checkbox-marked-circle',
  },
};

/**
 * @deprecated
 * @see Alert
 */
export default function ToastMessage({
  alertType = 'warning',
  children,
  rules,
  ...props
}: ToastMessageProps) {
  const {bgColor, color, iconColor, iconName} = styleMap[alertType];

  return (
    <_Container rules={rules} bgColor={bgColor} color={color} {...props}>
      <Icon
        icon={iconName}
        rules={() => ({
          color: iconColor,
          flexShrink: 0,
          height: '1.5rem',
          marginRight: '0.625rem',
          width: '1.5rem',
        })}
      />
      <span>{children}</span>
    </_Container>
  );
}

const _Container = styled(FelaElement)<{color: string; bgColor: string}>`
  align-items: center;
  border: 0;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  margin: 0 1.5rem 1rem;
  font-size: 0.875rem;
  padding: 0.625rem;
  color: ${(p) => p.color};
  background: ${(p) => p.bgColor};
  p:last-child {
    margin-bottom: 0;
  }
`;
