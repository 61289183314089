import {Element} from '@core/style';
import DataTable from '@core/ui/DataTable';
import {comma} from '../../lib/filters';
import {formatDate, toTimeString} from '../../lib/time';
import {isNone} from '../../lib/utils';
import {stripHTML, truncateWords} from '../../lib/words';

const EpisodesTable = ({episodes}) => {
  const columns = [
    {
      title: ' Title',
      accessor: 'title',
      type: 'string',
      Cell: ({data}) => (
        <>
          <Element
            rules={() => ({
              alignItems: 'center',
              display: 'flex',
              fontWeight: 500,
              minWidth: '12.5rem',
              whiteSpace: 'nowrap',
            })}>
            {data.title}
          </Element>
          <Element
            rules={() => ({
              color: 'var(--text-muted)',
              fontSize: '0.875rem',
              lineHeight: 1.5,
              margin: '0.25rem 0',
            })}>
            {truncateWords(stripHTML(data.description), 20)}
          </Element>
        </>
      ),
    },
    {
      title: 'Analytics Prefix',
      Cell: ({data}) => (
        <div>{!!data.enclosure.prefixPodsights ? '✅' : '🚫'}</div>
      ),
    },
    {
      title: 'Published',
      accessor: 'published',
      type: 'date',
      fmt: (val) => formatDate(val, 'MM/DD/YY'),
    },
    {
      title: 'Duration',
      accessor: 'duration',
      type: 'number',
      fmt: toTimeString,
    },
    {
      title: 'Ads',
      accessor: 'placementsCount',
      type: 'number',
    },
    {
      title: 'Impressions',
      accessor: 'downloads',
      type: 'number',
      fmt: comma,
    },
  ];

  const data = episodes.map((episode) => ({
    duration: episode.enclosure.length,
    description: episode.subtitle || episode.summary || '',
    downloads:
      episode.enclosure && !isNone(episode.enclosure.downloads)
        ? episode.enclosure.downloads
        : 0,
    ...episode,
  }));

  return (
    <DataTable
      searchKeys={['title']}
      data={data}
      columns={columns}
      orderBy='-published'
    />
  );
};

export default EpisodesTable;
