import React from 'react';
import {FormControl, FormHelperText, FormLabel} from '@mui/material';
import {
  Control,
  ControllerProps,
  FieldError,
  FieldValues,
  Path,
  useController,
} from 'react-hook-form';
import {RadioGroup} from './Radio';

export type RadioGroupControlledProps<T extends FieldValues> = {
  children: React.ReactNode;
  control?: Control<T>;
  helperText?: string;
  label?: string;
  name: Path<T>;
  onChange?: (_value: T[RadioGroupControlledProps<T>['name']]) => void;
  parseError?: (_error: FieldError) => string;
  required?: boolean;
  validation?: ControllerProps['rules'];
};

export function RadioGroupControlled<T extends FieldValues>({
  children,
  control,
  helperText: helperTextProp,
  label,
  name,
  onChange: onChangeProp,
  parseError,
  required,
  validation = {},
}: RadioGroupControlledProps<T>) {
  if (required && !validation.required) {
    validation.required = 'This field is required';
  }

  const {
    field: {value = '', onChange},
    fieldState: {error},
  } = useController({name, rules: validation, control});

  const errorMessage = error && (parseError?.(error) ?? error.message);

  const helperText = error ? errorMessage : helperTextProp;

  return (
    <FormControl error={!!error}>
      {label && (
        <FormLabel required={required} error={!!error}>
          {label}
        </FormLabel>
      )}
      <RadioGroup
        name={name}
        value={value}
        onChange={(e) => {
          const value = e.target.value as T[typeof name];
          onChange(value);
          onChangeProp?.(value);
        }}>
        {children}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
