import {HTMLAttributes, PropsWithChildren} from 'react';
import styled from 'styled-components';
import {useTheme} from '@core/style';

export type BadgeProps = HTMLAttributes<HTMLElement> &
  PropsWithChildren<{
    color?:
      | 'default'
      | 'primary'
      | 'secondary'
      | 'error'
      | 'warning'
      | 'success'
      | 'info';
    showLiveDot?: boolean;
    size?: 'small' | 'medium';
    customColors?: {
      background?: string;
      borderColor?: string;
      color?: string;
    };
  }>;

export default function Badge({
  children,
  color = 'default',
  customColors,
  showLiveDot = true,
  size = 'small',
  ...props
}: BadgeProps) {
  const clickable = !!props.onClick;
  const theme = useTheme();

  return (
    <_Badge
      role={clickable ? 'button' : undefined}
      $clickable={clickable}
      $color={color}
      $size={size}
      $customColors={customColors}
      {...props}>
      <>
        {showLiveDot && (
          <div
            data-testid='live-dot'
            css={`
              background: ${customColors
                ? customColors.color
                : `var(--${color})`};
              border-radius: 50%;
              height: 0.5rem;
              margin-right: var(--spacing-2);
              width: 0.5rem;
            `}
          />
        )}
        {children}
      </>
    </_Badge>
  );
}

const sizeStyleMap = {
  small: {
    fontSize: 'var(--font-size-body-4)',
    padding: '0.0625rem var(--spacing-3) 0',
    height: '1.5rem',
  },
  medium: {
    fontSize: 'var(--font-size-body-3)',
    padding: '0 var(--spacing-4)',
    height: '2rem',
  },
};

const _Badge = styled.div<{
  $clickable: boolean;
  $color: BadgeProps['color'];
  $size: BadgeProps['size'];
  $customColors: BadgeProps['customColors'];
}>`
  align-items: center;
  border-radius: 2rem;
  cursor: ${(p) => p.$clickable && 'pointer'};
  display: inline-flex;
  font-weight: 500;
  letter-spacing: 0.0313rem;
  text-transform: uppercase;
  white-space: nowrap;
  ${(p) => sizeStyleMap[p.$size!]}
  ${(p) =>
    p.$customColors
      ? `
        background: ${p.$customColors.background};
        color: ${p.$customColors.color};
        border: 1px solid ${p.$customColors.borderColor};
      `
      : ` color: var(--text-muted);`}
`;
