import { zIndexPopover } from "../../styles/index.js";
import styled, { css } from "../../styled-components.js";
export var cornerOffset = '17px';
export var Overlay = styled.div.withConfig({
  displayName: "Overlay",
  componentId: "sc-u80gmt-0"
})(["display:flex;min-width:296px;pointer-events:none;position:absolute;z-index:", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";", ";"], zIndexPopover, function (props) {
  return props.placement === 'top' && css(["bottom:100%;justify-content:center;left:50%;transform:translate(-50%,-12px);"]);
}, function (props) {
  return props.placement === 'topStart' && css(["bottom:100%;justify-content:flex-end;right:50%;transform:translate(", ",-12px);[dir='rtl'] &&{right:unset;left:50%;transform:translate(-", ",-12px);}"], cornerOffset, cornerOffset);
}, function (props) {
  return props.placement === 'topEnd' && css(["bottom:100%;justify-content:flex-start;left:50%;transform:translate(-", ",-12px);[dir='rtl'] &&{right:50%;transform:translate(", ",-12px);}"], cornerOffset, cornerOffset);
}, function (props) {
  return props.placement === 'bottom' && css(["justify-content:center;left:50%;top:100%;transform:translate(-50%,12px);"]);
}, function (props) {
  return props.placement === 'bottomStart' && css(["justify-content:flex-end;right:50%;top:100%;transform:translate(", ",12px);[dir='rtl'] &&{right:unset;left:50%;transform:translate(-", ",12px);}"], cornerOffset, cornerOffset);
}, function (props) {
  return props.placement === 'bottomEnd' && css(["justify-content:flex-start;left:50%;top:100%;transform:translate(-", ",12px);[dir='rtl'] &&{left:unset;right:50%;transform:translate(", ",12px);}"], cornerOffset, cornerOffset);
}, function (props) {
  return props.placement === 'start' && css(["justify-content:flex-end;right:100%;top:50%;transform:translate(-12px,-50%);[dir='rtl'] &&{right:unset;left:100%;transform:translate(12px,-50%);}"]);
}, function (props) {
  return props.placement === 'startTop' && css(["bottom:50%;justify-content:flex-end;right:100%;transform:translate(-12px,", ");[dir='rtl'] &&{right:unset;left:100%;transform:translate(12px,", ");}"], cornerOffset, cornerOffset);
}, function (props) {
  return props.placement === 'startBottom' && css(["justify-content:flex-end;right:100%;top:50%;transform:translate(-12px,-", ");[dir='rtl'] &&{right:unset;left:100%;transform:translate(12px,-", ");}"], cornerOffset, cornerOffset);
}, function (props) {
  return props.placement === 'end' && css(["justify-content:flex-start;left:100%;top:50%;transform:translate(12px,-50%);[dir='rtl'] &&{left:unset;right:100%;transform:translate(-12px,-50%);}"]);
}, function (props) {
  return props.placement === 'endTop' && css(["bottom:50%;justify-content:flex-start;left:100%;transform:translate(12px,", ");[dir='rtl'] &&{left:unset;right:100%;transform:translate(-12px,", ");}"], cornerOffset, cornerOffset);
}, function (props) {
  return props.placement === 'endBottom' && css(["justify-content:flex-start;left:100%;top:50%;transform:translate(12px,-", ");[dir='rtl'] &&{left:unset;right:100%;transform:translate(-12px,-", ");}"], cornerOffset, cornerOffset);
});
export default Overlay;