import React from 'react';
import AcceptCompanyClaimMutation from '@admin/graphql-api/_old_mutations/AcceptCompanyClaim';
import DeleteCompanyClaimMutation from '@admin/graphql-api/_old_mutations/DeleteCompanyClaim';
import RejectCompanyClaimMutation from '@admin/graphql-api/_old_mutations/RejectCompanyClaim';
import CompanyClaimQuery from '@admin/graphql-api/_old_queries/CompanyClaimQuery';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import {Redirect} from 'react-router-dom';
import {Element} from '@core/style';
import {Button} from '@core/ui/Button';
import {GraphErrors} from '@core/ui/Form';
import {Link} from '@core/ui/Link';
import Loading from '@core/ui/Loading';

class CompanyClaim extends React.Component {
  state = {
    loading: false,
    complete: false,
    errors: [],
  };

  accept = () => {
    const {
      acceptCompanyClaim,
      data: {
        admin: {
          companyClaim: {id},
        },
      },
    } = this.props;

    this.setState({
      loading: true,
    });

    acceptCompanyClaim({id})
      .then(() => {
        this.setState({
          loading: true,
          complete: true,
        });
      })
      .catch((resp) => {
        this.setState({
          loading: false,
          errors: resp.graphQLErrors,
        });
      });
  };

  reject = () => {
    const {
      rejectCompanyClaim,
      data: {
        admin: {
          companyClaim: {id},
        },
      },
    } = this.props;

    this.setState({
      loading: true,
    });

    rejectCompanyClaim({id})
      .then(() => {
        this.setState({
          loading: true,
          complete: true,
        });
      })
      .catch((resp) => {
        this.setState({
          loading: false,
          errors: resp.graphQLErrors,
        });
      });
  };

  delete = () => {
    const {
      deleteCompanyClaim,
      data: {
        admin: {
          companyClaim: {id},
        },
      },
    } = this.props;

    this.setState({
      loading: true,
    });

    deleteCompanyClaim({id})
      .then(() => {
        this.setState({
          loading: true,
          complete: true,
        });
      })
      .catch((resp) => {
        this.setState({
          loading: false,
          errors: resp.graphQLErrors,
        });
      });
  };

  render() {
    const {data} = this.props;
    const {complete, loading, errors} = this.state;

    if (data.loading) {
      return <Loading absolute />;
    }

    if (complete) {
      return <Redirect to='/claims' />;
    }

    const {state, user, organization, company} = data.admin.companyClaim;

    if (!user) {
      return (
        <Element>
          <p>This is some bad data. You can delete it.</p>
          <Button
            color='error'
            onClick={this.delete}
            disabled={loading}
            css={`
              margin-right: 10px;
            `}>
            Delete
          </Button>
        </Element>
      );
    }

    return (
      <Element>
        <GraphErrors
          errors={errors}
          onClose={() => {
            this.setState({
              errors: [],
            });
          }}
        />
        <p>
          The user{' '}
          <b>
            {user.firstName} {user.lastName} ({user.email})
          </b>{' '}
          is trying to claim the company <b>{company.name}</b> for the
          organization <b>{organization.name}.</b>
        </p>
        <p>The organization's members are as follows:</p>
        <ul>
          {organization.members.map(({firstName, lastName, email}, n) => (
            <li key={n}>
              {firstName} {lastName} ({email})
            </li>
          ))}
        </ul>
        <Element
          rules={() => ({
            textAlign: 'right',
          })}>
          <Button
            color='error'
            onClick={this.delete}
            disabled={loading}
            css={`
              margin-right: 10px;
            `}>
            Delete
          </Button>

          <Button
            onClick={this.reject}
            disabled={loading}
            css={`
              margin-right: 10px;
            `}>
            Reject
          </Button>

          <Button color='primary' disabled={loading} onClick={this.accept}>
            Accept
          </Button>
        </Element>
      </Element>
    );
  }
}

export default compose(
  graphql(CompanyClaimQuery, {
    options: ({
      match: {
        params: {companyClaimId},
      },
    }) => ({
      variables: {
        id: companyClaimId,
      },
    }),
  }),

  graphql(AcceptCompanyClaimMutation, {
    props: ({ownProps: {organization}, mutate}) => ({
      acceptCompanyClaim: (input) => {
        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  }),
  graphql(RejectCompanyClaimMutation, {
    props: ({ownProps: {organization}, mutate}) => ({
      rejectCompanyClaim: (input) => {
        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  }),
  graphql(DeleteCompanyClaimMutation, {
    props: ({ownProps: {organization}, mutate}) => ({
      deleteCompanyClaim: (input) => {
        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  })
)(CompanyClaim);
