import React, {forwardRef} from 'react';
import UsersQuery from '@admin/graphql-api/_old_queries/UsersQuery';
import {graphql} from '@apollo/client/react/hoc';
import dayjs from '@core/lib/dayjs';
import {Card, PageTitle} from '@core/ui/Content';
import DataTable, {DateRangeFilter} from '@core/ui/DataTable';
import Loading from '@core/ui/Loading';

const Users = ({data: {loading, admin}}) => {
  if (loading) {
    return <Loading absolute />;
  }

  const {users} = admin;

  const columns = [
    {
      title: 'User',
      accessor: 'name',
      type: 'string',
      rule: () => ({fontWeight: 500}),
    },
    {
      title: 'Email',
      accessor: 'email',
      type: 'string',
    },
    {
      title: 'Created',
      accessor: 'createdAt',
      type: 'date',
      Filter: forwardRef(({...props}, ref) => (
        <DateRangeFilter
          ref={ref}
          label='Created'
          startAccessor='createdAt'
          endAccessor='createdAt'
          {...props}
        />
      )),
      fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
    },
  ];

  const data = users.slice(0).map((user) => {
    const name = `${user.firstName} ${user.lastName}`;
    return Object.assign({}, user, {
      name,
    });
  });

  return (
    <>
      <PageTitle>Users</PageTitle>
      <Card>
        <DataTable
          columns={columns}
          data={data}
          searchKeys={['name', 'email', 'id']}
          searchPlaceholder='Search by username or email'
          orderBy='-createdAt'
          filtersStorageKey='admin-users'
          onClickRowPath={({id}) => `/users/${id}`}
        />
      </Card>
    </>
  );
};

export default graphql(UsersQuery, {
  options: {
    fetchPolicy: 'network-only',
  },
})(Users);
