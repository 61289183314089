import {Control, Controller, FieldValues, Path} from 'react-hook-form';
import {FormLabel} from '../Form New';
import {Slider, SliderProps} from './Slider';

export type SliderControlledProps<T extends FieldValues> = Omit<
  SliderProps,
  'control'
> & {
  name: Path<T>;
  control?: Control<T>;
  label?: string;
};

export function SliderControlled<TFieldValues extends FieldValues>({
  control,
  label,
  name,
  valueLabelDisplay = 'auto',
  ...props
}: SliderControlledProps<TFieldValues>) {
  return (
    <>
      {label && <FormLabel component='legend'>{label}</FormLabel>}
      <Controller
        name={name}
        control={control}
        render={({field: {onChange, value}}) => (
          <Slider
            {...props}
            value={value}
            onChange={onChange}
            valueLabelDisplay={valueLabelDisplay}
          />
        )}
      />
    </>
  );
}
