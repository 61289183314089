import {
  ChangeEvent,
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {Stack} from '@mui/system';
import ReactDOM from 'react-dom';
import {useHistory} from 'react-router-dom';
import useSessionStorage from '@core/lib/useSessionStorage';
import {css} from '@core/style';
import EmptyMessage from '../EmptyMessage';
import DataTableBody from './DataTableBody';
import DataTableBulkActions from './DataTableBulkActions';
import DataTableFilters from './DataTableFilters';
import DataTableHeader from './DataTableHeader';
import DataTablePagination from './DataTablePagination';
import DataTableSearch from './DataTableSearch';
import {applyFilters, applySearch, sortData} from './helpers';
import {
  DataTableColumn,
  DataTableFilter,
  DataTableProps,
  DataTableValuesMap,
} from './types';

function DataTable<Data extends Record<string, any> = Record<string, any>>({
  bulkActions = [],
  columns,
  data = [],
  defaultSelectedRows = [],
  domRef = undefined,
  emptyMessageText,
  emptyMessageTitle,
  filtersStorageKey,
  headerRenderer = undefined,
  noHeader = false,
  onChangeSelectedRows,
  onClickRowPath = undefined,
  onSortByChange,
  orderBy,
  pagination = true,
  paginationRowsPerPage = 20,
  paginationRowsPerPageOptions = [20, 50, 100],
  propertyForKey = 'id',
  rowRuleFn,
  rules,
  searchAutofocus = false,
  searchKeys = [],
  searchMinItems = 6,
  searchPlaceholder = 'Search',
  withSortFilter = false,
}: DataTableProps<Data>) {
  const history = useHistory();
  const [selectedRows, setSelectedRowState] = useState<any[]>(() => {
    const ids = new Set(defaultSelectedRows);
    return data.filter((d) => ids.has(d.id));
  });
  const setSelectedRows = (rows: any[]) => {
    setSelectedRowState(rows);
    onChangeSelectedRows?.(rows);
  };
  const [forceUpdateKey, setForceUpdateKey] = useState(0);
  const [sortBy, setSortBy] = useState<string>(orderBy);
  const [query, setQuery] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState(
    pagination ? paginationRowsPerPage : Infinity
  );
  const [rowStart, setRowStart] = useState(0);
  const [filtersDefaultValues, setFiltersDefaultValues] = useSessionStorage<{
    [accessor: string]: any;
  }>({
    id: filtersStorageKey ? `ui:datatable:${filtersStorageKey}` : null,
    initialState: {},
  });

  const visibleColumns = useMemo(
    () => columns.filter(({hidden = false}) => !hidden),
    [columns]
  );

  const filters = useMemo(
    () =>
      columns.reduce((acc: DataTableFilter[], column: DataTableColumn) => {
        if (column.Filter) {
          acc.push({
            column,
            Filter: column.Filter,
            ref: createRef(),
          });
        }
        return acc;
      }, []),
    [columns]
  );

  const valuesMap: DataTableValuesMap = useMemo(() => {
    const _map = new WeakMap();

    columns.forEach((c) => {
      _map.set(c, new WeakMap());
      data.forEach((d) => {
        _map
          .get(c)
          .set(d, c.getValue ? c.getValue({data: d, d}) : d[c.accessor]);
      });
    });

    return _map;
  }, [data, columns]);

  const onSearchInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const {value} = evt.target;
    setQuery(value);
    setRowStart(0);
  };

  const onFilterChange = useCallback(
    ({
      column,
      defaultValue,
    }: {column?: DataTableColumn; defaultValue?: any} = {}) => {
      if (filtersStorageKey && column) {
        filtersDefaultValues[column.accessor] = defaultValue;
        setFiltersDefaultValues({...filtersDefaultValues});
      }

      // Reset Table
      setRowStart(0);
      setForceUpdateKey((prev) => prev + 1);
    },
    [filtersStorageKey, filtersDefaultValues, setFiltersDefaultValues]
  );

  const onUpdateSortBy = useCallback(
    (value: string) => {
      setSortBy(value);
      onSortByChange?.(value);
    },
    [onSortByChange]
  );

  const filteredData = forceUpdateKey
    ? sortData({
        data: applyFilters({
          data: applySearch({
            data,
            query,
            searchColumns: searchKeys.map((key) =>
              columns.find((c) => c.accessor === key)
            ),
            valuesMap,
          }),
          filters,
        }),
        valuesMap,
        sortBy,
        columns,
      })
    : [];

  const currentPageData = filteredData.slice(rowStart, rowsPerPage + rowStart);
  const searchable = searchKeys.length && data.length >= searchMinItems;

  useLayoutEffect(() => {
    setForceUpdateKey((prev) => prev + 1);
  }, []);

  const searchContent =
    searchable && data.length ? (
      <DataTableSearch
        autofocus={searchAutofocus}
        onChange={onSearchInputChange}
        placeholder={searchPlaceholder}
        rules={() => ({
          marginRight: 'var(--spacing-3)',
        })}
      />
    ) : null;
  const filtersContent =
    filters.length > 0 && data.length ? (
      <DataTableFilters
        columns={columns}
        data={data}
        filters={filters}
        filtersDefaultValues={filtersDefaultValues}
        onFilterChange={onFilterChange}
        onUpdateSortBy={onUpdateSortBy}
        withSortFilter={withSortFilter}
        valuesMap={valuesMap}
      />
    ) : null;

  const headerContent = headerRenderer ? (
    <div
      css={`
        margin-bottom: var(--spacing-6);
      `}>
      {headerRenderer({filtersContent, searchContent})}
    </div>
  ) : searchContent || filtersContent ? (
    <Stack
      alignItems='center'
      css={`
        margin-bottom: var(--spacing-6);
      `}>
      {searchContent}
      {filtersContent}
    </Stack>
  ) : null;

  return (
    <div
      ref={domRef}
      {...css([
        () => ({
          color: 'var(--text-default)',
          display: 'grid',
          gridTemplateRows: `${
            searchable ? 'max-content ' : ''
          } max-content 1fr ${pagination ? 'max-content' : ''}`,
          height: '100%',
        }),
        rules,
      ])}>
      {headerContent}
      {data.length ? (
        <>
          <div style={{position: 'relative'}}>
            {bulkActions.length > 0 && selectedRows.length > 0 && (
              <DataTableBulkActions
                bulkActions={bulkActions}
                selectedRows={selectedRows}
              />
            )}
          </div>
          <div
            css={{
              minHeight: 0,
              overflowY: 'auto',
              ...(noHeader && {
                borderTop: '1px solid var(--border-subtle)',
                marginTop: '0.75rem',
              }),
            }}>
            <table
              css={{
                borderCollapse: 'collapse',
                borderSpacing: 0,
                fontSize: '0.875rem',
                margin: 0,
                textAlign: 'left',
                width: '100%',
              }}>
              {!noHeader && (
                <DataTableHeader
                  bulkActions={bulkActions}
                  columns={visibleColumns}
                  onUpdateSortBy={onUpdateSortBy}
                  filteredData={filteredData}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  sortBy={sortBy}
                />
              )}
              <DataTableBody
                key={forceUpdateKey}
                bulkActions={bulkActions}
                columns={visibleColumns}
                currentPageData={currentPageData}
                history={history}
                onClickRowPath={onClickRowPath}
                propertyForKey={propertyForKey}
                rowRuleFn={rowRuleFn}
                valuesMap={valuesMap}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            </table>
          </div>
          {pagination ? (
            <DataTablePagination
              count={filteredData.length}
              paginationRowsPerPage={paginationRowsPerPage}
              paginationRowsPerPageOptions={paginationRowsPerPageOptions}
              rowsPerPage={rowsPerPage}
              rowStart={rowStart}
              onRowStartChange={setRowStart}
              onRowsPerPageChange={setRowsPerPage}
            />
          ) : null}
        </>
      ) : (
        <EmptyMessage title={emptyMessageTitle}>
          {emptyMessageText}
        </EmptyMessage>
      )}
    </div>
  );
}

export default DataTable;
