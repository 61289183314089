import useRoutes from '@core/lib/useRoutes';
import {CompanyType} from './_types';

export const CompaniesPath = '/companies';

export const toCompanyUrl = (company: CompanyType) => {
  return `${CompaniesPath}/${company.id}`;
};

export const useCompanyRoutes = ({company}: {company: CompanyType}) => {
  return useRoutes({
    baseUrl: `${CompaniesPath}/${company.id}`,
    basePath: `${CompaniesPath}/:companyId`,
    routes: [
      {key: 'company', path: '', exact: true},
      {key: 'update', exact: true},
      {key: 'detail', exact: true},
      {key: 'merge', exact: true},
      {key: 'image', exact: true},
      {key: 'tags', exact: true},
      {key: 'delete', exact: true},
      {key: 'archive', exact: true},
      {key: 'product', path: '/products/:productId'},
    ],
  });
};
