import {gql} from '@apollo/client';

export default gql`
  query getQueue($topic: String!, $start: Int, $stop: Int) {
    admin {
      queue(topic: $topic) {
        length
        messages(start: $start, stop: $stop) {
          action
          attributes
        }
      }
    }
  }
`;
