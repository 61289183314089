/**
 * A Promise resolver is an object that provides a promise that can be resolved
 * or reject using functions from the object itself.
 *
 * Storing the `resolve` and `reject` functions from a Promise executor is a
 * common pattern, and a resolver object makes it easier to create and store
 * these objects.
 */
export interface PromiseResolver<T = unknown> {
  /**
   * Resolves the `promise` associated with this resolver instance with the
   * provided value.
   *
   * @param value - The resolved value of the promise.
   */
  resolve(value?: T | Promise<T>): void;

  /**
   * Rejects the `promise` associated with this resolver instance with the
   * provided reason.
   *
   * @param reason - The rejection reason.
   */
  reject(reason?: any): void;

  /**
   * The promise associated with this resolver.
   *
   * The promise will start in a pending state, and will be resolved or rejected
   * once the `resolve` and `reject` methods of this object is called.
   */
  promise: Promise<T>;
}

/**
 * Creates a new PromiseResolver.
 *
 * @returns A new PromiseResolver instance.
 */
export function createPromiseResolver<T = unknown>(): PromiseResolver<T> {
  let resolve: PromiseResolver<T>['resolve'];
  let reject: PromiseResolver<T>['reject'];
  const promise = new Promise<T>((res, rej) => {
    resolve = res as PromiseResolver['resolve'];
    reject = rej;
  });
  return {
    promise: promise,
    resolve: resolve!,
    reject: reject!,
  };
}
