var iconSizesMap = {
  xsmall: 12,
  small: 16,
  medium: 24,
  large: 32,
  xlarge: 48,
  xxlarge: 64
};
export var getIconNumeric = function getIconNumeric(iconSize) {
  return iconSizesMap[iconSize];
};