import {HTMLAttributes, Ref, forwardRef} from 'react';
import {Link, LinkProps} from 'react-router-dom';
import styled from 'styled-components';
import {focusVisibleCss} from '@core/style/mixins/focusVisible';
import {Icon, IconName} from '../Icon';

export type ButtonIconProps = HTMLAttributes<HTMLElement> &
  Pick<LinkProps, 'target' | 'href' | 'rel' | 'download'> & {
    /**
     * Optional Rules.
     */
    icon: IconName;
    /**
     * A string representation of the Link location, created by concatenating the
     * ocation’s pathname, search, and hash properties.
     *
     * Alternatively, it can be an object or a function: https://v5.reactrouter.com/web/api/Link
     */
    to?: LinkProps['to'];
    disabled?: boolean;
  };

const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({icon, href, to, ...props}, ref) => {
    const content = <Icon icon={icon} />;

    if (href) {
      return (
        <_ButtonIcon
          ref={ref as Ref<HTMLAnchorElement>}
          as='a'
          href={href}
          {...props}>
          {content}
        </_ButtonIcon>
      );
    } else if (to) {
      return (
        <_ButtonIcon
          as={Link}
          ref={ref as Ref<HTMLAnchorElement>}
          to={to}
          {...props}>
          {content}
        </_ButtonIcon>
      );
    }
    return (
      <_ButtonIcon ref={ref} type='button' {...props}>
        {content}
      </_ButtonIcon>
    );
  }
);

const _ButtonIcon = styled.button<{disabled?: boolean}>`
  align-items: center;
  background: none;
  border: 0;
  color: ${(button) =>
    button.disabled ? 'var(--icon-muted)' : 'var(--icon-subtle)'};
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  outline: 0;
  padding: 0 0.25rem;
  pointer-events: ${(button) => (button.disabled ? 'none' : 'auto')};
  :hover {
    color: ${(button) => (button.disabled ? null : 'var(--icon-default)')};
  }
  :hover > svg {
    color: ${(button) => (button.disabled ? null : 'var(--icon-default)')};
  }
  ${(button) => !button.disabled && focusVisibleCss}
`;

export default ButtonIcon;
