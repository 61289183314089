import {PropsWithChildren, createContext, useReducer} from 'react';

export type SettingsContextValue = {
  impressionsByPlacements: boolean;
  modelled: boolean;
  spendByImpressionsDelivered: boolean;
  theme: 'light' | 'dark' | 'system';
  // Layout Settings
  hideUi: boolean;
  layoutNoBottomPadding: boolean;
};

export type SettingsContextAction = {
  type: 'UPDATE_SETTINGS';
  settings: Partial<SettingsContextValue>;
};

const DEFAULT_SETTINGS: SettingsContextValue = {
  impressionsByPlacements: true,
  modelled: true,
  spendByImpressionsDelivered: true,
  theme: 'light',
  // Layout Settings
  hideUi: false,
  layoutNoBottomPadding: false,
};

// Settings that are going to be stored in the localStorage.
const LOCAL_STORAGE_SETTINGS = [
  'impressionsByPlacements',
  'modelled',
  'spendByImpressionsDelivered',
  'theme',
];

const getLocalStorageSettings = () => {
  const settings = DEFAULT_SETTINGS;
  try {
    const storedSettings = JSON.parse(
      window.localStorage.getItem('settings') ?? '{}'
    );
    const validStoredSettings = Object.entries(storedSettings).reduce(
      (acc, [k, v]) => {
        if (LOCAL_STORAGE_SETTINGS.includes(k)) acc[k] = v;
        return acc;
      },
      {}
    );

    return {...settings, ...validStoredSettings};
  } catch (_) {
    return {...settings};
  }
};

const setLocalStorageSettings = (settings: Partial<SettingsContextValue>) => {
  const allSettings = {...DEFAULT_SETTINGS, ...settings};

  Object.keys(allSettings).forEach((key) => {
    if (!LOCAL_STORAGE_SETTINGS.includes(key)) delete allSettings[key];
  });
  window.localStorage.setItem('settings', JSON.stringify(allSettings));
};

const reducer = (
  state: SettingsContextValue,
  action: SettingsContextAction
) => {
  switch (action.type) {
    case 'UPDATE_SETTINGS': {
      const newState = {
        ...state,
        ...action.settings,
      };
      setLocalStorageSettings(newState);
      return newState;
    }
    default:
      return state;
  }
};

const SettingsContext = createContext<
  [SettingsContextValue, React.Dispatch<SettingsContextAction>]
>([DEFAULT_SETTINGS, () => undefined]);

const SettingsProvider = ({children}: PropsWithChildren) => {
  return (
    <SettingsContext.Provider
      value={useReducer(reducer, getLocalStorageSettings())}>
      {children}
    </SettingsContext.Provider>
  );
};

export {SettingsProvider};

export default SettingsContext;
