import React from 'react';
import AddAccessMutation from '@admin/graphql-api/_old_mutations/AddAccess';
import PodcastQuery from '@admin/graphql-api/_old_queries/PodcastQuery';
import OrganizationsQuery from '@admin/graphql-api/queries/AdminOrganizationsQuery';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import {Redirect} from 'react-router-dom';
import {Element} from '@core/style';
import {Submit} from '@core/ui/FormElements';
import Loading from '@core/ui/Loading';
import Select from '@core/ui/Select';

class OrganizationPodcastAccessAdd extends React.Component {
  constructor(props) {
    super(props);
    const {company} = props;

    this.state = {
      organizationId: '',
      loading: false,
      complete: false,
    };
  }

  onOrgChange = ({title, value}) => {
    this.setState({
      organizationId: value,
    });
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const {
      addAccess,
      match: {
        params: {podcastId},
      },
    } = this.props;
    const {organizationId} = this.state;

    if (!organizationId) {
      return;
    }

    this.setState({
      loading: true,
    });

    addAccess({
      organizationId: organizationId,
      name: 'sell-side:podcast',
      permission: 'can-view',
      objectPk: podcastId,
      contentType: 'podcasts.podcast',
    }).then(() => {
      this.setState({
        complete: true,
      });
    });
  };

  onCancel = () => {
    this.setState({
      feedId: '',
      feedTitle: '',
    });
  };

  render() {
    const {organization, podcast, data} = this.props;

    if (data.loading) {
      return <Loading absolute />;
    }

    const {loading, complete, feedId, feedTitle, error} = this.state;

    if (complete) {
      return (
        <Redirect
          to={`/organizations/${organization.id}/podcasts/${podcast.id}`}
        />
      );
    }

    return (
      <Element rules={() => ({maxWidth: '600px', margin: '25px auto'})}>
        <h4>Add Access to Podcast</h4>
        <p>Link podcast to the following sell side.</p>

        <form onSubmit={this.onSubmit}>
          <h5>Sell Side Agency</h5>
          <Select
            items={data.admin.organizations.map((org) => ({
              title: org.name,
              value: org.id,
            }))}
            onSelect={this.onOrgChange}
            rules={() => ({
              padding: '10px',
              fontSize: '16px',
              height: '42px',
            })}
          />
          <Element rules={() => ({padding: '20px 0', textAlign: 'right'})}>
            <Submit variant='outlined' value='Add View Access' />
          </Element>
        </form>
      </Element>
    );
  }
}

export default compose(
  graphql(OrganizationsQuery, {
    options: () => ({
      variables: {
        kind: 'sell-side',
      },
    }),
  }),
  graphql(AddAccessMutation, {
    props: ({ownProps: {organization, podcast}, mutate}) => ({
      addAccess: (input) => {
        return mutate({
          variables: {
            input,
          },
          update: (
            store,
            {
              data: {
                addAccess: {access},
              },
            }
          ) => {
            const query = {
              query: PodcastQuery,
              variables: {organizationId: organization.id, id: podcast.id},
            };

            query.data = store.readQuery(query);

            const accessSet =
              query.data.admin.organization.podcast.accessSet.slice(0);
            accessSet.push(access);
            query.data.admin.organization.podcast.accessSet = accessSet;

            store.writeQuery(query);
          },
        });
      },
    }),
  })
)(OrganizationPodcastAccessAdd);
