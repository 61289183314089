import {
  Plugin,
  PluginEventTypes,
  PluginMediator,
  PluginSocketAPI,
} from '../../typedefs';
import {DealerErrors} from './typedefs';

import {PluginEvent} from '../../enums/plugin_event';

import {DealerError} from './error';

import {DealerEvent} from './event';
import {Dealer, DealerEventTypes, DealerOptions} from './dealer';
import {Transport} from '../../transport';

/**
 * The name of the Dealer Plugin.
 *
 * This value should be used when calling Transport methods like `hasPlugin()`
 * or `getPluginAPI()`.
 */
export const DEALER_PLUGIN_NAME = 'dealer';

/**
 * A Transport Plugin that implements the Dealer protocol.
 */
class DealerPlugin implements Plugin<PluginSocketAPI> {
  /**
   * The private Dealer instance.
   */
  private _dealer: Dealer;

  /**
   * An reference to the plugin mediator.
   */
  private _mediator?: PluginMediator;

  /**
   * The name of the plugin.
   */
  readonly name: string = DEALER_PLUGIN_NAME;

  /**
   * The API for the plugin.
   */
  readonly api: PluginSocketAPI;

  constructor(options: DealerOptions) {
    this._dealer = new Dealer(options);

    // Create the API, which is always bound to the instance.
    this.api = {
      hasConnectionInfo: () => this._dealer.hasConnectionId(),
      getConnectionInfo: () => {
        return this._dealer.getConnectionInfo().then((info) => {
          return {plugin: this.name, ...info};
        });
      },
    };

    // Rebind
    this._onDealerConnectionId = this._onDealerConnectionId.bind(this);
    this._onDealerDisconnected = this._onDealerDisconnected.bind(this);
    this._onDealerMessage = this._onDealerMessage.bind(this);
    this._onDealerRequest = this._onDealerRequest.bind(this);
    this._onTransportConnect = this._onTransportConnect.bind(this);
    this._onTransportAuthenticate = this._onTransportAuthenticate.bind(this);
    this._onTransportDisconnect = this._onTransportDisconnect.bind(this);
  }

  /**
   * Called when the Dealer instance receives a connection id.
   *
   * @param ev - The event object
   */
  private _onDealerConnectionId(
    ev: DealerEventTypes[DealerEvent.CONNECTION_ID]
  ): void {
    if (!this._mediator) {
      return;
    }
    this._mediator.emit(PluginEvent.PLUGIN_CONNECTION_INFO, {
      plugin: this.name,
      ...ev.data,
    });
  }

  /**
   * Called when the dealer instance gets disconnected.
   *
   * @param ev - The event object
   */
  private _onDealerDisconnected(
    ev: DealerEventTypes[DealerEvent.DISCONNECTED]
  ): void {
    if (!this._mediator) {
      return;
    }
    const data = ev.data;
    this._mediator.emit(PluginEvent.PLUGIN_DISCONNECTED, {
      plugin: this.name,
      code: data.wsCode,
      reason: data.reason,
    });
  }

  /**
   * Called when the dealer instance receives a message.
   *
   * @param ev - The event object
   */
  private _onDealerMessage(ev: DealerEventTypes[DealerEvent.MESSAGE]): void {
    if (!this._mediator) {
      return;
    }
    this._mediator.emit(PluginEvent.PLUGIN_MESSAGE, {
      plugin: this.name,
      ...ev.data,
    });
  }

  /**
   * Called when the dealer instance receives a request.
   *
   * @param ev - The event object
   */
  private _onDealerRequest(ev: DealerEventTypes[DealerEvent.REQUEST]): void {
    if (!this._mediator) {
      return;
    }
    this._mediator.emit(PluginEvent.PLUGIN_REQUEST, {
      plugin: this.name,
      ...ev.data,
    });
  }

  /**
   * Called when the transport instance starts connecting.
   *
   * @param ev - The event.
   */
  private _onTransportConnect(
    ev: PluginEventTypes[PluginEvent.TRANSPORT_CONNECT]
  ): void {
    const endpoints = ev.data.endpoints;
    const awaitPromise = ev.data.awaitPromise;
    if (!endpoints.dealer) {
      awaitPromise(
        Promise.reject(
          new DealerError(
            DealerErrors.ENDPOINT_NOT_DEFINED,
            'No "dealer" endpoint defined.'
          )
        )
      );
      return;
    }
    if (!/^wss:/.test(endpoints.dealer)) {
      awaitPromise(
        Promise.reject(
          new DealerError(
            DealerErrors.INVALID_ENDPOINT,
            'Dealer endpoint needs to be wss://'
          )
        )
      );
      return;
    }
    awaitPromise(this._dealer.connect(endpoints.dealer));
  }

  /**
   * Called when the Transport instance starts authenticating.
   *
   * @param ev - The event.
   */
  private _onTransportAuthenticate(
    ev: PluginEventTypes[PluginEvent.TRANSPORT_AUTHENTICATE]
  ): void {
    const data = ev.data;
    data.awaitPromise(this._dealer.authenticate(data.token));
  }

  /**
   * Called when the Transport instance is disconnecting.
   */
  private _onTransportDisconnect(): void {
    this._dealer.disconnect();
  }

  private _onDealerError(ev: DealerEventTypes[DealerEvent.ERROR]): void {
    this._mediator?.emit(PluginEvent.PLUGIN_ERROR, {
      plugin: this.name,
      error: ev.data.error,
    });
  }

  /**
   * Attaches the plugin to a Transport instance.
   *
   * @param _t - The Transport instance.
   * @param mediator - The PluginMediator from the Transport instance.
   */
  attach(_t: Transport, mediator: PluginMediator): void {
    this._mediator = mediator;
    mediator.addListeners({
      [PluginEvent.TRANSPORT_CONNECT]: this._onTransportConnect,
      [PluginEvent.TRANSPORT_AUTHENTICATE]: this._onTransportAuthenticate,
      [PluginEvent.TRANSPORT_DISCONNECT]: this._onTransportDisconnect,
    });

    this._dealer.addListeners({
      [DealerEvent.DISCONNECTED]: this._onDealerDisconnected,
      [DealerEvent.CONNECTION_ID]: this._onDealerConnectionId,
      [DealerEvent.MESSAGE]: this._onDealerMessage,
      [DealerEvent.REQUEST]: this._onDealerRequest,
      [DealerEvent.ERROR]: this._onDealerError,
    });
  }

  /**
   * Detaches the plugin from a Transport instance.
   *
   * @param _t - The Transport instance.
   * @param mediator - The PluginMediator from the Transport instance.
   */
  detach(_t: Transport, mediator: PluginMediator): void {
    this._mediator = undefined;
    mediator.removeListeners({
      [PluginEvent.TRANSPORT_CONNECT]: this._onTransportConnect,
      [PluginEvent.TRANSPORT_AUTHENTICATE]: this._onTransportAuthenticate,
      [PluginEvent.TRANSPORT_DISCONNECT]: this._onTransportDisconnect,
    });

    this._dealer.removeListeners({
      [DealerEvent.DISCONNECTED]: this._onDealerDisconnected,
      [DealerEvent.CONNECTION_ID]: this._onDealerConnectionId,
      [DealerEvent.MESSAGE]: this._onDealerMessage,
      [DealerEvent.REQUEST]: this._onDealerRequest,
      [DealerEvent.ERROR]: this._onDealerError,
    });
  }
}

/**
 * The DealerPlugin type.
 */
export type DealerPluginT = DealerPlugin;
export {DealerErrors, DealerOptions};

/**
 * Creates a new Dealer Plugin.
 *
 * This function should be passed to `transport.addPlugin()` directly.
 *
 * @param _t - The Transport instance.
 * @param options - The options for the plugin.
 * @returns A new DealerPlugin.
 */
export function dealerCreator(
  _t: Transport,
  options: DealerOptions
): DealerPluginT {
  return new DealerPlugin(options);
}
