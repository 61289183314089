import React from 'react';
import UpdateProductMutation from '@admin/graphql-api/_old_mutations/_UpdateProduct';
import ProductQuery from '@admin/graphql-api/_old_queries/ProductQuery';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import {Element} from '@core/style';
import {FieldGroup, Input, Label, TextArea} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';

class ProductUpdate extends React.Component {
  constructor(props) {
    super(props);
    const {product} = props;

    this.state = {
      name: product.name,
      slug: product.slug,
      description: product.description || '',
      url: product.url || '',

      loading: false,
      complete: false,
    };
  }

  onChange = ({target: {name, value}}) => {
    const update = {error: false};
    update[name] = value;
    this.setState(update);
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const {updateProduct, product} = this.props;

    const {name, slug, description, url} = this.state;

    if (!name) {
      return;
    }

    this.setState({
      loading: true,
    });

    updateProduct({
      id: product.id,
      name,
      slug,
      description,
      url,
    }).then(() => {
      this.setState({
        complete: true,
      });
    });
  };

  render() {
    const {company, product} = this.props;

    const {
      loading,
      complete,

      name,
      slug,
      description,
      url,
      error,
    } = this.state;

    if (complete) {
      return (
        <Redirect to={`/companies/${company.id}/products/${product.id}`} />
      );
    }

    return (
      <Element rules={() => ({maxWidth: '600px', margin: '25px auto'})}>
        <h3>Update Product</h3>

        <form onSubmit={this.onSubmit}>
          <FieldGroup>
            <Label>Name</Label>
            <Input
              type='text'
              name='name'
              value={name}
              onChange={this.onChange}
              style-error={error}
              disabled={loading}
            />
          </FieldGroup>

          <FieldGroup>
            <Label>Slug</Label>
            <Input
              type='text'
              name='slug'
              value={slug}
              onChange={this.onChange}
              style-error={error}
              disabled={loading}
            />
          </FieldGroup>

          <FieldGroup>
            <Label>URL</Label>
            <Input
              type='text'
              name='url'
              value={url}
              onChange={this.onChange}
              style-error={error}
              disabled={loading}
            />
          </FieldGroup>

          <FieldGroup>
            <Label>Description</Label>
            <TextArea
              name='description'
              value={description}
              onChange={this.onChange}
              style-error={error}
              disabled={loading}
            />
          </FieldGroup>

          <Element rules={() => ({padding: '20px 0', textAlign: 'right'})}>
            <Submit value='Update' disabled={loading} />
          </Element>
        </form>
      </Element>
    );
  }
}

export default graphql(UpdateProductMutation, {
  props: ({mutate}) => ({
    updateProduct: (input) => {
      return mutate({
        variables: {
          input,
        },
        update: (
          store,
          {
            data: {
              updateProduct: {id, name, slug, description, url},
            },
          }
        ) => {
          // Stuff the new journey into the cache.
          const query = {
            query: ProductQuery,
            variables: {
              id,
            },
          };

          query.data = store.readQuery(query);

          query.data.admin.product = Object.assign(
            {},
            query.data.admin.product,
            {
              name,
              slug,
              description,
              url,
            }
          );

          store.writeQuery(query);
        },
      });
    },
  }),
})(ProductUpdate);
