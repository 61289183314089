import {css} from 'styled-components';
import {focusVisibleCss} from '@core/style/mixins/focusVisible';

export type ButtonSize = 'sm' | 'md' | 'lg';
export type ButtonVariant = 'filled' | 'outlined' | 'unstyled';
export type ButtonColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | 'inverse'
  | 'text-error'
  | 'text-warning'
  | 'text-info'
  | 'text-success';

const BUTTON_TRANSITION =
  'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';

export const buttonStyleBase = css<{$fullWidth?: boolean}>`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  text-decoration: none;
  transition: ${BUTTON_TRANSITION};
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  ${(p) => (p.$fullWidth ? 'width: 100%' : '')};
  [disabled] {
    cursor: default;
    opacity: 0.8;
  }
  ${focusVisibleCss}
`;

const customColorsMap = {
  default: css<{$variant: ButtonVariant}>`
    ${(p) =>
      p.$variant === 'filled'
        ? `
        background: var(--bg-default);
        color: var(--text-muted);
        :hover {
          background: var(--bg-muted);
        }
        `
        : `
        border: 1px solid var(--border-subtle);
        color: var(--text-muted);
        :hover {
          border-color: var(--border-subtle-hover);
          color: var(--text-subtle);
        }
    `}
  `,
};

export const buttonStyleColors = css<{
  $variant: ButtonVariant;
  $color: ButtonColor;
  $buttonSize: ButtonSize;
}>`
  ${(p) => {
    if (customColorsMap[p.$color]) {
      return customColorsMap[p.$color];
    }
    return `
      ${
        p.$variant === 'filled'
          ? `
          background: var(--${p.$color});
          color: var(--text-on-${p.$color.replace('text-', '')});
          `
          : ''
      }
      ${
        p.$variant === 'outlined'
          ? `
          color: var(--${p.$color});
          border: 1px solid var(--${p.$color});
          `
          : ''
      }
      ${
        p.$variant === 'filled'
          ? `
          position: relative;
          &:before {
            border-radius: inherit;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transition: ${BUTTON_TRANSITION};
            width: 100%;
          }
          :hover:before {
            background: rgba(255,255,255,0.3);
          }
          `
          : `
            :hover {
              background: var(--${p.$color});
              color: var(--text-on-${p.$color.replace('text-', '')});
            }
            `
      }`;
  }}
`;

const paddingMap = {
  sm: '0 var(--spacing-3) 0.0625rem',
  md: '0 var(--spacing-4)',
  lg: '0 var(--spacing-7) 0.0625rem',
};

const radiusMap = {
  sm: 'var(--radius-4)',
  md: 'var(--radius-4)',
  lg: '3rem',
};

const heightMap = {
  sm: '1.875rem',
  md: '2.25rem',
  lg: '3rem',
};

const fontSizeMap = {
  sm: 'var(--font-size-body-3)',
  md: 'var(--font-size-body-2)',
  lg: 'var(--font-size-h5)',
};

const fontWeightMap = {
  sm: 'var(--font-weight-regular)',
  md: 'var(--font-weight-medium)',
  lg: 'var(--font-weight-bold)',
};

export const buttonStyleLayout = css<{$buttonSize: ButtonSize}>`
  border-radius: ${(p) => radiusMap[p.$buttonSize]};
  font-size: ${(p) => fontSizeMap[p.$buttonSize]};
  height: ${(p) => heightMap[p.$buttonSize]};
  padding: ${(p) => paddingMap[p.$buttonSize]};
  font-weight: ${(p) => fontWeightMap[p.$buttonSize]};
`;
