import React from 'react';
import dayjs from 'dayjs';
import DataTable, {
  DataTableColumn,
  DataTableLinkButton,
} from '@core/ui/DataTable';

const OrganizationAssociatedDomainsTable = ({organization}) => {
  const columns: DataTableColumn[] = [
    {
      title: 'Domain',
      accessor: 'domain',
      type: 'string',
    },
    {
      title: 'Created at',
      accessor: 'createdAt',
      type: 'date',
      fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
    },
    {
      accessor: 'actions',
      Cell: ({data: {id}}) => {
        return (
          <DataTableLinkButton
            to={`/organizations/${organization.id}/domains/${id}/delete`}>
            Delete
          </DataTableLinkButton>
        );
      },
    },
  ];

  return (
    <DataTable
      searchKeys={['domain']}
      data={organization.domains}
      columns={columns}
      orderBy='-createdAt'
      emptyMessageText='No domains found.'
      onClickRowPath={({id}) =>
        `/organizations/${organization.id}/domains/${id}`
      }
    />
  );
};

export default OrganizationAssociatedDomainsTable;
