import React from 'react';
import AttributionPreRunMutation from '@admin/graphql-api/_old_mutations/AttributionPreRun';
import AttributionRunMutation from '@admin/graphql-api/_old_mutations/AttributionRun';
import OrganizationBackfillQuery from '@admin/graphql-api/_old_queries/OrganizationBackfillQuery';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import dayjs from '@core/lib/dayjs';
import {toTimeSeries} from '@core/lib/series';
import {COLORS, Element} from '@core/style';
import {Card, CardHeader} from '@core/ui/Content';
import {Submit} from '@core/ui/FormElements';
import {Link} from '@core/ui/Link';
import Loading from '@core/ui/Loading';

const SimpleChart = ({data, after, before}) => {
  const chartData = data.reduce((array, {name, hourly}, n) => {
    const series = toTimeSeries(
      hourly.map(({hour, count}) => ({x: hour, y: count})),
      'hour',
      after,
      before
    ).map(({x, y}) => {
      const d = {hour: x};
      d[name] = y;
      return d;
    });

    if (array.length == 0) {
      return series;
    }

    return array.map((d, n) => Object.assign({}, d, series[n]));
  }, []);

  return (
    <ResponsiveContainer width='100%' height={400}>
      <LineChart data={chartData}>
        <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
        <XAxis
          dataKey='hour'
          tickFormatter={(v) => {
            return dayjs(v).format('YYYY-MM-DD');
          }}
        />
        <YAxis />
        <Tooltip
          labelFormatter={(v) => {
            return dayjs(v).format('YYYY-MM-DD HH:00');
          }}
        />
        <Legend />
        {data.map(function ({name, color}, idx) {
          return (
            <Line
              key={name}
              type='monotone'
              dataKey={name}
              stroke={COLORS[idx]}
              fill={COLORS[idx]}
              dot={false}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

class RangeBackfill extends React.Component {
  state = {
    loading: false,
    ranPre: false,
    ranAttribution: false,
  };
  runMissing = (evt) => {
    evt.preventDefault();
    const {
      organization,
      attributionPreRun,
      data: {
        admin: {
          management: {
            attribution: {missing},
          },
        },
      },
    } = this.props;

    this.setState({
      loading: true,
    });

    const ranges = missing.map(({after, before}) => ({
      after,
      before,
      organizationId: organization.id,
    }));

    attributionPreRun({
      ranges,
    }).then(() => {
      this.setState({
        loading: false,
        ranPre: true,
      });
    });
  };

  runResetAttribution = (evt) => {
    evt.preventDefault();
    const {organization, attributionRun} = this.props;

    this.setState({
      loading: true,
    });

    attributionRun({
      organizationId: organization.id,
      reset: true,
    }).then(() => {
      this.setState({
        loading: false,
        ranAttribution: true,
      });
    });
  };

  runAttribution = (evt) => {
    evt.preventDefault();
    const {organization, attributionRun} = this.props;

    this.setState({
      loading: true,
    });

    attributionRun({
      organizationId: organization.id,
    }).then(() => {
      this.setState({
        loading: false,
        ranAttribution: true,
      });
    });
  };

  render() {
    const {after, before, data} = this.props;
    const {loading, ranPre, ranAttribution} = this.state;

    if (data.loading || loading) {
      return <Loading absolute />;
    }

    const {
      organization,
      management: {
        attribution: {missing},
      },
    } = data.admin;
    const {campaigns} = organization;

    const attributionHourly = campaigns
      .filter(({attribution}) => !!attribution)
      .map(
        (
          {
            name,
            attribution: {
              events: {hourly},
            },
          },
          idx
        ) => ({
          name,
          hourly,
          color: COLORS[idx],
        })
      );

    const downloadsHourly = campaigns.map(
      ({name, downloads: {hourly}}, idx) => ({
        name,
        hourly,
        color: COLORS[idx],
      })
    );

    const lastBuilts = campaigns.filter((c) => c.lastBuilt);

    const lastBuilt = lastBuilts.length
      ? lastBuilts
          .map((c) => dayjs(c.lastBuilt).utc())
          .reduce((min, l) => (min.isBefore(l) ? min : l))
      : null;

    if (ranAttribution) {
      return (
        <>
          <CardHeader>All Done.</CardHeader>
          <Card>
            <p>
              You ran the attribution job, so you need to wait for it to
              complete before doing anything else. Check the{' '}
              <Link to='/queue/attribution'>Attribution Queue</Link> or{' '}
              <a
                target='_blank'
                href='https://console.cloud.google.com/dataflow?project=adaptive-growth'
                rel='noreferrer'>
                DataFlow
              </a>{' '}
              to see if it's done.
            </p>
          </Card>
        </>
      );
    }

    return (
      <>
        {lastBuilt ? (
          <Element rules={() => ({margin: '1.25rem', textAlign: 'right'})}>
            Attribution last ran on this campaign on{' '}
            <b>{lastBuilt.format('YYYY-MM-DD')}</b>
          </Element>
        ) : (
          ''
        )}

        <CardHeader>Downloads By Campaign</CardHeader>
        <Card>
          <SimpleChart data={downloadsHourly} after={after} before={before} />
        </Card>

        <CardHeader>Attribution By Campaign</CardHeader>
        <Card>
          <SimpleChart data={attributionHourly} after={after} before={before} />
        </Card>

        {missing.length ? (
          <>
            <CardHeader>Fix Missing</CardHeader>
            <Card>
              Fix the missing data. End dates are not included, 2020-03-04 to
              2020-03-05 would only run the 4th.
              <ul>
                {missing.map(({after, before}) => (
                  <li key={after}>
                    {after} to {before}
                  </li>
                ))}
              </ul>
              <form onSubmit={this.runMissing}>
                <Submit value='Fix Missing' />
              </form>
            </Card>
          </>
        ) : (
          ''
        )}

        <CardHeader>Run Attribution</CardHeader>
        {!ranPre ? (
          <Card
            rules={() => ({display: 'grid', gridTemplateColumns: '4fr 1fr'})}>
            <p>Run Attribution on {organization.name}</p>
            <form onSubmit={this.runAttribution}>
              <Submit value='Run Attribution' />
            </form>
          </Card>
        ) : (
          <Card>
            You ran a pre job, so you need to wait for it to complete before
            doing anything else. Check the{' '}
            <Link to='/queue/attribution'>Attribution Queue</Link> or{' '}
            <a
              target='_blank'
              href='https://console.cloud.google.com/dataflow?project=adaptive-growth'
              rel='noreferrer'>
              DataFlow
            </a>{' '}
            to see if it's done.
          </Card>
        )}

        <CardHeader>Run Attribution on Every Campaign, Ever.</CardHeader>
        <Card rules={() => ({display: 'grid', gridTemplateColumns: '4fr 1fr'})}>
          <p>
            For older campaigns, this resets everything they ever ran.
            <b>THIS CAN BE REALLY BAD</b>
          </p>
          <form onSubmit={this.runResetAttribution}>
            <Submit value='Reset Attribution' style-destructive />
          </form>
        </Card>

        <CardHeader
          css={`
            color: var(--text-muted);
            margin: 2rem 1.5rem 1.25rem;
          `}>
          Run Pre on Everything (REMOVED)
        </CardHeader>
        <Card rules={() => ({display: 'grid', gridTemplateColumns: '4fr 1fr'})}>
          <p
            css={`
              color: var(--text-muted);
            `}>
            Re-run the pre job on everything. {after.format('YYYY-MM-DD')} -{' '}
            {before.format('YYYY-MM-DD')}. If you want to do a hard refresh
            where we pull the raw download data then clear everything first.
          </p>
        </Card>

        <CardHeader
          css={`
            color: var(--text-muted);
            margin: 2rem 1.5rem 1.25rem;
          `}>
          Clear This Range (REMOVED)
        </CardHeader>
        <Card rules={() => ({display: 'grid', gridTemplateColumns: '4fr 1fr'})}>
          <p
            css={`
              color: var(--text-muted);
            `}>
            Delete all the pre data from {after.format('YYYY-MM-DD')} -{' '}
            {before.format('YYYY-MM-DD')}.<b>THIS CAN BE REALLY BAD</b>
          </p>
        </Card>
      </>
    );
  }
}

export default compose(
  graphql(OrganizationBackfillQuery, {
    options: ({organization: {id, kind}, after, before}) => {
      return {
        variables: {
          id,
          after: after.format('YYYY-MM-DD'),
          before: before.format('YYYY-MM-DD'),
          isMarketing: kind.toLowerCase() == 'podcast',
        },
      };
    },
  }),
  graphql(AttributionPreRunMutation, {
    props: ({mutate}) => ({
      attributionPreRun: (input) => {
        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  }),
  graphql(AttributionRunMutation, {
    props: ({mutate}) => ({
      attributionRun: (input) => {
        return mutate({
          variables: {
            input,
          },
        });
      },
    }),
  })
)(RangeBackfill);
