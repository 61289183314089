import {gql} from '@apollo/client';

export default gql`
  query getCompanyClaims($states: [String!]) {
    admin {
      companyClaims(states: $states) {
        id
        state
        user {
          id
          firstName
          lastName
          email
        }

        company {
          id
          name
        }
        organization {
          id
          name
        }
        createdAt
      }
    }
  }
`;
