import React from 'react';
import DataTable from '@core/ui/DataTable';

const columns = [
  {
    title: 'Podcast',
    accessor: 'title',
    type: 'string',
  },
];

const OrganizationPodcastsTable = ({organization}) => {
  const data = organization.podcasts.map((podcast) => ({
    id: podcast.id,
    title: podcast.feed.title,
  }));

  return (
    <DataTable
      searchKeys={['title']}
      data={data}
      columns={columns}
      orderBy='-title'
      emptyMessageText='No podcasts found.'
      onClickRowPath={({id}) =>
        `/organizations/${organization.id}/podcasts/${id}`
      }
    />
  );
};

export default OrganizationPodcastsTable;
