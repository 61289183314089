import {
  EventSenderReporter,
  EventSenderReporterConfig,
} from '../reporters/EventSender';

import { createUserAgentContext } from '@spotify-internal/event-sender/es5/contexts';

type DistributiveOmit<T, K extends keyof T> = T extends unknown
  ? Omit<T, K>
  : never;

type CreateUniversalReporterConfig = DistributiveOmit<
  EventSenderReporterConfig,
  'environment'
>;

export function createUniversalReporter(
  config: CreateUniversalReporterConfig = {},
) {
  const context = config.context
    ? [createUserAgentContext(), ...config.context]
    : [createUserAgentContext()];
  return EventSenderReporter.create({
    environment: 'browser',
    ...config,
    context,
  });
}
