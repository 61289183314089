import React from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import MessagePublish from './MessagePublish';
import Queue from './Queue';

export default function () {
  return (
    <div>
      <Link to='/queue/worker'>Worker Queue</Link>{' '}
      <Link to='/queue/fetcher'>Fetcher Queue</Link>{' '}
      <Link to='/queue/diarization'>Diarization Queue</Link>
      <Link to='/queue/attribution'>Attribution Queue</Link>
      <Switch>
        <Route exact path='/queue/:topic' component={Queue} />
        <Route exact path='/queue/:topic/publish' component={MessagePublish} />
        <Route exact path='/queue/:topic/:start/:stop' component={Queue} />
      </Switch>
    </div>
  );
}
