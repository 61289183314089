import {
  Switch as MuiSwitch,
  type SwitchProps as MuiSwitchProps,
  ThemeOptions,
} from '@mui/material';
import {focusVisibleStyleObj} from '@core/style';

export type SwitchProps = Pick<
  MuiSwitchProps,
  | 'id'
  | 'checked'
  | 'color'
  | 'defaultChecked'
  | 'disabled'
  | 'inputProps'
  | 'name'
  | 'onChange'
  | 'size'
  | 'value'
>;

export function Switch(props: SwitchProps) {
  return <MuiSwitch disableRipple {...props} />;
}

const sizesMap = {
  medium: {
    root: {
      width: '3.5rem',
      height: '2.125rem',
    },
    thumb: {
      width: '1.375rem',
      height: '1.375rem',
    },
    switchBaseChecked: {
      transform: 'translateX(1.375rem)',
    },
  },
  small: {
    root: {
      width: '34px',
      height: '20px',
    },
    thumb: {
      width: '12px',
      height: '12px',
    },
    switchBaseChecked: {
      transform: 'translateX(0.875rem) !important',
    },
  },
};

export const MuiSwitchStyles: ThemeOptions['components'] = {
  MuiSwitch: {
    defaultProps: {
      color: 'primary',
      size: 'small',
    },
    styleOverrides: {
      root: ({ownerState: {size = 'medium'}}) => {
        return {
          margin: 'var(--spacing-1)',
          overflow: 'unset',
          padding: 0,
          ...sizesMap[size].root,
        };
      },
      switchBase: ({ownerState: {size = 'medium', color}}) => ({
        padding: '6px',
        '&.Mui-focusVisible': focusVisibleStyleObj,
        '& .MuiSwitch-thumb': {
          backgroundColor: 'var(--icon-muted)',
          boxShadow: 'none',
          ...sizesMap[size].thumb,
        },
        '&.Mui-checked': {
          color: 'var(--white)',
          ...sizesMap[size].switchBaseChecked,
          '& + .MuiSwitch-track': {
            backgroundColor: `var(--${color})`,
            borderColor: `var(--${color})`,
            opacity: 1,
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: 'var(--white)',
          },
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          opacity: 0.5,
        },
      }),
      track: () => {
        return {
          backgroundColor: 'var(--bg-surface)',
          border: '2px solid var(--icon-muted)',
          borderRadius: '1.75rem',
          boxSizing: 'border-box',
          opacity: 1,
          transition: 'all 0.2s ease',
        };
      },
    },
  },
};
