import { screenXsMax } from '@spotify-internal/encore-foundation';
import { cssBorderRadius, cssSpacing, defaultTheme } from "../../styles/index.js";
import { Type } from "../Type/index.js";
import styled from "../../styled-components.js";
export var Legal = styled(Type).attrs({
  variant: 'finale'
}).withConfig({
  displayName: "Legal",
  componentId: "sc-5ufqr3-0"
})(["background:", ";border-radius:", ";color:", ";display:", ";max-width:100%;padding:", ";@media (max-width:", "){display:block;}a{margin-inline-start:0;}"], function (props) {
  return "var(--background-tinted-base, ".concat(defaultTheme[props.colorSet].background.tinted.base, ")");
}, cssBorderRadius('base', '4px'), function (props) {
  return "var(--text-subdued, ".concat(defaultTheme[props.colorSet].text.subdued, ")");
}, function (props) {
  return props.legalStrict ? 'block' : 'inline-block';
}, cssSpacing('tighter-2', '8px'), screenXsMax);