import React from 'react';
import MergeCompanyMutation from '@admin/graphql-api/_old_mutations/MergeCompany';
import {graphql} from '@apollo/client/react/hoc';
import {Redirect} from 'react-router-dom';
import CompanySelect from '@core/components/CompanySelect';
import ProductSelector from '@core/components/ProductSelector';
import {Button} from '@core/ui/Button';
import {Card, CardHeader} from '@core/ui/Content';
import {FormFooter, Label, Submit} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {toCompanyUrl} from './_routes';

class CompanyMerge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: '',
      companyName: '',
      companySlug: '',

      productId: '',
      productName: '',
      productSlug: '',

      loading: false,
      complete: false,
    };
  }

  onCompanyChange = ({name, id, slug}) => {
    this.setState({
      companyId: id,
      companyName: name,
      companySlug: slug,
    });
  };

  onProductChange = ({name, id, slug}) => {
    this.setState({
      productId: id,
      productName: name,
      productSlug: slug,
    });
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const {mergeCompany, company} = this.props;

    const {companyId, productId} = this.state;

    if (!companyId) {
      return;
    }

    this.setState({
      loading: true,
    });

    mergeCompany({
      id: company.id,
      companyId,
      productId,
    }).then(
      ({
        data: {
          mergeCompany: {
            company: {id},
          },
        },
      }) => {
        this.setState({
          complete: id,
        });
      }
    );
  };

  onCancel = () => {
    this.setState({
      companyId: '',
      companyName: '',
      companySlug: '',

      productId: '',
      productName: '',
      productSlug: '',
    });
  };

  render() {
    const {company} = this.props;

    const {
      loading,
      complete,

      companyId,
      companyName,
      companySlug,

      productId,
      productName,
      productSlug,

      error,
    } = this.state;

    if (complete) {
      return <Redirect to={`/companies/${complete}`} />;
    }
    return (
      <ResponsiveContent containerSize='medium'>
        <CardHeader backPath={toCompanyUrl(company)}>Merge Company</CardHeader>
        <Card>
          <p>
            Merge{' '}
            <strong>
              {company.name} ({company.slug})
            </strong>{' '}
            with the following company:
          </p>
          <form onSubmit={this.onSubmit}>
            {companyId ? (
              <>
                <b
                  css={`
                    margin: 1rem 0;
                    display: block;
                  `}>
                  {companyName} ({companySlug})
                </b>

                {productId ? (
                  <b
                    css={`
                      margin: 1rem 0;
                      display: block;
                    `}>
                    {productName} ({productSlug})
                  </b>
                ) : (
                  <>
                    <Label>Product</Label>
                    <ProductSelector
                      onSelect={this.onProductChange}
                      companyId={companyId}
                    />
                  </>
                )}

                <FormFooter
                  css={`
                    padding: 20px 0;
                    text-align: right;
                  `}>
                  <Button onClick={this.onCancel}>Cancel</Button>
                  <Submit value='Merge' disabled={loading} />
                </FormFooter>
              </>
            ) : (
              <>
                <Label>Company</Label>
                <CompanySelect onSelect={this.onCompanyChange} />
              </>
            )}
          </form>
        </Card>
      </ResponsiveContent>
    );
  }
}

export default graphql(MergeCompanyMutation, {
  props: ({mutate}) => ({
    mergeCompany: (input) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
  }),
})(CompanyMerge);
