import React from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {BLUE, Element} from '@core/style';
import UploadForm from './UploadForm';
import CreateAsset from './mutations/CreateAsset';
import CreateImage from './mutations/CreateImage';
import SignMedia from './mutations/SignMedia';

const acceptedFileTypes = [
  'image/png',
  'image/jpeg',
  'video/mp4',
  'image/x-icon',
  'image/vnd.microsoft.icon',
];

const uploadMedia = (signedUrl, media) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': media.file.type,
        },
        body: e.currentTarget.result,
      })
        .then(resolve)
        .catch(reject);
    };

    reader.readAsArrayBuffer(media.file);
  });
};

const createFileKey = (file) => {
  return [file.name, file.lastModified, file.size, file.type]
    .map((v) => v.toString())
    .join('.');
};

class Media {
  constructor(file) {
    this.mediaId = null;
    this.key = createFileKey(file);
    this.file = file;
    this.uploading = false;
    this.uploaded = false;

    this.contentType = file.type;

    this.isVideo = /^video\//.test(file.type);
    this.isImage = !this.isVideo;
  }

  getImageDimensions() {
    // We need the image dimensions before we upload it.
    return new Promise((resolve) => {
      const url = URL.createObjectURL(this.file);
      const img = new Image();

      img.onload = () => {
        this.width = img.width;
        this.height = img.height;
        resolve(this);
      };

      img.src = url;
    });
  }

  getVideoDimensions() {
    // We need the image dimensions before we upload it.
    return new Promise((resolve) => {
      const url = URL.createObjectURL(this.file);

      // create the video element
      const video = document.createElement('video');

      // place a listener on it
      video.addEventListener(
        'loadedmetadata',
        () => {
          this.height = video.videoHeight;
          this.width = video.videoWidth;
          resolve(this);
        },
        false
      );

      // start download meta-datas
      video.src = url;
    });
  }

  getDimensions() {
    if (this.isImage) {
      return this.getImageDimensions();
    }
    return this.getVideoDimensions();
  }

  getSrc() {
    return URL.createObjectURL(this.file);
  }
}

class BaseUploader extends React.Component {
  state = {
    medias: {},
    uploading: false,
  };

  /*
   * This does the bulk of the uploading.
   */

  addFiles = (fileList) => {
    const newFiles = Array.from(fileList);
    const {medias} = this.state;

    // create a media obj from the file. This allows use to share state about
    // what's going on with the uploading process.
    let key;
    Promise.all(
      newFiles
        .filter((newFile) => {
          return !(createFileKey(newFile) in medias);
        })
        .filter((newFile) => {
          // Make sure that it's a type that we can handle.
          return acceptedFileTypes.indexOf(newFile.type) > -1;
        })
        .map((newFile) => {
          return new Media(newFile);
        })
        .map((media) => {
          return media.getDimensions().then((media) => {
            // tell anyone listening about it.
            this.props.onChange('added', media);
            return media;
          });
        })
    ).then((newMedias) => {
      this.setState({
        uploading: true,
        medias: Object.assign(
          {},
          medias,
          newMedias.reduce((o, nM) => {
            o[nM.key] = nM;
            return o;
          }, {})
        ),
      });

      return Promise.all(
        newMedias.map((media) => {
          media.uploading = true;
          this.props.onChange('uploading', media);
          return this.upload(media);
        })
      ).then(() => {
        this.updateUploading();
      });
    });
  };

  updateUploading = () => {
    const {medias, uploading} = this.state;

    const isUploading = Object.keys(medias)
      .map((key) => medias[key])
      .some((media) => media.uploading);

    if (uploading != isUploading) {
      this.setState({
        uploading: isUploading,
      });
    }
  };

  upload = (media) => {
    const {signMedia, create, onNewMedia, prefix} = this.props;

    media.uploading = true;

    return signMedia({contentType: media.file.type, prefix})
      .then((resp) => {
        const signMedia = resp.data.signMedia;

        return uploadMedia(signMedia.signedUrl, media).then((r) => signMedia);
      })
      .then((signMedia) => {
        // File was uploaded, create the media asset
        const mediaInput = {
          id: signMedia.id,
          contentType: media.file.type,
          // name: media.file.name,
          width: media.width,
          height: media.height,
          size: media.file.size,
          path: signMedia.path,
        };

        return create(mediaInput);
      })
      .then((object) => {
        media.uploading = false;
        media.uploaded = true;

        // the new object we created.
        media.object = object;
        return media;
      })
      .then((media) => {
        this.props.onChange('uploaded', media);
      });
  };

  render() {
    return (
      <UploadForm addFiles={this.addFiles} uploading={this.uploading}>
        <Element rules={() => ({textAlign: 'center'})}>
          <Element rules={() => ({fontWeight: 500})}>
            Drag files here to upload
          </Element>
          <span> or </span>
          <Element
            tag='label'
            htmlFor='file'
            rules={() => ({
              color: BLUE,
              cursor: 'pointer',
              fontWeight: 500,
            })}>
            Choose a file
          </Element>
        </Element>
      </UploadForm>
    );
  }
}

const Uploader = graphql(SignMedia, {
  props: ({mutate}) => ({
    signMedia: (input) =>
      mutate({
        variables: {
          input,
        },
      }),
  }),
})(BaseUploader);

const BaseImageUploader = ({createImage, onChange, rules}) => {
  const create = (input) => {
    return createImage(input).then(
      ({
        data: {
          createImage: {image},
        },
      }) => {
        return image;
      }
    );
  };

  return <Uploader create={create} onChange={onChange} rules={rules} />;
};

const ImageUploader = graphql(CreateImage, {
  props: ({mutate}) => ({
    createImage: (input) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
  }),
})(BaseImageUploader);

const BaseAssetUploader = ({organization, createAsset, onChange}) => {
  const create = (input) => {
    input.organizationId = organization.id;

    return createAsset(input).then(
      ({
        data: {
          createAsset: {asset},
        },
      }) => {
        return asset;
      }
    );
  };

  return (
    <Uploader create={create} onChange={onChange} prefix={organization.id} />
  );
};

const AssetUploader = graphql(CreateAsset, {
  props: ({mutate, ownProps: {onNew}}) => ({
    createAsset: (input) => {
      return mutate({
        variables: {
          input,
        },
        update: onNew,
      });
    },
  }),
})(BaseAssetUploader);

export {ImageUploader, AssetUploader};
export default Uploader;
