import {Card} from '../Content';
import Stat, {StatProps} from './Stat';

export interface CardStatProps extends StatProps {
  elevated?: boolean;
}

const CardStat = ({elevated = false, ...statProps}: CardStatProps) => {
  return (
    <Card
      elevated={elevated}
      css={`
        margin: 0;
      `}>
      <Stat {...statProps} />
    </Card>
  );
};

export default CardStat;
