import {EventEmitter, EventTypes} from '@spotify-internal/emitter';
import {
  PromiseResolver,
  createPromiseResolver,
} from '@js-sdk/common/lib/promise_resolver';

import {
  ConnectionDescriptor,
  ErrorEventData,
  HeaderMap,
  WebSocketConstructor,
} from '../../typedefs';
import {DealerErrors} from './typedefs';

import {InternalSocketCode} from '../../enums/internal_socket_code';

import {DealerError} from './error';

import {DealerEvent} from './event';

// We want to make sure that when we run tests that we use the JS setTimeout and not the node setTimeout
declare const setTimeout: WindowOrWorkerGlobalScope['setTimeout'];

export interface DealerOptions {
  WebSocket?: WebSocketConstructor;
  heartbeatTimeout?: number;
}

export interface DealerPong {
  type: 'pong';
}

/**
 * A message from the Dealer service.
 */
export interface DealerMessage {
  type: 'message';
  uri: string;
  headers?: HeaderMap;
  payloads: unknown[];
}

export interface DealerRequest {
  type: 'request';
  key: unknown;
  message_ident: string;
  headers?: HeaderMap;
  payload: unknown;
}

export interface DealerMessageEventData {
  message: DealerMessage;
}

export interface DealerRequestEventData {
  request: DealerRequest;
  reply(payload: unknown): void;
}

export interface DealerDisconnectedEventData {
  wsCode?: number;
  reason?: string;
}

export type DealerData = DealerPong | DealerMessage | DealerRequest;

const NOOP = function (): void {};

/**
 * Used to match the connection id from the Pusher URL returned by dealer.
 */
const CONNECTION_ID_EXP = /hm:\/\/pusher\/(?:[^]+)?\/connections\/([^]+)/;

/**
 * The amount of time in between heartbeats.
 */
const HEARTBEAT_INTERVAL = 30000;

/**
 * The amount of time to wait before triggering a heartbeat timeout.
 */
const HEARTBEAT_TIMEOUT = 10000;

const hasWindow = typeof window !== 'undefined';

interface DealerEventMap {
  [DealerEvent.CONNECTION_ID]: ConnectionDescriptor;
  [DealerEvent.MESSAGE]: DealerMessageEventData;
  [DealerEvent.REQUEST]: DealerRequestEventData;
  [DealerEvent.AUTHENTICATED]: null;
  [DealerEvent.AUTHENTICATION_FAILED]: ErrorEventData<DealerError>;
  [DealerEvent.DISCONNECTED]: DealerDisconnectedEventData;
  [DealerEvent.ERROR]: ErrorEventData<DealerError>;
  [DealerEvent.CONNECTED]: null;
}

export type DealerEventTypes = EventTypes<DealerEventMap>;

/**
 * Interfaces with the Dealer messaging service.
 *
 * @param options - The options for this dealer instance.
 * @see https://ghe.spotify.net/messaging/dealer
 */
export class Dealer extends EventEmitter<DealerEventMap> {
  /**
   * The websocket implementation class that will be used when instantiating the
   * dealer socket connection (@see `this._socket`)
   *
   * Defaults to the `WebSocket` class on the global namespace.
   */
  private _WebSocket: WebSocketConstructor;

  /**
   * A reference to the internal WebSocket connection to Dealer.
   */
  private _socket: WebSocket | null = null;

  /**
   * A deferred that is created in response to a request to ping the service.
   */
  private _lastPingDeferred: PromiseResolver<boolean> | null = null;

  /**
   * A boolean flag that indicates whether we're waiting for a Connection Id.
   *
   * When set to true, the next "connection id" message will result in a similar event.
   */
  private _waitingForConnectionId: boolean = true;

  /**
   * The string connection id for the Dealer connection.
   */
  private _connectionId: string | null = null;

  /**
   * The string connection id uri for the Dealer connection.
   */
  private _connectionURI: string | null = null;

  /**
   * The amount of time before timing out a heartbeat.
   */
  private _heartbeatTimeout: number;
  /**
   * The setTimeout token used for the heartbeat.
   */
  private _heartbeatToken: number = 0;

  /**
   * The setTimeout token used for timing-out the heartbeat.
   */
  private _heartbeatTimeoutToken: number = 0;

  private _connected: boolean = false;

  private _endpoint: string | null = null;

  constructor(options: DealerOptions) {
    super();
    this._WebSocket = options.WebSocket || WebSocket;
    this._heartbeatTimeout = options.heartbeatTimeout || HEARTBEAT_TIMEOUT;
  }

  /**
   * Creates a new Dealer instance.
   *
   * @param options - The options for this dealer instance.
   * @returns The new Dealer instance.
   */
  static create(options: DealerOptions): Dealer {
    return new Dealer(options);
  }

  private _startHeartbeat(initial?: boolean): void {
    const heartbeat = (): void => {
      this.ping().then(
        () => this._onHeartbeatSuccess(),
        () => this._onHeartbeatError()
      );
      this._heartbeatTimeoutToken = setTimeout(
        () => this._onHeartbeatError(),
        this._heartbeatTimeout
      );
    };
    if (initial) {
      heartbeat();
    } else {
      this._heartbeatToken = setTimeout(() => heartbeat(), HEARTBEAT_INTERVAL);
    }
  }

  private _onHeartbeatError(): void {
    this._stopHeartbeat();
    if (!this._socket) {
      return;
    }
    this._socket.close(InternalSocketCode.TIMEOUT, 'internal-timeout');
  }

  private _onHeartbeatSuccess(): void {
    this._stopHeartbeat();
    this._startHeartbeat();
  }

  private _stopHeartbeat(): void {
    if (this._heartbeatToken !== null) {
      clearTimeout(this._heartbeatToken);
    }
    if (this._heartbeatTimeoutToken !== null) {
      clearTimeout(this._heartbeatTimeoutToken);
    }
  }

  /**
   * Parses a "connection id message" from Dealer.
   *
   * @param message - The message to parse.
   * @returns True if the message was successfully parsed, false otherwise.
   */
  private _prepareConnectionId(message: DealerMessage): boolean {
    if (!message.uri) {
      return false;
    }
    const [, connectionId] = message.uri.match(CONNECTION_ID_EXP) ?? [];
    if (!connectionId) {
      return false;
    }
    let id;
    if (message.headers && message.headers['Spotify-Connection-Id']) {
      // Header in ID is not URI encoded.
      id = message.headers['Spotify-Connection-Id'];
    } else {
      id = decodeURIComponent(connectionId);
    }
    this._connectionId = id;
    this._connectionURI = message.uri;
    this.emit(DealerEvent.CONNECTION_ID, {id: id, uri: message.uri});
    return true;
  }

  private _reply(key: unknown, payload: unknown): void {
    if (!key) {
      throw new TypeError('Invalid key.');
    }
    const socket = this._socket;
    if (!socket || socket.readyState !== 1) {
      return;
    }
    const msg = {
      type: 'reply',
      key: key,
      payload: payload,
    };
    socket.send(JSON.stringify(msg));
  }

  /**
   * Parses the actual message body from the internal WebSocket.
   *
   * @param data - A JSON-encoded string that contains the actual message data.
   */
  private _parseMessage(data: string): void {
    let msg: DealerData;
    try {
      msg = JSON.parse(data);
    } catch {
      return;
    }
    if (msg.type === 'message') {
      if (this._waitingForConnectionId && this._prepareConnectionId(msg)) {
        this._waitingForConnectionId = false;
        this._startHeartbeat(true);
      } else {
        this.emit(DealerEvent.MESSAGE, {message: msg});
      }
    } else if (msg.type === 'pong' && this._lastPingDeferred) {
      this._lastPingDeferred.resolve(true);
      this._lastPingDeferred = null;
    } else if (msg.type === 'request') {
      const key = msg.key;
      if (key) {
        this.emit(DealerEvent.REQUEST, {
          request: msg,
          reply: this._reply.bind(this, key),
        });
      }
    }
  }

  /**
   * Handles the "open" event from the internal WebSocket.
   *
   * @param deferred - The deferred object that was created as part of the part
   *   of the authentication process.
   */
  private _handleOpen(deferred: PromiseResolver): void {
    deferred.resolve(true);
    this._connected = true;
    this.emit(DealerEvent.AUTHENTICATED, null);
  }

  /**
   * Handles a "message" from the internal WebSocket.
   *
   * @param ev - The "message" event.
   */
  private _handleMessage(ev: MessageEvent): void {
    const _this = this;
    const data = ev.data;
    if (hasWindow && window.Blob && data instanceof window.Blob) {
      const fileReader = new FileReader();
      fileReader.onloadend = function () {
        if (!this.result) {
          return;
        }
        let result: string;
        if (this.result instanceof ArrayBuffer) {
          result = '';
          const temp = new Uint8Array(this.result);
          for (let i = 0; i < temp.length; i++) {
            result += String.fromCharCode(temp[i]!);
          }
        } else {
          result = this.result;
        }
        _this._parseMessage(result);
      };
      fileReader.readAsText(data);
    } else if (
      // @ts-ignore: We don't have @types/node anymore to prevent non es5 methods being used and
      // therefore don't have types defined for Buffer.
      typeof Buffer !== 'undefined' &&
      typeof ArrayBuffer !== 'undefined' &&
      data instanceof ArrayBuffer
    ) {
      // @ts-ignore
      this._parseMessage(new Buffer(data).toString('ascii'));
    } else {
      this._parseMessage(data);
    }
  }
  /**
   * Handles a "close" event from the internal WebSocket.
   *
   * @param ev - The "close" event.
   */
  private _handleClose(ev: CloseEvent): void {
    const wasConnected = this._connected;
    this._connected = false;
    if (!wasConnected) {
      // We had an error with authentication
      const error = new DealerError(
        DealerErrors.DEALER_AUTHENTICATION_FAILED,
        'Dealer connection error',
        ev
      );
      this.emit(DealerEvent.AUTHENTICATION_FAILED, {error: error});
      return;
    }
    this.emitSync(DealerEvent.DISCONNECTED, {
      wsCode: ev.code,
      reason: ev.reason,
    });
  }

  /**
   * Handles the "error" event from the internal WebSocket.
   *
   * @param deferred - The deferred object that was created as part of the
   *   authentication process.
   */
  private _handleError(deferred: PromiseResolver): void {
    const error = new DealerError(
      DealerErrors.DEALER_CONNECTION_ERROR,
      'Cannot connect to dealer'
    );
    deferred.reject(error);
    this._connected = false;
    this.emit(DealerEvent.ERROR, {error: error});
  }

  /**
   * Connects the instance to the Dealer service.
   *
   * @param endpoint - The endpoint to connect to.
   * @returns A promise that will be resolved to true if the connection was successful.
   */
  connect(endpoint: string): Promise<boolean> {
    // Dealer expects the token to be either part of the UPGRADE request header or
    // as a query-string parameter. Since we cannot set headers in the browser, we
    // use the query-string option. Since the token is unknown at this point, we
    // simply save the endpoint and wait until `authenticate()` is called.
    this._endpoint = endpoint;
    this._waitingForConnectionId = true;
    this.emit(DealerEvent.CONNECTED, null);
    return Promise.resolve(true);
  }

  /**
   * Authenticates the instance to the Dealer service.
   *
   * @param token - The OAuth token that identifies the current user.
   * @returns A promise that will be resolved when the instance has been
   *   properly authenticated.
   */
  authenticate(token: string): Promise<boolean> {
    const deferred = createPromiseResolver<boolean>();
    const endpoint = `${this._endpoint}?access_token=${token}`;
    const socket = (this._socket = new this._WebSocket(endpoint));
    socket.onopen = this._handleOpen.bind(this, deferred);
    socket.onclose = this._handleClose.bind(this);
    socket.onerror = this._handleError.bind(this, deferred);
    socket.onmessage = this._handleMessage.bind(this);
    return deferred.promise;
  }

  /**
   * Disconnects the instance from the Dealer service.
   */
  disconnect(): void {
    if (!this._socket) {
      return;
    }
    this._stopHeartbeat();
    this._waitingForConnectionId = true;
    this._connected = false;
    this._socket.close(InternalSocketCode.CLOSE, 'internal-close');
    this._socket.onopen = NOOP;
    this._socket.onerror = NOOP;
    this._socket.onmessage = NOOP;
    this._socket.onclose = NOOP;
    this._socket = null;
    this.emitSync(DealerEvent.DISCONNECTED, {
      wsCode: InternalSocketCode.CLOSE,
      reason: 'internal-close',
    });
  }

  /**
   * Sends a ping message to the Dealer instance.
   *
   * @returns A promise that will be resolved when the Dealer service returns a
   *   "pong" response.
   */
  ping(): Promise<boolean> {
    if (!this._socket || this._socket.readyState !== 1) {
      return Promise.reject(
        new DealerError(
          DealerErrors.DEALER_CONNECTION_ERROR,
          'Dealer connection error'
        )
      );
    }
    this._lastPingDeferred = createPromiseResolver();
    this._socket.send('{"type":"ping"}');
    return this._lastPingDeferred.promise;
  }

  /**
   * Returns the Connection ID of the instance.
   *
   * @returns A promise that will be resolved with the instance's connection id.
   */
  getConnectionId(): Promise<string> {
    if (this._waitingForConnectionId) {
      return new Promise((resolve) => {
        this.once(DealerEvent.CONNECTION_ID, (e) => {
          resolve(e.data.id);
        });
      });
    }
    return Promise.resolve(this._connectionId as string);
  }

  /**
   * Returns an object with the connection id and the connection uri.
   *
   * @returns A promise that will be resolved with the instance's connection id
   *   and connection uri.
   */
  getConnectionInfo(): Promise<ConnectionDescriptor> {
    if (this._waitingForConnectionId) {
      return new Promise((resolve) => {
        this.once(DealerEvent.CONNECTION_ID, (e) => {
          resolve({
            id: e.data.id,
            uri: e.data.uri,
          });
        });
      });
    }
    return Promise.resolve({
      id: this._connectionId as string,
      uri: this._connectionURI as string,
    });
  }

  /**
   * Returns whether there's a connectionId.
   *
   * @returns True if the dealer instance has a connection id.
   */
  hasConnectionId(): boolean {
    return !this._waitingForConnectionId && !!this._connectionId;
  }
}
