import {IconButton} from '../../Button/LegacyButton';
import {DropdownMenu, DropdownMenuProps} from '../../DropdownMenu';
import {DropdownMenuItemType} from '../../DropdownMenu/types';
import {Icon} from '../../Icon';

interface DataTableDotsDropdownMenuCellProps extends DropdownMenuProps {
  items: DropdownMenuItemType[];
  onSubmit?: (_item: DropdownMenuItemType) => void;
  toggleActiveRow?: (_value: any) => any;
}

const DataTableDotsDropdownMenuCell = ({
  items,
  onSubmit,
  toggleActiveRow,
  ...props
}: DataTableDotsDropdownMenuCellProps): JSX.Element => (
  <DropdownMenu
    items={items}
    onSubmit={onSubmit}
    onOpenedChanged={toggleActiveRow}
    overlayProps={{
      horizontalAlign: 'left',
    }}
    css={`
      vertical-align: bottom;
    `}
    {...props}>
    <IconButton
      rules={() => ({
        padding: '0.3125rem',
        transform: 'rotate(90deg)',
      })}>
      <Icon
        icon='more'
        rules={() => ({height: '1.375rem', width: '1.375rem'})}
      />
    </IconButton>
  </DropdownMenu>
);

export default DataTableDotsDropdownMenuCell;
