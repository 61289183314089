import {gql} from '@apollo/client';

export default gql`
  query searchFeeds($query: String!, $state: String) {
    admin {
      feedSearch(query: $query, state: $state) {
        id
        title
        subtitle
        href
      }
    }
  }
`;
