import PodcastQuery from '@admin/graphql-api/_old_queries/PodcastQuery';
import {graphql} from '@apollo/client/react/hoc';
import {Switch} from 'react-router-dom';
import {PropsRoute} from '@core/app/Routes';
import Loading from '@core/ui/Loading';
import AccessAdd from './AccessAdd';
import Podcast from './Podcast';

const PodcastIndex = ({organization, data: {loading, admin}}) => {
  if (loading) {
    return <Loading absolute />;
  }

  const {podcast} = admin.organization;

  return (
    <Switch>
      <PropsRoute
        exact
        path='/organizations/:organizationId/podcasts/:podcastId'
        organization={organization}
        podcast={podcast}
        component={Podcast}
      />
      <PropsRoute
        exact
        path='/organizations/:organizationId/podcasts/:podcastId/access'
        organization={organization}
        podcast={podcast}
        component={AccessAdd}
      />
    </Switch>
  );
};

export default graphql(PodcastQuery, {
  options: ({
    organization: {id},
    match: {
      params: {podcastId},
    },
  }) => ({
    variables: {
      organizationId: id,
      id: podcastId,
    },
  }),
})(PodcastIndex);
