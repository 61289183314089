import {gql} from '@apollo/client';

export default gql`
  query getPodcast($organizationId: ID!, $id: ID!) {
    admin {
      organization(id: $organizationId) {
        id
        podcast(id: $id) {
          id
          feed {
            id
            title
          }

          accessSet {
            id
            name
            permission
            organization {
              id
              name
              kind
            }
          }
        }
      }
    }
  }
`;
