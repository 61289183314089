import React from 'react';
import AddTagMutation from '@admin/graphql-api/_old_mutations/AddTag';
import RemoveTagMutation from '@admin/graphql-api/_old_mutations/RemoveTag';
import TagsQuery from '@admin/graphql-api/_old_queries/TagsQuery';
import CompanyQuery from '@admin/graphql-api/queries/AdminCompanyQuery';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import {Element} from '@core/style';
import {FieldGroup, Input, Label} from '@core/ui/Form';
import {Submit} from '@core/ui/FormElements';
import Loading from '@core/ui/Loading';
import Tag from './CompanyTag';

class CompanyTags extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      label: '',
      loading: false,
    };
  }

  onChange = ({target: {name, value}}) => {
    const update = {error: false};
    update[name] = value;
    this.setState(update);
  };

  addTag = (tag) => {
    const {addTag, company} = this.props;

    this.setState({
      loading: true,
    });

    addTag({
      id: tag.id,
      companyId: company.id,
    }).then(() => {
      this.setState({
        label: '',
        loading: false,
      });
    });
  };

  removeTag = (tag) => {
    const {removeTag, company} = this.props;

    this.setState({
      loading: true,
    });

    removeTag({
      id: tag.id,
      companyId: company.id,
    }).then(() => {
      this.setState({
        loading: false,
      });
    });
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    const {addTag, company} = this.props;

    const {label} = this.state;

    if (!label) {
      return;
    }

    this.setState({
      loading: true,
    });

    addTag({
      label,
      companyId: company.id,
    }).then(() => {
      this.setState({
        label: '',
        loading: false,
      });
    });
  };

  render() {
    const {company, data} = this.props;
    const {loading, label} = this.state;

    if (data.loading || loading) {
      return <Loading absolute />;
    }

    const {tags} = data.admin;

    const usedTagIds = company.tags.map((t) => t.id);

    const unusedTags = tags.filter((t) => usedTagIds.indexOf(t.id) === -1);

    return (
      <Element rules={() => ({maxWidth: '600px', margin: '25px auto'})}>
        <Element rules={() => ({display: 'flex'})}>
          <Element rules={() => ({flex: 1})}>
            <h3>Remove Tags</h3>
            {company.tags.map((tag) => {
              return (
                <Tag
                  key={tag.id}
                  onDelete={() => {
                    this.removeTag(tag);
                  }}
                  tag={tag}
                />
              );
            })}
          </Element>

          <Element rules={() => ({flex: 1})}>
            <h3>Add Tags</h3>
            {unusedTags.map((tag) => {
              return (
                <Tag
                  key={tag.id}
                  onAdd={() => {
                    this.addTag(tag);
                  }}
                  tag={tag}
                />
              );

              r;
            })}
          </Element>
        </Element>

        <h5>New Tag</h5>
        <form onSubmit={this.onSubmit}>
          <FieldGroup>
            <Label>Tag</Label>
            <Input
              type='text'
              name='label'
              value={label}
              onChange={this.onChange}
              disabled={loading}
            />
          </FieldGroup>

          <Element rules={() => ({padding: '20px 0', textAlign: 'right'})}>
            <Submit value='Add New Tag' disabled={loading} />
          </Element>
        </form>
      </Element>
    );
  }
}

export default compose(
  graphql(TagsQuery),
  graphql(AddTagMutation, {
    props: ({mutate}) => ({
      addTag: (input) => {
        return mutate({
          variables: {
            input,
          },
          update: (
            store,
            {
              data: {
                addTag: {companyId, tag},
              },
            }
          ) => {
            // Stuff the new journey into the cache.
            const query = {
              query: CompanyQuery,
              variables: {
                id: companyId,
              },
            };

            query.data = store.readQuery(query);

            const tags = query.data.company.tags.slice(0);
            tags.push(tag);
            query.data.company.tags = tags;

            store.writeQuery(query);
          },
        });
      },
    }),
  }),
  graphql(RemoveTagMutation, {
    props: ({mutate}) => ({
      removeTag: (input) => {
        return mutate({
          variables: {
            input,
          },
          update: (
            store,
            {
              data: {
                removeTag: {companyId, tag},
              },
            }
          ) => {
            // Stuff the new journey into the cache.
            const query = {
              query: CompanyQuery,
              variables: {
                id: companyId,
              },
            };

            query.data = store.readQuery(query);

            query.data.admin.company.tags =
              query.data.admin.company.tags.filter((t) => t.id != tag.id);

            store.writeQuery(query);
          },
        });
      },
    }),
  })
)(CompanyTags);
