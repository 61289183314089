import {gql} from '@apollo/client';

export default gql`
  query getCompanyClaim($id: ID!) {
    admin {
      companyClaim(id: $id) {
        id
        state
        company {
          id
          name
        }
        user {
          id
          firstName
          lastName
          email
        }
        organization {
          id
          name
          members {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;
