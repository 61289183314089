import {useEffect, useState} from 'react';

type UseSessionStorageFn = <S>(_props: {
  id: string;
  initialState?: S;
}) => [S, (_value: S | ((_prevState: S) => S)) => void];

const useSessionStorage: UseSessionStorageFn = ({id, initialState}) => {
  const [value, setValue] = useState(() => {
    if (id) {
      const item = window.sessionStorage.getItem(id);
      return item && item !== 'undefined' ? JSON.parse(item) : initialState;
    }
    return null;
  });

  useEffect(() => {
    if (id) {
      window.sessionStorage.setItem(id, JSON.stringify(value));
    }
  }, [id, value]);

  return [value, setValue];
};

export default useSessionStorage;
