import React from 'react';
import ContactedContactMutation from '@admin/graphql-api/_old_mutations/ContactedContact';
import IgnoredContactMutation from '@admin/graphql-api/_old_mutations/IgnoredContact';
import ResetContactMutation from '@admin/graphql-api/_old_mutations/ResetContact';
import ContactQuery from '@admin/graphql-api/_old_queries/ContactQuery';
import {graphql} from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import {Redirect} from 'react-router-dom';
import {Element} from '@core/style';
import {Button} from '@core/ui/Button';
import {Card, PageTitle} from '@core/ui/Content';
import {GraphErrors} from '@core/ui/Form';
import Loading from '@core/ui/Loading';

class Contact extends React.Component {
  state = {
    loading: false,
    complete: false,
    errors: [],
  };

  ignore = () => {
    const {ignoredContact} = this.props;

    this.setState({
      loading: true,
    });

    ignoredContact()
      .then(() => {
        this.setState({
          loading: false,
          complete: true,
        });
      })
      .catch((resp) => {
        this.setState({
          loading: false,
          errors: resp.graphQLErrors,
        });
      });
  };

  contacted = () => {
    const {contactedContact} = this.props;

    this.setState({
      loading: true,
    });

    contactedContact()
      .then(() => {
        this.setState({
          loading: false,
          complete: true,
        });
      })
      .catch((resp) => {
        this.setState({
          loading: false,
          errors: resp.graphQLErrors,
        });
      });
  };

  reset = () => {
    const {resetContact} = this.props;

    this.setState({
      loading: true,
    });

    resetContact()
      .then(() => {
        this.setState({
          loading: false,
          complete: true,
        });
      })
      .catch((resp) => {
        this.setState({
          loading: false,
          errors: resp.graphQLErrors,
        });
      });
  };

  render() {
    const {data} = this.props;

    const {loading, errors, complete} = this.state;

    if (data.loading || loading) {
      return <Loading absolute />;
    }

    if (complete) {
      return <Redirect to='/contacts' />;
    }

    const {firstName, lastName, email, message} = data.admin.contact;

    return (
      <>
        <GraphErrors
          errors={errors}
          onClose={() => {
            this.setState({
              errors: [],
            });
          }}
        />
        <PageTitle>
          {firstName} {lastName} ({email})
        </PageTitle>
        <Card>
          {message}
          <Element rules={() => ({textAlign: 'right'})}>
            <Button color='error' onClick={this.ignore} disabled={loading}>
              Ignore
            </Button>

            <Button
              onClick={this.reset}
              disabled={loading}
              css={`
                margin: 0 1rem;
              `}>
              Reset (for f-ups)
            </Button>

            <Button color='primary' disabled={loading} onClick={this.contacted}>
              Contacted
            </Button>
          </Element>
        </Card>
      </>
    );
  }
}

export default compose(
  graphql(ContactQuery, {
    options: ({
      match: {
        params: {contactId},
      },
    }) => ({
      fetchPolicy: 'network-only',
      variables: {
        id: contactId,
      },
    }),
  }),
  graphql(IgnoredContactMutation, {
    props: ({
      ownProps: {
        match: {
          params: {contactId},
        },
      },
      mutate,
    }) => ({
      ignoredContact: () => {
        return mutate({
          variables: {
            input: {contactId},
          },
        });
      },
    }),
  }),
  graphql(ContactedContactMutation, {
    props: ({
      ownProps: {
        match: {
          params: {contactId},
        },
      },
      mutate,
    }) => ({
      contactedContact: () => {
        return mutate({
          variables: {
            input: {contactId},
          },
        });
      },
    }),
  }),
  graphql(ResetContactMutation, {
    props: ({
      ownProps: {
        match: {
          params: {contactId},
        },
      },
      mutate,
    }) => ({
      resetContact: () => {
        return mutate({
          variables: {
            input: {contactId},
          },
        });
      },
    }),
  })
)(Contact);
