function normalizeSize(size) {
  switch (size) {
    case 'small':
      return 'smaller';
    case 'large':
      return 'larger';
    default:
      return 'base';
  }
}
function normalizeSpacing(spacerStep) {
  if (spacerStep === 0) return 'base';
  var prefix = spacerStep < 0 ? 'minus' : 'plus';
  var step = Math.abs(spacerStep);
  return "".concat(prefix, "-").concat(step);
}
/**
 * Get a CSS value that can be used when authoring CSS spacer rules
 * @deprecated in favor of cssSpacing
 * @param spacerStep- a spacer step number value (eg -5, 0, 6)
 * @param [fallbackSpacer] - a valid CSS <spacer> token as fallback
 * @returns a string in the form of var(--encore-spacing-variable, <fallback>)
 */
export function cssSpacerValue(spacerStep, fallbackSpacer) {
  var newSpacer = normalizeSpacing(spacerStep);
  return cssSpacing(newSpacer, fallbackSpacer);
}

/**
 * Get a CSS value that can be used when authoring CSS spacer rules
 * @param spacer - a spacer value name (e.g. tighter-5, base, looser-6)
 * @param [fallbackSpacer] - a valid CSS <spacer> token as fallback
 * @returns a string in the form of var(--encore-spacing-variable, <fallback>)
 */
export function cssSpacing(spacer, fallbackSpacer) {
  var optionalFallback = fallbackSpacer ? ", ".concat(fallbackSpacer) : '';
  return "var(--encore-spacing-".concat(spacer).concat(optionalFallback, ")");
}

/**
 * Get a CSS value that can be used when authoring CSS border radius rules
 * @param size (e.g. 'smaller', 'base', 'larger')
 * @param [fallback] - a valid CSS pixel value as fallback
 * @returns a string in the form of `var(--corner-radius-variable, <fallback>)`
 */
export function cssBorderRadius(size, fallback) {
  var optionalFallback = fallback ? ", ".concat(fallback) : '';
  return "var(--encore-corner-radius-".concat(size).concat(optionalFallback, ")");
}

/**
 * Get a CSS value that can be used when authoring CSS border width rules
 * @param size - a border width size string(hairline, thin, thick, thicker, focus)
 * @param [fallback] - a valid CSS pixel value as fallback
 * @returns a string in the form of `var(--border-width-variable, <fallback>)`
 */
export function cssBorderWidth(size, fallback) {
  var optionalFallback = fallback ? ", ".concat(fallback) : '';
  return "var(--encore-border-width-".concat(size).concat(optionalFallback, ")");
}

/**
 * Get a CSS value that can be used when authoring CSS control size rules
 * @param size - a control size string(small, medium, large)
 * @param [fallback] - a valid CSS pixel value as fallback
 * @returns a string in the form of `var(--control-size-variable, <fallback>)`
 */
export function cssControlSize(size, fallback) {
  var scalableLayoutSize = normalizeSize(size);
  var optionalFallback = fallback ? ", ".concat(fallback) : '';
  return "var(--encore-control-size-".concat(scalableLayoutSize).concat(optionalFallback, ")");
}

/**
 * Get a CSS value that can be used when authoring CSS layout margins
 * @param size - a layout margin size string(tigher, base, looser)
 * @param [fallback] - a valid CSS pixel value as fallback
 * @param [isNegative] - a boolean for if this is a negative margin
 * @returns a string in the form of `var(--layout-margin-variable, <fallback>)`
 */
export function cssLayoutMargin(size, fallback, isNegative) {
  var optionalFallback = fallback ? ", ".concat(fallback) : '';
  if (isNegative) {
    return "calc(-1 * var(--encore-layout-margin-".concat(size).concat(optionalFallback, "))");
  }
  return "var(--encore-layout-margin-".concat(size).concat(optionalFallback, ")");
}
/**
 * Get a CSS value that can be used when authoring CSS graphic rules
 * @param size - a size string(xsmall, small, medium, large, xxlarge)
 * @param role - a role string which relates to accessibility (decorative, informative)
 * @param [fallback] - a valid CSS pixel value as fallback
 * @returns a string in the form of `var(--encore-graphic-size-[role]-[size] <fallback>)`
 */
export function cssGraphicSize(size, role, fallback) {
  var optionalFallback = fallback ? ", ".concat(fallback) : '';
  return "var(--encore-graphic-size-".concat(role, "-").concat(size).concat(optionalFallback, ")");
}
function camelToKebab(inputString) {
  return inputString.replace(/[A-Z0-9]/g, function (m) {
    return "-".concat(m.toLowerCase());
  });
}
export function makeTextClass(variant) {
  return variant ? "encore-text-".concat(camelToKebab(variant)) : '';
}

/**
 * Appends text classname to the passed className string
 * @param variant - A type variant (eg. 'headlineMedium', 'bodyMedium', 'marginal')
 * @param className - Other classnames passed from the elements
 * @returns string of passed classnames plus the text variant class
 */
export function addTextClass(variant, className) {
  return [makeTextClass(variant), className].join(' ').trim();
}