import {Element} from '@core/style';

const CompanyTag = ({tag, onDelete, onAdd}) => {
  return (
    <Element
      tag='span'
      rules={() => ({
        display: 'inline-block',
        padding: '5px 13px',
        background: '#666',
        color: '#fff',
        borderRadius: '20px',
        margin: '0 10px 5px 0',
        fontSize: '14px',
      })}>
      {tag.label}
      {(onDelete || onAdd) && (
        <Element
          rules={() => ({
            display: 'inline-block',
            marginLeft: '13px',
            cursor: 'pointer',
          })}
          onClick={onDelete || onAdd}>
          {onDelete ? <span>&times;</span> : '+'}
        </Element>
      )}
    </Element>
  );
};

export default CompanyTag;
