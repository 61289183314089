import {FC, useState} from 'react';
import {
  AdminCompanyQuery,
  UpdateCompanyInput,
  useUpdateCompanyMutation,
} from '@admin/graphql-api';
import ADMIN_COMPANY_QUERY from '@admin/graphql-api/queries/AdminCompanyQuery';
import {Controller, useForm} from 'react-hook-form';
import {Redirect} from 'react-router-dom';
import IndustrySelect from '@core/components/IndustrySelect';
import {Card, CardHeader} from '@core/ui/Content';
import {
  FORM_ERRORS,
  Form,
  FormFooter,
  FormGroup,
  Label,
  LabelInput,
  Submit,
  TextArea,
} from '@core/ui/FormElements';
import ResponsiveContent from '@core/ui/ResponsiveContent';
import {toCompanyUrl} from './_routes';

interface IFormInput extends Omit<UpdateCompanyInput, 'id'> {}
interface ICompanyUpdateProps {
  company: AdminCompanyQuery['admin']['company'];
}

const CompanyUpdate: FC<ICompanyUpdateProps> = ({company}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [updateCompany] = useUpdateCompanyMutation({
    refetchQueries: [ADMIN_COMPANY_QUERY],
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: {errors, isDirty},
  } = useForm({
    defaultValues: {
      name: company.name,
      slug: company.slug,
      url: company.slug || '',
      description: company.description || '',
      industry: company.industry || '',
      organizationId: company.organization ? company.organization.id : '',
    },
  });

  const onSubmit = async ({
    name,
    slug,
    description,
    url,
    organizationId,
    industry,
  }: IFormInput) => {
    if (!isDirty) {
      setComplete(true);
      return;
    }

    setLoading(true);
    try {
      const resp = await updateCompany({
        variables: {
          input: {
            id: company.id,
            name,
            slug,
            description,
            url,
            organizationId,
            industry,
          },
        },
      });

      if (resp) {
        setComplete(true);
      }
    } catch (e) {
      setError(null, FORM_ERRORS.network);
    }
    setLoading(false);
  };

  if (complete) return <Redirect to={toCompanyUrl(company)} />;

  return (
    <ResponsiveContent containerSize='medium'>
      <CardHeader backPath={toCompanyUrl(company)}>Update Company</CardHeader>
      <Card>
        <Form errors={errors} onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Controller
              name='name'
              control={control}
              rules={{required: 'Please provide company name.'}}
              render={({field}) => (
                <LabelInput
                  label='Name'
                  invalid={!!errors.name}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name='slug'
              control={control}
              rules={{required: 'Please provide company slug.'}}
              render={({field}) => (
                <LabelInput
                  label='Slug'
                  invalid={!!errors.slug}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name='url'
              control={control}
              render={({field}) => (
                <LabelInput
                  label='URL'
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Controller
              name='organizationId'
              control={control}
              render={({field}) => (
                <LabelInput
                  label='Organization ID'
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label>Industry</Label>
            <Controller
              name='industry'
              control={control}
              rules={{
                validate: (value) =>
                  !!value ? true : 'Please select an industry.',
              }}
              render={({field}) => (
                <IndustrySelect
                  defaultValue={field.value}
                  invalid={!!errors.industry}
                  onSelect={({industry}) => field.onChange(industry)}
                />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label>Description</Label>
            <Controller
              name='description'
              control={control}
              render={({field}) => (
                <TextArea
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </FormGroup>
          <FormFooter>
            <Submit loading={loading} disabled={loading}>
              Update Company
            </Submit>
          </FormFooter>
        </Form>
      </Card>
    </ResponsiveContent>
  );
};

export default CompanyUpdate;
