import {gql} from '@apollo/client';

export default gql`
  mutation addArt19Secret($input: AddArt19SecretInput!) {
    authed {
      success
    }
    addArt19Secret(input: $input) {
      art19Secret
    }
  }
`;
