import {useCallback, useState} from 'react';

export function useOverlay(defaultValue?: boolean) {
  const [opened, setOpened] = useState(!!defaultValue);

  const toggle = useCallback((open?: boolean) => {
    setOpened((prev) => (typeof open === 'boolean' ? open : !prev));
  }, []);

  return [opened, toggle] as const;
}
