import {Dispatch, SetStateAction, useEffect} from 'react';
import {OverlayProps} from './Overlay';

export const PARENT_CONTAINER = document.body;

export const getCurrentOverlays = (): HTMLDivElement[] =>
  Array.from(PARENT_CONTAINER.querySelectorAll('div[data-overlay]'));

export const useBodyScrollLock = ({
  hideScroll,
}: {
  hideScroll: boolean;
}): void => {
  useEffect(() => {
    if (hideScroll) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      if (hideScroll && !getCurrentOverlays().length) {
        document.body.style.overflow = '';
      }
    };
  }, [hideScroll]);
};

const isLastOpened = (overlayRef: any): boolean => {
  const overlays = getCurrentOverlays();
  return overlays.length - 1 === overlays.indexOf(overlayRef.current);
};

export const useCloseOnOutsideClick = ({
  active,
  overlayRef,
  beforeCloseOnOutsideClick,
  toggle,
}: {
  beforeCloseOnOutsideClick?: () => void | null;
  toggle?: Dispatch<SetStateAction<boolean>> | ((_to?: boolean) => void | null);
  overlayRef: any;
  active: boolean;
}) => {
  useEffect(() => {
    const handleCloseOnOutsideClick = (evt: any) => {
      if (
        (evt.path ?? evt.composedPath()).indexOf(overlayRef.current) < 0 &&
        isLastOpened(overlayRef)
      ) {
        evt.preventDefault();
        evt.stopPropagation();
        beforeCloseOnOutsideClick?.();
        toggle?.(false);
      }
    };

    if (active) {
      document.addEventListener('mousedown', handleCloseOnOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleCloseOnOutsideClick);
    };
  }, [active, beforeCloseOnOutsideClick, overlayRef, toggle]);
};

export const useCloseOnEscKey = ({
  active,
  overlayRef,
  toggle,
}: Pick<OverlayProps, 'toggle'> & {
  active: boolean;
  overlayRef: any;
}) => {
  useEffect(() => {
    const handleCloseOnEscKey = (evt: KeyboardEvent) => {
      if (evt.key === 'Escape' && isLastOpened(overlayRef)) {
        toggle?.(false);
      }
    };

    if (active) {
      document.addEventListener('keydown', handleCloseOnEscKey);
    }

    return () => {
      document.removeEventListener('keydown', handleCloseOnEscKey);
    };
  }, [active, overlayRef, toggle]);
};
