import React, {useEffect, useRef, useState} from 'react';
import {Campaign} from '@admin/components/Campaign';
import CampaignsTable from '@admin/components/CampaignsTable';
import {withRouter} from 'react-router-dom';
import {Element} from '@core/style';
import {Card} from '@core/ui/Content';
import Select from '@core/ui/Select';

const CampaignsContent = withRouter(
  ({
    campaigns,
    headerContainer,
    history,
    match: {
      params: {campaignId: selectedCampaignId},
    },
  }) => {
    return (
      <Element
        rules={() => ({
          display: 'grid',
          flex: 1,
          gridGap: '1.25rem',
          gridTemplateColumns: selectedCampaignId ? '1fr 1fr' : '1fr',
          height: '100%',
          minHeight: 0,
        })}>
        <Card
          rules={() => ({
            background: 'white',
            margin: 0,
            minHeight: 0,
            flex: 1,
            minWidth: 0,
          })}>
          <CampaignsTable
            campaigns={campaigns}
            headerContainer={headerContainer}
            selectedCampaignId={selectedCampaignId}
          />
        </Card>
        {selectedCampaignId ? (
          <Card
            rules={() => ({
              background: 'white',
              margin: 0,
              minHeight: 0,
              minWidth: 0,
              overflow: 'auto',
              padding: 0,
              position: 'relative',
            })}>
            <Campaign
              key={selectedCampaignId}
              campaignId={selectedCampaignId}
              onClose={() => history.push('/campaigns')}
            />
          </Card>
        ) : null}
      </Element>
    );
  }
);

const Campaigns = ({campaigns}) => {
  const headerContainerRef = useRef();
  const [filteredCampaigns, setFilteredCampaigns] = useState(
    campaigns.slice(0)
  );
  const [billingFilter, setBillingFilter] = useState();

  useEffect(() => {
    let _filteredCampaigns = campaigns.slice(0);
    if (billingFilter) {
      switch (billingFilter) {
        case 'noPayer':
          _filteredCampaigns = _filteredCampaigns.filter(({payer}) => !payer);
          break;
        case 'incompleteBillingInfo':
          _filteredCampaigns = _filteredCampaigns.filter(({organization}) => {
            return (
              organization.attributionCpm === null ||
              (organization.attributionCpm === 0 && !organization.note)
            );
          });
          break;
      }
    }
    setFilteredCampaigns(_filteredCampaigns);
  }, [billingFilter]);

  return (
    <Element
      rules={() => ({
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        left: 0,
        padding: '1.5rem',
        position: 'absolute',
        right: 0,
        top: 0,
      })}>
      <Element
        rules={() => ({
          alignItems: 'start',
          display: 'flex',
          height: '3.125rem',
        })}>
        <div ref={headerContainerRef} />
        <Select
          removeOption
          placeholder='Billing filters'
          onSelect={(item) => setBillingFilter(item ? item.value : null)}
          items={[
            {
              title: 'Incomplete Billing Info',
              value: 'incompleteBillingInfo',
            },
            {title: 'No Payer', value: 'noPayer'},
          ]}
          rules={() => ({
            fontSize: '0.875rem',
            marginRight: '0.625rem',
          })}
          small
          outline
        />
      </Element>
      <Element rules={() => ({flex: 1, minHeight: 0})}>
        <CampaignsContent
          campaigns={filteredCampaigns}
          headerContainer={headerContainerRef.current}
        />
      </Element>
    </Element>
  );
};

export default Campaigns;
