//
// Overlays: Dialogs, Tooltips, Popovers, etc
// --------------------------------------------------

import { screenSmMin } from '@spotify-internal/encore-foundation';
import { cssBorderRadius, zIndexDialog } from "../../styles/index.js";
import { assertNever } from "../../typeHelpers.js";
import { cssColorValue, defaultTheme, semanticColors } from "../semantic-theme.js";
import { triangle } from "./triangle.js";
import { css } from "../../styled-components.js";

/* TODO: can we use an encore token here? */
export var overlayBoxShadow = "0 4px 12px 0 rgba(0, 0, 0, 0.2)";
export var overlayDirections = {
  top: 'top',
  topEnd: 'topEnd',
  topStart: 'topStart',
  start: 'start',
  startTop: 'startTop',
  startBottom: 'startBottom',
  bottom: 'bottom',
  bottomStart: 'bottomStart',
  bottomEnd: 'bottomEnd',
  end: 'end',
  endTop: 'endTop',
  endBottom: 'endBottom'
};
export var overlay = function overlay(_ref) {
  var _ref$maxWidth = _ref.maxWidth,
    maxWidth = _ref$maxWidth === void 0 ? '296px' : _ref$maxWidth,
    _ref$colorSet = _ref.colorSet,
    colorSet = _ref$colorSet === void 0 ? 'base' : _ref$colorSet;
  return css(["background:", ";color:", ";border-radius:", ";box-shadow:", ";text-align:start;cursor:default;display:inline-block;max-inline-size:", ";position:relative;"], cssColorValue(semanticColors.backgroundElevatedBase, defaultTheme[colorSet].background.elevated.base), cssColorValue(semanticColors.textBase, defaultTheme[colorSet].text.base), cssBorderRadius('larger-2', '8px'), overlayBoxShadow, maxWidth);
};
export var dialog = function dialog(_ref2) {
  var colorSet = _ref2.colorSet;
  return css(["", ";display:flex;flex-direction:column;max-block-size:90vh;z-index:", ";@media (min-width:", "){max-block-size:80vh;}"], overlay({
    maxWidth: "calc(100% - 48px)" /* TODO: can we use an encore token here? */,
    colorSet: colorSet
  }), zIndexDialog, screenSmMin);
};
export var overlayArrow = function overlayArrow(direction) {
  switch (direction) {
    /* TODO: can we use an encore token here? */
    case 'top':
      return triangle({
        offset: 'center',
        position: 'top'
      });
    case 'topStart':
      return triangle({
        offset: '8px',
        position: 'top'
      });
    case 'topEnd':
      return triangle({
        offset: "calc(100% - 24px)",
        position: 'top'
      });
    case 'bottom':
      return triangle({
        offset: 'center',
        position: 'bottom'
      });
    case 'bottomStart':
      return triangle({
        offset: '8px',
        position: 'bottom'
      });
    case 'bottomEnd':
      return triangle({
        offset: "calc(100% - 24px)",
        position: 'bottom'
      });
    case 'start':
      return triangle({
        offset: 'center',
        position: 'start'
      });
    case 'startTop':
      return triangle({
        offset: '8px',
        position: 'start'
      });
    case 'startBottom':
      return triangle({
        offset: "calc(100% - 24px)",
        position: 'start'
      });
    case 'end':
      return triangle({
        offset: 'center',
        position: 'end'
      });
    case 'endTop':
      return triangle({
        offset: '8px',
        position: 'end'
      });
    case 'endBottom':
      return triangle({
        offset: "calc(100% - 24px)",
        position: 'end'
      });
    default:
      return assertNever(direction);
  }
};