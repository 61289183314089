import FutureEpisodesQuery from '@admin/graphql-api/_old_queries/FutureEpisodesQuery';
import {graphql} from '@apollo/client/react/hoc';
import dayjs from '@core/lib/dayjs';
import {Element, TEXT_MUTED, TEXT_NORMAL} from '@core/style';
import {Card, PageTitle} from '@core/ui/Content';
import DataTable from '@core/ui/DataTable';
import {Dummy, Img} from '@core/ui/Image';
import Loading from '@core/ui/Loading';

const FutureEpisodes = ({data: {loading, admin}}) => {
  if (loading) {
    return <Loading absolute />;
  }

  const {futureEpisodes} = admin;

  const columns = [
    {
      title: 'Podcast',
      accessor: 'id',
      type: 'string',
      Cell: ({
        data: {
          podcast: {feed},
        },
      }) => (
        <Element rules={() => ({display: 'flex'})}>
          <Element
            rules={() => ({
              flex: '0 0 4.6875rem',
              marginRight: '1rem',
              position: 'relative',
            })}>
            {feed.image ? <Img image={feed.image} width={150} /> : <Dummy />}
          </Element>

          <Element rules={() => ({flex: 1})}>
            <Element rules={() => ({fontWeight: 500, color: TEXT_NORMAL})}>
              {feed.title}
            </Element>
            <Element
              tag='p'
              rules={() => ({
                margin: '0.325rem 0 0 0',
                color: TEXT_MUTED,
              })}>
              {feed.description}
            </Element>
          </Element>
        </Element>
      ),
    },
    {
      title: 'Has campaign episodes',
      accessor: 'hasCampaignEpisodes',
      type: 'string',
    },
    {
      title: 'Published',
      accessor: 'expectedPublished',
      type: 'date',
      fmt: (v) => (v ? dayjs(v).utc().format('MMM DD, YYYY') : 'never'),
      noWrap: true,
    },
    {
      title: 'Created',
      accessor: 'createdAt',
      type: 'date',
      noWrap: true,
      fmt: (v) => dayjs(v).format('MMM DD, YYYY'),
    },
  ];

  const data = futureEpisodes.slice(0).map((d) =>
    Object.assign({}, d, {
      podcastTitle: d.podcast ? d.podcast.feed.title : '',
      hasCampaignEpisodes: d.campaignEpisodes.length > 0 ? 'Yes' : 'No',
    })
  );

  return (
    <Element>
      <PageTitle>Future Episodes</PageTitle>
      <Card>
        <DataTable
          columns={columns}
          data={data}
          searchKeys={['podcastTitle']}
          searchPlaceholder='Search podcast'
          orderBy='expectedPublished'
          onClickRowPath={({id}) => `/futures/${id}`}
        />
      </Card>
    </Element>
  );
};

export default graphql(FutureEpisodesQuery, {
  options: ({after, before}) => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        after: after.format('YYYY-MM-DD'),
        before: before.format('YYYY-MM-DD'),
      },
    };
  },
})(FutureEpisodes);
