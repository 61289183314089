const BASE64_DIGITS =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
const STRING_CHUNK_SIZE = 4096;

const INVERSE_DATA = (() => {
  const inverseDigits = [];
  for (let i = 0; i < 256; ++i) {
    inverseDigits[i] = 0xff;
  }
  for (let i = 0; i < BASE64_DIGITS.length; ++i) {
    inverseDigits[BASE64_DIGITS.charCodeAt(i)] = i;
  }

  return String.fromCharCode(...inverseDigits);
})();

function stringFromCharCode(data: number[]): string {
  if (data.length < STRING_CHUNK_SIZE) {
    return String.fromCharCode(...data);
  }

  let ptr = 0;
  const results: string[] = [];
  do {
    results.push(
      String.fromCharCode(...data.slice(ptr, ptr + STRING_CHUNK_SIZE))
    );
    ptr += STRING_CHUNK_SIZE;
  } while (ptr < data.length);
  return results.join('');
}

function base64Encode(rawStr: string): string {
  const str = String(rawStr);

  const out: string[] = [];
  const len = str.length;
  let i = 0;
  while (i < len) {
    const c1 = str.charCodeAt(i++) & 0xff;
    if (i === len) {
      out.push(
        BASE64_DIGITS.charAt(c1 >> 2),
        BASE64_DIGITS.charAt((c1 & 0x3) << 4),
        '=='
      );
      break;
    }
    const c2 = str.charCodeAt(i++);
    if (i === len) {
      out.push(
        BASE64_DIGITS.charAt(c1 >> 2),
        BASE64_DIGITS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4)),
        BASE64_DIGITS.charAt((c2 & 0xf) << 2),
        '='
      );
      break;
    }
    const c3 = str.charCodeAt(i++);
    out.push(
      BASE64_DIGITS.charAt(c1 >> 2),
      BASE64_DIGITS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4)),
      BASE64_DIGITS.charAt(((c2 & 0xf) << 2) | ((c3 & 0xc0) >> 6)),
      BASE64_DIGITS.charAt(c3 & 0x3f)
    );
  }

  return out.join('');
}

function base64Decode(base64Str: string): string {
  const str = String(base64Str);

  const out: number[] = [];
  const len = str.length;
  let j = 0;

  let tmp0;
  let tmp1;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    do {
      tmp0 = INVERSE_DATA.charCodeAt(str.charCodeAt(j++) & 0xff);
    } while (tmp0 === 0xff && j < len);
    do {
      tmp1 = INVERSE_DATA.charCodeAt(str.charCodeAt(j++) & 0xff);
    } while (tmp1 === 0xff && j < len);
    if (tmp1 === 0xff) {
      break;
    }
    out.push(((tmp0 << 2) | (tmp1 >> 4)) & 0xff);
    do {
      tmp0 = INVERSE_DATA.charCodeAt(str.charCodeAt(j++) & 0xff);
    } while (tmp0 === 0xff && j < len);
    if (tmp0 === 0xff) {
      break;
    }
    out.push(((tmp1 << 4) | (tmp0 >> 2)) & 0xff);
    do {
      tmp1 = INVERSE_DATA.charCodeAt(str.charCodeAt(j++) & 0xff);
    } while (tmp1 === 0xff && j < len);
    if (tmp1 === 0xff) {
      break;
    }
    out.push(((tmp0 << 6) | tmp1) & 0xff);
  }

  return stringFromCharCode(out);
}

/**
 * Encodes a raw string to a base64 string.
 *
 * @param rawStr - The string to encode.
 * @returns Out The base64 encoding of the `rawStr`.
 */
let encode: (rawString: string) => string = base64Encode;

/**
 * Decodes a base64 encoded string into a raw string.
 *
 * @param base64Str - The base64 encoded string.
 * @returns RawStr The raw string.
 */
let decode: (base64Str: string) => string = base64Decode;

if (typeof window !== 'undefined' && window.btoa && window.atob) {
  encode = (str) => window.btoa(str);
  decode = (str) => window.atob(str);
}

export {encode, decode};
