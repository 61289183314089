import {gql} from '@apollo/client';

export default gql`
  mutation ignoredContact($input: IgnoredContactInput!) {
    authed {
      success
    }
    ignoredContact(input: $input) {
      success
    }
  }
`;
