import React, {
  Dispatch,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import {CSSTransition} from 'react-transition-group';
import styled from 'styled-components';
import {FelaElement, TRules} from '@core/style';
import {
  PARENT_CONTAINER,
  useBodyScrollLock,
  useCloseOnEscKey,
  useCloseOnOutsideClick,
} from './helpers';
import {usePositionTarget} from './usePositionTarget';

type OverlayAnimation = 'move-up' | 'move-down' | 'scale-in' | 'opacity';

export type OverlayProps = {
  /**
   * Optional animation to use when showing/hiding the overlay.
   */
  animation?: OverlayAnimation;
  /**
   * If `true`, the overlay is aligned in the middle of the screen.
   * @default false
   */
  centered?: boolean;
  className?: string;
  /**
   * If `true`, the overlay will be closed when the escape key is pressed.
   * @default true
   */
  closeOnEscKey?: boolean;
  /**
   * If `true`, the overlay will be closed when clicking outside the overlay.
   * @default true
   */
  closeOnOutsideClick?: boolean;
  /**
   * If `true`, the overlay will be fixed to the screen when scrolling.
   * @default 'false'
   */
  fixed?: boolean;
  /**
   * The horizontal position of the overlay respect to the positionTarget.
   * @default 'center'
   */
  horizontalAlign?: 'left' | 'center' | 'right';
  horizontalOffset?: number;
  /**
   * If `true`, will position the overlay around the positionTarget without overlapping it horizontally.
   * @default 'false'
   */
  noHorizontalOverlap?: boolean;
  /**
   * If `true`, will position the overlay around the positionTarget without overlapping it vertically.
   * @default 'true'
   */
  noVerticalOverlap?: boolean;
  beforeCloseOnOutsideClick?: () => void | null;
  /**
   * If `true`, the overlay is visible and attached to <body>
   * @default false
   */
  opened?: boolean;
  toggle?: Dispatch<SetStateAction<boolean>> | ((_to?: boolean) => void | null);
  /**
   * The element relative to which the overlay is positioned
   * @default null
   */
  positionTarget?: HTMLElement | null;
  transparentBackdrop?: boolean;
  /**
   * The vertical position of the overlay respect to the positionTarget.
   * @default 'center'
   */
  verticalAlign?: 'top' | 'middle' | 'bottom';
  verticalOffset?: number;
  /**
   * If `true`, the overlay has backdrop on top of content when opened.
   * @default false
   */
  withBackdrop?: boolean;
  /**
   * If `true`, the overlay has a shadow.
   * @default false
   */
  withShadow?: boolean;
  /**
   * @deprecated
   */
  rules?: TRules;
};

const ConditionalWrap = ({condition, wrap, children}) =>
  condition ? wrap(children) : children;

export const Overlay = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & OverlayProps
>(
  (
    {
      beforeCloseOnOutsideClick,
      centered = false,
      closeOnEscKey = true,
      closeOnOutsideClick = true,
      fixed = false,
      horizontalAlign = 'center',
      horizontalOffset = 0,
      animation,
      noHorizontalOverlap = false,
      noVerticalOverlap = true,
      opened = false,
      positionTarget = null,
      rules,
      toggle,
      transparentBackdrop = false,
      verticalAlign = 'bottom',
      verticalOffset = 0,
      withBackdrop = false,
      withShadow = false,
      ...props
    },
    ref
  ) => {
    const defaultRef = useRef<HTMLDivElement>(null);
    const overlayRef = (ref || defaultRef) as RefObject<HTMLDivElement>;
    const [animateIn, setAnimateIn] = useState<boolean>(false);
    const [inset] = usePositionTarget({
      opened,
      overlayRef,
      positionTarget,
      // Position props:
      fixed,
      horizontalAlign,
      horizontalOffset,
      noHorizontalOverlap,
      noVerticalOverlap,
      verticalAlign,
      verticalOffset,
    });
    useBodyScrollLock({
      hideScroll:
        opened && !positionTarget && withBackdrop && !transparentBackdrop,
    });
    useCloseOnOutsideClick({
      active: closeOnOutsideClick && opened,
      beforeCloseOnOutsideClick,
      overlayRef,
      toggle,
    });
    useCloseOnEscKey({
      active: closeOnEscKey && opened,
      toggle,
      overlayRef,
    });

    useEffect(() => {
      setAnimateIn(opened);
    }, [opened]);

    const OverlayComponent = getOverlayComponent(animation);

    return ReactDOM.createPortal(
      opened ? (
        <>
          {withBackdrop && (
            <_Backdrop
              // Hide from screen readers
              aria-hidden
              $transparentBackdrop={transparentBackdrop}
            />
          )}
          <ConditionalWrap
            condition={!!animation}
            wrap={(_child) => (
              <CSSTransition
                in={animateIn}
                nodeRef={overlayRef}
                timeout={280}
                classNames='animation'>
                {_child}
              </CSSTransition>
            )}>
            <ConditionalWrap
              condition={!positionTarget}
              wrap={(_child) => (
                <_OverlayContainer $centered={centered}>
                  {_child}
                </_OverlayContainer>
              )}>
              <OverlayComponent
                data-overlay
                ref={overlayRef}
                tabIndex={-1}
                $withShadow={withShadow}
                $inset={inset}
                $hasPositionTarget={!!positionTarget}
                $centered={centered}
                $fixed={fixed}
                rules={rules}
                {...props}
              />
            </ConditionalWrap>
          </ConditionalWrap>
        </>
      ) : null,
      PARENT_CONTAINER
    );
  }
);

type _OverlayProps = {
  $centered: boolean;
  $fixed: boolean;
  $hasPositionTarget: boolean;
  $inset: string;
  $withShadow: boolean;
};

const _Overlay = styled(FelaElement)<_OverlayProps>`
  background: var(--bg-overlay);
  border-radius: 0.5rem;
  box-shadow: ${(p) => (p.$withShadow ? 'var(--shadow-xl)' : 'none')};
  color: var(--text-default);
  margin: auto;
  max-height: 100vh;
  outline: 0;
  overflow: auto;
  pointer-events: all;
  z-index: 1000;
  ${(p) => (p.$centered ? 'max-height: 90vh;' : '')}
  ${(p) => (p.$fixed ? 'position: fixed;' : 'position: absolute;')}
  ${(p) =>
    p.$hasPositionTarget
      ? p.$inset
        ? `inset: ${p.$inset};`
        : `position: fixed; opacity: none;`
      : ''}
`;

const _Backdrop = styled.div<{$transparentBackdrop: boolean}>`
  background: ${(p) =>
    p.$transparentBackdrop ? 'transparent' : 'rgba(0,0,0, 0.33)'};
  bottom: 0;
  left: 0;
  pointer-events: all;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
`;

const _OverlayContainer = styled.div<{$centered: boolean}>`
  inset: 0;
  padding: 3rem;
  pointer-events: none;
  position: fixed;
  z-index: 1000;
  ${(p) =>
    p.$centered &&
    `
      align-items: center;
      display: flex;
      justify-content: center;
  `}
`;

const _ScaleInAnimation = styled(_Overlay)`
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 280ms, transform 280ms cubic-bezier(0.25, 0.1, 0.25, 1);

  &.animation-enter-active,
  &.animation-enter-done {
    opacity: 1;
    transform: translateX(0);
  }
`;

const _MoveDownAnimation = styled(_Overlay)`
  transform: translateY(-10px);
  transition: transform 280ms cubic-bezier(0.16, 1, 0.3, 1), opacity 280ms ease,
    visibility 0s 0s;

  &.animation-enter-active,
  &.animation-enter-done {
    transform: translateY(0);
  }
`;

const _MoveUpAnimation = styled(_Overlay)`
  transform: translateY(10px);
  transition: transform 280ms cubic-bezier(0.16, 1, 0.3, 1), opacity 280ms ease,
    visibility 0s 0s;

  &.animation-enter-active,
  &.animation-enter-done {
    transform: translateY(0);
  }
`;

const _OpacityAnimation = styled(_Overlay)`
  opacity: 0;
  transition: opacity 280ms;

  &.animation-enter-active,
  &.animation-enter-done {
    opacity: 1;
  }
`;

const getOverlayComponent = (animation?: OverlayAnimation) => {
  switch (animation) {
    case 'scale-in':
      return _ScaleInAnimation;
    case 'move-down':
      return _MoveDownAnimation;
    case 'move-up':
      return _MoveUpAnimation;
    case 'opacity':
      return _OpacityAnimation;
    default:
      return _Overlay;
  }
};
